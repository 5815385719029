import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 16px;
`;

export const Content = styled(Typography)`
  font-size: 12px;
  line-height: 13px;
  margin-bottom: 8px;
  color: ${colors.white};
`;

export const Link = styled.a`
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
  text-decoration: underline ${colors.orange};
  color: ${colors.orange};
`;
