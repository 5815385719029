import { useMemo } from "react";
import { Vector3, Euler, Matrix4 } from "three";
import { JsonLocation, JsonRig } from "features/JobBuilder/json-types";
import { useRelativeLocation } from "./useRelativeLocation";
import { useRigLocation } from "./useRigLocation";

export const useCaptureCameraLocation = (
  rigData: JsonRig | undefined,
  cameraLocation: JsonLocation | undefined
) => {
  const { origin, position, rotation } = useRigLocation(rigData, "mean");

  const cameraRelativeLocation = useRelativeLocation(cameraLocation);

  const cameraPosition = useMemo(() => {
    const rigPosition = new Vector3().addVectors(
      origin,
      position.applyEuler(rotation)
    );

    if (!cameraRelativeLocation.position) return null;

    return new Vector3().addVectors(
      rigPosition,
      cameraRelativeLocation.position.applyEuler(rotation)
    );
  }, [origin, position, rotation, cameraRelativeLocation.position]);

  const cameraRotation = useMemo(
    () =>
      cameraRelativeLocation.rotation
        ? new Euler().setFromRotationMatrix(
            new Matrix4()
              .makeRotationFromEuler(rotation)
              .multiply(
                new Matrix4().makeRotationFromEuler(
                  cameraRelativeLocation.rotation
                )
              )
          )
        : null,
    [rotation, cameraRelativeLocation.rotation]
  );

  const distanceToOrigin = useMemo(() => {
    if (!cameraPosition) return null;

    return cameraPosition.distanceTo(origin);
  }, [origin, cameraPosition]);

  return { cameraPosition, cameraRotation, distanceToOrigin };
};
