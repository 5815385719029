import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const Container = styled(Box)<{
  $selected?: boolean;
  $hideImages?: boolean;
}>`
  padding: ${(props) => (props.$hideImages ? "16px 16px 0" : "8px 8px 0")};
  border-radius: 8px;
  border: 1px solid
    ${(props) => (props.$selected ? colors.bright[2] : colors.grey20)};
  max-width: 198px;
  cursor: pointer;
  position: relative;
  box-shadow: ${(props) =>
    props.$selected && ` inset 0px 0px 0px 1px ${colors.bright[2]};`};

  position: ${(props) => (props.$selected ? "sticky" : "relative")};
  left: ${(props) => (props.$selected ? "0" : "auto")};
  z-index: ${(props) => (props.$selected ? "1" : "auto")};
  background-color: ${colors.white};
`;

export const Image = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 8px;
  margin-bottom: 12px;
`;

export const Title = styled(Typography)`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  padding-bottom: 4px;
`;

export const Description = styled(Typography)`
  font-size: 10px;
  line-height: 14px;
`;

export const BottomInfoContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
`;

export const CheckmarkContainer = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  background-color: ${colors.bright[2]};
  border-radius: 8px;
  font-size: 16px;
`;
