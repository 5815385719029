export const Messages = {
  animationsProTip:
    "Animations are body motion sequences or still poses. Animation frames are designed to be played back at 30fps.",
  animationsWarningVehicleOn:
    "You cannot select this gesture. Please ensure you have turned off vehicles in your environment.",
  animationsWarningVehicleOff:
    "You cannot select this gesture. Please ensure you have first set your environment to vehicle.",
  bodyGeometryInfo:
    "Do not only display a floating head and neck; display the body.",
  bodyHighResolutionSkinTextureInfo:
    "(Optional) Enforce a high resolution skin texture, even for low resolution images; may increase price per image.",
  bodyPropTip: `By default, height and fat content are set and consistent for each human ID.
    Optionally you can override these defaults and force widely varying shapes and sizes of bodies for every render for wider variance.`,
  cameraWaveLenghtInfo:
    "The default is the visible range of light. When NIR is selected, the camera will be set to also accept a range of wavelengths.",
  checkVerificationEmail: "Check your email for a new verification link",
  clothingProTip:
    "Outfits are pre-designed combinations of layers of clothing that look realistic together. We’ve mixed-and-matched a variety of layers on our own to save you time.",
  commaSeparated: "Comma Separated",
  copietToClipboard: "Command copied to clipboard",
  correctPassword: "Correct Password",
  denoiseDescription:
    "Apply a noise-reducing (smoothing) operation on the render output, a standard part of ray tracing pipelines.",
  emailUpdated:
    "Email successfully updated. Please log in again with the updated email address.",
  ethnicityMatchedDescription:
    "Force that the hair style be “stereotypically” ethnically-appropriate for the identity.",
  expressionProTip:
    "Expression manipulates the shape of the eyes, mouth, cheeks, etc. according to the name of an expression, and an amount, called intensity.",
  environmentHDRIProTip:
    "HDRI maps provide the primary lighting for the scene in terms of where the sun and surrounding lights are, and how bright they are, as well as color of the background. They can be rotated and increased/decreased in level/intensity to brighten the scene for more variance.",
  environmentVehicleProTip:
    "This tab place the human in the driver's seat of a vehicle.  HDRI maps add additional lighting for the scene outside the car, in terms of where the sun and surrounding lights are, and how bright they are, as well as color of the background.",
  gazeProTip:
    "Vary the direction the human's eye is looking.  Note: gaze vectors are not guaranteed to converge.",
  hairProTip:
    "By default, each identity has a set hairstyle and color.  For more customization, you can configure desired hairstyles here.",
  facialHairProTip:
    "By default, each male identity has a set facial hair style and color — mostly clean shaven. For more customization, you can configure desired facial hair style, color, length here.",
  headphonesProTip: "Add headphones to distributions of scenes of humans here.",
  headTurnProTip:
    "Vary the human's head turn here. Note: the head turn origin is anatomical, at the top of the neck/base of the skull.",
  hedwearProTip: "Add hats/headwear to distributions of scenes of humans here.",
  identitiesDemographicBuilderProTip: `Use the Demographic Builder to customize your cohort of identities for this Job.
  Calibrate your identities’ parameters, and click “Upgrade Identities Selected” to regenerate the Identites for this Job. `,
  identitiesManuallyEnterProTip: `Use the Manually Enter Identities mode to manually enter or add identities for this Job. Input comma separated identity ID request(s) to add them to this Job.`,
  identitiesPrefabProTip: `Use the Prefab Identity Groups to get started quickly.
  Select a prefab identity group from below that will load several commonly used identities to your Job builder.`,
  leftOrg: (orgName: string) =>
    `You successfully left org ${orgName}. You need to sign in again`,
  lightTypeInfo:
    "Different shapes of physical lights  vary the direction of light dispersal.",
  lightWaveLenghtInfo:
    "For near infrared light sources, select “NIR“. You will have to set at least one camera to NIR to see this light.",
  mirroredForAnimations:
    "Mirroring turns on the exact mirror image of the pose or animation, e.g. what was done with the left side of the body will be done with the right side of the body and vice versa.",
  passwordsNotMatching: "Passwords don't match. Please try again.",
  pipe2RigLocationDialog:
    "In order to submit a job and render images of the scenario, at least one rig and camera must be configured within each sub-job. Learn more about the rig and camera setup within the scene in this quick overview.",
  pipe2RigLocationProTip:
    "All cameras are attached to a center 'rig', so they can be moved around the scene together.",
  presetNameCaption:
    "The preset location helps you manipulate the position of the “mirror” that would be within the vehicle.",
  rigProTip:
    "All cameras and spot lighting are place into groups, called 'rigs', which can be moved around the scene together.",
  scenesPerIdentityProTip:
    "By default, a job generates one scene/image for each selected identity. However, you can change this number by increasing the number of scenes/images to be generated for each selected identity. The total number of images generated by the job is the total number of selected identities times the number of scenes/images per identity.",
  selectTemplate: "Select Template",
  sentAnotherInviteEmail: (email: string) =>
    `We have sent another invite to ${email}`,
  sexMatchedDescriptionForHair:
    "Force that the hair style be “stereotypically” sex-appropriate for the identity.",
  sexMatchedDescriptionForClothing:
    "Force that the outfit style selected be “stereotypically” sex-appropriate for the identity.",
  sexMatchedDescriptionForHeadwear:
    "Force that the headwear/hat style selected be “stereotypically” sex-appropriate for the identity.",
  sexMatchedDescriptionForGlasses:
    "Force that the glasses style selected be “stereotypically” sex-appropriate for the identity.",
  shouldAgree: "You need to agree to the terms and conditions",
  singleStylePerIdForClothing:
    "In every scene, ensure that the character is always wearing the same outfit.",
  singleStylePerIdForAnimations:
    "Single still images create a static pose (a single render with a single frame), as opposed to a full animation of several frames.",
  somethingWentWrong: "Oops!, something went wrong.",
  stageElementProTipModal: `In order to submit a job and render images of the human identity, at least one rig, camera and light must be present within the scene to successfully capture images of the human identity/identities.
    Learn more about the rig, camera and light setup within the scene in the diagram below, or goto the Synthesis AI documentation.`,
  stageElementStep1Camera: "All cameras are mounted to a rig",
  stageElementStep1Light: "All lights are mounted to a rig",
  stageElementStep1Rig:
    "A rig is the stand that all lights and cameras are mounted to within the stage.",
  stageElementStep2Camera: "There can be up to 5 cameras mounted to each rig",
  stageElementStep2Light: "There can be up to 3 lights mounted to each rig",
  stageElementStep2Rig:
    "There must be at least 1 rig with 1 camera within the stage.",
  stageElementStep3Camera:
    "Cameras can be calibrated to exhibit different photo effects to the images that they capture and output as renderings.",
  stageElementStep3Light:
    "Lights can be calibrated to exhibit different effects within the rendering of the stage.",
  stageElementStep3Rig:
    "Rigs can be calibrated to exhibit different positions within the rendering of the stage.",
  successPasswordChange: "Password successfully changed",
  thankForPurchase: "Thank you for your purchase.",
  userAdded: (email: string, orgName: string) =>
    `${email} has been added to ${orgName}`,
  wizardHeaderMessage:
    "To get started, choose an existing JSON or a job template",
  wrongPassword: "Wrong Password",
  maskProTip: `Add face masks to distributions of scenes of humans here, including variation of positions and designs.`,
  matchHairColorDescription:
    "Always match whatever head hair color is selected in this scene.",
  glassesProTip: `Add glasses to distributions of scenes of humans here, and customize the reflectivity and color of the lenses as well.`,
  addGroupTooltip:
    " Distribution group percentages must be greater than 0, and the total percentage of all groups needs to sum up to 100%.",
  search: "Search",
  noResultFoundTitle: "Sorry! No result found",
  noResultFoundDescription: "We couldn't find what you were looking for",
};
