import { SimpleSidebarItem } from "features/JobBuilder/LeftSidebar/SimpleSidebarItem";
import { selectPickedSubjob } from "features/JobBuilder/store";
import { useBoundStore } from "store/_boundStore";
import { compact } from "utils/stringUtil";

export function SidebarEnvironemnt() {
  const pickedSubjob = useBoundStore(selectPickedSubjob);
  const numberOfHdrisInPickedSection = pickedSubjob.hdri.name.length;
  return (
    <SimpleSidebarItem
      additionalInfo={compact(numberOfHdrisInPickedSection)}
      icon={"HDRIIcon"}
      id="hdri"
      label="Environment"
    />
  );
}
