import { useCallback, useMemo, useState } from "react";
import { IconsType } from "types/main";
const imageObjFromOption = (
  s: string,
  urlGetter: (s: string) => string,
  sideInfo?: (s: string) => string | undefined
) => ({
  src: urlGetter(s),
  label: s,
  alt: s,
  key: s,
  sideInfo: sideInfo ? sideInfo(s) : undefined,
});
export const useImageSelection = (
  selected: string[],
  allOptions: string[],
  urlGetter: (option: string) => string,
  updateAll: (selected: string[]) => void,
  label: string,
  id: string,
  icon: IconsType,
  togglePickOne: (i: number) => void,
  sideInfo?: (s: string) => string | undefined
) => {
  const [searchTerm, setSearchTerm] = useState("");

  const allImages = useMemo(
    () =>
      allOptions.map((s) => ({
        ...imageObjFromOption(s, urlGetter, sideInfo),
        isSelected: selected.includes(s),
      })),
    [allOptions, urlGetter, selected, sideInfo]
  );
  const filteredImages = useMemo(
    () =>
      allImages.filter((image) =>
        image.label.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [allImages, searchTerm]
  );
  const selectedImages = useMemo(
    () => selected.map((s) => imageObjFromOption(s, urlGetter, sideInfo)),
    [selected, urlGetter, sideInfo]
  );

  const areAllSelected = filteredImages.length === selectedImages.length;
  const handleSelectAll = useCallback(() => {
    if (areAllSelected) {
      updateAll([]);
    } else {
      updateAll(filteredImages.map((s) => s.label as string));
    }
  }, [areAllSelected, filteredImages, updateAll]);

  const pickOne = useCallback(
    (filteredIndex: number) => {
      const selectedImage = filteredImages[filteredIndex];

      const allIndex = allImages.findIndex(
        (image) => image.label === selectedImage.label
      );

      togglePickOne(allIndex);
    },
    [filteredImages, allImages, togglePickOne]
  );

  return useMemo(
    () => ({
      areAllSelected,
      filteredImages,
      handleSelectAll,
      icon,
      id,
      label,
      selectedImages,
      setSearchTerm,
      togglePickOne: pickOne,
    }),
    [
      areAllSelected,
      filteredImages,
      handleSelectAll,
      icon,
      id,
      label,
      pickOne,
      selectedImages,
    ]
  );
};

export function useSelectionOutcomeCount(
  imageSelections?: ReturnType<typeof useImageSelection>[]
) {
  if (
    !imageSelections ||
    imageSelections.every(
      (selection) => !selection || !selection.selectedImages.length
    )
  )
    return 0;

  return imageSelections.reduce(
    (acc, curr) => acc * curr.selectedImages.length,
    1
  );
}
