import { useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { produce } from "immer";
import lightsRelativeLocationPitch from "assets/diagrams/light-relative-location-pitch.svg";
import lightsRelativeLocationRoll from "assets/diagrams/light-relative-location-roll.svg";
import lightsRelativeLocationX from "assets/diagrams/light-relative-location-x.svg";
import lightsRelativeLocationY from "assets/diagrams/light-relative-location-y.svg";
import lightsRelativeLocationYaw from "assets/diagrams/light-relative-location-yaw.svg";
import lightsRelativeLocationZ from "assets/diagrams/light-relative-location-z-depth.svg";
import lightsSpecificationsColors from "assets/diagrams/light-specifications-colors.svg";
import lightsSpecificationsIntesity from "assets/diagrams/light-specifications-intensity.svg";
import lightsSpecificationsSizeMatters from "assets/diagrams/light-specifications-size-meters.svg";
import wavelenght from "assets/diagrams/rigs-wavelength.svg";
import { Collapsible } from "components/Collapsible";
import { DropdownWithTitle } from "components/DropdownWithTitle";
import { LightTypeSelector } from "components/LightTypeSelector";
import { Diagram } from "components/LightTypeSelector/LightTypeSelector.styled";
import { SelectorsPicker } from "components/SelectorsPicker";
import { TooltipWithLinkContent } from "components/TooltipWithLinkContent";
import { Messages } from "constants/messages";
import {
  defaultLight,
  LoRType,
  lorWithTitleUI,
  wavelengthOptions,
} from "domain/Human";
import { SelectorContainer } from "features/JobBuilderShared/Styles/styles";
import { useBoundStore } from "store/_boundStore";
import { convertLightStateToJson } from "./form-to-input-json";
import { JsonManagerNewUI } from "./json-manager-new-ui";
import { convertLight } from "./json-to-form";

type LightProps = {
  value: typeof defaultLight;
  onUpdate: (v: typeof defaultLight) => void;
  onDelete: () => void;
  myIndex: number;
};

export function Light(props: LightProps) {
  const { value, onUpdate, onDelete, myIndex } = props;

  const onUpdateLocal = useCallback(
    (fn: (draft: typeof defaultLight) => void) => {
      onUpdate(produce(value, fn));
    },
    [onUpdate, value]
  );

  const onUpdateType = (v: string) =>
    onUpdateLocal((d) => {
      d.type = v;
    });
  const onUpdateWavelength = (v: string) =>
    onUpdateLocal((d) => {
      d.wavelength = v;
    });
  const onUpdateIntensity = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.intensity = v;
    });
  const onUpdateSizeMeters = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.sizeMeters = v;
    });
  const onUpdateX = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.x = v;
    });
  const onUpdateY = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.y = v;
    });
  const onUpdateZ = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.z = v;
    });
  const onUpdatePitch = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.pitch = v;
    });
  const onUpdateYaw = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.yaw = v;
    });
  const onUpdateRoll = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.roll = v;
    });
  const onUpdateRed = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.red = v;
    });
  const onUpdateGreen = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.green = v;
    });
  const onUpdateBlue = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.blue = v;
    });
  const { setErrorMessage, setSuccessMessage } =
    useBoundStore.getState().message;

  // TODO: Type human object
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onIndecesReceived = (indices: number[], humansArr: any[]) => {
    try {
      const lightInJson =
        humansArr[indices[0]].camera_and_light_rigs[indices[1]].lights[
          indices[2]
        ];
      onUpdate(convertLight(lightInJson, value));
      setSuccessMessage("updated light object");
    } catch (error) {
      setErrorMessage("can't find light object");
    }
  };

  // TODO: Type JSON object
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDoneJson = (json: any) => {
    const updated = convertLight(json, value);
    onUpdate(updated);
  };
  const jsonValue = useMemo(() => convertLightStateToJson(value), [value]);

  return (
    <JsonManagerNewUI
      id={`light-${myIndex + 1}`}
      jsonValue={jsonValue}
      title="Specifications"
      unknownIndicesKeys={["Human", "Rig", "Light"]}
      onDelete={onDelete}
      onDoneEditJson={onDoneJson}
      onIndecesReceived={onIndecesReceived}
    >
      <Box component="div" mb="48px" style={{ padding: 8 }}>
        <LightTypeSelector value={value.type} onUpdate={onUpdateType} />
        <DropdownWithTitle
          extraInfo={
            <TooltipWithLinkContent
              linkLabel="Learn More"
              linkTo="https://docs.synthesis.ai/inputs.html#wavelengthNIR"
              textContent={Messages.lightWaveLenghtInfo}
            />
          }
          icon="WavesIcon"
          label="Wavelength"
          options={wavelengthOptions}
          value={value.wavelength}
          onUpdate={onUpdateWavelength}
        />
        <Diagram src={wavelenght} />
        {[
          lorWithTitleUI(
            "Intensity",
            value.intensity,
            defaultLight.intensity,
            onUpdateIntensity,
            "units",
            "IntensityIcon",
            lightsSpecificationsIntesity
          ),
          lorWithTitleUI(
            "Size",
            value.sizeMeters,
            defaultLight.sizeMeters,
            onUpdateSizeMeters,
            "meters",
            "SizeIcon",
            lightsSpecificationsSizeMatters
          ),
          lorWithTitleUI(
            "Red",
            value.red,
            defaultLight.red,
            onUpdateRed,
            "",
            "ColorIcon",
            lightsSpecificationsColors
          ),
          lorWithTitleUI(
            "Green",
            value.green,
            defaultLight.green,
            onUpdateGreen,
            "",
            "ColorIcon",
            lightsSpecificationsColors
          ),
          lorWithTitleUI(
            "Blue",
            value.blue,
            defaultLight.blue,
            onUpdateBlue,
            "",
            "ColorIcon",
            lightsSpecificationsColors
          ),
        ].map((l, i) => (
          <SelectorContainer key={i}>
            <SelectorsPicker
              noSpacing
              icon={l.icon ?? "SensorWidthIcon"}
              imgSrc={l.imgSrc ?? ""}
              limits={l.limits}
              lorValue={l.value}
              title={l.title}
              unit={l.unitLabel}
              onUpdate={l.updateFn}
            />
          </SelectorContainer>
        ))}
        <Collapsible collapsedByDefault label={"Relative Location"}>
          <Box component="div">
            {[
              lorWithTitleUI(
                "Z - Depth",
                value.z,
                defaultLight.z,
                onUpdateZ,
                "meters",
                "ZDepthIcon",
                lightsRelativeLocationZ
              ),
              lorWithTitleUI(
                "Pitch",
                value.pitch,
                defaultLight.pitch,
                onUpdatePitch,
                "degrees",
                "PitchIcon",
                lightsRelativeLocationPitch
              ),
              lorWithTitleUI(
                "Yaw",
                value.yaw,
                defaultLight.yaw,
                onUpdateYaw,
                "degrees",
                "YawIcon",
                lightsRelativeLocationYaw
              ),
              lorWithTitleUI(
                "Roll",
                value.roll,
                defaultLight.roll,
                onUpdateRoll,
                "degrees",
                "RollIcon",
                lightsRelativeLocationRoll
              ),
              lorWithTitleUI(
                "X - Axis",
                value.x,
                defaultLight.x,
                onUpdateX,
                "meters",
                "XAxisIcon",
                lightsRelativeLocationX
              ),
              lorWithTitleUI(
                "Y - Axis",
                value.y,
                defaultLight.y,
                onUpdateY,
                "meters",
                "YAxisIcon",
                lightsRelativeLocationY
              ),
            ].map((l, i) => (
              <SelectorContainer key={i}>
                <SelectorsPicker
                  noSpacing
                  icon={l.icon ?? "SensorWidthIcon"}
                  imgSrc={l.imgSrc ?? ""}
                  limits={l.limits}
                  lorValue={l.value}
                  title={l.title}
                  unit={l.unitLabel}
                  onUpdate={l.updateFn}
                />
              </SelectorContainer>
            ))}
          </Box>
        </Collapsible>
      </Box>
    </JsonManagerNewUI>
  );
}
