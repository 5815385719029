export function currencyFormat(x: number): string {
  const usdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  });
  return usdFormatter.format(x / 100);
}

export function dealWithWhiteSpaceInCommand(str: string) {
  const hasWhiteSpace = str.split(/\s/).length > 1;
  return hasWhiteSpace ? `'${str}'` : str;
}

export function allCharactersAfterAtInEmail(str: string) {
  return str.split("@")[1];
}

export function lastCharacterBeforeAtInEmail(str: string) {
  const beforeAt = str.split("@")[0];
  return beforeAt[beforeAt.length - 1];
}

export const isStringArray = (s: unknown): s is string[] => {
  return Array.isArray(s);
};

export const trimMultiple = (...strs: string[]) =>
  strs
    .map((str) => str + " ")
    .join(" ")
    .trim();

const compactNumberFormatter = Intl.NumberFormat("en", {
  notation: "compact",
});
export const compact = (n: number) => compactNumberFormatter.format(n);
export const addSIfGreaterThan1 = (num: number, noun: string) => {
  return `${num} ${noun}${num > 1 ? "s" : ""}`;
};

export const getNumberOfImagesString = (numbersArray: number[]) => {
  return numbersArray[1] && numbersArray[1] > numbersArray[0]
    ? `${compact(numbersArray[0])} - ${compact(numbersArray[1])}`
    : compact(numbersArray[0]);
};

export const dasherize = (str: string) => {
  return str
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-")
    .replace(/[^\w]/g, "-");
};
