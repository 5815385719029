import { useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { colors } from "components/App/theme";
import { PODialog } from "components/PaymentInfo/PODialog";
import { Routes } from "constants/routes";
import { useBoundStore } from "store/_boundStore";
import { selectedOrgSelector } from "store/orgStore";
import { OrgBilling } from "types/main";

export function PaymentMethod({
  orgBilling,
  orgName,
  onSubmitPORequest,
}: {
  orgBilling: OrgBilling;
  orgName: string;
  onSubmitPORequest: (notes: string) => void;
}) {
  const [isPODialogOpen, setIsPODialogOpen] = useState(false);
  const [org, email] = useBoundStore((s) => [
    selectedOrgSelector(orgName)(s),
    s.profile.data?.email,
  ]);
  const onConfirmSubmitPORequest = (notes: string) => {
    setIsPODialogOpen(false);
    onSubmitPORequest(notes);
  };

  const billMethodDependent = {
    override: <p>Payment override enabled</p>,
    po: (
      <>
        <Box component="div" display="flex" justifyContent="space-between">
          <Typography variant="body1">
            Purchase Order: {orgBilling.po_number}
          </Typography>
          {!orgBilling.has_pending_po_request && (
            <Link
              sx={{ cursor: "pointer" }}
              underline="always"
              onClick={() => setIsPODialogOpen(true)}
            >
              Request to replace PO Number
            </Link>
          )}
        </Box>
      </>
    ),
    card: (
      <>
        <Box component="div">
          Card ending in{" "}
          <Box component="div" display="inline" fontFamily="monospace">
            {" "}
            {orgBilling.last4 || "----"}
          </Box>{" "}
          <Link
            component={RouterLink}
            to={`${Routes.EDIT_PAYMENT_INFO}?org=${orgName}`}
            underline="always"
          >
            Edit
          </Link>
        </Box>
      </>
    ),
    none: <></>,
  };

  return (
    <Box
      bgcolor={colors.neutral[0]}
      component="div"
      data-testid="payment-method-component"
      height="110px"
      padding={2}
    >
      {isPODialogOpen && email && (
        <PODialog
          email={email}
          orgName={orgName}
          onCancel={() => setIsPODialogOpen(false)}
          onConfirm={onConfirmSubmitPORequest}
        />
      )}
      <Box component="div" display="flex" justifyContent="space-between" mb={1}>
        <Typography variant="h2">Payment method</Typography>
        {orgBilling.has_pending_po_request && (
          <Typography variant="body1">You have a pending PO request</Typography>
        )}
      </Box>
      {!!org && billMethodDependent[org.bill_method]}
    </Box>
  );
}
