import React from "react";
import { Box } from "@mui/material";
import { Label } from "components/TitleWithIcon/TitleWithIcon.styled";
import { IdentitiesSelected } from "./identities-selected";

export function PreviewIdentities({
  selectedPrefabIds,
  showWhenEmpty = true,
  emptyMessage,
  previewHeight,
}: {
  selectedPrefabIds: number[];
  showWhenEmpty?: boolean;
  emptyMessage?: React.ReactNode;
  previewHeight?: number;
}) {
  if (!showWhenEmpty && !selectedPrefabIds.length) return null;

  return (
    <Box component="div" mt={2}>
      <Label style={{ paddingLeft: 0, marginBottom: 8 }}>
        Identities preview ({selectedPrefabIds.length})
      </Label>
      <IdentitiesSelected
        emptyMessage={emptyMessage}
        height={previewHeight}
        selectedIds={selectedPrefabIds}
      />
    </Box>
  );
}
