import React from "react";

export function IdentitiesSelectedIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="20"
      style={style}
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4H0V18C0 19.1 0.9 20 2 20H16V18H2V4ZM12 9C13.66 9 15 7.66 15 6C15 4.34 13.66 3 12 3C10.34 3 9 4.34 9 6C9 7.66 10.34 9 12 9ZM12 5C12.55 5 13 5.45 13 6C13 6.55 12.55 7 12 7C11.45 7 11 6.55 11 6C11 5.45 11.45 5 12 5ZM18 0H6C4.9 0 4 0.9 4 2V14C4 15.1 4.9 16 6 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM8.69 14C9.64 13.37 10.78 13 12 13C13.22 13 14.36 13.37 15.31 14H8.69ZM18 13.73C16.53 12.06 14.4 11 12 11C9.6 11 7.47 12.06 6 13.73V2H18V13.73Z"
        fill={fill}
      />
    </svg>
  );
}
