import styled from "styled-components";

export const JOB_FOOTER_HEIGHT = 50;

export const FooterWrapper = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  position: sticky;
  bottom: 0;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 100;
  flex-shrink: 0;
  height: ${JOB_FOOTER_HEIGHT}px;
`;

export const FooterActionsWrapper = styled.div`
  display: flex;
  gap: 6px;
  padding-right: 8px;
`;
