import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const Container = styled(Box)`
  text-align: center;
  padding: 40px 0;
`;

export const InnerContainer = styled(Box)`
  margin: auto;
  width: 524px;
`;

export const Header = styled(Typography)`
  font-weight: 600;
  font-size: 23px;
  line-height: 29.9px;
  color: ${colors.grey70};
`;

export const Paragraph = styled(Typography)`
  margin-top: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.grey70};
`;
