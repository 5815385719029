export function StyleIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.98504 18.9387L2.98423 18.9383C2.22898 18.6243 1.85857 17.7454 2.16524 16.9788L3.63225 13.4411V19.2091L2.98504 18.9387ZM21.8303 15.8914L21.8306 15.892C22.1429 16.6431 21.7792 17.5217 21.0119 17.8377L21.0108 17.8381L13.6539 20.8868C13.4638 20.9625 13.2688 20.9996 13.0754 21C12.4842 20.9912 11.934 20.6421 11.7052 20.0812L11.7042 20.0786L6.74416 8.1086L6.74417 8.10859L6.74273 8.10518C6.66039 7.91056 6.62469 7.71324 6.6319 7.51851L6.63193 7.51851L6.63208 7.51298C6.64719 6.93121 6.9944 6.39307 7.55324 6.16208L7.55344 6.162L14.9234 3.112L14.9234 3.11202L14.9271 3.11048C15.11 3.03308 15.3086 3.00068 15.5157 3.00001C16.098 3.01757 16.6411 3.3668 16.8702 3.92099L16.8703 3.9214L21.8303 15.8914ZM12.6304 19.6915L12.822 20.1535L13.2839 19.9618L20.6339 16.9118L21.0954 16.7203L20.9042 16.2587L15.9542 4.30865L15.8263 4H15.4922H15.4822H15.3829L15.2911 4.03796L7.94114 7.07796L7.47867 7.26924L7.67039 7.73154L12.6304 19.6915ZM11.7622 8.75C11.7622 9.02614 11.5384 9.25 11.2622 9.25C10.9861 9.25 10.7622 9.02614 10.7622 8.75C10.7622 8.47386 10.9861 8.25 11.2622 8.25C11.5384 8.25 11.7622 8.47386 11.7622 8.75ZM8.14225 21C7.31839 21 6.64225 20.3239 6.64225 19.5V15.6767L8.84432 21H8.14225Z"
        stroke={fill}
      />
    </svg>
  );
}
