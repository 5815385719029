import { useEffect } from "react";
import { ReadOnlyPickedCameraPresetLocation } from "features/JobBuilder2/steps/threeDimensional/ReadOnlyPickedCameraPresetLocation";
import { selectPickedSubjob } from "features/JobBuilder2/store";
import { Pipe2StepEnum } from "features/JobBuilder2/types";
import { useBoundStore } from "store/_boundStore";
import { ActivityRegion } from "./activity-regions/ActivityRegion";

export function PrefabGroups3dStep() {
  const [
    setSelectedStep,
    setIsCharacterGroupDialogOpened,
    activityZones,
    isDragging,
  ] = useBoundStore((s) => [
    s.jobBuilder2.setSelectedStep,
    s.jobBuilder2.setIsCharacterGroupDialogOpened,
    selectPickedSubjob(s).activityZones,
    s.userControls.dragging,
  ]);

  useEffect(() => {
    if (!activityZones.some((zone) => zone.activities.length > 0)) {
      setSelectedStep(Pipe2StepEnum.Activities);
    }
  }, [setSelectedStep, activityZones]);

  return (
    <>
      <ReadOnlyPickedCameraPresetLocation />
      {activityZones.map((zone, shapeIndex) => {
        const { id } = zone.geomRegion;
        return (
          <ActivityRegion
            key={id}
            activityZone={zone}
            hidden={zone.activities.length === 0}
            shapeIndex={shapeIndex}
            onClick={() => {
              if (isDragging) {
                return;
              }
              setIsCharacterGroupDialogOpened(true, shapeIndex);
            }}
          />
        );
      })}
    </>
  );
}
