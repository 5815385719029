import { Box } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ChartContainer = styled(Box)`
  --webkit-transform: translateX(-4%) scale(1);
  transform: translateX(-4%) scale(1);
  width: 100%;
  height: 164px;
  padding: 8px 0 16px;
`;
