import { useCallback } from "react";
import { DistKey, AbstractCategory, isChild, countOfInner } from "domain/Human";
import { DistributionGroup } from "features/JobBuilder/LeftSidebar/DistributionGroup";
import { ReverseTreeItem } from "features/JobBuilder/LeftSidebar/left-sidebar.styled";
import { SectionLabel } from "features/JobBuilder/LeftSidebar/SectionLabel";
import { AdditionalInfo } from "features/JobBuilder/LeftSidebar/SectionLabel/section-label.styled";
import { selectPickedSubjob } from "features/JobBuilder/store";
import { useBoundStore } from "store/_boundStore";
import { IconsType } from "types/main";

export function DeepDists({
  category,
  icon,
  label,
  dists,
}: {
  category: AbstractCategory;
  icon: IconsType;
  label: string;
  dists: { icon: IconsType; label: string; id: DistKey }[];
}) {
  const {
    setSelectedHumanObjectPart,
    addDistItem,
    setSelectedDistIndex,
    selectedPart,
  } = useBoundStore.getState().jobBuilder;
  const subjob = useBoundStore(selectPickedSubjob);
  const totalGroupsCount = countOfInner(category, subjob);
  const isInsideCategory = isChild(category, selectedPart);
  const isDistGroupSelected = useCallback(
    (part: DistKey) => isInsideCategory && selectedPart === part,
    [isInsideCategory, selectedPart]
  );
  const deleteItem = useBoundStore((s) => s.jobBuilder.deleteDistItem);
  const selectedIndecis = useBoundStore((s) => s.jobBuilder.selectedIndeces);

  return (
    <ReverseTreeItem
      label={
        <SectionLabel
          additionalInfo={
            <AdditionalInfo selected={isInsideCategory}>
              ({totalGroupsCount})
            </AdditionalInfo>
          }
          icon={icon}
          label={label}
          selected={isInsideCategory}
        />
      }
      nodeId={category}
      onClick={() => setSelectedHumanObjectPart(dists[0].id)}
    >
      {dists.map(({ id, icon, label }, i) => (
        <DistributionGroup
          key={id}
          distributionGroups={subjob[id]}
          icon={icon}
          id={id}
          isSelected={isDistGroupSelected(id)}
          label={label}
          selectIndex={setSelectedDistIndex(id)}
          selectPart={() => setSelectedHumanObjectPart(id)}
          selectedIndex={selectedIndecis[id]}
          onAddDistributionGroup={addDistItem(id)}
          onDelete={deleteItem(id)}
        />
      ))}
    </ReverseTreeItem>
  );
}
