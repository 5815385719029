import styled from "styled-components";
import { colors } from "components/App/theme";

const ICON_CONTAINER_SIZE = 40;

export const ToolbarIconContainer = styled.span`
  display: inline-block;
  width: ${ICON_CONTAINER_SIZE}px;
  height: ${ICON_CONTAINER_SIZE}px;
  margin: 0 8px 0 0;
  vertical-align: middle;
  background-color: ${colors.bright[2]};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${ICON_CONTAINER_SIZE / 2}px;
  color: ${colors.white};
`;
