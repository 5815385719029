import { colors } from "components/App/theme";
import {
  Container,
  Label,
  InformationRow,
  Value,
  InfosConatiner,
} from "./BoxOfInfos.styled";
import { InformationType } from "./types";

export function BoxOfInfos({
  information,
  backgroundColor = colors.greyBG,
}: {
  information: InformationType;
  backgroundColor?: string;
}) {
  return (
    <Container $backgroundColor={backgroundColor}>
      <InfosConatiner>
        {information.map((info, i) => (
          <InformationRow key={i}>
            <Label>{info.label}</Label>
            <Value>{info.value}</Value>
          </InformationRow>
        ))}
      </InfosConatiner>
    </Container>
  );
}
