import React, { useEffect } from "react";
import { OrbitControls } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { useCaptureCameraLocation } from "features/JobBuilder/CamerasAndLightsPreview/hooks/useCaptureCameraLocation";
import { getFOVsNumbers } from "features/JobBuilder/CamerasAndLightsPreview/utils/getFov";
import { getMeanMinMaxValue } from "features/JobBuilder/CamerasAndLightsPreview/utils/getMeanMinMaxValue";
import { JsonCamera, JsonRig } from "features/JobBuilder/json-types";

interface EnvironmentProps {
  capturingRig: JsonRig | undefined;
  capturingCamera: JsonCamera | undefined;
  isStagePreview?: boolean;
}

export function Environment({
  capturingCamera,
  capturingRig,
  isStagePreview,
}: EnvironmentProps) {
  const { gl, camera } = useThree((state) => state);

  const { cameraPosition, cameraRotation } = useCaptureCameraLocation(
    capturingRig,
    capturingCamera?.relative_location
  );

  useEffect(() => {
    gl.physicallyCorrectLights = true;
  }, [gl]);

  useEffect(() => {
    if (!cameraPosition || !cameraRotation || !capturingCamera) {
      if (isStagePreview) {
        // this for stage preview
        camera.position.set(1.5, 3, 1.5);
      } else {
        // this for world preview
        camera.position.set(5, 4, 0);
      }
    } else {
      // this for single camera view preview
      const { focal_length, sensor_width, resolution_h, resolution_w } =
        capturingCamera.specifications;

      // position is readonly in the camera object. To change the camera position, you need to call the "copy" function.
      camera.position.copy(cameraPosition);
      camera.rotation.copy(cameraRotation);
      (camera as any).setFocalLength(getMeanMinMaxValue(focal_length).mean);

      const { verticalFOV } = getFOVsNumbers(
        resolution_w,
        resolution_h,
        sensor_width,
        focal_length
      );

      (camera as any).fov = verticalFOV;
    }
    camera.updateProjectionMatrix();
  }, [camera, cameraPosition, cameraRotation, capturingCamera, isStagePreview]);

  return (
    <>
      <gridHelper args={[30, 30]} />
      {!cameraPosition && !cameraRotation && (
        <OrbitControls target={[0, isStagePreview ? 1.5 : 2, 0]} />
      )}
    </>
  );
}
