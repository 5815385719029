import React from "react";
import { colors } from "components/App/theme";

type ErrorProps = {
  children?: React.ReactNode;
};

export function Error({ children }: ErrorProps) {
  const visible = !!children;

  return (
    <div
      aria-live="polite"
      style={{
        color: colors.red,
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "16px",
        position: "absolute",
        bottom: "5px",
        left: "0",
        visibility: visible ? "visible" : "hidden",
        opacity: visible ? 1 : 0,
        transform: visible ? "translateY(0)" : "translateY(8px)",
        transition: "all 0.2s ease-in-out",
      }}
    >
      {children}{" "}
    </div>
  );
}
