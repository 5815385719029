import React from "react";
import { Grid, Box } from "@mui/material";
import { PaymentInfo, Mode } from "components/PaymentInfo";
import { useOrgParam } from "hooks/useOrgParam";

export function AddPaymentInfo() {
  const { orgParam } = useOrgParam();

  return (
    <Grid container justifyContent="center">
      <Grid item md={8} xs={12}>
        <Box component="div" display="flex" justifyContent="center">
          <PaymentInfo mode={Mode.AddAndOfferPO} orgName={orgParam} />
        </Box>
      </Grid>
    </Grid>
  );
}
