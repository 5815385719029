import { useCallback } from "react";
import produce from "immer";
import { defaultCamera } from "domain/Human";
import { wavelengthOptions } from "features/JobBuilder2/domain";
import { CameraCoreProps } from "features/JobBuilderShared/Camera/core";
import { CameraTitle } from "features/JobBuilderShared/Camera/title";
import { Layout } from "./layout";

export function Camera2({
  onUpdate,
  value,
}: {
  onUpdate: (camera: typeof defaultCamera) => void;
  value: typeof defaultCamera;
}) {
  const onUpdateLocal = useCallback(
    (fn: (draft: typeof defaultCamera) => void) => {
      onUpdate(produce(value, fn));
    },
    [onUpdate, value]
  );

  const onUpdateName = (v: string) =>
    onUpdateLocal((d) => {
      d.name = v;
    });

  return (
    <Layout
      title={<CameraTitle value={value.name} onUpdateName={onUpdateName} />}
    >
      <CameraCoreProps
        hasNIR={false}
        rigType="preset_location2"
        showNormalDistribution={false}
        value={value}
        wavelengthOptions={wavelengthOptions}
        onUpdateLocal={onUpdateLocal}
      />
    </Layout>
  );
}
