import React, { useCallback, useState } from "react";
import produce from "immer";
import { AddIcon, DeleteIcon } from "assets/icons";
import { colors } from "components/App/theme";
import { DeleteConfirmationDialog } from "components/dialogs/DeleteConfirmationDialog";
import { defaultRig } from "domain/Human";
import { IconizedAdditionalInfo } from "features/JobBuilder/LeftSidebar/IconizedAdditionalInfo";
import { InfoWithIcon } from "features/JobBuilder/LeftSidebar/IconizedAdditionalInfo/types";
import { ReverseTreeItem } from "features/JobBuilder/LeftSidebar/left-sidebar.styled";
import { SectionLabel } from "features/JobBuilder/LeftSidebar/SectionLabel";
import { expandedKeyword } from "features/JobBuilder/LeftSidebar/useExpandedNodes";
import {
  selectActiveSubjobCameraAndLightForAllRigs,
  selectActiveSubjobRigs,
  selectSelectedHumanObjectPart,
  selectSelectedRigIndex,
} from "features/JobBuilder/store";
import {
  getRigTotalCameras,
  getRigTotalLights,
} from "features/JobBuilder/utils";
import { useBoundStore } from "store/_boundStore";
import { IconsType } from "types/main";
import { compact } from "utils/stringUtil";
import { CameraAndLightForRig } from "./CameraAndLightForRig";
const getIconColor = (selected: boolean) =>
  selected ? colors.orange : colors.grey70;
export function SidebarRigs() {
  const {
    setSelectedHumanObjectPart: selectHumanObjectPart,
    setSelectedRigIndex: selectRig,
    deleteRig: onDeleteRig,
    addRig: onAddRig,
    updateRig: onUpdateRig,
  } = useBoundStore.getState().jobBuilder;

  const cameraAndLightNames = useBoundStore(
    selectActiveSubjobCameraAndLightForAllRigs
  );

  const selectedRigIndex = useBoundStore(selectSelectedRigIndex);
  const selectedRig = useBoundStore(selectActiveSubjobRigs)?.[selectedRigIndex];

  const selectedHumanObjectPart = useBoundStore(selectSelectedHumanObjectPart);

  const onUpdateLocal = useCallback(
    (fn: (draft: typeof defaultRig) => void) => {
      onUpdateRig(produce(selectedRig, fn));
    },
    [onUpdateRig, selectedRig]
  );

  const onDeleteCamera = (i: number) =>
    onUpdateLocal((d) => {
      d.cameras.splice(i, 1);
    });

  const onDeleteLight = (i: number) =>
    onUpdateLocal((d) => {
      d.lights.splice(i, 1);
    });

  const onRigItemSelect = useCallback(
    (i: number, itemID: string) => {
      selectRig(i);
      const section = document.querySelector(`#${itemID}`);
      if (!section) {
        return;
      }
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    },
    [selectRig]
  );

  const [rigForDeletion, setRigForDeletion] = useState(-1);

  const onClickAddRig = useCallback(
    (e: React.MouseEvent<any>) => {
      e.preventDefault();
      e.stopPropagation();
      onAddRig();
      selectHumanObjectPart("rigs");
    },
    [onAddRig, selectHumanObjectPart]
  );

  const onClickDeleteRig = useCallback(
    (e: React.MouseEvent<any>, rigIndex: number) => {
      e.preventDefault();
      e.stopPropagation();
      selectHumanObjectPart("rigs");
      onDeleteRig(rigIndex);
    },
    [onDeleteRig, selectHumanObjectPart]
  );

  const onSelectRig = useCallback(
    (index: number) => {
      selectHumanObjectPart("rigs");
      selectRig(index);
    },
    [selectRig, selectHumanObjectPart]
  );

  const onSelectRigItem = (index: number, itemId: string) => {
    onRigItemSelect(index, itemId);
    selectHumanObjectPart("rigs");
  };

  const allowRigDeletion = cameraAndLightNames.length > 1;

  const allCamerasAndLights = cameraAndLightNames.map(
    (cameraAndLightNamesForRig) => {
      return cameraAndLightNamesForRig;
    }
  );

  const totalCameras = allCamerasAndLights.reduce(
    (accumulator, rig) => accumulator + getRigTotalCameras(rig),
    0
  );

  const totalLights = allCamerasAndLights.reduce(
    (accumulator, rig) => accumulator + getRigTotalLights(rig),
    0
  );

  const camerasAndLightsInfoArray: InfoWithIcon[] = [
    {
      icon: "RigIcon",
      label: compact(cameraAndLightNames.length),
      tooltipText: "Rigs",
    },
    {
      icon: "CameraIcon",
      label: compact(totalCameras),
      tooltipText: "Cameras",
    },
    {
      icon: "RectLightIcon",
      label: compact(totalLights),
      tooltipText: "Lights",
    },
  ];

  const AddRigIcon = (
    <AddIcon
      style={{
        color: getIconColor(selectedHumanObjectPart === "rigs"),
      }}
      onClick={onClickAddRig}
    />
  );

  const DeleteRigIcon = useCallback(
    (rigIndex: number) => {
      const canDelete = rigIndex > 0 && allowRigDeletion;
      return (
        <DeleteIcon
          style={{
            color: canDelete ? colors.red : "#f9afaf",
            cursor: canDelete ? "pointer" : "not-allowed",
          }}
          onClick={() => {
            if (canDelete) {
              setRigForDeletion(rigIndex);
            }
          }}
        />
      );
    },
    [allowRigDeletion]
  );

  const showDeleteConfirmationDialog = rigForDeletion !== -1;
  const rigHumanText = `Rig ${rigForDeletion + 1}`;
  const deleteRigTextContent = `Are you sure you want to delete ${rigHumanText}? If you delete this you will lose the data.`;

  const onCancelRigDeletionCallback = () => {
    setRigForDeletion(-1);
  };

  const onConfirmRigDeletionCallback = (e: React.MouseEvent<any>) => {
    const canDelete =
      rigForDeletion !== 0 && cameraAndLightNames.length > rigForDeletion;

    if (canDelete) {
      onClickDeleteRig(e, rigForDeletion);
    }
    setRigForDeletion(-1);
  };

  return (
    <ReverseTreeItem
      label={
        <SectionLabel
          additionalInfo={
            <IconizedAdditionalInfo
              info={camerasAndLightsInfoArray}
              selected={selectedHumanObjectPart === "rigs"}
            />
          }
          icon={"CameraAndLightIcon"}
          label="Cameras & Lights"
          selected={selectedHumanObjectPart === "rigs"}
        />
      }
      nodeId="rigs"
      onClick={() => selectHumanObjectPart("rigs")}
    >
      <ReverseTreeItem
        collapseIcon={AddRigIcon}
        expandIcon={AddRigIcon}
        label={
          <SectionLabel
            icon={"RigIcon"}
            label="Rigs"
            selected={selectedHumanObjectPart === "rigs"}
            variant="subsection"
          />
        }
        nodeId={`rigs${expandedKeyword}`}
        onClick={() => selectHumanObjectPart("rigs")}
      >
        {showDeleteConfirmationDialog && (
          <DeleteConfirmationDialog
            textContent={deleteRigTextContent}
            onCancel={onCancelRigDeletionCallback}
            onConfirm={onConfirmRigDeletionCallback}
          />
        )}
        {cameraAndLightNames.map((cameraAndLightNamesForRig, i) => {
          return (
            <ReverseTreeItem
              key={i}
              collapseIcon={DeleteRigIcon(i)}
              expandIcon={DeleteRigIcon(i)}
              label={
                <SectionLabel
                  additionalInfo={
                    <IconizedAdditionalInfo
                      info={[
                        {
                          icon: "CameraIcon" as IconsType,
                          label: compact(
                            getRigTotalCameras(cameraAndLightNamesForRig)
                          ),
                        },
                        {
                          icon: "RectLightIcon" as IconsType,
                          label: compact(
                            getRigTotalLights(cameraAndLightNamesForRig)
                          ),
                        },
                      ]}
                      selected={
                        selectedHumanObjectPart === "rigs" &&
                        selectedRigIndex === i
                      }
                    />
                  }
                  icon={"RigIcon"}
                  label={`Rig ${i + 1}`}
                  selected={
                    selectedHumanObjectPart === "rigs" && selectedRigIndex === i
                  }
                />
              }
              nodeId={`single-rig-${i}`}
              onClick={() => onSelectRig(i)}
            >
              {cameraAndLightNamesForRig.map((part, j) => {
                const nodeId = `rigs-${i}${j}`;
                const isSelectedRig = selectedRigIndex === i;

                return (
                  <CameraAndLightForRig
                    key={j}
                    isSelectedRig={isSelectedRig}
                    nodeId={nodeId}
                    rigPart={part}
                    selectedHumanObjectPart={selectedHumanObjectPart}
                    onDeleteCameraCallback={onDeleteCamera}
                    onDeleteLightCallback={onDeleteLight}
                    onSelectRigItemCallback={() =>
                      onSelectRigItem(i, part.name)
                    }
                  />
                );
              })}
            </ReverseTreeItem>
          );
        })}
      </ReverseTreeItem>
    </ReverseTreeItem>
  );
}
