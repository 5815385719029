import { IconContainer } from "./icons.styled";

export function AddIcon({
  fill = "currentColor",
  style = { height: "24px" },
  onClick,
}: {
  style?: React.CSSProperties;
  fill?: string;
  onClick?: (e: React.MouseEvent<any>) => void;
}) {
  return (
    <IconContainer onClick={onClick}>
      <svg
        fill="none"
        height="24"
        style={style}
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 12.5H12.5V13V18C12.5 18.2739 12.2739 18.5 12 18.5C11.7261 18.5 11.5 18.2739 11.5 18V13V12.5H11H6C5.72614 12.5 5.5 12.2739 5.5 12C5.5 11.7261 5.72614 11.5 6 11.5H11H11.5V11V6C11.5 5.72614 11.7261 5.5 12 5.5C12.2739 5.5 12.5 5.72614 12.5 6V11V11.5H13H18C18.2739 11.5 18.5 11.7261 18.5 12C18.5 12.2739 18.2739 12.5 18 12.5H13Z"
          fill={fill}
          stroke={fill}
        />
      </svg>
    </IconContainer>
  );
}
