import { Box, Button } from "@mui/material";
import styled, { css } from "styled-components";
import { colors } from "components/App/theme";

export const EnvironmentSelectionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  margin-top: 16px;
`;

export const GridContainer = styled(Box)`
  margin: 16px 0;
  max-height: 800px;
  overflow-y: scroll;
`;

export const StyledButton = styled(Button)<{ $isSelected: boolean }>`
  font-weight: 600;
  ${({ $isSelected }) =>
    $isSelected
      ? css`
          &.Mui-disabled {
            border: 1px solid ${colors.orange};
            color: ${colors.orange};
            background: ${colors.tint5};
          }
        `
      : css`
          border: 1px solid ${colors.grey50};
          color: ${colors.grey70};
        `};
`;
