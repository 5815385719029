export function CheckBoxIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="26"
      style={style}
      viewBox="0 0 26 26"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill={fill}
        height="25"
        rx="4.5"
        stroke="white"
        width="25"
        x="0.5"
        y="0.5"
      />
      <path
        d="M10.8634 15.5831L8.55004 13.2698C8.29004 13.0098 7.87004 13.0098 7.61004 13.2698C7.35004 13.5298 7.35004 13.9498 7.61004 14.2098L10.3967 16.9964C10.6567 17.2564 11.0767 17.2564 11.3367 16.9964L18.39 9.94311C18.65 9.68311 18.65 9.26311 18.39 9.00311C18.13 8.74311 17.71 8.74311 17.45 9.00311L10.8634 15.5831Z"
        fill="white"
      />
    </svg>
  );
}
