import * as Sentry from "@sentry/react";
import { restClient } from "services/restClient/main";
import { GetState, SetState } from "store/_boundStore";
import { getUserIdFromToken } from "utils/jwtUtils";

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1])) as {
      exp: number;
      sub: string;
      id: number;
    };
  } catch (error) {
    return null;
  }
};

export function createActions(set: SetState, get: GetState, initialState: any) {
  return {
    login: async (username: string, password: string) => {
      try {
        set((state) => {
          state.auth.loading = true;
          state.auth.authenticated = false;
          state.auth.error = "";
        });
        const response = await restClient.login(username, password);
        const userId = getUserIdFromToken(response.token);
        set((state) => {
          state.auth.authenticated = true;
          state.auth.loading = false;
          state.auth.token = response.token;
        });
        if (userId) {
          const profileResponse =
            await restClient.getUserProfileWithNoDefaultConfig(
              userId,
              response.token
            );
          window.analytics.identify(userId, {
            email: profileResponse.email,
            first_name: profileResponse.first_name,
            last_name: profileResponse.last_name,
          });
        }
      } catch (error) {
        const errorMessage = restClient.APIErrorMessage(error);
        Sentry.captureException(errorMessage);
        set((state) => {
          state.auth.authenticated = false;
          state.auth.loading = false;
          state.auth.error = errorMessage;
          state.auth.token = "";
        });
      }
    },

    logout: (error: string) => {
      window.analytics.track("Signed Out");
      set((state) => {
        state.auth = { ...state.auth, ...initialState, error };
      });
      get().org.clearState();
      get().profile.clearState();
    },

    getCurrentUserId() {
      const token = get().auth.token;
      if (!token) {
        return null;
      }
      const payload = parseJwt(token);
      return payload ? payload.sub : null;
    },
  };
}
