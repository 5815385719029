import { generateInputJson } from "features/JobBuilder/form-to-input-json";

export function toCharactersInputJSON(
  pipe1JobSpec: ReturnType<typeof generateInputJson>
) {
  const { humans } = pipe1JobSpec;

  if (!humans) {
    return null;
  }

  const characters = humans.map((human) => {
    return {
      ...human,
      "3d_location": undefined,
      animations: undefined,
      camera_and_light_rigs: undefined,
      environment: undefined,
      hdri: undefined,
      rigs: undefined,
      threeDLocation: undefined,
      skin: undefined,
      facial_attributes: {
        ...human.facial_attributes,
        gaze: undefined,
        headTurn: undefined,
      },
    };
  });

  return {
    humans: characters,
  };
}
