import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { Messages } from "constants/messages";
import { Routes } from "constants/routes";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { validateEmail } from "utils/validators";

function ResendActivationEmail() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailValidationError, setEmailValidationError] = useState("");
  const { setErrorMessage, setSuccessMessage } =
    useBoundStore.getState().message;
  const navigate = useNavigate();

  const validate = () => {
    const validationResult = validateEmail(email);
    if (!validationResult.valid) {
      setEmailValidationError(validationResult.message || "Invalid");
    } else {
      setEmailValidationError("");
    }
    return validationResult.valid;
  };

  const sendActivationEmail = async (e: React.MouseEvent) => {
    if (!validate()) {
      e.preventDefault();
      return;
    }
    setLoading(true);
    try {
      await restClient.resendVerification(email.toLowerCase(), {});
      setSuccessMessage(Messages.checkVerificationEmail);
      navigate(Routes.ROOT);
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <FullScreenProgress />}
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          <Box component="div" mb={5}>
            <Typography variant="h1">Resend Verification Email</Typography>
          </Box>
          <Typography variant="body2">
            Enter your email address below and we will send an email with a
            verification link.
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          <Box component="div" mt={4}>
            <TextField
              autoFocus
              fullWidth
              required
              error={!!emailValidationError}
              helperText={emailValidationError}
              id="email"
              label="Email address"
              size="small"
              type="text"
              value={email}
              variant="outlined"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailValidationError("");
              }}
            />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box component="div" mt={2}>
                <Button
                  fullWidth
                  color="warning"
                  variant="contained"
                  onClick={sendActivationEmail}
                >
                  Send verification email
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export { ResendActivationEmail };
