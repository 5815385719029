import React, { useState } from "react";
import { CheckBoxIcon } from "assets/icons";
import "react-indiana-drag-scroll/dist/style.css";
import { colors } from "components/App/theme";
import {
  StyledImage,
  StyledList,
  StyledListItem,
  StyledListItemButton,
  IconContainer,
  ImageAndIconContainer,
  StyledVideo,
  Spacer,
} from "./ImageGrid.styled";

export type ImageGridImage = {
  key?: string;
  src: string;
  alt?: string;
  label?: React.ReactNode;
  videoSrc?: string;
  disabled?: boolean;
  sideInfo?: string;
  isSelected: boolean;
};

export type ImageGridViewMode = "grid" | "horizontalList";

export function ImageGrid({
  images,
  onSelect,
  dynamicSize,
  viewMode = "grid",
}: {
  images: ImageGridImage[];
  onSelect: (i: number) => void;
  dynamicSize?: boolean;
  viewMode?: ImageGridViewMode;
}) {
  const [lastHoveredImageIndex, setLastHoveredImageIndex] = useState(-1);

  const handleImageHover = (i: number) => () => {
    if (!images[i].videoSrc) {
      setLastHoveredImageIndex(-1);
    } else {
      setLastHoveredImageIndex(i);
    }
  };

  const handleMouseLeave = () => {
    setLastHoveredImageIndex(-1);
  };

  return (
    <StyledList
      $hasDynamicSize={dynamicSize}
      $horizontalView={viewMode === "horizontalList"}
    >
      {images.map((imageSettings, index) => {
        const {
          key,
          src,
          alt,
          label,
          videoSrc,
          disabled,
          isSelected,
          sideInfo,
        } = imageSettings;

        return (
          <StyledListItem
            key={key}
            disablePadding
            $horizontalView={viewMode === "horizontalList"}
          >
            <StyledListItemButton
              dense
              $hasDynamicSize={dynamicSize}
              $isDisabled={!!disabled}
              $isSelected={isSelected}
              onClick={() => !disabled && onSelect(index)}
            >
              <ImageAndIconContainer
                onMouseEnter={handleImageHover(index)}
                onMouseLeave={handleMouseLeave}
              >
                {isSelected && (
                  <IconContainer>
                    <CheckBoxIcon fill={colors.orange} />
                  </IconContainer>
                )}
                {lastHoveredImageIndex === index && videoSrc && (
                  <StyledVideo autoPlay loop muted playsInline>
                    <source src={videoSrc} type="video/mp4" />
                  </StyledVideo>
                )}
                <StyledImage
                  $hasDynamicSize={dynamicSize}
                  alt={alt}
                  loading="lazy"
                  src={src}
                />
                {dynamicSize && <Spacer />}
              </ImageAndIconContainer>
              {label} {sideInfo && `(${sideInfo})`}
            </StyledListItemButton>
          </StyledListItem>
        );
      })}
    </StyledList>
  );
}
