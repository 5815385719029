export function YawIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 8.5H14.5V8V6.5C14.5 4.84614 15.8461 3.5 17.5 3.5C19.1539 3.5 20.5 4.84614 20.5 6.5C20.5 8.15386 19.1539 9.5 17.5 9.5H16H15.5V10V14V14.5H16H17.5C19.1539 14.5 20.5 15.8461 20.5 17.5C20.5 19.1539 19.1539 20.5 17.5 20.5C15.8461 20.5 14.5 19.1539 14.5 17.5V16V15.5H14H10H9.5V16V17.5C9.5 19.1539 8.15386 20.5 6.5 20.5C4.84614 20.5 3.5 19.1539 3.5 17.5C3.5 15.8461 4.84614 14.5 6.5 14.5H8H8.5V14V10V9.5H8H6.5C4.84614 9.5 3.5 8.15386 3.5 6.5C3.5 4.84614 4.84614 3.5 6.5 3.5C8.15386 3.5 9.5 4.84614 9.5 6.5V8V8.5H10H14ZM15.5 8V8.5H16H17.5C18.6061 8.5 19.5 7.60614 19.5 6.5C19.5 5.39386 18.6061 4.5 17.5 4.5C16.3939 4.5 15.5 5.39386 15.5 6.5V8ZM8 8.5H8.5V8V6.5C8.5 5.39386 7.60614 4.5 6.5 4.5C5.39386 4.5 4.5 5.39386 4.5 6.5C4.5 7.60614 5.39386 8.5 6.5 8.5H8ZM9.5 14V14.5H10H14H14.5V14V10V9.5H14H10H9.5V10V14ZM16 15.5H15.5V16V17.5C15.5 18.6061 16.3939 19.5 17.5 19.5C18.6061 19.5 19.5 18.6061 19.5 17.5C19.5 16.3939 18.6061 15.5 17.5 15.5H16ZM8.5 16V15.5H8H6.5C5.39386 15.5 4.5 16.3939 4.5 17.5C4.5 18.6061 5.39386 19.5 6.5 19.5C7.60614 19.5 8.5 18.6061 8.5 17.5V16Z"
        fill={fill}
        stroke={fill}
      />
    </svg>
  );
}
