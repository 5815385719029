// types
import { Geom2 } from "@flasd/modeling/src/geometries/types";
import {
  ActivitiesState,
  defaultSubjob,
  getNewPipe2Subjob,
  Pipe2StepEnum,
} from "features/JobBuilder2/types";
import { SliceType } from "store/_boundStore";
import { createActions } from "./actions";

export * from "./selectors";

type State = {
  subjobs: (typeof defaultSubjob)[];
  pickedSubjobIndex: number;
  selectedStep: Pipe2StepEnum;
  isCameraRigDialogOpened: boolean;
  activitiesPickerDialogData: ActivitiesState | null;
  pickedActivityZoneIndex: number;
  isCharacterGroupDialogOpened: boolean;
  showHelpDialog: boolean;
  hoveredActivityZone: number;
  jobName: string;
};

type Actions = {
  setPickedSubjobIndex: (subjobIndex: number) => void;
  setSubjobs: (subjobs: (typeof defaultSubjob)[]) => void;
  updatePickedSubjob: (subjobData: typeof defaultSubjob) => void;
  addNewSubjob: () => void;
  deleteSubjob: (subjobIndex: number) => void;
  duplicateSubjob: (subjobIndex: number) => void;
  setSelectedStep: (step: Pipe2StepEnum | number) => void;
  goToNextStep: () => void;
  goToPrevStep: () => void;
  setIsCameraRigDialogOpened: (opened: boolean) => void;
  setSelectedRigLocation: (index: number, callback?: () => void) => void;
  onGeomDrawn: (geom: Geom2) => void;
  onRemoveActivityZone: (i: number) => void;
  setActivitiesPickerDialogData: (data: ActivitiesState | null) => void;
  updateSelectedActivitiesOfZone: (activities: string[]) => void;
  setIsCharacterGroupDialogOpened: (
    opened: boolean,
    pickedZoneIndex?: number
  ) => void;
  updateSelectedPrefabGroupOfZone: (prefabGroupId: string) => void;
  setShowHelpDialog: (flag: boolean) => void;
  setHoveredActivityZone: (hoveredActivityZone: number) => void;
  updateJobName: (jobName: string) => void;
  clearState: () => void;
};

const initialState: State = {
  subjobs: [getNewPipe2Subjob()],
  pickedSubjobIndex: 0,
  showHelpDialog: false,
  // navigations
  selectedStep: Pipe2StepEnum.Location,
  // Steps Related Functions
  pickedActivityZoneIndex: 0,

  // 2nd step: Set rig location
  isCameraRigDialogOpened: false,
  // 4th Step: Assign activities
  activitiesPickerDialogData: null,
  // 5th Step: Assign Character Group
  isCharacterGroupDialogOpened: false,

  hoveredActivityZone: -1,
  jobName: "",
};

export type JobBuilder2Slice = State & Actions;

export const createJobBuilder2Slice: SliceType<JobBuilder2Slice> = (
  set,
  get
) => ({
  ...initialState,
  ...createActions(set, get, initialState),
});
