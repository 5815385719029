export function NoSearchResultFoundImage() {
  return (
    <svg
      fill="none"
      height="100"
      viewBox="0 0 100 100"
      width="100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50 4.29688C23.5723 4.29688 2.14844 25.7207 2.14844 52.1484C2.14844 78.5762 23.5723 100 50 100C63.5588 100 75.799 94.3594 84.5061 85.2984L84.2074 84.7236L70.2281 70.7445C69.5428 70.0592 69.5428 68.9439 70.2281 68.2586L75.8568 62.6299C76.1889 62.2979 76.6303 62.115 77.0998 62.115C77.5693 62.115 78.0107 62.298 78.3428 62.6299L91.3051 75.5922L91.6709 75.6818C95.6041 68.7326 97.8516 60.7035 97.8516 52.1484C97.8516 25.7207 76.4277 4.29688 50 4.29688V4.29688Z"
        fill="#FDF1E9"
      />
      <path
        d="M50 86.7188C69.0927 86.7188 84.5703 71.2411 84.5703 52.1484C84.5703 33.0558 69.0927 17.5781 50 17.5781C30.9073 17.5781 15.4297 33.0558 15.4297 52.1484C15.4297 71.2411 30.9073 86.7188 50 86.7188Z"
        fill="#FCE3D2"
      />
      <path
        d="M75.4895 62.9969L68.3898 55.8972C66.9965 57.7494 65.3473 59.3986 63.4951 60.792L70.5947 67.8916L75.4895 62.9969Z"
        fill="#8D9CA8"
      />
      <path
        d="M72.6549 65.8312L65.7232 58.8996C65.0191 59.5732 64.2752 60.2051 63.4951 60.7918L70.5947 67.8914L72.6549 65.8312Z"
        fill="#7A8C98"
      />
      <path
        d="M91.6705 75.6818L78.4804 62.4918C77.7177 61.7291 76.481 61.7291 75.7183 62.4918L70.0896 68.1205C69.3269 68.8832 69.3269 70.1199 70.0896 70.8826L84.5056 85.2986C87.2757 82.4156 89.6873 79.1861 91.6705 75.6818Z"
        fill="#596C76"
      />
      <path
        d="M76.2701 70.8826C75.5074 70.1199 75.5074 68.8832 76.2701 68.1205L80.1896 64.201L78.4804 62.4918C77.7177 61.7291 76.481 61.7291 75.7183 62.4918L70.0896 68.1205C69.3269 68.8832 69.3269 70.1199 70.0896 70.8826L84.5056 85.2986C85.5171 84.2461 86.4794 83.1461 87.3919 82.0045L76.2701 70.8826Z"
        fill="#465A61"
      />
      <path
        d="M48.7805 65.9043C62.434 65.9043 73.5023 54.8359 73.5023 41.1824C73.5023 27.5289 62.434 16.4605 48.7805 16.4605C35.127 16.4605 24.0586 27.5289 24.0586 41.1824C24.0586 54.8359 35.127 65.9043 48.7805 65.9043Z"
        fill="#EE741F"
      />
      <path
        d="M29.0717 41.1824C29.0717 28.375 38.8107 17.8426 51.2869 16.5863C50.4627 16.5033 49.6266 16.4605 48.7805 16.4605C35.127 16.4605 24.0586 27.5289 24.0586 41.1824C24.0586 54.8359 35.127 65.9043 48.7805 65.9043C49.6266 65.9043 50.4627 65.8615 51.2869 65.7785C38.8107 64.5221 29.0717 53.9896 29.0717 41.1824V41.1824Z"
        fill="#DB6B1D"
      />
      <path
        d="M48.7809 58.4877C58.3383 58.4877 66.0861 50.7398 66.0861 41.1824C66.0861 31.625 58.3383 23.8771 48.7809 23.8771C39.2234 23.8771 31.4756 31.625 31.4756 41.1824C31.4756 50.7398 39.2234 58.4877 48.7809 58.4877Z"
        fill="#C8EFFE"
      />
      <path
        d="M39.8027 41.1824C39.8027 33.0607 45.398 26.2469 52.9439 24.3828C51.61 24.0533 50.2156 23.8773 48.7799 23.8773C39.2225 23.8773 31.4746 31.6252 31.4746 41.1826C31.4746 50.74 39.2225 58.4879 48.7799 58.4879C50.2156 58.4879 51.61 58.3119 52.9439 57.9824C45.3982 56.118 39.8027 49.3041 39.8027 41.1824V41.1824Z"
        fill="#99E6FC"
      />
    </svg>
  );
}
