import React from "react";
import { Button } from "@mui/material";
import { FooterActionsWrapper, FooterWrapper } from "./JobBuilderFooter.styled";

type JobBuilderFooterProps = {
  draftElement?: React.ReactNode;
  isFormValid: boolean;
  onSubmitClick: () => void;
  onShowInputJsonClick?: () => void;
  onNextClick?: () => void;
  canGoNext?: () => boolean;
  onBackClick?: () => void;
  canGoBack?: () => boolean;
};

export function JobBuilderFooter(props: JobBuilderFooterProps) {
  const {
    draftElement,
    isFormValid,
    onSubmitClick,
    onShowInputJsonClick,
    onNextClick,
    canGoNext,
    onBackClick,
    canGoBack,
  } = props;

  return (
    <FooterWrapper>
      {draftElement}
      <FooterActionsWrapper>
        {onShowInputJsonClick && (
          <Button
            color="primary"
            disabled={!isFormValid}
            size="small"
            style={{ float: "right" }}
            sx={{ mr: 1 }}
            variant="contained"
            onClick={onShowInputJsonClick}
          >
            Show input json
          </Button>
        )}
        {onBackClick && (
          <Button
            color="warning"
            disabled={canGoBack && !canGoBack()}
            size="small"
            style={{ float: "right" }}
            sx={{ mr: 1 }}
            variant="outlined"
            onClick={onBackClick}
          >
            Back
          </Button>
        )}
        {onNextClick && (
          <Button
            color="warning"
            disabled={canGoNext && !canGoNext()}
            size="small"
            style={{ float: "right" }}
            sx={{ mr: 2 }}
            variant="outlined"
            onClick={onNextClick}
          >
            Next
          </Button>
        )}
        <Button
          color="warning"
          disabled={!isFormValid}
          size="small"
          style={{ float: "right" }}
          variant="contained"
          onClick={onSubmitClick}
        >
          Submit job
        </Button>
      </FooterActionsWrapper>
    </FooterWrapper>
  );
}
