import { Box, Divider, Typography } from "@mui/material";
import { ReactComponent as ActivitiesIllustration } from "features/JobBuilder2/steps/define-activity-zone/assets/activities.svg";
import { ReactComponent as ActivityZonesIllustration } from "features/JobBuilder2/steps/define-activity-zone/assets/activity-zones.svg";
import { ReactComponent as CharactersIllustration } from "features/JobBuilder2/steps/define-activity-zone/assets/characters.svg";
import { ReactComponent as HumanInZoneIllustration } from "features/JobBuilder2/steps/define-activity-zone/assets/human-in-zone.svg";
import { ReactComponent as HumansInZonesIllustration } from "features/JobBuilder2/steps/define-activity-zone/assets/humans-in-zones.svg";
import { BoxedNumber } from "./BoxedNumber";

export function ActivityZones() {
  return (
    <Box component="div">
      {/* Step 4 */}
      <Box component="div" mb={4}>
        <Box alignItems="center" component="div" display="flex" mb={3}>
          <BoxedNumber step={4} />
          <Typography flex="1" ml={2} variant="body1">
            Every simulation rendered, a random activity and random human is
            selected for each activity zone.
          </Typography>
        </Box>
        <Box component="div" display="flex" sx={{ textAlign: "center" }}>
          <Box component="div" flex={1}>
            <Box component="div" mb={1}>
              <HumanInZoneIllustration />
            </Box>
            <Box component="div" sx={{ maxWidth: "300px", margin: "0 auto" }}>
              Every Activity Zone can have 1 human and 1 activity per
              simulation.
            </Box>
          </Box>
          <Box component="div" flex={1}>
            <Box component="div" mb={1}>
              <HumansInZonesIllustration />
            </Box>
            <Box component="div" sx={{ maxWidth: "238px", margin: "0 auto" }}>
              To get 3 humans in a scene, you need 3 activity zones.
            </Box>
          </Box>
        </Box>
      </Box>

      <Divider sx={{ mb: 4 }} />

      <Box component="div">
        <Box alignItems="flex-start" component="div" display="flex" mb={3}>
          <BoxedNumber step={5} />
          <Box component="div" flex="1" ml={2}>
            <Typography mb={3} variant="body1">
              Steps to accomplish this:
            </Typography>
            <Box component="div" display="flex">
              <Box component="div" flex={1} mr={4}>
                <BoxedNumber secondary step={"a"} />
                <Box component="div" mt={1}>
                  <Box component="div" mb={1}>
                    <ActivityZonesIllustration />
                  </Box>
                  <Typography variant="body1">
                    <strong>Define Activity Zones</strong>
                  </Typography>
                  <Typography variant="body1">
                    The location is then divided into regions where activites
                    can take place.
                  </Typography>
                </Box>
              </Box>

              <Box component="div" flex={1} mr={4}>
                <BoxedNumber secondary step={"b"} />
                <Box component="div" mt={1}>
                  <Box component="div" mb={1}>
                    <ActivitiesIllustration />
                  </Box>
                  <Typography variant="body1">
                    <strong>Assign Activities</strong>
                  </Typography>
                  <Typography variant="body1">
                    Each activity zone can be assigned various activities that
                    the characters will perform.
                  </Typography>
                </Box>
              </Box>

              <Box component="div" flex={1}>
                <BoxedNumber secondary step={"c"} />
                <Box component="div" mt={1}>
                  <Box component="div" mb={1}>
                    <CharactersIllustration />
                  </Box>
                  <Typography variant="body1">
                    <strong>Assign Characters</strong>
                  </Typography>
                  <Typography variant="body1">
                    Each activity zone can be assigned character groups that
                    will perform those activities.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
