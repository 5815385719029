import React, { useState } from "react";
import {
  Portrait as CharactersIcon,
  MoreVert,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { Button, Menu, MenuItem, SxProps } from "@mui/material";
import { ConfirmationDialog } from "components/dialogs/ConfirmationDialog";
import { ReactComponent as ActivitiesIcon } from "features/JobBuilder2/assets/activities.svg";

const styles: Record<string, SxProps> = {
  menuItemStyling: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    padding: "10px 20px",
    color: "#5c656d",
    fill: "#5c656d",
    fontSize: "16px",
    fontWeight: 500,
    "& svg": {
      width: "30px",
    },
    "&:not(:first-of-type) svg": {
      fontSize: "25px",
    },
    "&:first-of-type svg": {
      fontSize: "20px",
    },
    "&:hover": {
      backgroundColor: "#fef0e9",
      color: "#f08f4c",
      fill: "#f08f4c",
    },
  },
};

interface ActivityZoneDropdownProps {
  isDeletable: boolean;
  isNoFittingActivities: boolean;
  onMenuToggle?: (opened: boolean) => void;
  onRemoveAreaClicked: () => void;
  onEditActivitiesClicked: () => void;
  onEditCharactersClicked: () => void;
  numberOfSelectedActivities: number;
  numberOfCharactersInSelectedGroup: number;
  hidden?: boolean;
}

export function ActivityZoneDropdown(props: ActivityZoneDropdownProps) {
  const {
    isDeletable,
    isNoFittingActivities,
    onMenuToggle,
    onRemoveAreaClicked,
    onEditActivitiesClicked,
    onEditCharactersClicked,
    numberOfSelectedActivities,
    numberOfCharactersInSelectedGroup,
    hidden = false,
  } = props;

  // handle the visiability of the menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (onMenuToggle) {
      onMenuToggle(true);
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(null);
    if (onMenuToggle) {
      onMenuToggle(false);
    }
  };

  // main functionality
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);

  return (
    <div>
      {isDeleteDialogOpened && (
        <ConfirmationDialog
          textContent={
            <>
              Are you sure you want to remove this activity zone? All
              configuration for this zone will be lost.
            </>
          }
          title="Remove activity zone?"
          onCancel={() => setIsDeleteDialogOpened(false)}
          onConfirm={() => {
            onRemoveAreaClicked();
            setIsDeleteDialogOpened(false);
          }}
        />
      )}

      {!hidden && (
        <Button
          aria-controls={open ? "basic-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          data-testid="activity-zone-dropdown"
          sx={{
            minWidth: 0,
            padding: 0,
          }}
          onClick={handleClick}
        >
          <MoreVert fontSize="small" htmlColor="white" />
        </Button>
      )}
      <Menu
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorEl}
        id="basic-menu"
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          sx={{
            ...styles.menuItemStyling,
            opacity: isNoFittingActivities ? 0.2 : 1,
          }}
          onClick={(e) => {
            handleClose(e);
            onEditActivitiesClicked();
          }}
        >
          <ActivitiesIcon /> Edit Available Activities (
          {numberOfSelectedActivities})
        </MenuItem>

        <MenuItem
          sx={styles.menuItemStyling}
          onClick={(e) => {
            handleClose(e);
            onEditCharactersClicked();
          }}
        >
          <CharactersIcon /> Edit Available Characters (
          {numberOfCharactersInSelectedGroup})
        </MenuItem>

        {isDeletable && (
          <MenuItem
            sx={styles.menuItemStyling}
            onClick={(e) => {
              setIsDeleteDialogOpened(true);
              handleClose(e);
            }}
          >
            <DeleteIcon /> Remove Activity Area
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
