import { ReactNode } from "react";
import { Box, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { colors } from "components/App/theme";
import { ReactComponent as UnionIcon } from "./Union.svg";

export function WizardLayout({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const onBackToDashboardClick = () => navigate(-1);
  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 2,
        flex: "1 1 100%",
        background: "F9F9F9",
      }}
    >
      <Box
        component="div"
        sx={{
          color: colors.black,
          mb: 2,
        }}
      >
        <Box
          component="div"
          sx={{
            cursor: "pointer",
            display: "flex",
          }}
          onClick={onBackToDashboardClick}
        >
          <Box
            component={UnionIcon}
            sx={{
              mr: 2,
              display: "inline",
            }}
          ></Box>
          <Typography
            display="inline"
            fontSize="14px"
            fontWeight={700}
            lineHeight="21px"
          >
            Back to Dashboard
          </Typography>
        </Box>
      </Box>
      {children}
    </Container>
  );
}
