import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const Container = styled(Box)<{
  $addBorderBottom: boolean;
  $withSpacing: boolean;
  $fitContentWidth: boolean;
}>`
  width: ${({ $fitContentWidth }) =>
    $fitContentWidth ? "fit-content" : "100%"};
  padding-bottom: ${({ $addBorderBottom }) => $addBorderBottom && "16px"};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  border-bottom: ${({ $addBorderBottom }) =>
    $addBorderBottom && `1px solid ${colors.grey20}`};
  ${({ $withSpacing }) =>
    $withSpacing
      ? `
    margin: 0 0 12px 0;
    padding-top: 8px;
  `
      : ""}
`;

export const Label = styled(Typography)`
  padding-left: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  line-clamp: 1;
  white-space: nowrap;
  color: ${colors.grey70};
`;

export const SubContainer = styled.div`
  display: flex;
  align-item: center;
  flex-shrink: 0;
`;
