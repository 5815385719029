import { Vec2 } from "@flasd/modeling/src/maths/vec2";
import { Vec3 } from "@flasd/modeling/src/maths/vec3";
import {
  defaultCamera,
  defaultLocation,
  defaultPresetTransform,
  LoRType,
  newLoR,
} from "domain/Human";
import { RenderQuality } from "./input-json-types";
import { GeomRegion, IGeomRegion } from "./steps/threeDimensional/GeomRegion";
import { ActivityStance } from "./steps/threeDimensional/utils/Location";

export enum Pipe2StepEnum {
  Location,
  RigLocation,
  ActivityZones,
  Activities,
  PrefabGroups,
  GlobalEnvironments,
  Distributions,
}

export enum FrameCutoffMode {
  static = "Manually Pick Last Frame",
  dynamic = "Dynamically Assign last frame by Activity Zone Animation",
}

export type ActivityCoordiantes = {
  x: number;
  z: number;
  rotation: number;
};

export const timesOfDay = [
  "Early Morning",
  "Late Morning",
  "Noon",
  "Sunset",
  "Night",
  "Overcast",
  // "Smokey",
] as const;

export const defaultGlobalEnv = {
  name: [...timesOfDay] as string[],
  intensity: newLoR(0, 1, 1),
  rotation: newLoR(0, 360, 0, "range"),
};

export const defaultDistributionConfig = {
  numberOfScenes: "",
  quality: RenderQuality.Default,
  frameIncrement: 6,
  frameCutoffMode: FrameCutoffMode.static,
  configs: {
    [FrameCutoffMode.static]: {
      lastFrameNumber: 1,
    },
    [FrameCutoffMode.dynamic]: {
      activitiesInAllScenesForSpecialZone: [] as string[],
      activityZoneIndex: "",
    },
  },
};
const defaultRigLocation = { ...defaultLocation, pitch: newLoR(-90, 90, 0) };
export const defaultRig = {
  ...defaultRigLocation,
  ...defaultPresetTransform,
  cameras: [defaultCamera],
};

export const defaultPipe2PresetTransform = {
  u: newLoR(0, 1, 0.5),
  v: newLoR(0, 1, 0.5),
};

export const defaultPipe2Camera = {
  ...defaultCamera,
  focalLength: newLoR(1, 300, 25),
};

export const defaultPipe2CameraWithName = (name: string) => {
  return { ...defaultPipe2Camera, name };
};

export const defaultPipe2Rig = {
  ...defaultRigLocation,
  ...defaultPipe2PresetTransform,
  cameras: [defaultPipe2Camera],
};

export type IActivityZone = {
  geomRegion: IGeomRegion;
  prefabGroupId: string;
  activities: string[];
};

export class ActivityZone implements IActivityZone {
  constructor(
    readonly geomRegion: GeomRegion,
    readonly prefabGroupId: string,
    readonly activities: string[]
  ) {}
}

export const defaultSubjob = {
  locationOptions: [] as string[],
  location: "",
  rigLocationIndex: -1,
  activityZones: [] as ActivityZone[],
  fullAnimation: true,
  globalEnv: defaultGlobalEnv,
  distribution: defaultDistributionConfig,
  rig: defaultRig,
};
export type ISubjob = Omit<typeof defaultSubjob, "activityZones"> & {
  activityZones: IActivityZone[];
};
export const getNewPipe2Subjob = (
  subjobPrototype: Partial<typeof defaultSubjob> = {}
) => {
  const { locationOptions } = subjobPrototype;

  return {
    ...defaultSubjob,
    ...subjobPrototype,
    locationOptions: locationOptions ? locationOptions : [],
    location: locationOptions ? locationOptions[0] : "",
    rig: { ...defaultPipe2Rig },
  };
};

// export type onPickPipe2TemplateFn = (name: string, json: TemplateJson) => void;
export type TemplateJson = {
  locations: string[];
};
export type Template = {
  name: string;
  json: TemplateJson;
};

type Pipe2Help = {
  proTip: string;
  Dialog?: JSX.Element;
};

export type Pipe2Step = {
  el: JSX.Element;
  label: string;
  completed: boolean;
  help?: Pipe2Help;
};
export const getPipe2Step = (
  label: string,
  el: JSX.Element,
  completed: boolean,
  help?: Pipe2Help
): Pipe2Step => ({
  label,
  el,
  completed,
  help,
});

export type UpdatePickedSubjobFn = (
  fn: (draft: typeof defaultSubjob) => void
) => void;

export type updateRegularPropFn = (
  prop: keyof typeof defaultSubjob
) => (value: any) => void;

export function isTemplate(obj: unknown): obj is Template {
  return (
    !!obj &&
    (obj as Template).name !== undefined &&
    typeof (obj as Template).name === "string"
  );
}

export type Activity = {
  _id: string;
  name: string;
  stance: ActivityStance;
  fps: number;
  end_frame: number;
  bounding_box: {
    width: number;
    depth: number;
  };
  filepaths: {
    preview_image: string;
    preview_video: string;
  };
};

export type CGLocation = {
  name: string;
  // represent where we are. Ideally we don't have this because data points should be normalized to 0 (we need only width and length)
  shell: Vec2[];
  action_regions: {
    name: string;
    hints: ActivityStance;
    shell: Vec2[] | Vec3[];
    avoid_regions: {
      name?: string;
      shell: Vec3[];
    }[];
  }[];
  camera_preset_locations: {
    name: string;
    // corners
    offset_uv_grid: {
      position: Vec3;
      // angle
      facing_direction: Vec3;
    }[];
  }[];
};

export type LocationWithAngles = {
  x: LoRType;
  y: LoRType;
  z: LoRType;
  yaw: LoRType;
  pitch: LoRType;
  roll: LoRType;
};

export type JobPresubmissionData = {
  state: (typeof defaultSubjob)[];
  version: number;
};

export enum DisallowActivityReason {
  NeedsMoreSpace,
  MismatchingStance,
}

export type ActivitiesState = {
  zoneIndex: number;
  allowedActivityNames: string[];
  disallowedActivityReasons: Record<string, DisallowActivityReason>;
};
