import { useEffect, useState } from "react";
import {
  Box,
  Divider,
  SxProps,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { colors } from "components/App/theme";
import { ActionButton } from "components/Common/ActionButton";
import { PasswordTextField } from "components/Common/PasswordTextField";
import { Routes } from "constants/routes";
import { useBoundStore } from "store/_boundStore";
import { validateEmail, validatePassword } from "utils/validators";
const centeredBox: SxProps = {
  display: "flex",
  justifyContent: "center",
  position: "relative",
};
function Login() {
  const [authenticated, loading, error, login] = useBoundStore((s) => [
    s.auth.authenticated,
    s.auth.loading,
    s.auth.error,
    s.auth.login,
  ]);

  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailValidationError, setEmailValidationError] = useState("");
  const [passwordValidationError, setPasswordValidationError] = useState("");

  useEffect(() => {
    if (authenticated) {
      navigate(Routes.ROOT);
    }
  }, [authenticated, location.search, navigate]);

  const validate = () => {
    let valid = true;

    let validationResult = validatePassword(password);
    if (!validationResult.valid) {
      valid = false;
      setPasswordValidationError(validationResult.message || "Invalid");
    } else {
      setPasswordValidationError("");
    }

    validationResult = validateEmail(email);
    if (!validationResult.valid) {
      valid = false;
      setEmailValidationError(validationResult.message || "Invalid");
    } else {
      setEmailValidationError("");
    }

    return valid;
  };

  const handleSignIn = async () => {
    if (!validate()) {
      return;
    }

    login(email, password);
  };

  return (
    <>
      <Box
        component="div"
        data-testid="login-component"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Box component="div" textAlign="center">
          {error && (
            <Box component="div" flexGrow={1} mb={2}>
              <Typography color="error" variant="body2">
                {error}
              </Typography>
              {error.includes("verif") ? (
                <Typography variant="body2">
                  Click{" "}
                  <Link
                    href={Routes.RESEND_ACTIVATION_EMAIL + location.search}
                    underline="always"
                    variant="body2"
                  >
                    here
                  </Link>{" "}
                  to resend verification email
                </Typography>
              ) : (
                ""
              )}
            </Box>
          )}
        </Box>
        <form>
          <Box
            component="div"
            sx={{
              backgroundColor: colors.white,
              border: `1px solid ${colors.grey20}`,
              width: {
                xs: 340,
                sm: 400,
              },
              borderRadius: "8px",
              padding: {
                xs: 3,
                sm: 4,
              },
            }}
          >
            <Box component="div" mb={6} textAlign="center">
              <Typography variant="h3">Sign in to your account</Typography>
            </Box>
            <Box component="div">
              <TextField
                autoFocus
                fullWidth
                autoComplete="on"
                error={!!emailValidationError}
                helperText={emailValidationError}
                id="email"
                inputProps={{
                  "data-testid": "email-input",
                }}
                label={"Email"}
                type="email"
                value={email}
                variant="outlined"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailValidationError("");
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSignIn();
                  }
                }}
              />
            </Box>
            <Box component="div" mb={4} mt={3}>
              <PasswordTextField
                autoComplete="on"
                error={!!passwordValidationError}
                helperText={passwordValidationError}
                inputProps={{
                  "data-testid": "password-input",
                }}
                label={"Password"}
                value={password}
                variant="outlined"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordValidationError("");
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSignIn();
                  }
                }}
              />
            </Box>
            <Box component="div" sx={centeredBox}>
              <ActionButton
                fullWidth
                color="warning"
                loading={loading}
                size="large"
                onClick={handleSignIn}
              >
                Sign in
              </ActionButton>
            </Box>
            <Box component="div" mt={4} sx={centeredBox} width="100%">
              <Box component="div" flex="50%" flexShrink={0} sx={centeredBox}>
                <Link
                  href="https://synthesis.ai/contact/"
                  underline="always"
                  variant="body2"
                >
                  Reach out to sign up
                </Link>
              </Box>
              <Box component="div" flexGrow={0} sx={centeredBox}>
                <Divider orientation="vertical" />
              </Box>
              <Box component="div" flex="50%" flexShrink={0} sx={centeredBox}>
                <Link
                  href={Routes.FORGOT_PASSWORD + location.search}
                  underline="always"
                  variant="body2"
                >
                  Forgot password?
                </Link>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
}

export { Login };
