import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { Messages } from "constants/messages";
import { URLParams } from "constants/params";
import { Routes } from "constants/routes";
import { useLogout } from "hooks/useLogout";
import { useURLParam } from "hooks/useURLParam";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";

function UpdateEmailReply() {
  const [token] = useURLParam(URLParams.token);
  const { setErrorMessage, setSuccessMessage } =
    useBoundStore.getState().message;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const authenticated = useLogout();

  useEffect(() => {
    if (!authenticated) {
      const finalizeUpdatingEmail = async () => {
        try {
          setLoading(true);
          if (!token) {
            return;
          }
          await restClient.updateEmailReply(token);
          setSuccessMessage(Messages.emailUpdated);
          navigate(Routes.LOGIN);
        } catch (error) {
          setErrorMessage(restClient.APIErrorMessage(error));
        }
        setLoading(false);
      };
      finalizeUpdatingEmail();
    }
  }, [token, authenticated, navigate, setSuccessMessage, setErrorMessage]);

  return <>{loading && <FullScreenProgress />}</>;
}

export { UpdateEmailReply };
