import { Box } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 24px;
`;

export const Diagram = styled.img`
  margin-top: 8px;
  width: 198px;
  height: 198px;
  border-radius: 4px;
`;
