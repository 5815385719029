import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogTitle } from "@mui/material";
import { disableBackdropClickClose } from "services/ui-service";
import { EmailForm } from "./EmailForm";
import { PasswordForm } from "./PasswordForm";

export enum ChangeEmailDialogMode {
  Closed,
  PasswordForm,
  EmailForm,
}

export function ChangeEmailDialog({
  onCancel,
  onSubmitEmail,
  onSubmitPassword,
  dialogMode,
  loading,
}: {
  onCancel: () => void;
  onSubmitEmail: (email: string) => void;
  onSubmitPassword: (password: string) => void;
  dialogMode: ChangeEmailDialogMode;
  loading: boolean;
}) {
  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      open
      data-testid="change-email-dialog"
      onClose={(e, reason) => disableBackdropClickClose(reason, null)}
    >
      <DialogTitle>
        <Box alignItems="flex-start" component="div" display="flex">
          <Box component="div" flexGrow={1}>
            Update Email
          </Box>
          <Box component="div" display="flex" flexGrow={0}>
            <CloseIcon onClick={onCancel} />
          </Box>
        </Box>
      </DialogTitle>
      {dialogMode === ChangeEmailDialogMode.PasswordForm ? (
        <PasswordForm
          loading={loading}
          onCancel={onCancel}
          onSubmit={onSubmitPassword}
        />
      ) : (
        <EmailForm
          loading={loading}
          onCancel={onCancel}
          onSubmit={onSubmitEmail}
        />
      )}
    </Dialog>
  );
}
