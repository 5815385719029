import { AppBar, Toolbar, Box } from "@mui/material";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";
import { colors } from "components/App/theme";
import { Logo } from "components/Common/Logo";
import { APP_BAR_HEIGHT } from "components/Common/TopAppBar2";
import { TopMenu } from "components/Common/TopMenu";
import { useBoundStore } from "store/_boundStore";

function TopAppBar() {
  const authenticated = useBoundStore((s) => s.auth.authenticated);

  return (
    <AppBar
      color="default"
      position="fixed"
      sx={{
        backgroundColor: colors.white,
        display: "flex",
        boxShadow: "0px 0px",
        borderBottom: `1px solid ${colors.grey20}`,
        height: APP_BAR_HEIGHT,
      }}
    >
      <Toolbar>
        <Container sx={{ display: "flex" }}>
          <Link to="/">
            <Logo />
          </Link>

          <Box
            component="div"
            sx={{
              flex: "1 0 150px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {authenticated && (
              <Box component="div" ml={3}>
                <TopMenu />
              </Box>
            )}
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export { TopAppBar };
