import { useState, useMemo, useCallback } from "react";
import { Vec2 } from "@flasd/modeling/src/maths/types";
import { Box } from "@mui/material";
import { Html } from "@react-three/drei";
import { colors } from "components/App/theme";
import {
  activityZoneGap,
  activityZoneHeight,
} from "features/JobBuilder2/steps/threeDimensional/activity-regions/ActivityRegion";
import { ActivityZoneDropdown } from "features/JobBuilder2/steps/threeDimensional/ActivityZoneDropdown";
import { getFittingActivitiesForZone } from "features/JobBuilder2/steps/threeDimensional/utils/getFittingActivitiesForZone";
import { ActivityZone } from "features/JobBuilder2/types";
import { useBoundStore } from "store/_boundStore";

const getTopLeftPoint = (sides: Vec2[][]) => {
  let topLeftPoint = [Infinity, -Infinity];
  sides
    .map((side) =>
      side.map((point) => point.map((val) => Number(val.toFixed(3))))
    )
    .forEach((side) =>
      side.forEach((point) => {
        if (
          point[1] > topLeftPoint[1] ||
          (point[1] === topLeftPoint[1] && point[0] < topLeftPoint[0])
        ) {
          topLeftPoint = point;
        }
      })
    );
  return topLeftPoint;
};

type ActivityZoneLabelProps = {
  activityZone: ActivityZone;
  shapeIndex: number;
};

export function ActivityZoneLabel(props: ActivityZoneLabelProps) {
  const { activityZone, shapeIndex } = props;

  const [
    activities,
    prefabGroups,
    setActivitiesPickerDialogData,
    setIsCharacterGroupDialogOpened,
    onRemoveActivityZone,
  ] = useBoundStore((s) => [
    s.catalogData.activities,
    s.catalogData.prefabGroups,
    s.jobBuilder2.setActivitiesPickerDialogData,
    s.jobBuilder2.setIsCharacterGroupDialogOpened,
    s.jobBuilder2.onRemoveActivityZone,
  ]);

  const [isMenuOpened, setIsMenuOpened] = useState(false);

  // handle assign activities to activity region.
  const activitiesData = useMemo(
    () =>
      // calculate only when the menu is opened for performance
      isMenuOpened
        ? getFittingActivitiesForZone(activityZone.geomRegion, activities)
        : null,
    [activityZone, activities, isMenuOpened]
  );

  const handleRemoveActivityArea = useCallback(() => {
    onRemoveActivityZone(shapeIndex);
  }, [onRemoveActivityZone, shapeIndex]);

  const handleEditActivities = useCallback(() => {
    if (!activitiesData) return;
    const { fittingActivities, disallowedActivityReasons } = activitiesData;

    setActivitiesPickerDialogData({
      zoneIndex: shapeIndex,
      allowedActivityNames: fittingActivities.map((item) => item.name),
      disallowedActivityReasons,
    });
  }, [activitiesData, shapeIndex, setActivitiesPickerDialogData]);
  const [showMenuIcon, setShowMenuIcon] = useState(false);
  const topLeftPoint = useMemo(
    () => getTopLeftPoint(activityZone.geomRegion.geom.outlines),
    [activityZone.geomRegion.geom.outlines]
  );

  const numberOfCharactersInSelectedGroup =
    prefabGroups[activityZone.prefabGroupId]?.characters?.length || 0;

  return (
    <group
      // maybe this is not necessary
      position={[
        0,
        0,
        activityZoneGap + shapeIndex * (activityZoneHeight + activityZoneGap),
      ]}
    >
      <group position={[topLeftPoint[0], topLeftPoint[1], 5]}>
        <Html zIndexRange={[5, 10]}>
          <Box
            component="div"
            sx={{
              pointerEvents: "none",
            }}
          >
            <Box
              component="div"
              sx={{
                backgroundColor: colors.bright[2],
                color: colors.white,
                fontWeight: "bold",
                fontSize: 12,
                padding: "4px 8px",
                borderRadius: 25,
                textAlign: "center",
                position: "relative",
                bottom: 1,
                right: 1,
                marginBottom: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                pointerEvents: "initial",
              }}
              onMouseOut={() => {
                // Using onMouseOut instead of onMouseLeave because onMouseLeave
                // sometimes does not register when user moves too quickly between targets.
                setShowMenuIcon(false);
              }}
              onMouseOver={() => {
                setShowMenuIcon(true);
              }}
            >
              <span data-testid={`activity-zone-label-${shapeIndex}`}>
                {shapeIndex + 1}
              </span>

              <ActivityZoneDropdown
                hidden={!showMenuIcon}
                isDeletable={activityZone.geomRegion.type === "drawn"}
                isNoFittingActivities={
                  activitiesData
                    ? activitiesData.fittingActivities.length === 0
                    : false
                }
                numberOfCharactersInSelectedGroup={
                  numberOfCharactersInSelectedGroup
                }
                numberOfSelectedActivities={activityZone.activities.length}
                onEditActivitiesClicked={handleEditActivities}
                onEditCharactersClicked={() => {
                  setIsCharacterGroupDialogOpened(true, shapeIndex);
                }}
                onMenuToggle={(value) => {
                  setIsMenuOpened(value);
                  if (!value) {
                    setShowMenuIcon(false);
                  }
                }}
                onRemoveAreaClicked={handleRemoveActivityArea}
              />
            </Box>
          </Box>
        </Html>
      </group>
    </group>
  );
}
