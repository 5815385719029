import * as Sentry from "@sentry/react";
import { restClient } from "services/restClient/main";
import { GetState, SetState } from "store/_boundStore";
import { AppLocation } from ".";

export function createActions(
  set: SetState,
  get: GetState,
  initialState: object
) {
  return {
    getUserProfile: async () => {
      try {
        const userId = get().auth.getCurrentUserId();
        set((state) => {
          state.profile.loading = true;
          state.profile.error = "";
        });
        const data = await restClient.getUserProfile(userId as string);
        set((state) => {
          state.profile.data = data;
          state.profile.loading = false;
        });
      } catch (error) {
        const errorMessage = restClient.APIErrorMessage(error);
        Sentry.captureException(errorMessage);
        set((state) => {
          state.profile.error = errorMessage;
          state.profile.loading = false;
        });
      }
    },

    setLoginRedirect: (location: AppLocation) => {
      set((state) => {
        state.profile.redirectLocationAfterLogin = location;
      });
    },

    clearState: () => {
      set((state) => {
        state.profile = { ...state.profile, ...initialState };
      });
    },
  };
}
