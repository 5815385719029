export function HeadphonesIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 15.5V15H19H17H16.5V15.5V19.5V20H17H18C18.8261 20 19.5 19.3261 19.5 18.5V15.5ZM7.5 15.5V15H7H5H4.5V15.5V18.5C4.5 19.3261 5.17386 20 6 20H7H7.5V19.5V15.5ZM4.5 13.5V14H5H8.5V21H6C4.61614 21 3.5 19.8839 3.5 18.5V11.5C3.5 6.80614 7.30614 3 12 3C16.6939 3 20.5 6.80614 20.5 11.5V18.5C20.5 19.8839 19.3839 21 18 21H15.5V14H19H19.5V13.5V11.5C19.5 7.35386 16.1461 4 12 4C7.85386 4 4.5 7.35386 4.5 11.5V13.5Z"
        fill={fill}
        stroke={fill}
      />
    </svg>
  );
}
