import { capitalize } from "lodash";
import { createSelector } from "reselect";
import { BoundStore } from "store/_boundStore";

const selectPrefabGroups = (s: BoundStore) => s.catalogData.prefabGroups;
export const selectFirstPrefabGroupID = createSelector(
  [selectPrefabGroups],
  (prefabGroups) => {
    const prefabGroupIds = Object.keys(prefabGroups);
    return prefabGroupIds.length > 0 ? prefabGroupIds[0] : "";
  }
);

export const selectCatalogLocations = (s: BoundStore) =>
  s.catalogData.locations;
export const selectCatalogActivities = (s: BoundStore) =>
  s.catalogData.activities;

export const selectCatalogLocationWithLabels = createSelector(
  [selectCatalogLocations],
  (locations) =>
    locations.map((l) => ({
      value: l.name,
      label: l.name.replace(/(_|-)/g, " ").split(" ").map(capitalize).join(" "),
    }))
);
