import { useMemo } from "react";
import DownloadJobSpecIcon from "@mui/icons-material/ArrowDownward";
import "react-image-lightbox/style.css";
import ClearIcon from "@mui/icons-material/Clear";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import GetAppIcon from "@mui/icons-material/GetApp";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Visibility from "@mui/icons-material/Visibility";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { ClipboardIcon } from "assets/icons/Clipboard";
import { dataTableCustomStyles } from "components/Common/DataTable/customStyles";
import { DateRow } from "components/Common/DataTable/DateRow";
import { EditableDatatableBox } from "components/Common/DataTable/EditableDatatableBox";
import { LabelWithValue } from "domain/Human";
import { canDuplicateJob, Job } from "domain/Job";
import { OrgPermissions } from "domain/Permissions";
import { DEFAULT_SORT_FIELD, DEFAULT_PAGE_SIZE } from "features/Jobs/constants";
import { useBoundStore } from "store/_boundStore";
import { StringToStringMap } from "utils/typesUtil";
import { IconWithToolip } from "./IconWithTooltip";
import { Status } from "./Status";

type JobsTableProps = {
  isEditable: boolean;
  onDownload: (job: Job) => void;
  onEditJob: (id: string, name: string) => void;
  onDeleteJob: (job: Job) => void;
  onCancelJob: (job: Job) => void;
  onResetJob: (job: Job) => void;
  onPreviewClick: (job: Job) => void;
  onJobSpecDownloadClick: (id: string, name: string) => void;
  onJobDuplicateClick: (id: string, name: string, type: number) => void;
  permissions: OrgPermissions;
  paginationTotalRows: number | undefined;
  setSize: (n: number) => void;
  setSortField: (v: string) => void;
  setSortOrder: (v: string) => void;
  setPage: (v: number) => void;
  loading: boolean;
  rows: Job[];
  jobTypeOptions: LabelWithValue[];
};

export function JobsTable(props: JobsTableProps) {
  const {
    isEditable,
    jobTypeOptions,
    loading,
    onCancelJob,
    onDeleteJob,
    onDownload,
    onEditJob,
    onJobDuplicateClick,
    onJobSpecDownloadClick,
    onPreviewClick,
    onResetJob,
    paginationTotalRows,
    permissions,
    rows,
    setPage,
    setSize,
    setSortField,
    setSortOrder,
  } = props;

  const isSupportUser = useBoundStore((s) => s.profile.data?.is_support);

  const jobTypeLabelByValue = useMemo(() => {
    const map: StringToStringMap = {};
    jobTypeOptions.forEach((item) => {
      map[item.value] = item.label;
    });
    return map;
  }, [jobTypeOptions]);

  const columns = useMemo((): IDataTableColumn[] => {
    const columns: IDataTableColumn<Job>[] = [
      {
        name: "Name",
        selector: "job_name",
        sortable: true,
        width: "200px",
        cell: (row) => (
          <EditableDatatableBox
            id={row.id}
            isEditable={isEditable}
            oldLabel={row.job_name}
            onEdit={onEditJob}
          />
        ),
      },
      {
        name: "Spec",
        sortable: false,
        width: "120px",
        cell: (row) => (
          <Box component="div" ml={-2.5}>
            <Tooltip title="Download input JSON spec">
              <IconButton
                size="large"
                onClick={() => onJobSpecDownloadClick(row.id, row.job_name)}
              >
                <DownloadJobSpecIcon />
              </IconButton>
            </Tooltip>
            {canDuplicateJob(row, Boolean(isSupportUser), permissions) && (
              <IconWithToolip
                title="Duplicate"
                onClick={() =>
                  onJobDuplicateClick(row.id, row.job_name, row.job_type)
                }
              >
                <ContentCopyIcon fontSize="small" />
              </IconWithToolip>
            )}
          </Box>
        ),
      },

      {
        name: "Creator",
        width: "130px",
        selector: "user_name",
        sortable: true,
      },

      {
        name: "Type",
        width: "130px",
        selector: "job_type",
        cell: (row) => jobTypeLabelByValue[row.job_type],
      },

      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "120px",
        cell: (row) => (
          <Box component="div" display="inline-flex">
            <Box
              component="div"
              lineHeight="20px"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              width="80px"
            >
              {row.id}
            </Box>
            <Tooltip title={row.id}>
              <IconButton
                size="large"
                style={{ padding: "0px" }}
                onClick={() => navigator.clipboard.writeText(row.id)}
              >
                <ClipboardIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
      {
        name: "Date created",
        selector: "created_at",
        sortable: true,
        width: "120px",
        cell: (row) => <DateRow dateStr={row.created_at} />,
      },
      {
        name: "Status",
        sortable: true,
        selector: "status",
        grow: 0,
        cell: (row) => <Status row={row} />,
      },

      {
        name: "Renders",
        selector: "task_count",
        sortable: false,
        width: "80px",
        right: true,
      },
      {
        name: "Preview Images",
        sortable: false,
        width: "60px",
        cell: (row) => <PreviewImages onClick={() => onPreviewClick(row)} />,
      },
      {
        name: "",
        sortable: false,
        width: "60px",
        cell: (row) => <DownloadJob onClick={() => onDownload(row)} />,
      },
    ];
    const actionsColumn: IDataTableColumn<Job> = {
      name: "",
      width: "80px",
      cell: (row) => {
        return (
          <>
            {row.status === "submitted" || row.status === "accepted" ? (
              <IconWithToolip title="Cancel" onClick={() => onCancelJob(row)}>
                <ClearIcon fontSize="small" />
              </IconWithToolip>
            ) : (
              <IconWithToolip title="Delete" onClick={() => onDeleteJob(row)}>
                <DeleteIcon fontSize="small" />
              </IconWithToolip>
            )}
            {isSupportUser && (
              <>
                <IconWithToolip title="Reset" onClick={() => onResetJob(row)}>
                  <RestartAltIcon fontSize="small" />
                </IconWithToolip>
              </>
            )}
          </>
        );
      },
    };
    if (isEditable) {
      columns.push(actionsColumn);
    }
    return columns;
  }, [
    isEditable,
    onEditJob,
    isSupportUser,
    permissions,
    onJobSpecDownloadClick,
    onJobDuplicateClick,
    jobTypeLabelByValue,
    onPreviewClick,
    onDownload,
    onCancelJob,
    onDeleteJob,
    onResetJob,
  ]);
  return (
    <DataTable
      noHeader
      pagination
      paginationServer
      responsive
      sortServer
      columns={columns}
      customStyles={dataTableCustomStyles}
      data={rows}
      defaultSortAsc={false}
      defaultSortField={DEFAULT_SORT_FIELD}
      highlightOnHover={false}
      keyField="id"
      paginationRowsPerPageOptions={[DEFAULT_PAGE_SIZE, 25, 50, 100]}
      paginationTotalRows={paginationTotalRows}
      progressComponent={
        <Box component="div" padding={2}>
          <CircularProgress size="6rem" />
        </Box>
      }
      progressPending={loading}
      onChangePage={(page) => {
        setPage(page - 1);
      }}
      onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
        setSize(currentRowsPerPage);
        setPage(currentPage - 1);
      }}
      onSort={(column, sortDirection) => {
        setSortField(column.selector as string);
        setSortOrder(sortDirection);
      }}
    />
  );
}

function DownloadJob(props: { onClick: () => void }) {
  const { onClick } = props;

  return (
    <IconButton data-testid="show-cli-icon" size="small" onClick={onClick}>
      <GetAppIcon />
    </IconButton>
  );
}

function PreviewImages(props: { onClick: () => void }) {
  const { onClick } = props;
  return (
    <IconButton size="small" onClick={onClick}>
      <Visibility />
    </IconButton>
  );
}
