import { Box, Typography, Tab } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const StyledTab = styled(Tab)`
  text-transform: none;
`;

export const Container = styled(Box)`
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.07);
  background-color: ${colors.white};
`;

export const TabHeaders = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;

  .css-kfsd23-MuiButtonBase-root-MuiTab-root {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: ${colors.grey50};
  }

  .css-kfsd23-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: ${colors.orange};
  }
`;

export const NoRenameText = styled(Typography)<{ $selected: boolean }>`
  font-size: 14px;
  line-height: 21px;
  min-width: 20px;
  font-weight: 700;
  color: ${({ $selected }) => ($selected ? colors.orange : colors.grey50)};
  cursor: default;
`;

export const Separator = styled(Box)`
  height: 1px;
  border-bottom: 1px solid ${colors.grey20};
`;

export const Content = styled(Box)`
  width: 100%;
  padding: 24px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const TabPanel = styled(Box)`
  width: 100%;
`;
