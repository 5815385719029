import styled from "@emotion/styled";
import { FileDownload } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { colors } from "components/App/theme";

const Button = styled.button`
  background-color: #fff;
  border: 1px solid ${colors.grey20};
  height: 40px;
  width: 40px;
  border-radius: 20px;
  font-size: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${colors.grey70};
  cursor: pointer;

  &:hover {
    background-color: ${colors.greyBGLighter};
  }
`;

export function DownloadJobSpec(props: { onClick: () => void }) {
  const { onClick } = props;

  return (
    <Tooltip arrow title="Download Input JSON Spec">
      <Button onClick={onClick}>
        <FileDownload fontSize="inherit" />
      </Button>
    </Tooltip>
  );
}
