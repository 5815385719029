import {
  distContainer,
  dist,
  DistList,
  accessoryDists,
  defaultSection,
} from "./Human";

export { defaultSection };

const topLevelDists = [
  dist("body", "Body", "BodyIcon"),
  dist("clothing", "Clothing", "ClothingIcon"),
];

export const facialDists = [
  dist("hair", "Hair", "HairIcon"),
  dist("facialHair", "Facial Hair", "FacialHairIcon"),
  dist("eyebrow", "Eyebrows", "EyebrowsIcon"),
  dist("eye", "Eye", "EyeIcon"),
];

export const leftSidebarDists: DistList = [
  distContainer(
    "facialAttributes",
    facialDists,
    "Facial Attributes",
    "FacialAttributesIcon"
  ),
  distContainer(
    "accessories",
    accessoryDists,
    "Accessories",
    "AccessoriesIcon"
  ),
  ...topLevelDists,
];
