import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { colors } from "components/App/theme";
import { useApisVersion } from "hooks/useApisVersion";
import { useProfile } from "hooks/useProfile";
import { trimMultiple } from "utils/stringUtil";

export interface ProfileBadgeProps {
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

function ProfileBadge(props: ProfileBadgeProps) {
  const { onClick } = props;

  const theme = useTheme();

  const [onlyProfilePic, setOnlyProfilePic] = useState(
    window.innerWidth <= theme.breakpoints.values.sm
  );

  useEffect(() => {
    function handleResize() {
      setOnlyProfilePic(window.innerWidth <= theme.breakpoints.values.sm);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [theme.breakpoints.values.sm]);

  const profile = useProfile();
  // const apisVersion = useApisVersion();
  if (!profile) {
    return <CircularProgress />;
  }

  const fullName = trimMultiple(profile.first_name, profile.last_name);
  const email = profile.email;
  const isSupport = profile?.is_support;
  const webCommit = process.env.REACT_APP_VERSION_INFO;
  const webBuildDate = process.env.REACT_APP_BUILD_DATE;
  // const apisVersionInfo = isSupport ? (
  //   <span>
  //     Apis: {apisVersion?.commit}
  //     <br /> Apis Date: {apisVersion?.build_date}
  //     <br /> Web: {webCommit}
  //     <br /> Web Date: {webBuildDate}
  //   </span>
  // ) : (
  //   ""
  // );

  return (
    <>
      {(fullName || email) && (
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={onClick}
        >
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              objectFit: "cover",
              backgroundColor: colors.neutral[1],
              ...(onlyProfilePic
                ? {
                    width: "34px",
                    height: "34px",
                  }
                : {
                    width: "40px",
                    height: "40px",
                    marginRight: theme.spacing(1),
                  }),
            }}
          >
            <Tooltip title={""}>
              <Typography variant="h2">
                {fullName ? fullName[0] : email[0]}
              </Typography>
            </Tooltip>
          </Box>

          {!onlyProfilePic && (
            <Box
              color={colors.tuna}
              component="div"
              fontSize="0.9rem"
              fontWeight={500}
            >
              {fullName || email}
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

export { ProfileBadge };
