import React, { useMemo, useState } from "react";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ApiIcon from "@mui/icons-material/Api";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import {
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import { ProfileBadge } from "components/Common/Badges/ProfileBadge";
import { URLParams } from "constants/params";
import { Routes } from "constants/routes";
import { useURLParam } from "hooks/useURLParam";
import { useBoundStore } from "store/_boundStore";

function TopMenu() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { logout } = useBoundStore.getState().auth;
  const [orgParam] = useURLParam(URLParams.org);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const permissions = useBoundStore((state) => state.org.selected?.permissions);

  const menuItems = useMemo(() => {
    return [
      {
        label: "My account",
        pathname: Routes.ACCOUNT_INFORMATION,
        icon: <AccountBoxIcon fontSize="small" />,
      },
      {
        label: "Team",
        pathname: Routes.TEAM,
        icon: <PeopleIcon fontSize="small" />,
      },
    ]
      .concat(
        permissions?.is_owner
          ? [
              {
                label: "Settings",
                pathname: Routes.SETTINGS,
                icon: <SettingsIcon fontSize="small" />,
              },
            ]
          : []
      )
      .concat([
        {
          label: "API keys",
          pathname: Routes.MANAGE_API_KEYS,
          icon: <VpnKeyIcon fontSize="small" />,
        },
      ]);
  }, [permissions?.is_owner]);

  return (
    <div>
      <ProfileBadge
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          setAnchorEl(e.currentTarget);
        }}
      />

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={Boolean(anchorEl)}
        sx={{
          ".MuiMenu-paper": {
            mt: 1,
            minWidth: 180,
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.pathname}
            onClick={() => {
              navigate(`${item.pathname}?${URLParams.org}=${orgParam}`);
              setAnchorEl(null);
            }}
          >
            <ListItemIcon style={{ minWidth: "28px" }}>
              {item.icon}
            </ListItemIcon>
            <Typography variant="body2">{item.label}</Typography>
          </MenuItem>
        ))}

        <Divider />

        <MenuItem
          onClick={() => {
            window.open("https://docs.synthesis.ai/inputs.html", "_blank");
          }}
        >
          <ListItemIcon style={{ minWidth: "28px" }}>
            <ApiIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="body2">API docs</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            logout("");
            navigate(Routes.LOGIN);
          }}
        >
          <ListItemIcon style={{ minWidth: "28px" }}>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="body2">Log out</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

export { TopMenu };
