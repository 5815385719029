import { Org } from "domain/Org";
import { OrgPermissions } from "domain/Permissions";
import { SliceType } from "store/_boundStore";
import { createActions } from "./actions";

export * from "./selectors";

type State = {
  list?: Org[];
  selected?: { permissions: OrgPermissions };
  error: string;
  loading: boolean;
};

type Actions = {
  fetchPermissions: (orgName: string) => Promise<void>;
  fetchOrgs: () => Promise<void>;
  replaceOrgs: () => Promise<void>;
  replacePermissions: (orgName: string) => Promise<void>;
  setLoading: (flag: boolean) => void;
  saveOrgs: (orgs: Org[]) => void;
  savePermissions: (permissions: OrgPermissions) => void;
  clearState: () => void;
};

const initialState: State = {
  list: undefined,
  selected: undefined,
  error: "",
  loading: false,
};

export type OrgSlice = State & Actions;
export const createOrgSlice: SliceType<OrgSlice> = (set, get) => ({
  ...initialState,
  ...createActions(set, get, initialState),
});
