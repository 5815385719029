import React from "react";
import {
  CameraPreviewTitle,
  CameraPreviewWrapper,
} from "features/JobBuilder/CamerasAndLightsPreview/CamerasAndLightsPreview.styled";

interface CameraPreviewProps {
  title?: string;
  noBorder?: boolean;
  children: React.ReactNode;
}

export function CameraPreview({
  title,
  children,
  noBorder,
}: CameraPreviewProps) {
  return (
    <CameraPreviewWrapper noBorder={noBorder}>
      {title ? <CameraPreviewTitle>{title}</CameraPreviewTitle> : null}
      {children}
    </CameraPreviewWrapper>
  );
}
