export function ClothingIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M9.90293 3.17808C10.1725 3.3649 10.3333 3.67203 10.3333 4.00001C10.3333 4.56598 10.5343 5.08848 10.8618 5.45696C11.1859 5.82161 11.5985 6.00001 12 6.00001C12.4015 6.00001 12.8141 5.82161 13.1382 5.45696C13.4657 5.08848 13.6667 4.56598 13.6667 4.00001C13.6667 3.67203 13.8275 3.3649 14.0971 3.17808C14.3666 2.99126 14.7107 2.94852 15.0178 3.06368L20.3511 5.06368C20.7414 5.21004 21 5.58316 21 6.00001V11C21 11.5523 20.5523 12 20 12H18.3333V19C18.3333 19.4949 18.1597 19.9898 17.8204 20.3715C17.4777 20.757 16.986 21 16.4444 21H7.55556C7.01404 21 6.52227 20.757 6.17961 20.3715C5.84035 19.9898 5.66667 19.4949 5.66667 19V12H4C3.44772 12 3 11.5523 3 11V6.00001C3 5.58316 3.25857 5.21004 3.64888 5.06368L8.98221 3.06368C9.28931 2.94852 9.63335 2.99126 9.90293 3.17808ZM5 6.69301V10H6.66667C7.21895 10 7.66667 10.4477 7.66667 11V19H16.3333V11C16.3333 10.4477 16.781 10 17.3333 10H19V6.69301L15.4484 5.36114C15.275 5.8852 15.0005 6.37228 14.633 6.78569C13.957 7.54626 13.013 8.00001 12 8.00001C10.987 8.00001 10.043 7.54626 9.36697 6.78569C8.9995 6.37228 8.72504 5.8852 8.55165 5.36114L5 6.69301Z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
}
