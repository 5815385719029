export function WindowsIcon({
  fill = "currentColor",
  style = { height: "20px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg style={style} viewBox="0 0 512 512">
      <g>
        <g>
          <polygon fill={fill} points="0,80 0,240 224,240 224,52" />
        </g>
      </g>
      <g>
        <g>
          <polygon fill={fill} points="256,48 256,240 512,240 512,16" />
        </g>
      </g>
      <g>
        <g>
          <polygon fill={fill} points="256,272 256,464 512,496 512,272" />
        </g>
      </g>
      <g>
        <g>
          <polygon fill={fill} points="0,272 0,432 224,460 224,272" />
        </g>
      </g>
    </svg>
  );
}
