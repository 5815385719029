import React from "react";
import { Box } from "@mui/material";
import { ClipboardIcon } from "assets/icons/Clipboard";
import { colors } from "components/App/theme";
import { TutorialCardButton } from "components/tutorials/TutorialCardButton";
import { Messages } from "constants/messages";
import { useBoundStore } from "store/_boundStore";

export function TutorialCommand({ sampleCommand }: { sampleCommand: string }) {
  const { setSuccessMessage } = useBoundStore.getState().message;

  return (
    <Box component="div">
      <TutorialCardButton
        color="secondary"
        endIcon={<ClipboardIcon />}
        onClick={() => {
          navigator.clipboard.writeText(sampleCommand);
          setSuccessMessage(Messages.copietToClipboard);
        }}
      >
        Copy
      </TutorialCardButton>
      <Box
        bgcolor={colors.black}
        borderRadius="8px"
        color={colors.brightGreen}
        component="div"
        fontFamily="monospace"
        fontSize="0.8rem"
        fontWeight={600}
        mt={4}
        padding={1.5}
        textAlign="left"
      >
        {sampleCommand}
      </Box>
    </Box>
  );
}
