import { useEffect } from "react";

export const useKeyDown = (
  key: string,
  callback: (e: KeyboardEvent) => void
) => {
  useEffect(() => {
    const keyDownListener = (e: KeyboardEvent) => {
      if (e.key === key) {
        callback(e);
      }
    };

    document.addEventListener("keydown", keyDownListener);

    return () => {
      document.removeEventListener("keydown", keyDownListener);
    };
  }, [callback, key]);
};
