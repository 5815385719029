import { getActivityEndFrame } from "features/JobBuilder2/domain";
import {
  defaultSubjob,
  FrameCutoffMode,
  Activity,
} from "features/JobBuilder2/types";
import { return0IfNan } from "utils/numberUtils";

export const numberOfScenes = (
  distribution: typeof defaultSubjob.distribution
) => return0IfNan(Number(distribution.numberOfScenes));

const lastFrameNumber = (distribution: typeof defaultSubjob.distribution) =>
  return0IfNan(
    Number(distribution.configs[FrameCutoffMode.static].lastFrameNumber)
  );

const getFramesAfterGap = (framesBeforeGaps: number, frameIncrement: number) =>
  Math.floor((framesBeforeGaps - 1) / frameIncrement) + 1;

const numberOfFramesForEverySceneBeforeGapsInDynamicMode = (
  activitiesInAllScenesForSpecialZone: string[],
  activities: Activity[]
) =>
  activitiesInAllScenesForSpecialZone.map((a) =>
    getActivityEndFrame(a, activities)
  );

export const numberOfFramesForAllScenesBeforeGapsInDynamicMode = (
  activitiesInAllScenesForSpecialZone: string[],
  activities: Activity[]
) =>
  numberOfFramesForEverySceneBeforeGapsInDynamicMode(
    activitiesInAllScenesForSpecialZone,
    activities
  ).reduce((a, b) => a + b, 0);

const numberOfFramesForAllScenesAfterGapsInStaticMode = (
  distribution: typeof defaultSubjob.distribution
) =>
  numberOfScenes(distribution) *
  getFramesAfterGap(lastFrameNumber(distribution), distribution.frameIncrement);
const numberOfFramesForAllScenesAfterGapsInDynamicMode = (
  distribution: typeof defaultSubjob.distribution,
  activities: Activity[]
) => {
  return getFramesAfterGap(
    numberOfFramesForAllScenesBeforeGapsInDynamicMode(
      distribution.configs[FrameCutoffMode.dynamic]
        .activitiesInAllScenesForSpecialZone,
      activities
    ),
    distribution.frameIncrement
  );
};
export const numberOfFramesForAllScenesAfterGaps = {
  [FrameCutoffMode.static]: (
    d: typeof defaultSubjob.distribution,
    activities: Activity[]
  ) => numberOfFramesForAllScenesAfterGapsInStaticMode(d),
  [FrameCutoffMode.dynamic]: (
    d: typeof defaultSubjob.distribution,
    activities: Activity[]
  ) => numberOfFramesForAllScenesAfterGapsInDynamicMode(d, activities),
};

export const numberOfImages = (
  s: typeof defaultSubjob,
  activities: Activity[]
) =>
  numberOfFramesForAllScenesAfterGaps[s.distribution.frameCutoffMode](
    s.distribution,
    activities
  ) * Number(s.rig.cameras.length);
