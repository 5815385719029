import { Box } from "@mui/material";
import { Alerts } from "components/Alerts";
import { TopAppBar2 } from "components/Common/TopAppBar2";

type Layout2Props = {
  children: React.ReactNode;
  title?: React.ReactElement | string;
  fullWidthTopbar?: boolean;
};

export function Layout2({
  children,
  title,
  fullWidthTopbar = false,
}: Layout2Props) {
  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      height="100vh"
      overflow="hidden"
    >
      <Alerts />
      <TopAppBar2 fullWidth={fullWidthTopbar} title={title} />
      {children}
    </Box>
  );
}

export function withLayout2<P extends object>(
  Component: React.ComponentType<P>,
  layoutProps?: Omit<Layout2Props, "children">
) {
  return function WithLayout2(props: P) {
    return (
      <Layout2 {...(layoutProps || {})}>
        <Component {...props} />
      </Layout2>
    );
  };
}
