import { useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router";
import { ActionButton } from "components/Common/ActionButton";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { Messages } from "constants/messages";
import { URLParams } from "constants/params";
import { Routes } from "constants/routes";
import { useURLParam } from "hooks/useURLParam";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { validatePassword } from "utils/validators";

function ResetPassword() {
  const { setErrorMessage, setSuccessMessage } =
    useBoundStore.getState().message;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [newPasswordValidationError, setNewPasswordValidationError] =
    useState("");

  const [email, code] = useURLParam(URLParams.email, URLParams.code);

  const clearValidationErrors = () => {
    setNewPasswordValidationError("");
  };

  const validate = () => {
    clearValidationErrors();

    let valid = true;
    const validationResult = validatePassword(newPassword1);
    if (!validationResult.valid) {
      valid = false;
      setNewPasswordValidationError(validationResult.message || "Invalid");
    } else {
      if (newPassword1 !== newPassword2) {
        valid = false;
        setNewPasswordValidationError(Messages.passwordsNotMatching);
      } else {
        setNewPasswordValidationError("");
      }
    }

    return valid;
  };

  const resetPassword = async () => {
    if (!validate()) {
      return;
    }

    setLoading(true);
    try {
      await restClient.resetPassword(email, code, newPassword1);
      setSuccessMessage(Messages.successPasswordChange);
      navigate(Routes.LOGIN);
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <FullScreenProgress />}
      <Grid container justifyContent="center">
        <Grid item md={6} xs={8}>
          <Box component="div" mb={5}>
            <Typography variant="h1">Reset Password</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item md={6} xs={8}>
          <Box component="div">
            <TextField
              fullWidth
              required
              error={!!newPasswordValidationError}
              helperText={newPasswordValidationError}
              id="email"
              inputProps={{ "data-testid": "new-password-input" }}
              label="New password"
              size="small"
              type="password"
              value={newPassword1}
              variant="outlined"
              onChange={(e) => {
                setNewPassword1(e.target.value);
                clearValidationErrors();
              }}
            />
          </Box>
          <Box component="div" mb={4} mt={2}>
            <TextField
              fullWidth
              required
              error={!!newPasswordValidationError}
              helperText={newPasswordValidationError}
              id="email"
              inputProps={{ "data-testid": "confirm-password-input" }}
              label="Confirm new password"
              size="small"
              type="password"
              value={newPassword2}
              variant="outlined"
              onChange={(e) => {
                setNewPassword2(e.target.value);
                clearValidationErrors();
              }}
            />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box component="div" mt={2}>
                <ActionButton
                  fullWidth
                  color="warning"
                  data-testid="reset-button"
                  onClick={resetPassword}
                >
                  Reset Password
                </ActionButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export { ResetPassword };
