import React from "react";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { SimpleErrorMessage } from "components/Common/Messages/SimpleErrorMessage";
import { Routes } from "constants/routes";

function NotFound() {
  return (
    <SimpleErrorMessage title="NOT FOUND">
      <p>
        Oops!, we {"couldn't"} find what you were looking for. Click{" "}
        <Link component={RouterLink} to={Routes.ROOT} underline="always">
          here
        </Link>{" "}
        to go somewhere that exists.
      </p>
    </SimpleErrorMessage>
  );
}

export { NotFound };
