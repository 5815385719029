import Geom2 from "@flasd/modeling/src/geometries/geom2/type";
import { Memoize } from "typescript-memoize";
import { v4 } from "uuid";
import { ActivityStance } from "features/JobBuilder2/steps/threeDimensional/utils/Location";

export type GeomRegionType = "drawn" | "background" | "seated";

export interface IGeomRegion {
  readonly geom: Geom2;
  readonly type: GeomRegionType;
}

export class GeomRegion implements IGeomRegion {
  constructor(
    readonly geom: Geom2,
    readonly type: GeomRegionType,
    readonly actionRegionName: string
  ) {}
  @Memoize()
  get id() {
    return v4();
  }
  @Memoize()
  get matchingActivityStance() {
    return this.type === "seated"
      ? ActivityStance.Sitting
      : ActivityStance.Standing;
  }
}
