import { useMemo, useState } from "react";
import { Typography, Box } from "@mui/material";
import intensityImage from "assets/diagrams/hdri-intensity.svg";
import rotationImage from "assets/diagrams/hdri-rotation.svg";
import { colors } from "components/App/theme";
import { NoSearchResultFound } from "components/Common/NoSearchResultFound";
import { ChangeEnvironmentDialog } from "components/dialogs/ChangeEnvironmentDialog";
import { ImageGrid } from "components/ImageGrid";
import { ListViewToggle } from "components/ListViewToggle";
import { ProTip } from "components/ProTip";
import { RightAsidePreviewFull } from "components/RightAsidePreviewFull";
import { PreviewContent } from "components/RightAsidePreviewFull/PreviewContent";
import { SelectorsPicker } from "components/SelectorsPicker";
import { HDRI_FOLDER } from "constants/constants";
import { Messages } from "constants/messages";
import { defaultHdri, ThreeDLocationType } from "domain/Human";
import { PAGE_PADDING } from "features/constants";
import {
  convertHdriStateToJson,
  convertThreeDimensionalLocationsToJson,
  undefinedIfEmpty,
} from "features/JobBuilder/form-to-input-json";
import { getHdri, getHumanObject } from "features/JobBuilder/json-extracter";
import { JsonManagerNewUI } from "features/JobBuilder/json-manager-new-ui";
import {
  convertHdri,
  convertThreeDimensionalLocations,
} from "features/JobBuilder/json-to-form";
import { DeepPartial, JsonHuman } from "features/JobBuilder/json-types";
import { Page, ContentCard } from "features/JobBuilder/layout";
import { MultiOptionFilter } from "features/JobBuilder/MultiOptionsFilter";
import {
  allHdrisSelector,
  outdoorHdrisSelector,
  selectActiveSubjobHDRI,
  selectActiveSubjobthreeDimensionLocations,
} from "features/JobBuilder/store";
import {
  useImageSelection,
  useSelectionOutcomeCount,
} from "features/JobBuilder/useImageSelection";
import { useImageGridViewState } from "hooks/useImageGridViewState";
import { useBoundStore } from "store/_boundStore";
import {
  EnvironmentSelectionContainer,
  GridContainer,
  StyledButton,
} from "./Environment.styled";

export function JobBuilderEnvironment() {
  const [
    onUpdateHdri,
    value,
    updateNonDistPart,
    toggleHdriListItem,
    environmentValue,
    outdoorHDRIs,
    hdriOptions,
  ] = useBoundStore((s) => [
    s.jobBuilder.onUpdateModule("hdri"),
    selectActiveSubjobHDRI(s),
    s.jobBuilder.onUpdateNonDistPart,
    s.jobBuilder.toggleNonDistListItem("hdri"),
    selectActiveSubjobthreeDimensionLocations(s),
    outdoorHdrisSelector(s),
    allHdrisSelector(s),
  ]);
  const isHdriSelected = environmentValue === "open";
  const isVehicleSelected = environmentValue === "vehicle";
  const options = isVehicleSelected ? outdoorHDRIs : hdriOptions;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { setErrorMessage, setSuccessMessage } =
    useBoundStore.getState().message;
  const setThreeDLocation = useBoundStore(
    (s) => s.jobBuilder.setThreeDLocation
  );

  const jsonValue = useMemo(() => convertHdriStateToJson(value), [value]);

  const asidePreview = useMemo(
    () =>
      value.name.map((item) => ({
        label: item,
        src: `${HDRI_FOLDER}/${item}.jpg`,
      })),
    [value.name]
  );

  const updateHdriPart = useMemo(
    () => updateNonDistPart("hdri"),
    [updateNonDistPart]
  );
  const onUpdateNames = updateHdriPart("name");
  const onUpdateIntensity = updateHdriPart("intensity");
  const onUpdateRotation = updateHdriPart("rotation");

  const nameUIState = useImageSelection(
    value ? value.name : [],
    options,
    (i) => `${HDRI_FOLDER}/${i}.jpg`,
    onUpdateNames,
    "",
    "hdri",
    "StyleIcon",
    toggleHdriListItem("name", options)
  );

  const outcomes = useSelectionOutcomeCount([nameUIState]);

  const onIndecesReceived = (
    indices: number[],
    humansArr: DeepPartial<JsonHuman>[]
  ) => {
    try {
      onUpdateHdri(
        convertHdri(getHdri(getHumanObject(humansArr, indices[0])), value)
      );
      setSuccessMessage("updated hdri");
    } catch (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
      }
    }
  };

  const onDoneJson = (json: typeof fullJsonValue) => {
    const updatedHDRI = convertHdri(json.environment.hdri, value);
    const updatedEnvironment = convertThreeDimensionalLocations(
      json["3d_location"],
      environmentValue
    );

    const filteredData = isHdriSelected
      ? updatedHDRI
      : {
          ...updatedHDRI,
          name: updatedHDRI.name.filter((item) =>
            outdoorHDRIs.some((outdoor) => outdoor === item)
          ),
        };

    onUpdateHdri(filteredData);
    setThreeDLocation(updatedEnvironment);
  };

  const jsonWithenvironmentValue = useMemo(
    () =>
      undefinedIfEmpty(
        convertThreeDimensionalLocationsToJson(environmentValue)
      ),
    [environmentValue]
  );

  const fullJsonValue = {
    environment: { hdri: jsonValue },
    "3d_location": jsonWithenvironmentValue,
  };

  const openModalCallback = () => setIsModalOpen(true);
  const onCancelCallback = () => setIsModalOpen(false);

  const onChanggeEnvironment = () => {
    setIsModalOpen(false);
    isHdriSelected ? onSelectVehicle() : onSelectHdri();
  };

  const onSelectHdri = () => {
    onUpdateNames(value.name.filter((item) => hdriOptions.includes(item)));
    setThreeDLocation(ThreeDLocationType.open);
  };

  const onSelectVehicle = () => {
    onUpdateNames(value.name.filter((item) => outdoorHDRIs.includes(item)));
    setThreeDLocation(ThreeDLocationType.vehicle);
  };

  const isPreviewEnabled = false;

  const {
    imageGridViewMode: envGridViewMode,
    setImageGridViewMode: setEnvGridViewMode,
  } = useImageGridViewState(nameUIState.filteredImages.length);

  const center = (
    <Box component="div" p={PAGE_PADDING}>
      {isModalOpen && (
        <ChangeEnvironmentDialog
          textContent="Changing environment will alter the selections you made and affect your selection of animations. Do you wish to continue?"
          onCancel={onCancelCallback}
          onConfirm={onChanggeEnvironment}
        />
      )}

      {isPreviewEnabled && (
        <ContentCard style={{ marginBottom: "36px", background: colors.tint5 }}>
          <Typography>
            Start with pre-configured environment settings
          </Typography>
          <button>Description of action</button>
          <button>Description of action</button>
        </ContentCard>
      )}

      <JsonManagerNewUI
        id="hdri"
        jsonValue={fullJsonValue}
        title="Environment"
        titleCount={outcomes}
        titleIcon="EnvironmentIcon"
        unknownIndicesKeys={["Human"]}
        onDoneEditJson={onDoneJson}
        onIndecesReceived={onIndecesReceived}
      >
        <ContentCard style={{ marginTop: "16px", padding: "8px" }}>
          <SelectorsPicker
            icon={"JoinInnerIcon"}
            imgSrc={intensityImage}
            limits={defaultHdri.intensity}
            lorValue={value.intensity}
            noSpacing={true}
            title={"Intensity"}
            unit=""
            onUpdate={onUpdateIntensity}
          />
        </ContentCard>

        <ContentCard style={{ marginTop: "16px", padding: "8px" }}>
          <SelectorsPicker
            icon={"RotateRightIcon"}
            imgSrc={rotationImage}
            limits={defaultHdri.rotation}
            lorValue={value.rotation}
            noSpacing={true}
            title={"Rotation"}
            unit={""}
            onUpdate={onUpdateRotation}
          />
        </ContentCard>
        <ContentCard>
          <EnvironmentSelectionContainer>
            <StyledButton
              $isSelected={isHdriSelected}
              color={isHdriSelected ? "warning" : "primary"}
              disabled={isHdriSelected}
              variant="outlined"
              onClick={openModalCallback}
            >
              HDRI Environment
            </StyledButton>
            <StyledButton
              $isSelected={isVehicleSelected}
              color={isVehicleSelected ? "warning" : "primary"}
              disabled={isVehicleSelected}
              variant="outlined"
              onClick={openModalCallback}
            >
              Vehicle Environment
            </StyledButton>
          </EnvironmentSelectionContainer>

          <ProTip
            label={
              isHdriSelected
                ? Messages.environmentHDRIProTip
                : Messages.environmentVehicleProTip
            }
          />

          <Box
            component="div"
            display="flex"
            justifyContent="flex-end"
            marginTop={3}
          >
            <ListViewToggle
              view={envGridViewMode}
              onChange={(mode) => setEnvGridViewMode(mode)}
            />
          </Box>

          <MultiOptionFilter
            allSelected={nameUIState.areAllSelected}
            placeholder={Messages.search}
            onSearch={(term) => nameUIState.setSearchTerm(term)}
            onSelectAll={nameUIState.handleSelectAll}
          />

          <GridContainer>
            {!nameUIState.filteredImages.length ? (
              <NoSearchResultFound />
            ) : (
              <ImageGrid
                dynamicSize
                images={nameUIState.filteredImages}
                viewMode={envGridViewMode}
                onSelect={nameUIState.togglePickOne}
              />
            )}
          </GridContainer>
        </ContentCard>
      </JsonManagerNewUI>
    </Box>
  );
  return (
    <Page
      fixedRightSide
      center={center}
      rightAside={
        <RightAsidePreviewFull previewTitle="Preview">
          <PreviewContent data={asidePreview} title="NAMES" />
        </RightAsidePreviewFull>
      }
    />
  );
}
