import { Typography } from "@mui/material";
import { colors } from "components/App/theme";

export function BoxedNumber({
  step,
  secondary,
}: {
  step: string | number;
  secondary?: boolean;
}) {
  return (
    <Typography
      px={secondary ? 1 : 1.5}
      py={secondary ? 0 : 0.5}
      sx={{
        display: "inline-block",
        border: "1px solid",
        borderColor: secondary ? colors.grey70 : colors.bright[2],
        borderRadius: "6px",
      }}
      variant={secondary ? "body1" : "h3"}
    >
      {step}
    </Typography>
  );
}
