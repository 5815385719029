export function MessageWithIcon({
  title,
  icon,
  text,
}: {
  title: string;
  icon: JSX.Element;
  text?: string;
}) {
  return (
    <>
      {icon}
      <h2>{title}</h2>
      {text}
    </>
  );
}
