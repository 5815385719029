import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Add,
  DeleteOutline,
  InfoOutlined,
  WarningOutlined,
} from "@mui/icons-material";
import {
  Typography,
  TextField,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Tabs,
  Tab,
  capitalize,
  Tooltip,
  Link,
  Divider,
} from "@mui/material";
import produce from "immer";
import { colors } from "components/App/theme";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { MultiCheckbox } from "components/MultiCheckbox";
import { ProTip } from "components/ProTip";
import { Messages } from "constants/messages";
import {
  ethnicityOptions,
  sexOptions,
  ageOptions,
  defaultDemographicSelection,
  defaultDemographicBuilder,
} from "domain/Human";
import { Label } from "features/JobBuilder/rigs.styled";
import {
  addToArrayPercentages,
  changeEventValue,
  includeAll,
  removeFromArrayPercentages,
  summingTo100,
} from "features/JobBuilder/utils";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { PreviewIdentities } from "./preview-identities";

const sexOptionsWithLabels = sexOptions.map((option) => ({
  label: capitalize(option),
  value: option,
}));

const ageOptionsWithLabels = ageOptions.map((option) => ({
  label: option,
  value: option,
}));

const ethnicityOptionsWithLabels = ethnicityOptions.map((option) => ({
  label: capitalize(option),
  value: option,
}));

const fetchIdentityIds = async (
  numberOfIdentities: string,
  selections: (typeof defaultDemographicSelection)[],
  preferFullCount: boolean
) => {
  const res = await restClient.getIdentities(
    parseInt(numberOfIdentities),
    selections.map((s) => ({
      age: includeAll(s.age, ageOptions),
      sex: includeAll(s.sex, sexOptions),
      ethnicity: includeAll(s.ethnicity, ethnicityOptions),
      percent: s.percent,
    })),
    preferFullCount
  );

  return res.ids || [];
};

export function IdentitiesDemographic({
  onAppendIds,
  demographicBuilder,
  onUpdateDemographicBuilder,
  closeDialog,
}: {
  onAppendIds: (ids: number[], allowDuplicates?: false) => void;
  demographicBuilder: typeof defaultDemographicBuilder;
  onUpdateDemographicBuilder: (v: typeof defaultDemographicBuilder) => void;
  closeDialog: () => void;
}) {
  const { setErrorMessage } = useBoundStore.getState().message;

  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const onUpdateNumberOfIdentities = (v: string) =>
    onUpdateDemographicBuilder(
      produce(demographicBuilder, (d) => {
        d.numberOfIdentities = v;
      })
    );

  const onUpdatePreferFullCount = () =>
    onUpdateDemographicBuilder(
      produce(demographicBuilder, (d) => {
        d.preferFullCount = !d.preferFullCount;
      })
    );

  const onUpdateSelections = (v: (typeof defaultDemographicSelection)[]) =>
    onUpdateDemographicBuilder(
      produce(demographicBuilder, (d) => {
        d.selections = v;
      })
    );

  const { numberOfIdentities, selections, preferFullCount } =
    demographicBuilder;

  const [generatedIdentities, setGeneratedIdentities] = useState<number[]>([]);
  const [replaceIdentityIds, setReplaceIdentityIds] = useState(false);
  const currentSelection = selections[currentTab];

  useEffect(() => {
    const getIds = async () => {
      setLoading(true);
      try {
        const ids = await fetchIdentityIds(
          numberOfIdentities,
          selections,
          preferFullCount
        );
        setGeneratedIdentities(ids);
        setReplaceIdentityIds(false);
      } catch (error) {}
      setLoading(false);
    };

    if (
      numberOfIdentities &&
      selections.length > 0 &&
      summingTo100(selections)
    ) {
      getIds();
    }
  }, [numberOfIdentities, selections, preferFullCount]);

  const callIdentitiesAPI = useCallback(async () => {
    setLoading(true);
    try {
      if (replaceIdentityIds) {
        const ids = await fetchIdentityIds(
          numberOfIdentities,
          selections,
          preferFullCount
        );
        onAppendIds(ids, false);
      } else {
        onAppendIds(generatedIdentities);
        setReplaceIdentityIds(true);
        closeDialog();
      }
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  }, [
    replaceIdentityIds,
    numberOfIdentities,
    selections,
    preferFullCount,
    onAppendIds,
    generatedIdentities,
    closeDialog,
    setErrorMessage,
  ]);

  const onChangePercentSelectionProp = (i: number) => (value: string) => {
    changeSelectionProp(i, (d) => {
      d.percent = value;
    });
  };
  const onChangeMultiselectSelectionProp =
    (i: number, prop: keyof typeof defaultDemographicSelection) =>
    (value: string[]) => {
      changeSelectionProp(i, (d) => {
        d[prop as "age"] = value;
      });
    };

  const changeSelectionProp = (
    i: number,
    fn: (x: typeof defaultDemographicSelection) => void
  ) => {
    changeSelections((d) => {
      fn(d[i]);
    });
  };

  const changeSelections = (
    fn: (x: (typeof defaultDemographicSelection)[]) => void
  ) => {
    const newSelections = produce(selections, fn);
    onUpdateSelections(newSelections);
  };

  const onDeleteSelection = (i: number) => () => {
    if (!isSelectionsDeletable) return;

    onUpdateSelections(removeFromArrayPercentages(selections, i));

    if (selections.length > 1 && i > 0) {
      setCurrentTab(i - 1);
    } else {
      setCurrentTab(0);
    }
  };
  const onAddSelection = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    onUpdateSelections(
      addToArrayPercentages(selections, defaultDemographicSelection)
    );
  };

  const pickedSectionSelectionsAreSummingTo100 = summingTo100(selections);
  const hasInvalidInputErrorDemographic = isNaN(parseInt(numberOfIdentities));

  const hasInvalidInputErrorSelection = isNaN(
    parseInt(currentSelection.percent)
  );

  const isSelectionsDeletable = useMemo(
    () => selections.length > 1,
    [selections.length]
  );

  return (
    <>
      {/* ProTip */}
      <Box component="div" p={2}>
        <ProTip label={Messages.identitiesDemographicBuilderProTip} />
      </Box>

      {/* Number of identities */}
      <Box component="div" display="flex" p={2} width="960px">
        <Box component="div" flex={1} mr={2}>
          <Box component="div">
            <Box
              alignItems="center"
              component="div"
              display="flex"
              justifyContent="flex-start"
            >
              <Label style={{ width: "initial", padding: "0 24px 0 0" }}>
                Number of Identities
              </Label>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={preferFullCount}
                    color="warning"
                    sx={{ pr: 0.5 }}
                    onChange={onUpdatePreferFullCount}
                  />
                }
                label="Populate with full count"
                sx={{ mr: 1 }}
              />
              <Tooltip
                title={
                  <Typography color={colors.white} p={1} variant="body2">
                    If Synthesis AI can not offer your precise criteria in full,
                    do you want us to smartfill with other identities?
                    <br />
                    <Link
                      href="https://docs.synthesis.ai/cli.html#prefer_full_count"
                      rel="noreferrer"
                      sx={{ color: colors.bright[2] }}
                      target={"_blank"}
                    >
                      Learn more
                    </Link>
                  </Typography>
                }
              >
                <InfoOutlined color="disabled" fontSize="small" />
              </Tooltip>
            </Box>
            <TextField
              InputLabelProps={{ shrink: true }}
              color="warning"
              error={hasInvalidInputErrorDemographic}
              helperText={
                hasInvalidInputErrorDemographic
                  ? "Please type a valid number"
                  : ""
              }
              label=""
              margin="normal"
              size="small"
              sx={{ mt: 0, width: 128, mb: 2 }}
              type="number"
              value={numberOfIdentities}
              variant="outlined"
              onChange={changeEventValue(onUpdateNumberOfIdentities)}
            />
          </Box>

          {/* Tab Bar */}
          <Box
            component="div"
            sx={{ borderBottom: 1, px: 1, borderColor: "divider" }}
          >
            <Tabs
              aria-label="demographics builder tabs"
              value={currentTab}
              onChange={(event, newValue) => setCurrentTab(newValue)}
            >
              {selections.map((selection, i) => {
                return (
                  <Tab
                    key={i}
                    label={
                      <Box
                        component="span"
                        sx={{
                          fontWeight: 600,
                          color:
                            currentTab === i ? colors.bright[2] : colors.black,
                        }}
                      >
                        Group {i + 1} (
                        {selection.percent
                          ? parseFloat(selection.percent).toFixed(2)
                          : "0"}
                        %)
                      </Box>
                    }
                    sx={{
                      minWidth: "auto",
                    }}
                  />
                );
              })}
              {
                <Tab
                  label={
                    <Box
                      alignItems="center"
                      component="span"
                      display="flex"
                      sx={{
                        fontWeight: 600,
                        color:
                          selections.length === 0
                            ? colors.bright[2]
                            : colors.black,
                      }}
                    >
                      <Add fontSize="small" /> Add Additional % Group
                    </Box>
                  }
                  onClick={onAddSelection}
                />
              }
            </Tabs>
          </Box>

          {/* Selected Group */}
          <Box component="div" mb={5} p={1} pt={2}>
            {/* Percentage and Delete */}
            <Box
              alignItems="center"
              component="div"
              display="flex"
              justifyContent="space-between"
            >
              <Box alignItems="center" component="div" display="flex">
                <TextField
                  FormHelperTextProps={{
                    sx: {
                      ml: 0,
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                  color="warning"
                  error={
                    !pickedSectionSelectionsAreSummingTo100 ||
                    hasInvalidInputErrorSelection
                  }
                  helperText={
                    !pickedSectionSelectionsAreSummingTo100
                      ? "Percentage should sum up to 100"
                      : hasInvalidInputErrorSelection
                      ? "Please type a valid number"
                      : ""
                  }
                  size="small"
                  type="number"
                  value={currentSelection.percent}
                  variant="outlined"
                  onChange={changeEventValue(
                    onChangePercentSelectionProp(currentTab)
                  )}
                />
                <Typography
                  sx={{
                    ml: 1,
                    alignSelf: "flex-start",
                    display: "flex",
                    alignItems: "center",
                    height: "48px",
                  }}
                  variant="body2"
                >
                  Percentage of total above
                </Typography>
              </Box>
              <DeleteOutline
                style={{
                  cursor: isSelectionsDeletable ? "pointer" : "not-allowed",
                  color: isSelectionsDeletable ? colors.red : colors.grey30,
                }}
                onClick={onDeleteSelection(currentTab)}
              />
            </Box>

            <Divider sx={{ my: 2 }} />
            {/* Sexes */}
            <MultiCheckbox
              selectAll
              label="Sexes"
              options={sexOptionsWithLabels}
              value={currentSelection.sex}
              onChange={onChangeMultiselectSelectionProp(currentTab, "sex")}
            />

            <Divider sx={{ my: 2 }} />

            {/* Ages */}
            <MultiCheckbox
              selectAll
              label="Ages"
              options={ageOptionsWithLabels}
              value={currentSelection.age}
              onChange={onChangeMultiselectSelectionProp(currentTab, "age")}
            />

            <Divider sx={{ my: 2 }} />

            {/* Ethnicities */}
            <MultiCheckbox
              selectAll
              label="Ethnicities"
              options={ethnicityOptionsWithLabels}
              value={currentSelection.ethnicity}
              onChange={onChangeMultiselectSelectionProp(
                currentTab,
                "ethnicity"
              )}
            />
          </Box>

          {loading && <FullScreenProgress />}
        </Box>

        <Box
          border={`1px solid ${colors.grey20}`}
          borderRadius={4}
          component="div"
          flexShrink={0}
          p={2}
          pt={0}
          width="320px"
        >
          <PreviewIdentities
            emptyMessage={null}
            // every preview image has 90px height, times 6 = 540px
            previewHeight={540}
            selectedPrefabIds={generatedIdentities}
          />
        </Box>
      </Box>
      <Box
        alignItems="center"
        borderTop={`1px solid ${colors.grey20}`}
        bottom={0}
        component="div"
        display="flex"
        justifyContent="flex-end"
        p={2}
        position="sticky"
        sx={{ backgroundColor: colors.white, zIndex: 1 }}
      >
        <Button color="warning" variant="outlined" onClick={closeDialog}>
          Cancel changes
        </Button>
        {generatedIdentities.length !== Number(numberOfIdentities) &&
          !loading && (
            <Box color={colors.bright[2]} component="div" sx={{ ml: 2 }}>
              <Tooltip
                placement="top"
                title="Synthesis AI can not offer your precise criteria in full."
              >
                <WarningOutlined fontSize="small" />
              </Tooltip>
            </Box>
          )}
        <Button
          color="warning"
          disabled={
            loading ||
            !pickedSectionSelectionsAreSummingTo100 ||
            hasInvalidInputErrorSelection
          }
          sx={{ ml: 2 }}
          variant="contained"
          onClick={callIdentitiesAPI}
        >
          Add {generatedIdentities.length} Identities
        </Button>
      </Box>
    </>
  );
}
