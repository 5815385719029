import React, { useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router";
import { ActionButton } from "components/Common/ActionButton";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { validateApiKeyName } from "utils/validators";
import { NewApiKeyDialog } from "./NewApiKeyDialog";

function NewApiKey() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [nameValidationError, setNameValidationError] = useState("");
  const [apiKey, setApiKey] = useState("");

  const { setErrorMessage } = useBoundStore.getState().message;
  const { getCurrentUserId } = useBoundStore.getState().auth;
  const validate = () => {
    const result = validateApiKeyName(name);
    if (!result.valid) {
      setNameValidationError(result.message || "Invalid");
    }
    return result.valid;
  };

  const createKey = async () => {
    if (!validate()) {
      return;
    }

    setLoading(true);
    try {
      const result = await restClient.createApiKey(
        getCurrentUserId() as string,
        name
      );
      setApiKey(result.key);
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  const onClose = () => {
    navigate(-1);
  };

  return (
    <>
      {loading && <FullScreenProgress />}
      {apiKey && (
        <NewApiKeyDialog
          apiKey={apiKey}
          apiKeyName={name}
          orgName={undefined}
          userInit={false}
          onClose={onClose}
        />
      )}
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          <Typography variant="h1">Create New API Key</Typography>
          <Box component="div" mt={5}>
            <Box component="div" mb={3} mt={4}>
              <TextField
                autoFocus
                fullWidth
                required
                data-testid="api-key-name-input"
                error={!!nameValidationError}
                helperText={nameValidationError}
                id="api-key"
                label="API Key name"
                size="small"
                type="text"
                value={name}
                variant="outlined"
                onChange={(e) => {
                  setName(e.target.value);
                  setNameValidationError("");
                }}
              />
            </Box>
          </Box>
          <Box component="div" mt={3}>
            <ActionButton color="warning" onClick={createKey}>
              Generate API Key
            </ActionButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export { NewApiKey };
