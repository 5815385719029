/* eslint-disable @typescript-eslint/no-non-null-assertion */
// TODO: remove eslint-disable after fixing all the non-null-assertion for org
import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import "react-image-lightbox/style.css";
import fileDownload from "js-file-download";
import { Range } from "react-date-range";
import { useLocation, useNavigate } from "react-router";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { ConfirmationDialog } from "components/dialogs/ConfirmationDialog";
import { DownloadAssetsDialog } from "components/dialogs/DownloadAssetsDialog";
import { GalleryDialog } from "components/dialogs/GalleryDialog";
import { NavigationHeader } from "components/Navigation/Header";
import { IS_NEW_HUMANS_GA, IS_SCENARIOS_GA } from "constants/constants";
import { URLParams } from "constants/params";
import { Routes } from "constants/routes";
import { handleJobSpecJson, Job, JobType } from "domain/Job";
import { hasValidBillMethod } from "domain/Org";
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_SORT_FIELD,
  DEFAULT_SORT_ORDER,
} from "features/Jobs/constants";
import { Header } from "features/Jobs/Header";
import { JobsTable } from "features/Jobs/JobsTable";
import { NeedHelp } from "features/Jobs/NeedHelp";
import { useOrgParam } from "hooks/useOrgParam";
import { restClient } from "services/restClient/main";
import { JobsMetadata } from "services/restClient/RestClient";
import { useBoundStore } from "store/_boundStore";
import { selectedOrgSelector } from "store/orgStore";
import { zerofyHours, addDay, getSeconds } from "utils/dateUtils";
import firstJobJson from "./first_job.json";
import { TableContentWhenEmpty } from "./table-content-when-empty";

const pageTitle = "Jobs";

export function Jobs() {
  const _isMounted = useRef(true);
  const location = useLocation();

  const { orgParam } = useOrgParam();
  const [
    org,
    permissions,
    error,
    orgLoading,
    setErrorMessage,
    setSuccessMessage,
    userId,
  ] = useBoundStore((s) => [
    selectedOrgSelector(orgParam)(s),
    s.org.selected?.permissions,
    s.org.error,
    s.org.loading,
    s.message.setErrorMessage,
    s.message.setSuccessMessage,
    s.auth.getCurrentUserId(),
  ]);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(DEFAULT_PAGE_SIZE);
  const [searchTerm, setSearchTem] = useState("");
  const [sortField, setSortField] = useState(DEFAULT_SORT_FIELD);
  const [sortOrder, setSortOrder] = useState(DEFAULT_SORT_ORDER);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<Job[]>([]);
  const [metadata, setMetadata] = useState<JobsMetadata>({
    total: 0,
    jobTypes: [],
    jobUsers: [],
  });
  const [pickedJobType, setPickedJobType] = useState(-1);
  const [pickedJobUser, setPickedJobUser] = useState(userId ?? "");
  const [jobForShowingImages, setJobForShowingImages] = useState<null | Job>(
    null
  );
  const [selectedRange, setSelectedRange] = useState<Range | null>(null);

  const navigate = useNavigate();

  const clearAndSetSearchTerm = (term: string) => {
    setPage(0);
    setSearchTem(term);
  };
  const clearAndSetPickedJobType = (jobType: number) => {
    setPage(0);
    setPickedJobType(jobType);
  };

  const clearAndSetPickerUser = (jobUser: string) => {
    setPage(0);
    setPickedJobUser(jobUser);
  };

  const [selectedJobForDownload, setSelectedJobForDownload] =
    useState<Job | null>(null);
  const [selectedJobForDelete, setSelectedJobForDelete] = useState<Job | null>(
    null
  );

  const [selectedJobForCancel, setSelectedJobForCancel] = useState<Job | null>(
    null
  );

  const resetDatatable = () => {
    setRows([]);
    setMetadata({ total: 0, jobTypes: [], jobUsers: [] });
    setSearchTem("");
    setPage(0);
  };

  const onAddJobClick = () => {
    const canGoToWizard =
      org?.humans_wizard_enabled ||
      org?.scenarios_wizard_enabled ||
      IS_NEW_HUMANS_GA ||
      IS_SCENARIOS_GA;

    if (canGoToWizard) {
      navigate(`${Routes.HUMAN_JOB}?org=${orgParam}`);
    } else {
      navigate(`${Routes.CREATE_JOB}?org=${orgParam}`, {
        state: {
          name: "first_job",
          jsonStr: JSON.stringify(firstJobJson, null, 2),
        },
      });
    }
  };

  useEffect(() => {
    if (orgLoading) {
      resetDatatable();
    }
  }, [orgLoading]);

  const getJobsPromise = useCallback(
    (orgName: string) => {
      let startDate = 0;
      let endDate = 0;
      if (
        selectedRange?.startDate instanceof Date &&
        selectedRange?.endDate instanceof Date
      ) {
        startDate = getSeconds(zerofyHours(selectedRange.startDate));
        endDate = getSeconds(addDay(zerofyHours(selectedRange.endDate)));
      }

      return restClient.getJobs(
        orgName,
        page,
        size,
        searchTerm,
        sortField,
        sortOrder,
        startDate,
        endDate,
        pickedJobType,
        pickedJobUser
      );
    },
    [
      page,
      size,
      searchTerm,
      sortField,
      sortOrder,
      selectedRange?.startDate,
      selectedRange?.endDate,
      pickedJobType,
      pickedJobUser,
    ]
  );

  const fetchJobs = useCallback(
    async (showSpinnerWhileFetching: boolean) => {
      if (!org) {
        return;
      }
      if (showSpinnerWhileFetching) {
        setLoading(true);
      }
      try {
        if (!_isMounted.current) {
          return;
        }
        const result = await getJobsPromise(org.name);
        setRows(result.data);
        setMetadata(result.metadata);
      } catch (error) {
        if (!_isMounted.current) {
          return;
        }
        Sentry.captureException(error);
        setErrorMessage(restClient.APIErrorMessage(error));
      }
      setLoading(false);
    },
    [setErrorMessage, org, getJobsPromise]
  );

  // fetch jobs whenever the "fetchJobs" function change like when we change the search term or page
  useEffect(() => {
    if (!org) {
      return;
    }
    if (!hasValidBillMethod(org)) {
      navigate(Routes.ADD_PAYMENT_INFO + `?${URLParams.org}=${org.name}`);
    } else {
      fetchJobs(true);
    }
    return () => {
      _isMounted.current = true;
    };
  }, [org, fetchJobs, navigate]);

  // Refetch jobs each 30 seconds to show latest
  useEffect(() => {
    if (!org) {
      return;
    }
    const intervalId = setInterval(() => {
      fetchJobs(false);
    }, 30000);
    return () => clearInterval(intervalId);
  }, [fetchJobs, org]);

  if (error) {
    return (
      <Box component="div" data-testid="jobs-component" height="100%">
        <NavigationHeader selectedOrgName={org?.name} title={pageTitle} />
        <Typography color="error" variant="h1">
          {error}
        </Typography>
      </Box>
    );
  }

  if (orgLoading) {
    return (
      <Box component="div" data-testid="jobs-component" height="100%">
        <NavigationHeader selectedOrgName={org?.name} title={pageTitle} />
        <FullScreenProgress />
      </Box>
    );
  }

  const editJobName = async (jobId: string, name: string) => {
    setLoading(true);
    try {
      const orgName = org?.name;

      if (!orgName) {
        // TODO: Refactor ORG name to be required
        noOrgAlert();
        return;
      }

      await restClient.updateJob(orgName, jobId, { name });
      const result = await getJobsPromise(orgName);
      setRows(result.data);
      setMetadata(result.metadata);
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  const cancelJob = async () => {
    setLoading(true);
    try {
      const orgName = org?.name;

      if (!orgName) {
        // TODO: Refactor ORG name to be required
        noOrgAlert();
        return;
      }

      if (!selectedJobForCancel) {
        return;
      }

      await restClient.cancelJob(orgName, selectedJobForCancel?.id);
      const result = await getJobsPromise(orgName);
      setRows(result.data);
      setMetadata(result.metadata);
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  const deleteJob = async () => {
    setLoading(true);
    try {
      const orgName = org?.name;

      if (!orgName) {
        // TODO: Refactor ORG name to be required
        noOrgAlert();
        return;
      }

      if (!selectedJobForDelete) {
        return;
      }

      await restClient.deleteJob(orgName, selectedJobForDelete.id);
      const result = await getJobsPromise(orgName);
      setRows(result.data);
      setMetadata(result.metadata);
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  const resetJob = async (job: Job) => {
    setLoading(true);
    try {
      await restClient.resetJob(job.id);

      if (!org) {
        noOrgAlert();
        return;
      }

      const result = await getJobsPromise(org.name);
      setRows(result.data);
      setMetadata(result.metadata);
      setSuccessMessage("Job is being reset");
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  const onJobDuplicateClick = async (
    jobID: string,
    jobName: string,
    jobType: number
  ) => {
    if (!org) {
      noOrgAlert();
      return;
    }

    setLoading(true);
    try {
      if (jobType === JobType.Humans) {
        const resp: ArrayBuffer = await restClient.getJobSpec(org.name, jobID);
        const json = handleJobSpecJson(resp);
        navigate(Routes.JOB_BUILDER + location.search, {
          state: {
            name: jobName,
            json,
          },
        });
      } else {
        const resp = await restClient.getJobPresubmissionData(org.name, jobID);
        const draft = await restClient.createPL2Draft(
          orgParam,
          jobName,
          resp.state,
          resp.version
        );
        navigate(Routes.JOB_BUILDER2_FN(draft.id) + location.search);
      }
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
      setLoading(false);
    }
  };

  const onJobSpecDownloadClick = async (jobID: string, jobName: string) => {
    if (!org) {
      noOrgAlert();
      return;
    }

    setLoading(true);
    try {
      const resp: ArrayBuffer = await restClient.getJobSpec(org.name, jobID);
      const json = handleJobSpecJson(resp);
      fileDownload(JSON.stringify(json, null, 2), `${jobName}.json`);
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  const onPreviewClick = (job: Job) => {
    setJobForShowingImages(job);
  };

  const onPreviewClose = () => {
    setJobForShowingImages(null);
  };

  return (
    <>
      {jobForShowingImages && org && (
        <GalleryDialog
          jobID={jobForShowingImages.id}
          jobName={jobForShowingImages.job_name}
          orgName={org.name}
          onClose={onPreviewClose}
        />
      )}
      {!!selectedJobForDownload && !!org && (
        <DownloadAssetsDialog
          descriptions={[
            "To view available assets for this job:",
            'To download "rgb" and "info" assets for this job:',
          ]}
          jobID={selectedJobForDownload.id}
          jobName={selectedJobForDownload.job_name}
          orgName={org.name}
          title="Download Job Assets"
          onClose={() => setSelectedJobForDownload(null)}
        />
      )}

      {selectedJobForDelete && (
        <ConfirmationDialog
          textContent={
            <>
              Are you sure you want to delete{" "}
              <span className="bold-text">{selectedJobForDelete.job_name}</span>
              ?{" "}
            </>
          }
          title="Delete Job"
          onCancel={() => setSelectedJobForDelete(null)}
          onConfirm={() => {
            deleteJob();
            setSelectedJobForDelete(null);
          }}
        />
      )}

      {selectedJobForCancel && (
        <ConfirmationDialog
          textContent={
            <>
              Are you sure you want to cancel{" "}
              <span className="bold-text">{selectedJobForCancel.job_name}</span>
              ?{" "}
            </>
          }
          title="Cancel Job"
          onCancel={() => setSelectedJobForCancel(null)}
          onConfirm={() => {
            cancelJob();
            setSelectedJobForCancel(null);
          }}
        />
      )}

      <Box component="div" data-testid="jobs-component" height="100%">
        <NavigationHeader selectedOrgName={org?.name} title={pageTitle} />
        <Header
          currentJobType={pickedJobType}
          currentSelectedUser={pickedJobUser}
          jobTypeOptions={metadata.jobTypes}
          selectedRange={selectedRange}
          setSearchTerm={clearAndSetSearchTerm}
          userIdOptions={metadata.jobUsers}
          onAddJobClick={onAddJobClick}
          onRangeChange={setSelectedRange}
          onUpdateJobType={clearAndSetPickedJobType}
          onUserIdChange={clearAndSetPickerUser}
        />
        {!!rows.length && !!permissions && (
          <div className="datatableWrapper">
            <JobsTable
              isEditable={!!(permissions?.is_owner || permissions?.is_writer)}
              jobTypeOptions={metadata.jobTypes}
              loading={loading}
              paginationTotalRows={metadata.total}
              permissions={permissions}
              rows={rows}
              setPage={setPage}
              setSize={setSize}
              setSortField={setSortField}
              setSortOrder={setSortOrder}
              onCancelJob={setSelectedJobForCancel}
              onDeleteJob={setSelectedJobForDelete}
              onDownload={setSelectedJobForDownload}
              onEditJob={editJobName}
              onJobDuplicateClick={onJobDuplicateClick}
              onJobSpecDownloadClick={onJobSpecDownloadClick}
              onPreviewClick={onPreviewClick}
              onResetJob={resetJob}
            />
          </div>
        )}
        {Boolean(!loading && !rows.length) && (
          <TableContentWhenEmpty onAddJobClick={onAddJobClick} />
        )}
        <NeedHelp org={org} />
      </Box>
    </>
  );
}

function noOrgAlert() {
  alert("No organization name found. Please select an organization.");
}
