export function WindowOffsetVIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 19.5H21.5V19V5V4.5H21H3H2.5V5V19V19.5H3H21ZM3 3.5H21C21.8239 3.5 22.5 4.17614 22.5 5V19C22.5 19.8239 21.8239 20.5 21 20.5H3C2.17614 20.5 1.5 19.8239 1.5 19V5C1.5 4.17614 2.17614 3.5 3 3.5ZM5.5 15.5H18.5V17.5H5.5V15.5Z"
        fill={fill}
        stroke={fill}
      />
    </svg>
  );
}
