import { SimpleSidebarItem } from "features/JobBuilder/LeftSidebar/SimpleSidebarItem";
import { selectActiveSubjobRendersPerIdentity } from "features/JobBuilder/store";
import { useBoundStore } from "store/_boundStore";

export function ScenesPerIdentity() {
  const scenesAmount = useBoundStore(selectActiveSubjobRendersPerIdentity);
  return (
    <SimpleSidebarItem
      additionalInfo={scenesAmount}
      icon={"RenderIcon"}
      id="identities"
      label="ScenesPerIdentity"
    />
  );
}
