export function DemographicSelectionIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 19V18.5H8H3C2.16777 18.5 1.5 17.8255 1.5 17V5C1.5 4.16614 2.16614 3.5 3 3.5H21C21.8255 3.5 22.5 4.16777 22.5 5V17C22.5 17.8239 21.8239 18.5 21 18.5H16H15.5V19V20.5H8.5V19ZM21 17.5H21.5V17V5V4.5H21H3H2.5V5V17V17.5H3H21ZM15.5 10.5V11.5H8.5V10.5H15.5Z"
        fill={fill}
        stroke={fill}
      />
    </svg>
  );
}
