import { OrgPermissions } from "domain/Permissions";

export function roleStrToObject(role: "reader" | "writer" | "owner") {
  const is_owner = role === "owner";
  const is_writer = role === "writer" || is_owner;
  const is_reader = role === "reader" || is_writer;
  return { is_owner, is_writer, is_reader };
}

export function roleObjectToStr(
  orgPermissions: OrgPermissions
): "reader" | "writer" | "owner" {
  return orgPermissions.is_owner
    ? "owner"
    : orgPermissions.is_writer
    ? "writer"
    : "reader";
}
