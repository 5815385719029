import { geom2 } from "@flasd/modeling/src/geometries";
import { Geom2 } from "@flasd/modeling/src/geometries/types";
import {
  intersect,
  subtract,
  union,
} from "@flasd/modeling/src/operations/booleans";
import produce from "immer";
import { pullAt, uniq } from "lodash";
import { GeomRegion } from "features/JobBuilder2/steps/threeDimensional/GeomRegion";
import { area } from "features/JobBuilder2/steps/threeDimensional/measurement-operations";
import { activityZonesInit } from "features/JobBuilder2/steps/threeDimensional/utils/activity-zones-init";
import { activittyFitsGeom } from "features/JobBuilder2/steps/threeDimensional/utils/getCircleInPolygon";
import { fromVec2sToGeom2 } from "features/JobBuilder2/steps/threeDimensional/utils/polygon-converters";
import { segmentTrack } from "features/JobBuilder2/track-service";
import {
  ActivitiesState,
  defaultSubjob,
  FrameCutoffMode,
  getNewPipe2Subjob,
  Pipe2StepEnum,
} from "features/JobBuilder2/types";
import { GetState, SetState } from "store/_boundStore";
import { selectFirstPrefabGroupID } from "store/catalogueDataStore";
import { selectPickedSubjob } from "./selectors";

export function createActions(set: SetState, get: GetState, initialState: any) {
  return {
    updateJobName: (jobName: string) =>
      set((state) => {
        state.jobBuilder2.jobName = jobName;
      }),
    setPickedSubjobIndex: (subjobIndex: number) =>
      set((state) => {
        state.jobBuilder2.pickedSubjobIndex = subjobIndex;
      }),
    setSubjobs: (subjobs: (typeof defaultSubjob)[]) =>
      set((state) => {
        state.jobBuilder2.subjobs = subjobs;
      }),
    updatePickedSubjob: (subjobData: typeof defaultSubjob) => {
      const { subjobs, pickedSubjobIndex, setSubjobs } = get().jobBuilder2;

      const newSubjobsData = produce(subjobs, (subjob) => {
        subjob[pickedSubjobIndex] = subjobData;
      });

      setSubjobs(newSubjobsData);
    },
    setShowHelpDialog: (showHelpDialog: boolean) =>
      set((state) => {
        state.jobBuilder2.showHelpDialog = showHelpDialog;
      }),
    addNewSubjob: () => {
      set((state) => {
        const locationOptions = state.jobBuilder2.subjobs[0].locationOptions;
        state.jobBuilder2.subjobs.push({
          ...getNewPipe2Subjob(),
          locationOptions,
          location: locationOptions[0],
          activityZones: activityZonesInit(locationOptions[0]),
        });
      });
    },
    deleteSubjob: (subjobIndex: number) => {
      set((state) => {
        const oldSubjobs = state.jobBuilder2.subjobs;
        const oldPickedSubjobIndex = state.jobBuilder2.pickedSubjobIndex;
        state.jobBuilder2.subjobs = oldSubjobs.filter(
          (_, i) => i !== subjobIndex
        );
        state.jobBuilder2.pickedSubjobIndex =
          oldPickedSubjobIndex >= oldSubjobs.length - 1
            ? oldSubjobs.length - 2
            : oldPickedSubjobIndex;
      });
    },
    duplicateSubjob: (subjobIndex: number) => {
      set((state) => {
        state.jobBuilder2.subjobs.push(state.jobBuilder2.subjobs[subjobIndex]);
        state.jobBuilder2.pickedSubjobIndex =
          state.jobBuilder2.subjobs.length - 1;
      });
    },

    // navigations
    setSelectedStep: (step: Pipe2StepEnum | number) =>
      set((state) => {
        state.jobBuilder2.selectedStep = step;
      }),
    goToNextStep: () =>
      set((state) => {
        state.jobBuilder2.selectedStep++;
      }),
    goToPrevStep: () =>
      set((state) => {
        state.jobBuilder2.selectedStep--;
      }),

    // 2nd step: Set rig location
    setIsCameraRigDialogOpened: (opened: boolean) =>
      set((state) => {
        state.jobBuilder2.isCameraRigDialogOpened = opened;
      }),
    setSelectedRigLocation: (rigIndex: number) => {
      const state = get();
      const pickedSubjob = selectPickedSubjob(state);
      const updatedSubjobData = produce(pickedSubjob, (d) => {
        d.rigLocationIndex = rigIndex;
        d.distribution.configs[FrameCutoffMode.static].lastFrameNumber = 1;
      });

      state.jobBuilder2.updatePickedSubjob(updatedSubjobData);
      const userID = state.profile.data?.id;
      segmentTrack("set rig location", userID, {
        locationName: pickedSubjob.location,
      });
    },

    // 3rd Step: Add/Remove ActivityZones
    onRemoveActivityZone: (i: number) => {
      const state = get();
      const pickedSubjob = selectPickedSubjob(state);
      const updatedSubjobData = produce(pickedSubjob, (d) => {
        pullAt(d.activityZones, i);
        d.distribution.configs = { ...defaultSubjob.distribution.configs };
      });
      state.jobBuilder2.updatePickedSubjob(updatedSubjobData);
    },

    onGeomDrawn: (newGeom: Geom2) => {
      const state = get();
      const pickedSubjob = selectPickedSubjob(state);

      // if any of the new points is outside original background region shell, alert and return
      const pl2Location = state.catalogData.locations.find(
        (l) => l.name === pickedSubjob.location
      )!;

      // dismiss any part of the geom that's not inside the background shell
      newGeom = intersect(
        newGeom,
        geom2.create([pl2Location.backgroundActionRegion.shell])
      );
      if (area(newGeom) === 0) {
        return;
      }
      const standingZones = pickedSubjob.activityZones.filter(
        (a) => a.geomRegion.type === "drawn"
      );
      const indecisToBeRemoved: number[] = [];

      let newActivitiesNames: string[] = [];
      const toBeUnioned: Geom2[] = [];
      standingZones.forEach((zone, i) => {
        const intersected = intersect(zone.geomRegion.geom, newGeom);
        if (intersected.outlines.length) {
          toBeUnioned.push(zone.geomRegion.geom);
          indecisToBeRemoved.push(i);
          // eat activities of intersecting geom
          newActivitiesNames = newActivitiesNames.concat(zone.activities);
        }
      });
      newGeom = union(newGeom, toBeUnioned);
      if (indecisToBeRemoved.length) {
        pullAt(standingZones, indecisToBeRemoved);
      }

      const avoidRegions = pl2Location.backgroundActionRegion.avoid_regions.map(
        (a) => fromVec2sToGeom2(a.shell)
      );
      // subract sittingGeoms
      const sittingGeoms = pickedSubjob.activityZones
        .filter((a) => a.geomRegion.type === "seated")
        .map((a) => a.geomRegion.geom);

      const toBeSubtracted: Geom2[] = [];
      const doNotTouchZones = sittingGeoms
        .map((g) => ({ geom: g, type: "sitting" }))
        .concat(avoidRegions.map((g) => ({ geom: g, type: "avoid" })));
      for (const doNotTouchZone of doNotTouchZones) {
        const intersected = intersect(doNotTouchZone.geom, newGeom);
        if (intersected.outlines.length) {
          toBeSubtracted.push(intersected);
        }
      }
      newGeom = subtract(newGeom, toBeSubtracted);
      standingZones.push({
        activities: uniq(newActivitiesNames).filter(activittyFitsGeom(newGeom)),
        prefabGroupId: selectFirstPrefabGroupID(state),
        geomRegion: new GeomRegion(newGeom, "drawn", ""),
      });

      const newActivityZones = pickedSubjob.activityZones
        .filter((a) => a.geomRegion.type !== "drawn")
        .concat(standingZones)
        .filter((a) => a.geomRegion.type !== "background");

      const updatedSubjobData = produce(pickedSubjob, (d) => {
        d.activityZones = newActivityZones;
        d.distribution.configs = { ...defaultSubjob.distribution.configs };
      });
      state.jobBuilder2.updatePickedSubjob(updatedSubjobData);
    },

    // 4th Step: Assign activities
    setActivitiesPickerDialogData: (data: ActivitiesState | null) => {
      set((state) => {
        state.jobBuilder2.activitiesPickerDialogData = data;
        state.jobBuilder2.pickedActivityZoneIndex = data ? data.zoneIndex : 0;
      });
    },
    updateSelectedActivitiesOfZone: (activities: string[]) => {
      const state = get();
      const {
        activitiesPickerDialogData,
        setActivitiesPickerDialogData,
        updatePickedSubjob,
      } = state.jobBuilder2;
      const pickedSubjob = selectPickedSubjob(state);
      const { data } = state.profile;

      const updatedSubjobData = produce(pickedSubjob, (d) => {
        if (!activitiesPickerDialogData) {
          return;
        }
        d.activityZones[activitiesPickerDialogData.zoneIndex].activities =
          activities;
        d.distribution.configs = { ...defaultSubjob.distribution.configs };
      });

      segmentTrack("pl2 activities set", data?.id, {
        activities,
        location: pickedSubjob.location,
      });
      setActivitiesPickerDialogData(null);
      updatePickedSubjob(updatedSubjobData);
    },

    // 5th Step: Assign Character Group
    setIsCharacterGroupDialogOpened: (
      opened: boolean,
      pickedZoneIndex?: number
    ) => {
      set((state) => {
        state.jobBuilder2.isCharacterGroupDialogOpened = opened;
        if (typeof pickedZoneIndex === "number") {
          state.jobBuilder2.pickedActivityZoneIndex = pickedZoneIndex;
        }
      });
    },
    updateSelectedPrefabGroupOfZone: (prefabGroupId: string) => {
      const state = get();
      const {
        pickedActivityZoneIndex,
        setIsCharacterGroupDialogOpened,
        updatePickedSubjob,
      } = state.jobBuilder2;
      const pickedSubjob = selectPickedSubjob(state);
      const { data } = state.profile;

      setIsCharacterGroupDialogOpened(false);

      const updatedSubjobData = produce(pickedSubjob, (d) => {
        d.activityZones[pickedActivityZoneIndex].prefabGroupId = prefabGroupId;
      });

      segmentTrack("updated prefab group", data?.id, {
        activityZoneIdex: pickedActivityZoneIndex,
        location: pickedSubjob.location,
        value: prefabGroupId,
      });

      updatePickedSubjob(updatedSubjobData);
    },
    setHoveredActivityZone: (hoveredActivityZone: number) => {
      set((state) => {
        state.jobBuilder2.hoveredActivityZone = hoveredActivityZone;
      });
    },
    clearState: () => {
      set((state) => {
        state.jobBuilder2 = { ...state.jobBuilder2, ...initialState };
      });
    },
  };
}
