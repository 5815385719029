import React, { useCallback, useState } from "react";
import { TreeView } from "@mui/lab";
import { Box } from "@mui/material";
import produce from "immer";
import { ChevronDownIcon, ChevronRightIcon } from "assets/icons";
import {
  ActionOnHoverContainer,
  OnHoverAction,
} from "components/ActionOnHoverContainer";
import { colors } from "components/App/theme";
import { NewUIConfirmationDialog } from "components/dialogs/NewUIConfirmationDialog";
import { defaultSection, leftSidebarDists } from "domain/Characters";
import "features/JobBuilder/LeftSidebar/left-sidebar.css";
import { DeepDists } from "features/JobBuilder/LeftSidebar/DeepDists";
import { JobInfo } from "features/JobBuilder/LeftSidebar/JobInfo";
import {
  ReverseTreeItem,
  SubjobContainer,
} from "features/JobBuilder/LeftSidebar/left-sidebar.styled";
import { ScenesPerIdentity } from "features/JobBuilder/LeftSidebar/ScenesPerIdentity";
import { SectionLabel } from "features/JobBuilder/LeftSidebar/SectionLabel";
import { AdditionalInfo } from "features/JobBuilder/LeftSidebar/SectionLabel/section-label.styled";
import { StageView } from "features/JobBuilder/LeftSidebar/StageView/StageView";
import { TopLevelDist } from "features/JobBuilder/LeftSidebar/TopLevelDist";
import { useExpandedNodes } from "features/JobBuilder/LeftSidebar/useExpandedNodes";
import {
  selectNumberOfImagesForAllSubjobsAsString,
  selectNumberOfImagesForEachSubjob,
  selectPickedSubjob,
  selectPickedSubjobIndex,
  selectSelectedHumanObjectPart,
  selectSelectedRigIndex,
  selectSubjobs,
  selectTemplate,
} from "features/JobBuilder/store";
import { push, splice } from "features/JobBuilder/utils";
import { useBoundStore } from "store/_boundStore";
import { compact, getNumberOfImagesString } from "utils/stringUtil";

export function JobBuilderLeftSidebar({ loading }: { loading: boolean }) {
  const templateName = useBoundStore(selectTemplate)?.name || "first_job";
  const subjobs = useBoundStore(selectSubjobs);
  const pickedSubjobIndex = useBoundStore(selectPickedSubjobIndex);
  const pickedSubjob = useBoundStore(selectPickedSubjob);
  const selectedHumanObjectPart = useBoundStore(selectSelectedHumanObjectPart);

  const displayNumberOfImagesForAllSections = useBoundStore(
    selectNumberOfImagesForAllSubjobsAsString
  );

  const numberOfImagesForEachSection = useBoundStore(
    selectNumberOfImagesForEachSubjob
  );

  // Camera & Lights (Rigs)
  const selectedRigIndex = useBoundStore(selectSelectedRigIndex);

  const {
    setSubjobs,
    setPickedSubjobIndex,
    setSelectedHumanObjectPart,
    updateJobName,
  } = useBoundStore.getState().jobBuilder;

  const selectedTreeNode =
    selectedHumanObjectPart === "rigs"
      ? `${"rigs"}${selectedRigIndex}`
      : `${selectedHumanObjectPart}`;

  const [subJobForDeletion, setSubJobForDeletion] = useState(-1);

  const openEditor = useBoundStore((s) => s.jobBuilder.openEditor);

  const onAddSubjob = () => {
    setSubjobs(produce(push(defaultSection)));
  };

  const deleteSubjob = (subjobIndex: number) => {
    setSubjobs(produce(splice(subjobIndex)));
    // in case we are standing on the last section and then deleting it
    if (pickedSubjobIndex >= subjobs.length - 1) {
      setPickedSubjobIndex(subjobs.length - 2);
    }

    setSubJobForDeletion(-1);
  };

  const onSelectIdentities = () => {
    setSelectedHumanObjectPart("demographicBuilder");
  };
  const allowSubjobDeletion = numberOfImagesForEachSection.length > 1;
  const getSubjobOnHoverActions = useCallback(
    (subjobIndex: number): OnHoverAction[] => {
      if (allowSubjobDeletion) {
        return [
          {
            icon: "DeleteIcon",
            key: "delete",
            onClick: () => {
              if (allowSubjobDeletion) {
                setSubJobForDeletion(subjobIndex);
              }
            },
          },
        ];
      }

      return [];
    },
    [allowSubjobDeletion]
  );

  const [expanded, handleNodeToggle] = useExpandedNodes(loading);

  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column"
      position="sticky"
      style={{
        color: "white",
        // the height will be viewport height minus header and footer heights
        height: "calc(100vh - 70px - 52px)",
        pointerEvents: openEditor ? "none" : "auto",
      }}
      top={70}
    >
      <JobInfo
        templateName={templateName}
        totalNumberOfImages={displayNumberOfImagesForAllSections}
        onAddSection={onAddSubjob}
        onUpdateJobName={updateJobName}
      />
      <SubjobContainer>
        {numberOfImagesForEachSection.map((n, i) => (
          <Box key={i} component="div" onClick={() => setPickedSubjobIndex(i)}>
            <ActionOnHoverContainer actions={getSubjobOnHoverActions(i)}>
              <SectionLabel
                additionalInfo={
                  <AdditionalInfo selected={pickedSubjobIndex === i}>
                    ({getNumberOfImagesString(n)} images)
                  </AdditionalInfo>
                }
                icon="SubJobIcon"
                label={`Sub-job ${i + 1}`}
                selected={pickedSubjobIndex === i}
                variant="subsection"
              />
            </ActionOnHoverContainer>
          </Box>
        ))}
      </SubjobContainer>

      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={
          <ChevronDownIcon style={{ color: colors.grey70 }} />
        }
        defaultExpandIcon={
          <ChevronRightIcon style={{ color: colors.grey70 }} />
        }
        expanded={expanded}
        selected={selectedTreeNode}
        style={{
          maxWidth: 400,
          overflowY: "auto",
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
        onNodeToggle={handleNodeToggle}
      >
        {/* Identities */}
        <ReverseTreeItem
          label={
            <SectionLabel
              additionalInfo={
                <AdditionalInfo
                  selected={selectedHumanObjectPart === "demographicBuilder"}
                >
                  ({compact(subjobs[pickedSubjobIndex].identities.ids.length)})
                </AdditionalInfo>
              }
              icon="IdentitiesIcon"
              label="Identities"
              selected={selectedHumanObjectPart === "demographicBuilder"}
            />
          }
          nodeId="identities"
          onClick={onSelectIdentities}
        />
        {/* Scenes per identity */}
        <ScenesPerIdentity />
        {leftSidebarDists.map((item) =>
          "dists" in item ? (
            <DeepDists key={item.category} {...item} />
          ) : (
            <TopLevelDist key={item.id} {...item} />
          )
        )}
        What?
        {/* Stage View */}
        <StageView rigsData={pickedSubjob.rigs} />
      </TreeView>
      {subJobForDeletion >= 0 && (
        <NewUIConfirmationDialog
          icon="TwoToneDeleteIcon"
          onCancel={() => {
            setSubJobForDeletion(-1);
          }}
          onConfirm={() => deleteSubjob(subJobForDeletion)}
        >
          <Box component="div">
            Are you sure you want to remove Sub-job {subJobForDeletion + 1}?
          </Box>
        </NewUIConfirmationDialog>
      )}
    </Box>
  );
}
