// noinspection RequiredAttributes

import { useCallback, useMemo, useState } from "react";
import { geom2 } from "@flasd/modeling/src/geometries";
import { rectangle } from "@flasd/modeling/src/primitives";
import { Videocam } from "@mui/icons-material";
import { Html, Line } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import * as THREE from "three";
import { Vector2, Vector3 } from "three";
import { colors } from "components/App/theme";
import { getRigLocationImage } from "features/JobBuilder2/locations-data";
import { CameraPresetLocationPopup } from "features/JobBuilder2/steps/threeDimensional/CameraPresetLocationPopup/CameraPresetLocationPopup";
import { TransformedCameraPresetLocation } from "features/JobBuilder2/steps/threeDimensional/utils/Location";
import {
  fromVec2ToVector2,
  connectVec2s,
} from "features/JobBuilder2/steps/threeDimensional/utils/polygon-converters";
import { selectPickedSubjob } from "features/JobBuilder2/store";
import { useBoundStore } from "store/_boundStore";
import { selectPL2LocationDimension } from "store/selectors/selectPL2LocationDimension";
import { useCursor2 } from "./utils/useCursor2";

const rectangleSizeFactor = 0.15;
const defaultRectangleDimensions = new Vector2(0.25, 0.8);
const videocamIconSizeFactor = 7;
const distanceFromPopupToRigWidthFactor = 3;
const zIndex = 0;

type CameraPresetLocationProps = {
  index: number;
  preset: TransformedCameraPresetLocation;
  interactive: boolean;
};

export function CameraPresetLocation(props: CameraPresetLocationProps) {
  const { index, preset, interactive } = props;

  const [isDragging, setSelectedRigLocation, pickedSubjob, locationDimensions] =
    useBoundStore((s) => [
      s.userControls.dragging,
      s.jobBuilder2.setSelectedRigLocation,
      selectPickedSubjob(s),
      selectPL2LocationDimension(s),
    ]);

  const onPickRigLocation = useCallback(() => {
    if (isDragging) {
      return;
    }
    setSelectedRigLocation(index);
  }, [isDragging, index, setSelectedRigLocation]);

  const zoom = useThree((s) => s.camera.zoom);

  // Tells the rotation angle of the camera rig in the x-axis
  const facingDirection = useMemo(
    () => fromVec2ToVector2(preset.facing_direction),
    [preset.facing_direction]
  );

  /**
   * Converting the facing direction into the equivalent representation
   * using Euler angles so we can use it in UI.
   */
  const euler = useMemo(() => {
    /**
     * A quaternion is a type of mathematical object that can be used to represent rotations using
     * four-dimensional space. See https://youtu.be/d4EgbgTm0Bg for a good explanation.
     */
    const quaternion = new THREE.Quaternion().setFromUnitVectors(
      new THREE.Vector3(1, 0, 0),
      new Vector3(facingDirection.x, facingDirection.y, 0)
    );
    return new THREE.Euler().setFromQuaternion(quaternion);
  }, [facingDirection]);

  const rectangleDimensions = useMemo(() => {
    const locationSize = new Vector2(
      locationDimensions.height,
      locationDimensions.width
    ).length();
    return defaultRectangleDimensions.clampLength(
      locationSize * rectangleSizeFactor,
      locationSize * rectangleSizeFactor
    );
  }, [locationDimensions]);

  // Vector 2 vertices of the rectangle
  const points = useMemo(() => {
    return connectVec2s(
      geom2.toPoints(
        rectangle({
          size: [rectangleDimensions.width, rectangleDimensions.height],
        })
      )
    ).map(fromVec2ToVector2);
  }, [rectangleDimensions]);

  // coordinates for the white lines (forming triangle)
  const directionLinePoints = useMemo(() => {
    const xDistance = rectangleDimensions.width / 2;
    const yDistance = rectangleDimensions.height / 2;
    const topPoint = [xDistance, yDistance];
    const downPoint = [xDistance, -yDistance];
    const centerPoint = [0, 0];
    return [topPoint, centerPoint, downPoint] as Array<[number, number]>;
  }, [rectangleDimensions]);

  // dashes in the sides for the rectangle
  const dashSize = useMemo(() => 20 / zoom, [zoom]);
  const gapSize = dashSize * 0.5;

  const isActiveLocation = pickedSubjob.rigLocationIndex === index;

  const [mouseEntered, setMouseEntered] = useState(false);
  const hovered = mouseEntered && !isDragging;

  useCursor2(hovered, "pointer");

  const color = hovered || isActiveLocation ? colors.orange : colors.white;

  const [cameraIconColor, cameraIconBgColor] = useMemo(() => {
    if (!interactive) {
      return [colors.white, colors.cerulean];
    } else if (isActiveLocation) {
      return [colors.white, colors.bright[2]];
    } else if (hovered) {
      return [colors.white, colors.bright[0]];
    }

    return [colors.black, colors.white];
  }, [hovered, isActiveLocation, interactive]);

  return (
    <group
      position={new Vector3(preset.position[0], preset.position[1], zIndex)}
    >
      <group
        rotation={euler}
        onClick={onPickRigLocation}
        onPointerEnter={() => setMouseEntered(true)}
        onPointerLeave={() => setMouseEntered(false)}
      >
        {interactive && (
          <mesh>
            <boxGeometry
              args={[rectangleDimensions.width, rectangleDimensions.height]}
            />
            <meshBasicMaterial transparent color={color} opacity={0.5} />
          </mesh>
        )}
        {/* dashed borders */}
        <Line
          color={interactive ? color : colors.cerulean}
          dashSize={dashSize}
          dashed={true}
          depthFunc={THREE.AlwaysDepth}
          depthTest={false}
          depthWrite={false}
          gapSize={gapSize}
          lineWidth={1}
          points={points}
          renderOrder={2}
          transparent={true}
        />
        {/* direction lines points inside the rectange that emphasize the angle of camera */}
        <Line
          color={interactive ? colors.white : colors.cerulean}
          depthFunc={THREE.AlwaysDepth}
          depthTest={false}
          depthWrite={false}
          lineWidth={2}
          points={directionLinePoints}
          renderOrder={2}
          transparent={true}
        />
        {/* video cam icon and the circle surrounding it */}
        <Html
          transform
          distanceFactor={videocamIconSizeFactor * rectangleDimensions.width}
          pointerEvents="none"
          zIndexRange={[2, 5]}
          onClick={onPickRigLocation}
        >
          <button
            data-testid={`camera-preset-location-${index}`}
            style={{
              backgroundColor: cameraIconBgColor,
              color: cameraIconColor,
              display: "flex",
              justifyContent: "center",
              borderRadius: 20,
              padding: 5,
              border: "none",
            }}
            tabIndex={0}
            onClick={onPickRigLocation}
          >
            <Videocam />
          </button>
        </Html>
      </group>
      {isActiveLocation && interactive && (
        <CameraPresetLocationPopup
          distanceFromRig={
            rectangleDimensions.width * distanceFromPopupToRigWidthFactor
          }
          facingDirection={facingDirection}
          imageLink={getRigLocationImage(pickedSubjob.location, preset.name)}
        />
      )}
    </group>
  );
}
