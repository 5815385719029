import { useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as UploadIcon } from "assets/diagrams/upload.svg";
import { Routes } from "constants/routes";
import { jobBuilder1Enabled } from "domain/Human";
import { DeepPartial, JsonInput } from "features/JobBuilder/json-types";
import { SmallTitle } from "features/JobBuilderShared/Styles/styles";
import { useBoundStore } from "store/_boundStore";
import { selectedOrgSelector } from "store/orgStore";
import {
  DropzoneContainer,
  IconContainer,
  LinkLike,
} from "./upload-tab.styled";

type UploadTabProps = {
  orgName: string;
  title: string;
};

export function UplaodTab(props: UploadTabProps) {
  const { orgName, title } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const org = useBoundStore(selectedOrgSelector(orgName));

  const onDrop: DropzoneOptions["onDrop"] = useCallback(
    (acceptedFiles: (Blob & { name: string })[]) => {
      const onLocalFileUpload = (
        name: string,
        json: DeepPartial<JsonInput>
      ) => {
        if (!org) {
          return;
        }
        if (jobBuilder1Enabled(org)) {
          navigate(Routes.JOB_BUILDER + location.search, {
            state: {
              name,
              json,
            },
          });
        } else {
          navigate(Routes.CREATE_JOB + location.search, {
            state: {
              name,
              jsonStr: JSON.stringify(json, null, 2),
            },
          });
        }
      };
      acceptedFiles.forEach((file: Blob & { name: string }) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          const str = reader.result as string;
          onLocalFileUpload(
            file.name.replace(/\.[^/.]+$/, ""),
            JSON.parse(str)
          );
        };
        reader.readAsText(file);
      });
    },
    [location.search, navigate, org]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: "application/json",
  });

  return (
    <>
      <SmallTitle sx={{ mb: 2 }}>{title}</SmallTitle>
      <DropzoneContainer {...getRootProps()}>
        <input {...getInputProps()} />
        <Box component="div">
          <IconContainer>
            <UploadIcon />
          </IconContainer>
          <Typography>
            <strong>
              <LinkLike>Select a JSON file</LinkLike> to upload
            </strong>
          </Typography>
          <Typography color="text.secondary">
            or drag and drop it here
          </Typography>
        </Box>
      </DropzoneContainer>
    </>
  );
}
