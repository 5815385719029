import { useCallback, useMemo } from "react";
import { produce } from "immer";
import skinResolutionDiagram from "assets/diagrams/body-skin-resolution.svg";
import { BodyIcon } from "assets/icons";
import { BooleanSelector } from "components/BooleanSelector";
import { ProTip } from "components/ProTip";
import { RightAsidePreviewFull } from "components/RightAsidePreviewFull";
import { PreviewContent } from "components/RightAsidePreviewFull/PreviewContent";
import { bodyImageURL } from "constants/constants";
import { Messages } from "constants/messages";
import { defaultSkin } from "domain/Human";
import { EmptyState } from "features/JobBuilder/empty-state";
import { convertBodiesStateToJson } from "features/JobBuilder/form-to-input-json";
import { getBodies, getHumanObject } from "features/JobBuilder/json-extracter";
import { JsonManagerNewUI } from "features/JobBuilder/json-manager-new-ui";
import { convertBodies } from "features/JobBuilder/json-to-form";
import { JsonHuman } from "features/JobBuilder/json-types";
import { ContentCard, Page } from "features/JobBuilder/layout";
import { MultiOptionImageGrid } from "features/JobBuilder/MultiOptionImageGrid";
import {
  heightSelector,
  fatContentSelector,
  selectActiveSubjobSkin,
} from "features/JobBuilder/store";
import { useDistPage } from "features/JobBuilder/useDistPage";
import {
  useImageSelection,
  useSelectionOutcomeCount,
} from "features/JobBuilder/useImageSelection";
import { changeEventValue } from "features/JobBuilder/utils";
import { useBoundStore } from "store/_boundStore";
const id = "body";
export function JobBuilderBody() {
  const {
    onUpdate,
    value,
    selectedGroupIndex,
    isSummingTo100,
    toggleDistListItem,
    onUpdatePart,
    onUpdatePercent,
    onAdd,
    numberOfImages,
  } = useDistPage(id);
  const skinValue = useBoundStore(selectActiveSubjobSkin);

  const selectedDistributionGroup = value[selectedGroupIndex];

  const [
    setErrorMessage,
    setSuccessMessage,
    isSupportUser,
    fatContentOptions,
    heightOptions,
    onUpdateSkin,
  ] = useBoundStore((s) => [
    s.message.setErrorMessage,
    s.message.setSuccessMessage,
    s.profile.data?.is_support,
    fatContentSelector(s),
    heightSelector(s),
    s.jobBuilder.onUpdateSkin,
  ]);

  const onUpdateSkinLocal = useCallback(
    (fn: (draft: typeof defaultSkin) => void) => {
      onUpdateSkin(produce(skinValue, fn));
    },
    [onUpdateSkin, skinValue]
  );

  const onUpdateEnabled = onUpdatePart("enabled");

  const heightUIState = useImageSelection(
    selectedDistributionGroup ? selectedDistributionGroup.height : [],
    heightOptions,
    bodyImageURL("height"),
    onUpdatePart("height"),
    "Height",
    "body-heaight",
    "HeightIcon",
    toggleDistListItem("height", heightOptions)
  );

  const fatContentUIState = useImageSelection(
    selectedDistributionGroup ? selectedDistributionGroup.fat_content : [],
    fatContentOptions,
    bodyImageURL("fat"),
    onUpdatePart("fat_content"),
    "Fat Content",
    "body-fat",
    "FatContentIcon",
    toggleDistListItem("fat_content", fatContentOptions)
  );

  const outcomes = useSelectionOutcomeCount([heightUIState, fatContentUIState]);

  const onUpdateHighResolutionSkinTexture = () =>
    onUpdateSkinLocal((d) => {
      d.highest_resolution = !d.highest_resolution;
    });

  const onIndecesReceived = (indices: number[], humansArr: JsonHuman[]) => {
    try {
      const humanObject = getHumanObject(humansArr, indices[0]);
      const jsonBodies = getBodies(humanObject);
      onUpdate(convertBodies(jsonBodies, value));
      setSuccessMessage("updated bodies");
    } catch (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
      }
    }
  };

  // TODO: Type this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDoneJson = (json: any) => {
    const obj = convertBodies(json, value);
    onUpdate(obj);
  };

  const jsonValue = useMemo(() => convertBodiesStateToJson(value), [value]);

  const isEmptyState = !value[selectedGroupIndex];

  const center = (
    <JsonManagerNewUI
      key={`distributionGroup-${selectedGroupIndex}`}
      emptyState={
        isEmptyState ? (
          <EmptyState
            description="Bodies controls the bodies variations of the character models. Default bodies variations are applied, some sample results are shown below:"
            folder="bodies"
            icon={<BodyIcon style={{ width: 48, height: 48 }} />}
            title="Default bodies settings applied"
          />
        ) : null
      }
      groupIndex={selectedGroupIndex + 1}
      groupPercentage={
        selectedDistributionGroup?.percent !== "NaN"
          ? selectedDistributionGroup?.percent
          : ""
      }
      id={`distributionGroup-${selectedGroupIndex}`}
      isSummingTo100={isSummingTo100}
      jsonValue={jsonValue}
      numberOfImages={numberOfImages}
      title={`Body`}
      titleCount={outcomes}
      titleIcon="BodyIcon"
      unknownIndicesKeys={["Human"]}
      onClickAddButton={onAdd}
      onDoneEditJson={onDoneJson}
      onIndecesReceived={onIndecesReceived}
      onUpdatePercent={onUpdatePercent}
    >
      <ContentCard>
        <ProTip label={Messages.bodyPropTip} />
        <BooleanSelector
          description={Messages.bodyGeometryInfo}
          icon={"FactCheckIcon"}
          imgSrc={skinResolutionDiagram}
          label="Enabled"
          title="Include Body Geometry"
          value={value[selectedGroupIndex]?.enabled}
          onUpdate={changeEventValue(onUpdateEnabled)}
        />
      </ContentCard>

      <ContentCard>
        <MultiOptionImageGrid {...heightUIState} />
      </ContentCard>

      <ContentCard>
        <MultiOptionImageGrid {...fatContentUIState} />
      </ContentCard>

      {isSupportUser && (
        <ContentCard>
          <BooleanSelector
            description={Messages.bodyHighResolutionSkinTextureInfo}
            icon={"FactCheckIcon"}
            label="Enabled"
            title="High Resolution Skin Texture"
            value={skinValue.highest_resolution}
            onUpdate={onUpdateHighResolutionSkinTexture}
          />
        </ContentCard>
      )}
    </JsonManagerNewUI>
  );

  const rightAside = (
    <RightAsidePreviewFull previewTitle="Body Preview">
      <PreviewContent data={heightUIState.selectedImages} title="HEIGHT" />
      <PreviewContent
        data={fatContentUIState.selectedImages}
        title="FAT CONTENT"
      />
    </RightAsidePreviewFull>
  );
  return <Page fixedRightSide center={center} rightAside={rightAside} />;
}
