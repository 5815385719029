import { SelectChangeEvent, Tooltip } from "@mui/material";
import * as Icons from "assets/icons";
import { InfoIcon } from "assets/icons";
import { colors } from "components/App/theme";
import { SimpleDropdown } from "components/SimpleDropdown";
import { IconsType } from "types/main";
import {
  Container,
  DropdownContainer,
  InfoTooltipContainer,
  Title,
} from "./DropdownWithTitle.styled";

export function DropdownWithTitle({
  icon,
  label,
  options,
  value,
  extraInfo,
  onUpdate,
}: {
  icon?: IconsType;
  label: string;
  options: string[];
  value: string;
  extraInfo?: string | React.ReactChild;
  onUpdate: (v: string) => void;
}) {
  const Icon = Icons[icon ?? "AccessoriesIcon"];

  const onUpdateSelector = (event: SelectChangeEvent<unknown>) => {
    onUpdate(event.target.value as string);
  };

  return (
    <Container>
      {icon && <Icon style={{ color: colors.grey50 }} />}
      <Title>{label}</Title>
      <DropdownContainer>
        <SimpleDropdown
          options={options}
          value={value}
          onChange={onUpdateSelector}
        />
      </DropdownContainer>
      {extraInfo && (
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: colors.grey70,
                "& .MuiTooltip-arrow": {
                  color: colors.grey70,
                },
              },
            },
          }}
          title={extraInfo}
        >
          <InfoTooltipContainer>
            <InfoIcon style={{ color: colors.grey50 }} />
          </InfoTooltipContainer>
        </Tooltip>
      )}
    </Container>
  );
}
