import React from "react";
import { Box } from "@mui/material";
import { InfoDialog } from "components/dialogs/InfoDialog";

export function JsonDialog({
  payload,
  onClose,
  title,
}: {
  payload: object;
  onClose: () => void;
  title: string;
}) {
  const stringifiedPayload = JSON.stringify(payload, null, 2);
  const numberOfRows = stringifiedPayload.split("\n").length;
  return (
    <InfoDialog fullScreen={true} title={title} onClose={onClose}>
      <Box component="div" mb={1}>
        {/* <Typography variant="body2">
                    This is a description
                </Typography> */}
      </Box>
      <Box component="div">
        <textarea
          ref={(element) =>
            setTimeout(() => {
              element?.focus?.();
            }, 250)
          }
          autoFocus
          readOnly
          rows={numberOfRows}
          style={{ width: "100%" }}
          value={stringifiedPayload}
        />
      </Box>
    </InfoDialog>
  );
}
