import { BillMethod } from "types/main";

export interface Org {
  id: number;
  name: string;
  display_name?: string;
  website?: string;
  street_address_1?: string;
  street_address_2?: string;
  city?: string;
  state_province_region?: string;
  postal_code?: string;
  country?: string;
  created_at?: string;
  created_by?: string;
  bill_method: BillMethod;
  humans_wizard_enabled: boolean;
  scenarios_wizard_enabled: boolean;
}
// orgs that have setup financials via any means like stripe, AWS, or manually
export function hasValidBillMethod(org: Org) {
  return org.bill_method !== "none";
}
