import * as Icons from "assets/icons";
import { colors } from "components/App/theme";
import { IconsType } from "types/main";
import { Container, Label, SubContainer } from "./TitleWithIcon.styled";

export function TitleWithIcon({
  label,
  icon,
  iconColor = colors.grey50,
  addBorderBottom = false,
  withSpacing = false,
  leftSideElement,
  id,
  fitContentWidth,
}: {
  label: string;
  icon: IconsType;
  iconColor?: string;
  addBorderBottom?: boolean;
  withSpacing?: boolean;
  leftSideElement?: React.ReactNode;
  fitContentWidth?: boolean;
  id?: string;
}) {
  const Icon = Icons[icon];

  return (
    <Container
      $addBorderBottom={addBorderBottom}
      $fitContentWidth={!!fitContentWidth}
      $withSpacing={withSpacing}
      id={id}
    >
      <SubContainer>
        <Icon style={{ color: iconColor }} />
        <Label>{label}</Label>
      </SubContainer>
      <SubContainer>{leftSideElement}</SubContainer>
    </Container>
  );
}
