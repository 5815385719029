import React, { forwardRef } from "react";
import { MenuItem, Select as BaseSelect, SelectProps } from "@mui/material";
import { colors } from "components/App/theme";

export const Select = forwardRef(function Input(
  props: SelectProps & {
    options: { value: any; label: string }[];
  },
  ref: React.Ref<HTMLSelectElement>
) {
  const { options, ...rest } = props;

  return (
    <BaseSelect
      {...rest}
      ref={ref}
      color="warning"
      size="small"
      sx={{
        borderRadius: "4px",
        border: "1px solid #e0e0e0",
        outline: "none",
        padding: "4px 12px",
        minWidth: "320px",
        boxShadow: "none",
        ".MuiSelect-select": {
          padding: "4px 0 5px",
        },
        "&:hover": {
          borderColor: colors.bright[2],
        },
        "&:focus-within": {
          borderColor: colors.bright[2],
        },
        ".MuiOutlinedInput-notchedOutline": {
          display: "none",
        },
        ...(props.sx || {}),
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </BaseSelect>
  );
});
