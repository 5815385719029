import { useCallback, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { NavigationHeader } from "components/Navigation/Header";
import { StripeInvoice } from "domain/Invoice";
import { useOrgParam } from "hooks/useOrgParam";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { selectedOrgSelector } from "store/orgStore";
import { OrgBilling } from "types/main";
import { Contract } from "./Contract";
import { OrgName } from "./OrgName";
import { PaymentHistory } from "./PaymentHistory";
import { PaymentMethod } from "./PaymentMethod";

export function Settings() {
  const [loading, setLoading] = useState(true);
  const [orgBilling, setOrgBilling] = useState<OrgBilling | null>(null);
  const [paymentHistory, setPaymentHistory] = useState<StripeInvoice[]>([]);
  const { orgParam } = useOrgParam();
  const [org, permissions, setErrorMessage, setSuccessMessage] = useBoundStore(
    (s) => [
      selectedOrgSelector(orgParam)(s),
      s.org.selected?.permissions,
      s.message.setErrorMessage,
      s.message.setSuccessMessage,
    ]
  );

  const fetchBillingInfo = useCallback(async () => {
    if (!org) {
      return;
    }
    setLoading(true);
    try {
      const billing = await restClient.getCurrentBillingInfo(org.name);
      setOrgBilling(billing);
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    try {
      const invoices = await restClient.getPaymentHistory(org.name);
      setPaymentHistory(invoices);
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  }, [org, setErrorMessage]);

  useEffect(() => {
    fetchBillingInfo();
  }, [fetchBillingInfo]);

  const onSubmitPORequest = async (notes: string) => {
    if (!org) {
      return;
    }
    try {
      setLoading(true);
      await restClient.submitPORequest(org.name, notes);
      setSuccessMessage("Your PO request has been successfully submitted");
      await fetchBillingInfo();
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };
  return (
    <>
      <Box component="div" data-testid="settings-component" height="100%">
        <NavigationHeader selectedOrgName={org?.name} title="Settings" />
        {loading ? (
          <FullScreenProgress />
        ) : (
          <>
            {org && permissions?.is_owner && (
              <>
                <Grid container mt={2} spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <OrgName orgName={org.name} />
                  </Grid>
                  {orgBilling && (
                    <Grid item sm={6} xs={12}>
                      <PaymentMethod
                        orgBilling={orgBilling}
                        orgName={org.name}
                        onSubmitPORequest={onSubmitPORequest}
                      />
                    </Grid>
                  )}
                  <Grid item sm={6} xs={12}>
                    <PaymentHistory rows={paymentHistory} />
                  </Grid>
                  {!!orgBilling && !!orgBilling.date_activated && (
                    <Grid item sm={6} xs={12}>
                      <Contract orgBilling={orgBilling} orgName={org.name} />
                    </Grid>
                  )}
                </Grid>
              </>
            )}
            {org && permissions && !permissions.is_owner && (
              <Box component="div" mt={2}>
                <Typography color="error" variant="h3">
                  Only an Org owner is able to see and edit its settings.
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
}
