export function AnimationNameIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0613 20.0516L20.0612 20.0515L20.0516 20.0613C19.785 20.3354 19.4139 20.5 19 20.5H18.2071L20.5 18.2071V19C20.5 19.4139 20.3354 19.785 20.0613 20.0516ZM3.94366 20.0564C3.82664 19.9329 3.72954 19.7917 3.65762 19.6395L19.6402 3.65688C19.9487 3.80248 20.1998 4.0519 20.3502 4.3626L4.3606 20.3424C4.20835 20.2705 4.06711 20.1734 3.94366 20.0564ZM3.5 13.5029V12.0871L12.0871 3.5H13.5029L3.5 13.5029ZM3.5 5C3.5 4.17614 4.17614 3.5 5 3.5H5.79289L3.5 5.79289V5ZM20.5 11.9129L11.9129 20.5H10.4971L20.5 10.4971V11.9129Z"
        stroke={fill}
      />
    </svg>
  );
}
