import { Box } from "@mui/material";
import styled from "styled-components";

const ICON_WIDTH = 24;

export const Container = styled(Box)<{ $items: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;

  :hover {
    .hidable {
      display: inline;
    }
    padding-right: ${({ $items }) => $items * ICON_WIDTH}px;
  }
`;

export const IconContainer = styled(Box)<{ $position: number }>`
  position: absolute;
  right: ${({ $position }) => $position * ICON_WIDTH - 4}px;
  display: none;
  z-index: 50px;
  cursor: pointer;
`;
