import { useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Divider,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { produce } from "immer";
import { colors } from "components/App/theme";
import { SingleSelect } from "components/Common/SingleSelect";
import { AppSlider } from "components/Slider/Slider";
import { changeEventValue } from "features/JobBuilder/utils";
import {
  pickRandomActivitiesForDynamicMode,
  subjobMaximumFrameLimit,
  TARGET_FPS,
} from "features/JobBuilder2/domain";
import {
  numberOfFramesForAllScenesBeforeGapsInDynamicMode,
  numberOfImages,
} from "features/JobBuilder2/frame-count-calculations";
import { RenderQuality } from "features/JobBuilder2/input-json-types";
import {
  defaultDistributionConfig,
  defaultSubjob,
  FrameCutoffMode,
} from "features/JobBuilder2/types";
import { useBoundStore } from "store/_boundStore";
import { selectIsSaiUser } from "store/profileStore";
import { turnEnumToArray } from "utils/arrUtil";
import { ReactComponent as FramesForActivityIllustration } from "./assets/frames-for-activity.svg";
import { ReactComponent as FramesPerSimulationIllustration } from "./assets/frames-per-simulation.svg";
import { ReactComponent as SimulationsIllustration } from "./assets/simulation.svg";

const signBetweenImageCalculationBoxes = (i: number) => {
  const MULTIPLY = "X";
  const DIVIDE = "/";
  return i === 1 ? DIVIDE : MULTIPLY;
};

type DistributionCoreProps = {
  subjob: typeof defaultSubjob;
  onUpdate: (distributionConfig: typeof defaultDistributionConfig) => void;
  onFieldBlur: (fieldName: string, value: string) => () => void;
};

export function DistributionCore(props: DistributionCoreProps) {
  const { subjob, onUpdate, onFieldBlur } = props;

  const activityZonesHaveActivities = useMemo(
    () => subjob.activityZones.map((z) => Boolean(z.activities.length)),
    [subjob.activityZones]
  );

  const activities = useBoundStore((state) => state.catalogData.activities);
  const isSaiUser = useBoundStore(selectIsSaiUser);

  const distribution = subjob.distribution;
  const pickedActivityZoneIndex =
    distribution.configs[FrameCutoffMode.dynamic].activityZoneIndex;
  const lastFrameNumber =
    distribution.configs[FrameCutoffMode.static].lastFrameNumber;

  const onUpdateLocal = useCallback(
    (fn: (draft: typeof defaultDistributionConfig) => void) => {
      onUpdate(produce(distribution, fn));
    },
    [onUpdate, distribution]
  );

  const maximumFrameLimit = useMemo(
    () =>
      subjobMaximumFrameLimit(
        subjob.activityZones.map((az) => az.activities),
        activities
      ),
    [subjob, activities]
  );

  const onUpdateLastFrameNumber = (e: Event, v: number | number[]) =>
    onUpdateLocal((d) => {
      if (typeof v !== "number") {
        return;
      }
      d.configs[FrameCutoffMode.static].lastFrameNumber = v;
    });

  const onUpdateFrameIncrement = (e: Event, v: number | number[]) =>
    onUpdateLocal((d) => {
      if (typeof v !== "number") {
        return;
      }
      d.frameIncrement = v;
    });

  const onUpdateNumberOfScenes = (v: string) =>
    onUpdateLocal((d) => {
      d.numberOfScenes = v;
      if (
        d.frameCutoffMode === FrameCutoffMode.dynamic &&
        pickedActivityZoneIndex
      ) {
        d.configs[FrameCutoffMode.dynamic].activitiesInAllScenesForSpecialZone =
          pickRandomActivitiesForDynamicMode(
            pickedActivityZoneIndex,
            v,
            subjob
          );
      }
    });

  const onUpdateQuality = (v: string) =>
    onUpdateLocal((d) => {
      d.quality = v as RenderQuality;
    });

  const onUpdateFrameCutoffMode = (v: string) =>
    onUpdateLocal((d) => {
      d.configs[FrameCutoffMode.dynamic] = {
        activitiesInAllScenesForSpecialZone: [],
        activityZoneIndex: "",
      };
      d.frameCutoffMode = v as FrameCutoffMode;
    });

  const onUpdateActivitiyZone = useCallback(
    (v: string) => {
      onUpdateLocal((d) => {
        d.configs[FrameCutoffMode.dynamic].activityZoneIndex = v;
        d.configs[FrameCutoffMode.dynamic].activitiesInAllScenesForSpecialZone =
          pickRandomActivitiesForDynamicMode(
            v,
            subjob.distribution.numberOfScenes,
            subjob
          );
      });
    },
    [onUpdateLocal, subjob]
  );

  const onRandomizeActivitiesPicked = useCallback(() => {
    onUpdateLocal((d) => {
      d.configs[FrameCutoffMode.dynamic].activitiesInAllScenesForSpecialZone =
        pickRandomActivitiesForDynamicMode(
          pickedActivityZoneIndex,
          subjob.distribution.numberOfScenes,
          subjob
        );
    });
  }, [onUpdateLocal, subjob, pickedActivityZoneIndex]);

  const activitiesInAllScenesForSpecialZone =
    distribution.configs[FrameCutoffMode.dynamic]
      .activitiesInAllScenesForSpecialZone;

  const dynamicFramesCount = useMemo(
    () =>
      numberOfFramesForAllScenesBeforeGapsInDynamicMode(
        activitiesInAllScenesForSpecialZone,
        activities
      ),
    [activitiesInAllScenesForSpecialZone, activities]
  );

  const imagesCount = useMemo(() => {
    return numberOfImages(subjob, activities);
  }, [subjob, activities]);

  const infoPanel = useMemo(() => {
    const baseArr = [
      ["Total no. of cameras", subjob.rig.cameras.length.toString()],
    ];
    const frameIncrementBox = [
      "Frame Increment",
      distribution.frameIncrement.toString(),
    ];
    if (distribution.frameCutoffMode === FrameCutoffMode.static) {
      baseArr.push(["Total no. of frames", lastFrameNumber.toString()]);
      baseArr.push(frameIncrementBox);
      baseArr.push(["Total no. of simulations", distribution.numberOfScenes]);
    }
    if (distribution.frameCutoffMode === FrameCutoffMode.dynamic) {
      baseArr.push(["Total no. of frames", dynamicFramesCount.toString()]);
      baseArr.push(frameIncrementBox);
    }
    return baseArr;
  }, [
    distribution,
    subjob.rig.cameras.length,
    lastFrameNumber,
    dynamicFramesCount,
  ]);

  return (
    <>
      <Box component="div" display="flex" flexWrap="wrap">
        <Box component="div" flex={1} minWidth={720}>
          <Typography mb={1} variant="h4">
            Number of Simulations
          </Typography>
          <Typography maxWidth="720px" variant="body1">
            This will decide how many times the animation that export the frames
            will take place.
          </Typography>
          <Box component="div" sx={{ mb: 2 }} width="300px">
            <TextField
              fullWidth
              inputProps={{ min: 0, max: 10000 }}
              margin="normal"
              placeholder="Number of Simulations"
              type="number"
              value={distribution.numberOfScenes}
              variant="outlined"
              onBlur={onFieldBlur(
                "number of simulations",
                distribution.numberOfScenes
              )}
              onChange={changeEventValue(onUpdateNumberOfScenes)}
            />
          </Box>
        </Box>
        <Box component="div" flex={1}>
          <Typography flex="1" my={2} variant="body1">
            You control the number of simulations run. For every simulation, we
            pick a random setup per activity zone.
          </Typography>
          <SimulationsIllustration />
        </Box>
      </Box>

      <Divider sx={{ my: 4 }} />

      <Box component="div" display="flex" flexWrap="wrap">
        <Box component="div" flex={1} minWidth={720}>
          <Typography mb={1} variant="h4">
            Simulation Frame Cutoff
          </Typography>
          <Typography maxWidth="720px" mb={2} variant="body1">
            This determines the last frame each {"simulation's"} animations will
            run through. Either set this to a static number you pick, or allow
            the randomly-picked animation dynamically set the last frame number.
          </Typography>

          <Box component="div" sx={{ mb: 4 }}>
            <SingleSelect
              label=""
              options={turnEnumToArray(FrameCutoffMode)}
              value={distribution.frameCutoffMode}
              width={490}
              onBlur={onFieldBlur(
                "frame cutoff mode",
                distribution.frameCutoffMode
              )}
              onChange={onUpdateFrameCutoffMode}
            />
          </Box>
        </Box>
        <Box component="div" flex={1} my={2}>
          <Box
            alignItems="center"
            component="div"
            display="flex"
            sx={{ textAlign: "center" }}
            width={720}
          >
            <Box component="div" flex={1} mr={4}>
              <FramesPerSimulationIllustration />
              <Typography mt={2} variant="body1">
                Set a fixed number of frames per simulation
              </Typography>
            </Box>
            <Box component="div" flex={1} mr={4}>
              <Typography mb={3} variant="h3">
                or
              </Typography>
            </Box>
            <Box component="div" flex={1} mr={4} width={260}>
              <FramesForActivityIllustration />
              <Typography mt={2} variant="body1">
                Let the selected animation of an activity region dynamically set
                the last frame number per simulation
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {distribution.frameCutoffMode === FrameCutoffMode.dynamic && (
        <>
          <Box component="div" sx={{ mb: 3 }}>
            <Typography mb={1} variant="h4">
              Select the Activity Zone
            </Typography>
            <Typography maxWidth="720px" mb={2} variant="body1">
              Calculates the total number of frames based on randomized
              selection of activities and its total number of frames.
            </Typography>
          </Box>
          <Box component="div" sx={{ mb: 2 }} width="300px">
            <Select
              displayEmpty
              fullWidth
              data-testid="activity-zone-select"
              input={<OutlinedInput label="Activity Zone" />}
              label=""
              value={pickedActivityZoneIndex}
              variant="outlined"
              onBlur={onFieldBlur(
                "activity zone in distribution",
                `Zone ${Number(pickedActivityZoneIndex) + 1}`
              )}
              onChange={(e) => onUpdateActivitiyZone(e.target.value)}
            >
              {activityZonesHaveActivities.map((o, i) => (
                <MenuItem key={i} disabled={!o} value={i.toString()}>
                  <ListItemText primary={`Zone ${i + 1}`} />
                </MenuItem>
              ))}
            </Select>
            <Box
              alignItems="center"
              component="div"
              display="flex"
              justifyContent="space-between"
              mb={2}
              mt={2}
              width="300px"
            >
              <Box component="div"> {dynamicFramesCount} frames </Box>
              <Button
                color="warning"
                disabled={
                  !distribution.numberOfScenes || !pickedActivityZoneIndex
                }
                variant="contained"
                onClick={onRandomizeActivitiesPicked}
              >
                Randomize activities picked
              </Button>
            </Box>
          </Box>
        </>
      )}

      {distribution.frameCutoffMode === FrameCutoffMode.static && (
        <div data-testid="last-frame-number-slider">
          <Typography mt={3} variant="h4">
            Last Frame Number Per Simulation
          </Typography>
          <AppSlider
            max={maximumFrameLimit}
            min={1}
            value={Number(
              distribution.configs[FrameCutoffMode.static].lastFrameNumber
            )}
            onBlur={onFieldBlur(
              "frame limit",
              distribution.configs[
                FrameCutoffMode.static
              ].lastFrameNumber.toString()
            )}
            onChange={onUpdateLastFrameNumber}
          />
        </div>
      )}

      <Divider sx={{ my: 4 }} />

      <Box component="div" flex={1} minWidth={720}>
        <Typography mb={1} variant="h4">
          (Optional) Frame Increment
        </Typography>
        <Typography maxWidth="720px" mb={2} variant="body1">
          Optionally, choose the frame increments to reduce the number of images
          output for each activity. 1 for every frame, 2 for every other frame,
          etc.
        </Typography>
        <Box component="div" sx={{ mb: 2, mt: 5 }} width="300px"></Box>

        <AppSlider
          max={TARGET_FPS}
          min={1}
          value={Number(distribution.frameIncrement)}
          onBlur={onFieldBlur(
            "frame subsampling",
            distribution.frameIncrement.toString()
          )}
          onChange={onUpdateFrameIncrement}
        />
      </Box>

      {isSaiUser && (
        <>
          <Divider sx={{ my: 4 }} />
          <Typography mb={1} variant="h4">
            Image Quality
          </Typography>

          <SingleSelect
            label=""
            options={turnEnumToArray(RenderQuality)}
            value={distribution.quality}
            onBlur={onFieldBlur("quality", distribution.quality)}
            onChange={onUpdateQuality}
          />
        </>
      )}

      <Divider sx={{ my: 4 }} />

      {Boolean(lastFrameNumber || pickedActivityZoneIndex) && (
        <>
          <Typography sx={{ mb: 2 }} variant="h4">
            Total number of images produced for this sub-job
          </Typography>
          <Box component="div" display="flex" mb={4}>
            {infoPanel.map((arr, i) => (
              <Box
                key={`${arr[0]}-${arr[1]}`}
                alignItems="center"
                component="div"
                display="flex"
              >
                <Box
                  key={i}
                  component="div"
                  sx={{
                    p: 2,
                    backgroundColor: colors.tint5,
                    borderRadius: "8px",
                  }}
                >
                  <Typography variant="body1">{arr[0]}</Typography>
                  <Typography
                    sx={{ fontWeight: "bold", color: colors.bright[2] }}
                    variant="body1"
                  >
                    {arr[1]}
                  </Typography>
                </Box>
                {i !== infoPanel.length - 1 && (
                  <Box component="div" sx={{ ml: 4, mr: 4 }}>
                    {signBetweenImageCalculationBoxes(i)}
                  </Box>
                )}
              </Box>
            ))}
            <Box
              alignItems="center"
              component="div"
              display="flex"
              height="86px"
              mx={4}
            >
              =
            </Box>
            <Box
              alignItems="center"
              component="div"
              display="flex"
              height="86px"
            >
              <Typography variant="h4">
                {imagesCount} image
                {imagesCount === 1 ? "" : "s"}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
