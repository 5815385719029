export function RadarIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.74 18.33C21.15 16.6 22 14.4 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C14.4 22 16.6 21.15 18.33 19.74C18.6 19.52 18.86 19.28 19.11 19.03C19.14 19 19.16 18.97 19.18 18.95C19.38 18.75 19.57 18.54 19.74 18.33ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 13.85 19.37 15.54 18.31 16.9L16.88 15.47C17.57 14.49 17.98 13.3 17.98 12.01C17.98 8.7 15.29 6.01 11.98 6.01C8.67 6.01 5.98 8.7 5.98 12.01C5.98 15.32 8.67 18.01 11.98 18.01C13.28 18.01 14.49 17.59 15.47 16.88L16.89 18.3C15.54 19.37 13.85 20 12 20ZM13.92 12.51C14.09 11.85 13.94 11.13 13.43 10.61L13.41 10.59C12.64 9.82 11.41 9.81 10.63 10.55C10.62 10.56 10.6 10.57 10.58 10.59C9.8 11.37 9.8 12.64 10.58 13.42L10.6 13.44C11.12 13.95 11.85 14.11 12.51 13.93L14.02 15.44C13.42 15.8 12.73 16.02 11.98 16.02C9.77 16.02 7.98 14.23 7.98 12.02C7.98 9.81 9.77 8.02 11.98 8.02C14.19 8.02 15.98 9.81 15.98 12.02C15.98 12.75 15.77 13.43 15.42 14.02L13.92 12.51Z"
        fill={fill}
      />
    </svg>
  );
}
