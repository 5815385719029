export function HairIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1589 4.03389C17.3132 3.8625 17.3019 3.60401 17.1318 3.43752L16.5079 3.39763C16.2557 3.59462 10.3021 8.33763 7.68207 18.1158C7.30616 19.5187 8.2041 20.9829 9.6834 21.3793C11.1627 21.7757 12.6725 20.9566 13.0484 19.5537C13.235 18.8573 13.1053 18.1071 12.6922 17.4773C11.7772 10.1093 17.1045 4.09389 17.1589 4.03389ZM12.8851 17.4075C12.4488 13.7947 13.5315 10.5074 14.7407 8.105C15.3472 6.89992 15.9839 5.92103 16.4719 5.24012C16.7159 4.89977 16.9225 4.63414 17.0695 4.45222C17.1429 4.36126 17.2014 4.29127 17.2421 4.24335C17.2625 4.21939 17.2784 4.20096 17.2895 4.18822L17.3025 4.17345C17.3055 4.17008 17.3069 4.16847 17.3071 4.16826L17.3071 4.16824L17.3076 4.16769C17.5368 3.91301 17.515 3.53262 17.2717 3.29458L17.2717 3.29457L17.2706 3.2935C17.0325 3.06408 16.6502 3.03298 16.3849 3.23995L16.3848 3.24003C16.1141 3.45145 10.1244 8.22802 7.48889 18.064C7.08184 19.5831 8.05542 21.1502 9.63163 21.5725C11.2078 21.9949 12.8345 21.1246 13.2416 19.6054C13.4398 18.8657 13.3074 18.0741 12.8851 17.4075ZM12.5433 9.79271C11.7251 12.0437 11.2032 14.7515 11.5903 17.6949L11.5905 17.6962C11.6047 17.799 11.6437 17.8979 11.7065 17.9846C11.9853 18.3717 12.0744 18.838 11.9608 19.2622C11.7412 20.0816 10.852 20.5763 9.9624 20.3379C9.0728 20.0995 8.55005 19.2265 8.7696 18.4071C9.71668 14.8727 11.1361 12.0023 12.5433 9.79271Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.4"
      />
    </svg>
  );
}
