import { useCallback, useMemo } from "react";
import produce from "immer";
import { AdvancedCameraSettings } from "components/routes/JobBuilder/Camera/AdvancedCameraSettings";
import { defaultCamera, wavelengthOptions } from "domain/Human";
import { CameraCoreProps } from "features/JobBuilderShared/Camera/core";
import { useBoundStore } from "store/_boundStore";
import { convertCameraStateToJson } from "./form-to-input-json";
import { JsonManagerNewUI } from "./json-manager-new-ui";
import { convertCamera } from "./json-to-form";

export function Camera({
  value,
  onUpdate,
  rigType,
  onDelete,
}: {
  value: typeof defaultCamera;
  onUpdate: (camera: typeof defaultCamera) => void;
  rigType: string;
  onDelete: () => void;
}) {
  const onUpdateLocal = useCallback(
    (fn: (draft: typeof defaultCamera) => void) => {
      onUpdate(produce(value, fn));
    },
    [onUpdate, value]
  );
  const { setErrorMessage, setSuccessMessage } =
    useBoundStore.getState().message;

  const onIndecesReceived = (indices: number[], humansArr: any[]) => {
    try {
      const cameraInJson =
        humansArr[indices[0]].camera_and_light_rigs[indices[1]].cameras[
          indices[2]
        ];
      onUpdate(convertCamera(cameraInJson, value));
      setSuccessMessage("updated camera object");
    } catch (error) {
      setErrorMessage("can't find camera object");
    }
  };

  const onDoneJson = (json: any) => {
    const updated = convertCamera(json, value);
    onUpdate(updated);
  };
  const jsonValue = useMemo(() => convertCameraStateToJson(value), [value]);

  return (
    <JsonManagerNewUI
      id={value.name}
      jsonValue={jsonValue}
      title={"Specifications"}
      unknownIndicesKeys={["Human", "Rig", "Camera"]}
      onDelete={onDelete}
      onDoneEditJson={onDoneJson}
      onIndecesReceived={onIndecesReceived}
    >
      <CameraCoreProps
        hasNIR
        rigType={rigType}
        value={value}
        wavelengthOptions={wavelengthOptions}
        onUpdateLocal={onUpdateLocal}
      />
      <AdvancedCameraSettings value={value} onUpdateLocal={onUpdateLocal} />
    </JsonManagerNewUI>
  );
}
