import { Typography } from "@mui/material";
import styled, { css, keyframes } from "styled-components";
import { colors } from "components/App/theme";

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const AnimatedImage = styled.img`
  animation: ${fadeIn} 1s;
  animation-iteration-count: 1;
`;

export const ContentWrapper = styled.div`
  position: relative;
  height: 605px;
`;

const sharedStyles = css`
  position: absolute;
  background: ${colors.grey70};
  width: 190px;
  z-index: 2;
  padding: 8px 16px;
`;

export const LightTipWrapper = styled.div<{ current?: boolean }>`
  ${sharedStyles}

  ${(props) =>
    props.current &&
    css`
      background: ${colors.orange};
    `}

  left: -45px;
  top: 90px;
`;

export const RigTipWrapper = styled.div<{ current?: boolean }>`
  ${sharedStyles}

  ${(props) =>
    props.current &&
    css`
      background: ${colors.orange};
    `}

  top: ${(props) => (props.current ? "85px" : "92px")};
  left: -60px;
  transition: top 0.5s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    width: 93px;
    height: 2px;
    background: ${(props) => (props.current ? colors.orange : colors.grey70)};
    top: 50%;
    left: 100%;
  }
`;

export const CameraTipWrapper = styled.div<{ current?: boolean }>`
  ${sharedStyles}

  ${(props) =>
    props.current &&
    css`
      background: ${colors.orange};
    `}

  left: 310px;
  top: 15px;

  &::before {
    content: "";
    position: absolute;
    width: 62px;
    height: 2px;
    background: ${(props) => (props.current ? colors.orange : colors.grey70)};
    top: 90%;
    right: 95%;
    transform: rotate(-45deg);
  }
`;

export const DialogParagraph = styled(Typography)`
  margin-top: 6px;
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  color: ${colors.white};
`;
