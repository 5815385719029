import { Container, Content, Link } from "./TooltipWithLinkContent.styled";

export function TooltipWithLinkContent({
  textContent,
  linkLabel,
  linkTo,
}: {
  textContent: string;
  linkLabel: string;
  linkTo: string;
}) {
  const openDocsNewTab = () => window.open(linkTo, "_blank");

  return (
    <Container>
      <Content>{textContent}</Content>
      <Link onClick={openDocsNewTab}>{linkLabel}</Link>
    </Container>
  );
}
