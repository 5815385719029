import {
  AppBar,
  Toolbar,
  Box,
  Divider,
  Typography,
  Container,
} from "@mui/material";
import { Link } from "react-router-dom";
import { colors } from "components/App/theme";
import { Logo } from "components/Common/Logo";
import { TopMenu } from "components/Common/TopMenu";
import { useBoundStore } from "store/_boundStore";

export const APP_BAR_HEIGHT = 70;

export function TopAppBar2({
  title,
  fullWidth,
}: {
  title?: React.ReactElement | string;
  fullWidth?: boolean;
}) {
  const authenticated = useBoundStore((s) => s.auth.authenticated);

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: colors.white,
        display: "flex",
        boxShadow: "0px 0px",
        borderBottom: `1px solid ${colors.grey20}`,
        height: APP_BAR_HEIGHT,
      }}
    >
      <Toolbar
        sx={
          fullWidth
            ? {
                px: "16px!important",
              }
            : undefined
        }
      >
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            maxWidth: fullWidth ? "100%!important" : undefined,
            p: fullWidth ? "0!important" : undefined,
          }}
        >
          <Link to="/">
            <Logo />
          </Link>

          <Divider
            flexItem
            orientation="vertical"
            sx={{
              mx: 3,
              my: 2.2,
              borderColor: colors.grey50,
              width: "1px",
              maxHeight: "40px",
            }}
            variant="middle"
          />

          {title && (
            <Box color={colors.orange} component={Typography} variant="h4">
              {title}
            </Box>
          )}

          <Box
            component="div"
            sx={{
              flex: "1 0 150px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {authenticated && (
              <Box component="div" ml={3}>
                <TopMenu />
              </Box>
            )}
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
}
