import { Box } from "@mui/material";
import { formatDate } from "utils/dateUtils";

export function DateRow({ dateStr }: { dateStr: string }) {
  return (
    <Box component="div" mr={1} whiteSpace="nowrap">
      {formatDate(dateStr)}
    </Box>
  );
}
