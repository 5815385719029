import React from "react";
import { Chip } from "@mui/material";
import { colors } from "components/App/theme";
import { JobStatus } from "domain/Job";

interface Row {
  task_count: number;
  task_completed: number;
  status: JobStatus;
}

export function Status({ row }: { row: Row }) {
  return (
    <Chip
      data-testid="status-component"
      label={getLabel(row)}
      style={{
        minWidth: "100px",
        color: "white",
        fontWeight: 600,
        background: background(row),
      }}
    />
  );
}

const backgroundColors: { [name: string]: string } = {
  submitted: colors.cerulean,
  complete: colors.sushi,
  def: colors.boulder,
};

const background = (row: Row) => {
  if (row.status?.toLowerCase() !== "submitted") {
    return backgroundColors[row.status]
      ? backgroundColors[row.status]
      : backgroundColors.def;
  }
  const done = backgroundColors.submitted;
  const pending = backgroundColors.def;
  if (row.task_count === 0) {
    return pending;
  }

  const percentage = (row.task_completed / row.task_count) * 100;
  return `linear-gradient(to right, ${done} 0%, ${done} ${percentage}% , ${pending} ${percentage}%, ${pending} 100%)`;
};

const getLabel = (row: Row) => {
  const status = row.status?.toLowerCase();
  if (status === "submitted" || status === "paused" || status === "accepted") {
    return `${row.task_completed}/${row.task_count}`;
  }
  return row.status;
};
