import { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { CliCommand } from "components/Common/CliCommand";
import { InfoDialog } from "components/dialogs/InfoDialog";
import { CLI_VIEW_ASSETS_COMMAND } from "constants/constants";
import { jobDownloadCommand } from "domain/SynthesisClientCLI";
import { download } from "services/download";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";

export function DownloadAssetsDialog({
  title,
  descriptions,
  jobID,
  jobName,
  orgName,
  onClose,
}: {
  title: string;
  descriptions: string[];
  jobID: string;
  jobName: string;
  orgName: string;
  onClose: () => void;
}) {
  const [zipFileExists, setZipFileExists] = useState(false);
  const [isFetchingZipFileUri, setIsFetchingZipFileUri] = useState(false);
  const [fetchedForZipURL, setFetchedForZipURL] = useState(false);
  const commands = [
    `${CLI_VIEW_ASSETS_COMMAND} ${jobID}`,
    jobDownloadCommand(jobID, jobName),
  ];

  const { setErrorMessage } = useBoundStore.getState().message;
  useEffect(() => {
    async function findZip() {
      try {
        const exists = await restClient.zipFileExists(orgName, jobID);
        setZipFileExists(exists);
      } catch (error) {}
      setFetchedForZipURL(true);
    }
    findZip();
  }, [jobID, orgName]);

  const onDownloadClick = async () => {
    try {
      setIsFetchingZipFileUri(true);
      const uri = await restClient.getZip(orgName, jobID);
      download(uri);
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setIsFetchingZipFileUri(false);
  };

  return (
    <InfoDialog fullScreen={false} title={title} onClose={onClose}>
      <>
        {isFetchingZipFileUri && <CircularProgress />}
        <Typography display="inline" variant="h4">
          Browser
        </Typography>
        <Typography variant="body2">
          Note: EXR files can only by downloaded via the CLI
        </Typography>
        {!zipFileExists && fetchedForZipURL && (
          <Typography variant="body2">
            Browser downloads are only enabled for certain types of completed
            jobs with less than a certain number of images, depending on their
            resolution.
          </Typography>
        )}
        <Button
          color="warning"
          disabled={!zipFileExists}
          style={{ marginTop: 10, marginBottom: 40 }}
          variant="contained"
          onClick={onDownloadClick}
        >
          Download
        </Button>
        <Box component="div">
          <Typography display="inline" variant="h4">
            CLI
          </Typography>
          <a href="https://docs.synthesis.ai/cli.html">(documentation)</a>
        </Box>
        {commands.map((cmd: string, i: number) => {
          return (
            <Box key={i} component="div" mb={2}>
              <Box component="div" mb={1}>
                <Typography variant="body2">{descriptions[i]}</Typography>
              </Box>
              <Box component="div">
                <CliCommand command={cmd} />
              </Box>
            </Box>
          );
        })}
      </>
    </InfoDialog>
  );
}
