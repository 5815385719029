import CloseIcon from "@mui/icons-material/Close";
import { Typography, Box } from "@mui/material";
import { VirtualizedList } from "components/Common/VirtualizedList";
import { ShowOnHover } from "components/ShowOnHover";
import { identityImageURL } from "constants/constants";
import { IdentitiesSelectedEmpty } from "./IdentitiesSelectedEmptyState";

export function IdentitiesSelected({
  selectedIds,
  onDeleteSelectedIDClick,
  emptyMessage = <IdentitiesSelectedEmpty />,
  height,
}: {
  selectedIds: number[];
  onDeleteSelectedIDClick?: (id: number) => void;
  emptyMessage?: React.ReactNode;
  height?: number;
}) {
  const isEmpty = !selectedIds.length;

  function IdentityCell(imageIndex: number) {
    return (
      <Box
        component="div"
        sx={{
          display: "inline-block",
          position: "relative",
          mr: 0.3,
          mb: 2,
        }}
      >
        <ShowOnHover
          toShow={
            onDeleteSelectedIDClick ? (
              <CloseIcon
                style={{
                  color: "red",
                  position: "absolute",
                  cursor: "pointer",
                  zIndex: 5,
                  fontSize: "19px",
                  visibility: "hidden",
                  top: "1px",
                  left: "2px",
                }}
                onClick={() => onDeleteSelectedIDClick(selectedIds[imageIndex])}
              />
            ) : (
              <></>
            )
          }
        >
          <img
            alt={selectedIds[imageIndex].toString()}
            height="67px"
            src={identityImageURL(selectedIds[imageIndex])}
            style={{
              objectFit: "cover",
              borderRadius: "4px",
            }}
            width="67px"
          />
        </ShowOnHover>
        <Box
          component={Typography}
          sx={{
            fontSize: "12px",
            textAlign: "center",
            mt: -1,
          }}
          variant="body2"
        >
          ID. {selectedIds[imageIndex]}
        </Box>
      </Box>
    );
  }

  return (
    <>
      {isEmpty ? (
        emptyMessage
      ) : (
        <Box component="div" height={height || "300px"}>
          <VirtualizedList cellWidth={70} items={selectedIds} rowHeight={90}>
            {IdentityCell}
          </VirtualizedList>
        </Box>
      )}
    </>
  );
}
