export function ScenesIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      height="1200pt"
      style={style}
      version="1.1"
      viewBox="0 0 1200 1200"
      width="1200pt"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={fill}>
        <path d="m231.31 628.55c-6.9805 0-13.527-4.2852-16.09-11.215-3.2656-8.8711 1.2734-18.734 10.16-22.016 107.38-39.594 240.45-61.391 374.68-61.391 134.35 0 261.08 20.207 366.53 58.441 8.9062 3.2305 13.492 13.059 10.277 21.965-3.2305 8.8906-13.074 13.441-21.965 10.277-101.73-36.898-224.45-56.402-354.84-56.402-130.3 0-259.15 21.043-362.83 59.281-1.957 0.72656-3.9492 1.0586-5.9219 1.0586z" />
        <path d="m864.64 1075c-3.832 0-7.582-1.2891-10.648-3.7148-4.1016-3.2461-6.4961-8.1875-6.4961-13.426v-416.92c0-7.75 5.207-14.531 12.672-16.559 169.86-45.785 271.26-125.86 271.26-214.21 0-135.97-243.36-250.84-531.43-250.84-288.06 0-531.43 114.87-531.43 250.84 0 88.93 93.48 169.04 250.04 214.3 7.332 2.125 12.387 8.8398 12.387 16.473v411.66c0 5.3086-2.4609 10.312-6.6445 13.559-4.2188 3.2461-9.6758 4.3359-14.801 3.0312-177.5-45.988-275.27-134.83-275.27-250.16v-408.87c0-159.88 248.49-285.12 565.71-285.12s565.71 125.25 565.71 285.12v427.03c0 112.82-97.199 190.46-297.15 237.36-1.3086 0.30078-2.6133 0.44922-3.918 0.44922zm17.141-421.02v382.03c167.93-42.859 249.64-108 249.64-198.82v-329.27c-44.633 60.805-131.13 112.23-249.64 146.05zm-813.21-142.27v307.32c0 93.766 80.793 168.25 228.15 211.09v-376.41c-107.28-32.945-186.2-82.852-228.15-142z" />
        <path d="m967.5 933.25c-6.4609 0-12.641-3.6484-15.551-9.8945-4-8.5703-0.30078-18.766 8.2852-22.785 44.062-20.574 68.336-43.094 68.336-63.398 0-9.4766 7.668-17.145 17.145-17.145 9.4766 0 17.145 7.668 17.145 17.145 0 35.375-29.648 67.148-88.125 94.469-2.3477 1.0898-4.8086 1.6094-7.2344 1.6094z" />
        <path d="m210.99 917.28c-3.2461 0-6.5273-0.92188-9.4414-2.8477-53.238-35.254-64.402-71.348-64.402-95.422 0-9.4766 7.668-17.145 17.145-17.145 9.4766 0 17.145 7.668 17.145 17.145 0 28.395 26.668 52.016 49.035 66.832 7.9023 5.2383 10.062 15.871 4.8398 23.754-3.3047 4.9883-8.7617 7.6836-14.32 7.6836z" />
        <path d="m819.91 588c-9.4766 0-17.145-7.668-17.145-17.145l0.003906-406.28c0-9.4688 7.668-17.145 17.145-17.145 9.4766 0 17.145 7.6758 17.145 17.145v406.28c-0.003906 9.4727-7.6719 17.141-17.148 17.141z" />
        <path d="m380.02 588.35c-9.4766 0-17.145-7.668-17.145-17.145v-406.63c0-9.4688 7.668-17.145 17.145-17.145 9.4766 0 17.145 7.6758 17.145 17.145v406.63c-0.003907 9.4727-7.6719 17.141-17.145 17.141z" />
        <path d="m380 588.35c-2.3086 0-4.6523-0.46875-6.8984-1.457-8.6719-3.8164-12.59-13.945-8.7734-22.602l114.11-258.75c2.5625-5.8086 8.1016-9.7266 14.43-10.18 6.3633-0.51953 12.387 2.5938 15.754 7.9531l150.77 239.4c5.0547 8.0195 2.6445 18.598-5.3555 23.637-8.0352 5.0742-18.598 2.6445-23.637-5.3555l-133.59-212.12-101.1 229.25c-2.8281 6.4102-9.1055 10.227-15.703 10.227z" />
        <path d="m819.93 568.98c-5.8242 0-11.484-2.9648-14.699-8.3047l-98.555-163.84-61.809 137.19c-3.8828 8.6211-13.98 12.473-22.684 8.5898-8.6211-3.9023-12.473-14.047-8.5898-22.684l75.27-167.03c2.6289-5.8086 8.2539-9.6914 14.633-10.062 6.3438-0.45312 12.406 2.8125 15.703 8.2695l115.41 191.89c4.8711 8.1211 2.2422 18.648-5.8594 23.539-2.7617 1.6562-5.8086 2.4453-8.8242 2.4453z" />
        <path d="m629.23 325.67c-33.082 0-60-26.918-60-60s26.918-60 60-60 60 26.918 60 60-26.922 60-60 60zm0-85.715c-14.18 0-25.715 11.535-25.715 25.715 0 14.18 11.535 25.715 25.715 25.715 14.18 0 25.715-11.535 25.715-25.715 0-14.18-11.535-25.715-25.715-25.715z" />
      </g>
    </svg>
  );
}
