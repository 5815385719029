import { useMemo } from "react";
import { HeadphonesIcon } from "assets/icons";
import { ProTip } from "components/ProTip";
import { RightAsidePreviewFull } from "components/RightAsidePreviewFull";
import { PreviewContent } from "components/RightAsidePreviewFull/PreviewContent";
import { PUBLIC_S3_BUCKET } from "constants/constants";
import { Messages } from "constants/messages";
import { EmptyState } from "features/JobBuilder/empty-state";
import { convertHeadphonesStateToJson } from "features/JobBuilder/form-to-input-json";
import {
  getHeadphones,
  getHumanObject,
} from "features/JobBuilder/json-extracter";
import { JsonManagerNewUI } from "features/JobBuilder/json-manager-new-ui";
import { convertHeadphones } from "features/JobBuilder/json-to-form";
import { DeepPartial, JsonHuman } from "features/JobBuilder/json-types";
import { ContentCard, Page } from "features/JobBuilder/layout";
import { MultiOptionImageGrid } from "features/JobBuilder/MultiOptionImageGrid";
import { headphonesStylesSelector } from "features/JobBuilder/store";
import { useDistPage } from "features/JobBuilder/useDistPage";
import {
  useImageSelection,
  useSelectionOutcomeCount,
} from "features/JobBuilder/useImageSelection";
import { useBoundStore } from "store/_boundStore";
const id = "headphones";

export function JobBuilderHeadphones() {
  const {
    isSummingTo100,
    onUpdate,
    onUpdatePart,
    value,
    selectedGroupIndex,
    onUpdatePercent,
    onAdd,
    numberOfImages,
    toggleDistListItem,
  } = useDistPage(id);
  const [setErrorMessage, setSuccessMessage, styles] = useBoundStore((s) => [
    s.message.setErrorMessage,
    s.message.setSuccessMessage,
    headphonesStylesSelector(s),
  ]);

  const selectedHeadphoneGroup = value[selectedGroupIndex];

  const styleUIState = useImageSelection(
    selectedHeadphoneGroup ? selectedHeadphoneGroup.style : [],
    styles,
    (s) => `${PUBLIC_S3_BUCKET}/pl1/headphones.style/${s}.png`,
    onUpdatePart("style"),
    "Style",
    "headphone-styles",
    "StyleIcon",
    toggleDistListItem("style", styles)
  );

  const outcomes = useSelectionOutcomeCount([styleUIState]);

  const onIndecesReceived = (
    indices: number[],
    humansArr: DeepPartial<JsonHuman>[]
  ) => {
    try {
      onUpdate(
        convertHeadphones(
          getHeadphones(getHumanObject(humansArr, indices[0])),
          value
        )
      );
      setSuccessMessage("updated headphones");
    } catch (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
      }
    }
  };

  const onDoneJson = (json: any) => {
    const list = convertHeadphones(json, value);
    onUpdate(list);
  };

  const jsonValue = useMemo(() => convertHeadphonesStateToJson(value), [value]);

  const isEmptyState = !selectedHeadphoneGroup || !styles;
  const center = (
    <JsonManagerNewUI
      emptyState={
        isEmptyState ? (
          <EmptyState
            description="Headphones controls the headphones variations of the character models. Default headphones variations are applied, some sample results are shown below:"
            folder="headphones"
            icon={<HeadphonesIcon style={{ width: 48, height: 48 }} />}
            title="Default headphones settings applied"
          />
        ) : null
      }
      groupIndex={selectedGroupIndex + 1}
      groupPercentage={selectedHeadphoneGroup?.percent}
      id={`headphone-${selectedGroupIndex + 1}`}
      isSummingTo100={isSummingTo100}
      jsonValue={jsonValue}
      numberOfImages={numberOfImages}
      parentTitle="Accessories"
      parentTitleIcon="AccessoriesIcon"
      title={`Headphones`}
      titleCount={outcomes}
      titleIcon="HeadphonesIcon"
      unknownIndicesKeys={["Human"]}
      onClickAddButton={onAdd}
      onDoneEditJson={onDoneJson}
      onIndecesReceived={onIndecesReceived}
      onUpdatePercent={onUpdatePercent}
    >
      <ContentCard>
        <ProTip label={Messages.headphonesProTip} />
        <MultiOptionImageGrid {...styleUIState} />
      </ContentCard>
    </JsonManagerNewUI>
  );
  const rightAside = (
    <RightAsidePreviewFull previewTitle="Preview">
      <PreviewContent data={styleUIState.selectedImages} title="STYLE" />
    </RightAsidePreviewFull>
  );
  return (
    <Page
      fixedRightSide
      center={center}
      hideRightAside={isEmptyState}
      rightAside={rightAside}
    />
  );
}
