export function DenoiseIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 14C8.5 13.1761 9.17614 12.5 10 12.5C10.8239 12.5 11.5 13.1761 11.5 14C11.5 14.8239 10.8239 15.5 10 15.5C9.17614 15.5 8.5 14.8239 8.5 14ZM4.5 10C4.5 9.17614 5.17614 8.5 6 8.5C6.82386 8.5 7.5 9.17614 7.5 10C7.5 10.8239 6.82386 11.5 6 11.5C5.17614 11.5 4.5 10.8239 4.5 10ZM4.5 18C4.5 17.1761 5.17614 16.5 6 16.5C6.82386 16.5 7.5 17.1761 7.5 18C7.5 18.8239 6.82386 19.5 6 19.5C5.17614 19.5 4.5 18.8239 4.5 18ZM19.5 6C19.5 6.82386 18.8239 7.5 18 7.5C17.1761 7.5 16.5 6.82386 16.5 6C16.5 5.17614 17.1761 4.5 18 4.5C18.8239 4.5 19.5 5.17614 19.5 6ZM12.5 18C12.5 17.1761 13.1761 16.5 14 16.5C14.8239 16.5 15.5 17.1761 15.5 18C15.5 18.8239 14.8239 19.5 14 19.5C13.1761 19.5 12.5 18.8239 12.5 18ZM16.5 14C16.5 13.1761 17.1761 12.5 18 12.5C18.8239 12.5 19.5 13.1761 19.5 14C19.5 14.8239 18.8239 15.5 18 15.5C17.1761 15.5 16.5 14.8239 16.5 14ZM12.5 10C12.5 9.17614 13.1761 8.5 14 8.5C14.8239 8.5 15.5 9.17614 15.5 10C15.5 10.8239 14.8239 11.5 14 11.5C13.1761 11.5 12.5 10.8239 12.5 10ZM8.5 6C8.5 5.17614 9.17614 4.5 10 4.5C10.8239 4.5 11.5 5.17614 11.5 6C11.5 6.82386 10.8239 7.5 10 7.5C9.17614 7.5 8.5 6.82386 8.5 6Z"
        fill={fill}
        stroke={fill}
      />
    </svg>
  );
}
