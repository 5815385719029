import { Box, IconButton } from "@mui/material";
import { ClipboardIcon } from "assets/icons/Clipboard";
import { InfoDialog } from "components/dialogs/InfoDialog";
import { Messages } from "constants/messages";
import { useBoundStore } from "store/_boundStore";

export function StrContentDialogWithCopyToClipboard({
  content,
  onClose,
  title,
}: {
  content: string;
  onClose: () => void;
  title: string;
}) {
  const { setSuccessMessage } = useBoundStore.getState().message;

  return (
    <InfoDialog fullScreen={true} title={title} onClose={onClose}>
      <Box component="div" display="flex">
        <Box component="div">{content}</Box>
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(content);
            setSuccessMessage(Messages.copietToClipboard);
          }}
        >
          <ClipboardIcon />
        </IconButton>
      </Box>
    </InfoDialog>
  );
}
