import * as Icons from "assets/icons";
import { colors } from "components/App/theme";
import { ItemLabelVariant } from "features/JobBuilder/types";
import { IconsType } from "types/main";
import { Container, Label, MainInfoContainer } from "./section-label.styled";

export function SectionLabel({
  label,
  icon,
  additionalInfo,
  variant = "section",
  selected = false,
}: {
  label: string;
  icon: IconsType;
  additionalInfo?: JSX.Element;
  variant?: ItemLabelVariant;
  selected?: boolean;
}) {
  const Icon = Icons[icon];

  return (
    <Container>
      <MainInfoContainer>
        <Icon style={{ color: selected ? colors.orange : colors.grey50 }} />
        <Label selected={selected} variant={variant}>
          {label}
        </Label>
      </MainInfoContainer>
      {additionalInfo}
    </Container>
  );
}
