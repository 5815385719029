import { FormGroup } from "@mui/material";
import { SearchInput } from "components/Common/SearchInput";
import {
  Container,
  StyledCheckbox,
  StyledFormControlLabel,
} from "./MultiOptionFilter.styled";

export function MultiOptionFilter({
  placeholder,
  allSelected = false,
  onSearch,
  onSelectAll,
}: {
  placeholder: string;
  allSelected?: boolean;
  onSearch: (term: string) => void;
  onSelectAll?: () => void;
}) {
  return (
    <Container>
      {onSelectAll && (
        <FormGroup>
          <StyledFormControlLabel
            control={
              <StyledCheckbox checked={allSelected} onChange={onSelectAll} />
            }
            label="Select All"
          />
        </FormGroup>
      )}
      <SearchInput placeholder={placeholder} onSearch={onSearch} />
    </Container>
  );
}
