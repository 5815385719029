export function StageElementOverviewIcon({
  style = { height: "32px" },
  fill = "currentColor",
  onClick,
}: {
  style?: React.CSSProperties;
  fill?: string;
  onClick?: () => void;
} = {}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={onClick}
    >
      <svg
        fill="none"
        height="32"
        style={style}
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.1305 16.7054L24 20.9699L15.1305 25.2344L6.26094 20.9699L15.1305 16.7054Z"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M24 20.9697V9.67667L15.1305 5.33318L6.26094 9.67667V20.9697"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M15.1289 16.7061L15.1289 5.33402"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <ellipse
          cx="22.3056"
          cy="20.6818"
          fill="white"
          rx="7.02432"
          ry="7.31754"
        />
        <path
          d="M21.3644 23.7151C21.243 23.7151 21.1403 23.6751 21.0564 23.5951C20.9725 23.5151 20.9306 23.4119 20.9306 23.2854C20.9306 23.1626 20.9725 23.0602 21.0564 22.9784C21.1403 22.8965 21.243 22.8556 21.3644 22.8556H22.0016V20.5281H21.4554C21.334 20.5281 21.2323 20.4872 21.1502 20.4053C21.068 20.3234 21.027 20.2192 21.027 20.0927C21.027 19.9699 21.068 19.8676 21.1502 19.7857C21.2323 19.7039 21.334 19.663 21.4554 19.663H22.4729C22.6264 19.663 22.7425 19.7141 22.821 19.8164C22.8995 19.9188 22.9388 20.0537 22.9388 20.2211V22.8556H23.5333C23.6546 22.8556 23.7573 22.8965 23.8412 22.9784C23.9251 23.0602 23.967 23.1626 23.967 23.2854C23.967 23.4119 23.9251 23.5151 23.8412 23.5951C23.7573 23.6751 23.6546 23.7151 23.5333 23.7151L21.3644 23.7151ZM22.2748 18.7978C22.0748 18.7978 21.9035 18.7234 21.7607 18.5746C21.6179 18.4257 21.5464 18.2471 21.5464 18.0387C21.5464 17.8229 21.6179 17.6406 21.7607 17.4917C21.9035 17.3429 22.0748 17.2685 22.2748 17.2685C22.4818 17.2685 22.6559 17.3429 22.7969 17.4917C22.9379 17.6406 23.0084 17.8229 23.0084 18.0387C23.0084 18.2471 22.9379 18.4257 22.7969 18.5746C22.6559 18.7234 22.4818 18.7978 22.2748 18.7978ZM22.3069 26.4557C23.0674 26.4557 23.7814 26.305 24.449 26.0036C25.1166 25.7022 25.7048 25.2873 26.2136 24.7589C26.7223 24.2305 27.1222 23.6166 27.4132 22.917C27.7041 22.2175 27.8496 21.4733 27.8496 20.6844C27.8496 19.8955 27.7041 19.1532 27.4132 18.4574C27.1222 17.7615 26.7223 17.1476 26.2136 16.6155C25.7048 16.0833 25.1157 15.6666 24.4463 15.3652C23.7769 15.0638 23.062 14.9131 22.3015 14.9131C21.5447 14.9131 20.8324 15.0638 20.1648 15.3652C19.4972 15.6666 18.909 16.0833 18.4002 16.6155C17.8915 17.1476 17.4925 17.7615 17.2033 18.4574C16.9141 19.1532 16.7695 19.8955 16.7695 20.6844C16.7695 21.4733 16.9141 22.2175 17.2033 22.917C17.4925 23.6166 17.8924 24.2305 18.4029 24.7589C18.9134 25.2873 19.5025 25.7022 20.1701 26.0036C20.8378 26.305 21.55 26.4557 22.3069 26.4557Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}
