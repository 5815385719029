import * as Sentry from "@sentry/react";
import { Org } from "domain/Org";
import { OrgPermissions } from "domain/Permissions";
import { restClient } from "services/restClient/main";
import { GetState, SetState } from "store/_boundStore";

export function createActions(
  set: SetState,
  get: GetState,
  initialState: object
) {
  return {
    fetchOrgs: async () => {
      try {
        set((state) => {
          state.org.loading = true;
          state.org.error = "";
        });
        const userId = get().auth.getCurrentUserId();
        const res = await restClient.getOrgs(userId as string);
        set((state) => {
          state.org.list = res;
          state.org.loading = false;
        });
      } catch (error) {
        const errorMessage = restClient.APIErrorMessage(error);
        Sentry.captureException(errorMessage);
        set((state) => {
          state.org.error = errorMessage;
          state.org.loading = false;
        });
      }
    },

    clearState: () =>
      set((state) => {
        state.org = { ...state.org, ...initialState };
      }),

    fetchPermissions: async (orgName: string) => {
      try {
        set((state) => {
          state.org.loading = true;
          state.org.error = "";
        });
        const permissions = await restClient.getPermissionsInOrg(
          orgName,
          get().auth.getCurrentUserId() as string
        );
        set((state) => {
          state.org.selected = { permissions };
          state.org.loading = false;
        });
      } catch (error) {
        Sentry.captureException(error);
        set((state) => {
          state.org.loading = false;
          state.org.error = "Error while fetching permissions";
        });
      }
    },
    replacePermissions: async (orgName: string) => {
      set((state) => {
        state.org.loading = true;
        state.org.error = "";
      });
      const res = await restClient.getPermissionsInOrg(
        orgName,
        get().auth.getCurrentUserId() as string
      );
      const permissions = {
        is_owner: res.is_owner,
        is_reader: res.is_reader,
        is_writer: res.is_writer,
      };
      set((state) => {
        state.org.selected = { permissions };
        state.org.loading = false;
      });
    },

    replaceOrgs: async () => {
      set((state) => {
        state.org.loading = true;
      });
      const userId = get().auth.getCurrentUserId();
      const orgs = await restClient.getOrgs(userId as string);
      set((state) => {
        state.org.loading = false;
        state.org.list = orgs;
      });
    },
    setLoading: (flag: boolean) =>
      set((state) => {
        state.org.loading = flag;
      }),
    saveOrgs: (orgs: Org[]) =>
      set((state) => {
        state.org.list = orgs;
      }),
    savePermissions: (permissions: OrgPermissions) =>
      set((state) => {
        state.org.selected = { permissions };
      }),
  };
}
