import { Box, Link } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";
import { IBreadcrumbNavigator } from "./types";

export const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const BreadcrumbNavigator = styled(Link)<IBreadcrumbNavigator>`
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  color: ${({ $active }) => ($active ? colors.orange : colors.grey70)};
  padding-right: 12px;
  text-decoration: none;
  text-transform: uppercase;
`;
