import { Geom2 } from "@flasd/modeling/src/geometries/types";
import { Vec2 } from "@flasd/modeling/src/maths/types";
import classifyPoint2 from "point-in-polygon-hao";
import { getRadiusForActivity } from "features/JobBuilder2/steps/threeDimensional/get-radius-for-activity";
import { bounds } from "features/JobBuilder2/steps/threeDimensional/measurement-operations";
import {
  Circle,
  getCircle,
} from "features/JobBuilder2/steps/threeDimensional/utils/Circle";
import {
  connectVec2s,
  fromGeom2ToVec2sNested,
} from "features/JobBuilder2/steps/threeDimensional/utils/polygon-converters";
import { useBoundStore } from "store/_boundStore";
import { pointPolygonDistance } from "./pointPolygonDistance";

const connectedVec2s = (arr: Vec2[]) => {
  const lastItem = arr[arr.length - 1];
  const firstItem = arr[0];
  const notConnected =
    lastItem[0] !== firstItem[0] || lastItem[1] !== firstItem[1];
  return notConnected ? connectVec2s(arr) : arr;
};
export function pointInsidePolygonWithPotentialHoles(
  point: Vec2,
  vecss: Vec2[][]
) {
  const connected = vecss.map(connectedVec2s);
  return classifyPoint2(point, connected);
}

const isOverlappingWithCircle = (vec2s: Vec2[], circle: Circle) =>
  pointPolygonDistance(vec2s, circle.center) <= circle.radius;

export const doesCircleFitAtPoint = (circle: Circle, vecss: Vec2[][]) =>
  pointInsidePolygonWithPotentialHoles(circle.center, vecss) &&
  !vecss.some((vec2s) => isOverlappingWithCircle(vec2s, circle));

export function getCircleInPolygon(
  geom: Geom2,
  circleRadius: number
): Vec2 | null {
  const tries = 1000;
  const { left, right, top, bottom } = bounds(geom);
  const width = right - left;
  const height = bottom - top;

  const vecss = fromGeom2ToVec2sNested(geom);
  for (let i = 0; i < tries; ++i) {
    const cX = left + Math.random() * width;
    const cY = top + Math.random() * height;
    const circleCenter = [cX, cY] as Vec2;
    if (doesCircleFitAtPoint(getCircle(circleRadius, circleCenter), vecss)) {
      return circleCenter;
    }
  }
  return null;
}

export const activittyFitsGeom = (geom: Geom2) => (activityName: string) => {
  const activities = useBoundStore.getState().catalogData.activities;
  const activity = activities.find((a) => a.name === activityName);
  if (!activity) {
    return false;
  }
  const radius = getRadiusForActivity(activity);
  return !!getCircleInPolygon(geom, radius);
};
