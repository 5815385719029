import { useBoundStore } from "store/_boundStore";

export const useProfile = () => {
  const { getUserProfile } = useBoundStore.getState().profile;
  const { profileData, isLoadingProfile } = useBoundStore((state) => {
    return {
      profileData: state.profile.data,
      isLoadingProfile: state.profile.loading,
    };
  });
  if (!profileData && !isLoadingProfile) {
    getUserProfile();
  }
  return profileData;
};
