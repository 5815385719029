import produce from "immer";
import {
  defaultCameraWithName,
  defaultDemographicBuilder,
  defaultRig,
  DetailedEnums,
  Glasses,
  Rig,
  Section,
  Skin,
  ThreeDLocationType,
  DistKey,
  distDefaults,
  isDistKey,
  afterDist,
  beforeDist,
  Gesture,
} from "domain/Human";
import { Template } from "features/JobBuilder/types";
import {
  addToArrayPercentages,
  push,
  removeFromArrayPercentages,
  splice,
  updateRigsAndCameraNames,
} from "features/JobBuilder/utils";
import type { GetState, SetState } from "store/_boundStore";
import { toggleIncludeItemInArr } from "utils/arrUtil";
import { StringArrayKeys } from "utils/typesUtil";
import { selectPickedSubjob, selectSelectedRigIndex } from "./selectors";

export function createActions(set: SetState, get: GetState) {
  const onUpdateModule =
    <T extends keyof Section>(type: T) =>
    (v: Section[T]) =>
      updateSubjob((d) => {
        d[type] = v;
      });

  const onUpdateDistPart =
    <T extends DistKey, S extends keyof Section[T][number]>(type: T) =>
    (subKey: S) =>
    (v: Section[T][number][S]) =>
      updateSubjob((d) => {
        const i = get().jobBuilder.selectedIndeces[type];
        d[type][i][subKey] = v;
      });

  const onUpdateNonDistPart =
    <T extends keyof Section, S extends keyof Section[T]>(type: T) =>
    (subKey: S) =>
    (v: Section[T][S]) =>
      updateSubjob((d) => {
        d[type][subKey] = v;
      });

  const toggleDistListItem =
    <T extends DistKey, S extends StringArrayKeys<Section[T][number]>>(
      type: T
    ) =>
    (subKey: S, allOptions: string[]) =>
    (optionIndex: number) => {
      const state = get();
      const pickedSubjob = selectPickedSubjob(state);
      const distItemIndex = state.jobBuilder.selectedIndeces[type] as number;
      const obj = pickedSubjob[type][distItemIndex] as Glasses;
      const oldArrValue = obj[subKey as "name"];
      const clickedOption = allOptions[optionIndex];
      const newArrValue = toggleIncludeItemInArr(oldArrValue, clickedOption);
      onUpdateDistPart(type)(subKey)(newArrValue as any);
    };

  const toggleNonDistListItem =
    <T extends keyof Section, S extends StringArrayKeys<Section[T]>>(type: T) =>
    (subKey: S, allOptions: string[]) =>
    (optionIndex: number) => {
      const state = get();
      const pickedSubjob = selectPickedSubjob(state);
      const obj = pickedSubjob[type as "hdri"];
      const oldArrValue = obj[subKey as "name"];
      const clickedOption = allOptions[optionIndex];
      const newArrValue = toggleIncludeItemInArr(oldArrValue, clickedOption);
      onUpdateNonDistPart(type)(subKey)(newArrValue as any);
    };

  const updatePercent = (type: DistKey) => (v: string) => {
    updateSubjob((d) => {
      const i = get().jobBuilder.selectedIndeces[type];
      d[type][i].percent = v.endsWith(".")
        ? v
        : v
        ? parseFloat(v).toString()
        : "";
    });
  };

  const deleteDistItem = (k1: DistKey) => (i: number) => {
    const picked = selectPickedSubjob(get());
    const oldArr = picked[k1];
    const v = removeFromArrayPercentages(oldArr, i);
    set((s) => {
      s.jobBuilder.subjobs[s.jobBuilder.pickedSubjobIndex][k1] = v as any;
      s.jobBuilder.selectedIndeces[k1] = Math.max(0, i - 1);
    });
  };
  const onUpdateRigs = (v: Rig[]) => onUpdateModule("rigs")(v);

  // select dist group index
  const setSelectedDistIndex = (k: DistKey) => (i: number) => {
    set((state) => {
      state.jobBuilder.selectedPart = k;
      state.jobBuilder.selectedIndeces[k] = i;
    });
  };

  const addDistItem =
    <T extends DistKey>(key: T) =>
    (v?: Section[T][0]) => {
      const state = get();
      const pickedSubjobIndex = state.jobBuilder.pickedSubjobIndex;
      const oldArr = state.jobBuilder.subjobs[pickedSubjobIndex][key];
      const newArr = addToArrayPercentages(
        oldArr as any,
        v ? v : distDefaults[key]
      );
      set((s) => {
        s.jobBuilder.subjobs[pickedSubjobIndex][key] = newArr as any;
      });
      setSelectedHumanObjectPart(key);
    };

  const addNewDistItemForSelectedPart = () => {
    const state = get();
    const selectedPart = state.jobBuilder.selectedPart;
    if (!isDistKey(selectedPart)) {
      return;
    }
    addDistItem(selectedPart)();
  };

  const goToNextPart = () => {
    const state = get();
    const selectedPart = state.jobBuilder.selectedPart;

    if (!isDistKey(selectedPart)) {
      switch (selectedPart) {
        case "demographicBuilder":
          setSelectedHumanObjectPart("identities");
          return;

        case "identities":
          setSelectedHumanObjectPart("expression");
          return;

        case "hdri":
          setSelectedHumanObjectPart("rigs");
      }

      return;
    }

    const key = afterDist(selectedPart);
    // if there is no next dist group, go to the next part which is 'hdri'
    if (!key) {
      setSelectedHumanObjectPart("hdri");
    } else {
      setSelectedDistIndex(key)(0);
    }
  };

  const goToPrevPart = () => {
    const state = get();
    const selectedPart = state.jobBuilder.selectedPart;

    switch (selectedPart) {
      case "identities":
        setSelectedHumanObjectPart("demographicBuilder");
        return;

      case "expression":
        setSelectedHumanObjectPart("identities");
        return;

      case "rigs":
        setSelectedHumanObjectPart("hdri");
        return;

      case "hdri":
        setSelectedHumanObjectPart("gesture");
        return;

      case "gesture":
        setSelectedHumanObjectPart("clothing");
        return;

      case "clothing":
        setSelectedHumanObjectPart("body");
        return;

      case "body":
        setSelectedHumanObjectPart("headwear");
        return;

      case "glasses":
        setSelectedHumanObjectPart("eye");
        return;
    }

    if (!isDistKey(selectedPart)) {
      return;
    }

    const key = beforeDist(selectedPart);

    // if there is no prev dist group, go to the prev part which is 'demographicBuilder'
    if (!key) {
      setSelectedHumanObjectPart("demographicBuilder");
    } else {
      setSelectedDistIndex(key)(0);
    }
  };

  const updateSubjob = (fn: (draft: Section) => void) => {
    set((state) => {
      fn(state.jobBuilder.subjobs[state.jobBuilder.pickedSubjobIndex]);
    });
  };

  const setSelectedHumanObjectPart = (part: keyof Section) => {
    set((state) => {
      state.jobBuilder.selectedPart = part;
    });
  };

  const saveEnums = (enums: DetailedEnums) => {
    set((state) => {
      state.jobBuilder.enums = enums;
    });
  };

  const saveGestures = (gestures: { [k: string]: Gesture }) => {
    set((state) => {
      state.jobBuilder.gestures = gestures;
    });
  };

  const saveInitialHumansAPIData = (
    enums: DetailedEnums,
    gestures: { [k: string]: Gesture }
  ) => {
    set((state) => {
      state.jobBuilder.enums = enums;
      state.jobBuilder.gestures = gestures;
    });
  };

  // JSON editor
  const setOpenEditor = (flag: boolean) =>
    set((state) => {
      state.jobBuilder.openEditor = flag;
    });

  // Top level
  const setPickedSubjobIndex = (subjobIndex: number) =>
    set((state) => {
      state.jobBuilder.pickedSubjobIndex = subjobIndex;
      state.jobBuilder.selectedPart = "demographicBuilder";
    });

  const setSubjobs = (subjobs: ((s: Section[]) => Section[]) | Section[]) => {
    set((state) => {
      if (typeof subjobs === "function") {
        state.jobBuilder.subjobs = subjobs(state.jobBuilder.subjobs);
      } else {
        state.jobBuilder.subjobs = subjobs;
      }
    });
  };

  // Identities
  const setSelectedIdentitiesPrefab = (prefab: number | null) => {
    set((state) => {
      state.jobBuilder.selectedIdentitiesPrefab = prefab;
    });
  };

  const onUpdateDemographicBuilder = (v: typeof defaultDemographicBuilder) => {
    updateSubjob((d) => {
      d.demographicBuilder = v;
    });
  };

  const onUpdatedIdentities = (v: number[]) => {
    updateSubjob((d) => {
      d.identities.ids = v;
    });
  };
  const onUpdateScenesPerIdentities = (v: string) => {
    const parsedNumber = parseInt(v, 10);
    const isInvalidNumber = isNaN(parsedNumber);
    if (isInvalidNumber && v !== "") {
      return;
    }
    updateSubjob((d) => {
      d.identities.rendersPerIdentity = isInvalidNumber
        ? ""
        : parsedNumber.toString();
    });
  };

  const onUpdateSkin = (v: Skin) => onUpdateModule("skin")(v);
  // Environment
  const setThreeDLocation = (v: ThreeDLocationType) => {
    updateSubjob((d) => {
      d.threeDLocation = v;
      d.gesture = [];
    });
  };

  // Cameras & Lights (Rig)
  const setSelectedRigIndex = (index: number) => {
    set((state) => {
      state.jobBuilder.selectedRigIndex = index;
    });
    setSelectedHumanObjectPart("rigs");
  };

  const addRig = () => {
    const pickedSubjob = selectPickedSubjob(get());
    const currentRig = pickedSubjob.rigs.length + 1;
    const newCameraName = `Rig_${currentRig}_Camera_1`;
    const newCamera = defaultCameraWithName(newCameraName);

    return onUpdateRigs(
      produce(
        pickedSubjob.rigs,
        push({
          ...defaultRig,
          cameras: [newCamera],
        })
      )
    );
  };

  const updateRigs = (fn: (a: (typeof defaultRig)[]) => void) => {
    onUpdateRigs(produce(selectPickedSubjob(get()).rigs, fn));
  };

  const updateRigsAndNames = (fn: (a: (typeof defaultRig)[]) => void) => {
    const newRigs = produce(selectPickedSubjob(get()).rigs, fn);
    const newRigsWithUpdatedNames = updateRigsAndCameraNames(newRigs);
    onUpdateRigs(newRigsWithUpdatedNames);
  };

  const updateRig = (v: typeof defaultRig) =>
    updateRigs((d) => {
      const selectedRigIndex = selectSelectedRigIndex(get());
      d[selectedRigIndex] = v;
    });

  const deleteRig = (i: number) => {
    const selectedRigIndex = selectSelectedRigIndex(get());
    // in case we are standing on the last rig and then deleting it
    if (selectedRigIndex >= selectPickedSubjob(get()).rigs.length - 1) {
      setSelectedRigIndex(selectPickedSubjob(get()).rigs.length - 2);
    }

    updateRigsAndNames(splice(i));
    setSelectedRigIndex(Math.max(0, i - 1));
  };

  const setTemplate = (template: Template) => {
    set((state) => {
      state.jobBuilder.template = template;
    });
  };

  const updateJobName = (name: string) => {
    set((state) => {
      if (!state.jobBuilder.template) {
        return;
      }
      state.jobBuilder.template.name = name;
    });
  };

  return {
    addDistItem,
    addRig,
    deleteRig,
    onUpdateDemographicBuilder,
    onUpdatedIdentities,
    onUpdateRigs,
    onUpdateScenesPerIdentities,
    onUpdateSkin,
    saveEnums,
    saveGestures,
    saveInitialHumansAPIData,
    setOpenEditor,
    setPickedSubjobIndex,
    setSelectedHumanObjectPart,
    setSelectedIdentitiesPrefab,
    setSelectedRigIndex,
    setSubjobs,
    setTemplate,
    setThreeDLocation,
    updateJobName,
    updateRig,
    updateSubjob,
    deleteDistItem,
    setSelectedDistIndex,
    addNewDistItemForSelectedPart,
    goToNextPart,
    goToPrevPart,
    onUpdateModule,
    onUpdateDistPart,
    onUpdateNonDistPart,
    updatePercent,
    toggleDistListItem,
    toggleNonDistListItem,
  };
}
