import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, Grid, TextField, Typography, Link } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import * as Sentry from "@sentry/react";
import { Link as RouterLink } from "react-router-dom";
import { ActionButton } from "components/Common/ActionButton";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { TermsAndConditionsFormElement } from "components/Common/TermsAndConditionsFormElement";
import { SuccessMessage } from "components/SuccessMessage";
import { Messages } from "constants/messages";
import { URLParams } from "constants/params";
import { Routes } from "constants/routes";
import { useLogout } from "hooks/useLogout";
import { useURLParam } from "hooks/useURLParam";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { validateEmail, validatePassword } from "utils/validators";
const MIN_PASSWORD_LENGTH = 8;

export function AcceptInvite() {
  const [loading, setLoading] = useState(false);
  const [unformattedEmail, code] = useURLParam(URLParams.email, URLParams.code);
  const email = unformattedEmail.toLowerCase();

  const [firstName, setFirstName] = useState("");
  const [firstNameValidationError, setFirstNameValidationError] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameValidationError, setLastNameValidationError] = useState("");

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [password1ValidationError, setPassword1ValidationError] = useState("");
  const [password2ValidationError, setPassword2ValidationError] = useState("");

  const [agree, setAgree] = useState(false);

  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
  const handleMouseDownPassword1 = () => setShowPassword1(!showPassword1);
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);

  const [successMessage, setSuccessMessage] = useState("");
  const { setErrorMessage } = useBoundStore.getState().message;

  useLogout();

  const validationResult = validateEmail(email);

  if (!validationResult.valid) {
    return (
      <Typography color="error" variant="h1">
        {validationResult.message}
      </Typography>
    );
  }

  if (!code || !code.trim()) {
    return (
      <Typography color="error" variant="h1">
        Invalid Code
      </Typography>
    );
  }

  const validateFirstNameField = () => {
    if (!firstName || !firstName.trim()) {
      setFirstNameValidationError("First name or initial is required");
    } else {
      setFirstNameValidationError("");
    }
  };

  const validateLastNameField = () => {
    if (!lastName || !lastName.trim() || lastName.length < 2) {
      setLastNameValidationError("Last name must be a minimum of 2 characters");
    } else {
      setLastNameValidationError("");
    }
  };

  const validatePassword1Field = () => {
    if (
      !password1 ||
      !password1.trim() ||
      password1.length < MIN_PASSWORD_LENGTH
    ) {
      setPassword1ValidationError(
        `Password must be a minimum of ${MIN_PASSWORD_LENGTH} characters`
      );
    } else {
      setPassword1ValidationError("");
    }
  };

  const validatePassword2Field = () => {
    if (password1 !== password2) {
      setPassword2ValidationError(`Passwords do not match`);
    } else {
      setPassword2ValidationError("");
    }
  };

  const formValid = () => {
    let valid = true;
    if (
      firstNameValidationError ||
      lastNameValidationError ||
      password1ValidationError ||
      password2ValidationError ||
      !firstName ||
      !lastName ||
      !password1 ||
      !password2 ||
      !agree
    ) {
      valid = false;
    }
    return valid;
  };

  const submitForm = async () => {
    if (!formValid()) {
      return;
    }
    setLoading(true);
    try {
      const message = await restClient.acceptInvite(
        email,
        firstName,
        lastName,
        password1,
        code
      );
      window.analytics.track("Invite Accepted", {
        email,
      });
      if (message) {
        setSuccessMessage(message);
      } else {
        setSuccessMessage("You can now access the org you were invited to.");
      }
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  if (successMessage) {
    return (
      <>
        <SuccessMessage
          details={successMessage}
          title="Your account has been created!"
        />
        <Grid container justifyContent="center">
          <Grid item md={6} xs={12}>
            <Box component="div" mt={2}>
              <Link component={RouterLink} to={Routes.LOGIN} underline="always">
                {" "}
                Go to Login Page{" "}
              </Link>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      {loading && <FullScreenProgress />}
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          <Typography variant="h1">Account Registration</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          <Box component="div" mt={4}>
            <TextField
              autoFocus
              fullWidth
              required
              error={!!firstNameValidationError}
              helperText={firstNameValidationError}
              id="firstName"
              inputProps={{ "data-testid": "firstname-input" }}
              label="First name"
              size="small"
              type="text"
              value={firstName}
              variant="outlined"
              onBlur={() => {
                validateFirstNameField();
              }}
              onChange={(e) => {
                setFirstName(e.target.value);
                setFirstNameValidationError("");
              }}
            />
          </Box>
          <Box component="div" mt={4}>
            <TextField
              fullWidth
              required
              error={!!lastNameValidationError}
              helperText={lastNameValidationError}
              id="lastName"
              inputProps={{ "data-testid": "last-name-input" }}
              label="Last name"
              size="small"
              type="text"
              value={lastName}
              variant="outlined"
              onBlur={() => {
                validateLastNameField();
              }}
              onChange={(e) => {
                setLastName(e.target.value);
                setLastNameValidationError("");
              }}
            />
          </Box>

          <Box component="div" mt={4}>
            <TextField
              disabled
              fullWidth
              required
              id="email"
              inputProps={{ "data-testid": "email-input" }}
              label="Email address"
              size="small"
              type="email"
              value={email}
              variant="outlined"
            />
          </Box>
          <Box component="div" mt={4}>
            <TextField
              fullWidth
              required
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password input visibility"
                      onClick={handleClickShowPassword1}
                      onMouseDown={handleMouseDownPassword1}
                    >
                      {showPassword1 ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!password1ValidationError}
              helperText={password1ValidationError}
              id="password1"
              inputProps={{ "data-testid": "new-password-input" }}
              label="Password"
              size="small"
              type={showPassword1 ? "text" : "password"}
              value={password1}
              variant="outlined"
              onBlur={() => {
                validatePassword1Field();
              }}
              onChange={(e) => {
                setPassword1(e.target.value);
                setPassword1ValidationError("");
              }}
            />
          </Box>
          <Box component="div" mt={4}>
            <TextField
              fullWidth
              required
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password field visibility"
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleMouseDownPassword2}
                    >
                      {showPassword2 ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!password2ValidationError}
              helperText={password2ValidationError}
              id="password2"
              inputProps={{ "data-testid": "confirm-password-input" }}
              label="Re-enter password"
              size="small"
              type={showPassword2 ? "text" : "password"}
              value={password2}
              variant="outlined"
              onBlur={() => {
                validatePassword2Field();
              }}
              onChange={(e) => {
                setPassword2(e.target.value);
                setPassword2ValidationError("");
              }}
            />
          </Box>
          <TermsAndConditionsFormElement
            agree={agree}
            agreeValidationError={""}
            onChange={(newValue: boolean) => setAgree(newValue)}
          />
          <Box component="div" mt={2}>
            <ActionButton
              fullWidth
              color="warning"
              data-testid="submit-button"
              disabled={!formValid()}
              onClick={submitForm}
            >
              Register Account
            </ActionButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
