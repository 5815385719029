import { Camera } from "components/dialogs/StageElementDialog/Camera";
import { Light } from "components/dialogs/StageElementDialog/Light";
import { Rig } from "components/dialogs/StageElementDialog/Rig";
import { Wrapper } from "./ProTipDialogContent.styled";

const contentToRender = (currentPage: number) => {
  switch (currentPage) {
    case 0:
      return <Rig />;
    case 1:
      return <Camera />;
    case 2:
      return <Light />;
    default:
      return <></>;
  }
};

export function ProTipDialogContent({ currentPage }: { currentPage: number }) {
  const content = contentToRender(currentPage);
  return <Wrapper>{content}</Wrapper>;
}
