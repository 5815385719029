import { hasDistributions } from "features/JobBuilder2/domain";
import { StepItem } from "features/JobBuilder2/step-item/step-item";
import { segmentTrack } from "features/JobBuilder2/track-service";
import {
  defaultDistributionConfig,
  defaultSubjob,
  getPipe2Step,
  UpdatePickedSubjobFn,
} from "features/JobBuilder2/types";
import { DistributionCore } from "./core";

export const getDistributionStep = (
  subjob: typeof defaultSubjob,
  updatePickedSubjob: UpdatePickedSubjobFn,
  userID: string | undefined
) => {
  const onUpdateDistributionConfig = (v: typeof defaultDistributionConfig) =>
    updatePickedSubjob((d) => {
      d.distribution = v;
    });
  const onBlur = (fieldName: string, value: string) => () => {
    segmentTrack(
      `Distribution config field ${fieldName} blurred. Current value is ${value}`,
      userID
    );
  };
  return getPipe2Step(
    "Distributions",
    <StepItem>
      <DistributionCore
        subjob={subjob}
        onFieldBlur={onBlur}
        onUpdate={onUpdateDistributionConfig}
      />
    </StepItem>,
    hasDistributions(subjob.distribution)
  );
};
