import { IconContainer } from "./icons.styled";

export function ChevronDownIcon({
  style = { height: "24px" },
  fill = "currentColor",
  onClick,
}: {
  style?: React.CSSProperties;
  fill?: string;
  onClick?: () => void;
} = {}) {
  return (
    <IconContainer style={style} onClick={onClick}>
      <svg
        fill="none"
        height="24"
        style={style}
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.8751 8.99953L11.9951 12.8795L8.1151 8.99953C7.7251 8.60953 7.0951 8.60953 6.7051 8.99953C6.3151 9.38953 6.3151 10.0195 6.7051 10.4095L11.2951 14.9995C11.6851 15.3895 12.3151 15.3895 12.7051 14.9995L17.2951 10.4095C17.6851 10.0195 17.6851 9.38953 17.2951 8.99953C16.9051 8.61953 16.2651 8.60953 15.8751 8.99953Z"
          fill={fill}
        />
      </svg>
    </IconContainer>
  );
}
