import { Box, Breakpoint, Container } from "@mui/material";
import { Alerts } from "components/Alerts";
import { TopAppBar } from "components/Common/TopAppBar";
import { Footer } from "components/Footer";

type LayoutProps = {
  paddingTop?: number;
  maxWidth?: Breakpoint;
  children: React.ReactNode;
};

function Layout({ children, paddingTop = 5, maxWidth = "lg" }: LayoutProps) {
  return (
    <Box component="div" display="flex" flexDirection="column" height="100vh">
      <TopAppBar />
      <Container
        maxWidth={maxWidth}
        sx={{
          flex: "1 1 100%",
          marginTop: {
            xs: "64px",
            sm: "72px",
          },
        }}
      >
        <Alerts />
        <Box component="div" height="100%" paddingTop={paddingTop}>
          {children}
        </Box>
      </Container>
      <Footer />
    </Box>
  );
}

export { Layout };

export function withLayout<P extends object>(
  Component: React.ComponentType<P>,
  layoutProps?: Omit<LayoutProps, "children">
) {
  return function WithLayout(props: P) {
    return (
      <Layout {...(layoutProps || {})}>
        <Component {...props} />
      </Layout>
    );
  };
}
