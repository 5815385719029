export const Routes = {
  ROOT: "/",
  DESIGN_SYSTEM_SHOWCASE: "/design-system-showcase",
  NEW_ACCOUNT: "/accounts/new",
  LOGIN: "/login",
  JOBS_TUTORIAL: "/jobs-tutorial",
  FIRST_ORG: "/orgs/first",
  NEW_ORG: "/orgs/new",
  FORGOT_PASSWORD: "/password/forgot",
  //ideally this should be /password/reset but it was set to /reset on the backend and this is a callback URL
  RESET_PASSWORD: "/reset",
  CHANGE_PASSWORD: "/password/change",
  MANAGE_API_KEYS: "/api-keys/manage",
  NEW_API_KEY: "/api-keys/new",
  JOBS: "/jobs",
  ACCOUNT_INFORMATION: "/account",
  ADD_PAYMENT_INFO: "/payment",
  EDIT_PAYMENT_INFO: "/payment/edit",
  TEAM: "/team",
  SETTINGS: "/settings",
  ADD_USER: "/team/add-user",
  ACCEPT_INVITE: "/invite/reply",
  RESEND_ACTIVATION_EMAIL: "/activation/resend",
  ACCOUNT_VERIFIED: "/account-verified",
  UPDATE_EMAIL_REPLY: "/update-email-reply",
  CREATE_JOB: "/create-job",
  POS: "/pos",
  ORGS_STATS: "/orgs-stats",
  INVOICES: "/invoices",
  HUMAN_JOB: "/human-job",
  HUMAN_JOB_TEMPLATES_CATEGORY:
    "/human-job/templates/category/:templateCategoryId",
  HUMAN_JOB_TEMPLATES_CATEGORY_FN: (templateCategoryID: number) =>
    `/human-job/templates/category/${templateCategoryID}`,
  HUMAN_JOB_TEMPLATES_SUBCATEGORY:
    "/human-job/templates/category/:templateCategoryId/subcategory/:templateSubcategoryId",
  HUMAN_JOB_TEMPLATES_SUBCATEGORY_FN: (
    templateCategoryId: number,
    templateSubcategoryId: number
  ) =>
    `/human-job/templates/category/${templateCategoryId}/subcategory/${templateSubcategoryId}`,
  JOB_BUILDER: "/job-builder",
  JOB_BUILDER2: `/job-builder2/:draftId`,
  JOB_BUILDER2_FN: (draftID: string) => `/job-builder2/${draftID}`,
  CHARACTERS_BUILDER: "/characters",
};
