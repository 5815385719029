import styled from "styled-components";
import { colors } from "components/App/theme";

export const Container = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${colors.grey20};
  margin-top: 16px;
`;
