import React from "react";

export function EyeIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2691 12.2199L21.3767 12L21.2691 11.7801C19.5355 8.2395 15.9818 6 12 6C8.01821 6 4.46448 8.2395 2.73094 11.7801L2.62329 12L2.73094 12.2199C4.46448 15.7605 8.01821 18 12 18C15.9818 18 19.5355 15.7605 21.2691 12.2199ZM1.53928 12C3.24223 7.89095 7.28287 5 12 5C16.7171 5 20.7578 7.89095 22.4607 12C20.7578 16.1091 16.7171 19 12 19C7.28287 19 3.24223 16.1091 1.53928 12ZM15 12C15 10.3439 13.6561 9 12 9C10.3439 9 9 10.3439 9 12C9 13.6561 10.3439 15 12 15C13.6561 15 15 13.6561 15 12ZM8 12C8 9.79614 9.79614 8 12 8C14.2039 8 16 9.79614 16 12C16 14.2039 14.2039 16 12 16C9.79614 16 8 14.2039 8 12Z"
        fill="black"
        stroke={fill}
      />
    </svg>
  );
}
