import { DistKey } from "domain/Human";
import { DistributionGroup } from "features/JobBuilder/LeftSidebar/DistributionGroup";
import { selectPickedSubjob } from "features/JobBuilder/store";
import { useBoundStore } from "store/_boundStore";
import { IconsType } from "types/main";

export function TopLevelDist({
  id,
  label,
  icon,
}: {
  id: DistKey;
  label: string;
  icon: IconsType;
}) {
  const selectedIndecies = useBoundStore((s) => s.jobBuilder.selectedIndeces);
  const subjob = useBoundStore(selectPickedSubjob);
  const [
    selectedHumanObjectPart,
    setSelectedHumanObjectPart,
    deleteItem,
    setSelectedTopLevelDistGroupIndexByPart,
    addItem,
  ] = useBoundStore((s) => [
    s.jobBuilder.selectedPart,
    s.jobBuilder.setSelectedHumanObjectPart,
    s.jobBuilder.deleteDistItem,
    s.jobBuilder.setSelectedDistIndex,
    s.jobBuilder.addDistItem,
  ]);

  return (
    <DistributionGroup
      distributionGroups={subjob[id]}
      icon={icon}
      id={id}
      isSelected={selectedHumanObjectPart === id}
      label={label}
      selectIndex={setSelectedTopLevelDistGroupIndexByPart(id)}
      selectPart={() => setSelectedHumanObjectPart(id)}
      selectedIndex={selectedIndecies[id]}
      onAddDistributionGroup={addItem(id)}
      onDelete={deleteItem(id)}
    />
  );
}
