import { Box, Typography } from "@mui/material";
import styled, { css } from "styled-components";
import { colors } from "components/App/theme";

export const Container = styled(Box)<{
  $hasImage?: boolean;
  $noSpacing?: boolean;
}>`
  display: flex;
  flex-direction: ${(props) => (props.$hasImage ? "row" : "column")};
  width: 100%;
  justify-content: flex-start;
  align-items: ${(props) => (props.$hasImage ? "flex-end" : "flex-start")};

  ${({ $noSpacing }) =>
    $noSpacing
      ? css`
          margin: 0;
          padding: 0;
        `
      : css`
          margin: 8px 0;
          padding: 24px 24px 36px;
        `}
`;

export const EndContainer = styled(Box)`
  height: 100%;
`;

export const Label = styled(Typography)`
  line-height: 21px;
  font-size: 14px;
  color: ${colors.grey70};
  line-clamp: 1;
  white-space: nowrap;
  padding-left: 8px;
`;

export const SelectorContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 8px;
  height: 40px;
`;

export const Description = styled(Typography)`
  width: 100%;
  line-height: 21px;
  font-size: 14px;
  color: ${colors.grey70};
  padding-left: 16px;
`;

export const InfoContainer = styled(Box)`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 250px;
`;

export const ExampleImage = styled.img<{
  $noSpacing?: boolean;
}>`
  width: 220px;
  height: 110px;
  object-fit: contain;
  border-radius: 4px;

  ${({ $noSpacing }) =>
    $noSpacing
      ? css`
          margin-top: 20px;
        `
      : css`
          margin-top: 40px;
        `}
`;
