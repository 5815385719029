import { createSelector } from "reselect";
import { getLocationOrThrow } from "features/JobBuilder2/steps/threeDimensional/utils/Location";
import { selectPickedSubjobLocation } from "features/JobBuilder2/store";
import { selectCatalogLocations } from "store/catalogueDataStore";

export const selectPL2Location = createSelector(
  [selectPickedSubjobLocation, selectCatalogLocations],
  (pickedSubjobLocation, locations) =>
    getLocationOrThrow(pickedSubjobLocation, locations)
);
