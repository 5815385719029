import {
  defaultDemographicBuilder,
  defaultRig,
  defaultSection,
  defaultSkin,
  DetailedEnums,
  DistKey,
  Section,
  ThreeDLocationType,
  Gesture,
} from "domain/Human";
import { Template } from "features/JobBuilder/types";
import { SliceType } from "store/_boundStore";
import { StringArrayKeys } from "utils/typesUtil";
import { createActions } from "./actions";

export * from "./selectors";

type SelectedIndeces = {
  [k in keyof Section]: number;
};

type State = {
  // Data
  template: Template | null;
  enums: DetailedEnums;
  gestures: { [k: string]: Gesture };
  openEditor: boolean;

  // Top level
  subjobs: Section[];
  pickedSubjobIndex: number;

  // Identities
  selectedIdentitiesPrefab: number | null;

  selectedIndeces: SelectedIndeces;

  selectedPart: keyof Section;
  // Cameras & Lights (Rig)
  selectedRigIndex: number;
};
type Actions = {
  addRig: () => void;
  deleteRig: (i: number) => void;
  onUpdateDemographicBuilder: (v: typeof defaultDemographicBuilder) => void;
  onUpdatedIdentities: (v: number[]) => void;
  onUpdateRigs: (v: (typeof defaultRig)[]) => void;
  onUpdateScenesPerIdentities: (v: string) => void;
  onUpdateSkin: (v: typeof defaultSkin) => void;
  saveEnums: (enums: DetailedEnums) => void;
  saveGestures: (gestures: { [k: string]: Gesture }) => void;
  saveInitialHumansAPIData: (
    enums: DetailedEnums,
    gestures: { [k: string]: Gesture }
  ) => void;
  setOpenEditor: (flag: boolean) => void;
  setPickedSubjobIndex: (subjobIndex: number) => void;
  setSelectedHumanObjectPart: (part: keyof Section) => void;
  setSelectedIdentitiesPrefab: (prefab: number | null) => void;
  setSelectedRigIndex: (index: number) => void;
  setSubjobs: (subjobs: Section[] | ((s: Section[]) => Section[])) => void;
  setTemplate: (template: Template) => void;
  setThreeDLocation(v: ThreeDLocationType): void;
  updateJobName: (name: string) => void;
  updateRig: (v: typeof defaultRig) => void;
  updateSubjob: (fn: (draft: Section) => void) => void;
  deleteDistItem: (k: DistKey) => (i: number) => void;
  setSelectedDistIndex: (k1: DistKey) => (i: number) => void;
  addDistItem: <T extends DistKey>(key: T) => (v?: Section[T][0]) => void;
  addNewDistItemForSelectedPart: () => void;
  goToNextPart: () => void;
  goToPrevPart: () => void;
  onUpdateModule: <T extends keyof Section>(type: T) => (v: Section[T]) => void;
  onUpdateDistPart: <T extends DistKey, S extends keyof Section[T][0]>(
    type: T
  ) => (subKey: S) => (v: Section[T][number][S]) => void;
  onUpdateNonDistPart: <T extends keyof Section, S extends keyof Section[T]>(
    type: T
  ) => (subKey: S) => (v: Section[T][S]) => void;
  updatePercent: (type: DistKey) => (v: string) => void;
  toggleDistListItem: <
    T extends DistKey,
    S extends StringArrayKeys<Section[T][number]>
  >(
    type: T
  ) => (subKey: S, allOptions: string[]) => (optionIndex: number) => void;
  toggleNonDistListItem: <
    T extends keyof Section,
    S extends StringArrayKeys<Section[T]>
  >(
    type: T
  ) => (subKey: S, allOptions: string[]) => (optionIndex: number) => void;
};

export type JobBuilderSlice = State & Actions;

const initialState: State = {
  // Data
  template: null,
  enums: {} as DetailedEnums,
  gestures: {},

  // JSON editor
  openEditor: false,

  // Top level
  pickedSubjobIndex: 0,
  subjobs: [{ ...defaultSection }],

  // Identities
  selectedIdentitiesPrefab: null,

  selectedPart: "demographicBuilder",
  selectedIndeces: Object.fromEntries(
    Object.entries(defaultSection).map(([key]) => [key, 0])
  ) as SelectedIndeces,
  // Cameras & Lights (Rig)
  selectedRigIndex: 0,
};

export const createJobBuilderSlice: SliceType<JobBuilderSlice> = (set, get) => {
  return {
    ...initialState,
    ...createActions(set, get),
  };
};
