export function HeightIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 6.49H12.5V6.99V17.01V17.51H13H14.7908L12 20.2938L9.20925 17.51H11H11.5V17.01V6.99V6.49H11H9.20925L12 3.70622L14.7908 6.49H13Z"
        stroke="#9A9A9B"
      />
    </svg>
  );
}
