import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
} from "@mui/material";
import { colors } from "components/App/theme";
import { ConfirmationDialog } from "components/dialogs/ConfirmationDialog";
import { Messages } from "constants/messages";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";

const chipBackground = {
  owner: colors.bright[2],
  writer: colors.deep[2],
  reader: colors.tuna,
};
export function EditUser({
  isVerified,
  orgName,
  email,
  user_id,
  role,
  allowEdit,
  onUpdateRole,
  onRemoveUser,
}: {
  isVerified: boolean;
  orgName: string;
  email: string;
  user_id: number;
  role: "owner" | "writer" | "reader";
  allowEdit: boolean;
  onUpdateRole: (email: string, role: "owner" | "writer" | "reader") => void;
  onRemoveUser: (userId: number) => void;
}) {
  const { setErrorMessage, setSuccessMessage } =
    useBoundStore.getState().message;
  const [roleInRadio, setRoleInRadio] = useState<
    "owner" | "writer" | "reader" | undefined
  >(undefined);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [isResendingInvite, setIsResendingInvite] = useState(false);

  const resendInvite = async () => {
    try {
      setIsResendingInvite(true);
      await restClient.resendInvite(orgName, email);
      setSuccessMessage(Messages.sentAnotherInviteEmail(email));
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setIsResendingInvite(false);
  };

  if (roleInRadio) {
    return (
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        margin="auto"
        mb={1}
        mt={1}
        width="80%"
      >
        <Box component="div" display="flex" flexDirection="column">
          <FormControl variant="standard">
            <RadioGroup
              aria-label="org"
              name="org"
              value={roleInRadio}
              onChange={(e) =>
                setRoleInRadio(e.target.value as "owner" | "writer" | "reader")
              }
            >
              {["owner", "writer", "reader"].map((r) => (
                <FormControlLabel
                  key={r}
                  control={<Radio color="warning" />}
                  label={r}
                  value={r}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
        <Box
          component="div"
          display="flex"
          flex="1 0"
          justifyContent="center"
          mr={2}
        >
          <Box component="div" flex="1 1 50%" mr={0.5}>
            <Button
              fullWidth
              color="warning"
              size="small"
              variant="contained"
              onClick={() => {
                onUpdateRole(email, roleInRadio);
              }}
            >
              Update
            </Button>
          </Box>
          <Box component="div" flex="1 1 50%" ml={0.5}>
            <Button
              fullWidth
              color="secondary"
              size="small"
              variant="contained"
              onClick={() => setRoleInRadio(undefined)}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <>
      {showRemoveDialog && (
        <ConfirmationDialog
          textContent={
            <>
              {" "}
              Are you sure you want to remove{" "}
              <span className="bold-text">{email}</span> from{" "}
              <span className="bold-text">{orgName}</span>?{" "}
            </>
          }
          title="Remove user"
          onCancel={() => setShowRemoveDialog(false)}
          onConfirm={() => {
            setShowRemoveDialog(false);
            onRemoveUser(user_id);
          }}
        />
      )}
      <Box alignItems="center" component="div" display="flex" width="100%">
        <Chip
          label={role}
          sx={{
            margin: "1px",
            height: "30px",
            color: colors.white,
            background: chipBackground[role],
          }}
        />
        {allowEdit && (
          <Box component="div" flex="1 0" mr={2} textAlign="right">
            {!isVerified && (
              <>
                {isResendingInvite ? (
                  <Box
                    component="div"
                    display="inline-block"
                    pb={1}
                    position="relative"
                    top="4px"
                  >
                    <CircularProgress size="1rem" />
                  </Box>
                ) : (
                  <Link
                    component="button"
                    underline="hover"
                    onClick={() => resendInvite()}
                  >
                    Resend Invite
                  </Link>
                )}
                &nbsp;|&nbsp;
              </>
            )}
            <Link
              component="button"
              underline="hover"
              onClick={() => setRoleInRadio(role)}
            >
              Edit
            </Link>
            &nbsp;|&nbsp;
            <Link
              component="button"
              underline="hover"
              onClick={() => setShowRemoveDialog(true)}
            >
              Remove
            </Link>
          </Box>
        )}
      </Box>
    </>
  );
}
