import { Box, Select, MenuItem } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const DropdownContainer = styled(Box)`
  width: 100%;

  .css-1fxfzbz-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.grey20};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  text-transform: capitalize;
`;

export const DropdownSelect = styled(Select)`
  height: 40.16px;

  &:before {
    border-color: ${colors.tint5};
  }

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 8px 16px;
    width: 100%;
  }

  .MuiTypography-root {
    font-size: 14px;
    line-height: 21px;
    color: ${colors.grey70};
  }

  /* Dropdown Options Styling */
  .css-85dhaq-MuiTypography-root {
    font-size: 14px;
    line-height: 21px;
  }

  /* Border */
  .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.grey20};
  }

  .MuiSelect-icon {
    margin-right: 4px;
    position: absolute;
    transition: 0.3s;
  }
`;
