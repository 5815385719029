import React, { useMemo } from "react";
import { Html } from "@react-three/drei";
import { useBoundStore } from "store/_boundStore";
import { selectPL2LocationDimension } from "store/selectors/selectPL2LocationDimension";
import { PreventHTMLPointerEvents } from "./PreventHTMLPointerEvents";
export const rulerSize = 70;
const zIndex = 1;

const rulerSharedStyling: React.CSSProperties = {
  background: "#fdf1e9",
  zIndex: -1,
  border: "3px solid #e0dedc",
  fontSize: 30,
  padding: 0,
  lineHeight: 0,
  overflow: "hidden",
  position: "relative",
};

export function MetricRuler({ scale }: { scale: number }) {
  // location diemensions
  const { width: locationWidth, height: locationHeight } = useBoundStore(
    selectPL2LocationDimension
  );

  const { topRulerMeters, leftRulerMeters } = useMemo(() => {
    // if the location's height or width is bigger than 15meter make the scale of the ruler every 5m otherwise 1m
    const scale = Math.max(locationWidth, locationHeight) > 18 ? 5 : 1;
    const topRulerCount = +(locationWidth / scale).toFixed();
    const leftRulerCount = +(locationHeight / scale).toFixed();

    const topRulerMeters = new Array(topRulerCount)
      .fill(0)
      .map((_, i) => i * scale);
    const leftRulerMeters = new Array(leftRulerCount)
      .fill(0)
      .map((_, i) => i * scale);

    return {
      topRulerMeters,
      leftRulerMeters,
    };
  }, [locationHeight, locationWidth]);

  if (!scale) {
    return null;
  }

  return (
    <group>
      {/* Top Ruler */}
      <Html
        as="div"
        distanceFactor={1 / scale}
        position={[0, locationHeight / 2, zIndex]}
        style={{
          ...rulerSharedStyling,
          width: scale * locationWidth,
          height: rulerSize,
          translate: "-50% -100%",
          borderTopRightRadius: 20,
        }}
        zIndexRange={[1, 0]}
      >
        {/* ??? Assuming we don't want the rulers to be clickable? */}
        <PreventHTMLPointerEvents />
        {topRulerMeters.map(
          (m) => m !== 0 && <MeterComponent key={m} ratio={scale} value={m} />
        )}
      </Html>
      {/* Left Ruler */}
      <Html
        as="div"
        distanceFactor={1 / scale}
        position={[-locationWidth / 2, 0, zIndex]}
        style={{
          ...rulerSharedStyling,
          width: rulerSize,
          height: scale * locationHeight + rulerSize,
          translate: `-100% calc(-50% - ${rulerSize / 2}px)`,
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
        }}
        zIndexRange={[1, 0]}
      >
        {/* ??? Assuming we don't want the rulers to be clickable? */}
        <PreventHTMLPointerEvents />
        {leftRulerMeters.map(
          (m) =>
            m !== 0 && (
              <MeterComponent key={m} isRotated ratio={scale} value={m} />
            )
        )}
      </Html>
    </group>
  );
}

function MeterComponent({
  value,
  ratio,
  isRotated,
}: {
  value: number;
  isRotated?: boolean;
  ratio: number;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "fit-content",
        position: "absolute",
        bottom: isRotated ? "unset" : 0,
        left: isRotated ? "unset" : ratio * value - 3,
        right: isRotated ? 8 : "0",
        top: isRotated ? ratio * value + rulerSize - 2.5 : "unset",
        rotate: isRotated ? "-90deg" : "unset",
        translate: `${isRotated ? 0 : "-50%"} ${isRotated ? "-50%" : 0}`,
        pointerEvents: "none",
      }}
    >
      <p>{value}m</p>
      <div>|</div>
    </div>
  );
}
