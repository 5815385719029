export function RigIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 2.5C21.2739 2.5 21.5 2.72614 21.5 3V21C21.5 21.2739 21.2739 21.5 21 21.5C20.7261 21.5 20.5 21.2739 20.5 21V3C20.5 2.72614 20.7261 2.5 21 2.5ZM16.5 9.5H3.5C2.94614 9.5 2.5 9.05386 2.5 8.5C2.5 7.94614 2.94614 7.5 3.5 7.5H16.5C17.0539 7.5 17.5 7.94614 17.5 8.5C17.5 9.05386 17.0539 9.5 16.5 9.5ZM16.5 16.5H9.5C8.94614 16.5 8.5 16.0539 8.5 15.5C8.5 14.9461 8.94614 14.5 9.5 14.5H16.5C17.0539 14.5 17.5 14.9461 17.5 15.5C17.5 16.0539 17.0539 16.5 16.5 16.5Z"
        fill={fill}
        stroke={fill}
      />
    </svg>
  );
}
