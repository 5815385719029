import React, { useState } from "react";
import { Box, Typography, Link } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { colors } from "components/App/theme";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { InlineEdit } from "components/Common/InlineEdit";
import { URLParams } from "constants/params";
import { Routes } from "constants/routes";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";

export function OrgName({ orgName }: { orgName: string }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [setErrorMessage, replaceOrgs] = useBoundStore((s) => [
    s.message.setErrorMessage,
    s.org.replaceOrgs,
  ]);
  const [isEditing, setIsEditing] = useState(false);
  const rename = async (newName: string) => {
    setLoading(true);
    try {
      await restClient.renameOrg(orgName!, newName);
      await replaceOrgs();
      navigate(`${Routes.SETTINGS}?${URLParams.org}=${newName}`);
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <FullScreenProgress />}
      <Box
        bgcolor={colors.neutral[0]}
        component="div"
        data-testid="org-name-component"
        height="110px"
        padding={2}
      >
        <Box component="div" mb={2}>
          <Typography variant="h2">Org name</Typography>
        </Box>
        <InlineEdit
          data-testid="rename-org-component"
          editIcon={
            <Link component="button" data-testid="edit-link">
              {" "}
              Edit{" "}
            </Link>
          }
          flexGrow={0}
          isEditing={isEditing}
          saveLabel="Rename"
          value={orgName || ""}
          onSave={(value) => rename(value)}
          onToggleEditingMode={(e) => setIsEditing(e)}
        />
      </Box>
    </>
  );
}
