import { useEffect } from "react";
import { Box, Tabs } from "@mui/material";
import { TabInlineEdit } from "components/Common/TabInlineEdit";
import { defaultCamera, defaultLight, defaultRig } from "domain/Human";
import { Camera } from "features/JobBuilder/camera";
import { Light } from "features/JobBuilder/light";
import {
  Container,
  Content,
  TabHeaders,
  TabPanel,
  Separator,
  NoRenameText,
  StyledTab,
} from "./CamerasAndLightsTabs.styled";

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export function CamerasAndLightsTabs({
  value,
  currentTab,
  onUpdateCamera,
  onUpdateLight,
  setRigPartDeletionType,
  setIsDeletingIndex,
  onUpdateCameraName,
  onUpdateLightName,
  setCurrentTab,
}: {
  value: typeof defaultRig;
  currentTab: number;
  onUpdateCamera: (index: number) => (v: typeof defaultCamera) => void;
  onUpdateLight: (index: number) => (v: typeof defaultLight) => void;
  setRigPartDeletionType: React.Dispatch<
    React.SetStateAction<"camera" | "light" | null>
  >;
  setIsDeletingIndex: React.Dispatch<React.SetStateAction<number | false>>;
  onUpdateCameraName: (index: number, newName: string) => void;
  onUpdateLightName: (index: number, newName: string) => void;
  setCurrentTab: (index: number) => void;
}) {
  const handleChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setCurrentTab(value);
  };

  useEffect(() => {
    const camerasLength = value.cameras.length;
    const lightsLength = value.lights.length;
    const lastIndex = Math.max(0, camerasLength + lightsLength - 1);
    if (currentTab > lastIndex) {
      setCurrentTab(lastIndex);
    }
  }, [value.cameras.length, value.lights.length, currentTab, setCurrentTab]);

  return (
    <Container>
      <TabHeaders>
        <Tabs
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
          onChange={handleChange}
        >
          {value.cameras.map((c, i) => (
            <StyledTab
              key={i}
              label={
                <TabInlineEdit
                  isSelected={i === currentTab}
                  oldLabel={c.name}
                  onSave={(newLabel) => onUpdateCameraName(i, newLabel)}
                />
              }
              style={{ padding: "0 6px" }}
              {...a11yProps(i)}
            />
          ))}
          {value.lights.map((l, i) => {
            const lightIndex = i + value.cameras.length;
            return (
              <StyledTab
                key={i}
                label={
                  <Box component="div">
                    <NoRenameText $selected={lightIndex === currentTab}>
                      {l.name}
                    </NoRenameText>
                  </Box>
                }
                {...a11yProps(i)}
              />
            );
          })}
        </Tabs>
      </TabHeaders>
      <Separator />
      <Content>
        {value.cameras.map((c, index) => (
          <TabPanel
            key={index}
            aria-labelledby={`full-width-tab-${index}`}
            hidden={currentTab !== index}
            id={`full-width-tabpanel-${index}`}
            role="tabpanel"
          >
            <Camera
              rigType={value.type}
              value={c}
              onDelete={() => {
                setRigPartDeletionType("camera");
                setIsDeletingIndex(index);
                setCurrentTab(Math.max(currentTab - 1, 0));
              }}
              onUpdate={onUpdateCamera(index)}
            />
          </TabPanel>
        ))}
        {value.lights.map((l, i) => {
          const lightIndex = i + value.cameras.length;
          return (
            <TabPanel
              key={lightIndex}
              aria-labelledby={`full-width-tab-${lightIndex}`}
              hidden={currentTab !== lightIndex}
              id={`full-width-tabpanel-${lightIndex}`}
              role="tabpanel"
            >
              <Light
                myIndex={i}
                value={l}
                onDelete={() => {
                  setRigPartDeletionType("light");
                  setIsDeletingIndex(i);
                  setCurrentTab(Math.max(currentTab - 1, 0));
                }}
                onUpdate={onUpdateLight(i)}
              />
            </TabPanel>
          );
        })}
      </Content>
    </Container>
  );
}
