import { Typography, Box } from "@mui/material";
import styled from "styled-components";

export const SmallTitle = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
`;

export const SelectorContainer = styled(Box)`
  margin: 8px 0;
  padding: 24px 48px 36px 0;
`;
