import styled from "styled-components";
import { colors } from "components/App/theme";

export const Container = styled.div`
  background-color: #fff;
  padding-bottom: 20px;
  overflow-y: auto;
`;

export const Header = styled.h2`
  font-size: 16px;
  color: ${colors.grey70};
  margin: 0;
  border-bottom: 1px solid ${colors.grey20};
  padding: 10px 10px 10px 20px;
  font-weight: 600;
`;

export const CameraPreviewWrapper = styled.div<{ noBorder?: boolean }>`
  padding: 20px 20px 20px 20px;
  ${(props) => !props.noBorder && `border-bottom: 1px solid ${colors.grey20}`};
`;

export const CameraPreviewTitle = styled(Header)`
  padding: 0;
  border-bottom: 0;
  font-size: 12px;
  margin-bottom: 10px;
`;

export const CameraPreviewContent = styled(Header)`
  padding: 0;
  border: 1px solid ${colors.grey20};
  border-radius: 8px;
  width: 100%;
  height: 166px;
  overflow: hidden;
  background-color: #444444;
`;
