import {
  DynamicFeedOutlined,
  Image,
  LocationOnOutlined,
  TabUnselectedOutlined,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { colors } from "components/App/theme";
import {
  selectNumberOfActivityZonesForAllSubjobs,
  selectNumberOfImagesForAllSubjobs,
  selectNumberOfScenesForAllSubjobs,
} from "features/JobBuilder2/store";
import { useBoundStore } from "store/_boundStore";
import { compact } from "utils/stringUtil";

export function JobGenerationInfo() {
  const [
    numberOfScenesForAllSubjobs,
    numberOfImagesForAllSubjobs,
    totalNumberOfLocations,
    totalNumberOfActivityZones,
  ] = useBoundStore((s) => [
    selectNumberOfScenesForAllSubjobs(s),
    selectNumberOfImagesForAllSubjobs(s),
    s.jobBuilder2.subjobs.length,
    selectNumberOfActivityZonesForAllSubjobs(s),
  ]);
  return (
    <Box
      component="div"
      p={2}
      pt={0}
      sx={{
        backgroundColor: colors.tint4,
        color: colors.grey70,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box component="div" flex={1}>
        <Box component="div">
          <LocationOnOutlined sx={{ fontSize: "16px" }} />{" "}
          {totalNumberOfLocations} locations
        </Box>
        <Box component="div">
          <DynamicFeedOutlined sx={{ fontSize: "16px" }} />{" "}
          {compact(numberOfScenesForAllSubjobs)} simulations
        </Box>
      </Box>
      <Box component="div" flex={1}>
        <Box component="div">
          <Image sx={{ fontSize: "16px" }} />
          {compact(numberOfImagesForAllSubjobs)} images
        </Box>
        <Box component="div">
          <TabUnselectedOutlined sx={{ fontSize: "16px" }} />{" "}
          {totalNumberOfActivityZones} zones
        </Box>
      </Box>
    </Box>
  );
}
