import React, { memo, useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import Lightbox from "react-image-lightbox";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { VirtualizedList } from "components/Common/VirtualizedList";
import { StrContentDialogWithCopyToClipboard } from "components/dialogs/GalleryDialog/StrContentDialogWithCopyToClipboard";
import { JsonDialog } from "components/dialogs/JsonDialog";
import { getInfoSuffix } from "domain/Asset";
import { useImagesFromManifest } from "hooks/useImagesFromManifest";
import { restClient } from "services/restClient/main";
import { disableBackdropClickClose } from "services/ui-service";
import { useBoundStore } from "store/_boundStore";

// import { SingleImageDialog } from "./single-image-dialog";

type props = {
  jobName: string;
  orgName: string;
  jobID: string;
  onClose: () => void;
};

const isString = (content: string | object): content is string =>
  typeof content === "string";

type DialogData = {
  title: string;
  content: string | object;
};

export const GalleryDialog = memo(function GalleryDialog({
  jobName,
  orgName,
  jobID,
  onClose,
}: props) {
  const [images, manifestLoading, manifest] = useImagesFromManifest(
    orgName,
    jobID
  );
  const [photoIndex, setphotoIndex] = useState<null | number>(null);
  const [dialogData, setDialogData] = useState<DialogData | null>(null);
  const [loading, setLoading] = useState(false);
  const { setErrorMessage } = useBoundStore.getState().message;

  const onMovePrevRequest = useCallback(() => {
    setphotoIndex((photoIndex) => {
      if (photoIndex === null) {
        return null;
      }
      return (photoIndex + images.length - 1) % images.length;
    });
  }, [images.length]);

  const onMoveNextRequest = useCallback(() => {
    setphotoIndex((photoIndex) => {
      if (photoIndex === null) {
        return null;
      }
      return (photoIndex + 1) % images.length;
    });
  }, [images.length]);

  function Cell(imageIndex: number) {
    return (
      <img
        alt=""
        src={images[imageIndex].url}
        style={{
          cursor: "pointer",
          maxWidth: "200px",
          maxHeight: "200px",
          height: "auto",
          width: "auto",
        }}
        onClick={() => setphotoIndex(imageIndex)}
      />
    );
  }

  useEffect(() => {
    function handleKeyUp(event: KeyboardEvent) {
      switch (event.key) {
        case "ArrowLeft":
          onMovePrevRequest();
          break;
        case "ArrowRight":
          onMoveNextRequest();
          break;
        case "Escape":
          setphotoIndex(null);
          break;
      }
    }
    window.addEventListener("keyup", handleKeyUp);
    return () => window.removeEventListener("keyup", handleKeyUp);
  }, [photoIndex, onMoveNextRequest, onMovePrevRequest]);

  const onShowMetadataJsonLClick = async () => {
    if (!manifest || photoIndex === null) {
      return;
    }
    try {
      setLoading(true);
      const content = await restClient.customURLGetWithAuth(
        manifest["metadata.jsonl"] +
          `?count=1&offset=${images[photoIndex].renderID}`
      );
      setDialogData({ title: "Metadata", content });
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  const onShowInfoClick = async () => {
    if (!manifest || typeof photoIndex !== "number") {
      return;
    }
    try {
      setLoading(true);
      const url = await restClient.getAssetURL(
        orgName,
        jobID,
        images[photoIndex].renderID,
        images[photoIndex].frameNumber,
        images[photoIndex].camName,
        getInfoSuffix(manifest)
      );
      const content = await restClient.customURLGetWithoutAuth(url);
      setDialogData({ title: "Landmarks", content });
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  const onShowHythonClick = async () => {
    if (!manifest || typeof photoIndex !== "number") {
      return;
    }
    try {
      setLoading(true);
      const content = await restClient.getHython(
        orgName,
        jobID,
        images[photoIndex].renderID,
        images[photoIndex].frameNumber,
        images[photoIndex].camName
      );
      setDialogData({ title: "Hython", content });
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  return (
    <>
      {Boolean(manifestLoading || loading) && <FullScreenProgress />}
      {dialogData &&
        (isString(dialogData.content) ? (
          <StrContentDialogWithCopyToClipboard
            content={dialogData.content}
            title={dialogData.title}
            onClose={() => setDialogData(null)}
          />
        ) : (
          <JsonDialog
            payload={dialogData.content}
            title={dialogData.title}
            onClose={() => setDialogData(null)}
          />
        ))}
      {typeof photoIndex === "number" && (
        <Lightbox
          imageCaption={
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              width="98vw"
            >
              <Typography>
                render: {images[photoIndex].renderID}
                <br />
                camera name: {images[photoIndex].camName}
                <br />
                frame number: {images[photoIndex].frameNumber}
                <br />
              </Typography>
              <Box component="div">
                {(
                  [
                    ["Show metadata", onShowMetadataJsonLClick],
                    ["Show landmarks", onShowInfoClick],
                    ["Show hython", onShowHythonClick],
                  ] as Array<[string, () => void]>
                ).map((tuple) => (
                  <Button
                    key={tuple[0]}
                    sx={{ mr: 2, color: "white" }}
                    variant="outlined"
                    onClick={tuple[1]}
                  >
                    {tuple[0]}
                  </Button>
                ))}
              </Box>
            </Box>
          }
          mainSrc={images[photoIndex].url}
          nextSrc={images[(photoIndex + 1) % images.length].url}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].url}
          reactModalProps={{}}
          reactModalStyle={{
            overlay: {
              zIndex: 2000,
            },
          }}
          onCloseRequest={() => setphotoIndex(null)}
          onMoveNextRequest={onMoveNextRequest}
          onMovePrevRequest={onMovePrevRequest}
        />
      )}
      <Dialog
        disableEscapeKeyDown
        fullScreen
        open
        data-testid="new-api-key-dialog"
        onClose={(e, reason) => disableBackdropClickClose(reason, onClose)}
      >
        <DialogTitle>
          <Box alignItems="flex-start" component="div" display="flex">
            <Box component="div" flexGrow={1}>
              {jobName}
            </Box>
            <Box component="div" display="flex" flexGrow={0}>
              <CloseIcon onClick={onClose} />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <VirtualizedList cellWidth={205} items={images} rowHeight={205}>
            {Cell}
          </VirtualizedList>
        </DialogContent>
        <DialogActions>
          <Box
            component="div"
            display="flex"
            justifyContent="space-between"
            mb={1}
            ml={2}
            mr={2}
            mt={2}
            textAlign="right"
            width="100%"
          >
            <Button
              autoFocus
              color="warning"
              size="small"
              variant="contained"
              onClick={onClose}
            >
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
},
areEqual);

function areEqual(prevProps: Readonly<props>, nextProps: Readonly<props>) {
  return (
    prevProps.jobID === nextProps.jobID &&
    prevProps.jobName === nextProps.jobName &&
    prevProps.orgName === nextProps.orgName
  );
}
