export function EnvironmentIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M8.57554 5.09621C9.40314 4.01269 10.6053 3.25 12 3.25C13.3947 3.25 14.5969 4.01269 15.4245 5.09621C16.2529 6.1808 16.75 7.63417 16.75 9.16661V16.5475C16.75 16.8887 16.6466 17.2432 16.4257 17.5285C16.2014 17.8182 15.85 18.0356 15.4286 18.0356H8.57143C8.14998 18.0356 7.79862 17.8182 7.57433 17.5285C7.35341 17.2432 7.25 16.8887 7.25 16.5475V9.16661C7.25 7.63417 7.74714 6.1808 8.57554 5.09621ZM9.7676 6.00671C9.14829 6.81753 8.75 7.94747 8.75 9.16661V16.5356H15.25V9.16661C15.25 7.94747 14.8517 6.81753 14.2324 6.00671C13.6123 5.19482 12.8144 4.75 12 4.75C11.1856 4.75 10.3877 5.19482 9.7676 6.00671Z"
        fill={fill}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.25 11.3816C7.25 10.9674 7.58579 10.6316 8 10.6316H16C16.4142 10.6316 16.75 10.9674 16.75 11.3816C16.75 11.7958 16.4142 12.1316 16 12.1316H8C7.58579 12.1316 7.25 11.7958 7.25 11.3816Z"
        fill={fill}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12 3.25049C12.4142 3.25049 12.75 3.58627 12.75 4.00049V17.2861C12.75 17.7003 12.4142 18.0361 12 18.0361C11.5858 18.0361 11.25 17.7003 11.25 17.2861V4.00049C11.25 3.58627 11.5858 3.25049 12 3.25049Z"
        fill={fill}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M2.79024 9.91948L5.10958 10.6149C5.13579 10.6228 5.16241 10.6292 5.1893 10.6342C5.19105 10.6363 5.19303 10.6389 5.19521 10.6419C5.219 10.6748 5.25 10.7439 5.25 10.8461V15.4439C5.25 15.5461 5.219 15.6152 5.19521 15.6481C5.19303 15.6511 5.19105 15.6537 5.1893 15.6558C5.16241 15.6608 5.13579 15.6672 5.10958 15.6751L2.87589 16.3449C2.84853 16.343 2.82101 16.3426 2.79347 16.3438C2.77222 16.3026 2.75 16.2356 2.75 16.1436V10.0965C2.75 10.0175 2.76991 9.95757 2.79024 9.91948ZM2.57739 17.8511C2.21323 17.8124 1.90449 17.6133 1.69599 17.3776C1.40929 17.0534 1.25 16.6095 1.25 16.1436V10.0965C1.25 9.65651 1.39902 9.22971 1.67442 8.90856C1.95344 8.58319 2.39054 8.34405 2.90779 8.40149C2.95272 8.40648 2.99711 8.41552 3.04042 8.42851L5.49331 9.16403C6.31272 9.31326 6.75 10.1212 6.75 10.8461V15.4439C6.75 16.1688 6.31272 16.9768 5.49331 17.126L3.04042 17.8615C2.88701 17.9075 2.725 17.9027 2.57739 17.8511ZM5.17992 15.6657C5.17994 15.6657 5.18042 15.6654 5.18134 15.6645C5.18037 15.6652 5.17991 15.6656 5.17992 15.6657ZM5.17992 10.6243C5.17991 10.6244 5.18037 10.6248 5.18134 10.6255C5.18042 10.6246 5.17994 10.6243 5.17992 10.6243Z"
        fill={fill}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M21.2098 9.91948L18.8904 10.6149C18.8642 10.6228 18.8376 10.6292 18.8107 10.6342C18.8089 10.6363 18.807 10.6389 18.8048 10.6419C18.781 10.6748 18.75 10.7439 18.75 10.8461V15.4439C18.75 15.5461 18.781 15.6152 18.8048 15.6481C18.807 15.6511 18.8089 15.6537 18.8107 15.6558C18.8376 15.6608 18.8642 15.6672 18.8904 15.6751L21.1241 16.3449C21.1515 16.343 21.179 16.3426 21.2065 16.3438C21.2278 16.3026 21.25 16.2356 21.25 16.1436V10.0965C21.25 10.0175 21.2301 9.95757 21.2098 9.91948ZM21.4226 17.8511C21.7868 17.8124 22.0955 17.6133 22.304 17.3776C22.5907 17.0534 22.75 16.6095 22.75 16.1436V10.0965C22.75 9.65651 22.601 9.22971 22.3256 8.90856C22.0466 8.58319 21.6095 8.34405 21.0922 8.40149C21.0473 8.40648 21.0029 8.41552 20.9596 8.42851L18.5067 9.16403C17.6873 9.31326 17.25 10.1212 17.25 10.8461V15.4439C17.25 16.1688 17.6873 16.9768 18.5067 17.126L20.9596 17.8615C21.113 17.9075 21.275 17.9027 21.4226 17.8511ZM18.8201 15.6657C18.8201 15.6657 18.8196 15.6654 18.8187 15.6645C18.8196 15.6652 18.8201 15.6656 18.8201 15.6657ZM18.8201 10.6243C18.8201 10.6244 18.8196 10.6248 18.8187 10.6255C18.8196 10.6246 18.8201 10.6243 18.8201 10.6243Z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
}
