const MIN_PASSWORD_LENGTH = 8;

interface ValidationResult {
  valid: boolean;
  message?: string;
}

const EMAIL_REGEX = RegExp(
  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
);

function valid(): ValidationResult {
  return { valid: true };
}

function invalid(message: string): ValidationResult {
  return { valid: false, message };
}

export const validateEmail = (email: string): ValidationResult => {
  if (!email || !email.trim()) {
    return invalid("Email cannot be empty");
  }

  return EMAIL_REGEX.test(email) ? valid() : invalid("Invalid Email");
};

export const validatePassword = (password: string): ValidationResult => {
  if (!password || !password.trim()) {
    return invalid(
      `Password must be at least ${MIN_PASSWORD_LENGTH} characters and not blank`
    );
  }

  return password.length >= MIN_PASSWORD_LENGTH
    ? valid()
    : invalid(`Password must be at least ${MIN_PASSWORD_LENGTH} characters`);
};

export const validateBillingName = (name: string): ValidationResult => {
  if (!name || !name.trim()) {
    return invalid(`Name cannot be empty`);
  }

  return valid();
};

export const validateApiKeyName = (name: string): ValidationResult => {
  if (!name || !name.trim()) {
    return invalid(`API Key name cannot be empty`);
  }

  return valid();
};

export const orgNameValidation = {
  required: "Org name cannot be empty",
  pattern: {
    value: /^[a-zA-Z0-9]{1}[a-zA-Z0-9-]*[a-zA-Z0-9]$/,
    message:
      "Please enter at least two characters that contains lowercase letters or numbers. Spaces and symbols are not allowed, other than a hyphen (-) between words",
  },
};

export const defaultEmailValidation = {
  required: "Email cannot be empty",
  pattern: {
    value: EMAIL_REGEX,
    message: "Please enter a correct email",
  },
};

export const defaultPasswordAuthValidation = {
  required: "Password cannot be empty",
};

export const defaultPasswordSignupValidation = {
  required: "Password cannot be empty",
  minLength: {
    value: 8,
    message: "Please enter a password with atleas 8 characters",
  },
};

export const defaultConfirmPasswordValidation = (firstEntry: any) => ({
  required: "You need to confirm password",
  validate: (value: string) =>
    value === firstEntry || "The passwords do not match",
});

export const bilingNameValidation = {
  required: "Billing name cannot be empty",
};
