import React from "react";
import * as Icons from "assets/icons";
import { colors } from "components/App/theme";
import { IconsType } from "types/main";
import { Container, IconContainer } from "./action-on-hover-container.styled";

export type OnHoverAction = {
  key: string;
  icon: IconsType;
  onClick: () => void;
  allow?: boolean;
  color?: string;
  "data-testid"?: string;
};

type ActionOnHoverContainerProps = {
  children: JSX.Element;
  actions: Array<OnHoverAction>;
};

export function ActionOnHoverContainer(props: ActionOnHoverContainerProps) {
  const { children, actions } = props;

  return (
    <Container $items={actions.length}>
      <>
        {children}
        {actions.map((action, i) => {
          const {
            key,
            icon,
            onClick,
            allow,
            color,
            "data-testid": dataTestId,
          } = action;

          // eslint-disable-next-line import/namespace
          const Icon = Icons[icon];
          const allowAction = allow === undefined ? true : allow;
          const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
            // Prevent event bubble to prevent step from getting selected.
            e.stopPropagation();
            onClick();
          };

          return (
            <IconContainer
              key={key}
              $position={i}
              className="hidable"
              data-testid={dataTestId}
              onClick={handleClick}
            >
              <Icon
                style={{
                  color: color || colors.red,
                  cursor: allowAction ? "pointer" : "not-allowed",
                }}
              />
            </IconContainer>
          );
        })}
      </>
    </Container>
  );
}
