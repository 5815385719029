import { DeepPartial, JsonHuman } from "./json-types";

const cantFindEnvironmentObjectMessage = "can't find 'environment' object";
const cantFindAccessoriesObjectMessage = "can't find 'accessories' object";

// returns undefined if there is an error
function safe<U>(
  callback: (...args: any[]) => U
): (...args: any[]) => U | undefined {
  return function (...args: any[]): U | undefined {
    try {
      return callback(...args);
    } catch (error) {
      return undefined;
    }
  };
}

export const getHumanObject = (
  humansArr: DeepPartial<JsonHuman>[],
  i: number
) => {
  if (!humansArr || !humansArr.length) {
    throw Error("can't find 'humans' array");
  }
  const humanObject = humansArr[i];
  if (!humanObject) {
    throw Error("can't find 'human' object");
  }
  return humanObject;
};

export const getHumanObjectPeacefully = (
  humansArr: DeepPartial<JsonHuman>[],
  i: number
) => safe(getHumanObject)(humansArr, i);

export const getIds = (humanObject: DeepPartial<JsonHuman>) => {
  if (!humanObject.identities) {
    return [] as number[];
  }
  return humanObject.identities.ids as number[];
};

export const getIdentities = (humanObject: DeepPartial<JsonHuman>) => {
  return humanObject.identities;
};

export const getHdri = (humanObject: DeepPartial<JsonHuman>) => {
  const env = humanObject.environment;
  if (!env) {
    throw Error(cantFindEnvironmentObjectMessage);
  }
  const hdriInJson = env.hdri;
  if (!hdriInJson) {
    throw Error("can't find 'hdir' object");
  }
  return hdriInJson;
};

export const getHdriPeacefully = (humanObject: DeepPartial<JsonHuman>) =>
  safe(getHdri)(humanObject);

export const getExpression = (humanObject: DeepPartial<JsonHuman>) => {
  const env = humanObject.facial_attributes;
  if (!env) {
    throw Error(cantFindEnvironmentObjectMessage);
  }
  const expressionInJson = env.expression;
  if (!expressionInJson) {
    throw Error("can't find 'expression' object");
  }
  return expressionInJson;
};

export const getGaze = (humanObject: DeepPartial<JsonHuman>) => {
  const env = humanObject.facial_attributes;
  if (!env) {
    throw Error(cantFindEnvironmentObjectMessage);
  }
  const gazeInJson = env.gaze;
  if (!gazeInJson) {
    throw Error("can't find 'gaze' object");
  }
  return gazeInJson;
};

export const getEye = (humanObject: DeepPartial<JsonHuman>) => {
  const env = humanObject.facial_attributes;
  if (!env) {
    throw Error(cantFindEnvironmentObjectMessage);
  }
  const eyeInJson = env.eye;
  if (!eyeInJson) {
    throw Error("can't find 'eye' object");
  }
  return eyeInJson;
};

export const getHeadTurn = (humanObject: DeepPartial<JsonHuman>) => {
  const env = humanObject.facial_attributes;
  if (!env) {
    throw Error(cantFindEnvironmentObjectMessage);
  }
  const headTurnInJson = env.head_turn;
  if (!headTurnInJson) {
    throw Error("can't find 'headTurn' object");
  }
  return headTurnInJson;
};

export const getHair = (humanObject: DeepPartial<JsonHuman>) => {
  const env = humanObject.facial_attributes;
  if (!env) {
    throw Error(cantFindEnvironmentObjectMessage);
  }
  const hairInJson = env.hair;
  if (!hairInJson) {
    throw Error("can't find 'hair' object");
  }
  return hairInJson;
};

export const getFacialHair = (humanObject: DeepPartial<JsonHuman>) => {
  const env = humanObject.facial_attributes;
  if (!env) {
    throw Error(cantFindEnvironmentObjectMessage);
  }
  const facialHairInJson = env.facial_hair;
  if (!facialHairInJson) {
    throw Error("can't find 'facialHair' object");
  }
  return facialHairInJson;
};

export const getEyeBrows = (humanObject: DeepPartial<JsonHuman>) => {
  const env = humanObject.facial_attributes;
  if (!env) {
    throw Error(cantFindEnvironmentObjectMessage);
  }
  const eyeBrowsInJson = env.eyebrow;
  if (!eyeBrowsInJson) {
    throw Error("can't find 'eyeBrows' object");
  }
  return eyeBrowsInJson;
};

export const getThreeDimensionalLocations = (
  humanObject: DeepPartial<JsonHuman>
) => {
  const env = humanObject["3d_location"];
  if (!env) {
    throw Error("can't find '3d_location' object");
  }
  return env;
};

export const getBodies = (humanObject: DeepPartial<JsonHuman>) => {
  const b = humanObject.body;
  if (!b) {
    throw Error("can't find 'body' object");
  }
  return b;
};

export const getClothingList = (humanObject: DeepPartial<JsonHuman>) => {
  const b = humanObject.clothing;
  if (!b) {
    throw Error("can't find 'body' object");
  }
  return b;
};

export const getClothingListPeacefully = (
  humanObject: DeepPartial<JsonHuman>
) => safe(getClothingList)(humanObject);

export const getAnimationsList = (humanObject: DeepPartial<JsonHuman>) => {
  const b = humanObject.gesture;
  if (!b) {
    throw Error("can't find 'gesture' object");
  }
  return b;
};

export const getAnimationsListPeacefully = (
  humanObject: DeepPartial<JsonHuman>
) => safe(getAnimationsList)(humanObject);

export const getMasks = (humanObject: DeepPartial<JsonHuman>) => {
  const accessories = humanObject.accessories;
  if (!accessories) {
    throw Error(cantFindAccessoriesObjectMessage);
  }
  const maskInJson = accessories.masks;
  if (!maskInJson) {
    throw Error("can't find 'mask' object");
  }
  return maskInJson;
};

export const getBodiesPeacefully = (humanObject: DeepPartial<JsonHuman>) =>
  safe(getBodies)(humanObject);

export const getHeadphones = (humanObject: DeepPartial<JsonHuman>) => {
  const acc = humanObject.accessories;
  if (!acc) {
    throw Error(cantFindAccessoriesObjectMessage);
  }
  const list = acc.headphones;
  if (!list) {
    throw Error("can't find 'headphones' object");
  }
  return list;
};

export const getHeadwear = (humanObject: DeepPartial<JsonHuman>) => {
  const acc = humanObject.accessories;
  if (!acc) {
    throw Error(cantFindAccessoriesObjectMessage);
  }
  const list = acc.headwear;
  if (!list) {
    throw Error("can't find 'headwear' object");
  }
  return list;
};
export const getGlasses = (humanObject: DeepPartial<JsonHuman>) => {
  const acc = humanObject.accessories;
  if (!acc) {
    throw Error(cantFindAccessoriesObjectMessage);
  }
  const glasses = acc.glasses;
  if (!glasses) {
    throw Error("can't find 'glasses' object");
  }
  return glasses;
};

export const getGlassesPeacefully = (humanObject: DeepPartial<JsonHuman>) => {
  return safe(getGlasses)(humanObject);
};
