import { useMemo, useState } from "react";
import { RestartAlt } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { colors } from "components/App/theme";
import { ResponsiveInput } from "components/Common/ResponsiveInput";
import { InfoDialog } from "components/dialogs/InfoDialog";
import { ProTip } from "components/ProTip";
import { identityImageURL } from "constants/constants";
import { Messages } from "constants/messages";
import { Label } from "features/JobBuilder/rigs.styled";
import { IdentitiesSelectedEmpty } from "./IdentitiesSelectedEmptyState";
import { PreviewIdentities } from "./preview-identities";

/**
 * We disabled the first 24 identities because of a copyright issue.
 */
const MIN_VALID_IDENTITY_ID = 24;

const validateIds = async (ids: number[]) => {
  const invalidIds: number[] = [];

  for (const id of ids) {
    await new Promise<void>((resolve) => {
      if (id <= MIN_VALID_IDENTITY_ID) {
        invalidIds.push(id);
        resolve();
        return;
      }

      // Add the image to the dom - bypasses the need for cors on s3
      const img = document.createElement("img");

      // it worked
      img.onload = () => resolve();

      // 404
      img.onerror = () => {
        invalidIds.push(id);
        resolve();
      };

      // Triggers the image load
      img.src = identityImageURL(id);
    });
  }

  return invalidIds;
};

const parseValue = (value: string, removeInvalid = false) => {
  return value
    .split(",")
    .filter(Boolean)
    .map((v) => parseInt(v, 10))
    .filter((v) => {
      if (removeInvalid) {
        return v > MIN_VALID_IDENTITY_ID;
      }

      return true;
    });
};

export function IdentitesManual({
  onAppendIds,
  closeDialog,
}: {
  onAppendIds: (ids: number[]) => void;
  closeDialog: () => void;
}) {
  const [value, setValue] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [invalidIdentities, setInvalidIdentities] = useState<number[]>([]);

  // check if entered identities are valid

  const handleUpdateIdentitiesSelected = async () => {
    setInProgress(true);
    setInvalidIdentities([]);

    try {
      const ids = parseValue(value);
      const invalidIds = await validateIds(ids);

      if (invalidIds.length > 0) {
        setInvalidIdentities(invalidIds);
        setInProgress(false);
        return;
      }

      onAppendIds(ids);
    } catch (error) {}

    setInProgress(false);
  };

  const handleInputChange = (v: string) => {
    // remove anything that is not a number, a space or a comma
    let newValue = v.replace(/[^0-9, ]/g, "");

    // remove repeated comas and spaces
    newValue = newValue.replace(/[, ]+/g, ", ");

    // remove trailing comma and space
    newValue = newValue.replace(/ $/, "");

    setValue(newValue);
  };

  const parsedValue = useMemo(() => parseValue(value, true), [value]);

  return (
    <>
      <Box component="div" p={2} width="768px">
        {inProgress && (
          <InfoDialog fullScreen={false}>
            <Box
              component="div"
              p={2}
              sx={{
                maxWidth: 280,
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box component="div" mb={2} sx={{ textAlign: "center" }}>
                Your identities added are now being regenerated & updated!
              </Box>
              <Button
                color="warning"
                size="large"
                startIcon={<RestartAlt />}
                sx={{
                  pointerEvents: "none",
                }}
                variant="contained"
              >
                Regenerating
              </Button>
            </Box>
          </InfoDialog>
        )}
        <ProTip label={Messages.identitiesManuallyEnterProTip} />

        <Label>Enter comma separated identity ID request</Label>
        <ResponsiveInput
          placeholder="1, 2, 3, 4..."
          value={value}
          onChange={handleInputChange}
        />
        {invalidIdentities.length > 0 && !inProgress && (
          <Typography color="error" variant="body2">
            Invalid identities: {invalidIdentities.join(", ")}
          </Typography>
        )}

        <PreviewIdentities
          showWhenEmpty
          emptyMessage={
            <IdentitiesSelectedEmpty
              description={
                "Preview of identities added above will be shown here."
              }
              title={"Add identities"}
            />
          }
          selectedPrefabIds={parsedValue}
        />
      </Box>
      <Box
        alignItems="center"
        borderTop={`1px solid ${colors.grey20}`}
        component="div"
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <>
          <Button color="warning" variant="outlined" onClick={closeDialog}>
            Cancel changes
          </Button>
          <Button
            color="warning"
            disabled={!parsedValue.length || inProgress}
            sx={{
              ml: 2,
            }}
            variant="contained"
            onClick={handleUpdateIdentitiesSelected}
          >
            Add {parsedValue.length} Identities
          </Button>
        </>
      </Box>
    </>
  );
}
