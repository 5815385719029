import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import { SelectorWidth } from "./utils";

export const Container = styled(Box)<{ $noSpacing?: boolean }>`
  display: flex;
  width: 100%;

  justify-content: flex-start;
  align-items: flex-start;

  ${({ $noSpacing }) =>
    $noSpacing
      ? css`
          margin: 0;
          padding: 0;
        `
      : css`
          margin: 8px 0;
          padding: 24px 24px 36px;
        `}
`;

export const InfoContainer = styled(Box)`
  display: flex;
  height: 100%;
  min-width: 250px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const ExampleImage = styled.img`
  width: 220px;
  height: 110px;
  object-fit: contain;
  border-radius: 4px;
  margin-top: 40px;
`;

export const SelectorsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 24px;
`;

export const DropdownContainer = styled(Box)`
  display: flex;
  width: ${SelectorWidth};
  padding-bottom: 24px;
`;
