import { ImageGrid } from "components/ImageGrid";
import { Label, Description, HeadingContainer } from "./commonly-used.styled";
import { ContentCard } from "./layout";
export interface CommonlyUsedItem<T> {
  src: string;
  json: T;
  label: string;
}

export function CommonlyUsed<T>({
  title,
  description,
  items,
  onPick,
}: {
  title: string;
  description?: string;
  items: CommonlyUsedItem<T>[];
  onPick: (json: T) => void;
}) {
  return (
    <ContentCard style={{ marginBottom: "16px" }}>
      <HeadingContainer>
        <Label>{title}</Label>
        {description && <Description>{description}</Description>}
      </HeadingContainer>
      <ImageGrid
        images={items as any}
        onSelect={(i) => onPick(items[i].json)}
      />
    </ContentCard>
  );
}
