import { UserProfile } from "domain/UserProfile";
import { SliceType } from "store/_boundStore";
import { createActions } from "./actions";

export * from "./selectors";

export type AppLocation = { pathname: string; search: string };

type State = {
  data?: UserProfile;
  redirectLocationAfterLogin?: AppLocation;
  error: string;
  loading: boolean;
};

type Actions = {
  getUserProfile: () => void;
  setLoginRedirect: (l: AppLocation) => void;
  clearState: () => void;
};

const initialState: State = {
  data: undefined,
  redirectLocationAfterLogin: undefined,
  error: "",
  loading: false,
};

export type ProfileSlice = State & Actions;

export const createProfileSlice: SliceType<ProfileSlice> = (set, get) => ({
  ...initialState,
  ...createActions(set, get, initialState),
});
