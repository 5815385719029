import { useEffect } from "react";
import { CameraControls as CameraControlsDrei } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import CameraControls from "camera-controls";
import { useCursor2 } from "features/JobBuilder2/steps/threeDimensional/utils/useCursor2";
import { useBoundStore } from "store/_boundStore";

export function CustomControls({ defaultZoom }: { defaultZoom: number }) {
  const canvas = useThree((state) => state.gl.domElement) as HTMLElement;
  const [dragging, controlMode, setDragging] = useBoundStore((s) => [
    s.userControls.dragging,
    s.userControls.controlMode,
    s.userControls.setDragging,
  ]);

  const isMoveControlMode = controlMode === "move";

  useCursor2(isMoveControlMode, dragging ? "grabbing" : "grab");
  // set the cursor to "crosshair" when in "draw" mode
  useCursor2(!isMoveControlMode, "crosshair");

  // ???
  useEffect(() => {
    const lastTouchAction = canvas.style.touchAction;
    canvas.style.touchAction = "none";

    return () => {
      if (!lastTouchAction) {
        canvas.style.removeProperty("touchAction");
      } else {
        canvas.style.touchAction = lastTouchAction;
      }
    };
  }, [canvas]);

  return (
    <CameraControlsDrei
      dollyToCursor
      maxZoom={defaultZoom * 8}
      // follow pointer when zooming
      minZoom={defaultZoom / 4}
      mouseButtons={{
        left: isMoveControlMode
          ? CameraControls.ACTION.TRUCK
          : CameraControls.ACTION.NONE,
        right: CameraControls.ACTION.TRUCK,
        middle: CameraControls.ACTION.ZOOM,
        wheel: CameraControls.ACTION.ZOOM,
      }}
      onChange={() => setDragging(true)}
      onEnd={() => setDragging(false)}
    />
  );
}
