import Check from "@mui/icons-material/Check";
import {
  Container,
  Description,
  Image,
  Title,
  BottomInfoContainer,
  CheckmarkContainer,
} from "./WizardOptionCard.styled";

type WizardOptionCardProps = {
  title: string;
  description: string;
  leftFooter?: JSX.Element;
  rightFooter?: JSX.Element;
  imageSrc: string;
  onClick: () => void;
  isSelected?: boolean;
  hideThumbnails?: boolean;
};

export function WizardOptionCard(props: WizardOptionCardProps) {
  const {
    title,
    description,
    leftFooter,
    rightFooter,
    imageSrc,
    onClick,
    isSelected,
    hideThumbnails,
  } = props;

  return (
    <Container
      $hideImages={!!hideThumbnails}
      $selected={isSelected}
      data-testid="Wizard-Option-Container"
      onClick={onClick}
    >
      {!hideThumbnails && <Image alt={title} src={imageSrc} />}
      <Title>{title}</Title>

      <Description>{description}</Description>
      <BottomInfoContainer>
        <>
          {!!leftFooter && { leftFooter }}
          {!!rightFooter && { rightFooter }}
        </>
      </BottomInfoContainer>

      {isSelected && (
        <CheckmarkContainer>
          <Check fontSize="inherit" />
        </CheckmarkContainer>
      )}
    </Container>
  );
}
