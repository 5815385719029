import React, { MouseEvent } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, InputAdornment } from "@mui/material";

export function SearchInputEndAdornment({
  handleClearClick,
  handleMouseDown,
}: {
  handleClearClick: () => void;
  handleMouseDown: (e: MouseEvent<HTMLButtonElement>) => void;
}) {
  return (
    <InputAdornment position="end">
      <IconButton
        color="warning"
        size="large"
        onClick={handleClearClick}
        onMouseDown={handleMouseDown}
      >
        <ClearIcon fontSize="small" />
      </IconButton>
    </InputAdornment>
  );
}
