import { Box, Checkbox, FormControlLabel } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const Container = styled(Box)`
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    font-size: 14px;
    color: ${colors.grey70};
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  transition: 0.4s;

  & .MuiSvgIcon-root {
    font-size: 22px;
  }

  &.MuiCheckbox-root {
    color: ${colors.grey50};
  }

  &.Mui-checked {
    color: ${colors.orange};
  }
`;
