import { Box, TextField, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";
import { SelectorWidth } from "components/SelectorsPicker/utils";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ListSelectorContainer = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

export const ListInput = styled(TextField).attrs((props) => ({
  FormHelperTextProps: {
    sx: {
      lineHeight: "1.0",
      overflow: "visible",
      whiteSpace: "nowrap",
      alignSelf: "flex-start",
      margin: "8px 0 0px",
    },
  },
}))`
  display: flex;
  width: ${SelectorWidth};
  min-width: 214px;
  align-items: center;
  margin: 8px 10px 4px 0px;

  input {
    padding: 10px 16px;
    font-size: 14px;
    line-height: 21px;
    min-width: 214px;
  }

  .css-qnpdxo-MuiFormHelperText-root {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const SeparationInfo = styled(Typography)`
  padding-left: 24px;
  font-size: 14px;
  line-height: 21px;
  line-clamp: 1;
  white-space: nowrap;
  color: ${colors.grey70};
  text-align: center;
  align-self: flex-start;
  padding-top: 20px;
`;
