import { IS_SCENARIOS_GA, IS_NEW_HUMANS_GA } from "constants/constants";
import { Org } from "domain/Org";
import { WithPercent } from "features/JobBuilder/utils";
import { labelWithValue } from "services/form-service";
import { IconsType } from "types/main";

// export const example = {
//   "animation_name": "vehicle_asleep_left_01",
//   "source_start_frame": 0,
//   "source_end_frame": 943,
//   "source_frame_rate": 60,
//   "output_frame_length": 472,
//   "pose_frame": 615,
//   "car_ready": true,
//   "animation_ready": true,
//   "location_tags": ["vehicle"],
//   "held_item": "none"
// }
export type Gesture = {
  name: string;
  start_frame: number;
  end_frame: number;
  source_fps: number;
  car_ready: boolean;
  animation_ready: boolean;
  output_frame_length: number;
};

export type DetailedEnums = {
  "expression.name": string[];
  "eyes.iris_color": string[];
  "eyebrows.style": string[];
  "eyebrows.color": string[];
  "hair.style": string[];
  "facial_hair.style": string[];
  "hair.color": string[];
  "glasses.style": string[];
  "glasses.color": string[];
  "mask.style": string[];
  "mask.position": string[];
  "mask.variant": string[];
  "headwear.style": string[];
  "headphones.style": string[];
  "clothing.outfit": string[];
  internal_garments: string[];
  "body.height": string[];
  "body.fat": string[];
  "agent.style": string[];
  "location.id": string[];
  gestures: string[];
  animated_gestures: string[];
  open_gestures: string[];
  vehicle_gestures: string[];
  house_external_gestures: string[];
  interior_gestures: string[];
  indoor_hdris: string[];
  outdoor_hdris: string[];
};

export const sexOptions = ["male", "female"];
export const ethnicityOptions = ["asian", "black", "hisp", "indian", "white"];

export const ageOptions = ["0-24", "25-34", "35-44", "45-54", "55-"];
export const lightTypeOptions = ["rect", "spot", "omni", "direct", "disc"];
export const wavelengthOptions = ["visible", "nir"];
export const facialHairs = [
  "beard_ducktail_01",
  "beard_dutch_01",
  "beard_klingon_01",
  "full_ducktail_01",
  "full_garibaldi_01",
  "full_generic_01",
  "full_generic_short_01",
  "full_stubble_01",
  "mustache_english_01",
  "mustache_painters_01",
  "mustache_walrus_01",
];
export const facialHairColors = [
  "beeline_honey",
  "blonde_graying",
  "brown_graying",
  "chocolate_brown",
  "chocolate_cherry",
  "copper_shimmer",
  "dark_brown",
  "dark_golden_brown",
  "darkest_brown",
  "gray",
  "leather_black",
  "light_ash_blonde",
  "light_brown",
  "medium_champagne",
  "pure_diamond",
  "red_graying",
  "reddish_blonde",
  "ruby_fusion",
  "salt_and_pepper",
  "white_gray",
];
export const allExpressions = [
  "aa_02",
  "ae_ax_ah_01",
  "angry",
  "ao_03",
  "aw_09",
  "ay_11",
  "cheeks_contracted_left",
  "cheeks_contracted_right",
  "cheeks_inhaled_left",
  "cheeks_inhaled_right",
  "cheeks_puffed",
  "cheeks_puffed_left",
  "cheeks_puffed_right",
  "cheeks_raised_left",
  "cheeks_raised_right",
  "chew",
  "chew_left",
  "chew_right",
  "chin_raised",
  "circumspect",
  "compressed",
  "d_t_n_19",
  "disgusted",
  "er_05",
  "ey_eh_uh_04",
  "eye_closed_left",
  "eye_closed_max_left",
  "eye_closed_max_right",
  "eye_closed_right",
  "eyebrows_frown",
  "eyebrows_lower",
  "eyebrows_raised",
  "eyes_closed",
  "eyes_closed_max",
  "eyes_opened_max_left",
  "eyes_opened_max_right",
  "eyes_squint",
  "f_v_18",
  "frown",
  "frown_left",
  "frown_right",
  "h_12",
  "happy",
  "jaw_back",
  "jaw_dn_upper",
  "jaw_lower",
  "jaw_out",
  "k_g_ng_20",
  "kiss_left",
  "kiss_right",
  "l_14",
  "lips_closed_blown",
  "lips_dimple_left",
  "lips_dimple_right",
  "lips_dn_bite_left",
  "lips_dn_bite_right",
  "lips_dn_corner_tight_left",
  "lips_dn_corner_tight_right",
  "lips_dn_corner_wide_left",
  "lips_dn_corner_wide_right",
  "lips_dn_funnel_left",
  "lips_dn_funnel_right",
  "lips_dn_in_left",
  "lips_dn_in_right",
  "lips_dn_lower_left",
  "lips_dn_lower_m",
  "lips_dn_lower_right",
  "lips_dn_out_left",
  "lips_dn_out_right",
  "lips_dn_raised_left",
  "lips_dn_raised_right",
  "lips_dn_tighten_left",
  "lips_dn_tighten_right",
  "lips_inner",
  "lips_push_dn",
  "lips_push_up",
  "lips_up_bite_left",
  "lips_up_bite_right",
  "lips_up_corner_tight_left",
  "lips_up_corner_tight_right",
  "lips_up_corner_wide_left",
  "lips_up_corner_wide_right",
  "lips_up_funnel_left",
  "lips_up_funnel_right",
  "lips_up_in_left",
  "lips_up_in_right",
  "lips_up_lower_left",
  "lips_up_lower_right",
  "lips_up_out_left",
  "lips_up_out_right",
  "lips_up_raised_left",
  "lips_up_raised_m",
  "lips_up_raised_right",
  "lips_up_tighten_left",
  "lips_up_tighten_right",
  "look_dwn_left",
  "look_dwn_right",
  "look_in_left",
  "look_in_right",
  "look_out_left",
  "look_out_right",
  "look_up_left",
  "look_up_right",
  "lower_eyelid_dn_left",
  "lower_eyelid_dn_right",
  "lower_eyelid_squint_left",
  "lower_eyelid_squint_right",
  "lower_eyelid_up_left",
  "lower_eyelid_up_right",
  "mouth_closed_corner_lower_left",
  "mouth_closed_corner_lower_right",
  "mouth_closed_jaw_move_left",
  "mouth_closed_jaw_move_right",
  "mouth_closed_move_left",
  "mouth_closed_move_right",
  "mouth_large_opened",
  "mouth_little_opened",
  "mouth_wide_opened",
  "neck_contracted",
  "neck_sternomastoid_contracted",
  "nostrils_compressed_left",
  "nostrils_compressed_right",
  "nostrils_corners_raise_global_left",
  "nostrils_corners_raise_global_right",
  "nostrils_corners_raise_left",
  "nostrils_corners_raise_right",
  "nostrils_dilated_left",
  "nostrils_dilated_right",
  "nostrils_lower_left",
  "nostrils_lower_right",
  "nostrils_raised_global_left",
  "nostrils_raised_global_right",
  "nostrils_sneer_left",
  "nostrils_sneer_right",
  "oops",
  "opened_closedteeth_left",
  "opened_closedteeth_right",
  "opened_global_left",
  "opened_global_right",
  "ow_08",
  "oy_10",
  "p_b_m_21",
  "r_13",
  "revulsion",
  "s_z_15",
  "sad",
  "scared",
  "see_a_spider",
  "sh_ch_jh_zh_16",
  "smile_lips_closed",
  "th_dh_17",
  "thinking",
  "uncompressed",
  "upper_eyelid_dn_left",
  "upper_eyelid_dn_right",
  "upper_eyelid_up_left",
  "upper_eyelid_up_right",
  "w_uw_07",
  "y_iy_ih_ix_06",
];

export const allHairs = [
  "afro_01",
  "afro_02",
  "afro_03",
  "afro_04",
  "afro_05",
  "afro_06",
  "afro_07",
  "afro_08",
  "afro_09",
  "afro_10",
  "afro_11",
  "afro_12",
  "afro_13",
  "bob_01",
  "bob_02",
  "bob_03",
  "bob_04",
  "bob_05",
  "bob_06",
  "bob_07",
  "bob_08",
  "bob_09",
  "bob_10",
  "bob_11",
  "bob_12",
  "bob_13",
  "bob_14",
  "bob_15",
  "bob_16",
  "bob_17",
  "bob_18",
  "bob_19",
  "bob_20",
  "bob_21",
  "bob_22",
  "bob_23",
  "bob_24",
  "bob_25",
  "bob_26",
  "bob_27",
  "bob_28",
  "buzzcut_01",
  "buzzcut_02",
  "convertible_01",
  "curtain_01",
  "curtain_02",
  "curtain_03",
  "curtain_04",
  "curtain_05",
  "curtain_06",
  "curtain_07",
  "curtain_08",
  "curtain_09",
  "frenchcrop_01",
  "frenchcrop_02",
  "frenchcrop_03",
  "layered_01",
  "layered_02",
  "layered_03",
  "layered_04",
  "layered_05",
  "layered_06",
  "long_wavy_01",
  "long_wavy_02",
  "long_wavy_03",
  "long_wavy_04",
  "long_wavy_05",
  "messy_01",
  "mohawk_01",
  "mohawk_02",
  "pompadour_01",
  "pompadour_02",
  "quiff_01",
  "quiff_02",
  "short_wavy_01",
  "short_wavy_02",
  "short_wavy_03",
  "short_wavy_04",
  "short_wavy_05",
  "simple_01",
  "simple_02",
  "slicked_01",
];

export type SelectionType = "list" | "range" | "normal_distribution";
export type LoRType = {
  listValues: string;
  max: string;
  min: string;
  mean?: string;
  std_dev?: string;
  type: SelectionType;
};

export const lorWithTitleUI = (
  title: string,
  value: LoRType,
  limits: { max: string; min: string },
  updateFn: (l: LoRType) => void,
  unitLabel: string,
  icon?: IconsType,
  imgSrc?: string
) => ({
  title,
  icon,
  imgSrc,
  value,
  limits,
  updateFn,
  unitLabel,
});

export const rigOptions = [
  labelWithValue("Head Orbit", "head_orbit"),
  labelWithValue("Head Orbit With Tracking", "head_orbit_with_tracking"),
  labelWithValue("Head Orbit in Car", "head_orbit_in_car"),
  labelWithValue("Preset Location", "preset_location"),
  labelWithValue("Manual", "manual"),
];

export type LabelWithValue = {
  label: string;
  value: string;
};
export const newLoR = (
  min: number,
  max: number,
  listValues: number,
  LoRType?: SelectionType
): LoRType => ({
  type: LoRType || "list",
  min: min.toString(),
  max: max.toString(),
  listValues: listValues.toString(),
  mean: (min + max / 2).toString(),
  std_dev: ((max - min) / 4).toString(),
});
export const defaultDemographicSelection = {
  percent: "100",
  sex: [] as string[],
  age: [] as string[],
  ethnicity: [] as string[],
};

export const defaultGlassesSelection = {
  percent: "100",
  name: [] as string[],
  lensColor: ["default"],
  transparency: newLoR(0, 1, 1),
  metalness: newLoR(0, 1, 0),
  sexMatching: true,
};

export const defaultMaskSelection = {
  percent: "100",
  style: [] as string[],
  position: ["2"],
  variant: ["0"],
};

export const defaultHeadphoneSelection = {
  percent: "100",
  style: [] as string[],
};

export const defaultHeadwearSelection = {
  style: [] as string[],
  sex_matched_only: true,
  percent: "100",
};

export const defaultBodySection = {
  percent: "100",
  fat_content: ["median"],
  height: ["median"],
  enabled: true,
};

export const defaultSkin = {
  highest_resolution: false,
};

export const defaultClothingSection = {
  percent: "100",
  outfit: [] as string[],
  sex_matched_only: true,
  single_style_per_id: false,
};

export const defaultAnimationsSection = {
  percent: "100",
  name: [] as string[],
  keyframe_only: true,
  mirrored: false,
  position_seed: newLoR(0, 10000, 0),
};

export const defaultExpression = {
  percent: "100",
  name: [] as string[],
  intensity: newLoR(0, 1, 0.75),
};

export const defaultGaze = {
  horizontal_angle: newLoR(-30, 30, 0),
  vertical_angle: newLoR(-30, 30, 0),
  percent: "100",
};

export const defaultHeadTurn = {
  pitch: newLoR(-45, 45, 0),
  yaw: newLoR(-45, 45, 0),
  roll: newLoR(-45, 45, 0),
  percent: "100",
};

export const defaultHair = {
  style: [] as string[],
  color: ["dark_golden_brown"],
  relative_length: newLoR(0.5, 1, 0.5),
  relative_density: newLoR(0.5, 1, 0.5),
  color_seed: newLoR(0, 1, 0),
  sex_matched_only: true,
  ethnicity_matched_only: false,
  percent: "100",
};

export const defaultFacialHair = {
  style: [] as string[],
  color: ["dark_golden_brown"],
  match_hair_color: true,
  relative_length: newLoR(0.5, 1, 1),
  relative_density: newLoR(0.5, 1, 1),
  color_seed: newLoR(0, 1, 0),
  percent: "100",
};

export const defaultEyebrow = {
  style: [] as string[],
  color: [] as string[],
  color_seed: newLoR(0, 1, 0),
  relative_length: newLoR(0.5, 1, 1),
  relative_density: newLoR(0.5, 1, 1),
  match_hair_color: true,
  sex_matched_only: true,
  percent: "100",
};

export const defaultEye = {
  iris_color: [] as string[],
  pupil_dilation: newLoR(0, 1, 0),
  redness: newLoR(0, 1, 0),
  percent: "100",
};

export const vehicleRigPresetLocations = [
  labelWithValue("Rear View Mirror", "rearview_mirror"),
  labelWithValue("Overhead Console", "overhead_console"),
  labelWithValue("Dashboard", "dashboard"),
  labelWithValue("Multimedia Console Upper", "multimedia_console_upper"),
  labelWithValue("Multimedia Console Lower", "multimedia_console_lower"),
  labelWithValue("Pillar A", "pillar_a"),
];

export const presetLocationCameraFocalLength = newLoR(1, 300, 12);

export const defaultPresetTransform = {
  u: newLoR(-1, 1, 0),
  v: newLoR(-1, 1, 0),
};

export const defaultLocation = {
  x: newLoR(-1000, 1000, 0),
  y: newLoR(-1000, 1000, 0),
  z: newLoR(-1000, 1000, 0),
  pitch: newLoR(-180, 180, 0),
  yaw: newLoR(-180, 180, 0),
  roll: newLoR(-180, 180, 0),
};

export const defaultCameraAdvanced = {
  noise_threshold: "0.017",
  denoise: true,
  window_offset_horizontal: newLoR(-100, 100, 0),
  window_offset_vertical: newLoR(-100, 100, 0),
  lens_shift_horizontal: newLoR(-100, 100, 0),
  lens_shift_vertical: newLoR(-100, 100, 0),
};

export const defaultCamera = {
  name: "Camera_1",
  resolutionW: "1024",
  resolutionH: "1080",
  wavelength: "visible",
  focalLength: newLoR(1, 300, 100),
  sensorWidth: newLoR(1, 50, 33),
  ...defaultLocation,
  ...defaultCameraAdvanced,
};

export const defaultCameraWithName = (name: string) => {
  return { ...defaultCamera, name };
};

export const defaultLightWithName = (name: string) => {
  return { ...defaultLight, name };
};

export const defaultLight = {
  name: "Light_1",
  type: "rect",
  wavelength: "visible",
  intensity: newLoR(0, 7000, 0),
  sizeMeters: newLoR(0, 3, 0.25),
  ...defaultLocation,
  red: newLoR(0, 255, 255),
  green: newLoR(0, 255, 255),
  blue: newLoR(0, 255, 255),
};

export const defaultRig = {
  type: "head_orbit",
  presetName: "rearview_mirror",
  ...{ ...defaultLocation, z: newLoR(-1000, 1000, 1) },
  ...defaultPresetTransform,
  cameras: [defaultCamera],
  lights: [] as (typeof defaultLight)[],
};

export const defaultHdri = {
  name: ["lauter_waterfall"],
  intensity: newLoR(0, 5, 0.75),
  rotation: { ...newLoR(-180, 180, 0), type: "range" as SelectionType },
};

export const defaultIdentities = {
  rendersPerIdentity: "1",
  ids: [] as number[],
};

export const defaultDemographicBuilder = {
  numberOfIdentities: "1",
  preferFullCount: true,
  selections: [defaultDemographicSelection],
};

export const defaultFacialAttributes = {
  expression: [] as (typeof defaultExpression)[],
  gaze: [] as (typeof defaultGaze)[],
  headTurn: [] as (typeof defaultHeadTurn)[],
  hair: [] as (typeof defaultHair)[],
  facialHair: [] as (typeof defaultFacialHair)[],
  eyebrow: [] as (typeof defaultEyebrow)[],
  eye: [] as (typeof defaultEye)[],
};

export const defaultAccessories = {
  glasses: [] as (typeof defaultGlassesSelection)[],
  masks: [] as (typeof defaultMaskSelection)[],
  headphones: [] as (typeof defaultHeadphoneSelection)[],
  headwear: [] as (typeof defaultHeadwearSelection)[],
};

export enum ThreeDLocationType {
  open = "open",
  vehicle = "vehicle",
}
export const defaultSection = {
  demographicBuilder: defaultDemographicBuilder,
  identities: defaultIdentities,
  hdri: defaultHdri,
  rigs: [defaultRig],
  ...defaultAccessories,
  body: [] as (typeof defaultBodySection)[],
  skin: defaultSkin,
  clothing: [] as (typeof defaultClothingSection)[],
  gesture: [] as (typeof defaultAnimationsSection)[],
  ...defaultFacialAttributes,
  threeDLocation: ThreeDLocationType.open,
};
export type Section = typeof defaultSection;
export type FacialAttributes = typeof defaultFacialAttributes;
export type FacialAttributesPart = keyof FacialAttributes;
export type Accessories = typeof defaultAccessories;
export type AccessoriesPart = keyof Accessories;
export type Gaze = typeof defaultGaze;
export type Glasses = typeof defaultGlassesSelection;
export type Mask = typeof defaultMaskSelection;
export type Hair = typeof defaultHair;
export type FacialHair = typeof defaultFacialHair;
export type HeadTurn = typeof defaultHeadTurn;
export type Headwear = typeof defaultHeadwearSelection;
export type Expression = typeof defaultExpression;
export type Headphone = typeof defaultHeadphoneSelection;
export type Body = typeof defaultBodySection;
export type Clothing = typeof defaultClothingSection;
export type Animation = typeof defaultAnimationsSection;
export type Rig = typeof defaultRig;
export type Skin = typeof defaultSkin;
export type Hdri = typeof defaultHdri;

export const identityIDToIndex = (identityID: number) => {
  const lastScannedID = 635;
  if (identityID <= lastScannedID) {
    return identityID - 1;
  }
  return identityID - 1000 + lastScannedID;
};

export const jobBuilder2Enalbed = (o: Org) =>
  o.scenarios_wizard_enabled || IS_SCENARIOS_GA;
export const jobBuilder1Enabled = (o: Org) =>
  o.humans_wizard_enabled || IS_NEW_HUMANS_GA;

// Pick distribution groups
type SectionDists = Pick<
  Section,
  {
    [K in keyof Section]: Section[K] extends WithPercent[] ? K : never;
  }[keyof Section]
>;
export type DistKey = keyof SectionDists;
export const isDistKey = (k: string): k is DistKey => {
  const keys = Object.keys(distDefaults);
  return keys.includes(k);
};
export const distDefaults: { [k in DistKey]: SectionDists[k][0] } = {
  body: defaultBodySection,
  gesture: defaultAnimationsSection,
  clothing: defaultClothingSection,
  gaze: defaultGaze,
  headTurn: defaultHeadTurn,
  expression: defaultExpression,
  facialHair: defaultFacialHair,
  eye: defaultEye,
  hair: defaultHair,
  glasses: defaultGlassesSelection,
  headphones: defaultHeadphoneSelection,
  headwear: defaultHeadwearSelection,
  masks: defaultMaskSelection,
  eyebrow: defaultEyebrow,
};

export type AbstractCategory = "facialAttributes" | "accessories";
const abstractCategoryToObj: { [k in AbstractCategory]: Partial<Section> } = {
  facialAttributes: defaultFacialAttributes,
  accessories: defaultAccessories,
};

export const childKeys = (k: AbstractCategory) =>
  Object.keys(abstractCategoryToObj[k]);

export const isChild = (category: AbstractCategory, childKey: keyof Section) =>
  childKeys(category).includes(childKey);

export const isAccessoryKey = (k: keyof Section): k is AccessoriesPart =>
  isChild("accessories", k);

export const isFacialKey = (k: keyof Section): k is FacialAttributesPart =>
  isChild("facialAttributes", k);

export const countOfInner = (k: AbstractCategory, section: Section) => {
  const keys = childKeys(k);
  return keys.reduce((acc, k) => acc + section[k as DistKey].length, 0);
};

export const hasAny = (k: AbstractCategory, section: Section) =>
  countOfInner(k, section) > 0;
export const hasAnyAccessories = (section: Section) =>
  hasAny("accessories", section);
export const hasAnyFacialAttributes = (section: Section) =>
  hasAny("facialAttributes", section);

export const dist = (id: DistKey, label: string, icon: IconsType) => ({
  id,
  label,
  icon,
});
export type Dist = ReturnType<typeof dist>;
export const distContainer = (
  category: AbstractCategory,
  dists: Dist[],
  label: string,
  icon: IconsType
) => ({
  category,
  dists,
  label,
  icon,
});
type DistContainer = ReturnType<typeof distContainer>;
export const topLevelDists = [
  dist("body", "Body", "BodyIcon"),
  dist("clothing", "Clothing", "ClothingIcon"),
  dist("gesture", "Animations", "AnimationsIcon"),
];
export const facialDists = [
  dist("expression", "Expression", "ExpressionIcon"),
  dist("gaze", "Gaze", "GazeIcon"),
  dist("headTurn", "Head Turn", "HeadTurnIcon"),
  dist("hair", "Hair", "HairIcon"),
  dist("facialHair", "Facial Hair", "FacialHairIcon"),
  dist("eyebrow", "Eyebrows", "EyebrowsIcon"),
  dist("eye", "Eye", "EyeIcon"),
];
export const accessoryDists = [
  dist("glasses", "Glasses", "GlassesIcon"),
  dist("masks", "Mask", "MasksIcon"),
  dist("headphones", "Headphone", "HeadphonesIcon"),
  dist("headwear", "Headwear", "CapIcon"),
];

export type DistList = (Dist | DistContainer)[];
export const leftSidebarDists: DistList = [
  distContainer(
    "facialAttributes",
    facialDists,
    "Facial Attributes",
    "FacialAttributesIcon"
  ),
  distContainer(
    "accessories",
    accessoryDists,
    "Accessories",
    "AccessoriesIcon"
  ),
  ...topLevelDists,
];

export const afterDist = (id: DistKey): DistKey | undefined => {
  let found = false;
  for (const item of leftSidebarDists) {
    if (found) {
      return "dists" in item ? item.dists[0].id : item.id;
    }

    if ("id" in item && item.id === id) {
      // If found, return the next dist
      found = true;
      continue;
    }

    if ("dists" in item) {
      // Look into nested dists
      for (const dist of item.dists) {
        if (dist.id === id) {
          found = true;
          continue;
        }
        if (found) {
          return dist.id;
        }
      }
    }
  }
};

export const beforeDist = (id: DistKey): DistKey | undefined => {
  const reverseArr = [...leftSidebarDists].reverse();

  let found = false;
  for (const item of reverseArr) {
    if (found) {
      return "dists" in item ? item.dists[0].id : item.id;
    }

    if ("id" in item && item.id === id) {
      // If found, return the next dist
      found = true;
      continue;
    }

    if ("dists" in item) {
      // Look into nested dists
      const reverseDists = [...item.dists].reverse();
      for (const dist of reverseDists) {
        if (dist.id === id) {
          found = true;
          continue;
        }
        if (found) {
          return dist.id;
        }
      }
    }
  }
};
