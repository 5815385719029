import { CameraPresetLocation } from "features/JobBuilder2/steps/threeDimensional/CameraPresetLocation";
import { selectPickedSubjob } from "features/JobBuilder2/store";
import { useBoundStore } from "store/_boundStore";
import { selectPL2Location } from "store/selectors/selectPL2Location";

export function ReadOnlyPickedCameraPresetLocation() {
  const [pickedSubjob, location] = useBoundStore((s) => [
    selectPickedSubjob(s),
    selectPL2Location(s),
  ]);
  return (
    <>
      {location.camera_preset_locations
        .filter(
          (presetLocation, index) => index === pickedSubjob.rigLocationIndex
        )
        .map((presetLocation, index) => {
          return (
            <CameraPresetLocation
              key={index}
              index={pickedSubjob.rigLocationIndex}
              interactive={false}
              preset={presetLocation}
            />
          );
        })}
    </>
  );
}
