import React, { useState } from "react";
import { Container } from "@mui/system";
import { Button } from "./buttons/Button";
import { Link } from "./buttons/Link";
import { Error } from "./forms/Error";
import { Input } from "./forms/Input";
import { Label } from "./forms/Label";
import { Select } from "./forms/Select";

export function Showcase() {
  const [inputValue, setInputValue] = useState("");
  return (
    <Container sx={{ p: 4 }}>
      <div>
        <h1>Design System</h1>
        <p>
          Design System is a collection of reusable components that can be used
          to build a website.
        </p>

        <h2>Forms</h2>
        <h3>Input</h3>
        <Label title="Label">
          <Input
            placeholder="Placeholder..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <Error>{inputValue && "This is an error!"}</Error>
        </Label>
        <Label title="Numbers">
          <Input placeholder="1" type="number" />
        </Label>
        <Label title="Numbers">
          <Input placeholder="1" type="number" />
        </Label>

        <h3>Select</h3>
        <Label title="Select a number">
          <Select
            options={[
              { value: "1", label: "One" },
              { value: "2", label: "Two" },
              { value: "3", label: "Three" },
            ]}
          />
        </Label>

        <h2>Buttons</h2>
        <h3>Button</h3>
        <h4>Primary</h4>
        <Button>Click me</Button>
        <Button disabled>Click me</Button>

        <h4>Secondary</h4>
        <Button level="secondary">Click me</Button>
        <Button disabled level="secondary">
          Click me
        </Button>

        <h3>Button Link</h3>
        <Button level="link">Click me</Button>
        <Button disabled level="link">
          Click me
        </Button>

        <h3>Links</h3>
        <h4>Primary</h4>
        <Link href="#">Click me</Link>

        <h4>Secondary</h4>
        <Link href="#" level="secondary">
          Click me
        </Link>
      </div>
    </Container>
  );
}
