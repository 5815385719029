import { DeepPartial, JsonInput } from "./json-types";

export type RigPart = {
  type: string;
  name: string;
  indexOnDataArray: number;
};

export type ItemLabelVariant = "section" | "subsection";

export type SectionItemLabel = {
  variant: ItemLabelVariant;
  selected: boolean;
};

export type Template = {
  name: string;
  json: DeepPartial<JsonInput>;
};

export type TemplateStr = {
  name: string;
  jsonStr: string;
};

export function isTemplate(obj: unknown): obj is Template {
  return (
    !!obj &&
    (obj as Template).name !== undefined &&
    typeof (obj as Template).name === "string"
  );
}

export type EmptyStateSectionActions = {
  keepDefault: () => void;
  addNew: () => void;
};
