import { useState, ReactNode, useEffect, useMemo, Fragment } from "react";
import { Backdrop, Box } from "@mui/material";
import { ChevronRightIcon, CircledAddIcon, DeleteIcon } from "assets/icons";
import { colors } from "components/App/theme";
import { Button } from "components/design-system/buttons/Button";
import { IndicesPickerDialog } from "components/dialogs/IndicesPickerDialog";
import { TitleWithIcon } from "components/TitleWithIcon/TitleWithIcon";
import { useBoundStore } from "store/_boundStore";
import { IconsType } from "types/main";
import { useBreadcrumbsContext } from "./breadcrumbs";
import { INITIAL_JSON_MANAGER_INTERNAL_VALUE } from "./data";
import {
  Editor,
  JsonEditorHighlighter,
  NumberOfImages,
  PercentageContainer,
  TitleContainer,
} from "./json-manager-new-ui.styled";
import {
  HeaderActionsContainer,
  HeaderContainer,
  Title,
  TitleCount,
} from "./json-manager.styled";
import { PercentageGroup } from "./PercentageGroup";
// Order matters
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-one_dark";

export function JsonManagerNewUI<T>({
  unknownIndicesKeys,
  children,
  commonlyUsedEl,
  title,
  titleIcon,
  titleAddIn,
  titleCount,
  parentTitle,
  parentTitleIcon,
  emptyState,
  id,
  jsonValue,
  groupIndex,
  percentDescription,
  groupPercentage,
  isSummingTo100 = true,
  numberOfImages,
  onIndecesReceived,
  onDoneEditJson,
  onDelete,
  onClickAddButton,
  onUpdatePercent,
  hideActions,
}: {
  unknownIndicesKeys: string[];
  children: ReactNode;
  commonlyUsedEl?: ReactNode;
  title: string;
  titleIcon?: IconsType;
  titleCount?: number;
  titleAddIn?: ReactNode;
  parentTitle?: string;
  parentTitleIcon?: IconsType;
  emptyState?: React.ReactElement | null;
  percentDescription?: string;
  id?: string;
  jsonValue: T;
  groupIndex?: number;
  groupPercentage?: string;
  isSummingTo100?: boolean;
  numberOfImages?: string;
  onIndecesReceived: (n: number[], tempArr: any) => void;
  onDoneEditJson: (json: T) => void;
  onDelete?: () => void;
  onClickAddButton?: () => void;
  onUpdatePercent?: (v: string) => void;
  hideActions?: boolean;
}) {
  const [tempArr, setTempArr] = useState<any[]>([]);
  const { setErrorMessage } = useBoundStore.getState().message;
  const { setOpenEditor } = useBoundStore.getState().jobBuilder;
  const isOpenEditor = useBoundStore((state) => state.jobBuilder.openEditor);

  const [internalValue, setInternalValue] = useState(
    INITIAL_JSON_MANAGER_INTERNAL_VALUE
  );

  useEffect(
    () => () => {
      const anyInstanceLeft = document.querySelectorAll(
        ".json-manager-new-ui"
      ).length;
      if (!anyInstanceLeft) {
        setOpenEditor(false);
      }
    },
    [setOpenEditor]
  );

  const onClose = () => {
    setInternalValue(INITIAL_JSON_MANAGER_INTERNAL_VALUE);
    setOpenEditor(false);
  };

  const onFinish = () => {
    try {
      const json = JSON.parse(internalValue);
      setInternalValue(INITIAL_JSON_MANAGER_INTERNAL_VALUE);
      setOpenEditor(false);
      onDoneEditJson(json);
    } catch (error) {
      setErrorMessage("Not a valid json");
    }
  };

  const onConfirmTempArrIndices = (indices: string[]) => {
    setTempArr([]);
    const numbers = indices.map(Number);
    onIndecesReceived(numbers, tempArr);
  };

  const showChildren = internalValue === INITIAL_JSON_MANAGER_INTERNAL_VALUE;
  const showJSONEditor = !showChildren;

  const { selectedSubjobIndex, numberOfImageForSelectedSection } =
    useBreadcrumbsContext();

  const breadcrumbs = useMemo(() => {
    const crumbs = [
      {
        title: `Sub-Job ${selectedSubjobIndex + 1}`,
        icon: "SubJobIcon" as IconsType,
        count: `${numberOfImageForSelectedSection} images`,
      },
      {
        title: parentTitle,
        icon: parentTitleIcon as IconsType,
        count: null,
      },
      {
        title,
        icon: titleIcon as IconsType,
        count: typeof titleCount === "number" ? titleCount : null,
      },
    ]
      .map((item) => {
        if (!item.title) return null;

        return item.icon ? (
          <TitleWithIcon
            key={item.title}
            fitContentWidth
            icon={item.icon}
            iconColor={colors.grey70}
            label={item.title}
            leftSideElement={
              item.count !== null ? (
                <TitleCount>({item.count})</TitleCount>
              ) : null
            }
          />
        ) : (
          <Title key={item.title}>{title}</Title>
        );
      })
      .filter(Boolean);

    return crumbs.map((crumb, index) => {
      if (index === crumbs.length - 1)
        return <Fragment key={crumb?.key ?? undefined}>{crumb}</Fragment>;
      return (
        <Fragment key={crumb?.key ?? undefined}>
          {crumb}
          <ChevronRightIcon
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              margin: "0 4px",
            }}
          />
        </Fragment>
      );
    });
  }, [
    numberOfImageForSelectedSection,
    parentTitle,
    parentTitleIcon,
    selectedSubjobIndex,
    title,
    titleCount,
    titleIcon,
  ]);

  if (emptyState) {
    return (
      <>
        <Box component="div" height="58px">
          <TitleContainer>{breadcrumbs}</TitleContainer>
        </Box>
        {emptyState}
      </>
    );
  }

  return (
    <>
      <Backdrop
        open={isOpenEditor}
        style={{ zIndex: 90 }}
        onClick={() => onFinish()}
      ></Backdrop>
      <Box className="json-manager-new-ui" component="div" id={id}>
        <Box component="div" height="58px">
          <TitleContainer>{breadcrumbs}</TitleContainer>
        </Box>
        {commonlyUsedEl}

        <JsonEditorHighlighter $isActive={isOpenEditor} component="div">
          <HeaderContainer>
            <TitleContainer>
              {breadcrumbs[breadcrumbs.length - 1]}

              {titleAddIn}
            </TitleContainer>

            {!hideActions && !emptyState && (
              <HeaderActionsContainer>
                {!!tempArr.length && (
                  <IndicesPickerDialog
                    arr={unknownIndicesKeys}
                    onConfirm={onConfirmTempArrIndices}
                  />
                )}
                {groupIndex && onUpdatePercent && (
                  <PercentageContainer>
                    <PercentageGroup
                      descriptionText={percentDescription}
                      isSummingTo100={isSummingTo100}
                      percentValue={groupPercentage ?? ""}
                      title={`Group ${groupIndex}`}
                      onUpdatePercentage={onUpdatePercent}
                    />
                    {numberOfImages ? (
                      <NumberOfImages>
                        of {numberOfImages} images
                      </NumberOfImages>
                    ) : null}
                  </PercentageContainer>
                )}

                {showJSONEditor ? (
                  <div style={{ display: "flex" }}>
                    <Button
                      level="secondary"
                      size="small"
                      style={{ marginRight: 8 }}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      style={{ marginRight: 18 }}
                      onClick={onFinish}
                    >
                      Save
                    </Button>
                  </div>
                ) : (
                  <Button
                    level="secondary"
                    size="small"
                    onClick={() => {
                      setInternalValue(JSON.stringify(jsonValue, null, 2));
                      setOpenEditor(true);
                    }}
                  >
                    Edit JSON
                  </Button>
                )}

                {onDelete && (
                  <DeleteIcon
                    style={{
                      cursor: "pointer",
                      color: colors.red,
                      marginLeft: "8.5px",
                    }}
                    onClick={onDelete}
                  />
                )}
                {onClickAddButton && (
                  <CircledAddIcon
                    style={{ cursor: "pointer", marginLeft: "8.5px" }}
                    onClick={onClickAddButton}
                  />
                )}
              </HeaderActionsContainer>
            )}
          </HeaderContainer>
          {showJSONEditor && (
            <>
              <Editor
                height="80vh"
                mode="json"
                theme="one_dark"
                value={internalValue}
                width="100%"
                onChange={setInternalValue}
              />
            </>
          )}

          <Box
            aria-hidden={showChildren ? "false" : "true"}
            component="div"
            display={showChildren ? "block" : "none"}
          >
            {children}
          </Box>
        </JsonEditorHighlighter>
      </Box>
    </>
  );
}
