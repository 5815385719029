import {
  HelpOverlay,
  HelpMessage,
} from "features/JobBuilder2/help-overlay/help-overlay";
import { defaultSubjob } from "features/JobBuilder2/types";

export function AssignCharacterGroupsCore({
  threeDElement,
  activityZones,
}: {
  threeDElement: JSX.Element;
  activityZones: typeof defaultSubjob.activityZones;
}) {
  return (
    <HelpOverlay
      helpMessage={
        <HelpMessage>
          Click on each activity zone to assign <br /> groups of characters to
          them.
        </HelpMessage>
      }
      startOpen={activityZones.every((zone) => {
        return zone.prefabGroupId === "";
      })}
    >
      {threeDElement}
    </HelpOverlay>
  );
}
