import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { ReactComponent as AssignActivitiesIllustration } from "features/JobBuilder2/steps/define-activity-zone/assets/assign-activities.svg";
import { ReactComponent as AssignCharactersIllustration } from "features/JobBuilder2/steps/define-activity-zone/assets/assign-characters.svg";
import { ReactComponent as DrawAreaIllustration } from "features/JobBuilder2/steps/define-activity-zone/assets/draw-area.svg";
import { BoxedNumber } from "./BoxedNumber";

export function Overview() {
  useEffect(() => {
    localStorage.setItem("__tutorial__activity_zones_seen", "true");
  }, []);

  return (
    <Box component="div">
      <Typography variant="body1">
        The next set of steps build the humans doing activities, in different
        places in the scene, during each simulation.
      </Typography>

      <Box
        component="div"
        display="flex"
        flexDirection="row"
        mt={4}
        sx={{ textAlign: "center" }}
      >
        <Box component="div" flex={1} mr={4}>
          <Box component="div" mb={2}>
            <BoxedNumber step={1} />
          </Box>

          <Box component="div" mb={2}>
            Draw geometric activity zones where each person can do something
          </Box>

          <DrawAreaIllustration style={{ position: "relative", right: 12 }} />
        </Box>
        <Box component="div" flex={1} mr={4} pt={18}>
          <Box component="div" mb={2}>
            <BoxedNumber step={2} />
          </Box>

          <Box component="div" mb={2}>
            Assign possible activities per geometric activity zone
          </Box>

          <AssignActivitiesIllustration
            style={{ position: "relative", right: 12 }}
          />
        </Box>
        <Box component="div" flex={1}>
          <Box component="div" mb={2}>
            <BoxedNumber step={3} />
          </Box>

          <Box component="div" mb={2}>
            Assign possible people per geometric activity zone
          </Box>

          <AssignCharactersIllustration
            style={{ position: "relative", right: 12 }}
          />
        </Box>
      </Box>
    </Box>
  );
}
