import { Box } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";
import { SectionItemLabel } from "features/JobBuilder/types";

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 45px;
  padding-right: 8px;
`;

export const Label = styled.p<SectionItemLabel>`
  padding-left: 8px;
  font-weight: ${({ variant }) => (variant === "section" ? 700 : 400)};
  font-size: 14px;
  line-height: 16px;
  color: ${({ selected }) => (selected ? colors.orange : colors.grey70)};
`;
