import { useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "assets/icons";
import { colors } from "components/App/theme";
import {
  Container,
  HideContainer,
  Label,
  HeaderContainer,
  Separator,
  Tag,
} from "./Collapsible.styled";
import { CollapsibleDisplayType } from "./types";

export function Collapsible({
  label,
  children,
  collapsedByDefault = false,
  justifyContent = "center",
  alignItems = "center",
  tag,
}: {
  label: string;
  children: JSX.Element;
  collapsedByDefault?: boolean;
  justifyContent?: React.CSSProperties["justifyContent"];
  alignItems?: React.CSSProperties["alignItems"];
  tag?: string;
}) {
  const [display, setDisplay] = useState<CollapsibleDisplayType>(
    collapsedByDefault ? "none" : "flex"
  );

  const Icon =
    display === "flex" ? (
      <ChevronUpIcon fill={colors.grey70} onClick={() => setDisplay("none")} />
    ) : (
      <ChevronDownIcon
        fill={colors.grey70}
        onClick={() => setDisplay("flex")}
      />
    );

  const [isTagOpened, setIsTagOpened] = useState(
    !!tag && !!sessionStorage.getItem(tag)
  );

  useEffect(() => {
    if (isTagOpened) return;

    if (display === "flex" && tag) {
      setIsTagOpened(true);
      sessionStorage.setItem(tag, "true");
    }
  }, [isTagOpened, display, tag]);

  return (
    <Container>
      <HeaderContainer
        onClick={() =>
          display === "flex" ? setDisplay("none") : setDisplay("flex")
        }
      >
        <Label>{label}</Label>
        <Separator />
        {Icon}
      </HeaderContainer>
      {tag && !isTagOpened && <Tag>{tag}</Tag>}
      <HideContainer
        $alignItems={alignItems}
        $display={display}
        $justifyContent={justifyContent}
      >
        {children}
      </HideContainer>
    </Container>
  );
}
