// types
import { LightModel } from "features/JobBuilder/CamerasAndLightsPreview/assets/LightModel";
import { useRelativeLocation } from "features/JobBuilder/CamerasAndLightsPreview/hooks/useRelativeLocation";
import { JsonLight } from "features/JobBuilder/json-types";

// hooks

// component

interface LightProps {
  data: JsonLight;
}

export function Light({ data }: LightProps) {
  const { position, rotation } = useRelativeLocation(data.relative_location);

  return (
    <group position={position} rotation={rotation}>
      <LightModel />
    </group>
  );
}
