import React, { useMemo } from "react";
import { AddCircle, CheckCircle } from "@mui/icons-material";
import { Button } from "@mui/material";
import { colors } from "components/App/theme";
import { APP_BAR_HEIGHT } from "components/Common/TopAppBar2";
import { PUBLIC_S3_BUCKET } from "constants/constants";
import { useBoundStore } from "store/_boundStore";
import { JOB_FOOTER_HEIGHT } from "./JobBuilderFooter/JobBuilderFooter.styled";
import { ContentCard } from "./layout";

const styles: Record<string, React.CSSProperties> = {
  container: {
    minHeight: `calc(100vh - ${APP_BAR_HEIGHT + JOB_FOOTER_HEIGHT + 80}px)`,
    display: "flex",
    maxWidth: "707px",
    width: "100%",
    margin: "0 auto",
    padding: "72px 0",
  },
  iconContainer: {
    marginRight: "32px",
  },
  iconWrapper: {
    width: "96px",
    height: "96px",
    borderRadius: "50%",
    backgroundColor: colors.tint5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "64px",
    color: colors.bright[2],
  },
  title: {
    padding: 0,
    margin: 0,
    fontWeight: 600,
  },
  description: {
    padding: 0,
    margin: 0,
    fontWeight: 400,
    color: colors.grey70,
    maxWidth: 520,
  },
  sampleResultsTitle: {
    fontWeight: 600,
    marginBottom: "16px",
  },
  samplesContainer: {
    display: "inline-flex",
    position: "relative",
  },
  imageContainer: {
    marginRight: 12,
  },
  image: {
    width: 100,
    height: 100,
  },
  gradient: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    background:
      "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%)",
    width: 100,
  },
  actionsContainer: {
    display: "flex",
    marginTop: "24px",
  },
};

function getImageSrc(scope: string, index: number) {
  return `${PUBLIC_S3_BUCKET}/pl1/fixed/${scope}/${index}.png`;
}

export function EmptyState(props: {
  icon: React.ReactElement;
  title: string;
  description: string;
  folder: string;
}) {
  const [addNew, goToNextPart] = useBoundStore((s) => [
    s.jobBuilder.addNewDistItemForSelectedPart,
    s.jobBuilder.goToNextPart,
  ]);
  const actions = useMemo(
    () => [
      {
        primary: true,
        label: "Keep default settings",
        icon: <CheckCircle />,
        onClick: goToNextPart,
      },
      {
        primary: false,
        label: "Add custom settings",
        icon: <AddCircle />,
        onClick: addNew,
      },
    ],
    [goToNextPart, addNew]
  );
  const { icon, title, description, folder } = props;

  const images = useMemo(() => {
    return Array.from({ length: 5 }, (_, index) => ({
      src: getImageSrc(folder, index),
      alt: `Sample result for ${folder} ${index + 1}`,
    }));
  }, [folder]);

  return (
    <ContentCard>
      <div style={styles.container}>
        <div style={styles.iconContainer}>
          <div style={styles.iconWrapper}>{icon}</div>
        </div>
        <div>
          <h3 style={styles.title}>{title}</h3>
          <p style={styles.description}>{description}</p>
          <div>
            <h4 style={styles.sampleResultsTitle}>Sample Results</h4>
            <div style={styles.samplesContainer}>
              {images.map((image, index) => (
                <div key={image.src} style={styles.imageContainer}>
                  <img alt={image.alt} src={image.src} style={styles.image} />
                </div>
              ))}
              <div style={styles.gradient} />
            </div>
          </div>
          <div style={styles.actionsContainer}>
            {actions.map((action, i) => (
              <div key={`${i}`}>
                <Button
                  color="warning"
                  size="large"
                  startIcon={action.icon}
                  sx={{
                    mr: 2,
                  }}
                  variant={action.primary ? "contained" : "outlined"}
                  onClick={action.onClick}
                >
                  {action.label}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </ContentCard>
  );
}
