import { useGLTF } from "@react-three/drei";
import carModel from "./carModel.glb";

export function CarModel(props) {
  const { nodes } = useGLTF(carModel, true);
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Seats_Retopo.geometry}
        material={nodes.Seats_Retopo.material}
        scale={0.01}
      />
      <mesh
        geometry={nodes.Seal.geometry}
        material={nodes.Seal.material}
        scale={0.01}
      />
      <mesh
        geometry={nodes.Steering_Wheel_Retopo.geometry}
        material={nodes.Steering_Wheel_Retopo.material}
        scale={0.01}
      />
      <mesh
        geometry={nodes.Center_Console_Retopo.geometry}
        material={nodes.Center_Console_Retopo.material}
        scale={0.01}
      />
      <mesh
        geometry={nodes.Dashboard_Retopo.geometry}
        material={nodes.Dashboard_Retopo.material}
        scale={0.01}
      />
      <mesh
        geometry={nodes.Buttons_Retopo.geometry}
        material={nodes.Buttons_Retopo.material}
        scale={0.01}
      />
      <mesh
        geometry={nodes.Bottom_Retopo.geometry}
        material={nodes.Bottom_Retopo.material}
        scale={0.01}
      />
      <mesh
        geometry={nodes["Ford_Escape_(Mk4)_SE_HQinterior_2020125"].geometry}
        material={nodes["Ford_Escape_(Mk4)_SE_HQinterior_2020125"].material}
        scale={0.01}
      />
    </group>
  );
}

useGLTF.preload(carModel);
