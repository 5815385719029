import {
  PreviewTitleContainer,
  RightAsideContainer,
  StyledPreviewTitle,
} from "./RightAsidePreviewFull.styled";
import { RightAsidePreviewFullProps } from "./types";

export function RightAsidePreviewFull({
  previewTitle,
  children,
}: RightAsidePreviewFullProps) {
  return (
    <RightAsideContainer component="div">
      <PreviewTitleContainer>
        <StyledPreviewTitle variant="h2">{previewTitle}</StyledPreviewTitle>
      </PreviewTitleContainer>
      {children}
    </RightAsideContainer>
  );
}
