import { JsonLoR } from "features/JobBuilder/json-types";

// getMeanMinMaxValue returns the Min, Max and Mean for the List or Range provided
// this works (enough) because a list with 1 item only returns the same min,max,mean values
export const getMeanMinMaxValue = (data: JsonLoR | undefined) => {
  if (!data) return { min: 0, max: 0, mean: 0 };
  const { values } = data;
  if (values instanceof Array) {
    return {
      min: values.reduce((a, b) => (a > b ? b : a), values[0]),
      max: values.reduce((a, b) => (a < b ? b : a), values[0]),
      mean: values.reduce((a, b) => a + b, 0) / values.length,
    };
  } else if ("max" in values && "min" in values) {
    const { max, min } = values;
    return { min, max, mean: (max + min) / 2 };
  }
  return { min: 0, max: 0, mean: 0 };
};
