import { ReactNode } from "react";
import { Container, Description } from "./BaseWizardTab.styled";

export function BaseWizardTab({
  HeaderComponent,
  description,
  children,
}: {
  children: ReactNode;
  HeaderComponent: ReactNode;
  description?: string;
}) {
  return (
    <Container>
      {HeaderComponent}
      {description && <Description>{description}</Description>}
      {children}
    </Container>
  );
}
