import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "components/App/theme";

export function TutorialCardHeader({
  title,
  stepNumber,
}: {
  title: string;
  stepNumber: number;
}) {
  return (
    <>
      <Box component="div" fontWeight={700} mb={5}>
        <Typography variant="h2">
          Step&nbsp;
          <Box
            component="div"
            sx={{
              display: "inline-block",
              textAlign: "center",
              width: {
                xs: "28px",
                sm: "32px",
              },
              height: {
                xs: "28px",
                sm: "32px",
              },
              borderRadius: "50%",
              backgroundColor: colors.bright[2],
              color: "white",
              fontWeight: 600,
            }}
          >
            {stepNumber}
          </Box>
        </Typography>
      </Box>
      <Box component="div" height="40px" mb={5}>
        {title}
      </Box>
    </>
  );
}
