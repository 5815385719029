import { Category, Category1 } from "./types";

export const getSelectedCategory = (
  categories: Category[],
  categoryId?: string
) => categories.find((category) => category.id.toString() === categoryId);

export const getSelectedSubcategory = (
  category?: Category,
  subcategoryId?: string
) =>
  (category as Category1)?.attributes.subcategories.data.find(
    (subcategory) => subcategory.id.toString() === subcategoryId
  );

export const getSelectedTemplate = (
  previewCategory: Category,
  templateId?: number
) =>
  (previewCategory as Category1)?.attributes.templates.data.find(
    (template) => template.id.toString() === templateId?.toString()
  );

export const hasItems = (value: any[]) => !!value.length;
