import { useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { colors } from "components/App/theme";
import { ProTip } from "components/ProTip";
import { identityImageURL } from "constants/constants";
import { Messages } from "constants/messages";
import { IdentitiesPrefab } from "features/HumanJob/types";
import { restClient, strapiRestClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { PreviewIdentities } from "./preview-identities";

const getPrefabIds = (prefab?: IdentitiesPrefab) =>
  prefab
    ? [
        prefab.attributes.mainImageId,
        ...prefab.attributes.supplementaryImagesIds,
        ...prefab.attributes.otherIds,
      ]
    : [];

export function IdentitesPrefab({
  prefabClicked,
  closeDialog,
}: {
  prefabClicked: (ids: number[], oldIds: number[]) => void;
  closeDialog: () => void;
}) {
  const { setErrorMessage } = useBoundStore.getState().message;
  const { selectedIdentitiesPrefab, setSelectedIdentitiesPrefab } =
    useBoundStore.getState().jobBuilder;

  const [prefabs, setPrefabs] = useState<IdentitiesPrefab[]>([]);

  const [selectedPrefab, setSelectedPrefab] = useState<IdentitiesPrefab | null>(
    null
  );

  useEffect(() => {
    async function fetchPrefabsAndRequestTheirImages() {
      try {
        const incomingPrefabs = await strapiRestClient.getIdentitiesPrefabs();
        setPrefabs(incomingPrefabs);

        if (typeof selectedIdentitiesPrefab === "number") {
          const selected = incomingPrefabs.find(
            (pf) => pf.id === selectedIdentitiesPrefab
          );

          if (!selected) return;

          setSelectedPrefab(selected);
        } else {
          setSelectedPrefab(incomingPrefabs[0]);
        }
      } catch (error) {
        setErrorMessage(restClient.APIErrorMessage(error));
      }
    }
    fetchPrefabsAndRequestTheirImages();
    // Removing `selectedIdentitiesPrefab` from dependencies because we don't want to re-fetch prefabs when it changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setErrorMessage]);

  const onAdd = () => {
    if (!selectedPrefab) return;

    prefabClicked(
      getPrefabIds(selectedPrefab),
      getPrefabIds(prefabs.find((pf) => pf.id === selectedIdentitiesPrefab))
    );

    setSelectedIdentitiesPrefab(selectedPrefab.id);
  };

  const selectedPrefabIds = useMemo(
    () => (selectedPrefab ? getPrefabIds(selectedPrefab) : []),
    [selectedPrefab]
  );

  return (
    <>
      <Box component="div" p={2} width="768px">
        <ProTip label={Messages.identitiesPrefabProTip} />
        <Box
          component="div"
          sx={{
            overflowX: "auto",
            overflowY: "hidden",
            whiteSpace: "nowrap",
            mt: 2,
          }}
        >
          {prefabs.map((prefab, i) => (
            <Box
              key={i}
              component="div"
              sx={{
                display: "inline-block",
                borderRadius: "4px",
                p: 0.3,
                border: `1px solid ${
                  selectedPrefab?.id === prefab.id
                    ? colors.orange
                    : colors.grey20
                }`,
                background:
                  selectedPrefab?.id === prefab.id
                    ? colors.tint5
                    : colors.white,
                height: "121px",
                width: "103px",
                mr: 1,
                position: "relative",
                cursor: "pointer",
                textAlign: "center",
              }}
              onClick={() => setSelectedPrefab(prefab)}
            >
              <Box
                component="div"
                sx={{
                  position: "absolute",
                  left: 6,
                  top: 6,
                  borderRadius: "180px",
                  padding: "2px 8px 2px 8px",
                  background: `${colors.orange}`,
                  color: "white",
                  fontSize: "12px",
                  lineHeight: "10px",
                  height: "14px",
                }}
              >
                {prefab.attributes.otherIds.length + 5}
              </Box>
              <img
                alt={prefab.attributes.mainImageId.toString()}
                height="70px"
                src={identityImageURL(prefab.attributes.mainImageId)}
                style={{
                  objectFit: "cover",
                  borderRadius: "4px",
                  marginBottom: "2.64px",
                  border: "1px solid #9A9A9B",
                  verticalAlign: "middle",
                }}
                width="95px"
              />
              <Box
                component="div"
                display="grid"
                gap="1px"
                mb={0}
                style={{ marginBottom: "8px" }}
              >
                {prefab.attributes.supplementaryImagesIds.map((id, i) => (
                  <img
                    key={i}
                    alt={id.toString()}
                    height="16"
                    src={identityImageURL(id)}
                    style={{
                      gridRow: 1,
                      marginTop: "0px",
                      display: "inline",
                      objectFit: "cover",
                      borderRadius: "4px",
                    }}
                    width="23"
                  />
                ))}
              </Box>
              <Box
                component={Typography}
                sx={{ fontSize: "10px", width: "95px" }}
                variant="body1"
              >
                {prefab.attributes.description}
              </Box>
            </Box>
          ))}
        </Box>

        <PreviewIdentities selectedPrefabIds={selectedPrefabIds} />
      </Box>
      <Box
        alignItems="center"
        borderTop={`1px solid ${colors.grey20}`}
        component="div"
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <>
          <Button color="warning" variant="outlined" onClick={closeDialog}>
            Cancel changes
          </Button>
          <Button
            color="warning"
            sx={{ ml: 1 }}
            variant="contained"
            onClick={onAdd}
          >
            Add selected prefab group
          </Button>
        </>
      </Box>
    </>
  );
}
