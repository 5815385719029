import { Box, Typography, Button } from "@mui/material";
import { ReactComponent as EmptyStateIllustration } from "./empty-state-illustration.svg";

export function TableContentWhenEmpty({
  onAddJobClick,
}: {
  onAddJobClick: () => void;
}) {
  return (
    <Box
      component="div"
      sx={{
        width: "100%",
        margin: "auto",
        display: "flex",
        mt: 7,
        mb: 7,
        justifyContent: "center",
      }}
    >
      <Box component="div" pt={8} textAlign="center">
        <EmptyStateIllustration />
        <Box component={Typography} variant="h3">
          You currently have no jobs created
        </Box>
        <Box
          component={Typography}
          display="block"
          pb={2}
          pt={1}
          variant="body2"
        >
          To get started creating images, continue below.
        </Box>
        <Button
          color="warning"
          sx={{
            display: "block",
            margin: "auto",
          }}
          variant="contained"
          onClick={onAddJobClick}
        >
          Create new job
        </Button>
      </Box>
    </Box>
  );
}
