//https://github.com/jbetancur/react-data-table-component/blob/master/src/DataTable/styles.js
export const dataTableCustomStyles = {
  headCells: {
    style: {
      fontWeight: 600,
      fontSize: "0.8rem",
    },
  },
  rows: {
    style: {
      fontSize: "0.85rem",
    },
  },
};
