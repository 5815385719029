import { Box } from "@mui/material";
import { TitleWithIcon } from "components/TitleWithIcon/TitleWithIcon";
import { HelpText } from "components/Typography";
import { IconsType } from "types/main";
import {
  Container,
  Input,
  ContentContainer,
  Label,
  ExampleImage,
  MainInputWrapper,
} from "./IntegerSelector.styled";

export function IntegerSelector({
  error,
  errorMessage,
  icon,
  imgSrc,
  label,
  limits,
  onUpdate,
  title,
  value,
}: {
  error?: boolean;
  errorMessage?: string;
  icon: IconsType;
  imgSrc?: string;
  label?: string;
  limits: { max: string; min: string };
  title: string;
  value: string;
  onUpdate: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}) {
  const hasInvalidInputError = isNaN(parseInt(value));
  const hasImage = !!imgSrc;
  const titleComponent = <TitleWithIcon icon={icon} label={title} />;
  return (
    <Container $hasImage={hasImage}>
      <Box component="div" minWidth={250}>
        {hasImage ? (
          <>
            {titleComponent}
            <ExampleImage src={imgSrc} />
          </>
        ) : (
          titleComponent
        )}
      </Box>
      <ContentContainer $noVerticalSpacing={hasImage}>
        <MainInputWrapper>
          <Input
            InputLabelProps={{ shrink: true }}
            error={error || hasInvalidInputError}
            helperText={
              error
                ? errorMessage
                : hasInvalidInputError
                ? "Please type a valid number"
                : ""
            }
            type="number"
            value={value}
            onChange={onUpdate}
          />

          {label ? <Label>{label}</Label> : null}
        </MainInputWrapper>
        <Box
          alignItems="center"
          component="div"
          display="flex"
          mt={1}
          sx={{ width: "100%" }}
        >
          <HelpText>
            From {limits.min} to {limits.max}
          </HelpText>
        </Box>
      </ContentContainer>
    </Container>
  );
}
