export function RelativeLengthIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 4H21V20H18V4ZM3 13H6V20H3V13ZM14 4H17V7H14V4ZM10 5H13V9H10V5ZM7 10H10V14H7V10Z"
        fill={fill}
      />
    </svg>
  );
}
