import { useMemo } from "react";
import { Geom2 } from "@flasd/modeling/src/geometries/types";
import { Shape } from "three";
import { colors } from "components/App/theme";
import { fromGeom2ToVector2s } from "features/JobBuilder2/steps/threeDimensional/utils/polygon-converters";

export function DrawingActivityRegion({ geom }: { geom: Geom2 }) {
  const points = useMemo(() => fromGeom2ToVector2s(geom), [geom]);
  return (
    <mesh>
      <extrudeBufferGeometry
        args={[
          new Shape(points),
          {
            bevelEnabled: false,
          },
        ]}
      />
      <meshBasicMaterial transparent color={colors.bright[2]} opacity={0.8} />
    </mesh>
  );
}
