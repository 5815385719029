import { useMemo } from "react";
import { Geom2 } from "@flasd/modeling/src/geometries/types";
import { Line } from "@react-three/drei";
import * as THREE from "three";
import { colors as themeColors } from "components/App/theme";
import { connectVec2s } from "features/JobBuilder2/steps/threeDimensional/utils/polygon-converters";

export function ActivityZoneOutlines({ geom }: { geom: Geom2 }) {
  // get geometry's constituent polygons
  const polygons = useMemo(
    () => geom.outlines.filter((poly) => poly.length > 1).map(connectVec2s),
    [geom]
  );

  return (
    <>
      {polygons.map((polygon, index) => (
        <Line
          key={index}
          color={themeColors.bright[2]}
          dashed={false}
          depthFunc={THREE.AlwaysDepth}
          depthTest={false}
          depthWrite={true}
          lineWidth={4}
          points={polygon}
          renderOrder={2}
          transparent={true}
        />
      ))}
    </>
  );
}
