import React, { useEffect, useState } from "react";
import { Box, Link } from "@mui/material";
import * as Sentry from "@sentry/react";
import { Navigate } from "react-router-dom";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { SimpleErrorMessage } from "components/Common/Messages/SimpleErrorMessage";
import { Messages } from "constants/messages";
import { URLParams } from "constants/params";
import { Routes } from "constants/routes";
import { hasValidBillMethod } from "domain/Org";
import { useOrgs } from "hooks/useOrgs";
import { useBoundStore } from "store/_boundStore";

function Home() {
  const [redirectPath, setRedirectPath] = useState("");
  const { fetchPermissions } = useBoundStore.getState().org;
  const { loginRedirect } = useBoundStore((state) => {
    return {
      loginRedirect: state.profile.redirectLocationAfterLogin,
    };
  });

  const [orgs, loading, error] = useOrgs();

  useEffect(() => {
    if (!orgs) {
      return;
    }
    const eligibleOrg = orgs.find(hasValidBillMethod);
    if (eligibleOrg) {
      fetchPermissions(eligibleOrg.name);
      // There is an org that already has a payment method. Go to it.
      setRedirectPath(
        loginRedirect
          ? loginRedirect.pathname + loginRedirect.search
          : Routes.JOBS + `?${URLParams.org}=${eligibleOrg.name}`
      );
    } else {
      // Finish the onboarding process of the first (default) org
      console.debug("No org with a payment method found");
      setRedirectPath(Routes.FIRST_ORG);
    }
  }, [orgs, loginRedirect, fetchPermissions]);

  useEffect(() => {
    if (error) {
      Sentry.captureException(Error(error));
    }
  }, [error]);

  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  return (
    <Box component="div" data-testid="home-component" height="100%">
      {loading && <FullScreenProgress />}
      {error && (
        <SimpleErrorMessage title={Messages.somethingWentWrong}>
          <Box component="div" mb={1}>
            We are really sorry, please try{" "}
            <Link
              href="/"
              underline="always"
              onClick={() => document.location.reload()}
            >
              reloading this page
            </Link>
            .
          </Box>
          <Box component="div">
            If you think you found a bug or need assistance please contact{" "}
            <Link href="mailto:support@synthesis.ai" underline="always">
              support@synthesis.ai
            </Link>
            .
          </Box>
        </SimpleErrorMessage>
      )}
    </Box>
  );
}

export { Home };
