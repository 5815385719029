import { TextField, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const Input = styled(TextField)`
  input {
    font-size: 14px;
    line-height: 21px;
    min-width: 20px;
    font-weight: 700;
    max-width: 80px;
    color: ${colors.grey50};
    max-height: 16px;
    padding: 4px 12px;
  }
  max-height: 24px;
`;

export const Text = styled(Typography)<{ $selected: boolean }>`
  font-size: 14px;
  line-height: 21px;
  min-width: 20px;
  font-weight: 700;
  color: ${({ $selected }) => ($selected ? colors.orange : colors.grey50)};
  cursor: text;
`;
