import { Box, Button, Dialog, Typography } from "@mui/material";
import styled, { css } from "styled-components";
import { colors } from "components/App/theme";

export const DialogContainer = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 8px;
    padding: 24px 40px;
  }
`;

export const DialogBody = styled(Typography)`
  padding: 8px 0;
  line-height: 24px;
  font-size: 16px;
  color: ${colors.grey70};
`;

export const ActionsContainer = styled(Box)`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonCommonCss = css`
  width: 100%;
  padding: 8px 16px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
`;

export const ConfirmButton = styled(Button)`
  ${ButtonCommonCss};
  background-color: ${colors.orange};
  color: ${colors.white};
  margin-bottom: 8px;
`;

export const CancelButton = styled(Button)`
  ${ButtonCommonCss};
  background-color: ${colors.tint5};
  color: ${colors.orange};
  padding: 8px 16px;
`;
