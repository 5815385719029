import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { produce } from "immer";
import * as constants from "constants/constants";
import { ApisVersion } from "domain/ApisVersion";
import { Draft, DraftWithoutState, PatchDraftPayload } from "domain/Draft";
import { LabelWithValue, defaultDemographicSelection } from "domain/Human";
import { StripeInvoice } from "domain/Invoice";
import { Job } from "domain/Job";
import { Org } from "domain/Org";
import { OrgUser } from "domain/OrgUser";
import { OrgPermissions } from "domain/Permissions";
import { UserProfile } from "domain/UserProfile";
import {
  defaultSubjob,
  JobPresubmissionData,
} from "features/JobBuilder2/types";
import { roleStrToObject } from "services/permission-service";
import { useBoundStore } from "store/_boundStore";
import {
  PORequest,
  Manifest,
  OrgStats,
  Contract,
  OrgBilling,
  POInvoice,
} from "types/main";
import { defaultRestAPIConfig } from "./utils";

const GENERIC_REST_ERROR = "Error while connecting to the server";

export type JobsMetadata = {
  total: number;
  jobTypes: LabelWithValue[];
  jobUsers: LabelWithValue[];
};

const encode = encodeURIComponent;
const errorIfAxiosError = (error: AxiosError) => {
  const data = error.response?.data as Record<string, string> | undefined;

  if (typeof data === "object") {
    return data?.message || data?.error || GENERIC_REST_ERROR;
  }

  return GENERIC_REST_ERROR;
};
class RestClient {
  axiosInstance;
  uninterceptedAxiosInstance;
  noBaseURLInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: constants.BASE_URL,
    });

    this.uninterceptedAxiosInstance = axios.create({
      baseURL: constants.BASE_URL,
    });

    this.noBaseURLInstance = axios.create();
  }
  APIErrorMessage(e: unknown) {
    if (axios.isAxiosError(e)) {
      return errorIfAxiosError(e);
    }
    return "Unknown error";
  }
  PotentiallyAPIErrorMessage(e: unknown) {
    if (axios.isAxiosError(e)) {
      return errorIfAxiosError(e);
    }
    let message = "Unknown error";
    if (e instanceof Error) {
      message = e.message;
    }
    return message;
  }
  APIValidationErrors(e: unknown) {
    const message = this.APIErrorMessage(e);
    let errors: { field: string; error: string }[] = [];
    if (axios.isAxiosError(e)) {
      const data = e.response?.data;
      if (Array.isArray(data.data)) {
        errors = data.data;
      }
    }
    return { message, errors };
  }

  defaultConfig(): AxiosRequestConfig {
    return defaultRestAPIConfig(useBoundStore.getState().auth.token);
  }

  async login(email: string, password: string): Promise<{ token: string }> {
    const result = await this.axiosInstance.post("/login", {
      email,
      password,
    });

    const { data } = result.data;
    const { jwt } = data;

    return { token: jwt };
  }

  async checkIfIsAuthorized(email: string, password: string) {
    await this.uninterceptedAxiosInstance.post("/login", {
      email,
      password,
    });
  }

  async getUserProfile(userId: string): Promise<UserProfile> {
    const result = await this.axiosInstance.get(
      `/users/${encode(userId)}`,
      this.defaultConfig()
    );

    const { data } = result.data;
    return data;
  }

  async getApisVersion(): Promise<ApisVersion> {
    const result = await this.axiosInstance.get(
      `/v1/version`,
      this.defaultConfig()
    );

    const { data } = result;
    return data;
  }

  async getUserProfileWithNoDefaultConfig(
    userId: string,
    token: string
  ): Promise<UserProfile> {
    const result = await this.axiosInstance.get(`/users/${encode(userId)}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { data } = result.data;
    return data;
  }

  async getPermissionsInOrg(
    orgName: string,
    userId: string
  ): Promise<OrgPermissions> {
    const result = await this.axiosInstance.get(
      `/orgs/${encode(orgName)}/users/${encode(userId)}`,
      this.defaultConfig()
    );

    const { data } = result.data;
    return data;
  }

  async updateUserProfile(
    userId: string,
    {
      firstName,
      lastName,
    }: {
      firstName: string;
      lastName: string;
    }
  ): Promise<UserProfile> {
    const result = await this.axiosInstance.patch(
      `/users/${encode(userId)}`,
      { first_name: firstName, last_name: lastName },
      this.defaultConfig()
    );

    const { data } = result.data;
    return data;
  }

  async updateEmailInit(userId: string, email: string) {
    return await this.axiosInstance.patch(
      `/users/${userId}/email-init`,
      { new_email: email },
      this.defaultConfig()
    );
  }

  async changePassword(
    userId: string,
    currentPassword: string,
    newPassword: string
  ): Promise<UserProfile> {
    const result = await this.axiosInstance.patch(
      `/users/${encode(userId)}`,
      { old_password: currentPassword, password: newPassword },
      this.defaultConfig()
    );

    const { data } = result.data;
    return data;
  }

  async createAccount(
    email: string,
    firstName: string,
    lastName: string,
    password: string
  ): Promise<string> {
    const result = await this.axiosInstance.post(
      `/users`,
      { email, first_name: firstName, last_name: lastName, password },
      this.defaultConfig()
    );
    const { message } = result.data;
    return message;
  }

  async getStripeSecret(orgName: string): Promise<string> {
    const result = await this.axiosInstance.post(
      `/orgs/${encode(orgName)}/billings`,
      {},
      this.defaultConfig()
    );
    const { data } = result.data;
    return data.client_secret;
  }

  async getOrgs(userId: string): Promise<Org[]> {
    const result = await this.axiosInstance.get(
      `/users/${encode(userId)}/orgs`,
      this.defaultConfig()
    );

    const { data } = result.data;
    return data || [];
  }

  async getIdentities(
    count: number,
    selections: (typeof defaultDemographicSelection)[],
    prefer_full_count: boolean
  ): Promise<{ ids: number[] }> {
    const fillAllIfEmptyProps = ["age", "ethnicity", "sex"] as const;
    const updatedSelections = selections.map((s) => ({
      ...s,
      percent: parseFloat(s.percent),
    }));
    const criteria = updatedSelections.map((selection) => {
      return produce(selection, (draft) => {
        for (const prop of fillAllIfEmptyProps) {
          if (!draft[prop].length) {
            draft[prop] = ["all"];
          }
        }
      });
    });
    const result = await this.noBaseURLInstance.post(
      `${constants.getCatalogIdentitiesBaseURL()}/distro`,
      { count, criteria, prefer_full_count },
      this.defaultConfig()
    );
    return result.data.data;
  }

  async createOrg(org: Org): Promise<Org> {
    const result = await this.axiosInstance.post(
      `/orgs`,
      org,
      this.defaultConfig()
    );

    const { data } = result.data;
    return data;
  }

  async createPL2Draft(
    orgName: string,
    job_name: string,
    job_state: (typeof defaultSubjob)[],
    version: number
  ): Promise<Draft> {
    const result = await this.axiosInstance.post(
      `v2/orgs/${orgName}/drafts`,
      {
        job_name,
        job_state,
        version,
        location: job_state[0].location,
      },
      this.defaultConfig()
    );

    const { data } = result.data;
    return data;
  }

  async deletePL2Draft(orgName: string, draftID: string): Promise<Draft> {
    return this.axiosInstance.delete(
      `v2/orgs/${orgName}/drafts/${draftID}`,
      this.defaultConfig()
    );
  }

  async patchPL2Draft(
    draft: PatchDraftPayload,
    draftID: string,
    orgName: string
  ) {
    return this.axiosInstance.patch(
      `v2/orgs/${orgName}/drafts/${draftID}`,
      draft,
      this.defaultConfig()
    );
  }

  async getPL2Draft(orgName: string, draftID: string): Promise<Draft> {
    const result = await this.axiosInstance.get(
      `v2/orgs/${orgName}/drafts/${draftID}`,
      this.defaultConfig()
    );

    const { data } = result.data;
    return data;
  }

  async getPL2DraftsWithoutStateForAnOrgAndVersion(
    orgName: string,
    version: number
  ): Promise<DraftWithoutState[]> {
    const result = await this.axiosInstance.get(
      `v2/orgs/${orgName}/drafts/version/${version}`,
      this.defaultConfig()
    );

    return (result.data.data as Draft[]) || [];
  }

  async renameOrg(orgName: string, name: string) {
    await this.axiosInstance.patch(
      `/orgs/${encode(orgName)}`,
      { name },
      this.defaultConfig()
    );
  }

  async getJobs(
    orgName: string,
    page: number,
    size: number,
    q: string,
    sortField: string,
    sortOrder: string,
    startDate: number,
    endDate: number,
    jobType: number,
    jobUser: string
  ): Promise<{
    data: Job[];
    metadata: JobsMetadata;
  }> {
    const result = await this.axiosInstance.get(
      `/orgs/${encode(orgName)}/jobs`,
      this.configWithPagingParams(
        page,
        size,
        q,
        sortField,
        sortOrder,
        startDate,
        endDate,
        jobType,
        jobUser
      )
    );
    const { data } = result.data;

    return {
      data: data.jobs || [],
      metadata: {
        total: data.total as number,
        jobTypes: Object.entries(data.job_types as Record<string, string>).map(
          ([key, value]) => ({
            value: key,
            label: value,
          })
        ),
        jobUsers: data.job_users.map(
          (user: { id: string; full_name: string }) => ({
            value: `${user.id}`,
            label: user.full_name.trim() || "-",
          })
        ),
      },
    };
  }

  // This is not being used because of a CORS error. I should use this in CreateJob
  // component if this error got resolved.
  async getSampleJob() {
    const result = await this.axiosInstance.get(constants.SAMPLE_JOB_URL);
    return result.data;
  }

  async createApiKey(
    userId: string,
    name: string
  ): Promise<{ name: string; key: string }> {
    const result = await this.axiosInstance.post(
      `/users/${encode(userId)}/apikeys`,
      { name },
      this.defaultConfig()
    );

    const { data } = result.data;
    return data;
  }

  async getApiKeys(userId: string): Promise<{ name: string }[]> {
    const result = await this.axiosInstance.get(
      `/users/${encode(userId)}/apikeys`,
      this.defaultConfig()
    );

    const { data } = result.data;
    return data;
  }

  async deleteApiKey(
    userId: string,
    name: string
  ): Promise<{ name: string }[]> {
    const result = await this.axiosInstance.delete(
      `/users/${encode(userId)}/apikeys/${encode(name)}`,
      this.defaultConfig()
    );

    const { data } = result.data;
    return data;
  }

  async renameApiKey(
    userId: string,
    currentName: string,
    newName: string
  ): Promise<{ name: string; key: string }> {
    const result = await this.axiosInstance.put(
      `/users/${encode(userId)}/apikeys/${encode(currentName)}`,
      { name: newName },
      this.defaultConfig()
    );

    const { data } = result.data;
    return data;
  }

  async forgotPassword(
    email: string,
    verificationEmailURLParams: { [key: string]: string[] }
  ): Promise<string> {
    const result = await this.axiosInstance.post("/lost", {
      email,
      verificationEmailURLParams,
    });

    const { message } = result.data;
    return message;
  }

  async resetPassword(
    email: string,
    code: string,
    password: string
  ): Promise<string> {
    const result = await this.axiosInstance.post("/reset", {
      email,
      code,
      password,
    });

    const { message } = result.data;
    return message;
  }

  async userEmailExists(email: string): Promise<boolean> {
    const result = await this.axiosInstance.get(`/users/exists/${email}`);
    return result.data.data;
  }

  async orgNameExists(orgName: string): Promise<boolean> {
    const result = await this.axiosInstance.get(`/orgs/exists/${orgName}`);
    return result.data.data;
  }

  async getCurrentBillingInfo(orgName: string): Promise<OrgBilling> {
    const result = await this.axiosInstance.get(
      `/orgs/${encode(orgName)}/billings`,
      this.defaultConfig()
    );

    const { data } = result.data;
    return data;
  }

  async getOrgUsers(orgName: string): Promise<OrgUser[]> {
    const result = await this.axiosInstance.get(
      `/orgs/${encode(orgName)}/users`,
      this.defaultConfig()
    );

    const { data } = result.data;
    return data || [];
  }

  async updateUserPemissions(
    orgName: string,
    email: string,
    role: "reader" | "writer" | "owner"
  ): Promise<OrgUser[]> {
    const result = await this.axiosInstance.post(
      `/orgs/${encode(orgName)}/users`,
      {
        email,
        ...roleStrToObject(role),
      },
      this.defaultConfig()
    );

    const { data } = result.data;
    return data || [];
  }

  async inviteUser(
    orgName: string,
    email: string,
    role: "reader" | "writer" | "owner"
  ): Promise<string> {
    // Don't fully understand why, but it's how https://github.com/Synthesis-AI-Dev/synthesis-web-app/issues/178 says things should be.
    const result = await this.axiosInstance.post(
      `/orgs/${encode(orgName)}/invite`,
      {
        ...roleStrToObject(role),
        email,
      },
      this.defaultConfig()
    );

    const { message } = result.data;
    return message;
  }

  async resendInvite(orgName: string, email: string): Promise<string> {
    const result = await this.axiosInstance.post(
      `orgs/${orgName}/resend-invite`,
      {
        email,
      },
      this.defaultConfig()
    );
    const { message } = result.data;
    return message;
  }

  async addOrModifyOrgUser(
    orgName: string,
    email: string,
    role: "reader" | "writer" | "owner"
  ): Promise<void> {
    return await this.axiosInstance.post(
      `/orgs/${encode(orgName)}/users`,
      {
        ...roleStrToObject(role),
        email,
      },
      this.defaultConfig()
    );
  }

  async removeUserFromOrg(
    orgName: string,
    userId: number | string
  ): Promise<void> {
    return await this.axiosInstance.delete(
      `/orgs/${encode(orgName)}/users/${encode(userId)}`,
      this.defaultConfig()
    );
  }

  async acceptInvite(
    email: string,
    first_name: string,
    last_name: string,
    password: string,
    code: string
  ): Promise<string> {
    const result = await this.axiosInstance.post(
      "/reply",
      {
        email,
        first_name,
        last_name,
        code,
        password,
      },
      this.defaultConfig()
    );

    const { message } = result.data;
    return message;
  }

  async updateJob(
    orgName: string,
    jobId: string,
    job: { name: string }
  ): Promise<void> {
    const result = await this.axiosInstance.patch(
      `/orgs/${encode(orgName)}/jobs/${encode(jobId)}`,
      job,
      this.defaultConfig()
    );

    const { data } = result.data;
    return data;
  }

  async resetJob(jobId: string): Promise<void> {
    return this.axiosInstance.post(
      `v1/job/${jobId}/reset`,
      {},
      this.defaultConfig()
    );
  }

  async cancelJob(orgName: string, jobId: string): Promise<void> {
    return this.axiosInstance.post(
      `v1/org/${orgName}/job/${jobId}/cancel`,
      {},
      this.defaultConfig()
    );
  }

  async deleteJob(orgName: string, jobId: string): Promise<void> {
    const result = await this.axiosInstance.delete(
      `orgs/${orgName}/jobs/${jobId}`,
      this.defaultConfig()
    );

    const { data } = result.data;
    return data;
  }

  async getJobSpec(orgName: string, jobId: string) {
    const result = await this.axiosInstance.get(
      `orgs/${orgName}/jobs/${jobId}/spec-url`,
      this.defaultConfig()
    );
    const url = result.data.data.url;
    const res = await this.axiosInstance.get(url, {
      responseType: "arraybuffer",
    });
    return res.data;
  }

  async getPaymentHistory(orgName: string): Promise<StripeInvoice[]> {
    const result = await this.axiosInstance.get(
      `/orgs/${encode(orgName)}/billings/history`,
      this.defaultConfig()
    );

    const { data } = result.data;
    return data.invoices || [];
  }

  async resendVerification(
    email: string,
    verificationEmailURLParams: { [key: string]: string[] }
  ) {
    return await this.axiosInstance.post(
      `/resend-verification`,
      { email, verificationEmailURLParams },
      this.defaultConfig()
    );
  }

  async updateOrgUserName(
    orgName: string,
    user_id: number,
    first_name: string,
    last_name: string
  ): Promise<UserProfile> {
    const result = await this.axiosInstance.post(
      `/orgs/${orgName}/users/update-name`,
      { user_id, first_name, last_name },
      this.defaultConfig()
    );

    const { data } = result.data;
    return data;
  }

  async updateEmailReply(token: string) {
    const result = await this.axiosInstance.patch(
      `/email-finalize`,
      { token },
      this.defaultConfig()
    );
    const { data } = result.data;
    return data;
  }

  async getManifest(orgName: string, jobID: string): Promise<Manifest> {
    const result = await this.axiosInstance.get(
      `/v1/org/${orgName}/job/${jobID}/manifest`,
      this.defaultConfig()
    );
    return result.data;
  }

  async getHython(
    orgName: string,
    jobID: string,
    renderID: number,
    frameNumber: number,
    cameraName: string
  ): Promise<Manifest> {
    const result = await this.axiosInstance.get(
      `/v1/org/${orgName}/job/${jobID}/${renderID}/${frameNumber}/${cameraName}/hython`,
      this.defaultConfig()
    );
    return result.data;
  }

  async getJobPresubmissionData(
    orgName: string,
    jobID: string
  ): Promise<JobPresubmissionData> {
    const result = await this.axiosInstance.get(
      `/v2/orgs/${orgName}/jobs/${jobID}/presubmission`,
      this.defaultConfig()
    );
    return result.data.data;
  }

  async patchJobPresubmissionData(
    orgName: string,
    jobID: string,
    jobPresubmissionData: JobPresubmissionData
  ) {
    const result = await this.axiosInstance.patch(
      `/v2/orgs/${orgName}/jobs/${jobID}/presubmission`,
      jobPresubmissionData,
      this.defaultConfig()
    );
    return result.data;
  }

  async getAssetURL(
    orgName: string,
    jobID: string,
    renderID: number,
    frameNumber: number,
    cameraName: string,
    filename: string
  ): Promise<string> {
    const result = await this.axiosInstance.get(
      `/v1/org/${orgName}/job/${jobID}/${renderID}/${frameNumber}/${cameraName}/web/${filename}`,
      this.defaultConfig()
    );
    return result.data.url;
  }

  async customURLGetWithAuth(url: string): Promise<string | object> {
    const result = await this.noBaseURLInstance.get(url, this.defaultConfig());
    return result.data;
  }

  async customURLGetWithoutAuth(url: string): Promise<string | object> {
    const result = await this.noBaseURLInstance.get(url);
    return result.data;
  }

  async getZip(orgName: string, jobID: string): Promise<string> {
    const result = await this.axiosInstance.get(
      `/orgs/${orgName}/jobs/${jobID}/zip`,
      this.defaultConfig()
    );
    return result.data.data.url;
  }

  async zipFileExists(orgName: string, jobID: string): Promise<boolean> {
    const result = await this.axiosInstance.get(
      `/orgs/${orgName}/jobs/${jobID}/zip-exists`,
      this.defaultConfig()
    );
    return result.data.data;
  }

  async getEnums() {
    const result = await this.axiosInstance.get(
      `/v1/enums`,
      this.defaultConfig()
    );
    return result.data;
  }

  async getGestures() {
    const result = await this.axiosInstance.get(
      `/v1/enums/gestures`,
      this.defaultConfig()
    );
    return result.data.data;
  }

  async submitJob(
    orgName: string,
    job: string | object,
    userEmail: string,
    jobName: string
  ): Promise<{ job_id: string }> {
    const result = await this.axiosInstance.post(
      `/v1/org/${orgName}/human?notify=${userEmail}&name=${jobName}`,
      job,
      this.defaultConfig()
    );
    return result.data;
  }

  async submitCharactersJob(
    orgName: string,
    job: object,
    userEmail: string,
    jobName: string
  ): Promise<{ job_id: string }> {
    const result = await this.axiosInstance.post(
      `/v1/org/${orgName}/charactergen?notify=${userEmail}&name=${jobName}`,
      job,
      this.defaultConfig()
    );
    return result.data;
  }

  async submitPL2Job(
    orgName: string,
    job: object,
    userEmail: string,
    jobName: string
  ): Promise<{ job_id: string }> {
    const result = await this.axiosInstance.post(
      `/v1/org/${orgName}/multihumans?notify=${userEmail}&name=${jobName}`,
      job,
      this.defaultConfig()
    );
    return result.data;
  }

  async submitPORequest(orgName: string, notes: string) {
    return this.axiosInstance.post(
      `orgs/${orgName}/po-requests`,
      { notes },
      this.defaultConfig()
    );
  }
  async getInvoices(): Promise<POInvoice[]> {
    const result = await this.axiosInstance.get(
      `/invoices`,
      this.defaultConfig()
    );
    const { data } = result.data;
    return data || [];
  }
  async createInvoice(p: Partial<POInvoice>): Promise<POInvoice> {
    const result = await this.axiosInstance.post(
      `/invoices`,
      p,
      this.defaultConfig()
    );
    return result.data;
  }
  async editInvoice(
    invoice_number: string,
    p: Partial<POInvoice>
  ): Promise<POInvoice> {
    const result = await this.axiosInstance.put(
      `/invoices/${invoice_number}`,
      p,
      this.defaultConfig()
    );
    return result.data;
  }
  async setInvoicePaid(
    invoice_number: string,
    p: Partial<POInvoice>
  ): Promise<POInvoice> {
    const result = await this.axiosInstance.patch(
      `/invoices/${invoice_number}/paid`,
      p,
      this.defaultConfig()
    );
    return result.data;
  }
  async getPOs(): Promise<PORequest[]> {
    const result = await this.axiosInstance.get(
      `/po-requests`,
      this.defaultConfig()
    );
    const { data } = result.data;
    return data || [];
  }

  async approvePORequest(id: number, po_number: string, end_date: string) {
    return this.axiosInstance.post(
      `/po-requests/${id}/approve`,
      { po_number, end_date },
      this.defaultConfig()
    );
  }

  async createPO(org_id: number, po_number: string, end_date: string) {
    return this.axiosInstance.post(
      `/po-numbers`,
      { org_id, po_number, end_date },
      this.defaultConfig()
    );
  }

  async rejectPORequest(id: number, admin_notes: string) {
    return this.axiosInstance.post(
      `/po-requests/${id}/reject`,
      { admin_notes },
      this.defaultConfig()
    );
  }

  async enableDenseDownloads(orgID: number) {
    return this.axiosInstance.post(
      `/orgs/${orgID}/extra-assets/dense`,
      {},
      this.defaultConfig()
    );
  }

  async disableDenseDownloads(orgID: number) {
    return this.axiosInstance.delete(
      `/orgs/${orgID}/extra-assets/dense`,
      this.defaultConfig()
    );
  }

  async overridePayment(orgID: number) {
    return this.axiosInstance.patch(
      `/orgs/payment-override/${orgID}`,
      {},
      this.defaultConfig()
    );
  }
  async removePaymentOverride(orgID: number) {
    return this.axiosInstance.delete(
      `/orgs/payment-override/${orgID}`,
      this.defaultConfig()
    );
  }
  async orgsStats(): Promise<OrgStats[]> {
    const result = await this.axiosInstance.get(
      `/orgs/stats`,
      this.defaultConfig()
    );
    const { data } = result.data;
    return data || [];
  }

  async createNewContract(contract: Contract) {
    return this.axiosInstance.post(
      `/contracts`,
      contract,
      this.defaultConfig()
    );
  }

  async editContract(id: number, contract: Contract) {
    return this.axiosInstance.put(
      `/contracts/${id}`,
      contract,
      this.defaultConfig()
    );
  }

  async deactivateContract(id: number) {
    return this.axiosInstance.put(
      `/contracts/${id}/deactivate`,
      null,
      this.defaultConfig()
    );
  }

  configWithPagingParams(
    page: number,
    size: number,
    q: string,
    sortField: string,
    sortOrder: string,
    startDate: number,
    endDate: number,
    jobType: number,
    jobUser?: string
  ) {
    const config = this.defaultConfig();
    config.params = {};
    if (page !== undefined && size) {
      config.params.page = page;
      config.params.size = size;
    }

    if (startDate && endDate) {
      config.params.dateStart = startDate;
      config.params.dateEnd = endDate;
    }

    if (q) {
      config.params.q = q;
    }

    if (sortField) {
      config.params.sort = sortField;
    }

    if (sortOrder) {
      config.params.order = sortOrder;
    }

    if (jobType && jobType !== -1) {
      config.params.jobType = jobType;

      // TODO [SAI-3890]: Remove this once the app is deployed to production
      config.params.job_type = jobType;
    }

    if (jobUser) {
      config.params.userId = jobUser;
    }

    return config;
  }
}

export { RestClient };
