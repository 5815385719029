import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { InlineEdit } from "components/Common/InlineEdit";

export function CameraTitle({
  value,
  onUpdateName,
}: {
  value: string;
  onUpdateName: (v: string) => void;
}) {
  const [isEditingName, setIsEditingName] = useState(false);

  return (
    <InlineEdit
      editIcon={
        <EditIcon
          fontSize="small"
          style={{ marginLeft: "1rem", cursor: "pointer" }}
        />
      }
      flexGrow={0}
      internalValueFontSize="h6"
      isEditing={isEditingName}
      saveLabel="Rename"
      value={value}
      onSave={onUpdateName}
      onToggleEditingMode={setIsEditingName}
    />
  );
}
