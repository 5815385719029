import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const Container = styled(Box)`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 4px;
`;

export const Title = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  padding-right: 16px;
  white-space: nowrap;
  line-clamp: 1;
  color: ${colors.grey70};
`;

export const DropdownContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 220px;
  min-width: 200px;
`;
