import { GetState, SetState } from "store/_boundStore";

export function createActions(
  set: SetState,
  get: GetState,
  initialState: object
) {
  return {
    setErrorMessage: (error: string) =>
      set((state) => {
        state.message.error = error;
      }),
    setWarnMessage: (warn: string) =>
      set((state) => {
        state.message.warn = warn;
      }),
    setSuccessMessage: (success: string, overwrite = true) => {
      if (overwrite) {
        const {
          message: { warn, error, success },
        } = get();
        // don't emit a toast if one already exists
        if (warn || error || success) {
          return;
        }
      }
      set((state) => {
        state.message.success = success;
      });
    },
    clearMessages: () =>
      set((state) => {
        state.message = { ...state.message, ...initialState };
      }),
  };
}
