import { Box, Typography } from "@mui/material";
import { colors } from "components/App/theme";
import { VirtualizedList } from "components/Common/VirtualizedList";
import { addSIfGreaterThan1 } from "utils/stringUtil";
import { CELL_DIMENSION_PX } from "./constants";
import {
  ImageGrid,
  PreviewImage,
  Subtitle,
} from "./RightAsidePreviewFull.styled";
import { RightAsidePreviewFullBaseProps } from "./types";

export function InfiniteScroll(props: RightAsidePreviewFullBaseProps) {
  const { data, title } = props;

  function Cell(imageIndex: number) {
    return (
      <PreviewImage
        key={imageIndex}
        alt={data[imageIndex].label}
        src={data[imageIndex].src}
      />
    );
  }

  const text = data.length
    ? `Selected ${addSIfGreaterThan1(data.length, "option")}`
    : `No options selected`;

  console.log(data);

  return (
    <Box component="div">
      <Typography color={colors.grey70} fontWeight="600" variant="subtitle2">
        {title}
      </Typography>
      <Subtitle variant="subtitle2">{text}</Subtitle>
      <ImageGrid>
        <VirtualizedList
          cellWidth={CELL_DIMENSION_PX}
          items={data}
          rowHeight={CELL_DIMENSION_PX}
        >
          {Cell}
        </VirtualizedList>
      </ImageGrid>
    </Box>
  );
}
