export function MediaIcon({
  style = { height: "16px" },
  fill = "currentColor",
}: {
  style?: React.CSSProperties;
  fill?: string;
} = {}) {
  return (
    <svg
      fill="none"
      height="16"
      style={style}
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33333 4.50004H0V7.83337H0.00666667L0 13.8334C0 14.5667 0.6 15.1667 1.33333 15.1667H13.3333V13.8334H1.33333V4.50004ZM4.66667 10.5H14L11.6667 7.50004L10 9.50671L7.66667 6.50004L4.66667 10.5ZM14.6667 3.16671H9.33333L8 1.83337H4C3.26667 1.83337 2.67333 2.43337 2.67333 3.16671L2.66667 11.1667C2.66667 11.9 3.26667 12.5 4 12.5H14.6667C15.4 12.5 16 11.9 16 11.1667V4.50004C16 3.76671 15.4 3.16671 14.6667 3.16671ZM14.6667 11.1667H4V3.16671H7.44667L8.78 4.50004H14.6667V11.1667Z"
        fill={fill}
      />
    </svg>
  );
}
