import styled from "styled-components";

export const PopupWrapper = styled.div<{ scale: number }>`
  width: 140px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  border-radius: 8px;
  transform: ${(props) => `scale(${props.scale})`};
  position: relative;
  translate: -50% -50%;

  &.top {
    transform-origin: center top;
  }

  &.bottom {
    transform-origin: center bottom;
  }

  &.left {
    transform-origin: center left;
  }

  &.right {
    transform-origin: center right;
  }

  &::after {
    content: "";
    z-index: 10;
    position: absolute;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border-width: 10px;
    border-style: solid;
  }

  &.top::after {
    top: 0;
    left: calc(50% - 5px);
    border-color: rgba(0, 0, 0, 0.8) rgba(0, 0, 0, 0.8) transparent transparent;
    transform: translateY(-100%);
    rotate: -45deg;
    transform-origin: top right;
  }

  &.bottom::after {
    bottom: 0;
    left: calc(50% - 5px);
    border-color: rgba(0, 0, 0, 0.8) transparent transparent;
    transform-origin: top left;
    transform: translateY(100%);
  }

  &.left::after {
    left: 0;
    bottom: 50%;
    border-color: transparent transparent rgba(0, 0, 0, 0.8) rgba(0, 0, 0, 0.8);
    rotate: 45deg;
    transform-origin: 0 0;
  }

  &.right::after {
    right: 0;
    bottom: 50%;
    border-color: rgba(0, 0, 0, 0.8) rgba(0, 0, 0, 0.8) transparent transparent;
    transform: translateX(100%);
    transform-origin: top right;
    rotate: 45deg;
  }
`;
