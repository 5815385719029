import { ReactNode } from "react";
import { ListItemText, SelectChangeEvent } from "@mui/material";
import { ChevronDownIcon } from "assets/icons";
import {
  DropdownContainer,
  DropdownSelect,
  StyledMenuItem,
} from "./SimpleDropdown.styled";

export function SimpleDropdown({
  value,
  options,
  onChange,
  alternativeCustomIcon,
}: {
  value: string;
  options: string[];
  onChange: (e: SelectChangeEvent<unknown>, child: ReactNode) => void;
  alternativeCustomIcon?: boolean;
}) {
  const iconComponent = alternativeCustomIcon
    ? (props: any) => (
        <ChevronDownIcon
          style={{
            cursor: "pointer",
            position: "absolute !important",
            right: "0 !important",
            pointerEvents: "none !important",
            marginRight: "8px",
          }}
          {...props}
        />
      )
    : (props: any) => (
        <div {...props}>
          <ChevronDownIcon {...props} />
        </div>
      );
  return (
    <DropdownContainer>
      <DropdownSelect
        fullWidth
        IconComponent={iconComponent}
        value={value}
        variant="outlined"
        onChange={onChange}
      >
        {options.map((o) => (
          <StyledMenuItem key={o} value={o}>
            <ListItemText primary={o} />
          </StyledMenuItem>
        ))}
      </DropdownSelect>
    </DropdownContainer>
  );
}
