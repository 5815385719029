import { Box, Typography, TextField } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Title = styled(Typography)`
  padding-right: 16px;
  color: ${colors.grey70};
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  line-clamp: 1;
  white-space: nowrap;
`;

export const InputContainer = styled(Box)`
  position: relative;
  display: flex;
`;

export const Input = styled(TextField)`
  input {
    font-size: 12px;
    line-height: 16px;
    min-width: 88px;
    color: ${colors.grey70};
    height: 26px;
    padding: 8px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid ${colors.grey20};
  }
  margin-right: 12px;
`;

export const Description = styled(Typography)`
  padding-right: 12px;
  color: ${colors.grey70};
  font-size: 14px;
  line-height: 21px;
`;
