import { createContext, useCallback, useContext, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { colors } from "components/App/theme";

const HelpOverlayContext = createContext({
  show: () => {
    // do nothing
  },
});

export const useShowHelpOverlay = () => {
  const { show } = useContext(HelpOverlayContext);
  return show;
};

export function HelpOverlay({
  startOpen,
  helpMessage,
  children,
  backdropColor,
}: {
  startOpen?: boolean;
  helpMessage: React.ReactNode;
  children: React.ReactNode;
  backdropColor?: string;
}) {
  const [shown, setShow] = useState(startOpen || false);

  const show = useCallback(() => {
    setShow(true);
  }, []);

  return (
    <HelpOverlayContext.Provider value={{ show }}>
      {shown && (
        <>
          <Box
            component="div"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: backdropColor || "rgba(0, 0, 0, 0.6)",
              borderRadius: 2,
              zIndex: 1000,
            }}
          />

          <Box
            component="div"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              zIndex: 1001,
            }}
          >
            {helpMessage}
            <Button
              color="warning"
              sx={{ mt: 2 }}
              variant="contained"
              onClick={() => setShow(false)}
            >
              Ok
            </Button>
          </Box>
        </>
      )}

      {children}
    </HelpOverlayContext.Provider>
  );
}

export function HelpMessage({ children }: { children: React.ReactNode }) {
  return (
    <Typography
      color={colors.white}
      sx={{ fontSize: 28, textAlign: "center" }}
      variant="h3"
    >
      {children}
    </Typography>
  );
}
