import styled from "styled-components";
import { colors } from "components/App/theme";

export const InputWrapper = styled.div`
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
`;

export const InputContainer = styled.div`
  display: inline-grid;
  vertical-align: top;
  align-items: center;
  position: relative;
  border: solid 1px ${colors.grey50};
  border-radius: 4px;
  padding: 0.1em 0.5em;
  align-items: stretch;

  &::after,
  textarea {
    grid-area: 2 / 1;
    min-width: 360px;
  }

  &::after,
  textarea {
    width: auto;
    min-width: 1em;
    grid-area: 1 / 2;
    font: inherit;
    padding: 0.25em;
    margin: 0;
    resize: none;
    background: none;
    appearance: none;
    border: none;
  }

  &::after {
    content: attr(data-value) " ";
    visibility: hidden;
    white-space: pre-wrap;
    word-break: break-word;
  }

  &:focus-within {
    outline: solid 1px ${colors.bright[2]};
    border-color: ${colors.bright[2]};

    textarea:focus,
    input:focus {
      outline: none;
    }
  }
`;
