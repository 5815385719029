import React from "react";
import { useNavigate } from "react-router";
import { CreateOrg } from "components/CreateOrg";
import { URLParams } from "constants/params";
import { Routes } from "constants/routes";

function NewOrg() {
  const navigate = useNavigate();

  return (
    <CreateOrg
      done={(orgName: string) =>
        navigate(Routes.ADD_PAYMENT_INFO + `?${URLParams.org}=${orgName}`)
      }
      subTitle="Enter the organization details"
      title="Create a new Organization"
    />
  );
}

export { NewOrg };
