import { ActivityZonesControlsMode } from "features/JobBuilder2/steps/threeDimensional/activity-zones-controls";
import { SetState } from "store/_boundStore";
import { CursorWithId } from ".";

export function createActions(set: SetState) {
  return {
    setControlMode: (mode: ActivityZonesControlsMode) =>
      set((state) => {
        state.userControls.controlMode = mode;
      }),
    setDragging: (dragging: boolean) =>
      set((state) => {
        state.userControls.dragging = dragging;
      }),
    appendToCursorStack: (cursorWithId: CursorWithId) =>
      set((state) => {
        state.userControls.cursorStack.push(cursorWithId);
      }),
    removeFromCursorStack: (id: string) =>
      set((state) => {
        const stack = state.userControls.cursorStack;
        const index = stack.findIndex((c) => c.id === id);
        stack.splice(index, 1);
      }),
  };
}
