import { useMemo } from "react";
// Truncated normal distribution probability density function (PDF).
import pdf from "@stdlib/stats-base-dists-truncated-normal-pdf";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  ChartData,
  CoreChartOptions,
} from "chart.js";
import { _DeepPartialObject } from "chart.js/types/utils";
import { Line } from "react-chartjs-2";
import { useDebounce } from "use-debounce";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler
);

type LineChartOptions = _DeepPartialObject<CoreChartOptions<"line">>;

// The number of ticks to render on the x-axis
const numberOfTicks = 7;
const tickGaps = numberOfTicks - 1;
// It determines the number of points to use to draw the graph. The higher the number, the smoother the graph but slower the rendering.
const resolutionPower = 1;
// The number of points to use to draw the graph
const resolution = tickGaps * 10 ** resolutionPower;

const useCustomDebounce = (x: number) =>
  useDebounce(x, 200, {
    leading: false,
    maxWait: 1000,
    trailing: true,
  });

type NormalDistChartProps = {
  minValue: number;
  maxValue: number;
  mean: number;
  standardDeviation: number;
};

export function NormalDistChart(props: NormalDistChartProps) {
  const { minValue, maxValue, mean, standardDeviation } = props;

  const [debouncedMean] = useCustomDebounce(mean);
  const [debouncedStdDev] = useCustomDebounce(standardDeviation);

  const calcPdf = useMemo(
    () => pdf.factory(minValue, maxValue, debouncedMean, debouncedStdDev),
    [debouncedMean, debouncedStdDev, minValue, maxValue]
  );

  const xValues = useMemo(() => {
    const increment = (maxValue - minValue) / (resolution - 1);
    return Array.from(
      { length: resolution },
      (_, i) => minValue + increment * i
    );
  }, [maxValue, minValue]);

  const yValues = useMemo(() => xValues.map(calcPdf), [calcPdf, xValues]);

  const maxY = useMemo(() => {
    const max = Math.max(...yValues);
    // Add 15% to the max value to make sure the chart doesn't cut off the top of the graph
    return max + max * 0.15;
  }, [yValues]);

  const data: ChartData<"line", number[], number> = useMemo(
    () => ({
      labels: xValues,
      datasets: [
        {
          fill: true,
          data: yValues,
          // Must be RGB because ChardJs draws on a canvas
          borderColor: "rgb(238, 116, 31)",
          backgroundColor: "rgba(238, 116, 31, 0.3)",
        },
      ],
    }),
    [xValues, yValues]
  );

  const options: LineChartOptions = useMemo(() => {
    const tickIncrement = (maxValue - minValue) / tickGaps;
    const tickValues = Array.from({ length: numberOfTicks }, (_, i) =>
      parseFloat((minValue + tickIncrement * i).toFixed(1))
    );

    // Prevents the same tick from being rendered twice
    const rendered: number[] = [];

    return {
      responsive: true,
      scales: {
        x: {
          min: minValue,
          max: maxValue,
          ticks: {
            callback: (index: number) => {
              const value = xValues[index];
              const closestTickValue = tickValues.find(
                (tick) => Math.abs(value - tick) < tickIncrement / 2
              );

              if (
                closestTickValue !== undefined &&
                !rendered.includes(closestTickValue)
              ) {
                rendered.push(closestTickValue);

                if (Number.isInteger(closestTickValue)) {
                  return closestTickValue;
                }

                return closestTickValue.toFixed(1);
              }

              return null;
            },
          },
        },
        y: {
          ticks: {
            display: false,
          },
          min: 0,
          max: maxY,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };
  }, [maxValue, minValue, maxY, xValues]);

  // If the mean is outside the range, don't render the chart
  if (debouncedMean < minValue || debouncedMean > maxValue) {
    return null;
  }

  return <Line data={data} options={options} />;
}
