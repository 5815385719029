import { Button } from "@mui/material";
import styled from "styled-components";

export const PageButton = styled.button`
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  height: 20px;
  width: 20px;
  display: grid;
  place-content: center;
  cursor: pointer;
`;

export const PageIndicatorWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActionButton = styled(Button)`
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  box-shadow: none;
  height: 32px;
  padding: 8px 16px;
`;

export const NextPageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
