import { IconContainer } from "./icons.styled";

export function ChevronRightIcon({
  style = { height: "24px" },
  fill = "currentColor",
  onClick,
}: {
  style?: React.CSSProperties;
  fill?: string;
  onClick?: () => void;
} = {}) {
  return (
    <IconContainer data-testid="icon-chevron-right" onClick={onClick}>
      <svg
        fill="none"
        height="24"
        style={style}
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.00002 6.71173C8.61002 7.10173 8.61002 7.73173 9.00002 8.12173L12.88 12.0017L9.00002 15.8817C8.61002 16.2717 8.61002 16.9017 9.00002 17.2917C9.39002 17.6817 10.02 17.6817 10.41 17.2917L15 12.7017C15.39 12.3117 15.39 11.6817 15 11.2917L10.41 6.70173C10.03 6.32173 9.39002 6.32173 9.00002 6.71173Z"
          fill={fill}
        />
      </svg>
    </IconContainer>
  );
}
