import React from "react";
import {
  DialogContent,
  Box,
  CircularProgress,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { defaultPasswordAuthValidation } from "utils/validators";

export function PasswordForm({
  onCancel,
  onSubmit,
  loading,
}: {
  onCancel: () => void;
  onSubmit: (password: string) => void;
  loading: boolean;
}) {
  const { register, handleSubmit, errors, getValues } = useForm();
  return (
    <form onSubmit={handleSubmit(() => onSubmit(getValues("password")))}>
      <DialogContent>
        <p>Enter your password to verify your identity</p>
        <Box component="div" mb={3} mt={4}>
          {loading ? (
            <CircularProgress size="6rem" />
          ) : (
            <TextField
              autoFocus
              fullWidth
              error={!!errors.password}
              helperText={errors.password ? errors.password.message : ""}
              id="password"
              inputProps={{
                "data-testid": "password-input",
              }}
              inputRef={register(defaultPasswordAuthValidation)}
              label="Password *"
              name="password"
              type="password"
              variant="outlined"
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          component="div"
          mb={1}
          ml={2}
          mr={2}
          textAlign="right"
          width="100%"
        >
          <Box component="div" display="inline" mr={2}>
            <Button color="secondary" variant="contained" onClick={onCancel}>
              Cancel
            </Button>
          </Box>
          <Button
            autoFocus
            color="warning"
            data-testid="submit-password-form"
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </DialogActions>
    </form>
  );
}
