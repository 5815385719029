import { useState } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Typography,
  Dialog as BaseDialog,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Grid,
} from "@mui/material";
import { PageIndicator } from "components/DialogPageIndicator";
import { Messages } from "constants/messages";
import { ProTipDialogContent } from "./ProTipDialogContent";

export function StageElementDialog({
  isModalOpen,
  setIsModalOpenCallback,
}: {
  isModalOpen: boolean;
  setIsModalOpenCallback: (newValue: boolean) => void;
}) {
  const [currentDialogPage, setcurrentDialogPage] = useState<number>(0);

  return (
    <BaseDialog
      disableEscapeKeyDown
      PaperProps={{ style: { width: "880px" } }}
      maxWidth={false}
      open={isModalOpen}
      onClose={() => setIsModalOpenCallback(false)}
    >
      <DialogTitle fontWeight="600">
        <Grid
          container
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          Stage Element Overview
          <IconButton
            aria-label="close"
            onClick={() => setIsModalOpenCallback(false)}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box component="div">
          <Typography variant="h5">
            {Messages.stageElementProTipModal}
          </Typography>
          <ProTipDialogContent currentPage={currentDialogPage} />
          <PageIndicator
            currentPage={currentDialogPage}
            numberOfPages={3}
            onCloseDialog={() => setIsModalOpenCallback(false)}
            onPageChangeCallback={(i: number) => setcurrentDialogPage(i)}
          />
        </Box>
      </DialogContent>
    </BaseDialog>
  );
}
