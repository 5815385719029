import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const CloseIconContainer = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

export const DialogHeader = styled(DialogTitle)`
  display: flex;
  flex-direction: column;
`;

export const MainHeaderContent = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
  padding-left: 32px;
`;

export const MainImage = styled.img`
  width: 288px;
  height: 232px;
  border-radius: 8px;
  border: 1px solid ${colors.grey20};
`;

export const HeaderTextContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 24px;
  padding-right: 32px;
  width: 100%;
`;

export const Title = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.black};
  padding-bottom: 8px;
`;

export const Description = styled(Typography)`
  font-size: 10px;
  max-width: 218px;
  line-height: 14px;
  color: ${colors.black};
`;

export const PreviewContainer = styled(DialogContent)`
  padding: 30px 54px 0px 56px;
  width: 100%;
`;

export const PreviewImage = styled.img`
  width: 90px;
  height: 90px;
  border: 1px solid ${colors.grey20};
  border-radius: 8px;
  margin-right: 10px;
  margin-bottom: 10px;

  &:nth-child(5n) {
    margin-right: 0;
  }
`;

export const ActionsContainer = styled(DialogActions)`
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 56px 62px;
`;
