import { useState } from "react";
import { Box, styled } from "@mui/material";
import { AddIcon, EditIcon } from "assets/icons";
import { colors } from "components/App/theme";
import { InlineEdit } from "components/Common/InlineEdit";
import { Container, NumberOfImages } from "./job-info.styled";

type JobInfoProps = {
  templateName: string;
  totalNumberOfImages: string;
  onUpdateJobName: (jobName: string) => void;
  onAddSection: () => void;
};

export function JobInfo(props: JobInfoProps) {
  const { templateName, totalNumberOfImages, onUpdateJobName, onAddSection } =
    props;
  const [isEditingName, setIsEditingName] = useState(false);

  return (
    <Container>
      <Box component="div">
        <InlineEdit
          editIcon={
            <EditIcon
              style={{
                color: colors.grey70,
                marginLeft: "8px",
                cursor: "pointer",
              }}
            />
          }
          flexGrow={0}
          internalValueFontWeight={700}
          isEditing={isEditingName}
          saveLabel="Rename"
          textVariant="leftSidebar"
          value={templateName}
          onSave={onUpdateJobName}
          onToggleEditingMode={setIsEditingName}
        />
        <NumberOfImages>({totalNumberOfImages} images)</NumberOfImages>
      </Box>
      <AddIconWrapper data-testid="add-subjob-button" onClick={onAddSection}>
        <AddIcon style={{ cursor: "pointer", color: colors.orange }} />
      </AddIconWrapper>
    </Container>
  );
}

const AddIconWrapper = styled("button")({
  cursor: "pointer",
  color: colors.orange,
  backgroundColor: "transparent",
  border: "none",
  outline: "none",
  padding: 0,
  margin: 0,
  "&:hover": {
    color: colors.orange,
    backgroundColor: "transparent",
  },
});
