import React, { useCallback, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { Box, Divider, MenuItem, Select, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { colors } from "components/App/theme";
import { URLParams } from "constants/params";
import { Routes } from "constants/routes";
import { Org } from "domain/Org";
import { useBoundStore } from "store/_boundStore";

type NavigationHeaderProps = {
  selectedOrgName: string | undefined;
  title: string;
};

function NavigationHeader(props: NavigationHeaderProps) {
  const { selectedOrgName, title } = props;

  const navigate = useNavigate();

  const [
    setErrorMessage,
    fetchPermissions,
    orgs,
    error,
    loading,
    isSupportUser,
  ] = useBoundStore((state) => [
    state.message.setErrorMessage,
    state.org.fetchPermissions,
    state.org.list,
    state.org.error,
    state.org.loading,
    state.profile.data?.is_support,
  ]);

  const navigateToOrg = useCallback(
    (orgName: string) => {
      navigate(Routes.JOBS + `?${URLParams.org}=${orgName}`);
    },
    [navigate]
  );

  useEffect(() => {
    if (error) {
      setErrorMessage(error);
    }
  }, [error, setErrorMessage]);

  const onOrgSelect = useCallback(
    (org: Org) => {
      if (selectedOrgName !== org.name) {
        fetchPermissions(org.name);
        navigateToOrg(org.name);
      }
    },
    [fetchPermissions, navigateToOrg, selectedOrgName]
  );

  const orgItems = useMemo(() => {
    const menuItems = (orgs || []).map((org, index) => (
      <MenuItem key={index} value={org.name} onClick={() => onOrgSelect(org)}>
        {org.name}
      </MenuItem>
    ));
    if (isSupportUser) {
      menuItems.push(<Divider key={menuItems.length} />);
      menuItems.push(
        <MenuItem
          key={menuItems.length + 1}
          value="__new_org"
          onClick={() => {
            navigate(Routes.NEW_ORG);
          }}
        >
          + Create New Org
        </MenuItem>
      );
    }
    return menuItems;
  }, [orgs, isSupportUser, navigate, onOrgSelect]);

  return (
    <NavigationHeaderContainer
      component="div"
      data-testid="navigation-header-component"
    >
      <Typography variant="h1">{title}</Typography>
      <Box
        component="div"
        sx={{
          minWidth: "120px",
        }}
      >
        <OrgSelector
          disableUnderline
          displayEmpty
          fullWidth
          disabled={!orgs?.length}
          value={(orgs?.length && selectedOrgName) || ""}
          variant="standard"
        >
          {orgs?.length ? (
            orgItems
          ) : (
            <MenuItem key="none" value="">
              {loading ? "Loading..." : "No orgs"}
            </MenuItem>
          )}
        </OrgSelector>
      </Box>
    </NavigationHeaderContainer>
  );
}

const NavigationHeaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: 16,
  borderBottom: `1px solid ${colors.grey20}`,
  color: colors.riverBed,
  fontWeight: 600,
  fontSize: "1.2rem",
});

const OrgSelector = styled(Select)({
  ".MuiSelect-icon": {
    right: 0,
    marginRight: 8,
  },
  ".MuiSelect-iconOpen": {
    transform: "none",
  },
  ".MuiSelect-select": {
    fontWeight: 500,
    fontSize: "1rem",
    background: colors.white,
    border: `1px solid ${colors.grey20}`,
    padding: "8px 16px",
    borderRadius: 4,
  },
});

export { NavigationHeader };
