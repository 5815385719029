function isFullfilled<T>(
  result: PromiseSettledResult<T>
): result is PromiseFulfilledResult<T> {
  return result.status === "fulfilled";
}

export const allSettledFullfilled = async <T>(promises: Promise<T>[]) => {
  const promiseResults = await Promise.allSettled(promises);
  return promiseResults.filter(isFullfilled).map((result) => result.value);
};
