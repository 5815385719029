import { IconContainer } from "./icons.styled";

export function EditIcon({
  fill = "currentColor",
  style = { height: "16px" },
  onClick,
}: {
  style?: React.CSSProperties;
  fill?: string;
  onClick?: (e: React.MouseEvent<any>) => void;
}) {
  return (
    <IconContainer onClick={onClick}>
      <svg
        fill="none"
        height="16"
        style={style}
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.2063 5.29341L8.70634 2.79341L2.66634 8.83341V11.3334H5.16634L11.2063 5.29341ZM3.99967 10.0001V9.38675L8.70634 4.68008L9.31967 5.29341L4.61301 10.0001H3.99967Z"
          fill={fill}
        />
        <path
          d="M13.1397 3.36008C13.3997 3.10008 13.3997 2.68008 13.1397 2.42008L11.5797 0.860081C11.4463 0.726748 11.2797 0.666748 11.1063 0.666748C10.9397 0.666748 10.7663 0.733415 10.6397 0.860081L9.41967 2.08008L11.9197 4.58008L13.1397 3.36008Z"
          fill={fill}
        />
        <path
          d="M14.6663 12.6667H1.33301V15.3334H14.6663V12.6667Z"
          fill={fill}
        />
      </svg>
    </IconContainer>
  );
}
