import { createSelector } from "reselect";
import { BoundStore } from "store/_boundStore";

const selectCursorStack = (s: BoundStore) => s.userControls.cursorStack;

export const selectCurrentCursor = createSelector(
  [selectCursorStack],
  (cursorStack) =>
    cursorStack.length ? cursorStack[cursorStack.length - 1].cursor : "auto"
);
