import { hasAtleastOnePrefabGroupIndex } from "features/JobBuilder2/domain";
import { StepItem } from "features/JobBuilder2/step-item/step-item";
import { AssignCharacterGroupsCore } from "features/JobBuilder2/steps/assign-character-groups/core";
import { defaultSubjob, getPipe2Step } from "features/JobBuilder2/types";

export const getAssignCharacterGroupStep = (
  threeDElement: JSX.Element,
  activityZones: typeof defaultSubjob.activityZones
) =>
  getPipe2Step(
    "Assign character groups",
    <StepItem disabledPadding>
      <AssignCharacterGroupsCore
        activityZones={activityZones}
        threeDElement={threeDElement}
      />
    </StepItem>,
    hasAtleastOnePrefabGroupIndex(activityZones)
  );
