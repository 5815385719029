export function RigGimbalIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.81238 6.75H6.56238V9H8.81258L8.81238 6.75ZM7.68738 8.625C7.27378 8.625 6.93738 8.2886 6.93738 7.875C6.93738 7.4614 7.27378 7.125 7.68738 7.125C8.10098 7.125 8.43738 7.4614 8.43738 7.875C8.43738 8.2886 8.10098 8.625 7.68738 8.625Z"
        fill={fill}
      />
      <path
        d="M13.125 23.438H15.75V19.688H13.125V23.438ZM14.4375 20.063C14.7477 20.063 15 20.3153 15 20.6255C15 20.9356 14.7477 21.188 14.4375 21.188C14.1273 21.188 13.875 20.9356 13.875 20.6255C13.875 20.3153 14.1273 20.063 14.4375 20.063ZM14.4375 21.563C14.7477 21.563 15 21.8153 15 22.1255C15 22.4356 14.7477 22.688 14.4375 22.688C14.1273 22.688 13.875 22.4356 13.875 22.1255C13.875 21.8153 14.1273 21.563 14.4375 21.563Z"
        fill={fill}
      />
      <path d="M13.3124 11.625H15.5624V13.3125H13.3124V11.625Z" fill={fill} />
      <path
        d="M16.3124 13.6875H12.5624C12.4591 13.6875 12.3749 13.7715 12.3749 13.875V14.625C12.3749 14.7285 12.4591 14.8125 12.5624 14.8125H16.3124C16.4157 14.8125 16.4999 14.7285 16.4999 14.625V13.875C16.4999 13.7715 16.4157 13.6875 16.3124 13.6875Z"
        fill={fill}
      />
      <path
        d="M12.75 16.5C12.75 16.9335 12.8357 17.2279 12.9347 17.5686C13.002 17.7996 13.0714 18.0384 13.1223 18.3443C13.1242 18.3544 13.125 18.3647 13.125 18.375V18.5625H15.75V18.375C15.75 18.3647 15.7508 18.3544 15.7527 18.3442C15.8034 18.0384 15.873 17.7997 15.9403 17.5685C16.0393 17.2279 16.125 16.9335 16.125 16.5V15.1875H12.75V16.5ZM14.4375 15.5625C15.1613 15.5625 15.75 16.1514 15.75 16.875C15.75 17.5986 15.1612 18.1875 14.4375 18.1875C13.7138 18.1875 13.125 17.5986 13.125 16.875C13.125 16.1514 13.7138 15.5625 14.4375 15.5625Z"
        fill={fill}
      />
      <path d="M13.125 18.9375H15.75V19.3125H13.125V18.9375Z" fill={fill} />
      <path
        d="M15.375 16.875C15.375 17.3927 14.9552 17.8125 14.4375 17.8125C13.9198 17.8125 13.5 17.3927 13.5 16.875C13.5 16.3573 13.9198 15.9375 14.4375 15.9375C14.9552 15.9375 15.375 16.3573 15.375 16.875Z"
        fill={fill}
      />
      <path
        d="M14.625 20.6239C14.625 20.7274 14.541 20.8114 14.4375 20.8114C14.334 20.8114 14.25 20.7274 14.25 20.6239C14.25 20.5204 14.334 20.4364 14.4375 20.4364C14.541 20.4364 14.625 20.5204 14.625 20.6239Z"
        fill={fill}
      />
      <path
        d="M8.43762 9.9375C8.43762 10.041 8.52184 10.125 8.62512 10.125H8.88966C8.84201 10.0312 8.81279 9.92664 8.81279 9.81414V9.375H8.43763L8.43762 9.9375Z"
        fill={fill}
      />
      <path
        d="M8.06238 7.875C8.06238 8.0821 7.89449 8.25 7.68738 8.25C7.48027 8.25 7.31238 8.08211 7.31238 7.875C7.31238 7.66789 7.48027 7.5 7.68738 7.5C7.89449 7.5 8.06238 7.66789 8.06238 7.875Z"
        fill={fill}
      />
      <path
        d="M13.5 2.43214V2.06238C13.5 1.95886 13.4158 1.87488 13.3125 1.87488H12.1875C12.0842 1.87488 12 1.95886 12 2.06238V2.43738H13.4831C13.4893 2.43628 13.4942 2.43347 13.5 2.43214L13.5 2.43214Z"
        fill={fill}
      />
      <path
        d="M9.48957 10.125H11.643C11.8065 10.125 12 9.92171 12 9.74999V5.06239C12 5.03332 12.0067 5.00442 12.0197 4.97856L12.2072 4.60356C12.3908 4.3002 12.4538 3.79746 12.2336 3.39778C12.0281 3.02504 11.622 2.82254 11.0591 2.81238C9.88836 2.81238 9.1875 3.44316 9.1875 4.49988L9.18766 9.81408C9.18766 9.98541 9.32305 10.1249 9.48954 10.1249L9.48957 10.125Z"
        fill={fill}
      />
      <path
        d="M6.01594 6H4.875V9.7496H6.01594C6.11047 9.7496 6.1875 9.67272 6.1875 9.57804V6.17164C6.1875 6.07695 6.11047 6.00008 6.01594 6.00008V6Z"
        fill={fill}
      />
      <path
        d="M3 6.56238V9.18698C3 9.49714 3.25234 9.74948 3.5625 9.74948H4.5V5.99988H3.5625C3.25234 5.99988 3 6.25222 3 6.56238Z"
        fill={fill}
      />
      <path
        d="M8.06238 5.625C8.06238 5.52148 7.97816 5.4375 7.87488 5.4375H7.49988C7.3966 5.4375 7.31238 5.52148 7.31238 5.625V6.375H8.06238V5.625Z"
        fill={fill}
      />
      <path
        d="M14.625 22.1239C14.625 22.2274 14.541 22.3114 14.4375 22.3114C14.334 22.3114 14.25 22.2274 14.25 22.1239C14.25 22.0204 14.334 21.9364 14.4375 21.9364C14.541 21.9364 14.625 22.0204 14.625 22.1239Z"
        fill={fill}
      />
      <path
        d="M8.06238 9.9375V9.375H7.31238V10.3125C7.31238 10.8295 7.73292 11.25 8.24988 11.25H17.4375V10.5H8.62508C8.31492 10.5 8.06258 10.2477 8.06258 9.9375H8.06238Z"
        fill={fill}
      />
      <path
        d="M17.8124 6.1875C17.8124 6.80882 17.3087 7.3125 16.6874 7.3125C16.0661 7.3125 15.5624 6.80882 15.5624 6.1875C15.5624 5.56618 16.0661 5.0625 16.6874 5.0625C17.3087 5.0625 17.8124 5.56618 17.8124 6.1875Z"
        fill={fill}
      />
      <path
        d="M19.2608 2.80516C18.8381 2.69922 18.4581 2.09 18.0195 1.20844C17.748 0.66242 17.5473 0.5625 17.25 0.5625H15.375C15.0776 0.5625 14.877 0.662422 14.6054 1.20844C14.5137 1.39274 14.4462 1.58758 14.381 1.77602C14.2501 2.15398 14.1123 2.53876 13.7864 2.71806C13.775 2.7254 13.7641 2.73267 13.7512 2.73736C13.6848 2.76939 13.6138 2.79603 13.531 2.80993C13.5206 2.81158 13.5103 2.81251 13.4999 2.81251H12.2184C12.3573 2.92462 12.4747 3.05829 12.5621 3.21697C12.8212 3.68743 12.8101 4.33205 12.5353 4.78487L12.375 5.10659V9.74999C12.375 9.88124 12.3294 10.0106 12.257 10.125H20.0624C20.096 10.125 20.1286 10.1293 20.1607 10.1349C20.1768 10.1304 20.1924 10.125 20.21 10.125C20.6549 10.125 20.9999 9.62101 20.9999 9.18749V5.06249C20.9999 3.78109 20.5285 3.16913 19.2607 2.80509L19.2608 2.80516ZM15 1.3125C15 1.1057 15.1682 0.9375 15.375 0.9375H17.25C17.4568 0.9375 17.625 1.1057 17.625 1.3125V1.6875C17.625 1.8943 17.4567 2.0625 17.25 2.0625H15.375C15.1682 2.0625 15 1.8943 15 1.6875V1.3125ZM16.6875 9.7501C14.723 9.7501 13.1251 8.15206 13.1251 6.1877C13.1251 4.22334 14.7231 2.6253 16.6875 2.6253C18.6518 2.6253 20.2499 4.22334 20.2499 6.1877C20.2499 8.15206 18.6518 9.7501 16.6875 9.7501ZM20.0625 9.7501C19.7523 9.7501 19.5 9.49776 19.5 9.1876C19.5 8.87744 19.7523 8.6251 20.0625 8.6251C20.3726 8.6251 20.625 8.87744 20.625 9.1876C20.625 9.49776 20.3726 9.7501 20.0625 9.7501Z"
        fill={fill}
      />
      <path
        d="M16.6876 3.75C15.3436 3.75 14.25 4.84352 14.25 6.1876C14.25 7.53168 15.3435 8.6252 16.6876 8.6252C18.0317 8.6252 19.1252 7.53168 19.1252 6.1876C19.1252 4.84352 18.0317 3.75 16.6876 3.75ZM16.6876 7.6876C15.8606 7.6876 15.1876 7.01464 15.1876 6.1876C15.1876 5.36056 15.8606 4.6876 16.6876 4.6876C17.5146 4.6876 18.1876 5.36056 18.1876 6.1876C18.1876 7.01464 17.5146 7.6876 16.6876 7.6876Z"
        fill={fill}
      />
      <path
        d="M20.25 9.1875C20.25 9.29102 20.166 9.375 20.0625 9.375C19.959 9.375 19.875 9.29102 19.875 9.1875C19.875 9.08398 19.959 9 20.0625 9C20.166 9 20.25 9.08398 20.25 9.1875Z"
        fill={fill}
      />
      <path
        d="M16.6876 3C14.9299 3 13.5 4.42984 13.5 6.1876C13.5 7.94536 14.9298 9.3752 16.6876 9.3752C18.4454 9.3752 19.8752 7.94536 19.8752 6.1876C19.8752 4.42984 18.4454 3 16.6876 3ZM16.6876 9C15.1368 9 13.8752 7.73828 13.8752 6.1876C13.8752 4.63692 15.1369 3.3752 16.6876 3.3752C18.2383 3.3752 19.5 4.63692 19.5 6.1876C19.5 7.73828 18.2383 9 16.6876 9Z"
        fill={fill}
      />
      <path
        d="M17.8124 11.5682L18.1002 12H19.0246L19.3124 11.5682V10.5H17.8124L17.8124 11.5682Z"
        fill={fill}
      />
      <path d="M15.375 1.3125H17.2498V1.6875H15.375V1.3125Z" fill={fill} />
      <path
        d="M20.062 10.5H19.687V11.25H20.062C20.1653 11.25 20.2495 11.166 20.2495 11.0625V10.6875C20.2495 10.584 20.1653 10.5 20.062 10.5Z"
        fill={fill}
      />
      <path
        d="M18.1876 12.5624C18.1876 12.6659 18.2718 12.7499 18.3751 12.7499H18.7501C18.8534 12.7499 18.9376 12.6659 18.9376 12.5624V12.3749H18.1876V12.5624Z"
        fill={fill}
      />
    </svg>
  );
}
