import { CircleFilledIcon, CircleIcon } from "assets/icons";
import {
  PageIndicatorWrapper,
  ButtonsWrapper,
  PageButton,
  ActionButton,
  NextPageWrapper,
} from "./PageIndicator.styled";

export function PageIndicator({
  currentPage,
  numberOfPages,
  onPageChangeCallback,
  onCloseDialog,
}: {
  currentPage: number;
  numberOfPages: number;
  onPageChangeCallback: (newPage: number) => void;
  onCloseDialog?: () => void;
}) {
  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage === numberOfPages - 1;

  const disabledPageButton = (
    <PageButton disabled>
      <CircleFilledIcon fill="#929292" />
    </PageButton>
  );

  const pageButton = (page: number) => (
    <PageButton onClick={() => onPageChangeCallback(page)}>
      <CircleIcon fill="#929292" />
    </PageButton>
  );

  const pages = Array.from({ length: numberOfPages }, (_, i) =>
    i === currentPage ? disabledPageButton : pageButton(i)
  );

  return (
    <PageIndicatorWrapper>
      <div aria-hidden={isFirstPage}>
        <ActionButton
          color="warning"
          disabled={isFirstPage}
          variant="outlined"
          onClick={() => onPageChangeCallback(currentPage - 1)}
        >
          Previous Page
        </ActionButton>
      </div>

      <ButtonsWrapper>{pages}</ButtonsWrapper>

      {!isLastPage && (
        <NextPageWrapper>
          <ActionButton
            color="warning"
            variant="contained"
            onClick={() => onPageChangeCallback(currentPage + 1)}
          >
            Next Page
          </ActionButton>
        </NextPageWrapper>
      )}
      {isLastPage && (
        <NextPageWrapper>
          <ActionButton
            color="warning"
            variant="outlined"
            onClick={onCloseDialog}
          >
            Close
          </ActionButton>
        </NextPageWrapper>
      )}
    </PageIndicatorWrapper>
  );
}
