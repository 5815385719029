import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";

export type IPasswordTextFieldProps = TextFieldProps;

function PasswordTextField(props: IPasswordTextFieldProps) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={"visible"}
              size="large"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      id="password"
      label={"Enter Password"}
      type={showPassword ? "text" : "password"}
      variant="standard"
      {...props}
    />
  );
}

export { PasswordTextField };
