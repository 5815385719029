import { Box } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";
import { SectionItemLabel } from "features/JobBuilder/types";

export const Container = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
`;

export const MainInfoContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const Label = styled.p<SectionItemLabel>`
  padding-left: 8px;
  font-weight: ${({ variant }) => (variant === "section" ? 600 : 400)};
  font-size: 14px;
  line-height: 16px;
  color: ${({ selected }) => (selected ? colors.orange : colors.grey70)};
`;

export const AdditionalInfo = styled.p<{
  selected: boolean;
  $percentageError?: boolean;
}>`
  font-size: 14px;
  line-height: 16px;
  padding-right: 8px;
  display: inline;
  line-clamp: 1;
  white-space: nowrap;
  color: ${({ selected, $percentageError }) =>
    $percentageError ? colors.red : selected ? colors.orange : colors.grey70};
`;
