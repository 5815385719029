export function StageViewIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="20"
      style={style}
      viewBox="0 0 18 20"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99 17.005L1.62 11.275L0 12.535L9 19.535L18 12.535L16.37 11.265L8.99 17.005ZM9 14.465L16.36 8.73497L18 7.46497L9 0.464966L0 7.46497L1.63 8.73497L9 14.465ZM9 2.99497L14.74 7.46497L9 11.935L3.26 7.46497L9 2.99497Z"
        fill={fill}
      />
    </svg>
  );
}
