import { useMemo } from "react";
import cameraFocalLengthImage from "assets/diagrams/camera-focal-length-image.svg";
import cameraPitchImage from "assets/diagrams/camera-pitch.svg";
import resolutionHeightImage from "assets/diagrams/camera-resolution-height.svg";
import resolutionWidthImage from "assets/diagrams/camera-resolution-width.svg";
import cameraRollImage from "assets/diagrams/camera-roll.svg";
import cameraSensorWidthImage from "assets/diagrams/camera-sensor-width-image.png";
import cameraXAxisImage from "assets/diagrams/camera-x.svg";
import cameraYAxisImage from "assets/diagrams/camera-y.svg";
import cameraYawImage from "assets/diagrams/camera-yaw.svg";
import cameraZDepthImage from "assets/diagrams/camera-z-depth.svg";
import wavelenght from "assets/diagrams/rigs-wavelength.svg";
import { BoxOfInfos } from "components/BoxOfInfos/BoxOfInfos";
import { Collapsible } from "components/Collapsible";
import { DropdownWithTitle } from "components/DropdownWithTitle";
import { IntegerSelector } from "components/IntegerSelector";
import {
  Container,
  Diagram,
} from "components/LightTypeSelector/LightTypeSelector.styled";
import { SelectorsPicker } from "components/SelectorsPicker";
import { exceedLimit } from "components/SelectorsPicker/utils";
import { TooltipWithLinkContent } from "components/TooltipWithLinkContent";
import { Messages } from "constants/messages";
import {
  lorWithTitleUI,
  defaultCamera,
  presetLocationCameraFocalLength,
  LoRType,
} from "domain/Human";
import { getFOVsStrings } from "features/JobBuilder/CamerasAndLightsPreview/utils/getFov";
import { changeEventValue } from "features/JobBuilder/utils";

type CameraCoreProps = {
  rigType: string;
  onUpdateLocal: (fn: (draft: typeof defaultCamera) => void) => void;
  value: typeof defaultCamera;
  wavelengthOptions: string[];
  hasNIR: boolean;
  showNormalDistribution?: boolean;
};

export function CameraCoreProps(props: CameraCoreProps) {
  const {
    rigType,
    onUpdateLocal,
    value,
    wavelengthOptions,
    hasNIR,
    showNormalDistribution = true,
  } = props;

  const resolutionMin = "512";
  const resolutionMax = "4096";

  const resolutionError = (resolution: string) =>
    exceedLimit(resolution, Number(resolutionMin), Number(resolutionMax)) ||
    Number.isNaN(Number(resolution));

  const resolutionErrorMessage = `Enter a number between ${resolutionMin} and ${resolutionMax}`;

  const onUpdateWavelength = (v: string) =>
    onUpdateLocal((d) => {
      d.wavelength = v;
    });
  const onUpdateResolutionW = (v: string) =>
    onUpdateLocal((d) => {
      d.resolutionW = v;
    });
  const onUpdateResolutionH = (v: string) =>
    onUpdateLocal((d) => {
      d.resolutionH = v;
    });
  const onUpdateX = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.x = v;
    });
  const onUpdateY = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.y = v;
    });
  const onUpdateZ = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.z = v;
    });
  const onUpdatePitch = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.pitch = v;
    });
  const onUpdateYaw = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.yaw = v;
    });
  const onUpdateRoll = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.roll = v;
    });
  const onUpdateSensorWidth = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.sensorWidth = v;
    });
  const onUpdateFocalLength = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.focalLength = v;
    });

  const { verticalFOVString, horizontalFOVString } = useMemo(() => {
    const { resolutionW, resolutionH, sensorWidth, focalLength } = value;

    // convert sensor width and focal length from input to LoR objects for the spec
    const sensorWidthLoR = {
      type: sensorWidth.type, // list or range
      values:
        sensorWidth.type === "list"
          ? sensorWidth.listValues.split(",").map((v) => +v)
          : { min: +sensorWidth.min, max: +sensorWidth.max },
    };
    const focalLengthLoR = {
      type: focalLength.type,
      values:
        focalLength.type === "list"
          ? focalLength.listValues.split(",").map((v) => +v)
          : { min: +focalLength.min, max: +focalLength.max },
    };

    const FOVS = getFOVsStrings(
      +resolutionW,
      +resolutionH,
      sensorWidthLoR,
      focalLengthLoR
    );

    return {
      horizontalFOVString: FOVS.horizontalFOV,
      verticalFOVString: FOVS.verticalFOV,
    };
  }, [value]);

  const boxInformation = [
    { label: "Equivalent Vertical Field of View:", value: verticalFOVString },
    {
      label: "Equivalent Horizontal Field of View:",
      value: horizontalFOVString,
    },
  ];

  return (
    <>
      <IntegerSelector
        error={resolutionError(value.resolutionW)}
        errorMessage={resolutionErrorMessage}
        icon={"ResolutionWidthIcon"}
        imgSrc={resolutionWidthImage}
        limits={{
          max: resolutionMax,
          min: resolutionMin,
        }}
        title={"Resolution Width (pixels)"}
        value={value.resolutionW}
        onUpdate={changeEventValue(onUpdateResolutionW)}
      />

      <IntegerSelector
        error={resolutionError(value.resolutionH)}
        errorMessage={resolutionErrorMessage}
        icon={"ResolutionHeightIcon"}
        imgSrc={resolutionHeightImage}
        limits={{
          max: resolutionMax,
          min: resolutionMin,
        }}
        title={"Resolution Height (pixels)"}
        value={value.resolutionH}
        onUpdate={changeEventValue(onUpdateResolutionH)}
      />
      {[
        lorWithTitleUI(
          "Focal length",
          value.focalLength,
          rigType === "preset_location"
            ? presetLocationCameraFocalLength
            : defaultCamera.focalLength,
          onUpdateFocalLength,
          "mm",
          "FocalLengthIcon",
          cameraFocalLengthImage
        ),
        lorWithTitleUI(
          "Sensor Width",
          value.sensorWidth,
          defaultCamera.sensorWidth,
          onUpdateSensorWidth,
          "mm",
          "SensorWidthIcon",
          cameraSensorWidthImage
        ),
      ].map((l, i) => (
        <SelectorsPicker
          key={i}
          icon={l.icon ?? "SensorWidthIcon"}
          imgSrc={l.imgSrc ?? ""}
          limits={l.limits}
          lorValue={l.value}
          showNormalDistribution={showNormalDistribution}
          title={l.title}
          unit={l.unitLabel}
          onUpdate={l.updateFn}
        />
      ))}
      <BoxOfInfos information={boxInformation} />

      {hasNIR && (
        <Container>
          <DropdownWithTitle
            extraInfo={
              <TooltipWithLinkContent
                linkLabel="Learn More"
                linkTo="https://docs.synthesis.ai/inputs.html#wavelengthNIR"
                textContent={Messages.cameraWaveLenghtInfo}
              />
            }
            icon="WavesIcon"
            label="Wavelength"
            options={wavelengthOptions}
            value={value.wavelength}
            onUpdate={onUpdateWavelength}
          />
          <Diagram src={wavelenght} />
        </Container>
      )}

      <Collapsible
        collapsedByDefault
        label={"Relative Location"}
        tag="Set Relative Camera Position"
      >
        <div>
          {[
            lorWithTitleUI(
              "Z - Depth",
              value.z,
              defaultCamera.z,
              onUpdateZ,
              "meters",
              "ZDepthIcon",
              cameraZDepthImage
            ),
            lorWithTitleUI(
              "Pitch",
              value.pitch,
              defaultCamera.pitch,
              onUpdatePitch,
              "degrees",
              "PitchIcon",
              cameraPitchImage
            ),
            lorWithTitleUI(
              "Yaw",
              value.yaw,
              defaultCamera.yaw,
              onUpdateYaw,
              "degrees",
              "YawIcon",
              cameraYawImage
            ),
            lorWithTitleUI(
              "Roll",
              value.roll,
              defaultCamera.roll,
              onUpdateRoll,
              "degrees",
              "RollIcon",
              cameraRollImage
            ),
            lorWithTitleUI(
              "X - Axis",
              value.x,
              defaultCamera.x,
              onUpdateX,
              "meters",
              "XAxisIcon",
              cameraXAxisImage
            ),
            lorWithTitleUI(
              "Y - Axis",
              value.y,
              defaultCamera.y,
              onUpdateY,
              "meters",
              "YAxisIcon",
              cameraYAxisImage
            ),
          ].map((l, i) => (
            <SelectorsPicker
              key={i}
              icon={l.icon ?? "SensorWidthIcon"}
              imgSrc={l.imgSrc ?? ""}
              limits={l.limits}
              lorValue={l.value}
              showNormalDistribution={showNormalDistribution}
              title={l.title}
              unit={l.unitLabel}
              onUpdate={l.updateFn}
            />
          ))}
        </div>
      </Collapsible>
    </>
  );
}
