import { useCallback, useMemo, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import produce from "immer";
import { CircledAddIcon, WarningIcon } from "assets/icons";
import { colors } from "components/App/theme";
import { DeleteConfirmationDialog } from "components/dialogs/DeleteConfirmationDialog";
import { StageElementDialog } from "components/dialogs/StageElementDialog";
import { MessageWithIcon } from "components/MessageWithIcon";
import { ProTip } from "components/ProTip";
import { RightAsidePreviewFull } from "components/RightAsidePreviewFull";
import { Messages } from "constants/messages";
import {
  defaultCamera,
  defaultCameraWithName,
  defaultLightWithName,
  defaultLight,
  defaultRig,
  vehicleRigPresetLocations,
} from "domain/Human";
import { PAGE_PADDING } from "features/constants";
import {
  selectActiveSubjobCameraAndLightNamesForRig,
  selectActiveSubjobRigs,
  selectActiveSubjobSelectedRig,
  selectSelectedRigIndex,
} from "features/JobBuilder/store";
import { RigLocation } from "features/JobBuilderShared/Rig/rig-location";
import { UV } from "features/JobBuilderShared/Rig/uv";
import { useBoundStore } from "store/_boundStore";
import { IconsType } from "types/main";
import { CamerasAndLightsPreview } from "./CamerasAndLightsPreview/CamerasAndLightsPreview";
import { CamerasAndLightsTabs } from "./CamerasAndLightsTabs";
import { CommonlyUsed } from "./commonly-used";
import cameraPic from "./commonly-used-jsons/camera.jpg";
import lightPic from "./commonly-used-jsons/light.jpg";
import orbitingCameraRig from "./commonly-used-jsons/orbital_camera.json";
import orbitingLightRig from "./commonly-used-jsons/orbital_light.json";
import { convertRigStateToJson } from "./form-to-input-json";
import { JsonManagerNewUI } from "./json-manager-new-ui";
import { convertRig } from "./json-to-form";
import { DeepPartial, JsonHuman, JsonRig } from "./json-types";
import { Page } from "./layout";
import { IconizedAdditionalInfo } from "./LeftSidebar/IconizedAdditionalInfo";
import {
  InformationalIconsContainer,
  RigConfigurationContainer,
  Label,
  PresetHeaderContainer,
  Caption,
  ActionButtonsContainer,
  ActionButton,
  WarningContainer,
} from "./rigs.styled";
import { RigTypeSelector } from "./RigTypeSelector";
import { SelectorWIthTitle } from "./SelectorWIthTitle";
import {
  getRigTotalCameras,
  getRigTotalLights,
  getNewCameraNLightName,
} from "./utils";

const commonlyUsed = [
  {
    src: lightPic,
    label: "Orbital Directional Light",
    json: orbitingLightRig as DeepPartial<JsonRig>,
  },
  {
    src: cameraPic,
    label: "Wide Orbital Camera",
    json: orbitingCameraRig as DeepPartial<JsonRig>,
  },
];

export function JobBuilderRigs() {
  const {
    addRig: onAddRig,
    onUpdateRigs,
    setSelectedRigIndex,
    setSelectedHumanObjectPart: selectHumanObjectPart,
    setSubjobs,
  } = useBoundStore.getState().jobBuilder;

  const rigIndex = useBoundStore(selectSelectedRigIndex);
  const value = useBoundStore(selectActiveSubjobSelectedRig);
  const allRigsData = useBoundStore(selectActiveSubjobRigs);

  const cameraAndLightNamesForRig = useBoundStore(
    selectActiveSubjobCameraAndLightNamesForRig
  );

  const onCommonlyUsedRigPick = (json: DeepPartial<JsonRig>) => {
    setSubjobs(
      produce((d) => {
        allRigsData.push(convertRig(json, { ...defaultRig }));
      })
    );
    setSelectedRigIndex(allRigsData.length);
  };

  const updateRigs = useCallback(
    (fn: (a: (typeof defaultRig)[]) => void) => {
      onUpdateRigs(produce(allRigsData, fn));
    },
    [allRigsData, onUpdateRigs]
  );

  const onUpdate = useCallback(
    (v: typeof defaultRig) =>
      updateRigs((d) => {
        d[rigIndex] = v;
      }),
    [rigIndex, updateRigs]
  );

  const [isDeletingIndex, setIsDeletingIndex] = useState<false | number>(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [rigPartDeletionType, setRigPartDeletionType] = useState<
    null | "camera" | "light"
  >(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const camerasLimitReached = value?.cameras?.length > 4;
  const lightsLimitReached = value?.lights?.length > 2;

  const onCancelCallback = () => {
    setIsDeletingIndex(false);
    setRigPartDeletionType(null);
  };

  const onUpdateLocal = useCallback(
    (fn: (draft: typeof defaultRig) => void) => {
      onUpdate(produce(value, fn));
    },
    [onUpdate, value]
  );

  const onUpdateType = (v: string) =>
    onUpdateLocal((d) => {
      d.type = v;
    });

  const onUpdatePresetName = (v: string) =>
    onUpdateLocal((d) => {
      d.presetName = v;
    });

  const onDeleteCamera = (i: number) =>
    onUpdateLocal((d) => {
      d.cameras.splice(i, 1);
    });

  const onAddCamera = (): any =>
    onUpdateLocal((d) => {
      const newCameraName = getNewCameraNLightName(
        d.cameras,
        `Rig_${rigIndex + 1}_Camera`
      );
      d.cameras.push({
        ...defaultCameraWithName(newCameraName),
      });
      setCurrentTab(d.cameras.length - 1);
    });

  const onDeleteLight = (i: number) =>
    onUpdateLocal((d) => {
      d.lights.splice(i, 1);
    });

  const onAddLight = () =>
    onUpdateLocal((d) => {
      const newLightName = getNewCameraNLightName(d.lights, "Light");
      d.lights.push({
        ...defaultLightWithName(newLightName),
      });
      const lastLightIndex = Math.max(
        0,
        d.cameras.length + d.lights.length - 1
      );
      setCurrentTab(lastLightIndex);
    });

  const onUpdateCamera = (i: number) => (v: typeof defaultCamera) =>
    onUpdateLocal((d) => {
      d.cameras[i] = v;
    });

  const onUpdateCameraName = (i: number, newName: string) =>
    onUpdateLocal((d) => {
      d.cameras[i].name = newName;
    });

  const onUpdateLight = (i: number) => (v: typeof defaultLight) =>
    onUpdateLocal((d) => {
      d.lights[i] = v;
    });

  const onUpdateLightName = (i: number, newName: string) =>
    onUpdateLocal((d) => {
      d.lights[i].name = newName;
    });

  const onConfirmDeletionCallback = (i: number) => {
    console.log({ rigPartDeletionType, isDeletingIndex, i });
    rigPartDeletionType === "camera" ? onDeleteCamera(i) : onDeleteLight(i);
    setIsDeletingIndex(false);
    setRigPartDeletionType(null);
  };

  const { setErrorMessage, setSuccessMessage } =
    useBoundStore.getState().message;

  const onIndecesReceived = (indices: number[], humansArr: JsonHuman[]) => {
    try {
      const jsonRig = humansArr[indices[0]].camera_and_light_rigs[indices[1]];
      onUpdate(convertRig(jsonRig, value));
      setSuccessMessage("updated rig values");
    } catch (error) {
      setErrorMessage("can't find rig object");
    }
  };

  const textContent = `Are you sure you want to delete this ${rigPartDeletionType}? If you delete this you will lose the data.`;

  const commonlyUsedEl = (
    <CommonlyUsed
      items={commonlyUsed}
      title="Add Commonly Used Rigs (Camera/Light Combinations)"
      onPick={onCommonlyUsedRigPick}
    />
  );
  const titleAdditionalInfo = (
    <InformationalIconsContainer>
      <IconizedAdditionalInfo
        iconsDefaultColor={colors.grey70}
        info={[
          {
            icon: "CameraIcon" as IconsType,
            label: getRigTotalCameras(cameraAndLightNamesForRig).toString(),
          },
          {
            icon: "RectLightIcon" as IconsType,
            label: (
              getRigTotalLights(cameraAndLightNamesForRig) ?? 0
            ).toString(),
          },
        ]}
      />
    </InformationalIconsContainer>
  );

  const onDoneJson = (json: any) => {
    const updated = convertRig(json, value);
    onUpdate(updated);
  };
  const jsonValue = useMemo(() => convertRigStateToJson(value), [value]);

  const noCamerasAndLights =
    value.cameras.length === 0 && value.lights.length === 0;

  const noCamerasAndLightsWarningText = "";

  const openStageElementsModalCallback = () => setIsModalOpen(true);

  const presetLocationsOptions = vehicleRigPresetLocations.map(
    (option) => option.label
  );

  const onUpdatePresetNameFromDropdown = (value: string) => {
    const presetNameValue = vehicleRigPresetLocations.find(
      (option) => option.label === value
    )?.value;
    onUpdatePresetName(presetNameValue ?? vehicleRigPresetLocations[0].value);
  };

  const dropdownPresetNameValue =
    vehicleRigPresetLocations.find(
      (option) => option.value === value.presetName
    )?.label ?? vehicleRigPresetLocations[0].label;

  const center = (
    <Box component="div" p={PAGE_PADDING}>
      <JsonManagerNewUI
        commonlyUsedEl={commonlyUsedEl}
        id="rigs"
        jsonValue={jsonValue}
        title={`Rig ${rigIndex + 1}`}
        titleAddIn={titleAdditionalInfo}
        titleIcon="RigIcon"
        unknownIndicesKeys={["Human", "Rig"]}
        onClickAddButton={onAddRig}
        onDoneEditJson={onDoneJson}
        onIndecesReceived={onIndecesReceived}
      >
        {isDeletingIndex !== false && (
          <DeleteConfirmationDialog
            textContent={textContent}
            onCancel={onCancelCallback}
            onConfirm={() => onConfirmDeletionCallback(isDeletingIndex)}
          />
        )}
        <RigConfigurationContainer>
          <RigTypeSelector
            value={value.type}
            onEnvironmentClick={() => selectHumanObjectPart("hdri")}
            onOpenModalCallback={openStageElementsModalCallback}
            onUpdateType={onUpdateType}
          />

          <ProTip
            label={Messages.rigProTip}
            onClickLearnMore={openStageElementsModalCallback}
          />
          <StageElementDialog
            isModalOpen={isModalOpen}
            setIsModalOpenCallback={setIsModalOpen}
          />

          {value.type === "preset_location" && (
            <>
              <PresetHeaderContainer>
                <SelectorWIthTitle
                  label={"Preset Name"}
                  options={presetLocationsOptions}
                  value={dropdownPresetNameValue}
                  onChange={onUpdatePresetNameFromDropdown}
                />
                <Caption>{Messages.presetNameCaption}</Caption>
              </PresetHeaderContainer>

              <Label>Preset Transform</Label>
              <UV u={value.u} v={value.v} onUpdateLocal={onUpdateLocal} />
            </>
          )}
          <RigLocation
            type={value.type}
            value={value}
            onUpdateLocal={onUpdateLocal}
          />
          <ActionButtonsContainer>
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: colors.grey70,
                    "& .MuiTooltip-arrow": {
                      color: colors.grey70,
                    },
                  },
                },
              }}
              title={
                camerasLimitReached ? "Only 5 Cameras allowed per Rig" : ""
              }
            >
              <div>
                <ActionButton
                  disabled={camerasLimitReached}
                  onClick={onAddCamera}
                >
                  <CircledAddIcon
                    style={{
                      color: camerasLimitReached ? colors.grey50 : colors.white,
                      height: "24px",
                      width: "24px",
                      marginRight: "4px",
                    }}
                  />
                  Add Camera in Rig
                </ActionButton>
              </div>
            </Tooltip>
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: colors.grey70,
                    "& .MuiTooltip-arrow": {
                      color: colors.grey70,
                    },
                  },
                },
              }}
              title={lightsLimitReached ? "Only 3 Lights allowed per Rig" : ""}
            >
              <div>
                <ActionButton
                  disabled={lightsLimitReached}
                  onClick={onAddLight}
                >
                  <CircledAddIcon
                    style={{
                      color: lightsLimitReached ? colors.grey50 : colors.white,
                      height: "24px",
                      width: "24px",
                      marginRight: "4px",
                    }}
                  />
                  Add Light in Rig
                </ActionButton>
              </div>
            </Tooltip>
          </ActionButtonsContainer>
        </RigConfigurationContainer>

        {noCamerasAndLights ? (
          <WarningContainer>
            <MessageWithIcon
              icon={<WarningIcon style={{ height: "64px" }} />}
              text={noCamerasAndLightsWarningText}
              title="Add Camera and Lights"
            />
          </WarningContainer>
        ) : (
          <CamerasAndLightsTabs
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            setIsDeletingIndex={setIsDeletingIndex}
            setRigPartDeletionType={setRigPartDeletionType}
            value={value}
            onUpdateCamera={onUpdateCamera}
            onUpdateCameraName={onUpdateCameraName}
            onUpdateLight={onUpdateLight}
            onUpdateLightName={onUpdateLightName}
          />
        )}
      </JsonManagerNewUI>
    </Box>
  );
  const rightAside = (
    <RightAsidePreviewFull previewTitle="Cameras & Lights Preview">
      <CamerasAndLightsPreview data={allRigsData} />
    </RightAsidePreviewFull>
  );
  return <Page fixedRightSide center={center} rightAside={rightAside} />;
}
