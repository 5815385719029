import { useCallback } from "react";
import { SelectorsPicker } from "components/SelectorsPicker";
import { LoRType } from "domain/Human";
import { defaultRig } from "features/JobBuilder2/types";
import cameraHeight from "./assets/cameras-resolution-height.svg";
import cameraWidth from "./assets/cameras-resolution-width.svg";

export function UV({
  u,
  v,
  onUpdateLocal,
  limits = defaultRig,
  showNormalDistribution = true,
}: {
  u: LoRType;
  v: LoRType;
  onUpdateLocal: (fn: (draft: typeof defaultRig) => void) => void;
  limits?: typeof defaultRig;
  showNormalDistribution?: boolean;
}) {
  const onUpdateU = useCallback(
    (v: LoRType) =>
      onUpdateLocal((d) => {
        d.u = v;
      }),
    [onUpdateLocal]
  );
  const onUpdateV = useCallback(
    (v: LoRType) =>
      onUpdateLocal((d) => {
        d.v = v;
      }),
    [onUpdateLocal]
  );
  return (
    <>
      <SelectorsPicker
        icon={"SensorWidthIcon"}
        imgSrc={cameraWidth}
        limits={limits.u}
        lorValue={u}
        showNormalDistribution={showNormalDistribution}
        title={"U"}
        unit=""
        onUpdate={onUpdateU}
      />
      <SelectorsPicker
        icon={"SensorWidthIcon"}
        imgSrc={cameraHeight}
        limits={limits.v}
        lorValue={v}
        showNormalDistribution={showNormalDistribution}
        title={"V"}
        unit=""
        onUpdate={onUpdateV}
      />
    </>
  );
}
