import { PrefabGroupMap } from "domain/Prefabs";
import { TransformedLocation } from "features/JobBuilder2/steps/threeDimensional/utils/Location";
import { Activity } from "features/JobBuilder2/types";
import { SliceType } from "store/_boundStore";
import { createActions } from "./actions";

export * from "./selectors";

export type State = {
  activities: Activity[];
  locations: TransformedLocation[];
  prefabGroups: PrefabGroupMap;
};

type Actions = {
  setStoreValues: (values: State) => void;
};

export type CatalogDataSlice = State & Actions;

const initialState: State = {
  activities: [],
  locations: [],
  prefabGroups: {},
};

export const createCatalogDataSlice: SliceType<CatalogDataSlice> = (set) => ({
  ...initialState,
  ...createActions(set),
});
