import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { URLParams } from "constants/params";
import { Routes } from "constants/routes";
import { useURLParam } from "hooks/useURLParam";
import { useBoundStore } from "store/_boundStore";

function AccountVerified() {
  const navigate = useNavigate();
  const { setSuccessMessage } = useBoundStore.getState().message;
  const [msg, type, org] = useURLParam(
    URLParams.msg,
    URLParams.msgType,
    URLParams.org
  );
  useEffect(() => {
    const goToLoginAndShowSuccessMessage = () => {
      if (msg && type === "success") {
        setSuccessMessage(msg);
      }
      navigate(Routes.LOGIN);
    };
    goToLoginAndShowSuccessMessage();
  }, [msg, type, org, navigate, setSuccessMessage]);

  return (
    <>
      <p>Account verified</p>
    </>
  );
}

export { AccountVerified };
