export function UploadIcon({
  style = { height: "16px" },
  fill = "currentColor",
}: {
  style?: React.CSSProperties;
  fill?: string;
} = {}) {
  return (
    <svg
      fill="none"
      height="16"
      style={style}
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9993 10.5V12.5H3.99935V10.5H2.66602V12.5C2.66602 13.2334 3.26602 13.8334 3.99935 13.8334H11.9993C12.7327 13.8334 13.3327 13.2334 13.3327 12.5V10.5H11.9993ZM4.66602 6.50002L5.60602 7.44002L7.33268 5.72002V11.1667H8.66602V5.72002L10.3927 7.44002L11.3327 6.50002L7.99935 3.16669L4.66602 6.50002Z"
        fill={fill}
      />
    </svg>
  );
}
