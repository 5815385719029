import { useMemo } from "react";
import ColorSeedDiagram from "assets/diagrams/hair-color-seed.svg";
import EthnicityMatchedOnlyDiagram from "assets/diagrams/hair-ethinicity-matched.svg";
import SexMatchedOnlyDiagram from "assets/diagrams/hair-relative-density-sex-matched-only.svg";
import RelativeDensityDiagram from "assets/diagrams/hair-relative-density.svg";
import RelativeLengthDiagram from "assets/diagrams/hair-relative-length.svg";
import { HairIcon } from "assets/icons";
import { BooleanSelector } from "components/BooleanSelector";
import { ProTip } from "components/ProTip";
import { RightAsidePreviewFull } from "components/RightAsidePreviewFull";
import { PreviewContent } from "components/RightAsidePreviewFull/PreviewContent";
import { SelectorsPicker } from "components/SelectorsPicker";
import { PUBLIC_S3_BUCKET } from "constants/constants";
import { Messages } from "constants/messages";
import { defaultHair } from "domain/Human";
import { EmptyState } from "features/JobBuilder/empty-state";
import { convertHairStateToJson } from "features/JobBuilder/form-to-input-json";
import { getHair, getHumanObject } from "features/JobBuilder/json-extracter";
import { JsonManagerNewUI } from "features/JobBuilder/json-manager-new-ui";
import { convertHairs } from "features/JobBuilder/json-to-form";
import { DeepPartial, JsonHuman } from "features/JobBuilder/json-types";
import { ContentCard, Page } from "features/JobBuilder/layout";
import { MultiOptionImageGrid } from "features/JobBuilder/MultiOptionImageGrid";
import {
  hairColorSelector,
  hairStyleSelector,
} from "features/JobBuilder/store";
import { useDistPage } from "features/JobBuilder/useDistPage";
import {
  useImageSelection,
  useSelectionOutcomeCount,
} from "features/JobBuilder/useImageSelection";
import { changeCheckEventValue } from "features/JobBuilder/utils";
import { useBoundStore } from "store/_boundStore";

const id = "hair";
export function JobBuilderHair() {
  const {
    value,
    selectedGroupIndex,
    onUpdate,
    onAdd,
    numberOfImages,
    isSummingTo100,
    onUpdatePart,
    onUpdatePercent,
    toggleDistListItem,
  } = useDistPage(id);
  const { setErrorMessage, setSuccessMessage } =
    useBoundStore.getState().message;

  const { hairStyles, hairColors } = useBoundStore((state) => {
    return {
      hairStyles: hairStyleSelector(state),
      hairColors: hairColorSelector(state),
    };
  });
  const selectedHairGroup = value[selectedGroupIndex];
  const onUpdateRelativeLength = onUpdatePart("relative_length");
  const onUpdateRelativeDensity = onUpdatePart("relative_density");
  const onUpdateColorSeed = onUpdatePart("color_seed");
  const onUpdateSexMatchedOnly = onUpdatePart("sex_matched_only");
  const onUpdateEthnicityMatchedOnly = onUpdatePart("ethnicity_matched_only");
  const styleUIState = useImageSelection(
    selectedHairGroup ? selectedHairGroup.style : [],
    hairStyles,
    (hs) => `${PUBLIC_S3_BUCKET}/pl1/hair.style/${hs}.png`,
    onUpdatePart("style"),
    "Style",
    "style",
    "StyleIcon",
    toggleDistListItem("style", hairStyles)
  );
  const colorUIState = useImageSelection(
    selectedHairGroup ? selectedHairGroup.color : [],
    hairColors,
    (hc) => `${PUBLIC_S3_BUCKET}/pl1/hair.color/${hc}.png`,
    onUpdatePart("style"),
    "Color",
    "color",
    "ColorIcon",
    toggleDistListItem("color", hairColors)
  );

  const outcome = useSelectionOutcomeCount([styleUIState, colorUIState]);

  const onIndecesReceived = (
    indices: number[],
    humansArr: DeepPartial<JsonHuman>[]
  ) => {
    try {
      onUpdate(
        convertHairs(
          getHair(getHumanObject(humansArr, indices[0])),
          value,
          hairStyles,
          hairColors
        )
      );
      setSuccessMessage("updated hair");
    } catch (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
      }
    }
  };

  // TODO: Type this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDoneJson = (json: any) => {
    const updated = convertHairs(json, value, hairStyles, hairColors);
    onUpdate(updated);
  };
  const jsonValue = useMemo(() => convertHairStateToJson(value), [value]);

  const isEmptyState = !selectedHairGroup || !hairStyles || !hairColors;
  const center = (
    <JsonManagerNewUI
      emptyState={
        isEmptyState ? (
          <EmptyState
            description="Hair controls the hair variations of the character models. Default hair variations are applied, some sample results are shown below:"
            folder="hair"
            icon={<HairIcon style={{ width: 48, height: 48 }} />}
            title="Default hair settings applied"
          />
        ) : null
      }
      groupIndex={selectedGroupIndex + 1}
      groupPercentage={selectedHairGroup?.percent}
      id={`hair-${selectedGroupIndex + 1}`}
      isSummingTo100={isSummingTo100}
      jsonValue={jsonValue}
      numberOfImages={numberOfImages}
      parentTitle="Facial Attributes"
      parentTitleIcon="FacialAttributesIcon"
      title={`Hair`}
      titleCount={outcome}
      titleIcon="HairIcon"
      unknownIndicesKeys={["Human"]}
      onClickAddButton={onAdd}
      onDoneEditJson={onDoneJson}
      onIndecesReceived={onIndecesReceived}
      onUpdatePercent={onUpdatePercent}
    >
      <ContentCard>
        <ProTip label={Messages.hairProTip} />
        <MultiOptionImageGrid {...styleUIState} />
      </ContentCard>

      <ContentCard>
        <MultiOptionImageGrid {...colorUIState} />
      </ContentCard>
      <ContentCard style={{ padding: "8px" }}>
        <SelectorsPicker
          noSpacing
          icon={"ColorSeedIcon"}
          imgSrc={ColorSeedDiagram}
          limits={defaultHair.color_seed}
          lorValue={selectedHairGroup?.color_seed}
          title="Color Seed"
          unit={"degree(s)"}
          onUpdate={onUpdateColorSeed}
        />
      </ContentCard>
      <ContentCard style={{ padding: "8px" }}>
        <SelectorsPicker
          noSpacing
          icon={"RelativeLengthIcon"}
          imgSrc={RelativeLengthDiagram}
          limits={defaultHair.relative_length}
          lorValue={selectedHairGroup?.relative_length}
          title="Relative Length"
          unit={"degree(s)"}
          onUpdate={onUpdateRelativeLength}
        />
      </ContentCard>
      <ContentCard style={{ padding: "8px" }}>
        <SelectorsPicker
          noSpacing
          icon={"RelativeDensityIcon"}
          imgSrc={RelativeDensityDiagram}
          limits={defaultHair.relative_density}
          lorValue={selectedHairGroup?.relative_density}
          title="Relative Density"
          unit={"degree(s)"}
          onUpdate={onUpdateRelativeDensity}
        />
      </ContentCard>

      <ContentCard style={{ padding: "8px" }}>
        <BooleanSelector
          noSpacing
          description={Messages.sexMatchedDescriptionForHair}
          icon={"SexMatchedOnlyIcon"}
          imgSrc={SexMatchedOnlyDiagram}
          label={"Enabled"}
          title={"Sex Matched Only"}
          value={selectedHairGroup?.sex_matched_only}
          onUpdate={changeCheckEventValue(onUpdateSexMatchedOnly)}
        />
      </ContentCard>

      <ContentCard style={{ padding: "8px" }}>
        <BooleanSelector
          noSpacing
          description={Messages.ethnicityMatchedDescription}
          icon={"EthnicityMatchedOnlyIcon"}
          imgSrc={EthnicityMatchedOnlyDiagram}
          label={"Enabled"}
          title={"Ethnicity Matched Only"}
          value={selectedHairGroup?.ethnicity_matched_only}
          onUpdate={changeCheckEventValue(onUpdateEthnicityMatchedOnly)}
        />
      </ContentCard>
    </JsonManagerNewUI>
  );

  const rightAside = (
    <RightAsidePreviewFull previewTitle="Preview">
      <PreviewContent data={styleUIState.selectedImages} title={"STYLE"} />
      <PreviewContent data={colorUIState.selectedImages} title={"COLOR"} />
    </RightAsidePreviewFull>
  );
  return (
    <Page
      fixedRightSide
      center={center}
      hideRightAside={isEmptyState}
      rightAside={rightAside}
    />
  );
}
