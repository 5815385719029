import { Vec2 } from "@flasd/modeling/src/maths/vec2";
import { LoRType } from "domain/Human";
import { PrefabGroupMap } from "domain/Prefabs";
import { bounds } from "features/JobBuilder2/steps/threeDimensional/measurement-operations";
import { getCircleInPolygon } from "features/JobBuilder2/steps/threeDimensional/utils/getCircleInPolygon";
import { reverseTransformVec3Maker } from "features/JobBuilder2/steps/threeDimensional/utils/pre-transform-cg-location";
import { getRandomFromArr } from "utils/arrUtil";
import { getRandomBetweenLimits } from "utils/numberUtils";
import {
  convertPrefabGroupMapToCharactersStringArr,
  getActivityEndFrame,
} from "./domain";
import {
  JsonAction,
  JsonCameraRig,
  JsonEnvironment,
  JsonInput,
  JsonPlacement,
  JsonRender,
  JsonScene,
} from "./input-json-types";
import { getRadiusForActivity } from "./steps/threeDimensional/get-radius-for-activity";
import {
  ActivityStance,
  TransformedLocation,
} from "./steps/threeDimensional/utils/Location";
import {
  Activity,
  defaultSubjob,
  LocationWithAngles,
  FrameCutoffMode,
} from "./types";

const resolveLoR = (loR: LoRType) => {
  if (loR.type === "list") {
    return getRandomFromArr(loR.listValues.split(",").map(Number));
  }
  return getRandomBetweenLimits(Number(loR.max), Number(loR.min));
};

const resolvePlacementOffset = (item: LocationWithAngles): JsonPlacement => ({
  position: [resolveLoR(item.x), resolveLoR(item.y), resolveLoR(item.z)],
  yaw: Math.round(resolveLoR(item.yaw)),
  pitch: resolveLoR(item.pitch),
  roll: Math.round(resolveLoR(item.roll)),
});

export const generateInputJson = (
  subjobs: (typeof defaultSubjob)[],
  prefabGroups: PrefabGroupMap,
  locations: TransformedLocation[],
  activities: Activity[]
): JsonInput => {
  const scenes: JsonScene[] = [];

  subjobs.forEach((sj) => {
    const location = locations.find((l) => l.name === sj.location);
    if (!location) {
      return;
    }
    const transformFn = reverseTransformVec3Maker(location.origin);
    for (
      let sceneIndex = 0;
      sceneIndex < Number(sj.distribution.numberOfScenes);
      sceneIndex++
    ) {
      const pickCharacter = buildCharacterPicker(prefabGroups);

      const render: JsonRender = {
        quality: sj.distribution.quality,
        end_frame:
          sj.distribution.frameCutoffMode === FrameCutoffMode.static
            ? Number(
                sj.distribution.configs[FrameCutoffMode.static].lastFrameNumber
              )
            : getActivityEndFrame(
                sj.distribution.configs[FrameCutoffMode.dynamic]
                  .activitiesInAllScenesForSpecialZone[sceneIndex],
                activities
              ),
        frame_increment: sj.distribution.frameIncrement,
        start_frame: 1,
      };
      const possibleActions: (JsonAction | undefined)[] = sj.activityZones.map(
        (az, activityZoneIndex) => {
          const activitiesInZone = az.activities;
          if (!activitiesInZone.length) {
            return undefined;
          }

          const activityAlreadyPicked =
            activityZoneIndex ===
              Number(
                sj.distribution.configs[FrameCutoffMode.dynamic]
                  .activityZoneIndex
              ) && sj.distribution.frameCutoffMode === FrameCutoffMode.dynamic;

          const activityName = activityAlreadyPicked
            ? sj.distribution.configs[FrameCutoffMode.dynamic]
                .activitiesInAllScenesForSpecialZone[sceneIndex]
            : getRandomFromArr(activitiesInZone);

          const previewActivity = activities.find(
            (activity) => activity.name === activityName
          );
          if (!previewActivity) {
            console.error({ activities, activityName });
            throw new Error(
              `Cannot find the activity named ${activityName}. See console log.`
            );
          }
          const radius = getRadiusForActivity(previewActivity);
          let rotation: number;
          let position: Vec2 | null;
          const geomBounds = bounds(az.geomRegion.geom);
          if (previewActivity.stance === ActivityStance.Sitting) {
            position = [
              geomBounds.left + geomBounds.right,
              geomBounds.top + geomBounds.bottom,
            ].map((x) => x * 0.5) as Vec2;
            rotation = 0;
          } else {
            position = getCircleInPolygon(az.geomRegion.geom, radius);
            rotation = Math.random() * 360;
          }
          if (position === null) {
            // TODO maybe throw an error here?
            return undefined;
          }

          const result: JsonAction = {
            name: activityName,
            transform: {
              position: transformFn(position),
              rotation,
            },
            human_preset: pickCharacter(az.prefabGroupId),
            action_region: az.geomRegion.actionRegionName,
          };

          return result;
        }
      );
      const actions = possibleActions.filter(
        (mightBe) => !!mightBe
      ) as JsonAction[];
      const environment: JsonEnvironment = {
        time_of_day: getRandomFromArr(sj.globalEnv.name),
        location_preset: sj.location,
        artificial_light_intensity: resolveLoR(sj.globalEnv.intensity),
        environment_light_rotation: resolveLoR(sj.globalEnv.rotation),
      };
      const cameraRig: JsonCameraRig = {
        initial_position: {
          placement_type: "location_uv",
          properties: {
            uv_coords_u: resolveLoR(sj.rig.u),
            uv_coords_v: resolveLoR(sj.rig.v),
            name: location.camera_preset_locations[sj.rigLocationIndex].name,
          },
        },
        rig: {
          placement_offset: resolvePlacementOffset(sj.rig),
          cameras: sj.rig.cameras.map((c) => ({
            name: c.name,
            placement_offset: resolvePlacementOffset(c),
            specifications: {
              focal_length: resolveLoR(c.focalLength),
              resolution_h: Number(c.resolutionH),
              resolution_w: Number(c.resolutionW),
              sensor_width: resolveLoR(c.sensorWidth),
              wavelength: c.wavelength,
            },
          })),
        },
      };

      scenes.push({
        actions,
        camera_rigs: [cameraRig],
        environment,
        render,
      });
    }
  });
  return {
    scenes,
  };
};

export function buildCharacterPicker(prefabGroups: PrefabGroupMap) {
  const charactersByPrefabGroup = convertPrefabGroupMapToCharactersStringArr(
    prefabGroups
  );

  return (prefabGroupId: string) => {
    let character = getRandomFromArr(charactersByPrefabGroup[prefabGroupId]);

    if (!character) {
      // All characters have been used, reset the list
      charactersByPrefabGroup[
        prefabGroupId
      ] = convertPrefabGroupMapToCharactersStringArr(prefabGroups)[
        prefabGroupId
      ];

      character = getRandomFromArr(charactersByPrefabGroup[prefabGroupId]);
    }

    charactersByPrefabGroup[prefabGroupId] = charactersByPrefabGroup[
      prefabGroupId
    ].filter((c) => c !== character);

    return character;
  };
}
