import { useEffect, useState } from "react";
import { Add, People, PersonSearch, PersonAdd } from "@mui/icons-material";
import { Box, Divider, Backdrop, Typography, Button } from "@mui/material";
import produce from "immer";
import { without } from "lodash";
import { colors } from "components/App/theme";
import { TitleWithIcon } from "components/TitleWithIcon/TitleWithIcon";
import { getHumanObject, getIds } from "features/JobBuilder/json-extracter";
import { JsonManagerNewUI } from "features/JobBuilder/json-manager-new-ui";
import { convertIds } from "features/JobBuilder/json-to-form";
import { DeepPartial, JsonHuman } from "features/JobBuilder/json-types";
import { Page, ContentCard } from "features/JobBuilder/layout";
import {
  selectActiveSubjobDemographicBuilder,
  selectActiveSubjobIdentitiesIds,
} from "features/JobBuilder/store";
import { useBoundStore } from "store/_boundStore";
import { AddIdentitiesDialog } from "./add-identities-dialog";
import { IdentitiesSelected } from "./identities-selected";
import { Bold, TotalLabel } from "./Identities.styled";

const ADD_IDENTITIES_OPTIONS = [
  {
    key: "prefab",
    title: "Prefab Identity Groups",
    description: "Select from a list of pre-defined identity groups",
    actionLabel: "Add prefab group",
    icon: (
      <People
        sx={{
          fontSize: "24px",
        }}
      />
    ),
  },
  {
    key: "demographic",
    title: "Demographic Builder",
    description: "Customize your identities with Demographic Builder",
    actionLabel: "Add using builder",
    icon: (
      <PersonSearch
        sx={{
          fontSize: "24px",
        }}
      />
    ),
  },
  {
    key: "manual",
    title: "Manually Enter Identitites",
    description: "Manually input comma separated IDs to add them to your Job.",
    actionLabel: "Add ID's manually",
    icon: (
      <PersonAdd
        sx={{
          fontSize: "24px",
        }}
      />
    ),
  },
];

type IdentityOptionKey = "prefab" | "demographic" | "manual";

export function JobBuilderIdentities() {
  const { onUpdateDemographicBuilder, onUpdatedIdentities: onUpdate } =
    useBoundStore.getState().jobBuilder;

  const value = useBoundStore(selectActiveSubjobIdentitiesIds);
  const demographicBuilder = useBoundStore(
    selectActiveSubjobDemographicBuilder
  );
  const { setErrorMessage, setSuccessMessage } =
    useBoundStore.getState().message;
  const { setSelectedIdentitiesPrefab } = useBoundStore.getState().jobBuilder;

  const [addIdentitiesModalVisible, setAddIdentitiesModalVisible] = useState<
    string | null
  >(null);

  const addIdentities = (ids: number[], preventDuplicates = true) =>
    onUpdate(
      produce(value, (d) => {
        const set = new Set<number>(d);

        ids.forEach((id) => {
          if (!preventDuplicates || (preventDuplicates && !set.has(id))) {
            d.push(id);
          }
        });
      })
    );

  const setPrefab = (
    selectedPrefabIdentitiesIds: number[],
    previousPrefabIdentitiesIds: number[]
  ) =>
    onUpdate(
      produce(value, (d) => {
        const identitiesNotInPrefab = d.filter(
          (id) => !previousPrefabIdentitiesIds.includes(id)
        );

        const set = new Set<number>(identitiesNotInPrefab);
        const nextIdentityIds = identitiesNotInPrefab.concat([]);

        selectedPrefabIdentitiesIds.forEach((id) => {
          if (!set.has(id)) {
            nextIdentityIds.push(id);
          }
        });

        return nextIdentityIds;
      })
    );

  const onClearAll = () => {
    onUpdate([]);
    setSelectedIdentitiesPrefab(null);
  };

  const onDeleteSelectedIDClick = (id: number) => onUpdate(without(value, id));

  const onIndecesReceived = (
    indices: number[],
    humansArr: DeepPartial<JsonHuman>[]
  ) => {
    try {
      onUpdate(
        convertIds(getIds(getHumanObject(humansArr, indices[0])), value)
      );
      setSuccessMessage("updated identities");
    } catch (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
      }
    }
  };

  const onDoneJson = (json: any) => {
    const updated = convertIds(json, value);
    onUpdate(updated);
  };

  const center = (
    <JsonManagerNewUI
      id="identities"
      jsonValue={value}
      title={`Identities`}
      titleCount={value.length}
      titleIcon="IdentitiesIcon"
      unknownIndicesKeys={["Human"]}
      onDoneEditJson={onDoneJson}
      onIndecesReceived={onIndecesReceived}
    >
      <ContentCard style={{ position: "relative", zIndex: 4 }}>
        <TitleWithIcon
          withSpacing
          icon={"IdentitiesSelectedIcon"}
          label={"Add Identities"}
        />

        {ADD_IDENTITIES_OPTIONS.map((option, index) => {
          const isFirst = index === 0;
          const isLast = index === ADD_IDENTITIES_OPTIONS.length - 1;
          return (
            <Box
              key={option.key}
              alignItems={"center"}
              borderBottom={isLast ? "none" : `1px solid ${colors.grey20}`}
              component="div"
              display="flex"
              mb={isLast ? 0 : 3}
              mt={isFirst ? 3 : 0}
              pb={isLast ? 0 : 3}
            >
              <Box component="div" mr={3}>
                <Box
                  alignItems="center"
                  bgcolor={colors.tint5}
                  borderRadius={20}
                  color={colors.bright[2]}
                  component="div"
                  display="flex"
                  height={56}
                  justifyContent="center"
                  width={56}
                >
                  {option.icon}
                </Box>
              </Box>
              <Box component="div" flex={1} mr={1}>
                <Bold>{option.title}</Bold>
                <Box color={colors.grey70} component="div">
                  {option.description}
                </Box>
              </Box>
              <Button
                color="warning"
                startIcon={<Add />}
                sx={{
                  flexShrink: 0,
                  width: 180,
                }}
                variant="outlined"
                onClick={() =>
                  setAddIdentitiesModalVisible(option.key as IdentityOptionKey)
                }
              >
                {option.actionLabel}
              </Button>
            </Box>
          );
        })}

        <AddIdentitiesDialog
          addIdentities={addIdentities}
          addIdentitiesModalVisible={addIdentitiesModalVisible}
          demographicBuilder={demographicBuilder}
          setAddIdentitiesModalVisible={setAddIdentitiesModalVisible}
          setPrefab={setPrefab}
          onUpdateDemographicBuilder={onUpdateDemographicBuilder}
        />
      </ContentCard>
      <ContentCard style={{ position: "relative", zIndex: 4 }}>
        <Box
          alignItems={"center"}
          component="div"
          display="flex"
          justifyContent="space-between"
          mb={1}
        >
          <TitleWithIcon
            fitContentWidth
            withSpacing
            icon={"IdentitiesSelectedIcon"}
            label={`Identities Selected (${value.length})`}
          />

          <Box
            color={colors.orange}
            component={Typography}
            sx={{
              cursor: "pointer",
              fontSize: "16px",
              flexShrink: 0,
            }}
            variant="body1"
            onClick={onClearAll}
          >
            Clear All Identity {"ID's"}
          </Box>
        </Box>
        <IdentitiesSelected
          selectedIds={value}
          onDeleteSelectedIDClick={onDeleteSelectedIDClick}
        />
        <Divider sx={{ mb: 3, mt: 5 }} />
        <TotalLabel>
          Total Identities: <Bold>{value.length}</Bold>
        </TotalLabel>
      </ContentCard>
    </JsonManagerNewUI>
  );

  return <Page center={center} />;
}
