import JoinInnerMUI from "@mui/icons-material/JoinInner";

export function JoinInnerIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return <JoinInnerMUI htmlColor={fill} style={style} />;
}
