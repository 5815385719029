import { Box } from "@mui/material";
import { AppleIcon } from "assets/icons/Apple";
import { LinuxIcon } from "assets/icons/Linux";
import { WindowsIcon } from "assets/icons/Windows";
import { colors } from "components/App/theme";
import { TutorialCardButton } from "components/tutorials/TutorialCardButton";
import {
  CLI_OSX_X86_URL,
  CLI_OSX_ARM_URL,
  CLI_LINUX_URL,
  CLI_WINDOWS_URL,
} from "constants/constants";
import { download } from "services/download";
import { detectOS } from "services/env-service";
type OsIcon = ({
  fill,
  style,
}: {
  fill?: string;
  style?: React.CSSProperties;
}) => JSX.Element;

const getOsDownloadMaterial = (
  name: string,
  downloadURL: string,
  afterIconDescription: string,
  icon: OsIcon
) => ({
  name,
  downloadURL,
  afterIconDescription,
  icon,
});
const oss = [
  getOsDownloadMaterial("os", CLI_OSX_ARM_URL, "- m1/arm64", AppleIcon),
  getOsDownloadMaterial("os", CLI_OSX_X86_URL, "- intel/x86", AppleIcon),
  getOsDownloadMaterial("linux", CLI_LINUX_URL, "", LinuxIcon),
  getOsDownloadMaterial("windows", CLI_WINDOWS_URL, "", WindowsIcon),
];
export function TutorialDownloadOs() {
  const OS = detectOS();
  return (
    <>
      {oss.map((o) => (
        <Box key={o.downloadURL} component="div" mb={1}>
          <TutorialCardButton
            color={o.name === OS ? "warning" : "secondary"}
            data-testid={`download-${o.name}`}
            onClick={() => download(o.downloadURL)}
          >
            {
              <o.icon
                fill={o.name === OS ? colors.white : undefined}
                style={{ width: "1.4rem" }}
              />
            }
            {o.afterIconDescription}
          </TutorialCardButton>
        </Box>
      ))}
    </>
  );
}
