import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import { LabelWithValue } from "domain/Human";

export function SingleSelect({
  value,
  options,
  label,
  onChange,
  onBlur,
  width = 300,
}: {
  value: string;
  options: LabelWithValue[] | string[];
  label: string;
  onChange: (s: string) => void;
  onBlur?: () => void;
  width?: number;
}) {
  return (
    <FormControl style={{ display: "block", width }} variant="standard">
      <InputLabel id={label} variant="filled">
        {label}
      </InputLabel>
      <Select
        displayEmpty
        fullWidth
        input={<OutlinedInput label={label} />}
        value={value}
        variant="standard"
        onBlur={onBlur}
        onChange={(e) => onChange(e.target.value as string)}
      >
        {options.map((o, i) =>
          typeof o === "string" ? (
            <MenuItem key={o} value={o}>
              <ListItemText primary={o} />
            </MenuItem>
          ) : (
            <MenuItem key={o.value} value={o.value}>
              <ListItemText primary={o.label} />
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
}
