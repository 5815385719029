import { DropdownWithTitle } from "components/DropdownWithTitle";
import { TooltipWithLinkContent } from "components/TooltipWithLinkContent";
import { Messages } from "constants/messages";
import { lightTypeOptions } from "domain/Human";
import { lightTypeDiagrams } from "./data";
import { Container, Diagram } from "./LightTypeSelector.styled";

export function LightTypeSelector({
  value,
  onUpdate,
}: {
  value: string;
  onUpdate: (v: string) => void;
}) {
  return (
    <Container>
      <DropdownWithTitle
        extraInfo={
          <TooltipWithLinkContent
            linkLabel="Learn More"
            linkTo="https://docs.synthesis.ai/inputs.html#wavelengthNIR"
            textContent={Messages.lightTypeInfo}
          />
        }
        icon="TypeIcon"
        label="Type"
        options={lightTypeOptions}
        value={value}
        onUpdate={onUpdate}
      />
      <Diagram src={lightTypeDiagrams[value]} />
    </Container>
  );
}
