import {
  WarningDialog,
  WarningDialogBaseProps,
} from "components/dialogs/WarningDialog";

export function ChangeEnvironmentDialog(props: WarningDialogBaseProps) {
  return (
    <WarningDialog
      {...props}
      icon="WarningIcon"
      onCancelButtonText="Cancel"
      onConfirmButtonText="Continue"
    />
  );
}
