import { useEffect } from "react";
import { useBoundStore } from "store/_boundStore";

export const useLogout = () => {
  const authenticated = useBoundStore((s) => s.auth.authenticated);
  const { logout } = useBoundStore.getState().auth;

  useEffect(() => {
    if (authenticated) {
      logout("");
    }
  }, [authenticated, logout]);

  return authenticated;
};
