import { Container } from "./IconizedAdditionalInfo.styled";
import { IconNode } from "./IconNode";
import { InfoWithIcon } from "./types";

export function IconizedAdditionalInfo({
  info,
  selected = false,
  iconsDefaultColor,
}: {
  info: InfoWithIcon[];
  selected?: boolean;
  iconsDefaultColor?: string;
}) {
  return (
    <Container>
      {info.map((node, index) => (
        <IconNode
          key={index}
          icon={node.icon}
          iconsDefaultColor={iconsDefaultColor}
          label={node.label}
          selected={selected}
          tooltipText={node.tooltipText}
        />
      ))}
    </Container>
  );
}
