import { useMemo } from "react";
import { useGLTF } from "@react-three/drei";
import { MeshStandardMaterial } from "three";
import lightModel from "./lightModel.glb";

const materialColors = {
  Light: "#FFFFFF",
  LightFrame: "#8D8D8D",
};

export function LightModel(props) {
  const { nodes } = useGLTF(lightModel);

  const materials = useMemo(() => {
    return Object.fromEntries(
      Object.entries(materialColors).map(([k, color]) => {
        const characterMaterial = new MeshStandardMaterial();
        characterMaterial.roughness = 1;
        characterMaterial.metalness = 0.5;
        characterMaterial.color.set(color);

        return [k, characterMaterial];
      })
    );
  }, []);

  return (
    <group {...props} dispose={null}>
      <group rotation={[0, -Math.PI / 2, 0]}>
        <mesh
          geometry={nodes.Cube003.geometry}
          material={materials.LightFrame}
        />
        <mesh geometry={nodes.Cube003_1.geometry} material={materials.Light} />
      </group>
    </group>
  );
}

useGLTF.preload(lightModel);
