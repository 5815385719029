import React, { useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router";
import { ActionButton } from "components/Common/ActionButton";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { Messages } from "constants/messages";
import { useOrgParam } from "hooks/useOrgParam";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { selectedOrgSelector } from "store/orgStore";
import { validateEmail } from "utils/validators";

export function AddUser() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState<"reader" | "writer" | "owner">("reader");
  const [emailValidationError, setEmailValidationError] = useState("");
  const { orgParam } = useOrgParam();
  const { setSuccessMessage, setErrorMessage } =
    useBoundStore.getState().message;
  const { org, permissions, orgLoading } = useBoundStore((state) => {
    return {
      org: selectedOrgSelector(orgParam)(state),
      permissions: state.org.selected?.permissions,
      orgLoading: state.org.loading,
    };
  });

  if (orgLoading) {
    return <FullScreenProgress />;
  }

  if (!org) {
    return (
      <Typography color="error" variant="h3">
        No Org was selected.
      </Typography>
    );
  }

  if (org && permissions && !permissions.is_owner) {
    return (
      <Typography color="error" variant="h3">
        Only an Org owner is able to invite users.
      </Typography>
    );
  }

  const validate = () => {
    const validationResult = validateEmail(email);
    if (!validationResult.valid) {
      setEmailValidationError(validationResult.message || "Invalid");
    } else {
      setEmailValidationError("");
    }
    return validationResult.valid;
  };

  const addUser = async () => {
    if (!validate()) {
      return;
    }

    setLoading(true);
    try {
      await restClient.addOrModifyOrgUser(org.name, email.toLowerCase(), role);
      setSuccessMessage(Messages.userAdded(email, org.name));
      window.analytics.track("Added User", {
        orgName: org.name,
        userEmail: email,
        userRole: role,
      });
      setLoading(false);
      navigate(-1);
    } catch (error) {
      setLoading(false);
      await invite();
    }
  };

  const invite = async () => {
    try {
      const message = await restClient.inviteUser(
        org.name,
        email.toLowerCase(),
        role
      );
      window.analytics.track("Invite Sent", {
        orgName: org.name,
        userEmail: email,
        userRole: role,
      });
      setSuccessMessage(message);
      navigate(-1);
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(restClient.APIErrorMessage(error));
    }
  };

  return (
    <>
      {loading && <FullScreenProgress />}
      <Grid container justifyContent="center">
        <Grid item md={6} sm={8}>
          <Box component="div" data-testid="team-component">
            <Box component="div" mb={4}>
              <Typography variant="h1">Add new member</Typography>
            </Box>
            <Box component="div">
              Enter the email address and we will add the user to{" "}
              <Box component="div" display="inline" fontWeight={600}>
                {" "}
                {org?.name}
              </Box>
              .
            </Box>
          </Box>
          <Box component="div" mt={5}>
            <TextField
              autoFocus
              fullWidth
              required
              error={!!emailValidationError}
              helperText={emailValidationError}
              id="email"
              inputProps={{
                "data-testid": "email-input",
              }}
              label="Email address"
              size="small"
              type="email"
              value={email}
              variant="outlined"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailValidationError("");
              }}
            />
          </Box>
          <Box component="div" mb={4} mt={4}>
            <FormControl fullWidth required variant="standard">
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                fullWidth
                value={role}
                variant="standard"
                onChange={(e) => {
                  setRole(e.target.value as "reader" | "writer" | "owner");
                }}
              >
                <MenuItem value="reader">Reader</MenuItem>
                <MenuItem value="writer">Writer</MenuItem>
                <MenuItem value="owner">Owner</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <ActionButton
                fullWidth
                color="secondary"
                onClick={() => navigate(-1)}
              >
                Cancel
              </ActionButton>
            </Grid>
            <Grid item xs={6}>
              <ActionButton
                fullWidth
                color="warning"
                data-testid="add-user-button"
                onClick={addUser}
              >
                Add User
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
