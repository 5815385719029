import { Box } from "@mui/material";
import FullLogo from "assets/synthesis/logo-complete.png";

export function Logo() {
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        alt={"Synthesis logo"}
        component="img"
        src={FullLogo}
        sx={{
          height: {
            xs: 32,
            sm: 36,
          },
          mt: 2,
          mb: 2,
        }}
      />
    </Box>
  );
}
