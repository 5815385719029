import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import {
  DateRangePicker as LibDateRangePicker,
  RangeKeyDict,
  Range,
} from "react-date-range";
import { colors } from "components/App/theme";
import { SearchInput } from "components/Common/SearchInput";
import { Button } from "components/design-system/buttons/Button";
import { Select } from "components/design-system/forms/Select";
import { LabelWithValue } from "domain/Human";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useBoundStore } from "store/_boundStore";

const defaultRange: Range = {
  startDate: new Date(),
  endDate: new Date(),
  key: "selection",
};

export type JobHeaderProps = {
  currentJobType: number;
  currentSelectedUser: string;
  jobTypeOptions: LabelWithValue[];
  onAddJobClick: () => void;
  onRangeChange: (value: Range | null) => void;
  onUpdateJobType: (newJobType: number) => void;
  onUserIdChange: (value: string) => void;
  selectedRange: Range | null;
  setSearchTerm: (value: string) => void;
  userIdOptions: LabelWithValue[];
};

const DEFAULT_VALUE_ID = "-1";

export function Header(props: JobHeaderProps) {
  const {
    currentJobType,
    currentSelectedUser,
    jobTypeOptions,
    onAddJobClick,
    onRangeChange,
    onUpdateJobType,
    onUserIdChange,
    selectedRange,
    setSearchTerm,
    userIdOptions,
  } = props;

  const [showingDatePickers, setShowingDatePickers] = useState(false);

  const userId = useBoundStore((state) => state.auth.getCurrentUserId());

  const [internalSelectedRange, setInternalSectedRange] =
    useState<Range>(defaultRange);

  const onDatePickerChange = (ranges: RangeKeyDict) => {
    const s = ranges.selection;
    if (s) {
      setInternalSectedRange(s);
    }
  };

  const handleRangeOk = () => {
    onRangeChange(internalSelectedRange);
    setShowingDatePickers(false);
  };

  const handleClearRange = () => {
    setInternalSectedRange(defaultRange);
    onRangeChange(null);
    setShowingDatePickers(false);
  };

  const showTypeFilter = useMemo(
    () => Object.keys(jobTypeOptions).length > 0,
    [jobTypeOptions]
  );

  const jobTypeOptionsWithAll = useMemo(
    () =>
      [
        {
          value: DEFAULT_VALUE_ID,
          label: "All jobs",
        },
      ].concat(jobTypeOptions),
    [jobTypeOptions]
  );

  const userIdOptionsWithAll = useMemo(() => {
    const currentUser = userIdOptions.find((user) => user.value === userId);
    const allUsers = {
      value: DEFAULT_VALUE_ID,
      label: "All users",
    };

    if (currentUser) {
      return [allUsers, currentUser].concat(
        userIdOptions.filter((user) => user.value !== userId)
      );
    }

    return [allUsers].concat(userIdOptions);
  }, [userId, userIdOptions]);

  return (
    <>
      <Box
        alignItems="center"
        component="div"
        display="flex"
        justifyContent="space-between"
      >
        <Box alignItems="center" component="div" display="flex">
          <SearchInput
            placeholder="Search"
            onSearch={(term) => setSearchTerm(term)}
          />
          {showTypeFilter && (
            <Box component="div" mb={4} ml={2} mr={2} mt={4}>
              <Select
                id="jobtype"
                label="Type"
                labelId="jobtype-label"
                options={jobTypeOptionsWithAll}
                sx={{
                  color:
                    !currentJobType ||
                    currentJobType.toString() === DEFAULT_VALUE_ID
                      ? colors.grey50
                      : undefined,
                  minWidth: "180px",
                }}
                value={currentJobType || DEFAULT_VALUE_ID}
                onChange={(e) => {
                  onUpdateJobType(e.target.value as number);
                }}
              />
            </Box>
          )}
          {
            <Box component="div" mb={4} mr={2} mt={4}>
              <Select
                id="userId"
                label="User"
                labelId="userId-label"
                options={userIdOptionsWithAll}
                sx={{
                  color:
                    !currentSelectedUser ||
                    currentSelectedUser === DEFAULT_VALUE_ID
                      ? colors.grey50
                      : undefined,
                  minWidth: "180px",
                }}
                value={currentSelectedUser || DEFAULT_VALUE_ID}
                onChange={(e) => {
                  onUserIdChange(e.target.value as string);
                }}
              />
            </Box>
          }
          <Box component="div" position="relative">
            <Button
              level="link"
              onClick={() => setShowingDatePickers(!showingDatePickers)}
            >
              {selectedRange ? "Filtering By Date" : "Filter By Date"}
              {selectedRange && (
                <Box component="span" ml={1}>
                  ({selectedRange?.startDate?.toLocaleDateString()} -{" "}
                  {selectedRange?.endDate?.toLocaleDateString()})
                </Box>
              )}
            </Button>

            {showingDatePickers && (
              <Box
                boxShadow={1}
                component="div"
                left={0}
                position="absolute"
                sx={{ backgroundColor: colors.white }}
                top={48}
                zIndex={100}
              >
                <LibDateRangePicker
                  showPreview
                  direction="horizontal"
                  moveRangeOnFirstSelection={false}
                  rangeColors={[colors.bright[2]]}
                  ranges={[internalSelectedRange]}
                  onChange={onDatePickerChange}
                />
                <Box
                  component="div"
                  display="flex"
                  justifyContent="flex-end"
                  p={1}
                >
                  <Button sx={{ mr: 1 }} onClick={handleRangeOk}>
                    Apply
                  </Button>
                  <Button level="secondary" onClick={handleClearRange}>
                    Clear
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box component="div">
          <Box component="div" display="inline-block" mr={2}>
            <Button sx={{ cursor: "pointer" }} onClick={onAddJobClick}>
              Add Job
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
