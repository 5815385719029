import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const Container = styled.div`
  width: 320px;
`;

export const ImageGrid = styled.div`
  display: grid;
  width: 290px;
  height: 280px;
  overflow: none;
  margin-top: 8px;
`;

export const PreviewImage = styled.img`
  object-fit: cover;
  height: 72px;
  width: 72px;
  border-radius: 4px;
  overflow: hidden;
`;

export const Spacer = styled(Box)`
  width: 350px;
`;

export const RightAsideContainer = styled(Box)`
  height: calc(100vh - 119px);
  width: 350px;
  background-color: #fff;
  border: 1px solid ${colors.grey20};
  border-right: none;
  border-top: none;
  overflow: auto;
  position: fixed;
  right: 0;
`;

export const PreviewTitleContainer = styled.div`
  padding: 8px 24px;
  border-bottom: 1px solid ${colors.grey20};
`;

export const StyledPreviewTitle = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

export const Subtitle = styled(Typography)<{ $isVariant?: boolean }>`
  color: ${colors.grey50};
  font-weight: 400;
  margin-top: 4px;

  font-size: 12px;
  line-height: 16px;
`;
