import { IconButton, Tooltip } from "@mui/material";

export function IconWithToolip({
  children,
  onClick,
  title,
}: {
  children: React.ReactChild;
  onClick: () => void;
  title: string;
}) {
  return (
    <Tooltip placement="right" title={title}>
      <IconButton size="small" onClick={onClick}>
        {children}
      </IconButton>
    </Tooltip>
  );
}
