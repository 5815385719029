import { Manifest } from "types/main";

const enum AssetType {
  rgb = "rgb.png",
  rgba = "rgba.png",
  info = "info.json",
  imageMetadata = "image_metadata.json",
}

export function getImageSuffix(manifest: Manifest): AssetType {
  return manifest.asset_types.find((x) => x === AssetType.rgba)
    ? AssetType.rgba
    : AssetType.rgb;
}

export function getInfoSuffix(manifest: Manifest): AssetType {
  return manifest.asset_types.find((x) => x === AssetType.info)
    ? AssetType.info
    : AssetType.imageMetadata;
}
