import { useMemo } from "react";
import { Vector3, Euler, MathUtils } from "three";
import { getMeanMinMaxValue } from "features/JobBuilder/CamerasAndLightsPreview/utils/getMeanMinMaxValue";
import { JsonLocation } from "features/JobBuilder/json-types";

export const useRelativeLocation = (data: JsonLocation | undefined) => {
  const cameraDetails = useMemo(() => {
    if (!data) return null;

    const position = new Vector3();
    position.x += getMeanMinMaxValue(data.x).mean;
    position.y += getMeanMinMaxValue(data.y).mean;
    position.z += getMeanMinMaxValue(data.z).mean;

    const rotation = new Euler();
    rotation.x = MathUtils.degToRad(getMeanMinMaxValue(data.pitch).mean);
    rotation.y = MathUtils.degToRad(getMeanMinMaxValue(data.yaw).mean);
    rotation.z = MathUtils.degToRad(getMeanMinMaxValue(data.roll).mean);
    rotation.order = "ZYX";

    return {
      position,
      rotation,
    };
  }, [data]);

  return { ...cameraDetails };
};
