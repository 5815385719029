import React, { useEffect, useMemo } from "react";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { Box, Button, SxProps, Theme, Typography } from "@mui/material";
import { colors } from "components/App/theme";
import { CharacterGroupPickerDialog } from "components/dialogs/CharacterGroupPickerDialog";
import { ProTip } from "components/ProTip";
import { ActivitiesDialogs } from "features/JobBuilder2/activities-dialogs";
import { selectPickedSubjob } from "features/JobBuilder2/store";
import { Pipe2StepEnum, Pipe2Step } from "features/JobBuilder2/types";
import { usePartialSticky } from "hooks/usePartialSticky";
import { useBoundStore } from "store/_boundStore";

export function Steps({ steps }: { steps: Pipe2Step[] }) {
  const {
    setShowHelpDialog,
    goToNextStep,
    goToPrevStep,
    setIsCharacterGroupDialogOpened,
    updateSelectedPrefabGroupOfZone,
  } = useBoundStore.getState().jobBuilder2;

  const [
    selectedStep,
    showHelpDialog,
    isCharacterGroupDialogOpened,
    pickedActivityZoneIndex,
    pickedSubjob,
    prefabGroups,
  ] = useBoundStore((state) => [
    state.jobBuilder2.selectedStep,
    state.jobBuilder2.showHelpDialog,
    state.jobBuilder2.isCharacterGroupDialogOpened,
    state.jobBuilder2.pickedActivityZoneIndex,
    selectPickedSubjob(state),
    state.catalogData.prefabGroups,
  ]);

  useEffect(() => {
    if (selectedStep === Pipe2StepEnum.ActivityZones) {
      setShowHelpDialog(
        !localStorage.getItem("__tutorial__activity_zones_seen")
      );
    } else {
      setShowHelpDialog(false);
    }
  }, [selectedStep, setShowHelpDialog]);

  const Dialog = steps[selectedStep].help?.Dialog;

  const isBackButtonDisabled = useMemo(() => {
    if (selectedStep === Pipe2StepEnum.Location) {
      return true;
    }
    if (selectedStep === Pipe2StepEnum.RigLocation) {
      return false;
    }
    return !steps[selectedStep - 2].completed;
  }, [selectedStep, steps]);

  const [
    scrollContentRef,
    stickyContentRef,
    scrolledDown,
    widthOfNonStickyContent,
  ] = usePartialSticky(15);

  const navButtonsSX: SxProps<Theme> = useMemo(
    () => ({
      mr: 1,
      opacity: scrolledDown ? 0.3 : 1,
      "&:hover": {
        opacity: 1,
      },
    }),
    [scrolledDown]
  );

  return (
    <Box
      ref={scrollContentRef}
      component="div"
      display="flex"
      flex="1"
      flexDirection="column"
      overflow="scroll"
      sx={{
        backgroundColor: colors.greyBGLighter,
      }}
    >
      <ActivitiesDialogs />
      {isCharacterGroupDialogOpened && (
        <CharacterGroupPickerDialog
          prefabGroups={prefabGroups}
          selectedPrefabGroupId={
            pickedSubjob.activityZones[pickedActivityZoneIndex].prefabGroupId
          }
          onCancel={() => setIsCharacterGroupDialogOpened(false)}
          onConfirm={updateSelectedPrefabGroupOfZone}
        />
      )}
      {Dialog && showHelpDialog && <>{Dialog}</>}
      {/* Step Header Info and Nav Controls */}
      <Box
        alignItems="center"
        component="div"
        display="flex"
        flexDirection="row"
        p={2}
        py={4}
        sx={{
          width: widthOfNonStickyContent,
          minHeight: "112px",
        }}
      >
        <Box
          alignItems="center"
          component="div"
          display="flex"
          flex={1}
          flexDirection="row"
          height="100%"
        >
          <Typography component="div" mr={2} variant="h4">
            {steps[selectedStep].label}
          </Typography>
          {steps[selectedStep].help && (
            <ProTip
              key={selectedStep}
              dismissible={false}
              label={steps[selectedStep].help?.proTip as string}
              marginY={0}
              onClickLearnMore={
                Dialog ? () => setShowHelpDialog(true) : undefined
              }
            />
          )}
        </Box>

        <Box
          ref={stickyContentRef}
          component="div"
          sx={{
            position: "absolute",
            top: 100,
            zIndex: 200,
            right: 25,
          }}
        >
          {
            // Back Button
            selectedStep > 0 && (
              <Button
                color="warning"
                disabled={isBackButtonDisabled}
                sx={navButtonsSX}
                variant="contained"
                onClick={goToPrevStep}
              >
                <ChevronLeft /> {steps[selectedStep - 1].label}
              </Button>
            )
          }

          {selectedStep !== steps.length - 1 && (
            <Button
              color="warning"
              disabled={!steps[selectedStep].completed}
              sx={navButtonsSX}
              variant="contained"
              onClick={goToNextStep}
            >
              {steps[selectedStep + 1].label} <ChevronRight />
            </Button>
          )}
        </Box>
      </Box>

      {/* Step */}
      <Box
        component="div"
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {steps[selectedStep].el}
      </Box>
    </Box>
  );
}
