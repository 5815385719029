import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  Typography,
} from "@mui/material";

export function TermsAndConditionsFormElement({
  agree,
  onChange,
  agreeValidationError,
}: {
  agree: boolean;
  onChange: (flag: boolean) => void;
  agreeValidationError: string;
}) {
  return (
    <Box component="div" mt={2}>
      <Typography variant="caption">
        <FormControl required error={!!agreeValidationError} variant="standard">
          <FormControlLabel
            control={
              <Checkbox
                checked={agree}
                data-testid="termsCheckbox"
                inputProps={{ "aria-label": "primary checkbox" }}
                size="small"
                onChange={() => onChange(!agree)}
              />
            }
            label={
              <>
                I agree to the{" "}
                <Link
                  href="https://synthesis.ai/api-terms-conditions/"
                  rel="noreferrer"
                  target="_blank"
                  underline="always"
                >
                  API Terms &amp; Conditions.
                </Link>
              </>
            }
          />
          <FormHelperText>{agreeValidationError}</FormHelperText>
        </FormControl>
      </Typography>
    </Box>
  );
}
