import React from "react";
import { GenericMessage } from "./GenericMessage";

type WarnMessageProps = {
  message: string;
  onDismiss: () => void;
};

export function WarnMessage(props: WarnMessageProps) {
  const { message, onDismiss } = props;

  return (
    <GenericMessage
      duration={null}
      message={message}
      severity="warning"
      onDismiss={onDismiss}
    />
  );
}
