import { createTheme } from "@mui/material/styles";

const fontFamily = ["gibson", "Helvetica", "sans-serif"].join(",");
export const colors = {
  neutral: ["#f0eeeb", "#e1dcd3"],
  deep: ["#2f6884", "#37568e", "#37387e"],
  bright: ["#ebac28", "#ed9125", "#f16720"],
  pumpkin: "#FF6E1A",
  red: "#F44C58",
  brightGreen: "#7ED321",
  oxfordBlue: "#31434C",
  tuna: "#3f3f4b",
  riverBed: "#414c55",
  mercury: "#E5E5E5",
  pomegranate: "#f44336",
  boulder: "#777777",
  sushi: "#7bb735",
  cadetBlue: "#aab7c4",
  fiord: "#424770",
  cerulean: "#0095E3",
  white: "#ffffff",
  black: "#000",
  orange: "#EE741F",
  tint5: "#FDF1E9",
  tint4: "#FDF7F3",
  tint3: "#FCE3D2",
  greyBGLighter: "#F9F9F9",
  greyBG: "#E9E9E9",
  grey70: "#414C55",
  grey20: "#D7D7D7",
  grey30: "#B8B8B8",
  grey50: "#9A9A9B",
  purple: "#5b00c4",
};
const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};

const theme = createTheme({
  breakpoints,
  palette: {
    primary: {
      light: colors.deep[0],
      main: colors.deep[1],
      dark: colors.deep[2],
    },
    secondary: {
      light: colors.neutral[0],
      main: colors.neutral[1],
      dark: colors.neutral[2],
      contrastText: colors.oxfordBlue,
    },
    warning: {
      light: colors.bright[0],
      main: colors.bright[2],
      dark: colors.bright[1],
      contrastText: "white",
    },
    info: {
      light: colors.grey50,
      main: colors.grey70,
      dark: colors.black,
      contrastText: "white",
    },
    mode: "light",
    background: {
      default: colors.white,
    },
  },
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: colors.neutral[1],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        button: {
          color: colors.bright[2],
          textDecoration: "underline",
          fontFamily,
        },
        root: {
          color: colors.bright[2],
          fontSize: "18px",
          [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
            fontSize: "16px",
          },
          fontFamily,
          textDecoration: "underline",
        },
      },
    },
  },
  typography: {
    fontFamily,
    h1: {
      fontSize: "36px",
      "@media (max-width:600px)": {
        fontSize: "28px",
      },
      fontWeight: 400,
      letterSpacing: "-0.45px",
      color: colors.oxfordBlue,
    },
    h2: {
      fontSize: "28px",
      "@media (max-width:600px)": {
        fontSize: "24px",
      },
      fontWeight: 600,
      color: colors.tuna,
    },
    h3: {
      fontSize: "22px",
      "@media (max-width:600px)": {
        fontSize: "20px",
      },
      fontWeight: 600,

      color: colors.tuna,
    },
    h4: {
      fontSize: "18px",
      fontWeight: 600,
      color: colors.tuna,
    },
    h5: {
      fontSize: "16px",
      fontWeight: 300,
      color: colors.tuna,
    },
    subtitle1: {
      fontSize: "22px",
      "@media (max-width:600px)": {
        fontSize: "16px",
      },
      fontWeight: "normal",
      color: colors.oxfordBlue,
    },
    body1: {
      fontSize: "18px",
      "@media (max-width:600px)": {
        fontSize: "14px",
      },
      fontWeight: 400,
      color: colors.oxfordBlue,
    },
    body2: {
      fontSize: "16px",
      "@media (max-width:600px)": {
        fontSize: "14px",
      },
      color: colors.oxfordBlue,
    },
    caption: {
      fontSize: "14px",
      fontWeight: 600,
      color: colors.oxfordBlue,
    },
  },
});

const globalStyles = {
  ".datatableWrapper": {
    borderRadius: "4px 4px 0 0",
    boxShadow: `0 1px 0 0 ${colors.mercury}`,
  },
  "input.billing-element": {
    fontSize: "20px",
    color: colors.fiord,
    letterSpacing: "0.025em",
    fontFamily: "monospace",
    padding: 0,
    WebkitFontSmoothing: "auto",
    "::WebkitInputPlaceholder": {
      color: colors.cadetBlue,
    },
    "::placeholder": {
      color: colors.cadetBlue,
    },
    "-MuiTabs-indicator": {
      backgroundColor: colors.orange,
    },
  },
};
export { theme, globalStyles };
