import { JsonLoR } from "features/JobBuilder/json-types";
import { getMeanMinMaxValue } from "./getMeanMinMaxValue";

const RADIANS_TO_DEGREES = 180 / Math.PI;

// getSingleFOVString, if single list items, returns vert and horz FOVs to precision 3
// otherwise it returns "multiple", used for the labels
export const getFOVsStrings = (
  resolution_w: number,
  resolution_h: number,
  sensor_width: JsonLoR,
  focal_length: JsonLoR
) => {
  // return values if both sw and fl are lists of 1 item
  // else it needs to return a string that say "multiple" for each
  let verticalFOV = "multiple";
  let horizontalFOV = "multiple";
  if (
    sensor_width.values instanceof Array &&
    sensor_width.values.length == 1 &&
    focal_length.values instanceof Array &&
    focal_length.values.length == 1
  ) {
    const sw = sensor_width.values[0];
    const fl = focal_length.values[0];

    const sensor_height = (resolution_h / resolution_w) * sw;

    const hFOV = 2 * Math.atan(sw / (2 * fl)) * RADIANS_TO_DEGREES;
    const vFOV = 2 * Math.atan(sensor_height / (2 * fl)) * RADIANS_TO_DEGREES;

    horizontalFOV = hFOV.toFixed(3).toString();
    verticalFOV = vFOV.toFixed(3).toString();
  }

  return { verticalFOV, horizontalFOV };
};

// getMeanFOVNumber takes in h,w,sw and fl, returns vert and horz FOVs to precision 3
// it always returns a number, if multiple values it gives the mean
export const getFOVsNumbers = (
  resolution_w: number,
  resolution_h: number,
  sensor_width: JsonLoR,
  focal_length: JsonLoR
) => {
  // uses mean sw and fl values, so if mutiple or using a range, isn't accurate
  // but, the previews need to show something
  const sw = getMeanMinMaxValue(sensor_width).mean;
  const fl = getMeanMinMaxValue(focal_length).mean;

  const sensor_height = (resolution_h / resolution_w) * sw;

  const hFOV = 2 * Math.atan(sw / (2 * fl)) * RADIANS_TO_DEGREES;
  const vFOV = 2 * Math.atan(sensor_height / (2 * fl)) * RADIANS_TO_DEGREES;

  const horizontalFOV = hFOV.toFixed(3);
  const verticalFOV = vFOV.toFixed(3);

  return { verticalFOV, horizontalFOV };
};
