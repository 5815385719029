import { Tooltip } from "@mui/material";
import * as Icons from "assets/icons";
import { colors } from "components/App/theme";
import { IconsType } from "types/main";
import { Container, Label } from "./IconNode.styled";

export function IconNode({
  icon,
  label,
  selected = false,
  iconsDefaultColor = colors.grey50,
  tooltipText,
}: {
  icon: IconsType;
  label: string;
  selected?: boolean;
  iconsDefaultColor?: string;
  tooltipText?: string;
}) {
  const Icon = Icons[icon];

  return (
    <Tooltip arrow placement="top" title={tooltipText}>
      <Container>
        <Icon
          style={{
            color: selected ? colors.orange : iconsDefaultColor,
            height: "20px",
            width: "20px",
          }}
        />
        <Label selected={selected}>{label}</Label>
      </Container>
    </Tooltip>
  );
}
