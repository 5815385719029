import { useCallback } from "react";
import { Slider } from "@mui/material";
import produce from "immer";
import { SelectorConfigInput } from "components/SelectorsPicker/SelectorConfigInput";
import { exceedLimit } from "components/SelectorsPicker/utils";
import { LoRType } from "domain/Human";
import {
  Container,
  RangeSelectorContainer,
  Unit,
} from "./RangeSelector.styled";

const valueToText = (value: number) => `${value}`;
export function RangeSelector({
  lorValue,
  minValue,
  maxValue,
  unit,
  step = 0.05,
  onUpdate,
}: {
  lorValue: LoRType;
  minValue: number;
  maxValue: number;
  unit: string;
  step?: number;
  onUpdate: (lor: LoRType) => void;
}) {
  const isMinInRange = Number(lorValue.min) === minValue;
  const isMaxInRange = Number(lorValue.max) === maxValue;
  const rangeValue = [Number(lorValue.min), Number(lorValue.max)];

  const minError =
    exceedLimit(lorValue.min, minValue, maxValue) ||
    Number.isNaN(Number(lorValue.min));
  const maxError =
    exceedLimit(lorValue.max, minValue, maxValue) ||
    Number.isNaN(Number(lorValue.max));

  const maxSmallerThanMin = Number(lorValue.max) < Number(lorValue.min);
  const rangeErrorMessage = `Enter a number between ${minValue} and ${maxValue}`;
  const maxSmallerThanMinErrorMessage =
    "The max value shall be greater or equal to the min value.";

  const onUpdateLocal = useCallback(
    (fn: (draft: LoRType) => void) => {
      onUpdate(produce(lorValue, fn));
    },
    [lorValue, onUpdate]
  );

  const onUpdateMax = (v: string) =>
    onUpdateLocal((d) => {
      d.max = v;
    });
  const onUpdateMin = (v: string) =>
    onUpdateLocal((d) => {
      d.min = v;
    });

  const onUpdateRange = (min: string, max: string) =>
    onUpdateLocal((d) => {
      d.min = min;
      d.max = max;
    });

  const marks = [
    {
      value: minValue,
      label: minValue.toString(),
    },
    {
      value: maxValue,
      label: maxValue.toString(),
    },
  ];

  const handleChange = (_event: Event, newValue: number | number[]) => {
    return onUpdateRange(
      (newValue as number[])[0].toString(),
      (newValue as number[])[1].toString()
    );
  };

  return (
    <Container>
      <RangeSelectorContainer
        maxInRange={isMaxInRange}
        minInRange={isMinInRange}
      >
        <Slider
          getAriaLabel={() => "Range Selector"}
          getAriaValueText={valueToText}
          marks={marks}
          max={maxValue}
          min={minValue}
          step={step}
          value={rangeValue}
          valueLabelDisplay="on"
          onChange={handleChange}
        />
      </RangeSelectorContainer>
      <Unit>{unit}</Unit>
      <SelectorConfigInput
        leftError={minError || maxSmallerThanMin}
        leftErrorMessage={
          maxSmallerThanMin ? maxSmallerThanMinErrorMessage : rangeErrorMessage
        }
        leftLabel="Min"
        leftValue={lorValue.min}
        rightError={maxError || maxSmallerThanMin}
        rightErrorMessage={
          maxSmallerThanMin ? maxSmallerThanMinErrorMessage : rangeErrorMessage
        }
        rightLabel="Max"
        rightValue={lorValue.max}
        onChangeLeftValue={onUpdateMin}
        onChangeRightValue={onUpdateMax}
      />
    </Container>
  );
}
