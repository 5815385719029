import { useState } from "react";
import { ProTip } from "components/ProTip";

function useProTip() {
  const [showingProTip, setShowingProTip] = useState(true);

  const defaultCallBackProTip = ({ label }: { label: string }) => (
    <ProTip closeCallback={() => setShowingProTip(false)} label={label} />
  );

  return { ProTip: defaultCallBackProTip, showingProTip, setShowingProTip };
}

export { useProTip };
