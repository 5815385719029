import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";
import { ProTipDisplayType } from "./types";

export const Container = styled(Box)<{ $display: ProTipDisplayType }>`
  width: 100%;
  max-width: 1400px;
  display: ${({ $display }) => $display};
  justify-content: center;
  padding: 16px;
  border: 1px solid ${colors.orange};
  border-radius: 6px;
  background-color: ${colors.white};
`;

export const TipIconContainer = styled(Box)`
  background-color: ${colors.tint5};
  display: grid;
  place-items: center;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  margin-right: 16px;
`;

export const ProTipTitle = styled(Typography)`
  display: inline;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.grey70};
  font-weight: 600;
  padding-right: 4px;
`;

export const Label = styled(Box)<{ $dismissible?: boolean }>`
  display: inline;
  width: 100%;
  padding-right: ${({ $dismissible }) => ($dismissible ? "24px" : "0")};
  font-size: 16px;
  line-height: 24px;
  color: ${colors.grey70};
`;

export const LearnMore = styled(Typography)`
  display: inline;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.orange};
  padding-left: 6px;
  cursor: pointer;
`;
