export function LensShiftVIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 12C20.5 7.31386 16.6861 3.5 12 3.5C7.31386 3.5 3.5 7.31386 3.5 12C3.5 16.6861 7.31386 20.5 12 20.5C16.6861 20.5 20.5 16.6861 20.5 12ZM2.5 12C2.5 6.75614 6.75614 2.5 12 2.5C17.2439 2.5 21.5 6.75614 21.5 12C21.5 17.2439 17.2439 21.5 12 21.5C6.75614 21.5 2.5 17.2439 2.5 12Z"
        fill={fill}
        stroke={fill}
      />
      <path
        d="M12 7.2C14.646 7.2 16.8 9.354 16.8 12C16.8 14.646 14.646 16.8 12 16.8C9.354 16.8 7.2 14.646 7.2 12C7.2 9.354 9.354 7.2 12 7.2ZM12 6C8.688 6 6 8.688 6 12C6 15.312 8.688 18 12 18C15.312 18 18 15.312 18 12C18 8.688 15.312 6 12 6Z"
        fill="black"
      />
    </svg>
  );
}
