import { useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router";
import { ActionButton } from "components/Common/ActionButton";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { Messages } from "constants/messages";
import { Routes } from "constants/routes";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { validatePassword } from "utils/validators";

function ChangePassword() {
  const navigate = useNavigate();
  const { setSuccessMessage, setErrorMessage } =
    useBoundStore.getState().message;
  const profile = useBoundStore((state) => state.profile.data);

  const [loading, setLoading] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [oldPasswordValidationError, setOldPasswordValidationError] =
    useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [newPasswordValidationError, setNewPasswordValidationError] =
    useState("");
  const userId = profile?.id;

  const validate = () => {
    let valid = true;

    const validationResult = validatePassword(newPassword1);
    if (!validationResult.valid) {
      valid = false;
      setNewPasswordValidationError(validationResult.message || "Invalid");
    } else {
      if (newPassword1 !== newPassword2) {
        valid = false;
        setNewPasswordValidationError(Messages.passwordsNotMatching);
      } else {
        setNewPasswordValidationError("");
      }
    }
    return valid;
  };

  const clearValidationErrors = () => {
    setOldPasswordValidationError("");
    setNewPasswordValidationError("");
  };

  const updatePassword = async () => {
    clearValidationErrors();
    if (!userId) {
      return;
    }

    if (!validate()) {
      return;
    }

    setLoading(true);
    try {
      await restClient.changePassword(userId, currentPassword, newPassword1);
      setLoading(false);
      navigate(-1);
      setSuccessMessage(Messages.successPasswordChange);
    } catch (error) {
      setLoading(false);
      Sentry.captureException(error);
      const apiErrors = restClient.APIValidationErrors(error);
      if (apiErrors.errors?.length) {
        const errors = apiErrors.errors;
        const oldPasswordError = errors.find(
          (it: { field: string; error: string }) => it.field === "old_password"
        )?.error;

        if (oldPasswordError) {
          setOldPasswordValidationError(oldPasswordError);
        }

        const newPasswordError = errors.find(
          (it: { field: string; error: string }) => it.field === "password"
        )?.error;

        if (newPasswordError) {
          setNewPasswordValidationError(newPasswordError);
        }
      }
      setErrorMessage(apiErrors.message);
    }
  };

  return (
    <>
      {loading && <FullScreenProgress />}
      <Grid container justifyContent="center">
        <Grid item md={6} xs={8}>
          <Box component="div" mb={5}>
            <Typography variant="h1">Change Password</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item md={6} xs={8}>
          <Box component="div">
            <TextField
              autoFocus
              fullWidth
              required
              error={!!oldPasswordValidationError}
              helperText={oldPasswordValidationError}
              id="email"
              inputProps={{
                "data-testid": "old-password-input",
              }}
              label="Enter old password"
              size="small"
              type="password"
              value={currentPassword}
              variant="outlined"
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </Box>
          <Box component="div" mt={2}>
            <TextField
              fullWidth
              required
              error={!!newPasswordValidationError}
              helperText={newPasswordValidationError}
              id="email"
              inputProps={{
                "data-testid": "new-password-input",
              }}
              label="New password"
              size="small"
              type="password"
              value={newPassword1}
              variant="outlined"
              onChange={(e) => setNewPassword1(e.target.value)}
            />
          </Box>
          <Box component="div" mb={4} mt={2}>
            <TextField
              fullWidth
              required
              error={!!newPasswordValidationError}
              helperText={newPasswordValidationError}
              id="email"
              inputProps={{
                "data-testid": "confirm-password-input",
              }}
              label="Confirm new password"
              size="small"
              type="password"
              value={newPassword2}
              variant="outlined"
              onChange={(e) => setNewPassword2(e.target.value)}
            />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box component="div" mt={2}>
                <ActionButton
                  fullWidth
                  color="secondary"
                  onClick={() => navigate(Routes.ACCOUNT_INFORMATION)}
                >
                  Cancel
                </ActionButton>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box component="div" mt={2}>
                <ActionButton
                  fullWidth
                  color="warning"
                  onClick={updatePassword}
                >
                  Update password
                </ActionButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export { ChangePassword };
