import { useCallback, useState } from "react";
import { TreeView } from "@mui/lab";
import { Box, Stepper, Step, StepLabel, Typography } from "@mui/material";
import "features/JobBuilder/LeftSidebar/left-sidebar.css";
import { ChevronDownIcon, ChevronRightIcon } from "assets/icons";
import {
  ActionOnHoverContainer,
  OnHoverAction,
} from "components/ActionOnHoverContainer";
import { colors } from "components/App/theme";
import { NewUIConfirmationDialog } from "components/dialogs/NewUIConfirmationDialog";
import { JobInfo } from "features/JobBuilder/LeftSidebar/JobInfo";
import { SubjobContainer } from "features/JobBuilder/LeftSidebar/left-sidebar.styled";
import { SectionLabel } from "features/JobBuilder/LeftSidebar/SectionLabel";
import { AdditionalInfo } from "features/JobBuilder/LeftSidebar/SectionLabel/section-label.styled";
import {
  selectNumberOfImagesForAllSubjobs,
  selectNumberOfImagesForEachSubjob,
} from "features/JobBuilder2/store";
import { Pipe2Step } from "features/JobBuilder2/types";
import { useBoundStore } from "store/_boundStore";
import { compact } from "utils/stringUtil";
import { JobGenerationInfo } from "./job-generation-info";
import { getStepIcon, StepConnector } from "./step-icon";

type JobBuilder2LeftSidebarProps = { steps: Pipe2Step[] };

export function JobBuilder2LeftSidebar(props: JobBuilder2LeftSidebarProps) {
  const { steps } = props;

  const [
    selectedStep,
    numberOfImagesForEachSubjob,
    pickedSubjobIndex,
    deleteSubjob,
    duplicateSubjob,
    setSelectedStep,
    setPickedSubjobIndex,
    totalNumberOfImages,
    templateName,
    onUpdateJobName,
    addNewSubjob,
  ] = useBoundStore((s) => [
    s.jobBuilder2.selectedStep,
    selectNumberOfImagesForEachSubjob(s),
    s.jobBuilder2.pickedSubjobIndex,
    s.jobBuilder2.deleteSubjob,
    s.jobBuilder2.duplicateSubjob,
    s.jobBuilder2.setSelectedStep,
    s.jobBuilder2.setPickedSubjobIndex,
    compact(selectNumberOfImagesForAllSubjobs(s)),
    s.jobBuilder2.jobName,
    s.jobBuilder2.updateJobName,
    s.jobBuilder2.addNewSubjob,
  ]);

  const selectedTreeNode = `${selectedStep}`;

  const [subJobForDeletion, setSubJobForDeletion] = useState(-1);
  const deleteSection = (subjobIndex: number) => {
    deleteSubjob(subjobIndex);
    setSubJobForDeletion(-1);
  };
  const allowSubjobDeletion = numberOfImagesForEachSubjob.length > 1;

  const getSubjobOnHoverActions = useCallback(
    (subjobIndex: number) => {
      const candidates: Array<OnHoverAction> = [
        {
          icon: "CopyIcon",
          key: "duplicate",
          "data-testid": "duplicate-subjob-button",
          color: colors.orange,
          onClick: () => {
            duplicateSubjob(subjobIndex);
          },
        },
      ];

      if (allowSubjobDeletion) {
        candidates.push({
          icon: "DeleteIcon",
          key: "delete",
          "data-testid": "delete-subjob-button",
          onClick: () => {
            if (allowSubjobDeletion) {
              setSubJobForDeletion(subjobIndex);
            }
          },
        });
      }

      return candidates;
    },
    [allowSubjobDeletion, duplicateSubjob]
  );

  return (
    <Box component="div" flex="1" overflow="scroll">
      <Box component="div" style={{ color: "white" }}>
        <JobInfo
          templateName={templateName}
          totalNumberOfImages={totalNumberOfImages}
          onAddSection={addNewSubjob}
          onUpdateJobName={onUpdateJobName}
        />
        <JobGenerationInfo />
        <SubjobContainer>
          {numberOfImagesForEachSubjob.map((n, i) => (
            <Box
              key={i}
              component="button"
              data-testid={`subjob-${i}`}
              sx={{
                display: "block",
                width: "100%",
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "none",
              }}
              onClick={() => setPickedSubjobIndex(i)}
            >
              <ActionOnHoverContainer actions={getSubjobOnHoverActions(i)}>
                <SectionLabel
                  additionalInfo={
                    <AdditionalInfo selected={pickedSubjobIndex === i}>
                      ({compact(n)} images)
                    </AdditionalInfo>
                  }
                  icon={"SubJobIcon"}
                  label={`Sub-job ${i + 1}`}
                  selected={pickedSubjobIndex === i}
                  variant="subsection"
                />
              </ActionOnHoverContainer>
            </Box>
          ))}
        </SubjobContainer>

        <TreeView
          aria-label="file system navigator"
          defaultCollapseIcon={
            <ChevronDownIcon style={{ color: colors.grey70 }} />
          }
          defaultExpandIcon={
            <ChevronRightIcon style={{ color: colors.grey70 }} />
          }
          selected={selectedTreeNode}
          style={{
            flexGrow: 1,
            maxWidth: 400,
            overflowY: "auto",
          }}
        ></TreeView>
        {subJobForDeletion >= 0 && (
          <NewUIConfirmationDialog
            icon="TwoToneDeleteIcon"
            onCancel={() => {
              setSubJobForDeletion(-1);
            }}
            onConfirm={() => deleteSection(subJobForDeletion)}
          >
            <Box component="div">
              Are you sure you want to remove Sub-job {subJobForDeletion + 1}?
            </Box>
          </NewUIConfirmationDialog>
        )}
      </Box>
      <Box component="div" p={2}>
        <Typography
          gutterBottom
          display="block"
          fontWeight="600"
          variant="button"
        >
          Sub-job {pickedSubjobIndex + 1}
        </Typography>
        <Stepper
          activeStep={selectedStep}
          connector={<StepConnector />}
          orientation="vertical"
          variant="outlined"
        >
          {steps.map((s, i) => {
            const isAllPrevCompleted = steps
              .slice(0, i)
              .every((s) => s.completed);

            const isActive = selectedStep === i;

            return (
              <Step
                key={s.label}
                active={isActive}
                completed={isAllPrevCompleted && s.completed}
                index={i}
                sx={{
                  opacity: isAllPrevCompleted ? 1 : 0.4,
                }}
                onClick={() =>
                  isAllPrevCompleted ? setSelectedStep(i) : undefined
                }
              >
                <StepLabel
                  StepIconComponent={getStepIcon(i)}
                  componentsProps={{
                    label: {
                      style: {
                        cursor: isAllPrevCompleted ? "pointer" : "unset",
                        color: isActive ? colors.orange : undefined,
                      },
                    },
                  }}
                >
                  {s.label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </Box>
  );
}
