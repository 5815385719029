import { Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const HeadingContainer = styled.div`
  margin-bottom: 16px;
`;

export const Label = styled(Typography)`
  padding-left: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  line-clamp: 1;
  white-space: nowrap;
  color: ${colors.grey70};
`;

export const Description = styled(Typography)`
  padding-left: 8px;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.grey70};
`;
