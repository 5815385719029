import { AccessoriesPart, isAccessoryKey } from "domain/Human";
import { useBoundStore } from "store/_boundStore";
import { JobBuilderGlasses } from "./Glasses";
import { JobBuilderHeadphones } from "./Headphones";
import { JobBuilderHeadwear } from "./Headwear";
import { JobBuilderMasks } from "./Masks";

export function JobBuilderAccessories() {
  const selectedPart = useBoundStore((s) => s.jobBuilder.selectedPart);
  if (!isAccessoryKey(selectedPart)) {
    return null;
  }

  const accessoryUI: {
    [k in AccessoriesPart]: React.ReactNode;
  } = {
    glasses: <JobBuilderGlasses />,
    masks: <JobBuilderMasks />,
    headphones: <JobBuilderHeadphones />,
    headwear: <JobBuilderHeadwear />,
  };
  return <div style={{ width: "100%" }}>{accessoryUI[selectedPart]}</div>;
}
