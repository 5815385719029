import { StringToStringMap } from "utils/typesUtil";

export const getRandomFromArr = <T>(array: T[]): T =>
  array[Math.floor(Math.random() * array.length)];

export const turnEnumToArray = (enumme: StringToStringMap) =>
  Object.keys(enumme).map((key) => enumme[key]);

// if an item is in the array remove it, otherwise add it
export const toggleIncludeItemInArr = <T>(oldArr: T[], newItem: T) =>
  oldArr.includes(newItem)
    ? oldArr.filter((h) => h !== newItem)
    : oldArr.concat(newItem);
