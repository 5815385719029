import { useMemo } from "react";
import { DistKey } from "domain/Human";
import {
  selectNumberOfImagesForActiveSubjobAsString,
  selectPickedSubjob,
} from "features/JobBuilder/store";
import { summingTo100 } from "features/JobBuilder/utils";
import { useBoundStore } from "store/_boundStore";

export const useDistPage = <T extends DistKey>(id: T) => {
  const [
    onAdd,
    onUpdatePart,
    onUpdatePercent,
    onUpdate,
    pickedSection,
    selectedPart,
    selectedIndeces,
    numberOfImages,
    toggleDistListItem,
  ] = useBoundStore((s) => [
    s.jobBuilder.addDistItem(id),
    s.jobBuilder.onUpdateDistPart(id),
    s.jobBuilder.updatePercent(id),
    s.jobBuilder.onUpdateModule(id),
    selectPickedSubjob(s),
    s.jobBuilder.selectedPart,
    s.jobBuilder.selectedIndeces,
    selectNumberOfImagesForActiveSubjobAsString(s),
    s.jobBuilder.toggleDistListItem(id),
  ]);
  const selectedGroupIndex = selectedIndeces[selectedPart];
  const value = useMemo(() => pickedSection[id], [id, pickedSection]);
  const isSummingTo100 = useMemo(() => summingTo100(value), [value]);

  return {
    value,
    isSummingTo100,
    numberOfImages,
    selectedGroupIndex,
    onAdd,
    onUpdate,
    onUpdatePart,
    onUpdatePercent,
    toggleDistListItem,
  };
};
