import React, { useMemo } from "react";
import { defaultRig } from "domain/Human";
import { convertRigStateToJson } from "features/JobBuilder/form-to-input-json";
import { Container } from "./CamerasAndLightsPreview.styled";
import { CameraPreview } from "./components/CameraPreview";
import { ThreeDimensionalComponent } from "./components/ThreeDimensionalComponent";

interface CamerasAndLightsPreviewProps {
  data: (typeof defaultRig)[];
}

export function CamerasAndLightsPreview({
  data,
}: CamerasAndLightsPreviewProps) {
  const jsonValues = useMemo(
    () => data.map((d) => convertRigStateToJson(d)),
    [data]
  );

  return (
    <Container>
      <CameraPreview title="World Preview">
        <ThreeDimensionalComponent rigsData={jsonValues} />
      </CameraPreview>
      {jsonValues.map((rig, i) => (
        <React.Fragment key={rig.type + i}>
          {rig.cameras
            ? rig.cameras.map((cam) => (
                <CameraPreview
                  key={cam.name + rig.type + i}
                  title={`${cam.name} View Preview`}
                >
                  <ThreeDimensionalComponent
                    capturingCameraData={cam}
                    capturingRigData={rig}
                  />
                </CameraPreview>
              ))
            : null}
        </React.Fragment>
      ))}
    </Container>
  );
}
