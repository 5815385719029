import React, { forwardRef } from "react";
import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
} from "@mui/material";

type ButtonProps = Omit<BaseButtonProps, "variant" | "color"> & {
  level?: "primary" | "secondary" | "tertiary" | "link";
  size?: "small" | "medium" | "large";
  children: React.ReactNode;
  disabled?: boolean;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button({ children, disabled, sx, ...props }, ref) {
    const { level = "primary", size = "medium" } = props;

    if (level === "link") {
      return (
        <BaseButton
          {...props}
          ref={ref}
          color="warning"
          disabled={disabled}
          size={size}
          sx={{
            textTransform: "none",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
            ...sx,
          }}
          variant="text"
        >
          {children}
        </BaseButton>
      );
    }

    if (level === "tertiary") {
      return (
        <BaseButton
          {...props}
          ref={ref}
          color="info"
          disabled={disabled}
          size={size}
          sx={{
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
            ...sx,
          }}
          variant="outlined"
        >
          {children}
        </BaseButton>
      );
    }
    if (level === "secondary") {
      return (
        <BaseButton
          {...props}
          ref={ref}
          color="warning"
          disabled={disabled}
          size={size}
          sx={{
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
            ...sx,
          }}
          variant="outlined"
        >
          {children}
        </BaseButton>
      );
    }

    return (
      <BaseButton
        {...props}
        ref={ref}
        color="warning"
        disabled={disabled}
        size={size}
        sx={{
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          ...sx,
        }}
        variant="contained"
      >
        {children}
      </BaseButton>
    );
  }
);
