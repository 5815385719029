import { ConfirmationDialog } from "components/dialogs/ConfirmationDialog";

export function VerifyBigJobDialog({
  totalNumberOfImages,
  submitJob,
  onCancel,
}: {
  totalNumberOfImages: number;
  submitJob: () => void;
  onCancel: () => void;
}) {
  return (
    <ConfirmationDialog
      textContent={
        <>
          {"We're"} happy to see that {"you're"} submitting a job with{" "}
          {totalNumberOfImages} images! Just to confirm that this is
          intentional, and that we will proceed.
        </>
      }
      title="Warning"
      onCancel={onCancel}
      onConfirm={submitJob}
    />
  );
}
