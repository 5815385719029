import React from "react";
import { Box, Button, Tooltip } from "@mui/material";
import { ClipboardIcon } from "assets/icons/Clipboard";
import { colors } from "components/App/theme";

export function CliCommand({ command }: { command: string }) {
  return (
    <Button
      fullWidth
      sx={{
        padding: 0,
      }}
      onClick={() => {
        navigator.clipboard.writeText(command);
      }}
    >
      <Tooltip arrow title="Copy to clipboard">
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            width: "100%",
            background: colors.black,
            fontFamily: "monospace",
            color: colors.brightGreen,
            fontWeight: 600,
            textAlign: "left",
            fontSize: "0.8rem",
            borderRadius: "8px",
          }}
        >
          <Box component="div" flexGrow={2} padding={1.5}>
            {command}
          </Box>
          <Box
            component="div"
            display="inline-block"
            flexGrow={0}
            flexShrink={0}
            height="1.2rem"
            width="1.5rem"
          >
            <ClipboardIcon fill={colors.white} />
          </Box>
        </Box>
      </Tooltip>
    </Button>
  );
}
