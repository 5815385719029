import { GeomRegion } from "features/JobBuilder2/steps/threeDimensional/GeomRegion";
import { getCircleInPolygon } from "features/JobBuilder2/steps/threeDimensional/utils/getCircleInPolygon";
import { DisallowActivityReason } from "features/JobBuilder2/types";
import { ActivityStance } from "./Location";

export const radiusFrom2D = (width: number, depth: number) => {
  return 0.5 * ((width * 0.5) ** 2 + (depth * 0.5) ** 2) ** 0.5;
};

export const getFittingActivitiesForZone = (
  geomRegion: GeomRegion,
  activities: {
    stance: ActivityStance;
    name: string;
    bounding_box: { width: number; depth: number };
  }[]
) => {
  const { geom, matchingActivityStance } = geomRegion;
  const disallowedActivityReasons: Record<string, DisallowActivityReason> = {};
  const fittingActivities = activities.filter((activity) => {
    if (activity.stance !== matchingActivityStance) {
      disallowedActivityReasons[activity.name] =
        DisallowActivityReason.MismatchingStance;
      return false;
    }

    if (activity.stance === ActivityStance.Sitting) {
      return true;
    }

    const { width, depth } = activity.bounding_box;
    const radius = radiusFrom2D(width, depth);

    const fitsInSpace = getCircleInPolygon(geom, radius) !== null;
    if (!fitsInSpace) {
      disallowedActivityReasons[activity.name] =
        DisallowActivityReason.NeedsMoreSpace;
    }
    return fitsInSpace;
  });

  return {
    fittingActivities,
    disallowedActivityReasons,
  };
};
