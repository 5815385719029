import { useMemo } from "react";
import { ActivitiesPickerDialog } from "components/dialogs/ActivitiesPickerDialog/ActivitiesPickerDialog";
import { InfoDialog } from "components/dialogs/InfoDialog";
import {
  MAX_CHARACTERS_PER_SCENE,
  selectExceededMaxZones,
  selectPickedSubjob,
  selectPickedZoneToAssignActivitiesHasActivities,
} from "features/JobBuilder2/store";
import { useBoundStore } from "store/_boundStore";

export function ActivitiesDialogs() {
  const [
    setActivitiesPickerDialogData,
    updateSelectedActivitiesOfZone,
    activitiesPickerDialogData,
    pickedSubjob,
    exceededMaxZones,
    pickedZoneHasActivities,
    activities,
  ] = useBoundStore((s) => [
    s.jobBuilder2.setActivitiesPickerDialogData,
    s.jobBuilder2.updateSelectedActivitiesOfZone,
    s.jobBuilder2.activitiesPickerDialogData,
    selectPickedSubjob(s),
    selectExceededMaxZones(s),
    selectPickedZoneToAssignActivitiesHasActivities(s),
    s.catalogData.activities,
  ]);

  const activitiesForSelectedActivityZone = useMemo(() => {
    if (
      pickedSubjob.activityZones.length === 0 ||
      !activitiesPickerDialogData
    ) {
      return [];
    }
    const pickedActivityZone =
      pickedSubjob.activityZones[activitiesPickerDialogData.zoneIndex];
    return pickedActivityZone ? pickedActivityZone.activities : [];
  }, [pickedSubjob, activitiesPickerDialogData]);

  if (!activitiesPickerDialogData) {
    return null;
  }

  if (exceededMaxZones && !pickedZoneHasActivities) {
    return (
      <InfoDialog
        fullScreen={false}
        title="Exceeded Max Zones"
        onClose={() => setActivitiesPickerDialogData(null)}
      >
        You cannot have more than {MAX_CHARACTERS_PER_SCENE} characters in one
        scene
      </InfoDialog>
    );
  }

  return (
    <ActivitiesPickerDialog
      activities={activities}
      allowedActivitiesNames={activitiesPickerDialogData.allowedActivityNames}
      disallowedActivityReasons={
        activitiesPickerDialogData.disallowedActivityReasons
      }
      value={activitiesForSelectedActivityZone}
      onCancel={() => setActivitiesPickerDialogData(null)}
      onConfirm={updateSelectedActivitiesOfZone}
    />
  );
}
