import { IconContainer } from "./icons.styled";

export function FileCopyIcon({
  style,
  fill = "currentColor",
  onClick,
}: {
  style?: React.CSSProperties;
  fill?: string;
  onClick?: () => void;
} = {}) {
  return (
    <IconContainer onClick={onClick}>
      <svg
        fill="none"
        height="25"
        style={style}
        viewBox="0 0 24 25"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5 1.5H4.5C3.4 1.5 2.5 2.4 2.5 3.5V17.5H4.5V3.5H16.5V1.5ZM15.5 5.5H8.5C7.4 5.5 6.51 6.4 6.51 7.5L6.5 21.5C6.5 22.6 7.39 23.5 8.49 23.5H19.5C20.6 23.5 21.5 22.6 21.5 21.5V11.5L15.5 5.5ZM8.5 21.5V7.5H14.5V12.5H19.5V21.5H8.5Z"
          fill={fill}
        />
      </svg>
    </IconContainer>
  );
}
