import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { flatten } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BaseWizardTab } from "components/BaseWizardTab";
import {
  Description,
  Spacer,
} from "components/BaseWizardTab/BaseWizardTab.styled";
import { Breadcrumb } from "components/Breadcrumb";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { TemplatePreviewDialog } from "components/dialogs/TemplatePreviewDialog";
import { PL2_JOB_STATE_VERSION } from "constants/constants";
import { Routes } from "constants/routes";
import { jobBuilder2Enalbed, jobBuilder1Enabled } from "domain/Human";
import { Org } from "domain/Org";
import { segmentTrack } from "features/JobBuilder2/track-service";
import { getNewPipe2Subjob } from "features/JobBuilder2/types";
import { SmallTitle } from "features/JobBuilderShared/Styles/styles";
import { restClient, strapiRestClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { selectedOrgSelector } from "store/orgStore";
import { CardOptionList } from "./OptionsCardList/options-card-list";
import { TemplateTabHeader, TemplateTabs } from "./styled";
import {
  Category,
  CategoryType,
  Template1,
  Template2,
  TemplatesParams,
} from "./types";
import {
  getSelectedCategory,
  getSelectedSubcategory,
  getSelectedTemplate,
  hasItems,
} from "./utils";

const isPipe1 = (c: Category) =>
  !c.attributes.isPipe2 && c.attributes.type !== "characters";

const isPipe2 = (c: Category) =>
  c.attributes.isPipe2 && c.attributes.type !== "characters";

const isCharacters = (c: Category) => c.attributes.type === "characters";

const getSection = (
  shouldShowPredicate: (o: Org) => boolean,
  title: string,
  description: string,
  type: CategoryType,
  categoriesToShowPredicate: (c: Category) => boolean
) => ({
  shouldShowPredicate,
  title,
  description,
  type,
  categoriesToShowPredicate,
});

const sections = [
  getSection(
    jobBuilder2Enalbed,
    "Synthesis Scenarios",
    "Create multi-human images and videos with region-based controls",
    "scenarios",
    isPipe2
  ),
  getSection(
    jobBuilder1Enabled,
    "Synthesis Humans",
    "Create images and animations of a single human with human-oriented camera controls",
    "humans",
    isPipe1
  ),
  getSection(
    jobBuilder1Enabled,
    "Synthesis Characters",
    "Create characters with a variety of skin tones, hair styles, clothing, etc.",
    "characters",
    isCharacters
  ),
];

function getNumberForIdentities(category: Category) {
  const templates = category.attributes.templates.data;

  return (templates as Template1[]).reduce((acc, template) => {
    const humans = template.attributes.json.humans;

    const identities = flatten(humans.map((human) => human.identities.ids));

    return acc + identities.length;
  }, 0);
}

type TemplateTabProps = {
  title: string;
  orgName: string;
};

export function TemplatesTab(props: TemplateTabProps) {
  const { title, orgName } = props;

  const [categories, setCategories] = useState<Category[]>([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [showTemplatePreviewModal, setShowTemplatePreviewModal] = useState<
    Template1 | Template2 | null
  >(null);
  const [currentTemplateType, setCurrentTemplateType] =
    useState<CategoryType>("humans");

  const { setErrorMessage } = useBoundStore.getState().message;
  const navigate = useNavigate();
  const location = useLocation();
  const org = useBoundStore(selectedOrgSelector(orgName));
  const currentPath: string = location.pathname;
  const { templateCategoryId, templateSubcategoryId } =
    useParams<TemplatesParams>();
  const [userID, isSupportUser] = useBoundStore((s) => [
    s.profile.data?.id,
    s.profile.data?.is_support,
  ]);

  useEffect(() => {
    async function fetchTemplatesCategories() {
      try {
        setIsLoadingCategories(true);
        const categories = await strapiRestClient.getTemplateCategories();
        setCategories(
          categories.map((category) =>
            isCharacters(category)
              ? ({
                  ...category,
                  attributes: {
                    ...category.attributes,
                    title: `${
                      category.attributes.title
                    } (${getNumberForIdentities(category)} IDs)`,
                  },
                } as Category)
              : category
          )
        );
      } catch (error) {
        setErrorMessage(restClient.APIErrorMessage(error));
      }
      setIsLoadingCategories(false);
    }
    fetchTemplatesCategories();
  }, [setErrorMessage]);

  const selectedCategory = getSelectedCategory(categories, templateCategoryId);
  const selectedSubcategory = getSelectedSubcategory(
    selectedCategory,
    templateSubcategoryId
  );

  useEffect(() => {
    if (!(selectedCategory && selectedCategory.attributes.isPipe2 && userID)) {
      return;
    }
    segmentTrack("selected category", userID, {
      categoryName: selectedCategory.attributes.title,
    });
  }, [selectedCategory, userID]);

  const sectionsToShow = useMemo(
    () =>
      sections.filter(
        (s) =>
          org && s.shouldShowPredicate(org) && currentTemplateType === s.type
      ),
    [currentTemplateType, org]
  );

  useEffect(() => {
    if (
      !(selectedSubcategory && selectedSubcategory.attributes.isPipe2 && userID)
    ) {
      return;
    }
    segmentTrack("selected subcategory", userID, {
      subcategoryName: selectedSubcategory.attributes.title,
    });
  }, [selectedSubcategory, userID]);

  const orgPathSufix = `?org=${orgName}`;

  const description = useMemo(() => {
    if (selectedSubcategory) {
      return selectedSubcategory.attributes.description;
    }

    if (selectedCategory) {
      return selectedCategory?.attributes.description;
    }
  }, [selectedCategory, selectedSubcategory]);

  const breadcrumbs = useMemo(
    () => [
      {
        label: title,
        href: -1,
      },
      ...(selectedCategory
        ? [
            {
              label: selectedCategory?.attributes.title,
              href:
                Routes.HUMAN_JOB_TEMPLATES_CATEGORY_FN(selectedCategory.id) +
                orgPathSufix,
            },
          ]
        : []),
      ...(selectedSubcategory
        ? [
            {
              label: selectedSubcategory?.attributes.title,
              href:
                Routes.HUMAN_JOB_TEMPLATES_SUBCATEGORY_FN(
                  selectedCategory?.id || 0,
                  selectedSubcategory.id
                ) + orgPathSufix,
            },
          ]
        : []),
    ],
    [orgPathSufix, selectedCategory, selectedSubcategory, title]
  );

  const closeTemplatePreviewModal = () => setShowTemplatePreviewModal(null);

  const onSelectCategory = (categoryId: number, type: string) => {
    if (type === "characters") {
      const category = categories.find((c) => c.id === categoryId);

      if (!category) return;

      navigate(Routes.CHARACTERS_BUILDER + orgPathSufix, {
        state: {
          name: category?.attributes.title,
          json: category?.attributes.templates.data[0].attributes.json,
        },
      });
    } else {
      navigate(
        Routes.HUMAN_JOB_TEMPLATES_CATEGORY_FN(categoryId) + orgPathSufix
      );
    }
  };

  const onSelectSubcategory = (subcategoryId: number) =>
    navigate(`${currentPath}/subcategory/${subcategoryId}${orgPathSufix}`);

  const onSelectTemplate = async (templateId: number) => {
    const previewCategory = selectedSubcategory ?? selectedCategory;
    if (!previewCategory) {
      return;
    }
    const selectedTemplate = getSelectedTemplate(previewCategory, templateId);
    if (!selectedTemplate) {
      return;
    }
    if (!previewCategory.attributes.isPipe2) {
      setShowTemplatePreviewModal(selectedTemplate);
      return;
    }
    try {
      const locationOptions = (selectedTemplate as unknown as Template2)
        .attributes.json.locations;
      if (!locationOptions.length) {
        setErrorMessage("A template should have atleas one location option");
        return;
      }
      const draft = await restClient.createPL2Draft(
        orgName,
        selectedTemplate.attributes.title,
        [getNewPipe2Subjob({ locationOptions })],
        PL2_JOB_STATE_VERSION
      );
      segmentTrack("selected a template", userID, {
        templateName: selectedTemplate.attributes.title,
      });
      navigate(Routes.JOB_BUILDER2_FN(draft.id) + location.search);
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
    }
  };

  const onConfirmGoToNewPL1UI = () => {
    navigate(Routes.JOB_BUILDER + location.search, {
      state: {
        name: showTemplatePreviewModal?.attributes.title,
        json: showTemplatePreviewModal?.attributes.json,
      },
    });
    closeTemplatePreviewModal();
  };

  const handleTabChange = (
    _event: ChangeEvent<object>,
    selectedTab: CategoryType
  ) => {
    if (selectedCategory || selectedSubcategory) {
      navigate(-1);
    }

    setCurrentTemplateType(selectedTab);
  };

  if (isLoadingCategories) {
    return <FullScreenProgress />;
  }

  return (
    <>
      <TemplateTabs value={currentTemplateType} onChange={handleTabChange}>
        <TemplateTabHeader label="Detailed Human Builder" value="humans" />
        <TemplateTabHeader
          label="Multi-human Scenario Builder"
          value="scenarios"
        />
        {isSupportUser && (
          <TemplateTabHeader
            label="Character Group Builder"
            value="characters"
          />
        )}
      </TemplateTabs>

      <BaseWizardTab
        HeaderComponent={
          selectedCategory ? <Breadcrumb breadcrumbs={breadcrumbs} /> : null
        }
        description={description}
      >
        {!selectedCategory && (
          <>
            {sectionsToShow.map((section) => (
              <React.Fragment key={section.title}>
                <SmallTitle>{section.title}</SmallTitle>
                <Description>{section.description}</Description>
                <CardOptionList
                  hideThumbnails={section.type === "characters"}
                  options={categories.filter(section.categoriesToShowPredicate)}
                  onOptionClick={(id) => onSelectCategory(id, section.type)}
                />
                <Spacer />
              </React.Fragment>
            ))}
          </>
        )}
        {selectedCategory &&
          hasItems(selectedCategory.attributes.subcategories.data) &&
          !selectedSubcategory && (
            <CardOptionList
              options={selectedCategory.attributes.subcategories.data}
              onOptionClick={onSelectSubcategory}
            />
          )}
        {selectedCategory &&
          !selectedSubcategory &&
          !hasItems(selectedCategory.attributes.subcategories.data) && (
            <>
              <CardOptionList
                options={selectedCategory.attributes.templates.data}
                onOptionClick={onSelectTemplate}
              />
              {showTemplatePreviewModal && (
                <TemplatePreviewDialog
                  template={showTemplatePreviewModal}
                  onCancel={closeTemplatePreviewModal}
                  onConfirmGoToNewPL1UI={onConfirmGoToNewPL1UI}
                />
              )}
            </>
          )}
        {selectedSubcategory && (
          <>
            <CardOptionList
              options={selectedSubcategory.attributes.templates.data}
              onOptionClick={onSelectTemplate}
            />
            {showTemplatePreviewModal && (
              <TemplatePreviewDialog
                template={showTemplatePreviewModal}
                onCancel={closeTemplatePreviewModal}
                onConfirmGoToNewPL1UI={onConfirmGoToNewPL1UI}
              />
            )}
          </>
        )}
      </BaseWizardTab>
    </>
  );
}
