import { IconContainer } from "./icons.styled";

export function CopyIcon({
  style = { height: "16px" },
  fill = "currentColor",
  onClick,
}: {
  style?: React.CSSProperties;
  fill?: string;
  onClick?: () => void;
} = {}) {
  return (
    <IconContainer onClick={onClick}>
      <svg
        fill="none"
        height="16"
        style={style}
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.9993 1.16669H2.99935C2.26602 1.16669 1.66602 1.76669 1.66602 2.50002V11.8334H2.99935V2.50002H10.9993V1.16669ZM12.9993 3.83335H5.66602C4.93268 3.83335 4.33268 4.43335 4.33268 5.16669V14.5C4.33268 15.2334 4.93268 15.8334 5.66602 15.8334H12.9993C13.7327 15.8334 14.3327 15.2334 14.3327 14.5V5.16669C14.3327 4.43335 13.7327 3.83335 12.9993 3.83335ZM12.9993 14.5H5.66602V5.16669H12.9993V14.5Z"
          fill={fill}
        />
      </svg>
    </IconContainer>
  );
}
