import { useCallback, useState } from "react";
import { Videocam } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from "@mui/material";
import produce from "immer";
import { Collapsible } from "components/Collapsible";
import { InfoDialog } from "components/dialogs/InfoDialog";
import { defaultCamera } from "domain/Human";
import {
  defaultPipe2Rig,
  defaultRig,
  defaultSubjob,
  UpdatePickedSubjobFn,
} from "features/JobBuilder2/types";
import { RigLocation } from "features/JobBuilderShared/Rig/rig-location";
import { UV } from "features/JobBuilderShared/Rig/uv";
import { ToolbarIconContainer } from "./camera-rig-dialog.styled";
import { Camera2 } from "./camera2";
import { LeftNavigation } from "./left-navigation";

export function CameraRigDialog({
  open,
  onOpen,
  onClose,
  onCameraRigSettingsChange,
  cameraRig,
  onCameraAdded,
  onCameraRemoved,
}: {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  onCameraRigSettingsChange: UpdatePickedSubjobFn;
  cameraRig: typeof defaultSubjob.rig;
  onCameraAdded: () => void;
  onCameraRemoved: () => void;
}) {
  /**
   * 0 means Rig Settings
   * 1...n means Camera Settings for n-th camera
   */
  const [activeView, setActiveView] = useState(0);
  const [attemptToAddCamera, setAttemptToAddCamera] = useState(false);

  const onUpdatePickerSettings = useCallback(
    (v: typeof defaultSubjob.rig) =>
      onCameraRigSettingsChange((d) => {
        d.rig = v;
      }),
    [onCameraRigSettingsChange]
  );

  const onUpdateLocal = useCallback(
    (fn: (draft: typeof defaultRig) => void) => {
      onUpdatePickerSettings(produce(cameraRig, fn));
    },
    [onUpdatePickerSettings, cameraRig]
  );

  const onDeleteCamera = (cameraIndex: number) => () => {
    setActiveView(0);

    onUpdateLocal((d) => {
      d.cameras.splice(cameraIndex, 1);
    });
    onCameraRemoved();
  };

  const onAddCamera = () => {
    setAttemptToAddCamera(true);
  };

  const onUpdateCamera = (i: number) => (v: typeof defaultCamera) =>
    onUpdateLocal((d) => {
      d.cameras[i] = v;
    });

  const openRigSettings = () => setActiveView(0);
  const openCameraSettings = (i: number) => () => setActiveView(i);

  return (
    <SwipeableDrawer
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        style: {
          height: "calc(100vh - 72px)",
          width: "100%",
          maxWidth: "100%",
          maxHeight: "100%",
          overflow: "hidden",
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        },
      }}
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        height="100%"
        overflow="auto"
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ToolbarIconContainer>
              <Videocam />
            </ToolbarIconContainer>{" "}
            <Typography ml={1} variant="h4">
              Location rig
            </Typography>
          </div>
          <Button color="warning" variant="contained" onClick={onClose}>
            Save changes
          </Button>
        </Toolbar>
        <Divider />
        <Box
          component="div"
          display="flex"
          flex={1}
          flexDirection="row"
          overflow="hidden"
        >
          <LeftNavigation
            activeView={activeView}
            cameras={cameraRig.cameras}
            openCameraSettings={openCameraSettings}
            openRigSettings={openRigSettings}
            onAddCamera={onAddCamera}
            onDeleteCamera={onDeleteCamera}
          />
          {attemptToAddCamera && (
            <InfoDialog
              fullScreen={false}
              title="Coming Soon"
              onClose={() => setAttemptToAddCamera(false)}
            >
              <Typography>Multi camera support coming soon.</Typography>
            </InfoDialog>
          )}
          <Divider orientation="vertical" />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              overflow: "auto",
            }}
          >
            {activeView === 0 && (
              <>
                <Collapsible
                  justifyContent="flex-start"
                  label="Camera Rig Location within Preset Grid"
                >
                  <Box component="div" mb={3}>
                    <UV
                      limits={defaultPipe2Rig}
                      showNormalDistribution={false}
                      u={cameraRig.u}
                      v={cameraRig.v}
                      onUpdateLocal={onUpdateLocal}
                    />
                  </Box>
                </Collapsible>
                <RigLocation
                  justifyContent="flex-start"
                  type="preset_location2"
                  value={cameraRig}
                  onUpdateLocal={onUpdateLocal}
                />
              </>
            )}
            {activeView > 0 && (
              <Camera2
                value={cameraRig.cameras[activeView - 1]}
                onUpdate={onUpdateCamera(activeView - 1)}
              />
            )}
          </Box>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
}
