export const disableBackdropClickClose = (
  reason: string,
  onClose: (() => void) | null
) => {
  if (reason === "backdropClick") {
    return false;
  }
  if (onClose) {
    onClose();
  }
};
