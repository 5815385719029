import { useState } from "react";
import { Box } from "@mui/material";
import { colors } from "components/App/theme";
import { ConfirmationDialog } from "components/dialogs/ConfirmationDialog";
import { LabelWithValue } from "domain/Human";
import { getImage } from "features/JobBuilder2/locations-data";

export function JobBuilder2Location({
  value,
  onUpdate,
  shouldShowWarningOnUpdate,
  applicableLocations,
}: {
  value: string;
  onUpdate: (s: string) => void;
  shouldShowWarningOnUpdate: () => boolean;
  applicableLocations: LabelWithValue[];
}) {
  const [showWarning, setShowWarning] = useState<string | null>(null);

  const onLocationChange = (location: string) => () => {
    if (shouldShowWarningOnUpdate()) {
      setShowWarning(location);
    } else {
      setShowWarning(null);
      onUpdate(location);
    }
  };

  const onConfirm = () => {
    if (showWarning) {
      onUpdate(showWarning);
    }

    setShowWarning(null);
  };

  const onCancel = () => {
    setShowWarning(null);
  };

  return (
    <Box
      component="div"
      display="flex"
      flex="1"
      flexDirection="column"
      mt={-2}
      width="100%"
    >
      {showWarning && (
        <ConfirmationDialog
          textContent={
            <>
              Changing location will reset all setup done for this subjob. Are
              you sure?
            </>
          }
          title="Change location"
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
      <Box component="div" maxWidth="100%" overflow="auto">
        <div
          style={{
            display: "flex",
            paddingTop: 32,
            marginBottom: 32,
            paddingLeft: 1,
          }}
        >
          {applicableLocations.map((location, i) => {
            const isActive = location.value === value;
            return (
              <div
                key={i}
                style={{
                  display: "flex",
                  padding: 8,
                  alignItems: "center",
                  border: `1px solid ${
                    isActive ? colors.bright[2] : colors.grey20
                  }`,
                  // box shadow as second border
                  boxShadow: isActive
                    ? `0 0 0 1px ${colors.bright[2]}`
                    : undefined,
                  backgroundColor: isActive ? colors.tint4 : "white",
                  cursor: "pointer",
                  borderRadius: 4,
                  marginRight: 8,
                }}
                onClick={onLocationChange(location.value)}
              >
                <img
                  alt={location.label}
                  src={getImage(location.value)}
                  style={{
                    width: 32,
                    height: 32,
                    display: "inline-block",
                    marginRight: 8,
                    borderRadius: 4,
                    backgroundColor: colors.grey50,
                  }}
                />
                <span>{location.label}</span>
              </div>
            );
          })}
        </div>
      </Box>
      {value && (
        <div
          style={{
            position: "relative",
            minHeight: 562,
            overflow: "hidden",
            borderRadius: 4,
            flex: 1,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: colors.black,
              opacity: 0.6,
              zIndex: 1,
            }}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              // set the image as a blured background
              backgroundImage: `url(${getImage(value)})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              // zoom the background
              transform: "scale(1.1)",
              overflow: "hidden",
              filter: "blur(10px)",
              zIndex: 0,
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 2,
            }}
          >
            <img
              alt={value}
              src={getImage(value)}
              style={{
                maxWidth: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      )}
    </Box>
  );
}
