import React from "react";
import { Button } from "@mui/material";
import { colors } from "components/App/theme";

export function TutorialCardButton({
  children,
  onClick,
  color,
  endIcon,
  "data-testid": dataTestId,
}: {
  children: React.ReactNode;
  endIcon?: React.ReactNode;
  onClick: () => void;
  color:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  "data-testid"?: string;
}) {
  return (
    <Button
      fullWidth
      color={color}
      data-testid={dataTestId}
      endIcon={endIcon}
      sx={{
        "&:hover": { backgroundColor: colors.bright[1], color: colors.white },
      }}
      variant="contained"
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
