import React, { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { StripeCardNumberElement } from "@stripe/stripe-js";
import { colors } from "components/App/theme";
import { ActionButton } from "components/Common/ActionButton";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { ErrorMessage } from "components/Common/SnackbarMessages/ErrorMessage";
import { useStripeFormOptions } from "hooks/useStripeFormOptions";
import { restClient } from "services/restClient/main";
import { validateBillingName } from "utils/validators";

function StripeForm({
  orgName,
  onPaymentMethodSaved,
}: {
  orgName: string | undefined;
  onPaymentMethodSaved: (pm?: string | null) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [billingName, setBillingName] = useState("");
  const [billingNameValidationError, setBillingNameValidationError] =
    useState("");
  const [secret, setSecret] = useState("");

  useEffect(() => {
    let isCancelled = false;
    if (orgName) {
      const fetchSecret = async () => {
        setLoading(true);
        try {
          const secret = await restClient.getStripeSecret(orgName);
          if (isCancelled) {
            return;
          }

          setSecret(secret);
        } catch (error) {
          Sentry.captureException(error);
          setErrorMessage(restClient.APIErrorMessage(error));
        }
        setLoading(false);
      };

      fetchSecret();
    }
    return () => {
      isCancelled = true;
    };
  }, [orgName]);

  const stripe = useStripe();
  const elements = useElements();
  const options = useStripeFormOptions();

  const validate = () => {
    const result = validateBillingName(billingName);
    if (!result.valid) {
      setBillingNameValidationError(result.message || "Invalid");
    }

    return result.valid;
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (!validate()) {
      return;
    }
    window.analytics.track("Payment Info Submit Clicked");
    setLoading(true);
    try {
      const result = await stripe.confirmCardSetup(secret, {
        payment_method: {
          card: elements.getElement(
            CardNumberElement
          ) as StripeCardNumberElement,
          billing_details: {
            name: billingName,
          },
        },
      });
      if (result.error) {
        Sentry.captureException(result.error);
        setErrorMessage(
          result.error?.message || "Unknown error from Stripe API"
        );
        setLoading(false);
      } else {
        const pm = result.setupIntent?.payment_method;
        window.analytics.track("Payment Info Entered");
        setLoading(false);
        onPaymentMethodSaved(pm);
      }
    } catch (error) {
      // {code: "invalid_number", type: "validation_error", message: "Your card number is invalid."}
      // {code: "incomplete_number", type: "validation_error", message: "Your card number is incomplete."}
      // {code: "incomplete_expiry", type: "validation_error", message: "Your card's expiration date is incomplete."}
      // {code: "invalid_expiry_year_past", type: "validation_error", message: "Your card's expiration year is in the past."}
      // {code: "incomplete_cvc", type: "validation_error", message: "Your card's security code is incomplete."}
      // {code: "incorrect_cvc", type:"validation_error", message:"..."}
      Sentry.captureException(error);
      console.error(error);
      setErrorMessage(restClient.APIErrorMessage(error));
      setLoading(false);
    }
  };

  return (
    <Box
      bgcolor={colors.neutral[0]}
      borderRadius="4px"
      component="div"
      flex="0 1 480px"
      mt={4}
      padding={4}
      paddingTop={2}
    >
      {loading && <FullScreenProgress />}
      <ErrorMessage
        message={errorMessage}
        onDismiss={() => setErrorMessage("")}
      />
      <Box component="div" mb={3}>
        <Box component="div" mb={0.5}>
          <Typography variant="body2">Billing Name</Typography>
        </Box>
        <TextField
          autoFocus
          fullWidth
          InputProps={{
            disableUnderline: true,
          }}
          error={!!billingNameValidationError}
          helperText={billingNameValidationError}
          inputProps={{
            className: "billing-element",
          }}
          placeholder="Name on card"
          value={billingName}
          variant="standard"
          onChange={(e) => {
            setBillingName(e.target.value);
            setBillingNameValidationError("");
          }}
        ></TextField>
      </Box>
      <Box component="div" mb={3}>
        <Box component="div" mb={0.5}>
          <Typography variant="body2">Card number</Typography>
        </Box>

        <CardNumberElement options={options} />
      </Box>
      <Box component="div" mb={3}>
        <Box component="div" mb={0.5}>
          <Typography variant="body2">Expiration date</Typography>
        </Box>
        <CardExpiryElement options={options} />
      </Box>
      <Box component="div" mb={4}>
        <Box component="div" mb={0.5}>
          <Typography variant="body2">CVC</Typography>
        </Box>
        <CardCvcElement options={options} />
      </Box>
      <Box component="div">
        <ActionButton
          fullWidth
          color="warning"
          disabled={!stripe}
          onClick={handleSubmit}
        >
          Save Card
        </ActionButton>
      </Box>
    </Box>
  );
}

export { StripeForm };
