import { useMemo } from "react";
import { useGLTF } from "@react-three/drei";
import { MeshStandardMaterial } from "three";
import cameraModel from "./cameraModel.glb";

const materialColors = {
  Cam: "#B9B9B9",
  Rig: "#525252",
};

export function CameraModel(props) {
  const { nodes } = useGLTF(cameraModel);

  const materials = useMemo(() => {
    return Object.fromEntries(
      Object.entries(materialColors).map(([k, color]) => {
        const characterMaterial = new MeshStandardMaterial();
        characterMaterial.roughness = 1;
        characterMaterial.metalness = 0.5;
        characterMaterial.color.set(color);

        return [k, characterMaterial];
      })
    );
  }, []);

  return (
    <group layers={3} {...props} dispose={null}>
      <group rotation={[0, -Math.PI / 2, 0]} scale={[0.95, 1, 1]}>
        <mesh geometry={nodes.Cube001.geometry} material={materials.Cam} />
        <mesh geometry={nodes.Cube001_1.geometry} material={materials.Rig} />
      </group>
    </group>
  );
}

useGLTF.preload(cameraModel);
