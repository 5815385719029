import React, { useMemo, useState } from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { StripeForm } from "components/StripeForm";
import { StripeFormWrapper } from "components/StripeFormWrapper";
import { URLParams } from "constants/params";
import { Routes } from "constants/routes";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { selectedOrgSelector } from "store/orgStore";
import { PODialog } from "./PODialog";

export enum Mode {
  EditAndOfferPO,
  EditOnly,
  AddAndOfferPO,
}

function PaymentInfo({
  showTitle,
  mode,
  orgName,
}: {
  showTitle: boolean;
  mode: Mode;
  orgName: string;
}) {
  const navigate = useNavigate();
  const [isPODialogOpen, setIsPODialogOpen] = useState(false);
  const [isSubmittingPORequest, setIsSubmittingPORequest] = useState(false);
  const { replaceOrgs } = useBoundStore.getState().org;
  const { org, loading } = useBoundStore((state) => {
    return {
      org: selectedOrgSelector(orgName)(state),
      loading: state.org.loading,
    };
  });

  const email = useBoundStore((state) => state.profile.data?.email);

  const { setSuccessMessage, setErrorMessage } =
    useBoundStore.getState().message;
  const onConfirmSubmitPORequest = async (notes: string) => {
    if (!org) {
      return;
    }
    try {
      setIsSubmittingPORequest(true);
      await restClient.submitPORequest(org.name, notes);
      setSuccessMessage("Your PO has been successfully submitted");
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setIsPODialogOpen(false);
    setIsSubmittingPORequest(false);
  };

  function NeedPurchase() {
    return (
      <Box component="div" sx={{ color: "warning.main", display: "inline" }}>
        -or-{" "}
        <Box component="div" sx={{ display: "inline" }}>
          Need Purchase Order (PO) billing?{" "}
        </Box>
        <u
          style={{ cursor: "pointer" }}
          onClick={() => setIsPODialogOpen(true)}
        >
          {" "}
          Contact Us{" "}
        </u>
        to discuss a contract
      </Box>
    );
  }

  const modeToHeader = useMemo(
    () => [
      <NeedPurchase key="need-purchase" />,
      <></>,
      <>
        <Box component="div">
          We need a credit card on file for{" "}
          <Box component="div" display="inline" fontWeight={600}>
            {!!org && org.name}
          </Box>
          .
        </Box>
        <Box component="div" sx={{ typography: "body2" }}>
          Once you begin creating images you will be billed based on monthly
          usage <NeedPurchase />
        </Box>
        <Box component="div" mt={2}>
          <Link
            href="https://synthesis.ai/pricing"
            target="_blank"
            underline="hover"
          >
            See our pricing page for more details.
          </Link>
        </Box>
      </>,
    ],
    [org]
  );

  const header = useMemo(() => modeToHeader[mode], [mode, modeToHeader]);

  if (loading || isSubmittingPORequest) {
    return <FullScreenProgress />;
  }

  return (
    <>
      {isPODialogOpen && email && (
        <PODialog
          email={email}
          orgName={orgName}
          onCancel={() => setIsPODialogOpen(false)}
          onConfirm={onConfirmSubmitPORequest}
        />
      )}

      <Grid container justifyContent="center">
        <Grid item md={8} xs={12}>
          {showTitle && mode === Mode.AddAndOfferPO && (
            <Box component="div" mb={2}>
              <Typography variant="h1">Payment Info</Typography>{" "}
            </Box>
          )}
          {org && (
            <>
              {header}
              <StripeFormWrapper
                StripeForm={
                  <StripeForm
                    orgName={org.name}
                    onPaymentMethodSaved={async () => {
                      if (
                        mode === Mode.EditAndOfferPO ||
                        mode === Mode.EditOnly
                      ) {
                        navigate(-1);
                      } else {
                        try {
                          window.analytics.track("Signed Up", {
                            details: "saved payment info for new org",
                            org: org.name,
                          });
                          await replaceOrgs();
                          navigate(
                            `${Routes.JOBS}?${URLParams.org}=${org.name}`
                          );
                        } catch (error) {
                          setErrorMessage(restClient.APIErrorMessage(error));
                        }
                      }
                    }}
                  />
                }
              />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

PaymentInfo.defaultProps = {
  showTitle: true,
  edit: false,
};

export { PaymentInfo };
