import { useEffect, useState } from "react";
import { ImageGridViewMode } from "components/ImageGrid";

export const useImageGridViewState = (imagesCount: number) => {
  const [imageGridViewMode, setImageGridViewMode] =
    useState<ImageGridViewMode>("grid");

  const [isInitialState, setIsInitialState] = useState(false);

  useEffect(() => {
    if (isInitialState || imagesCount === 0) return;
    if (imagesCount <= 7) {
      setImageGridViewMode("horizontalList");
    }

    setIsInitialState(true);
  }, [imagesCount, setImageGridViewMode, isInitialState, setIsInitialState]);

  return {
    imageGridViewMode,
    setImageGridViewMode,
  };
};
