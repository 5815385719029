export function HDRIIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_2)">
        <path
          d="M4.23789 12.823C4.08786 12.823 3.9472 12.7308 3.89218 12.5819C3.82208 12.3912 3.91972 12.1793 4.11074 12.1088C6.41891 11.2588 9.27904 10.7916 12.1639 10.7928C15.0514 10.794 17.7749 11.2294 20.0408 12.0521C20.2322 12.1216 20.3307 12.3329 20.2615 12.5242C20.192 12.7153 19.9804 12.813 19.7893 12.7449C17.6033 11.951 14.966 11.5307 12.1636 11.5295C9.36324 11.5284 6.59381 11.9795 4.36517 12.8003C4.32311 12.8159 4.28029 12.823 4.23789 12.823L4.23789 12.823Z"
          fill={fill}
        />
        <path
          d="M17.8461 22.4239C17.7637 22.4239 17.6832 22.3961 17.6173 22.344C17.5292 22.2742 17.4777 22.168 17.4778 22.0554L17.4816 13.095C17.4816 12.9284 17.5936 12.7827 17.7541 12.7392C21.4051 11.7567 23.5851 10.0367 23.5859 8.13785C23.5871 5.21559 18.3579 2.74461 12.1667 2.742C5.97572 2.7394 0.744186 5.20598 0.742959 8.12824C0.742156 10.0395 2.7505 11.7621 6.11487 12.7362C6.27243 12.782 6.38101 12.9263 6.38094 13.0904L6.37723 21.9377C6.37718 22.0518 6.32424 22.1593 6.2343 22.2291C6.1436 22.2988 6.02631 22.3222 5.91617 22.2941C2.10177 21.3041 0.0013002 19.3939 0.00234129 16.9152L0.00603217 8.12778C0.00747541 4.69165 5.34914 2.00225 12.1668 2.00511C18.9845 2.00798 24.3239 4.70209 24.3225 8.13801L24.3186 17.3157C24.3176 19.7404 22.2279 21.4082 17.9301 22.4144C17.902 22.4208 17.874 22.424 17.8459 22.424L17.8461 22.4239ZM18.2183 13.3755L18.2148 21.5861C21.8244 20.6665 23.5811 19.2672 23.5819 17.3153L23.5849 10.2387C22.6251 11.5451 20.7656 12.6495 18.2183 13.3753L18.2183 13.3755ZM0.742109 10.3105L0.739335 16.9154C0.738488 18.9306 2.47422 20.5322 5.64082 21.4542L5.64422 13.3644C3.33886 12.6554 1.64316 11.5821 0.742109 10.3105V10.3105Z"
          fill={fill}
        />
        <path
          d="M20.0574 19.3784C19.9185 19.3783 19.7857 19.2998 19.7232 19.1656C19.6373 18.9813 19.7169 18.7622 19.9015 18.6759C20.8487 18.2342 21.3706 17.7504 21.3707 17.314C21.3708 17.1103 21.5357 16.9456 21.7394 16.9457C21.943 16.9458 22.1078 17.1106 22.1077 17.3143C22.1074 18.0746 21.4699 18.7572 20.2129 19.3438C20.1624 19.3672 20.1095 19.3784 20.0574 19.3784L20.0574 19.3784Z"
          fill={fill}
        />
        <path
          d="M3.79864 19.0284C3.72888 19.0284 3.65837 19.0085 3.59575 18.9671C2.45188 18.2089 2.21227 17.4331 2.21249 16.9157C2.21258 16.7121 2.37745 16.5473 2.58113 16.5474C2.7848 16.5475 2.94954 16.7124 2.94945 16.916C2.94919 17.5263 3.52213 18.0342 4.0027 18.3528C4.17249 18.4655 4.21881 18.694 4.10651 18.8634C4.03544 18.9706 3.91813 19.0284 3.79867 19.0284L3.79864 19.0284Z"
          fill={fill}
        />
        <path
          d="M16.8885 11.9569C16.6848 11.9568 16.5201 11.7919 16.5202 11.5882L16.5239 2.85646C16.524 2.65295 16.6889 2.48805 16.8925 2.48813C17.0962 2.48822 17.2609 2.65325 17.2609 2.85677L17.2572 11.5885C17.257 11.7921 17.0922 11.9569 16.8885 11.9568L16.8885 11.9569Z"
          fill={fill}
        />
        <path
          d="M7.43444 11.9604C7.23077 11.9603 7.06604 11.7955 7.06612 11.5918L7.06979 2.85249C7.06988 2.64899 7.23474 2.48408 7.43842 2.48416C7.64208 2.48425 7.80682 2.64929 7.80673 2.8528L7.80306 11.5921C7.8029 11.7957 7.63803 11.9604 7.43444 11.9603L7.43444 11.9604Z"
          fill={fill}
        />
        <path
          d="M7.43405 11.9604C7.38443 11.9604 7.33407 11.9503 7.2858 11.929C7.09946 11.8469 7.01535 11.6292 7.09745 11.4432L9.55223 5.88315C9.60735 5.75833 9.72644 5.67418 9.86245 5.66449C9.99921 5.65338 10.1286 5.72035 10.201 5.83556L13.4391 10.9821C13.5477 11.1545 13.4958 11.3818 13.3238 11.49C13.1511 11.599 12.9241 11.5467 12.8159 11.3747L9.94668 6.81466L7.77177 11.7408C7.71093 11.8785 7.57599 11.9605 7.43419 11.9604L7.43405 11.9604Z"
          fill={fill}
        />
        <path
          d="M16.8891 11.5482C16.7639 11.5481 16.6423 11.4843 16.5732 11.3695L14.4566 7.84741L13.1269 10.7953C13.0434 10.9806 12.8264 11.0633 12.6393 10.9797C12.4541 10.8958 12.3714 10.6777 12.4549 10.4921L14.0741 6.90302C14.1307 6.77821 14.2516 6.69481 14.3887 6.6869C14.5251 6.67722 14.6553 6.74746 14.7261 6.86477L17.2048 10.9899C17.3094 11.1645 17.2528 11.3907 17.0786 11.4957C17.0193 11.5313 16.9538 11.5482 16.889 11.5482L16.8891 11.5482Z"
          fill={fill}
        />
        <path
          d="M12.7928 6.31725C12.0818 6.31695 11.5035 5.73818 11.5038 5.02719C11.5041 4.31619 12.0829 3.73791 12.7938 3.73821C13.5048 3.73851 14.0831 4.31727 14.0828 5.02827C14.0825 5.73927 13.5037 6.31754 12.7928 6.31725ZM12.7935 4.47506C12.4888 4.47494 12.2408 4.72274 12.2406 5.0275C12.2405 5.33225 12.4883 5.58027 12.7931 5.58039C13.0978 5.58052 13.3458 5.33272 13.346 5.02796C13.3461 4.72321 13.0983 4.47519 12.7935 4.47506Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2_2">
          <rect fill={fill} height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
}
