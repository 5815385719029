import { useEffect } from "react";
import { useBoundStore } from "store/_boundStore";

export const useOrgs = () => {
  const [orgs, orgsLoading, error, fetchOrgs] = useBoundStore((state) => [
    state.org.list || undefined,
    state.org.loading,
    state.org.error,
    state.org.fetchOrgs,
  ]);
  useEffect(() => {
    if (!orgs) {
      fetchOrgs();
    }
  }, [orgs, fetchOrgs]);

  return [orgs, orgsLoading, error] as const;
};
