import { Box, Tab, Tabs, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const WizardTabHeader = styled(Tab)`
  flex-direction: row;
  border-radius: 5px;
  margin: 8px 24px;
  padding: 8px;
  font-size: 14px;
  line-height: 21px;
  color: ${colors.grey70};
  min-height: 24px;

  svg {
    margin-right: 6px;
  }

  span {
    max-height: 32px;
  }

  &.MuiTab-root.Mui-selected {
    color: ${colors.black};
    background-color: rgba(238, 116, 31, 0.1);
    svg {
      color: ${colors.orange};
    }
  }
`;

export const WizardTabs = styled(Tabs)`
  button:first-child {
    margin-left: 10px;
  }
`;

export const WizardTabsContainer = styled(Box)`
  border-bottom: 1px solid ${colors.grey20};
  border-top: 1px solid ${colors.grey20};
`;

export const JobWizardContainer = styled(Box)`
  width: 100%;
  padding-top: 30px;
  border: 1px solid ${colors.grey20};
  border-radius: 10px;
  min-height: 50vh;
  max-height: calc(100vh - 172px);
  box-shadow: 0px 1px 44px rgba(0, 0, 0, 0.07);
  overflow-y: auto;
`;

export const WizardHeaderMessage = styled(Typography)`
  padding: 0 0 20px 20px;
  font-size: 14px;
  line-height: 21px;
`;

export const TabContentContainer = styled(Box)`
  padding: 24px;
`;

export const TemplateTabs = styled(WizardTabs)`
  margin-bottom: 24px;

  .MuiTabs-indicator {
    display: none;
  }

  button:first-child,
  button {
    margin-left: 0px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const TemplateTabHeader = styled(WizardTabHeader)`
  text-transform: none;
  font-weight: 600;

  &.MuiTab-root.Mui-selected {
    color: ${colors.orange};
  }
`;
