import { Typography } from "@mui/material";
import lightImage from "components/dialogs/StageElementDialog/assets/light.svg";
import {
  AnimatedImage,
  CameraTipWrapper,
  ContentWrapper,
  LightTipWrapper,
  RigTipWrapper,
  DialogParagraph,
} from "components/dialogs/StageElementDialog/StageElementDialog.styled";
import { Messages } from "constants/messages";

export function Light() {
  return (
    <ContentWrapper>
      <LightTipWrapper current>
        <Typography color="white">Light</Typography>
        <DialogParagraph>{Messages.stageElementStep3Light}</DialogParagraph>
      </LightTipWrapper>

      <RigTipWrapper>
        <Typography color="white">Rig</Typography>
        <DialogParagraph>{Messages.stageElementStep3Rig}</DialogParagraph>
      </RigTipWrapper>

      <CameraTipWrapper>
        <Typography color="white">Camera</Typography>
        <DialogParagraph>{Messages.stageElementStep3Camera}</DialogParagraph>
      </CameraTipWrapper>
      <AnimatedImage src={lightImage} />
    </ContentWrapper>
  );
}
