import { useMemo } from "react";
import { useGLTF } from "@react-three/drei";
import { MeshStandardMaterial } from "three";
import { selectIsVehicleEnviroment } from "features/JobBuilder/store";
import { useBoundStore } from "store/_boundStore";
import characterModel from "./characterModel.glb";

const materialColors = {
  "Material.001": "#707070",
  hoodieA: "#9E9E9E",
  teeA: "#D3D3D3",
  jeansA: "#535353",
  Pupil: "#111111",
  Head_Body: "#DBB4A0",
  Eyeball: "#FFFFFF",
  Eyebrows_1: "#292929",
};

export function CharacterModel(props) {
  const { nodes } = useGLTF(characterModel);
  const isVehicleEnviroment = useBoundStore(selectIsVehicleEnviroment);

  const newMaterials = useMemo(() => {
    return Object.fromEntries(
      Object.entries(materialColors).map(([k, color]) => {
        const characterMaterial = new MeshStandardMaterial();
        characterMaterial.roughness = 1;
        characterMaterial.metalness = 0.5;
        characterMaterial.color.set(color);

        return [k, characterMaterial];
      })
    );
  }, []);

  return (
    <group
      {...props}
      dispose={null}
      position={[
        isVehicleEnviroment ? 0.37 : 0,
        isVehicleEnviroment ? -0.42 : 0,
        isVehicleEnviroment ? -0.1 : 0,
      ]}
    >
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <mesh
          geometry={nodes.Clothing001_1.geometry}
          material={newMaterials["Material.001"]}
        />
        <mesh
          geometry={nodes.Clothing001_2.geometry}
          material={newMaterials.hoodieA}
        />
        <mesh
          geometry={nodes.Clothing001_3.geometry}
          material={newMaterials.teeA}
        />
        <mesh
          geometry={nodes.Clothing001_4.geometry}
          material={newMaterials.jeansA}
        />
      </group>
      <mesh
        geometry={nodes.Pupil001.geometry}
        material={newMaterials.Pupil}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
      <mesh
        geometry={nodes.Body001.geometry}
        material={newMaterials.Head_Body}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
      <mesh
        geometry={nodes.Eyeball001.geometry}
        material={newMaterials.Eyeball}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
      <mesh
        geometry={nodes.Plane002.geometry}
        material={newMaterials.Eyebrows_1}
        position={[0.03, 1.78, 0.1]}
      />
      <mesh
        geometry={nodes.Head001.geometry}
        material={newMaterials.Head_Body}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.01}
      />
    </group>
  );
}

useGLTF.preload(characterModel);
