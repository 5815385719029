export function ExpressionIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 9.5C16.5 10.0523 16.0523 10.5 15.5 10.5C14.9477 10.5 14.5 10.0523 14.5 9.5C14.5 8.94772 14.9477 8.5 15.5 8.5C16.0523 8.5 16.5 8.94772 16.5 9.5ZM9.5 9.5C9.5 10.0523 9.05228 10.5 8.5 10.5C7.94772 10.5 7.5 10.0523 7.5 9.5C7.5 8.94772 7.94772 8.5 8.5 8.5C9.05228 8.5 9.5 8.94772 9.5 9.5ZM7.67345 14.5H8.27459C9.08282 15.6962 10.4349 16.5 12 16.5C13.5651 16.5 14.9172 15.6962 15.7254 14.5H16.3266C15.4624 15.996 13.8483 17 12 17C10.1517 17 8.53764 15.996 7.67345 14.5ZM2.5 12C2.5 6.75549 6.74679 2.5 11.99 2.5C17.2442 2.5 21.5 6.75647 21.5 12C21.5 17.2435 17.2442 21.5 11.99 21.5C6.74679 21.5 2.5 17.2445 2.5 12ZM3.5 12C3.5 16.6961 7.30386 20.5 12 20.5C16.6961 20.5 20.5 16.6961 20.5 12C20.5 7.30386 16.6961 3.5 12 3.5C7.30386 3.5 3.5 7.30386 3.5 12Z"
        fill={fill}
        stroke={fill}
      />
    </svg>
  );
}
