export enum RenderQuality {
  Debug = "Debug",
  Low = "Low",
  Default = "Default",
  High = "High",
}

export type JsonRender = {
  quality: RenderQuality;
  start_frame: number;
  end_frame: number;
  frame_increment: number;
};

export type JsonEnvironment = {
  location_preset: string;
  time_of_day: string;
  environment_light_rotation: number;
  artificial_light_intensity: number;
};

export type JsonAction = {
  name: string;
  transform: {
    position: [number, number, number];
    rotation: number;
  };
  human_preset: string;
  action_region: string;
};

export type JsonPlacement = {
  position: [number, number, number];
  yaw: number;
  roll: number;
  pitch: number;
};

export type JsonCamera = {
  name: string;
  specifications: {
    wavelength: string;
    focal_length: number;
    resolution_h: number;
    resolution_w: number;
    sensor_width: number;
  };
  placement_offset: JsonPlacement;
};

export type JsonCameraRig = {
  initial_position: {
    placement_type: "location_uv";
    properties: {
      name: string;
      uv_coords_u: number;
      uv_coords_v: number;
    };
  };
  rig: {
    placement_offset: JsonPlacement;
    cameras: JsonCamera[];
  };
};
export type JsonScene = {
  environment: JsonEnvironment;
  actions: JsonAction[];
  camera_rigs: JsonCameraRig[];
  render: JsonRender;
};
export type JsonInput = {
  scenes: JsonScene[];
};
