import { Geom2 } from "@flasd/modeling/src/geometries/types";
import { Vec2 } from "@flasd/modeling/src/maths/vec2";
import { Vec3 } from "@flasd/modeling/src/maths/vec3";
import { polygon } from "@flasd/modeling/src/primitives";
import { Vector2, Vector3 } from "three";

export const fromVec2sToGeom2 = (arr: Vec2[]) =>
  polygon({
    points: arr,
  });
export const fromVec2ToVector2 = (v: Vec2) => new Vector2(v[0], v[1]);
export const fromVec3ToVec2 = (v: Vec3) => [v[0], v[1]] as Vec2;
export const fromVec2ToVec3 = (v: Vec2) => [v[0], v[1], 0] as Vec3;
export const fromVec2toVector3 = (v: Vec2) => new Vector3(v[0], v[1], 0);
export const fromVec2toVector2 = (v: Vec2) => new Vector2(v[0], v[1]);
export const fromVec3toVector3 = (v: Vec3) => new Vector3(v[0], v[1], v[2]);
export const fromGeom2ToVec2sNested = (geom2: Geom2) => geom2.outlines;
export const fromGeom2ToVec2s = (geom2: Geom2) => geom2.outlines[0];
export const fromGeom2ToVector2s = (geom2: Geom2) =>
  fromGeom2ToVec2s(geom2).map(fromVec2ToVector2);

export const fromGeom2ToVector2sNested = (newGeom: Geom2) =>
  fromGeom2ToVec2sNested(newGeom).map((list) => {
    return list.map(fromVec2ToVector2);
  });

export const connectVec2s = (arr: Vec2[]) => arr.concat([arr[0]]);
