import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { v4 } from "uuid";
import { CarModel } from "features/JobBuilder/CamerasAndLightsPreview/assets/CarModel";
import { CharacterModel } from "features/JobBuilder/CamerasAndLightsPreview/assets/CharacterModel";
import { CameraPreviewContent } from "features/JobBuilder/CamerasAndLightsPreview/CamerasAndLightsPreview.styled";
import { JsonCamera, JsonRig } from "features/JobBuilder/json-types";
import { selectIsVehicleEnviroment } from "features/JobBuilder/store";
import { useBoundStore } from "store/_boundStore";
import { Environment } from "./Environment";
import { Rig } from "./Rig";

interface ThreeDimensionalComponentProps {
  rigsData?: JsonRig[];
  capturingCameraData?: JsonCamera;
  capturingRigData?: JsonRig;
  isStagePreview?: boolean;
}

export function ThreeDimensionalComponent({
  rigsData,
  capturingCameraData,
  capturingRigData,
  isStagePreview,
}: ThreeDimensionalComponentProps) {
  const isVehicleEnviroment = useBoundStore(selectIsVehicleEnviroment);

  return (
    <CameraPreviewContent>
      <Canvas
        gl={{
          antialias: true,
        }}
      >
        <ambientLight intensity={1} />
        {Array(20)
          .fill(0)
          .map((_, i) => (
            <spotLight
              key={v4()}
              intensity={0.45}
              position={[0.5 - Math.random(), 2, 10 - i]}
            />
          ))}

        <Suspense fallback={null}>
          <CharacterModel />
          {isVehicleEnviroment && <CarModel />}

          <Environment
            capturingCamera={capturingCameraData}
            capturingRig={capturingRigData}
            isStagePreview={isStagePreview}
          />

          {rigsData && rigsData.map((d) => <Rig key={v4()} data={d} />)}
        </Suspense>
      </Canvas>
    </CameraPreviewContent>
  );
}
