import { useCallback } from "react";
import { ReadOnlyPickedCameraPresetLocation } from "features/JobBuilder2/steps/threeDimensional/ReadOnlyPickedCameraPresetLocation";
import { selectPickedSubjob } from "features/JobBuilder2/store";
import { ActivityZone } from "features/JobBuilder2/types";
import { useBoundStore } from "store/_boundStore";
import { ActivityRegion } from "./activity-regions/ActivityRegion";
import { getFittingActivitiesForZone } from "./utils/getFittingActivitiesForZone";

export function Activities3dStep() {
  const { setActivitiesPickerDialogData } =
    useBoundStore.getState().jobBuilder2;
  const allActivityZones = useBoundStore(selectPickedSubjob).activityZones;
  const activities = useBoundStore((state) => state.catalogData.activities);
  const isDragging = useBoundStore((state) => state.userControls.dragging);

  const onRegionClick = useCallback(
    (activityZone: ActivityZone, shapeIndex: number) => {
      if (isDragging) {
        return;
      }

      const { fittingActivities, disallowedActivityReasons } =
        getFittingActivitiesForZone(activityZone.geomRegion, activities);

      setActivitiesPickerDialogData({
        zoneIndex: shapeIndex,
        allowedActivityNames: fittingActivities.map((item) => item.name),
        disallowedActivityReasons,
      });
    },
    [activities, isDragging, setActivitiesPickerDialogData]
  );

  return (
    <>
      <ReadOnlyPickedCameraPresetLocation />
      {allActivityZones.map((activityZone, shapeIndex) => {
        const { id } = activityZone.geomRegion;
        return (
          <ActivityRegion
            key={id}
            activityZone={activityZone}
            shapeIndex={shapeIndex}
            onClick={() => onRegionClick(activityZone, shapeIndex)}
          />
        );
      })}
    </>
  );
}
