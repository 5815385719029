import { Messages } from "constants/messages";
import { Container, Description, Title } from "./NoSearchResultFound.styled";
import { NoSearchResultFoundImage } from "./NoSearchResultFoundImage";

export function NoSearchResultFound() {
  return (
    <Container>
      <NoSearchResultFoundImage />
      <Title>{Messages.noResultFoundTitle}</Title>
      <Description>{Messages.noResultFoundDescription}</Description>
    </Container>
  );
}
