import React, { cloneElement } from "react";

export function ShowOnHover({
  toShow,
  children,
}: {
  toShow: JSX.Element;
  children: JSX.Element;
}) {
  return (
    <div className="show-on-hover-parent">
      {cloneElement(toShow, {
        className: `${toShow.props.className} show-on-hover`,
      })}
      {children}
    </div>
  );
}
