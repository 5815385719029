import { useEffect } from "react";
import { useBoundStore } from "store/_boundStore";
import { useId } from "./useIdShim";

export const useCursor2 = (toggled: boolean, cursor: string) => {
  const id = useId();
  const [appendToCursorStack, removeFromCursorStack] = useBoundStore((s) => [
    s.userControls.appendToCursorStack,
    s.userControls.removeFromCursorStack,
  ]);

  useEffect(() => {
    if (toggled) {
      appendToCursorStack({
        id,
        cursor,
      });
      return () => removeFromCursorStack(id);
    }
  }, [cursor, id, toggled, appendToCursorStack, removeFromCursorStack]);
};
