import React from "react";
import { Box, Typography } from "@mui/material";

function SimpleErrorMessage({
  title,
  children,
}: {
  title: string;
  children?: any;
}) {
  return (
    <Box
      alignItems="center"
      component="div"
      display="flex"
      height="100%"
      justifyContent="center"
    >
      <Box component="div">
        <Box component="div" mb={2}>
          <Typography color="error" variant="h1">
            {title}
          </Typography>
        </Box>
        {children}
      </Box>
    </Box>
  );
}

export { SimpleErrorMessage };
