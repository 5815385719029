import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const Container = styled(Box)`
  width: 100%;
  min-height: 75px;
  margin-bottom: 0px;
  padding-bottom: 16px;
`;

export const RowContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MainInfoContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 8px;
  margin-bottom: 8px;
`;

export const Title = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  padding-right: 16px;
  white-space: nowrap;
  line-clamp: 1;
  color: ${colors.grey70};
`;

export const DropdownContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 220px;
  min-width: 200px;
  margin-right: 16px;
`;

export const OptionLabel = styled(Typography)`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey70};
  padding-bottom: 24px;
  margin-left: 82.25px;
`;

export const ButtonWrapper = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
`;

export const Image = styled.img`
  border-radius: 4px;
  width: 159px;
  height: 149px;
  margin-left: 82.25px;
`;
