import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const HeaderContainer = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderActionsContainer = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const Title = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${colors.grey70};
`;

export const TitleCount = styled.small`
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey70};
  margin-left: 4px;
  position: relative;
  top: 1px;
`;
