import {
  WarningDialog,
  WarningDialogBaseProps,
} from "components/dialogs/WarningDialog";

export function DeleteConfirmationDialog(props: WarningDialogBaseProps) {
  return (
    <WarningDialog
      {...props}
      icon="DeleteIcon"
      onCancelButtonText="Cancel"
      onConfirmButtonText="Delete"
    />
  );
}
