import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Slider } from "@mui/material";
import produce from "immer";
import { useDebouncedCallback } from "use-debounce";
import {
  RangeSelectorContainer,
  Unit,
} from "components/SelectorsPicker/RangeSelector/RangeSelector.styled";
import { SelectorConfigInput } from "components/SelectorsPicker/SelectorConfigInput";
import { exceedLimit } from "components/SelectorsPicker/utils";
import { LoRType } from "domain/Human";
import { NormalDistChart } from "./NormalDistChart";
import { Container, ChartContainer } from "./NormalDistributionSelector.styled";

const valueToText = (value: number) => `${value}`;
export function NormalDistributionSelector({
  lorValue,
  minValue,
  maxValue,
  step = 0.01,
  onUpdate,
}: {
  lorValue: LoRType;
  minValue: number;
  maxValue: number;
  step?: number;
  onUpdate: (lor: LoRType) => void;
}) {
  const [mean, setMean] = useState(
    () => lorValue.mean ?? ((minValue + maxValue) / 2).toString()
  );

  const decimalDefaultStandardDeviation = (maxValue - Number(mean)) / 3;
  const defaultStandardDeviation = Number(
    decimalDefaultStandardDeviation.toFixed(3)
  );
  const [standardDeviation, setStandardDeviation] = useState(
    defaultStandardDeviation.toString()
  );

  const meanRef = useRef(mean);
  meanRef.current = mean;

  const standardDeviationRef = useRef(standardDeviation);
  standardDeviationRef.current = standardDeviation;

  useEffect(() => {
    if (lorValue.mean && lorValue.mean !== meanRef.current) {
      setMean(lorValue.mean);
    }
  }, [lorValue.mean, meanRef, minValue, maxValue]);

  useEffect(() => {
    if (lorValue.std_dev && lorValue.std_dev !== standardDeviationRef.current) {
      setStandardDeviation(lorValue.std_dev);
    }
  }, [lorValue.std_dev, standardDeviationRef, minValue, maxValue]);

  const numericMean = Number(mean);
  const numericStandardDeviation = Number(standardDeviation);

  const meanError =
    Number.isNaN(numericMean) || exceedLimit(mean, minValue, maxValue);
  const stdDevError =
    Number.isNaN(numericStandardDeviation) || numericStandardDeviation < 0;

  const meanErrorMessage = `Input needs to be a number between ${minValue} and ${maxValue}`;
  const stdDevErrorMessage = `Std Dev Input needs to be a positve number`;

  const onUpdateLocal = useCallback(
    (fn: (draft: LoRType) => void) => {
      onUpdate(produce(lorValue, fn));
    },
    [lorValue, onUpdate]
  );

  const onUpdateMean = useDebouncedCallback(
    (v: string) =>
      onUpdateLocal((d) => {
        d.mean = v;
      }),
    500,
    { leading: true, trailing: true }
  );

  const onUpdateStdDev = useDebouncedCallback(
    (v: string) =>
      onUpdateLocal((d) => {
        d.std_dev = v;
      }),
    500,
    { leading: true, trailing: true }
  );

  useEffect(() => {
    onUpdateMean(mean);
  }, [mean, onUpdateMean]);

  useEffect(() => {
    onUpdateStdDev(standardDeviation);
  }, [standardDeviation, onUpdateStdDev]);

  const handleChange = (_event: Event, newValue: number | number[]) => {
    setMean(newValue.toString());
  };

  const handleMeanValueChange = (newValue: string) => {
    setMean(newValue);
  };

  const handleStdDeviationValueChange = (newValue: string) => {
    setStandardDeviation(newValue);
  };

  return (
    <Container>
      <Box component="div">
        <RangeSelectorContainer>
          <Slider
            getAriaLabel={() => "Normal Distribution Selector"}
            getAriaValueText={valueToText}
            max={maxValue}
            min={minValue}
            step={step}
            value={numericMean}
            valueLabelDisplay="off"
            onChange={handleChange}
          />
        </RangeSelectorContainer>
        <Unit>Mean</Unit>
      </Box>
      <ChartContainer>
        <NormalDistChart
          maxValue={maxValue}
          mean={numericMean}
          minValue={minValue}
          standardDeviation={numericStandardDeviation}
        />
      </ChartContainer>
      <SelectorConfigInput
        InputProps={{ inputProps: { maxLength: 3 } }}
        labelDirection="column"
        leftError={meanError}
        leftErrorMessage={meanErrorMessage}
        leftLabel="Mean"
        leftValue={mean}
        rightError={stdDevError}
        rightErrorMessage={stdDevErrorMessage}
        rightLabel="Standard Deviation"
        rightValue={standardDeviation}
        onChangeLeftValue={handleMeanValueChange}
        onChangeRightValue={handleStdDeviationValueChange}
      />
    </Container>
  );
}
