import React, { useMemo } from "react";
import sexMatchedOnlyDiagram from "assets/diagrams/hair-relative-density-sex-matched-only.svg";
import { ExpressionIcon } from "assets/icons";
import { BooleanSelector } from "components/BooleanSelector";
import { RightAsidePreviewFull } from "components/RightAsidePreviewFull";
import { PreviewContent } from "components/RightAsidePreviewFull/PreviewContent";
import { SelectorsPicker } from "components/SelectorsPicker";
import { PUBLIC_S3_BUCKET } from "constants/constants";
import { defaultEyebrow } from "domain/Human";
import { EmptyState } from "features/JobBuilder/empty-state";
import { convertEyebrowStateToJson } from "features/JobBuilder/form-to-input-json";
import {
  getEyeBrows,
  getHumanObject,
} from "features/JobBuilder/json-extracter";
import { JsonManagerNewUI } from "features/JobBuilder/json-manager-new-ui";
import { convertEyebrows } from "features/JobBuilder/json-to-form";
import {
  DeepPartial,
  JsonEyebrow,
  JsonHuman,
  Uncertain,
} from "features/JobBuilder/json-types";
import { ContentCard, Page } from "features/JobBuilder/layout";
import { MultiOptionImageGrid } from "features/JobBuilder/MultiOptionImageGrid";
import {
  eyebrowColorSelector,
  eyebrowStyleSelector,
} from "features/JobBuilder/store";
import { useDistPage } from "features/JobBuilder/useDistPage";
import { useImageSelection } from "features/JobBuilder/useImageSelection";
import { changeCheckEventValue } from "features/JobBuilder/utils";
import { useBoundStore } from "store/_boundStore";

export function JobBuilderEyebrows() {
  const {
    value,
    selectedGroupIndex,
    onUpdate,
    onAdd,
    numberOfImages,
    toggleDistListItem,
    isSummingTo100,
    onUpdatePart,
    onUpdatePercent,
  } = useDistPage("eyebrow");

  const { setErrorMessage, setSuccessMessage } =
    useBoundStore.getState().message;

  const selectedEyebrowGroup = value[selectedGroupIndex];
  const isEmptyState = !selectedEyebrowGroup;

  const eyebrowStyleOptions = useBoundStore((state) =>
    eyebrowStyleSelector(state)
  );

  const eyebrowColorOptions = useBoundStore((state) =>
    eyebrowColorSelector(state)
  );

  const onUpdateRelativeLength = onUpdatePart("relative_length");
  const onUpdateRelativeDensity = onUpdatePart("relative_density");
  const onUpdateColorSeed = onUpdatePart("color_seed");
  const onUpdateSexMatchedOnly = onUpdatePart("sex_matched_only");

  const jsonValue = useMemo(() => convertEyebrowStateToJson(value), [value]);
  const onDoneJson = (json: Uncertain<JsonEyebrow[]>) => {
    const updated = convertEyebrows(
      json,
      value,
      eyebrowStyleOptions,
      eyebrowColorOptions
    );
    onUpdate(updated);
  };

  const styleUIState = useImageSelection(
    selectedEyebrowGroup ? selectedEyebrowGroup.style : [],
    eyebrowStyleOptions,
    (hs) => `${PUBLIC_S3_BUCKET}/pl1/eyebrows/${hs}.png`,
    onUpdatePart("style"),
    "Style",
    "style",
    "StyleIcon",
    toggleDistListItem("style", eyebrowStyleOptions)
  );

  const colorUIState = useImageSelection(
    selectedEyebrowGroup ? selectedEyebrowGroup.color : [],
    eyebrowColorOptions,
    (hc) => `${PUBLIC_S3_BUCKET}/pl1/eyebrows.colors/${hc}.png`,
    onUpdatePart("style"),
    "Color",
    "color",
    "ColorIcon",
    toggleDistListItem("color", eyebrowColorOptions)
  );

  const onIndecesReceived = (
    indices: number[],
    humansArr: DeepPartial<JsonHuman>[]
  ) => {
    try {
      onUpdate(
        convertEyebrows(
          getEyeBrows(getHumanObject(humansArr, indices[0])),
          value,
          eyebrowStyleOptions,
          eyebrowColorOptions
        )
      );
      setSuccessMessage("updated eyebrows");
    } catch (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
      }
    }
  };

  const center = (
    <JsonManagerNewUI<Uncertain<JsonEyebrow[]>>
      emptyState={
        isEmptyState ? (
          <EmptyState
            description="Eyebrows controls the eyebrows variations of the character models. Default eyebrows variations are applied, some sample results are shown below:"
            folder="eyebrow"
            icon={<ExpressionIcon style={{ width: 48, height: 48 }} />}
            title="Default eyebrows settings applied"
          />
        ) : null
      }
      groupIndex={selectedGroupIndex + 1}
      groupPercentage={selectedEyebrowGroup?.percent}
      id={`eyebrows-${selectedGroupIndex + 1}`}
      isSummingTo100={isSummingTo100}
      jsonValue={jsonValue}
      numberOfImages={numberOfImages}
      parentTitle="Facial Attributes"
      parentTitleIcon="FacialAttributesIcon"
      title="Eyebrows"
      titleCount={value[selectedGroupIndex]?.style?.length}
      titleIcon="EyebrowsIcon"
      unknownIndicesKeys={["Human"]}
      onClickAddButton={onAdd}
      onDoneEditJson={onDoneJson}
      onIndecesReceived={onIndecesReceived}
      onUpdatePercent={onUpdatePercent}
    >
      {/* Eyebrow Style */}
      <ContentCard>
        <MultiOptionImageGrid {...styleUIState} />
      </ContentCard>

      {/* Eyebrow Color */}
      <ContentCard>
        <MultiOptionImageGrid {...colorUIState} />
      </ContentCard>

      {/* color_seed */}
      <ContentCard style={{ padding: "8px" }}>
        <SelectorsPicker
          icon={"VerticalAngleIcon"}
          imgSrc={""}
          limits={defaultEyebrow.color_seed}
          lorValue={selectedEyebrowGroup?.color_seed}
          noSpacing={true}
          title="Vertical Angle"
          unit={"degree(s)"}
          onUpdate={onUpdateColorSeed}
        />
      </ContentCard>

      {/* relative_length */}
      <ContentCard style={{ padding: "8px" }}>
        <SelectorsPicker
          icon={"VerticalAngleIcon"}
          imgSrc={""}
          limits={defaultEyebrow.relative_length}
          lorValue={selectedEyebrowGroup?.relative_length}
          noSpacing={true}
          title="Vertical Angle"
          unit={"degree(s)"}
          onUpdate={onUpdateRelativeLength}
        />
      </ContentCard>

      {/* relative_density */}
      <ContentCard style={{ padding: "8px" }}>
        <SelectorsPicker
          icon={"VerticalAngleIcon"}
          imgSrc={""}
          limits={defaultEyebrow.relative_density}
          lorValue={selectedEyebrowGroup?.relative_density}
          noSpacing={true}
          title="Vertical Angle"
          unit={"degree(s)"}
          onUpdate={onUpdateRelativeDensity}
        />
      </ContentCard>

      <ContentCard>
        <BooleanSelector
          description={"Messages.sexMatchedDescriptionForClothing"}
          icon={"SexMatchedOnlyIcon"}
          imgSrc={sexMatchedOnlyDiagram}
          label={"Enabled"}
          title={"Sex Matched Only"}
          value={selectedEyebrowGroup?.sex_matched_only}
          onUpdate={changeCheckEventValue(onUpdateSexMatchedOnly)}
        />
      </ContentCard>
    </JsonManagerNewUI>
  );

  const rightAside = (
    <RightAsidePreviewFull previewTitle="Preview">
      <PreviewContent data={styleUIState.selectedImages} title="STYLE" />
    </RightAsidePreviewFull>
  );
  return (
    <Page
      fixedRightSide
      center={center}
      hideRightAside={isEmptyState}
      rightAside={rightAside}
    />
  );
}
