import React from "react";

type EyebrowsIconProps = {
  style?: React.CSSProperties;
  fill?: string;
};

export function EyebrowsIcon(props: EyebrowsIconProps) {
  const { fill = "currentColor", style = { height: "24px" } } = props;

  return (
    <svg
      fill="none"
      height="16"
      style={style}
      viewBox="0 0 22 16"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9998 8.33809C9.05731 8.33809 7.4665 9.87111 7.4665 11.7689C7.4665 13.6666 9.05731 15.1996 10.9998 15.1996C12.9424 15.1996 14.5332 13.6666 14.5332 11.7689C14.5332 9.87111 12.9424 8.33809 10.9998 8.33809ZM10.9998 13.3637C10.0832 13.3637 9.34798 12.6422 9.34798 11.7689C9.34798 10.8955 10.0832 10.174 10.9998 10.174C11.9165 10.174 12.6517 10.8955 12.6517 11.7689C12.6517 12.6422 11.9165 13.3637 10.9998 13.3637Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.4"
      />
      <path
        d="M19.2194 3.44355L19.2194 3.44352C18.8369 3.07698 18.2203 3.07699 17.8378 3.44351L17.8378 3.44353L15.449 5.73306C14.3512 5.31128 13.1818 5.04878 11.9812 4.95613V1.74879C11.9812 1.21686 11.5338 0.8 11 0.8C10.4661 0.8 10.0187 1.21686 10.0187 1.74879V4.95609C8.81817 5.04874 7.64878 5.31124 6.55092 5.73303L4.1622 3.44349L4.1622 3.44349C3.77972 3.07691 3.16297 3.0769 2.78053 3.4435C2.39335 3.81464 2.39336 4.42003 2.78057 4.7912L2.78057 4.7912L4.70467 6.63538C3.0595 7.63023 1.74892 8.9914 0.907055 10.5895L0.90705 10.5895C0.657563 11.0632 0.85974 11.6352 1.34217 11.8687C1.82015 12.1001 2.409 11.9195 2.65576 11.451C4.06707 8.77183 7.22444 6.8159 11 6.8159C14.7784 6.8159 17.9342 8.77417 19.3442 11.451C19.5915 11.9203 20.1805 12.0997 20.6578 11.8687C21.1403 11.6352 21.3425 11.0632 21.0929 10.5895C20.251 8.99142 18.9404 7.63026 17.2953 6.63542L19.2194 4.79124C19.6066 4.4201 19.6066 3.81471 19.2194 3.44355Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.4"
      />
    </svg>
  );
}
