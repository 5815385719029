import styled from "styled-components";
import { colors } from "components/App/theme";

export const DropzoneContainer = styled.div`
  border: 3px dashed ${colors.grey20};
  width: 100%;
  border-radius: 10px;
  text-align: center;
  padding: 96px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    border: 3px dashed ${colors.bright[2]};
    background-color: ${colors.greyBGLighter};
  }
`;

export const IconContainer = styled.div`
  color: ${colors.bright[2]};
  max-width: 64px;
  font-size: 24px;
  margin: 0 auto;
  margin-bottom: 12px;
`;

export const LinkLike = styled.span`
  color: ${colors.bright[2]};
  cursor: pointer;
  text-decoration: underline;
`;
