import axios, { AxiosRequestConfig } from "axios";
import { TEMPLATE_BASE_URL } from "constants/constants";
import {
  Category1,
  Category2,
  IdentitiesPrefab,
} from "features/HumanJob/types";
import { useBoundStore } from "store/_boundStore";
import { defaultRestAPIConfig } from "./utils";

class StrapiRestClient {
  axiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: TEMPLATE_BASE_URL,
    });
  }

  defaultConfig(): AxiosRequestConfig {
    return defaultRestAPIConfig(useBoundStore.getState().auth.token);
  }

  async getTemplateCategories(): Promise<(Category1 | Category2)[]> {
    const result = await this.axiosInstance.get(
      `/categories?sort=order:asc&populate[templates][populate]=*&populate[subcategories][populate]=*&filters[hasParent][$eq]=false&populate[templates][sort][0]=order`
    );
    const { data } = result.data;
    return data || [];
  }

  async getIdentitiesPrefabs(): Promise<IdentitiesPrefab[]> {
    const result = await this.axiosInstance.get(
      `/identities-prefabs?sort=order:asc`
    );
    const { data } = result.data;

    return data || [];
  }
}

export { StrapiRestClient };
