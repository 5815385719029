export function MirroredIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 19V19.5H5H8.5V20.5H5C4.17614 20.5 3.5 19.8239 3.5 19V5C3.5 4.17614 4.17614 3.5 5 3.5H8.5V4.5H5H4.5V5V19ZM16.5 19.5V20.5H15.5V19.5H16.5ZM20.5 7.5V8.5H19.5V7.5H20.5ZM20.4135 4.5H19.5V3.58648C19.924 3.73798 20.262 4.076 20.4135 4.5ZM12.5 1.5V22.5H11.5V1.5H12.5ZM20.5 15.5V16.5H19.5V15.5H20.5ZM16.5 3.5V4.5H15.5V3.5H16.5ZM20.5 11.5V12.5H19.5V11.5H20.5ZM19.5 20.4135V19.5H20.4135C20.262 19.924 19.924 20.262 19.5 20.4135Z"
        fill={fill}
        stroke={fill}
      />
    </svg>
  );
}
