import { StepItem } from "features/JobBuilder2/step-item/step-item";
import { getPipe2Step } from "features/JobBuilder2/types";

export const getAssignActivitiesStep = (
  threeDElement: JSX.Element,
  hasAtleastOneActivity: boolean
) => {
  return getPipe2Step(
    "Assign activities",
    <StepItem disabledPadding>{threeDElement}</StepItem>,
    hasAtleastOneActivity
  );
};
