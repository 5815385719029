import React, { createContext, useContext } from "react";

const BreadcrumbsContext = createContext<{
  selectedSubjobIndex: number;
  numberOfImageForSelectedSection: string;
}>({
  selectedSubjobIndex: 0,
  numberOfImageForSelectedSection: "",
});

export const BreadcrumbsProvider = BreadcrumbsContext.Provider;

export const useBreadcrumbsContext = () => useContext(BreadcrumbsContext);
