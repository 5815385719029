import { Box } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const LeftSideBarContainer = styled(Box)`
  background-color: ${colors.white};
  width: 320px;
  border-right: 1px solid ${colors.grey20};
  display: flex;
  flex-direction: column;
`;
