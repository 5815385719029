import React from "react";
import { Box, Link, Typography } from "@mui/material";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import { colors } from "components/App/theme";
import { dataTableCustomStyles } from "components/Common/DataTable/customStyles";
import { DateRow } from "components/Common/DataTable/DateRow";
import { StripeInvoice } from "domain/Invoice";

export function PaymentHistory({ rows }: { rows: StripeInvoice[] }) {
  return (
    <>
      <Box
        bgcolor={colors.neutral[0]}
        component="div"
        data-testid="payment-history-component"
        padding={2}
      >
        <Box
          component="div"
          display="flex"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h2">Payment History</Typography>
          <Link href="https://synthesis.ai/pricing/" underline="always">
            Pricing Details
          </Link>
        </Box>
        <DataTable
          noHeader
          responsive
          columns={getColumns()}
          customStyles={dataTableCustomStyles}
          data={rows}
          defaultSortField="date"
          highlightOnHover={false}
        />
      </Box>
    </>
  );
}

const getColumns = (): IDataTableColumn[] => {
  return [
    {
      name: "Billing date",
      sortable: false,

      cell: (row) => {
        return <DateRow dateStr={row.date} />;
      },
    },
    {
      name: "Amount",
      selector: "amount",
      sortable: false,
      allowOverflow: true,
    },
    {
      name: "Invoice",
      sortable: false,
      cell: (row) => {
        if (row.receipt_url) {
          return (
            <Link
              href={row.receipt_url}
              style={{ whiteSpace: "nowrap" }}
              target="_blank"
              underline="hover"
            >
              Download
            </Link>
          );
        }
        return "N/A";
      },
    },
  ];
};
