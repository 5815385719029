export function XAxisIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 15V14.5H19H5H4.5V15V17V17.5H5H19H19.5V17V15ZM3.5 6.5V5.5H20.5V6.5H3.5ZM3.5 10.5V9.5H20.5V10.5H3.5ZM3.5 18.5V13.5H20.5V18.5H3.5Z"
        fill={fill}
        stroke={fill}
      />
    </svg>
  );
}
