import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  padding-right: 4px;
`;

export const Label = styled(Typography)<{ selected: boolean }>`
  padding-left: 2px;
  font-size: 12px;
  line-height: 16px;
  color: ${({ selected }) => (selected ? colors.orange : colors.grey70)};
`;
