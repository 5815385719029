import { useCallback, useMemo, useState } from "react";
import { Box, Checkbox, Divider, Input, Typography } from "@mui/material";
import { produce } from "immer";
import lightIntensityImage from "assets/diagrams/light-intensity-image.svg";
import lightRotationImage from "assets/diagrams/light-rotation-image.png";
import { ImageGrid, ImageGridImage } from "components/ImageGrid";
import { SelectorsPicker } from "components/SelectorsPicker";
import { LoRType } from "domain/Human";
import { StepItem } from "features/JobBuilder2/step-item/step-item";
import { defaultGlobalEnv, timesOfDay } from "features/JobBuilder2/types";
import afternoon from "./assets/Afternoon.png";
import earlyMorning from "./assets/EarlyMorning.png";
import lateMorning from "./assets/LateMorning.png";
import night from "./assets/Night.png";
import overcast from "./assets/Overcast.png";
// import smokey from "./assets/Smokey.png";
import sunset from "./assets/Sunset.png";

type ImageGridWithLabel = ImageGridImage & {
  label: string;
};

const timesOfDayImages = [
  earlyMorning,
  lateMorning,
  afternoon,
  sunset,
  night,
  overcast,
  // smokey,
] as const;

type TimeOfTheDayProps = {
  value: typeof defaultGlobalEnv;
  onUpdate: (globalEnv: typeof defaultGlobalEnv) => void;
};

export function TimeOfTheDay(props: TimeOfTheDayProps) {
  const { value, onUpdate } = props;
  const onUpdateLocal = useCallback(
    (fn: (draft: typeof defaultGlobalEnv) => void) => {
      onUpdate(produce(value, fn));
    },
    [onUpdate, value]
  );

  const onUpdateNames = useCallback(
    (v: string[]) =>
      onUpdateLocal((d) => {
        d.name = v;
      }),
    [onUpdateLocal]
  );

  const onUpdateIntensity = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.intensity = v;
    });

  const onUpdateRotation = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.rotation = v;
    });

  const [images, _setImages] = useState<Array<ImageGridWithLabel>>(() => {
    return timesOfDay.map<ImageGridWithLabel>((timeOfDay, index) => ({
      key: timeOfDay,
      src: timesOfDayImages[index],
      alt: timeOfDay,
      label: timeOfDay,
      isSelected: value.name.includes(timeOfDay),
    }));
  });

  const setImages = useCallback(
    (images: Array<ImageGridWithLabel>) => {
      _setImages(images);
      onUpdateNames(images.filter((i) => i.isSelected).map((i) => i.label));
    },
    [onUpdateNames]
  );

  const handleSelect = (i: number) => {
    setImages(
      images.map((image, index) => {
        if (index === i) {
          return {
            ...image,
            isSelected: !image.isSelected,
          };
        }
        return image;
      })
    );
  };

  const onToggleSelectAll = (v: boolean) => {
    setImages(
      images.map((i) => ({ ...i, isSelected: v } as ImageGridWithLabel))
    );
  };

  const areAllSelected = images.every((i) => i.isSelected);

  const [searchTerm, setSearchTerm] = useState("");

  const filteredImages = useMemo(
    () =>
      images.filter((i) =>
        i.label.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [images, searchTerm]
  );

  return (
    <>
      <StepItem>
        <Typography variant="h4">
          Outdoor Times of Day (seen through windows if indoor location)
        </Typography>

        <Divider sx={{ my: 2 }} />

        <Box
          component="div"
          display="flex"
          justifyContent="space-between"
          mb={2}
        >
          <Box component="div" sx={{ position: "relative", left: -10 }}>
            <Checkbox
              checked={areAllSelected}
              color="warning"
              id="selectedTimesOfTheDay"
              onChange={(e) => onToggleSelectAll(e.target.checked)}
            />
            <label htmlFor="selectedTimesOfTheDay">Select All</label>
          </Box>
          <Input
            disableUnderline
            placeholder="Search"
            sx={{ border: "1px solid #ccc", borderRadius: 1, px: 2 }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>
        <Box component="div">
          <ImageGrid images={filteredImages} onSelect={handleSelect} />
        </Box>

        <Box component="div" mt={6}>
          <Typography variant="h4">Light Bulb Levels</Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box component="div">
          <SelectorsPicker
            icon="BrightnessIcon"
            imgSrc={lightIntensityImage}
            limits={defaultGlobalEnv.intensity}
            lorValue={value.intensity}
            showNormalDistribution={false}
            step={0.05}
            title="Light Bulb/Indoor Intensity"
            unit=""
            onUpdate={onUpdateIntensity}
          />
        </Box>

        <Box component="div" mt={6}>
          <Typography variant="h4">
            Sky (outdoors) / Window Scene (indoors)
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box component="div">
          <SelectorsPicker
            icon="LightModeIcon"
            imgSrc={lightRotationImage}
            limits={defaultGlobalEnv.rotation}
            lorValue={value.rotation}
            showNormalDistribution={false}
            step={1}
            title="Outdoor Light Rotation"
            unit="degrees"
            onUpdate={onUpdateRotation}
          />
        </Box>
      </StepItem>
    </>
  );
}
