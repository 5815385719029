import React from "react";
import { Backdrop, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

function FullScreenProgress() {
  return (
    <Backdrop open sx={{ zIndex: 4000 }}>
      <Box component="div" textAlign="center">
        <CircularProgress size="6rem" />
      </Box>
    </Backdrop>
  );
}

export { FullScreenProgress };
