import { PrefabGroupMap } from "domain/Prefabs";
import { getRandomFromArr } from "utils/arrUtil";
import { Activity, defaultSubjob, FrameCutoffMode } from "./types";
export const wavelengthOptions = ["infrared", "visible"];

export const TARGET_FPS = 30;
export const atleastOneActivityForOneActivityZone = (
  activityZones: typeof defaultSubjob.activityZones
) => {
  return Boolean(activityZones.find((z) => z.activities.length > 0));
};

export const hasAtleastOneActivityZone = (
  activityZones: typeof defaultSubjob.activityZones
) => activityZones.length > 0;

export const hasAtleastOnePrefabGroupIndex = (
  activityZones: typeof defaultSubjob.activityZones
) =>
  activityZones.every((zone) => {
    if (!zone.activities || zone.activities.length === 0) {
      return true;
    }

    return zone.prefabGroupId !== "" && zone.activities.length;
  });

export const hasRigLocation = (rigLocationIndex: number) =>
  rigLocationIndex !== -1;

export const validActivityZoneBasedDistrubtionConfig = (
  distribution: typeof defaultSubjob.distribution
) =>
  Boolean(
    distribution.configs[FrameCutoffMode.dynamic]
      .activitiesInAllScenesForSpecialZone.length
  );

export const hasDistributions = (
  distribution: typeof defaultSubjob.distribution
) =>
  distribution.numberOfScenes !== "" &&
  Number(distribution.numberOfScenes) > 0 &&
  (distribution.frameCutoffMode === FrameCutoffMode.static
    ? true
    : validActivityZoneBasedDistrubtionConfig(distribution));

export const isSubjobValid = (sj: typeof defaultSubjob) => {
  return (
    // hasLocation(sj.location) &&
    // hasRigLocation(sj.rigLocationIndex) &&
    hasAtleastOneActivityZone(sj.activityZones) &&
    atleastOneActivityForOneActivityZone(sj.activityZones) &&
    hasAtleastOnePrefabGroupIndex(sj.activityZones) &&
    hasDistributions(sj.distribution)
  );
};

export const getEndFrameConsideringFPS = (end_frame: number, fps: number) =>
  Math.floor(((end_frame - 1) * TARGET_FPS) / fps) + 1;

export const getActivityEndFrame = (
  activityName: string,
  activities: Activity[]
) => {
  const foundActivity = activities.find((a) => a.name === activityName);
  if (!foundActivity) {
    return 0;
  }
  return getEndFrameConsideringFPS(foundActivity.end_frame, foundActivity.fps);
};

export const subjobMaximumFrameLimit = (
  activitiesInSubjob: string[][],
  allActivities: Activity[]
) =>
  Math.max(
    ...activitiesInSubjob
      .map((activitiesInZone) =>
        activitiesInZone.map((a) => getActivityEndFrame(a, allActivities))
      )
      .flat()
  );

export const pickRandomActivitiesForDynamicMode = (
  pickedActivityZoneIndex: string,
  numberOfScenes: string,
  subjob: typeof defaultSubjob
) => {
  const activities =
    subjob.activityZones[Number(pickedActivityZoneIndex)].activities;
  const pickedActivitiesInRandom = [] as string[];
  for (let i = 0; i < Number(numberOfScenes); i++) {
    pickedActivitiesInRandom.push(getRandomFromArr(activities));
  }
  return pickedActivitiesInRandom;
};

export const convertPrefabGroupMapToCharactersStringArr = (
  prefabGroups: PrefabGroupMap
) => {
  const prefabGroupIDToCharacters: { [k: string]: string[] } = {};

  // fill charactersToBePopped
  Object.keys(prefabGroups).forEach((id) => {
    const newCharactersArr = prefabGroups[id].characters.map((c) => c);
    prefabGroupIDToCharacters[id] = newCharactersArr;
  });

  return prefabGroupIDToCharacters;
};
