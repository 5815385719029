import { useEffect, useState } from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router";
import { ActionButton } from "components/Common/ActionButton";
import { dataTableCustomStyles } from "components/Common/DataTable/customStyles";
import { EditableDatatableBox } from "components/Common/DataTable/EditableDatatableBox";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { ConfirmationDialog } from "components/dialogs/ConfirmationDialog";
import { Routes } from "constants/routes";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";

function ManageApiKeys() {
  const navigate = useNavigate();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedKeyForDelete, setSelectedKeyForDelete] = useState("");
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<{ name: string }[]>([]);

  const { setErrorMessage } = useBoundStore.getState().message;
  const userId = useBoundStore.getState().auth.getCurrentUserId();

  useEffect(() => {
    const fetchApiKeys = async (userId: string) => {
      setLoading(true);
      try {
        const result = await restClient.getApiKeys(userId);
        setRows(result);
      } catch (error) {
        Sentry.captureException(error);
        setErrorMessage(restClient.APIErrorMessage(error));
      }
      setLoading(false);
    };

    if (userId) {
      fetchApiKeys(userId);
    }
  }, [userId, setErrorMessage]);

  const deleteApiKey = (apiKeyName: string) => {
    setSelectedKeyForDelete(apiKeyName);
    setShowDeleteDialog(true);
  };

  const editApiKey = async (oldName: string, newName: string) => {
    setLoading(true);
    try {
      if (userId) {
        await restClient.renameApiKey(userId, oldName, newName);
        const newRows = rows.map((row: { name: string }) =>
          row.name === oldName ? { name: newName } : row
        );
        setRows(newRows);
      }
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  const confirmDelete = async () => {
    if (!userId) {
      return;
    }

    setLoading(true);
    try {
      await restClient.deleteApiKey(userId, selectedKeyForDelete);
      setSelectedKeyForDelete("");
      setShowDeleteDialog(false);
      const newRows = rows.filter(
        (row: { name: string }) => row.name !== selectedKeyForDelete
      );
      setRows(newRows);
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  return (
    <>
      {showDeleteDialog && (
        <ConfirmationDialog
          data-testid="delete-api-key-dialog"
          textContent={
            <>
              Are you sure you want to delete{" "}
              <span className="bold-text">{selectedKeyForDelete}</span>?{" "}
            </>
          }
          title="Delete API Key"
          onCancel={() => {
            setSelectedKeyForDelete("");
            setShowDeleteDialog(false);
          }}
          onConfirm={confirmDelete}
        />
      )}

      <Grid container justifyContent="center">
        {loading ? (
          <FullScreenProgress />
        ) : (
          <Grid item data-testid="manage-api-keys" md={6} xs={12}>
            <Typography variant="h1">Manage API Keys</Typography>
            <DataTable
              noHeader
              responsive
              columns={[
                {
                  name: "Key Name",
                  selector: "name",
                  grow: 2,
                  cell: (row) => {
                    return (
                      <EditableDatatableBox
                        id={row.name}
                        isEditable={true}
                        oldLabel={row.name}
                        onEdit={editApiKey}
                      />
                    );
                  },
                },
                {
                  name: "",
                  grow: 0,
                  cell: (row) => {
                    return (
                      <Link
                        href="#"
                        underline="hover"
                        onClick={() => deleteApiKey(row.name)}
                      >
                        Delete
                      </Link>
                    );
                  },
                },
              ]}
              customStyles={dataTableCustomStyles}
              data={rows}
              highlightOnHover={false}
              keyField="name"
            />
            <Box component="div" mt={4}>
              <ActionButton
                color="warning"
                onClick={() => navigate(Routes.NEW_API_KEY)}
              >
                Create new API Key
              </ActionButton>
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export { ManageApiKeys };
