import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PL2_JOB_STATE_VERSION } from "constants/constants";
import { Routes } from "constants/routes";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { generateInputJson } from "./state-to-input-json";
import { segmentTrack } from "./track-service";

export const useJobSubmission = (
  orgParam: string,
  draftId: string | undefined,
  templateName: string
) => {
  const navigate = useNavigate();
  const [
    activities,
    prefabGroups,
    locations,
    subjobs,
    userEmail,
    userID,
    setErrorMessage,
    setSuccessMessage,
    clearState,
  ] = useBoundStore((s) => [
    s.catalogData.activities,
    s.catalogData.prefabGroups,
    s.catalogData.locations,
    s.jobBuilder2.subjobs,
    s.profile.data?.email,
    s.profile.data?.id,
    s.message.setErrorMessage,
    s.message.setSuccessMessage,
    s.jobBuilder2.clearState,
  ]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitJob = async () => {
    if (!templateName || !userEmail) {
      return;
    }
    const inputJson = generateInputJson(
      subjobs,
      prefabGroups,
      locations,
      activities
    );
    try {
      if (!draftId) {
        return;
      }
      setIsSubmitting(true);
      const { job_id } = await restClient.submitPL2Job(
        orgParam,
        inputJson,
        userEmail,
        templateName
      );
      const addJobPresubmissionDataPromise =
        restClient.patchJobPresubmissionData(orgParam, job_id, {
          state: subjobs,
          version: PL2_JOB_STATE_VERSION,
        });
      const deleteDraftPromise = restClient.deletePL2Draft(orgParam, draftId);
      await Promise.all([addJobPresubmissionDataPromise, deleteDraftPromise]);
      segmentTrack("pl2 job submitted", userID, { subjobs });
      clearState();

      setSuccessMessage(`${templateName} has been submitted`);
      setIsSubmitting(false);
      navigate(`${Routes.JOBS}?org=${orgParam}`);
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
      setIsSubmitting(false);
    }
  };

  return {
    submitJob,
    isSubmitting,
  };
};
