import { MutableRefObject, useEffect, useRef } from "react";

export function PreventHTMLPointerEvents() {
  const div = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    const grandparent = div.current.parentElement?.parentElement;
    if (grandparent != null) {
      grandparent.style.pointerEvents = "none";
    }
  }, []);

  // An empty div just to get a reference to the parent HTML object.
  return (
    <div ref={div} style={{ position: "absolute", width: 0, height: 0 }} />
  );
}
