import React, { useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import cameraPitchImage from "assets/diagrams/rig-locations-camera-pitch.png";
import cameraRollImage from "assets/diagrams/rig-locations-camera-roll.png";
import cameraXAxisImage from "assets/diagrams/rig-locations-camera-x-axis.png";
import cameraYAxisImage from "assets/diagrams/rig-locations-camera-y-axis.png";
import cameraYawImage from "assets/diagrams/rig-locations-camera-yaw.png";
import cameraZDepthImage from "assets/diagrams/rig-locations-camera-z-depth.png";
import headOrbitPitch from "assets/diagrams/rig-locations-head-orbit-pitch.svg";
import headOrbitRoll from "assets/diagrams/rig-locations-head-orbit-roll.svg";
import headOrbitYaw from "assets/diagrams/rig-locations-head-orbit-yaw.svg";
import headOrbitzDepth from "assets/diagrams/rig-locations-head-orbit-z-depth.svg";
import headOrbitXAxis from "assets/diagrams/rig-locations-head-x-axis.svg";
import headOrbitYAxis from "assets/diagrams/rig-locations-head-y-axis.svg";
import { Collapsible } from "components/Collapsible";
import { SelectorsPicker } from "components/SelectorsPicker";
import { LoRType, lorWithTitleUI } from "domain/Human";
import { defaultRig } from "features/JobBuilder2/types";
import { SelectorContainer } from "features/JobBuilderShared/Styles/styles";

const headOrbitDiagrams = {
  pitch: headOrbitPitch,
  roll: headOrbitRoll,
  x: headOrbitXAxis,
  y: headOrbitYAxis,
  yaw: headOrbitYaw,
  zDepth: headOrbitzDepth,
};

const otherDiagrams = {
  pitch: cameraPitchImage,
  roll: cameraRollImage,
  x: cameraXAxisImage,
  y: cameraYAxisImage,
  yaw: cameraYawImage,
  zDepth: cameraZDepthImage,
};

export const rigLocationDiagrams: {
  [key: string]: {
    pitch: string;
    roll: string;
    x: string;
    y: string;
    yaw: string;
    zDepth: string;
  };
} = {
  head_orbit: headOrbitDiagrams,
  head_orbit_with_tracking: headOrbitDiagrams,
  head_orbit_in_car: headOrbitDiagrams,
  preset_location: otherDiagrams,
  preset_location2: otherDiagrams,
  manual: otherDiagrams,
};

type RigLocationProps = {
  type: string;
  value: typeof defaultRig;
  onUpdateLocal: (fn: (draft: typeof defaultRig) => void) => void;
  justifyContent?: React.CSSProperties["justifyContent"];
  showNormalDistribution?: boolean;
};

export function RigLocation(props: RigLocationProps) {
  const { type, value, onUpdateLocal, showNormalDistribution = false } = props;

  const onUpdateX = useCallback(
    (v: LoRType) =>
      onUpdateLocal((d) => {
        d.x = v;
      }),
    [onUpdateLocal]
  );
  const onUpdateY = useCallback(
    (v: LoRType) =>
      onUpdateLocal((d) => {
        d.y = v;
      }),
    [onUpdateLocal]
  );
  const onUpdateZ = useCallback(
    (v: LoRType) =>
      onUpdateLocal((d) => {
        d.z = v;
      }),
    [onUpdateLocal]
  );

  const onUpdatePitch = useCallback(
    (v: LoRType) =>
      onUpdateLocal((d) => {
        d.pitch = v;
      }),
    [onUpdateLocal]
  );
  const onUpdateYaw = useCallback(
    (v: LoRType) =>
      onUpdateLocal((d) => {
        d.yaw = v;
      }),
    [onUpdateLocal]
  );
  const onUpdateRoll = useCallback(
    (v: LoRType) =>
      onUpdateLocal((d) => {
        d.roll = v;
      }),
    [onUpdateLocal]
  );
  const locations = useMemo(() => {
    const z = lorWithTitleUI(
      "Z - Depth",
      value.z,
      defaultRig.z,
      onUpdateZ,
      "meters",
      "ZDepthIcon",
      rigLocationDiagrams[type].zDepth
    );
    const angleLocations = [
      lorWithTitleUI(
        "Pitch",
        value.pitch,
        defaultRig.pitch,
        onUpdatePitch,
        "degrees",
        "PitchIcon",
        rigLocationDiagrams[type].pitch
      ),
      lorWithTitleUI(
        "Yaw",
        value.yaw,
        defaultRig.yaw,
        onUpdateYaw,
        "degrees",
        "YawIcon",
        rigLocationDiagrams[type].yaw
      ),
      lorWithTitleUI(
        "Roll",
        value.roll,
        defaultRig.roll,
        onUpdateRoll,
        "degrees",
        "RollIcon",
        rigLocationDiagrams[type].roll
      ),
    ];
    const xAndY = [
      lorWithTitleUI(
        "X - Axis",
        value.x,
        defaultRig.x,
        onUpdateX,
        "meters",
        "XAxisIcon",
        rigLocationDiagrams[type].x
      ),
      lorWithTitleUI(
        "Y - Axis",
        value.y,
        defaultRig.y,
        onUpdateY,
        "meters",
        "YAxisIcon",
        rigLocationDiagrams[type].y
      ),
    ];
    return type === "manual" || type === "preset_location2"
      ? xAndY.concat([z]).concat(angleLocations)
      : type === "preset_location"
      ? angleLocations
      : [z].concat(angleLocations).concat(xAndY);
  }, [
    type,
    value.x,
    value.y,
    value.z,
    value.pitch,
    value.yaw,
    value.roll,
    onUpdateX,
    onUpdateY,
    onUpdateZ,
    onUpdatePitch,
    onUpdateYaw,
    onUpdateRoll,
  ]);

  return (
    <Collapsible
      justifyContent="flex-start"
      label="Rig Center Offset from Preset"
    >
      <Box component="div" display="inline-block" px={2}>
        {locations.map((l) => (
          <SelectorContainer key={l.title}>
            <SelectorsPicker
              noSpacing
              icon={l.icon ?? "SensorWidthIcon"}
              imgSrc={l.imgSrc ?? ""}
              limits={l.limits}
              lorValue={l.value}
              showNormalDistribution={showNormalDistribution}
              title={l.title}
              unit={l.unitLabel}
              onUpdate={l.updateFn}
            />
          </SelectorContainer>
        ))}
      </Box>
    </Collapsible>
  );
}
