import { TreeItem } from "@mui/lab";
import { Box } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const ReverseTreeItem = styled(TreeItem)`
  .MuiTreeItem-content {
    flex-direction: row-reverse;
    width: 100%;
  }
`;

export const SubjobContainer = styled(Box)`
  padding: 0 12px;
  margin-bottom: 8px;
  overflow-y: auto;
  max-height: 115px;
  border-bottom: 1px solid ${colors.grey20};
`;
