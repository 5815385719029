import { useMemo } from "react";
import { Typography, Button, Box } from "@mui/material";
import { Html } from "@react-three/drei";
import { Vector2, Vector3 } from "three";
import { colors } from "components/App/theme";
import { PreventHTMLPointerEvents } from "features/JobBuilder2/steps/threeDimensional/PreventHTMLPointerEvents";
import { selectPickedSubjob } from "features/JobBuilder2/store";
import { useBoundStore } from "store/_boundStore";
import { addSIfGreaterThan1 } from "utils/stringUtil";
import { PopupWrapper } from "./CameraPresetLocationPopup.styled";

const sizeFactor = 0.007;
const zIndex = 0;

export function CameraPresetLocationPopup({
  distanceFromRig,
  facingDirection,
  imageLink,
}: {
  distanceFromRig: number;
  facingDirection: Vector2;
  imageLink: string;
}) {
  const [setIsCameraRigDialogOpened, pickedSubjob] = useBoundStore((s) => [
    s.jobBuilder2.setIsCameraRigDialogOpened,
    selectPickedSubjob(s),
  ]);

  const position = useMemo(() => {
    return (
      facingDirection
        .clone()
        // Space between camera and popup
        .setLength(distanceFromRig)
    );
  }, [facingDirection, distanceFromRig]);

  const arrowPointer = useMemo(() => {
    const xDiff = Math.abs(position.x);
    const yDiff = Math.abs(position.y);
    const angleIsMainlyHorizontal = Math.abs(xDiff) > Math.abs(yDiff);

    return angleIsMainlyHorizontal
      ? position.x < 0
        ? "right"
        : "left"
      : position.y < 0
      ? "top"
      : "bottom";
  }, [position]);

  return (
    <group position={new Vector3(position.x, position.y, zIndex)}>
      <Html
        as="div"
        distanceFactor={distanceFromRig * sizeFactor}
        pointerEvents="none"
        style={{
          pointerEvents: "none",
        }}
        zIndexRange={[2, 5]}
      >
        <PreventHTMLPointerEvents />
        <PopupWrapper className={arrowPointer} scale={1}>
          <Box
            alignItems="center"
            color={colors.white}
            component="div"
            display="flex"
            flexDirection="column"
            gap={1}
            justifyContent="center"
          >
            <Typography variant="body2">
              {addSIfGreaterThan1(pickedSubjob.rig.cameras.length, "camera")}
            </Typography>
            <img alt="rig location" src={imageLink} width="120px" />
            <Button
              color={"warning"}
              size="small"
              sx={{
                pointerEvents: "initial",
              }}
              variant="contained"
              onClick={() => setIsCameraRigDialogOpened(true)}
            >
              Edit Rig
            </Button>
          </Box>
        </PopupWrapper>
      </Html>
    </group>
  );
}
