import { measurements } from "@flasd/modeling";
import { Geom2 } from "@flasd/modeling/src/geometries/types";

export const area = (geom2: Geom2) => measurements.measureArea(geom2);
export const bounds = (geom2: Geom2) => {
  const box = measurements.measureBoundingBox(geom2);
  const [[left, top], [right, bottom]] = box;
  return {
    left,
    right,
    top,
    bottom,
  };
};
