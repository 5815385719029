import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export function InfoDialog({
  fullScreen,
  children,
  onClose,
  title,
}: {
  fullScreen: boolean;
  title?: string;
  children: React.ReactNode;
  onClose?: () => void;
}) {
  return (
    <Dialog
      open
      fullScreen={fullScreen}
      sx={{ zIndex: 3000 }}
      onClose={onClose}
    >
      {title && (
        <DialogTitle>
          <Box alignItems="flex-start" component="div" display="flex">
            <Box component="div" flexGrow={1}>
              {title}
            </Box>
            {onClose && (
              <Box component="div" display="flex" flexGrow={0}>
                <CloseIcon onClick={onClose} />
              </Box>
            )}
          </Box>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      {onClose && (
        <DialogActions>
          <Box
            component="div"
            mb={1}
            ml={2}
            mr={2}
            mt={2}
            textAlign="right"
            width="100%"
          >
            <Button
              autoFocus
              fullWidth
              color="warning"
              size="small"
              variant="contained"
              onClick={onClose}
            >
              Close
            </Button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
}
