/* eslint-disable @typescript-eslint/no-non-null-assertion */
// TODO: remove eslint disable after fixing all the non-null assertion for org
import React, { useEffect, useState } from "react";
import { Box, Typography, Link } from "@mui/material";
import * as Sentry from "@sentry/react";
import DataTable from "react-data-table-component";
import { Link as RouterLink } from "react-router-dom";
import { ActionButton } from "components/Common/ActionButton";
import { dataTableCustomStyles } from "components/Common/DataTable/customStyles";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { ConfirmationDialog } from "components/dialogs/ConfirmationDialog";
import { NavigationHeader } from "components/Navigation/Header";
import { UserNameInlineEdit } from "components/UserNameInlineEdit";
import { Messages } from "constants/messages";
import { URLParams } from "constants/params";
import { Routes } from "constants/routes";
import { OrgUser } from "domain/OrgUser";
import { useOrgParam } from "hooks/useOrgParam";
import { roleObjectToStr } from "services/permission-service";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { selectedOrgSelector } from "store/orgStore";
import { EditUser } from "./EditUser";

export function Team() {
  const [loading, setLoading] = useState(false);
  const [showLeaveOrgDialog, setShowLeaveOrgDialog] = useState(false);
  const [isCurrentUserTheOnlyOwner, setIsCurrentUserTheOnlyOwner] =
    useState(false);
  const [users, setUsers] = useState<OrgUser[]>();
  const { orgParam } = useOrgParam();
  const [
    logout,
    org,
    permissions,
    currentUserId,
    setErrorMessage,
    setSuccessMessage,
  ] = useBoundStore((s) => [
    s.auth.logout,
    selectedOrgSelector(orgParam)(s),
    s.org.selected?.permissions,
    s.profile.data?.id,
    s.message.setErrorMessage,
    s.message.setSuccessMessage,
  ]);

  // hide the leave org functionlity if this user is the last owner
  useEffect(() => {
    if (users && currentUserId) {
      const owners = users.filter((user) => user.is_owner);

      if (
        owners.length === 1 &&
        owners[0] &&
        owners[0].user_id &&
        owners[0].user_id === parseInt(currentUserId, 10)
      ) {
        setIsCurrentUserTheOnlyOwner(true);
      }
    }
  }, [users, currentUserId]);

  const onUpdateUserName = async (
    userId: number,
    firstName: string,
    lastName: string
  ) => {
    setLoading(true);
    try {
      await restClient.updateOrgUserName(
        org!.name,
        userId,
        firstName,
        lastName
      );
      const users = await restClient.getOrgUsers(org!.name);
      setUsers(users);
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  useEffect(() => {
    let isCancelled = false;
    if (org?.name) {
      const fetchUsers = async () => {
        setLoading(true);
        try {
          const result = await restClient.getOrgUsers(org.name);
          if (isCancelled) {
            return;
          }

          setUsers(result);
        } catch (error) {
          Sentry.captureException(error);
          setErrorMessage(restClient.APIErrorMessage(error));
        }
        setLoading(false);
      };

      fetchUsers();
    }

    return () => {
      isCancelled = true;
    };
  }, [org?.name, setErrorMessage]);

  const updateRole = async (
    email: string,
    role: "owner" | "writer" | "reader"
  ) => {
    setLoading(true);
    try {
      const orgName = org!.name;
      await restClient.updateUserPemissions(orgName, email, role);
      // we need to reload the user list
      const users = await restClient.getOrgUsers(orgName);
      setUsers(users);
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  const removeUser = async (userId: number) => {
    setLoading(true);
    try {
      const orgName = org!.name;
      await restClient.removeUserFromOrg(orgName, userId);
      window.analytics.track("Removed User", {
        orgName,
        userId,
      });
      const users = await restClient.getOrgUsers(orgName);
      setUsers(users);
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  const leaveOrg = async () => {
    setLoading(true);
    try {
      if (currentUserId) {
        const orgName = org!.name;
        await restClient.removeUserFromOrg(orgName, currentUserId);
        window.analytics.track("Left org", {
          orgName,
          currentUserId,
        });
        logout("");
        setSuccessMessage(Messages.leftOrg(orgName));
      }
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  return (
    <>
      {showLeaveOrgDialog && (
        <ConfirmationDialog
          textContent={
            <>
              {" "}
              Are you sure you want to leave{" "}
              <span className="bold-text">{org!.name}</span>?{""}
            </>
          }
          title="Leave org"
          onCancel={() => setShowLeaveOrgDialog(false)}
          onConfirm={() => {
            setShowLeaveOrgDialog(false);
            leaveOrg();
          }}
        />
      )}
      {loading && <FullScreenProgress />}
      <Box component="div" data-testid="team-component" height="100%">
        <NavigationHeader selectedOrgName={org?.name} title="Team" />
        {org && users && (
          <>
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              mt={2}
            >
              <Box component="div">
                <Box component="div">
                  {org.name} has{" "}
                  <Box component="div" display="inline" fontWeight={600}>
                    {users.length}
                  </Box>{" "}
                  team member
                  {users.length === 1 ? "" : "s"}
                </Box>
              </Box>
              {!isCurrentUserTheOnlyOwner && (
                <Box component="div">
                  <ActionButton
                    fullWidth
                    color="warning"
                    onClick={() => setShowLeaveOrgDialog(true)}
                  >
                    Leave Org
                  </ActionButton>
                </Box>
              )}
            </Box>
            {permissions?.is_owner && org && (
              <Box component="div">
                <Link
                  component={RouterLink}
                  to={Routes.ADD_USER + `?${URLParams.org}=${org.name}`}
                  underline="always"
                >
                  Add new team member
                </Link>
              </Box>
            )}
            <Box component="div" mt={4} pb={8}>
              <div className="datatableWrapper">
                <DataTable
                  noHeader
                  pagination
                  responsive
                  columns={[
                    {
                      name: "Name",
                      selector: "name",
                      grow: 2,
                      sortable: true,
                      cell: (row) => {
                        return (
                          <UserNameInlineEdit
                            firstName={row.first_name}
                            isOwner={permissions?.is_owner || false}
                            lastName={row.last_name}
                            onSave={(firstName: string, lastName: string) =>
                              onUpdateUserName(row.user_id, firstName, lastName)
                            }
                          />
                        );
                      },
                    },
                    {
                      name: "Email",
                      selector: "email",
                      sortable: true,
                      grow: 2,
                      cell: (row) => {
                        if (row.verified === false) {
                          return (
                            <Box component="div">
                              {row.email}
                              <Typography variant="caption">
                                {" "}
                                (pending)
                              </Typography>
                            </Box>
                          );
                        }
                        return <Box component="div">{row.email}</Box>;
                      },
                    },
                    {
                      name: "Jobs",
                      selector: "job_count",
                      sortable: true,
                      grow: 0,
                      width: "80px",
                      right: true,
                    },
                    {
                      name: "Role",
                      sortable: false,
                      width: "500px",
                      grow: 0,
                      cell: (row) => (
                        <EditUser
                          allowEdit={!!permissions?.is_owner}
                          email={row.email}
                          isVerified={row.verified}
                          orgName={org.name}
                          role={roleObjectToStr(row)}
                          user_id={row.user_id}
                          onRemoveUser={removeUser}
                          onUpdateRole={updateRole}
                        />
                      ),
                    },
                  ]}
                  customStyles={dataTableCustomStyles}
                  data={users}
                  defaultSortField="email"
                  highlightOnHover={false}
                  keyField="email"
                />
              </div>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
