import React, { useMemo } from "react";
import { defaultRig } from "domain/Human";
import { CameraPreview } from "features/JobBuilder/CamerasAndLightsPreview/components/CameraPreview";
import { ThreeDimensionalComponent } from "features/JobBuilder/CamerasAndLightsPreview/components/ThreeDimensionalComponent";
import { convertRigStateToJson } from "features/JobBuilder/form-to-input-json";
import { SectionLabel } from "features/JobBuilder/LeftSidebar/SectionLabel";
import { Container } from "./StageView.styled";

interface StageViewProps {
  rigsData: (typeof defaultRig)[];
}

export function StageView({ rigsData }: StageViewProps) {
  const jsonValues = useMemo(
    () => rigsData.map((d) => convertRigStateToJson(d)),
    [rigsData]
  );

  return (
    <Container
      label={<SectionLabel icon="StageViewIcon" label="Stage View" />}
      nodeId="Stage View"
    >
      <CameraPreview noBorder>
        <ThreeDimensionalComponent isStagePreview rigsData={jsonValues} />
      </CameraPreview>
    </Container>
  );
}
