import denoiseImage from "assets/diagrams/camera-denoise.png";
import noiseThresholdImage from "assets/diagrams/camera-noise-threshold.svg";
import { BooleanSelector } from "components/BooleanSelector";
import { Collapsible } from "components/Collapsible";
import { IntegerSelector } from "components/IntegerSelector";
import { SelectorsPicker } from "components/SelectorsPicker";
import { exceedLimit } from "components/SelectorsPicker/utils";
import { Messages } from "constants/messages";
import { lorWithTitleUI, LoRType, defaultCamera } from "domain/Human";
import {
  changeEventValue,
  changeCheckEventValue,
} from "features/JobBuilder/utils";
import { ConfigContainer } from "./AdvancedCameraSettings.styled";

type AdvancedCameraSettingsProps = {
  value: typeof defaultCamera;
  onUpdateLocal: (fn: (draft: typeof defaultCamera) => void) => void;
};

export function AdvancedCameraSettings(props: AdvancedCameraSettingsProps) {
  const { value, onUpdateLocal } = props;

  const noiseThresholdMin = "0.001";
  const noiseThresholdMax = "1";

  const noiseThresholdError = (resolution: string) =>
    exceedLimit(
      resolution,
      Number(noiseThresholdMin),
      Number(noiseThresholdMax)
    ) || Number.isNaN(Number(resolution));

  const noiseThresholdErrorMessage = `Enter a number between ${noiseThresholdMin} and ${noiseThresholdMax}`;

  const onUpdateLensShiftH = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.lens_shift_horizontal = v;
    });
  const onUpdateLensShiftV = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.lens_shift_vertical = v;
    });
  const onUpdateWindowOffsetH = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.window_offset_horizontal = v;
    });
  const onUpdateWindowOffsetV = (v: LoRType) =>
    onUpdateLocal((d) => {
      d.window_offset_vertical = v;
    });
  const onUpdateNoiseThreshold = (v: string) =>
    onUpdateLocal((d) => {
      d.noise_threshold = v;
    });
  const onUpdateDenoise = (v: boolean) =>
    onUpdateLocal((d) => {
      d.denoise = v;
    });

  return (
    <Collapsible
      collapsedByDefault
      label={"Advanced"}
      tag="Set Advanced Camera Settings"
    >
      <div>
        <ConfigContainer>
          {[
            lorWithTitleUI(
              "Lens Shift Horizontal",
              value.lens_shift_horizontal,
              defaultCamera.lens_shift_vertical,
              onUpdateLensShiftH,
              "mm",
              "LensShiftHIcon",
              ""
            ),
            lorWithTitleUI(
              "Lens Shift Vertical",
              value.lens_shift_vertical,
              defaultCamera.lens_shift_vertical,
              onUpdateLensShiftV,
              "",
              "LensShiftVIcon",
              ""
            ),
            lorWithTitleUI(
              "Window Offset Horizontal",
              value.window_offset_horizontal,
              defaultCamera.window_offset_horizontal,
              onUpdateWindowOffsetH,
              "",
              "WindowOffsetHIcon",
              ""
            ),
            lorWithTitleUI(
              "Window Offset Vertical",
              value.window_offset_vertical,
              defaultCamera.window_offset_vertical,
              onUpdateWindowOffsetV,
              "",
              "WindowOffsetVIcon",
              ""
            ),
          ].map((l, i) => (
            <SelectorsPicker
              key={i}
              icon={l.icon ?? "SensorWidthIcon"}
              imgSrc={l.imgSrc ?? ""}
              limits={l.limits}
              lorValue={l.value}
              title={l.title}
              unit={l.unitLabel}
              onUpdate={l.updateFn}
            />
          ))}
          <IntegerSelector
            error={noiseThresholdError(value.noise_threshold)}
            errorMessage={noiseThresholdErrorMessage}
            icon={"NoiseThresholdIcon"}
            imgSrc={noiseThresholdImage}
            label={"Noise Threshold level"}
            limits={{
              max: noiseThresholdMax,
              min: noiseThresholdMin,
            }}
            title={"Noise Threshold"}
            value={value.noise_threshold}
            onUpdate={changeEventValue(onUpdateNoiseThreshold)}
          />
        </ConfigContainer>
        <BooleanSelector
          description={Messages.denoiseDescription}
          icon={"DenoiseIcon"}
          imgSrc={denoiseImage}
          label={"Enabled"}
          title={"Denoise"}
          value={value.denoise}
          onUpdate={changeCheckEventValue(onUpdateDenoise)}
        />
      </div>
    </Collapsible>
  );
}
