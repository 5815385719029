import { predefinedGeomFromActionRegion } from "features/JobBuilder2/steps/threeDimensional/utils/Location";
import { ActivityZone } from "features/JobBuilder2/types";
import { useBoundStore } from "store/_boundStore";
import { selectFirstPrefabGroupID } from "store/catalogueDataStore";

export const activityZonesInit = (locationName: string): ActivityZone[] => {
  const location = useBoundStore
    .getState()
    .catalogData.locations.find((l) => l.name === locationName);

  if (!location) {
    return [];
  }
  const firstPrefabGroupId = selectFirstPrefabGroupID(useBoundStore.getState());
  return [
    predefinedGeomFromActionRegion(location.backgroundActionRegion),
    ...location.sittingRegions,
  ].map((region) => ({
    activities: [],
    geomRegion: region,
    prefabGroupId: firstPrefabGroupId,
  }));
};
