export function CircleFilledIcon({
  fill = "currentColor",
  style,
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="12"
      style={style}
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" fill={fill} r="5.5" stroke={fill} />
    </svg>
  );
}
