// stores
import { createSelector } from "reselect";
import { atleastOneActivityForOneActivityZone } from "features/JobBuilder2/domain";
import {
  numberOfImages,
  numberOfScenes,
} from "features/JobBuilder2/frame-count-calculations";
import { BoundStore } from "store/_boundStore";
import { selectCatalogActivities } from "store/catalogueDataStore";

export const MAX_CHARACTERS_PER_SCENE = 5;

const selectPickedSubjobIndex = (s: BoundStore) =>
  s.jobBuilder2.pickedSubjobIndex;
const selectSubjobs = (s: BoundStore) => s.jobBuilder2.subjobs;
const selectActivitiesPickerDialogDataIndex = (s: BoundStore) =>
  s.jobBuilder2.activitiesPickerDialogData?.zoneIndex;

export const selectPickedSubjob = createSelector(
  [selectSubjobs, selectPickedSubjobIndex],
  (subjobs, i) => subjobs[i]
);
export const selectPickedSubjobLocation = createSelector(
  [selectPickedSubjob],
  (pickedSubjob) => pickedSubjob.location
);
export const selectExceededMaxZones = createSelector(
  [selectPickedSubjob],
  (pickedSubjob) =>
    pickedSubjob.activityZones.filter((z) => z.activities.length).length >=
    MAX_CHARACTERS_PER_SCENE
);

export const selectPickedZoneToAssignActivitiesHasActivities = createSelector(
  [selectActivitiesPickerDialogDataIndex, selectPickedSubjob],
  (pickedzone, pickedSubjob) => {
    if (pickedzone === undefined) {
      return false;
    }
    return Boolean(pickedSubjob.activityZones[pickedzone].activities.length);
  }
);

export const selectNumberOfScenesForAllSubjobs = createSelector(
  [selectSubjobs],
  (subjobs) =>
    subjobs
      .map((s) => numberOfScenes(s.distribution))
      .reduce((a, b) => a + b, 0)
);

export const selectNumberOfImagesForEachSubjob = createSelector(
  [selectSubjobs, selectCatalogActivities],
  (subjobs, activities) => subjobs.map((s, i) => numberOfImages(s, activities))
);

export const selectNumberOfImagesForAllSubjobs = createSelector(
  [selectNumberOfImagesForEachSubjob],
  (arr) => arr.reduce((a, b) => a + b, 0)
);

export const selectNumberOfActivityZonesForAllSubjobs = createSelector(
  [selectSubjobs],
  (subjobs) =>
    subjobs.map((s) => s.activityZones.length).reduce((a, b) => a + b, 0)
);

export const selectPickedSubjobHasAtleastOneActivity = createSelector(
  [selectPickedSubjob],
  (pickedSubjob) =>
    atleastOneActivityForOneActivityZone(pickedSubjob.activityZones)
);
