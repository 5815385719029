import { Box, Typography } from "@mui/material";
import styled, { css } from "styled-components";
import { colors } from "components/App/theme";
import { SelectorWidth } from "components/SelectorsPicker/utils";
import { RangeSelectorContainerType } from "./types";

const minAndMaxLabelsStyles = css<RangeSelectorContainerType>`
  .MuiSlider-markLabel {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;

    &[data-index="0"] {
      color: ${({ minInRange }) =>
        minInRange ? colors.orange : colors.grey70};
    }

    &[data-index="1"] {
      color: ${({ maxInRange }) =>
        maxInRange ? colors.orange : colors.grey70};
    }
  }
`;

const rootColor = css`
  .MuiSlider-root {
    color: ${colors.orange};
  }
`;

const jumpToMinAndMaxSelectorStyles = css`
  .MuiSlider-mark {
    color: ${colors.grey20};
    opacity: 30%;
  }
`;

const sliderBackgroundRailStyles = css`
  .MuiSlider-rail {
    color: ${colors.grey20};
  }
`;

const sliderThumb = css`
  .MuiSlider-thumb {
    color: ${colors.white};
    border: 2px solid ${colors.orange};
    width: 24px;
    height: 24px;

    :hover {
      box-shadow: 0px 0px 5px 2px rgb(238 116 31 / 30%),
        0px 0px 3px 3px rgb(238 116 31 / 20%),
        0px 0px 3px 3px rgb(238 116 31 / 10%);
    }
  }

  .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: 0px 0px 5px 5px rgb(238 116 31 / 30%),
      0px 0px 7px 7px rgb(238 116 31 / 20%),
      0px 0px 10px 10px rgb(238 116 31 / 10%);
  }

  .MuiSlider-thumb.Mui-active {
    box-shadow: 0px 0px 5px 5px rgb(238 116 31 / 30%),
      0px 0px 7px 7px rgb(238 116 31 / 20%),
      0px 0px 10px 10px rgb(238 116 31 / 10%);
  }
`;

const thumbLabel = css`
  .MuiSlider-valueLabelOpen {
    background: none;
    --webkit-transform: translateY(100%) scale(1);
    transform: translateY(100%) scale(1);
  }

  .MuiSlider-valueLabel {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.orange};
  }
`;

export const RangeSelectorContainer = styled.div<RangeSelectorContainerType>`
  display: flex;
  width: ${SelectorWidth};
  align-content: center;
  /* padding: 0 12px; */
  margin-bottom: 4px;

  ${minAndMaxLabelsStyles};
  ${rootColor};
  ${jumpToMinAndMaxSelectorStyles};
  ${sliderBackgroundRailStyles};
  ${sliderThumb};
  ${thumbLabel};
`;

export const Unit = styled(Typography)`
  font-size: 14px;
  line-height: 21px;
  color: ${colors.grey70};
  position: relative;
  bottom: 4px;
`;

export const Container = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
