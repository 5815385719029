import React, { useMemo } from "react";
import { ExpressionIcon } from "assets/icons";
import { RightAsidePreviewFull } from "components/RightAsidePreviewFull";
import { PreviewContent } from "components/RightAsidePreviewFull/PreviewContent";
import { SelectorsPicker } from "components/SelectorsPicker";
import { PUBLIC_S3_BUCKET } from "constants/constants";
import { defaultEye } from "domain/Human";
import { EmptyState } from "features/JobBuilder/empty-state";
import { convertEyeStateToJson } from "features/JobBuilder/form-to-input-json";
import { getEye, getHumanObject } from "features/JobBuilder/json-extracter";
import { JsonManagerNewUI } from "features/JobBuilder/json-manager-new-ui";
import { convertEyes } from "features/JobBuilder/json-to-form";
import {
  DeepPartial,
  JsonEye,
  JsonHuman,
  Uncertain,
} from "features/JobBuilder/json-types";
import { ContentCard, Page } from "features/JobBuilder/layout";
import { MultiOptionImageGrid } from "features/JobBuilder/MultiOptionImageGrid";
import { eyesIrisColorSelector } from "features/JobBuilder/store";
import { useDistPage } from "features/JobBuilder/useDistPage";
import { useImageSelection } from "features/JobBuilder/useImageSelection";
import { useBoundStore } from "store/_boundStore";

export function JobBuilderEyes() {
  const {
    value,
    selectedGroupIndex,
    onUpdate,
    onAdd,
    numberOfImages,
    toggleDistListItem,
    isSummingTo100,
    onUpdatePart,
    onUpdatePercent,
  } = useDistPage("eye");

  const { setErrorMessage, setSuccessMessage } =
    useBoundStore.getState().message;

  const selectedEyeGroup = value[selectedGroupIndex];
  const isEmptyState = !selectedEyeGroup;

  const eyeIrisColorOptions = useBoundStore((s) => eyesIrisColorSelector(s));

  const onUpdateIrisColor = onUpdatePart("iris_color");
  const onUpdatePupilDilation = onUpdatePart("pupil_dilation");
  const onUpdateRedness = onUpdatePart("redness");

  const jsonValue = useMemo(() => convertEyeStateToJson(value), [value]);
  const onDoneJson = (json: Uncertain<JsonEye[]>) => {
    const updated = convertEyes(json, value, eyeIrisColorOptions);
    onUpdate(updated);
  };

  const irisColorUIStyles = useImageSelection(
    selectedEyeGroup ? selectedEyeGroup.iris_color : [],
    eyeIrisColorOptions,
    (color) => `${PUBLIC_S3_BUCKET}/pl1/eyes.iris_color/${color}.png`,
    onUpdateIrisColor,
    "Iris Color",
    "style",
    "StyleIcon",
    toggleDistListItem("iris_color", eyeIrisColorOptions)
  );

  const onIndecesReceived = (
    indices: number[],
    humansArr: DeepPartial<JsonHuman>[]
  ) => {
    try {
      onUpdate(
        convertEyes(
          getEye(getHumanObject(humansArr, indices[0])),
          value,
          eyeIrisColorOptions
        )
      );
      setSuccessMessage("updated eyes");
    } catch (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
      }
    }
  };

  const center = (
    <JsonManagerNewUI<Uncertain<JsonEye[]>>
      emptyState={
        isEmptyState ? (
          <EmptyState
            description="Eyes controls variation of iris color, pupil dilation, and redness. Default eyes variations are applied, some sample results are shown below:"
            folder="eyes"
            icon={<ExpressionIcon style={{ width: 48, height: 48 }} />}
            title="Default eyes settings applied"
          />
        ) : null
      }
      groupIndex={selectedGroupIndex + 1}
      groupPercentage={selectedEyeGroup?.percent}
      id={`eye-${selectedGroupIndex + 1}`}
      isSummingTo100={isSummingTo100}
      jsonValue={jsonValue}
      numberOfImages={numberOfImages}
      parentTitle="Facial Attributes"
      parentTitleIcon="FacialAttributesIcon"
      title="Eyes"
      titleCount={value[selectedGroupIndex]?.iris_color?.length}
      titleIcon="EyeIcon"
      unknownIndicesKeys={["Human"]}
      onClickAddButton={onAdd}
      onDoneEditJson={onDoneJson}
      onIndecesReceived={onIndecesReceived}
      onUpdatePercent={onUpdatePercent}
    >
      {/* Eye Iris Color */}
      <ContentCard>
        <MultiOptionImageGrid {...irisColorUIStyles} />
      </ContentCard>

      {/* Pupil Dilation */}
      <ContentCard style={{ padding: "8px" }}>
        <SelectorsPicker
          icon={"VerticalAngleIcon"}
          imgSrc={""}
          limits={defaultEye.pupil_dilation}
          lorValue={selectedEyeGroup?.pupil_dilation}
          noSpacing={true}
          title="Pupil Dilation"
          unit={"degree(s)"}
          onUpdate={onUpdatePupilDilation}
        />
      </ContentCard>

      {/* Redness */}
      <ContentCard style={{ padding: "8px" }}>
        <SelectorsPicker
          icon={"VerticalAngleIcon"}
          imgSrc={""}
          limits={defaultEye.redness}
          lorValue={selectedEyeGroup?.redness}
          noSpacing={true}
          title="Redness"
          unit={"degree(s)"}
          onUpdate={onUpdateRedness}
        />
      </ContentCard>
    </JsonManagerNewUI>
  );

  const rightAside = (
    <RightAsidePreviewFull previewTitle="Preview">
      <PreviewContent data={irisColorUIStyles.selectedImages} title="STYLE" />
    </RightAsidePreviewFull>
  );
  return (
    <Page
      fixedRightSide
      center={center}
      hideRightAside={isEmptyState}
      rightAside={rightAside}
    />
  );
}
