import { useMemo } from "react";
import { SelectChangeEvent } from "@mui/material";
import headOrbitInCar from "assets/diagrams/rig-types-head-orbit-in-car.png";
import headOrbit from "assets/diagrams/rig-types-head-orbit.png";
import manual from "assets/diagrams/rig-types-manual.png";
import presetLocation from "assets/diagrams/rig-types-preset-location.png";
import { StageElementOverviewIcon } from "assets/icons";
import { colors } from "components/App/theme";
import { SimpleDropdown } from "components/SimpleDropdown";
import { rigOptions } from "domain/Human";
import {
  Container,
  RowContainer,
  DropdownContainer,
  MainInfoContainer,
  OptionLabel,
  Title,
  ButtonWrapper,
  Image,
} from "./RigTypeSelector.styled";

const rigTypeImages: Record<string, string> = {
  head_orbit: headOrbit,
  head_orbit_with_tracking: headOrbit,
  head_orbit_in_car: headOrbitInCar,
  preset_location: presetLocation,
  manual,
};

export { rigTypeImages };

export function RigTypeSelector(props: {
  value: string;
  onUpdateType: (v: string) => void;
  onOpenModalCallback: () => void;
  onEnvironmentClick: () => void;
}) {
  const { value, onUpdateType, onOpenModalCallback, onEnvironmentClick } =
    props;

  const rigTypeOptions = rigOptions.map((option) => option.label);

  const onUpdateSelector = (event: SelectChangeEvent<unknown>) => {
    const rigTypeValue = rigOptions.find(
      (option) => option.label === (event.target.value as string)
    )?.value;
    onUpdateType(rigTypeValue ?? rigOptions[0].value);
  };

  const dropdownShowValue =
    rigOptions.find((option) => option.value === value)?.label ??
    rigOptions[0].label;

  const captionText = useMemo(() => {
    switch (value) {
      case "head_orbit_with_tracking":
        return "This type keeps the rig in orbit around the head of the main human except the head joint’s rotation is taken into account and acts as an additional offset.";
      case "head_orbit_in_car":
        return "This type keeps the rig in orbit around the head of the main human except the head joint is hard coded in and additional steps are taken to move it down to car level. This limits the orbit of the rig around the head of the human.";
      case "manual":
        return "This permits the user to define within the 3d space where the rig is located.";
      case "preset_location":
        return (
          <>
            This type places the rig within one of the predefined locations
            within a vehicle. Note: please select the vehicle environment as
            well in the
            <button
              style={{
                color: "inherit",
                background: "none",
                border: "none",
                padding: 0,
                font: "inherit",
                cursor: "pointer",
                outline: "inherit",
                borderBottom: `1px solid ${colors.grey50}`,
                marginLeft: "4px",
                marginRight: "4px",
              }}
              onClick={onEnvironmentClick}
            >
              Environments
            </button>
            tab.
          </>
        );
      case "head_orbit":
      default:
        return "This type keeps the rig in orbit around the head of the main human.";
    }
  }, [onEnvironmentClick, value]);

  return (
    <Container>
      <RowContainer>
        <MainInfoContainer>
          <Title>Rig Type</Title>
          <DropdownContainer>
            <SimpleDropdown
              options={rigTypeOptions}
              value={dropdownShowValue}
              onChange={onUpdateSelector}
            />
          </DropdownContainer>
        </MainInfoContainer>
        <ButtonWrapper onClick={onOpenModalCallback}>
          <StageElementOverviewIcon style={{ color: colors.grey50 }} />
        </ButtonWrapper>
      </RowContainer>
      <OptionLabel>{captionText}</OptionLabel>
      <Image src={rigTypeImages[value]} />
    </Container>
  );
}
