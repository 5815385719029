import React from "react";
import { Box, ButtonProps } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

export interface ActionButtonProps extends ButtonProps {
  loading?: boolean;
  onClick?: () => void;
  children?: any;
}

function ActionButton({
  loading,
  onClick,
  children,
  ...props
}: ActionButtonProps) {
  return (
    <Box
      component="div"
      sx={{
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <Box
        component="div"
        sx={{
          position: "relative",
          width: "100%",
        }}
      >
        <Button
          disabled={loading}
          variant="contained"
          onClick={onClick}
          {...props}
          sx={{
            ...props.sx,
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
            },
          }}
        >
          {children}
        </Button>
        {loading && (
          <CircularProgress
            color="warning"
            size={16}
            sx={{
              position: "absolute",
              top: "32%",
              left: "60%",
            }}
          />
        )}
      </Box>
    </Box>
  );
}

export { ActionButton };
