export function FactCheckIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 19.5H20.5V19V5V4.5H20H4H3.5V5V19V19.5H4H20ZM12.7059 12.1612L13.4087 11.4584L14.4652 12.5223L14.8187 12.8784L15.1736 12.5236L17.99 9.70711L18.7025 10.4196L14.8216 14.292L12.7059 12.1612ZM4 3.5H20C20.8239 3.5 21.5 4.17614 21.5 5V19C21.5 19.8239 20.8239 20.5 20 20.5H4C3.17614 20.5 2.5 19.8239 2.5 19V5C2.5 4.17614 3.17614 3.5 4 3.5ZM5.5 7.5H9.5V8.5H5.5V7.5ZM5.5 11.5H9.5V12.5H5.5V11.5ZM5.5 15.5H9.5V16.5H5.5V15.5Z"
        fill={fill}
        stroke="#9A9A9B"
      />
    </svg>
  );
}
