import { useCallback, useState } from "react";
import { Tooltip } from "@mui/material";
import { AddIcon, DeleteIcon } from "assets/icons";
import { colors } from "components/App/theme";
import { DeleteConfirmationDialog } from "components/dialogs/DeleteConfirmationDialog";
import { Messages } from "constants/messages";
import { DistKey } from "domain/Human";
import { ReverseTreeItem } from "features/JobBuilder/LeftSidebar/left-sidebar.styled";
import { SectionLabel } from "features/JobBuilder/LeftSidebar/SectionLabel";
import { AdditionalInfo } from "features/JobBuilder/LeftSidebar/SectionLabel/section-label.styled";
import { expandedKeyword } from "features/JobBuilder/LeftSidebar/useExpandedNodes";
import { WithPercent, summingTo100 } from "features/JobBuilder/utils";
import { IconsType } from "types/main";

export function DistributionGroup({
  id,
  selectedIndex,
  onAddDistributionGroup,
  distributionGroups,
  icon,
  label,
  isSelected,
  selectPart,
  selectIndex,
  onDelete,
}: {
  id: DistKey;
  selectedIndex: number;
  onAddDistributionGroup: () => void;
  distributionGroups: WithPercent[];
  icon: IconsType;
  label: string;
  isSelected: boolean;
  selectPart: () => void;
  selectIndex: (i: number) => void;
  onDelete: (i: number) => void;
}) {
  const percentageError = !summingTo100(distributionGroups);
  const [distributionGroupForDeletion, setDistributionGroupForDeletion] =
    useState(-1);
  const showDistributionGroupDeleteConfirmationDialog =
    distributionGroupForDeletion !== -1;
  const distributionGroupHumanText = `Distribution Group ${
    distributionGroupForDeletion + 1
  }`;
  const deleteDistributionGroupTextContent = `Are you sure you want to delete ${distributionGroupHumanText}? If you delete this you will lose the data.`;

  const getIconColor = (selected: boolean) =>
    selected ? colors.orange : colors.grey70;

  const onClickAddDistributionGroup = useCallback(
    (e: React.MouseEvent<any>) => {
      e.preventDefault();
      e.stopPropagation();
      onAddDistributionGroup();
      selectPart();
    },
    [onAddDistributionGroup, selectPart]
  );

  const onClickDeleteDistributionGroup = useCallback(
    (e: React.MouseEvent<any>, distributionGroupIndex: number) => {
      e.preventDefault();
      e.stopPropagation();
      selectPart();
      onDelete(distributionGroupIndex);
    },
    [onDelete, selectPart]
  );

  const AddDistributionGroupIcon = (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: colors.grey70,
            "& .MuiTooltip-arrow": {
              color: colors.grey70,
            },
          },
        },
      }}
      title={Messages.addGroupTooltip}
    >
      <div>
        <AddIcon
          style={{
            color: getIconColor(isSelected),
          }}
          onClick={onClickAddDistributionGroup}
        />
      </div>
    </Tooltip>
  );

  const DeleteDistributionGroupIcon = useCallback(
    (distributionGroupIndex: number) => {
      return (
        <DeleteIcon
          style={{
            color: colors.red,
            cursor: "pointer",
          }}
          onClick={() => {
            setDistributionGroupForDeletion(distributionGroupIndex);
          }}
        />
      );
    },
    []
  );

  const onCancelDistributionGroupDeletionCallback = () => {
    setDistributionGroupForDeletion(-1);
  };

  const onConfirmDistributionGroupDeletionCallback = (
    e: React.MouseEvent<any>
  ) => {
    onClickDeleteDistributionGroup(e, distributionGroupForDeletion);
    setDistributionGroupForDeletion(-1);
  };

  return (
    <ReverseTreeItem
      label={
        <SectionLabel
          additionalInfo={
            <AdditionalInfo selected={isSelected}>
              ({distributionGroups.length})
            </AdditionalInfo>
          }
          icon={icon}
          label={label}
          selected={isSelected}
        />
      }
      nodeId={id}
      onClick={selectPart}
    >
      <ReverseTreeItem
        collapseIcon={AddDistributionGroupIcon}
        expandIcon={AddDistributionGroupIcon}
        label={
          <SectionLabel
            icon={"DistributionGroupIcon"}
            label="Distribution Group"
            selected={isSelected}
            variant="subsection"
          />
        }
        nodeId={`${id}${expandedKeyword}`}
        onClick={selectPart}
      >
        {showDistributionGroupDeleteConfirmationDialog && (
          <DeleteConfirmationDialog
            textContent={deleteDistributionGroupTextContent}
            onCancel={onCancelDistributionGroupDeletionCallback}
            onConfirm={onConfirmDistributionGroupDeletionCallback}
          />
        )}
        {distributionGroups.map((distributionGroup, index) => (
          <ReverseTreeItem
            key={index}
            collapseIcon={DeleteDistributionGroupIcon(index)}
            expandIcon={DeleteDistributionGroupIcon(index)}
            label={
              <SectionLabel
                additionalInfo={
                  <AdditionalInfo
                    $percentageError={percentageError}
                    selected={isSelected && selectedIndex === index}
                  >
                    ({`${distributionGroup.percent} %`})
                  </AdditionalInfo>
                }
                icon={"GroupIcon"}
                label={`Group ${index + 1}`}
                selected={isSelected && selectedIndex === index}
                variant="subsection"
              />
            }
            nodeId={`${id}DistributionGroup-${index}`}
            onClick={() => selectIndex(index)}
          >
            {/* this empty thing is here to make the tree work */}
            <></>
          </ReverseTreeItem>
        ))}
      </ReverseTreeItem>
    </ReverseTreeItem>
  );
}
