import { useMemo } from "react";
import { MasksIcon } from "assets/icons";
import { ProTip } from "components/ProTip";
import { RightAsidePreviewFull } from "components/RightAsidePreviewFull";
import { PreviewContent } from "components/RightAsidePreviewFull/PreviewContent";
import { maskImageURL } from "constants/constants";
import { Messages } from "constants/messages";
import { EmptyState } from "features/JobBuilder/empty-state";
import { convertMasksStateToJson } from "features/JobBuilder/form-to-input-json";
import { getHumanObject, getMasks } from "features/JobBuilder/json-extracter";
import { JsonManagerNewUI } from "features/JobBuilder/json-manager-new-ui";
import { convertMasks } from "features/JobBuilder/json-to-form";
import { JsonHuman } from "features/JobBuilder/json-types";
import { Page, ContentCard } from "features/JobBuilder/layout";
import { MultiOptionImageGrid } from "features/JobBuilder/MultiOptionImageGrid";
import {
  maskStylesSelector,
  maskPositionsSelector,
  maskVariantsSelector,
} from "features/JobBuilder/store";
import { useDistPage } from "features/JobBuilder/useDistPage";
import {
  useImageSelection,
  useSelectionOutcomeCount,
} from "features/JobBuilder/useImageSelection";
import { useBoundStore } from "store/_boundStore";

const id = "masks";
export function JobBuilderMasks() {
  const {
    value,
    selectedGroupIndex,
    onUpdate,
    onAdd,
    numberOfImages,
    toggleDistListItem,
    isSummingTo100,
    onUpdatePart,
    onUpdatePercent,
  } = useDistPage(id);
  const selectedMaskGroup = value[selectedGroupIndex];
  const [
    setErrorMessage,
    setSuccessMessage,
    styleOptions,
    positionOptions,
    variantOptions,
  ] = useBoundStore((s) => [
    s.message.setErrorMessage,
    s.message.setSuccessMessage,
    maskStylesSelector(s),
    maskPositionsSelector(s),
    maskVariantsSelector(s),
  ]);

  const styleUIState = useImageSelection(
    selectedMaskGroup ? selectedMaskGroup.style : [],
    styleOptions,
    maskImageURL("style"),
    onUpdatePart("style"),
    "Style",
    "mask-style",
    "StyleIcon",
    toggleDistListItem("style", styleOptions)
  );
  const positionUIState = useImageSelection(
    selectedMaskGroup ? selectedMaskGroup.position : [],
    positionOptions,
    maskImageURL("position"),
    onUpdatePart("position"),
    "Position",
    "mask-position",
    "PositionIcon",
    toggleDistListItem("position", positionOptions)
  );
  const variantUIState = useImageSelection(
    selectedMaskGroup ? selectedMaskGroup.variant : [],
    variantOptions,
    maskImageURL("variant"),
    onUpdatePart("variant"),
    "Variant",
    "mask-variant",
    "VariantIcon",
    toggleDistListItem("variant", variantOptions)
  );

  const outcomes = useSelectionOutcomeCount([
    styleUIState,
    positionUIState,
    variantUIState,
  ]);

  const onIndecesReceived = (indices: number[], humansArr: JsonHuman[]) => {
    try {
      const humanObject = getHumanObject(humansArr, indices[0]);
      const jsonMasks = getMasks(humanObject);
      onUpdate(convertMasks(jsonMasks, value));
      setSuccessMessage("updated mask");
    } catch (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
      }
    }
  };

  const onDoneJson = (json: any) => {
    const obj = convertMasks(json, value);
    onUpdate(obj);
  };

  const jsonValue = useMemo(() => convertMasksStateToJson(value), [value]);

  const isEmptyState = !selectedMaskGroup;
  const center = (
    <JsonManagerNewUI
      key={`maskGroup-${selectedGroupIndex}`}
      emptyState={
        isEmptyState ? (
          <EmptyState
            description="Masks controls the masks variations of the character models. Default masks variations are applied, some sample results are shown below:"
            folder="masks"
            icon={<MasksIcon style={{ width: 48, height: 48 }} />}
            title="Default masks settings applied"
          />
        ) : null
      }
      groupIndex={selectedGroupIndex + 1}
      groupPercentage={selectedMaskGroup?.percent}
      id={`maskGroup-${selectedGroupIndex}`}
      isSummingTo100={isSummingTo100}
      jsonValue={jsonValue}
      numberOfImages={numberOfImages}
      parentTitle="Accessories"
      parentTitleIcon="AccessoriesIcon"
      title={`Masks`}
      titleCount={outcomes}
      titleIcon="MasksIcon"
      unknownIndicesKeys={["Human"]}
      onClickAddButton={onAdd}
      onDoneEditJson={onDoneJson}
      onIndecesReceived={onIndecesReceived}
      onUpdatePercent={onUpdatePercent}
    >
      <ContentCard>
        <ProTip label={Messages.maskProTip} />
        <MultiOptionImageGrid {...styleUIState} />
      </ContentCard>
      <ContentCard>
        <MultiOptionImageGrid {...positionUIState} />
      </ContentCard>
      <ContentCard>
        <MultiOptionImageGrid {...variantUIState} />
      </ContentCard>
    </JsonManagerNewUI>
  );
  const rightAside = (
    <RightAsidePreviewFull previewTitle="Preview">
      <PreviewContent data={styleUIState.selectedImages} title="STYLE" />
      <PreviewContent data={positionUIState.selectedImages} title="POSITION" />
      <PreviewContent data={variantUIState.selectedImages} title="VARIANT" />
    </RightAsidePreviewFull>
  );
  return (
    <Page
      fixedRightSide
      center={center}
      hideRightAside={isEmptyState}
      rightAside={rightAside}
    />
  );
}
