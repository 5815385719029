import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Input, Text } from "./TabInlineEdit.styled";

export function TabInlineEdit({
  oldLabel,
  flexGrow = 1,
  onSave,
  isSelected = false,
}: {
  oldLabel: string;
  flexGrow?: number;
  onSave: (val: string) => void;
  isSelected?: boolean;
}) {
  const [internalValue, setInternalValue] = useState("");
  const [isEditingTabName, setIsEditingTabName] = useState(false);

  useEffect(() => {
    setInternalValue(oldLabel);
  }, [oldLabel]);

  const handleEnterKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      if (internalValue === "") {
        setInternalValue(oldLabel);
        setIsEditingTabName(false);
        return;
      }
      setIsEditingTabName(false);

      onSave(internalValue);
    }
  };

  return (
    <Box component="div" display="flex">
      {isEditingTabName ? (
        <Box component="div" flexGrow={flexGrow}>
          <Input
            autoFocus
            fullWidth
            value={internalValue}
            onBlur={() => {
              onSave(internalValue);
              setIsEditingTabName(false);
            }}
            onChange={(e) => setInternalValue(e.target.value)}
            onKeyDown={handleEnterKeyPressed}
          />
        </Box>
      ) : (
        <Box
          component="div"
          onClick={() => isSelected && setIsEditingTabName(true)}
        >
          <Text $selected={isSelected}>{internalValue}</Text>
        </Box>
      )}
    </Box>
  );
}
