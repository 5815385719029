import { LabelWithValue } from "domain/Human";
import { StepItem } from "features/JobBuilder2/step-item/step-item";
import { activityZonesInit } from "features/JobBuilder2/steps/threeDimensional/utils/activity-zones-init";
import { segmentTrack } from "features/JobBuilder2/track-service";
import {
  defaultSubjob,
  getPipe2Step,
  UpdatePickedSubjobFn,
} from "features/JobBuilder2/types";
import { JobBuilder2Location } from "./core";

export const getSetLocationStep = (
  updatePickedSubjob: UpdatePickedSubjobFn,
  subjob: typeof defaultSubjob,
  userID: string | undefined,
  applicableLocations: LabelWithValue[]
) => {
  const onUpdateLocation = (v: string) =>
    updatePickedSubjob((d) => {
      d.location = v;
      d.rigLocationIndex = -1;
      d.activityZones = activityZonesInit(v);
      segmentTrack("set location", userID, { locationName: v });
    });

  const shouldShowWarningOnUpdate = (subJob: typeof defaultSubjob) =>
    !!(subJob.location && subJob.rigLocationIndex !== -1);

  return getPipe2Step(
    "Set location",
    <StepItem>
      <JobBuilder2Location
        applicableLocations={applicableLocations}
        shouldShowWarningOnUpdate={() => shouldShowWarningOnUpdate(subjob)}
        value={subjob.location}
        onUpdate={onUpdateLocation}
      />
    </StepItem>,
    true,
    {
      proTip:
        "Here is where you select the location where the animations will take place.",
    }
  );
};
