import { Vec2 } from "@flasd/modeling/src/maths/vec2";
import { Vector2 } from "three";
import { fromVec2ToVector2 } from "features/JobBuilder2/steps/threeDimensional/utils/polygon-converters";

const a = new Vector2();
const b = new Vector2();
const c = new Vector2();

// Gets the minimum distance between a point and a polygon
export const pointPolygonDistance = function (polygon: Vec2[], pointV2: Vec2) {
  const point = fromVec2ToVector2(pointV2);
  let minDist = 999999;
  for (let i = 0; i < polygon.length; ++i) {
    const pointStart = fromVec2ToVector2(polygon[i]);
    const edgeEnd = fromVec2ToVector2(polygon[(i + 1) % polygon.length]);

    const pointMinusP1 = a.copy(point).sub(pointStart);
    const p2MinusP1 = b.copy(edgeEnd).sub(pointStart);
    const r = p2MinusP1.dot(pointMinusP1) / p2MinusP1.length() ** 2;
    let dist: number;
    if (r < 0) {
      dist = pointMinusP1.length();
    } else if (r > 1) {
      const p2MinusPoint = c.copy(edgeEnd).sub(point);
      dist = p2MinusPoint.length();
    } else {
      dist =
        (pointMinusP1.length() ** 2 - (r * p2MinusP1.length()) ** 2) ** 0.5;
    }

    minDist = Math.min(dist, minDist);
  }

  return minDist;
};
