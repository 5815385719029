import { PL2_SEGMENT_CATEGORY_NAME } from "constants/constants";

export const segmentTrack = (
  description: string,
  userID: string | undefined,
  otherProps?: any
) => {
  const name = `${PL2_SEGMENT_CATEGORY_NAME} - ${description}`;
  const toSend = [name, { ...otherProps, userID }] as const;
  console.log(...toSend);
  window.analytics.track(...toSend);
};

export const segmentPage = (
  description: string,
  userID: string | undefined
) => {
  const toSend = [`${PL2_SEGMENT_CATEGORY_NAME}`, description, { userID }];
  console.log(...toSend);
  window.analytics.page(...toSend);
};
