import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  overflow-y: auto;
`;

export const Spacer = styled(Box)`
  background: transparent;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
`;

export const Description = styled(Typography)`
  font-size: 16px;
  line-height: 21px;
  text-align: start;
  margin-top: 3px;
  margin-bottom: 24px;
`;

export const ContentContainer = styled(Box)`
  margin-bottom: 0px;
  display: grid;
  place-items: center;
  grid-gap: 20px;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fill, 198px);
  grid-template-rows: repeat(auto-fill, max-content);
  width: 100%;
`;
