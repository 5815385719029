export function CircledCloseIcon({
  style = { height: "35px" },
  fill = "currentColor",
  onClick,
}: {
  style?: React.CSSProperties;
  fill?: string;
  onClick?: () => void;
} = {}) {
  return (
    <div data-testid="icon-close" onClick={onClick}>
      <svg
        fill="none"
        height="35"
        style={style}
        viewBox="0 0 35 35"
        width="35"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M17.4993 2.91669C25.5535 2.91669 32.0827 9.44587 32.0827 17.5C32.0827 25.5542 25.5535 32.0834 17.4993 32.0834C9.4452 32.0834 2.91602 25.5542 2.91602 17.5C2.91602 9.44587 9.4452 2.91669 17.4993 2.91669ZM14.1557 12.0939C13.5862 11.5244 12.6628 11.5244 12.0933 12.0939C11.5676 12.6196 11.5271 13.4468 11.972 14.0189L12.0933 14.1563L15.437 17.5L12.0932 20.8438C11.5236 21.4133 11.5236 22.3367 12.0932 22.9062C12.6189 23.4319 13.4461 23.4724 14.0182 23.0275L14.1555 22.9062L17.4994 19.5624L20.8433 22.9063C21.4128 23.4758 22.3362 23.4758 22.9057 22.9063C23.4314 22.3806 23.4718 21.5534 23.027 20.9813L22.9057 20.8439L19.5618 17.5L22.9055 14.1562C23.4751 13.5867 23.4751 12.6633 22.9055 12.0938C22.3798 11.5681 21.5526 11.5277 20.9805 11.9725L20.8432 12.0938L17.4994 15.4376L14.1557 12.0939Z"
          fill={fill}
          fillRule="evenodd"
        />
      </svg>
    </div>
  );
}
