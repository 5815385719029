import React, { useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { ActionButton } from "components/Common/ActionButton";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { Org } from "domain/Org";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { orgNameValidation } from "utils/validators";

function CreateOrg({
  title,
  subTitle,
  done,
}: {
  title: string;
  subTitle: string;
  done: (orgName: string) => void;
}) {
  const { register, handleSubmit, errors, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const { setErrorMessage } = useBoundStore.getState().message;
  const { replaceOrgs, replacePermissions } = useBoundStore.getState().org;

  const onSubmit = async () => {
    const lowerCasedName = (getValues("name") || "").toLowerCase();
    const website = getValues("website") || "";
    try {
      setLoading(true);
      await restClient.createOrg({ name: lowerCasedName, website } as Org);
      await replaceOrgs();
      await replacePermissions(lowerCasedName);
      done(lowerCasedName);
    } catch (error) {
      setLoading(false);
      setErrorMessage(restClient.APIErrorMessage(error));
    }
  };

  return (
    <Box component="div" data-testid="create-org-component">
      {loading && <FullScreenProgress />}
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          <Box component="div" mb={2}>
            <Typography variant="h1">{title}</Typography>
          </Box>
          <Typography variant="h4">{subTitle}</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box component="div" mb={3} mt={4}>
              <TextField
                autoFocus
                fullWidth
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ""}
                id="name"
                inputRef={register(orgNameValidation)}
                label="Name *"
                name="name"
                size="small"
                type="text"
                variant="outlined"
              />
            </Box>
            <Box component="div" mb={4}>
              <TextField
                fullWidth
                id="website-url"
                inputRef={register}
                label="Website URL"
                name="website"
                size="small"
                type="text"
                variant="outlined"
              />
            </Box>
            <ActionButton fullWidth color="warning" type="submit">
              Next
            </ActionButton>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
}

export { CreateOrg };
