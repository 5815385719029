import { parse } from "qs";
import { URLParams } from "constants/params";

export const params = (
  search: string,
  withoutDecoding: boolean,
  ...keys: URLParams[]
): string[] => {
  const options: qs.IParseOptions = {
    ignoreQueryPrefix: true,
  };
  if (withoutDecoding) {
    options.decoder = (originalStr: string) => originalStr;
  }
  const queryParams = parse(search, options);
  return keys.map((key) => {
    const param = queryParams[key];
    return typeof param === "string" ? param : "";
  });
};
