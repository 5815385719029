import { exceedLimit } from "components/SelectorsPicker/utils";
import {
  Container,
  Title,
  Input,
  Description,
  InputContainer,
} from "./PercentageGroup.styled";

export function PercentageGroup({
  title,
  percentValue,
  descriptionText,
  isSummingTo100,
  onUpdatePercentage,
}: {
  title: string;
  percentValue: string;
  isSummingTo100: boolean;
  descriptionText?: string;
  onUpdatePercentage: (newValue: string) => void;
}) {
  const onChangePercentage = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const stringValue = e.target.value;

    onUpdatePercentage(stringValue.replace(/[^0-9.]/g, ""));
  };

  const percentNumber = Number(percentValue);

  const percentageError = (resolution: string) =>
    exceedLimit(resolution, 0, 100) || Number.isNaN(Number(resolution));

  const percentageErrorMessage =
    "The input value should be between 0% and 100%";

  const hasInvalidInputError =
    isNaN(percentNumber) || percentNumber < 0 || !isSummingTo100;

  return (
    <Container>
      <Title>{title}</Title>
      <InputContainer>
        <Input
          error={hasInvalidInputError}
          helperText={
            percentageError(percentValue) ? percentageErrorMessage : ""
          }
          value={`${percentValue}%`}
          onChange={onChangePercentage}
        />
      </InputContainer>
      {descriptionText && <Description>{descriptionText}</Description>}
    </Container>
  );
}
