import { IconContainer } from "./icons.styled";

export function CircledAddIcon({
  fill = "currentColor",
  style = { height: "24px" },
  onClick,
}: {
  style?: React.CSSProperties;
  fill?: string;
  onClick?: () => void;
}) {
  return (
    <IconContainer onClick={() => (onClick ? onClick() : null)}>
      <svg
        fill="none"
        height="24"
        style={style}
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 7.5C11.45 7.5 11 7.95 11 8.5V11.5H8C7.45 11.5 7 11.95 7 12.5C7 13.05 7.45 13.5 8 13.5H11V16.5C11 17.05 11.45 17.5 12 17.5C12.55 17.5 13 17.05 13 16.5V13.5H16C16.55 13.5 17 13.05 17 12.5C17 11.95 16.55 11.5 16 11.5H13V8.5C13 7.95 12.55 7.5 12 7.5ZM12 2.5C6.48 2.5 2 6.98 2 12.5C2 18.02 6.48 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 12 2.5ZM12 20.5C7.59 20.5 4 16.91 4 12.5C4 8.09 7.59 4.5 12 4.5C16.41 4.5 20 8.09 20 12.5C20 16.91 16.41 20.5 12 20.5Z"
          fill={fill}
        />
      </svg>
    </IconContainer>
  );
}
