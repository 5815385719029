import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Link,
} from "@mui/material";
import { colors } from "components/App/theme";
import { CliCommand } from "components/Common/CliCommand";
import { disableBackdropClickClose } from "services/ui-service";

export function NewApiKeyDialog({
  orgName,
  apiKey,
  apiKeyName,
  userInit,
  onClose,
}: {
  orgName: string | undefined;
  apiKey: string;
  apiKeyName: string;
  userInit: boolean;
  onClose: () => void;
}) {
  const command =
    userInit && orgName
      ? `synthesis user init ${apiKey} ${orgName}`
      : `synthesis apikey set ${apiKey}`;

  return (
    <Dialog
      disableEscapeKeyDown
      open
      data-testid="new-api-key-dialog"
      onClose={(e, reason) => disableBackdropClickClose(reason, onClose)}
    >
      <DialogTitle>
        <Box alignItems="flex-start" component="div" display="flex">
          <Box component="div" flexGrow={1}>
            API Key
          </Box>
          <Box component="div" display="flex" flexGrow={0}>
            <CloseIcon onClick={onClose} />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="div" mb={3}>
          <Typography variant="body2">
            Make sure to copy your API key now. You won’t be able to see it
            again!
          </Typography>
        </Box>

        <Box
          color={colors.oxfordBlue}
          component="div"
          fontFamily="monospace"
          fontSize="1.2rem"
          textAlign="center"
        >
          {apiKey}
        </Box>

        <Box component="div" mb={1} mt={3}>
          <Typography variant="body2">
            To use your API key with our CLI, run:
          </Typography>
        </Box>

        <Box component="div">
          <CliCommand command={command} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          component="div"
          display="flex"
          justifyContent="space-between"
          mb={1}
          ml={2}
          mr={2}
          mt={2}
          textAlign="right"
          width="100%"
        >
          <Link
            download={`${apiKeyName}.txt`}
            href={"data:text/plain;charset=utf-8," + encodeURIComponent(apiKey)}
            underline="hover"
          >
            {" "}
            Download{" "}
          </Link>

          <Button
            autoFocus
            color="warning"
            size="small"
            variant="contained"
            onClick={onClose}
          >
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
