import RotateRightMUI from "@mui/icons-material/RotateRight";

export function RotateRightIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return <RotateRightMUI htmlColor={fill} style={style} />;
}
