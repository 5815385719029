import GetAppIcon from "@mui/icons-material/GetApp";
import { Box } from "@mui/material";
import { TutorialCardButton } from "components/tutorials/TutorialCardButton";
import { SAMPLE_JOB_URL } from "constants/constants";
import { download } from "services/download";

export function TutorialDownloadSampleJob() {
  return (
    <>
      <Box component="div">
        <TutorialCardButton
          color="secondary"
          endIcon={<GetAppIcon />}
          onClick={() => download(SAMPLE_JOB_URL)}
        >
          Sample Job
        </TutorialCardButton>
      </Box>
    </>
  );
}
