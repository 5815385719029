export const lightTypeDiagrams: {
  [key: string]: string;
} = {
  rect: "https://docs.synthesis.ai/images/diagrams/png/diagram_6_rect.png",
  spot: "https://docs.synthesis.ai/images/diagrams/png/Diagram_6_LightTypes_Spot_2.png",
  omni: "https://docs.synthesis.ai/images/diagrams/png/Diagram_6_LightTypes_Omni_2.png",
  direct:
    "https://docs.synthesis.ai/images/diagrams/png/Diagram_6_LightTypes_Direct_2.png",
  disc: "https://docs.synthesis.ai/images/diagrams/png/Diagram_6_LightTypes_Disc_2.png",
};
