import Person from "@mui/icons-material/Person";

export function PersonIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return <Person htmlColor={fill} style={style} />;
}
