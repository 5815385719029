import { SelectChangeEvent } from "@mui/material";
import { SimpleDropdown } from "components/SimpleDropdown";
import {
  Container,
  DropdownContainer,
  Title,
} from "./SelectorWIthTitle.styled";

export function SelectorWIthTitle({
  label,
  value,
  options,
  onChange,
}: {
  label: string;
  value: string;
  options: string[];
  onChange: (v: string) => void;
}) {
  const onUpdateSelector = (event: SelectChangeEvent<unknown>) => {
    onChange(event.target.value as string);
  };

  return (
    <Container>
      <Title>{label}</Title>
      <DropdownContainer>
        <SimpleDropdown
          options={options}
          value={value}
          onChange={onUpdateSelector}
        />
      </DropdownContainer>
    </Container>
  );
}
