import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { Link as RouterLink } from "react-router-dom";
import { ActionButton } from "components/Common/ActionButton";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { Messages } from "constants/messages";
import { Routes } from "constants/routes";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { ChangeEmailDialog, ChangeEmailDialogMode } from "./ChangeEmailDialog";

function AccountInfo() {
  const { setErrorMessage, setSuccessMessage } =
    useBoundStore.getState().message;
  const profile = useBoundStore((state) => state.profile.data);
  const { getUserProfile } = useBoundStore.getState().profile;

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [changeEmailDialogMode, setChangeEmailDialogMode] = useState(
    ChangeEmailDialogMode.Closed
  );
  const [changeEmailDialogLoading, setChangeEmailDialogLoading] =
    useState(false);

  useEffect(() => {
    if (!profile) {
      return;
    }
    setEmail(profile.email);
    setFirstName(profile.first_name);
    setLastName(profile.last_name);
  }, [profile]);

  if (!profile) {
    return <FullScreenProgress />;
  }

  const updateUserProfile = async () => {
    setLoading(true);
    try {
      await restClient.updateUserProfile(profile.id, {
        firstName,
        lastName,
      });
      getUserProfile();
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  const onSubmitPassword = async (password: string) => {
    try {
      setChangeEmailDialogLoading(true);
      await restClient.checkIfIsAuthorized(profile.email, password);
      setSuccessMessage(Messages.correctPassword);
      setChangeEmailDialogMode(ChangeEmailDialogMode.EmailForm);
    } catch (error) {
      setChangeEmailDialogMode(ChangeEmailDialogMode.PasswordForm);
      setErrorMessage(Messages.wrongPassword);
    }
    setChangeEmailDialogLoading(false);
  };

  const onSubmitEmail = async (email: string) => {
    try {
      setChangeEmailDialogLoading(true);
      const result = await restClient.updateEmailInit(
        profile.id,
        email.toLowerCase()
      );
      setSuccessMessage(result.data.message);
      setChangeEmailDialogMode(ChangeEmailDialogMode.Closed);
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setChangeEmailDialogLoading(false);
  };

  return (
    <>
      {loading && <FullScreenProgress />}

      {changeEmailDialogMode !== ChangeEmailDialogMode.Closed && (
        <ChangeEmailDialog
          dialogMode={changeEmailDialogMode}
          loading={changeEmailDialogLoading}
          onCancel={() =>
            setChangeEmailDialogMode(ChangeEmailDialogMode.Closed)
          }
          onSubmitEmail={onSubmitEmail}
          onSubmitPassword={onSubmitPassword}
        />
      )}

      <Grid container justifyContent="center">
        <Grid item md={5} sm={8} xs={10}>
          <Box component="div" mb={4}>
            <Typography variant="h1">My Account</Typography>
          </Box>
          <Typography variant="h2">Profile</Typography>
          <Box component="div" mt={2}>
            <form autoComplete="off">
              <Box component="div" mb={1} paddingBottom={2}>
                <InputLabel shrink htmlFor="email">
                  Email address:
                </InputLabel>
                <FormControl variant="standard">
                  <Box component="div" id="email" lineHeight="33px">
                    {email}
                  </Box>
                </FormControl>

                {email && (
                  <Box
                    component="div"
                    display="inline-block"
                    ml={4}
                    onClick={() =>
                      setChangeEmailDialogMode(
                        ChangeEmailDialogMode.PasswordForm
                      )
                    }
                  >
                    <IconButton
                      data-testid="pencil-icon-change-email"
                      size="small"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </Box>

              <Box component="div" mb={2} paddingBottom={2}>
                <TextField
                  fullWidth
                  inputProps={{
                    "data-testid": "first-name-input",
                  }}
                  label="First name"
                  value={firstName}
                  variant="standard"
                  onChange={(e) => setFirstName(e.target.value)}
                ></TextField>
                <Divider />
              </Box>
              <Box component="div" mb={2} paddingBottom={2}>
                <TextField
                  fullWidth
                  inputProps={{
                    "data-testid": "last-name-input",
                  }}
                  label="Last name"
                  value={lastName}
                  variant="standard"
                  onChange={(e) => setLastName(e.target.value)}
                ></TextField>
              </Box>
              <Box component="div" mb={4} paddingBottom={2}>
                <ActionButton
                  color="warning"
                  size="small"
                  onClick={updateUserProfile}
                >
                  Update profile
                </ActionButton>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item md={5} sm={8} xs={10}>
          <Typography variant="h2">Security</Typography>

          <Box component="div" mt={1} paddingBottom={2}>
            <Link
              component={RouterLink}
              to={Routes.CHANGE_PASSWORD}
              underline="always"
            >
              Change password
            </Link>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export { AccountInfo };
