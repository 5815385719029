// Functions in this file are temporary till CG or any other team perform these conversions for us
import { area } from "@flasd/modeling/src/maths/utils";
import { Vec2 } from "@flasd/modeling/src/maths/vec2";
import { Vec3 } from "@flasd/modeling/src/maths/vec3";
import {
  fromVec2ToVec3,
  fromVec3ToVec2,
} from "features/JobBuilder2/steps/threeDimensional/utils/polygon-converters";
import { CGLocation } from "features/JobBuilder2/types";

const getDimensions = (shell: Vec2[]) => {
  const left = shell[0][0];
  const top = shell[0][1];
  const right = shell[2][0];
  const bottom = shell[2][1];
  return {
    width: right - left,
    height: top - bottom,
  };
};

const getOrigin = (shell: Vec2[]) => {
  const left = shell[0][0];
  const top = shell[0][1];
  const { width, height } = getDimensions(shell);
  return [left + width / 2, top - height / 2] as Vec2;
};
const switchYAndZVec3 = (v: Vec3) => [v[0], v[2], v[1]] as Vec3;
const negateY = (v: Vec2) => [v[0], -v[1]] as Vec2;
const negateVec3 = (v: Vec3) => [v[0], -v[1], v[2]] as Vec3;
const translateVec2 = (v: Vec2, origin: Vec2) =>
  [v[0] - origin[0], v[1] - origin[1]] as Vec2;
const reverseTranslateVec2 = (v: Vec2, origin: Vec2) =>
  [v[0] + origin[0], v[1] + origin[1]] as Vec2;

const transformVec3Maker = (origin: Vec2) => (v: Vec3) =>
  translateVec2(negateY(fromVec3ToVec2(switchYAndZVec3(v))), origin);
export const reverseTransformVec3Maker = (origin: Vec2) => (v: Vec2) =>
  switchYAndZVec3(fromVec2ToVec3(negateY(reverseTranslateVec2(v, origin))));
const transformVec2Maker = (origin: Vec2) => (v: Vec2) =>
  translateVec2(negateY(v), origin);

const reverseVec2sIfNeeded = (arr: Vec2[]) =>
  area(arr) < 0 ? arr.reverse() : arr;

const average = (vs: Vec2[]) =>
  vs
    .reduce(
      (prev, current) => [prev[0] + current[0], prev[1] + current[1]],
      [0, 0]
    )
    .map((v) => v / vs.length) as Vec2;

export const pretranformCGLocation = (l: CGLocation) => {
  const adjustedShell = l.shell.map(negateY);
  const origin = getOrigin(adjustedShell);
  const transformFnVec3 = transformVec3Maker(origin);
  const transformFnVec2 = transformVec2Maker(origin);
  return {
    name: l.name,
    dimensions: getDimensions(adjustedShell),
    // saving origin because we'll use it when we submit a job to reverse the transformation
    origin,
    action_regions: l.action_regions.map((action) => ({
      name: action.name,
      hints: action.hints,
      shell: reverseVec2sIfNeeded(
        action.shell.map((x) =>
          x.length === 3 ? transformFnVec3(x) : transformFnVec2(x)
        )
      ),
      avoid_regions: action.avoid_regions.map((avoid) => ({
        name: avoid.name,
        shell: reverseVec2sIfNeeded(avoid.shell.map(transformFnVec3)),
      })),
    })),
    camera_preset_locations: l.camera_preset_locations.map(
      (presetLocation) => ({
        name: presetLocation.name,
        facing_direction: fromVec3ToVec2(
          negateVec3(
            switchYAndZVec3(presetLocation.offset_uv_grid[0].facing_direction)
          )
        ),
        position: average(
          presetLocation.offset_uv_grid
            .map((g) => g.position)
            .map(transformFnVec3)
        ),
      })
    ),
  };
};
