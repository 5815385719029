import { FacialAttributesPart, isFacialKey } from "domain/Human";
import { selectSelectedHumanObjectPart } from "features/JobBuilder/store";
import { useBoundStore } from "store/_boundStore";
import { JobBuilderExpression } from "./Expression";
import { JobBuilderEyes } from "./Eye";
import { JobBuilderEyebrows } from "./Eyebrows";
import { JobBuilderFacialHair } from "./FacialHair";
import { JobBuilderGaze } from "./Gaze";
import { JobBuilderHair } from "./Hair";
import { JobBuilderHeadTurn } from "./HeadTurn";

export function JobBuilderFacialAttributes() {
  const part = useBoundStore(selectSelectedHumanObjectPart);
  if (!isFacialKey(part)) {
    return null;
  }
  const facialAttributeUI: {
    [k in FacialAttributesPart]: React.ReactNode;
  } = {
    expression: <JobBuilderExpression />,
    gaze: <JobBuilderGaze />,
    headTurn: <JobBuilderHeadTurn />,
    hair: <JobBuilderHair />,
    facialHair: <JobBuilderFacialHair />,
    eyebrow: <JobBuilderEyebrows />,
    eye: <JobBuilderEyes />,
  };
  return <div style={{ width: "100%" }}>{facialAttributeUI[part]}</div>;
}
