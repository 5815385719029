import { useMemo } from "react";
import MatchColor from "assets/diagrams/facial-hair-match-color.svg";
import RelativeDensity from "assets/diagrams/facial-hair-relative-density.svg";
import RelativeLength from "assets/diagrams/facial-hair-relative-length.svg";
import ColorSeed from "assets/diagrams/hair-color-seed.svg";
import { FacialHairIcon } from "assets/icons";
import { BooleanSelector } from "components/BooleanSelector";
import { ProTip } from "components/ProTip";
import { RightAsidePreviewFull } from "components/RightAsidePreviewFull";
import { PreviewContent } from "components/RightAsidePreviewFull/PreviewContent";
import { SelectorsPicker } from "components/SelectorsPicker";
import { PUBLIC_S3_BUCKET } from "constants/constants";
import { Messages } from "constants/messages";
import { defaultFacialHair } from "domain/Human";
import { EmptyState } from "features/JobBuilder/empty-state";
import { convertFacialHairStateToJson } from "features/JobBuilder/form-to-input-json";
import {
  getFacialHair,
  getHumanObject,
} from "features/JobBuilder/json-extracter";
import { JsonManagerNewUI } from "features/JobBuilder/json-manager-new-ui";
import { convertFacialHairs } from "features/JobBuilder/json-to-form";
import { DeepPartial, JsonHuman } from "features/JobBuilder/json-types";
import { ContentCard, Page } from "features/JobBuilder/layout";
import { MultiOptionImageGrid } from "features/JobBuilder/MultiOptionImageGrid";
import {
  facialHairColorSelector,
  facialHairStyleSelector,
} from "features/JobBuilder/store";
import { useDistPage } from "features/JobBuilder/useDistPage";
import {
  useImageSelection,
  useSelectionOutcomeCount,
} from "features/JobBuilder/useImageSelection";
import { changeCheckEventValue } from "features/JobBuilder/utils";
import { useBoundStore } from "store/_boundStore";

const id = "facialHair";
export function JobBuilderFacialHair() {
  const {
    isSummingTo100,
    onUpdate,
    onUpdatePart,
    value,
    selectedGroupIndex,
    onUpdatePercent,
    onAdd,
    numberOfImages,
    toggleDistListItem,
  } = useDistPage(id);
  const selectedFacialHairGroup = value[selectedGroupIndex];
  const [
    setErrorMessage,
    setSuccessMessage,
    facialHairStyles,
    facialHairColors,
  ] = useBoundStore((s) => [
    s.message.setErrorMessage,
    s.message.setSuccessMessage,
    facialHairStyleSelector(s),
    facialHairColorSelector(s),
  ]);
  const onUpdateRelativeLength = onUpdatePart("relative_length");
  const onUpdateRelativeDensity = onUpdatePart("relative_density");
  const onUpdateColorSeed = onUpdatePart("color_seed");
  const onUpdateMatchHairColor = onUpdatePart("match_hair_color");

  const styleUIState = useImageSelection(
    selectedFacialHairGroup ? selectedFacialHairGroup.style : [],
    facialHairStyles,
    (fhs: string) => `${PUBLIC_S3_BUCKET}/pl1/facial_hair.style/${fhs}.png`,
    onUpdatePart("style"),
    "Styles",
    "facial-hair-styles",
    "StyleIcon",
    toggleDistListItem("style", facialHairStyles)
  );
  const colorUIState = useImageSelection(
    selectedFacialHairGroup ? selectedFacialHairGroup.color : [],
    facialHairColors,
    (hc) => `${PUBLIC_S3_BUCKET}/pl1/hair.color/${hc}.png`,
    onUpdatePart("color"),
    "Color",
    "facialHair-color",
    "ColorIcon",
    toggleDistListItem("color", facialHairColors)
  );

  const outcomes = useSelectionOutcomeCount([styleUIState, colorUIState]);

  const onIndecesReceived = (
    indices: number[],
    humansArr: DeepPartial<JsonHuman>[]
  ) => {
    try {
      onUpdate(
        convertFacialHairs(
          getFacialHair(getHumanObject(humansArr, indices[0])),
          value,
          facialHairStyles,
          facialHairColors
        )
      );
      setSuccessMessage("updated facialHair");
    } catch (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
      }
    }
  };

  // TODO: type this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDoneJson = (json: any) => {
    const updated = convertFacialHairs(
      json,
      value,
      facialHairStyles,
      facialHairColors
    );
    onUpdate(updated);
  };
  const jsonValue = useMemo(() => convertFacialHairStateToJson(value), [value]);

  const isEmptyState =
    !selectedFacialHairGroup || !facialHairStyles || !facialHairColors;
  const center = (
    <JsonManagerNewUI
      emptyState={
        isEmptyState ? (
          <EmptyState
            description="Facial hair controls the facial hair variations of the character models. Default facial hair variations are applied, some sample results are shown below:"
            folder="facial_hair.style"
            icon={<FacialHairIcon style={{ width: 48, height: 48 }} />}
            title="Default facial hair settings applied"
          />
        ) : null
      }
      groupIndex={selectedGroupIndex + 1}
      groupPercentage={selectedFacialHairGroup?.percent}
      id={`facialHair-${selectedGroupIndex + 1}`}
      isSummingTo100={isSummingTo100}
      jsonValue={jsonValue}
      numberOfImages={numberOfImages}
      parentTitle="Facial Attributes"
      parentTitleIcon="FacialAttributesIcon"
      title={`FacialHair`}
      titleCount={outcomes}
      titleIcon="FacialHairIcon"
      unknownIndicesKeys={["Human"]}
      onClickAddButton={onAdd}
      onDoneEditJson={onDoneJson}
      onIndecesReceived={onIndecesReceived}
      onUpdatePercent={onUpdatePercent}
    >
      <ContentCard>
        <ProTip label={Messages.facialHairProTip} />
        <MultiOptionImageGrid {...styleUIState} />
      </ContentCard>
      <ContentCard>
        <MultiOptionImageGrid {...colorUIState} />
      </ContentCard>
      <ContentCard style={{ padding: "8px" }}>
        <SelectorsPicker
          noSpacing
          icon={"ColorSeedIcon"}
          imgSrc={ColorSeed}
          limits={defaultFacialHair.color_seed}
          lorValue={selectedFacialHairGroup?.color_seed}
          title="Color Seed"
          unit={""}
          onUpdate={onUpdateColorSeed}
        />
      </ContentCard>
      <ContentCard style={{ padding: "8px" }}>
        <SelectorsPicker
          noSpacing
          icon={"RelativeLengthIcon"}
          imgSrc={RelativeLength}
          limits={defaultFacialHair.relative_length}
          lorValue={selectedFacialHairGroup?.relative_length}
          title="Relative Length"
          unit={"degree(s)"}
          onUpdate={onUpdateRelativeLength}
        />
      </ContentCard>
      <ContentCard style={{ padding: "8px" }}>
        <SelectorsPicker
          noSpacing
          icon={"RelativeDensityIcon"}
          imgSrc={RelativeDensity}
          limits={defaultFacialHair.relative_density}
          lorValue={selectedFacialHairGroup?.relative_density}
          title="Relative Density"
          unit={"degree(s)"}
          onUpdate={onUpdateRelativeDensity}
        />
      </ContentCard>

      <ContentCard style={{ padding: "8px" }}>
        <BooleanSelector
          noSpacing
          description={Messages.matchHairColorDescription}
          icon={"MatchHairColorIcon"}
          imgSrc={MatchColor}
          label={"Enabled"}
          title={"Match Hair Color"}
          value={selectedFacialHairGroup?.match_hair_color}
          onUpdate={changeCheckEventValue(onUpdateMatchHairColor)}
        />
      </ContentCard>
    </JsonManagerNewUI>
  );

  const rightAside = (
    <RightAsidePreviewFull previewTitle="Preview">
      <PreviewContent data={styleUIState.selectedImages} title={"STYLE"} />
      <PreviewContent data={colorUIState.selectedImages} title={"COLOR"} />
    </RightAsidePreviewFull>
  );
  return (
    <Page
      fixedRightSide
      center={center}
      hideRightAside={isEmptyState}
      rightAside={rightAside}
    />
  );
}
