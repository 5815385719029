import { Box, Typography } from "@mui/material";
import AceEditor from "react-ace";
import styled, { css } from "styled-components";
import { colors } from "components/App/theme";
import { PAGE_PADDING } from "features/constants";

export const Editor = styled(AceEditor)`
  .ace_content {
    background-color: ${colors.white};
    color: ${colors.grey70};
  }

  .ace_gutter {
    background: ${colors.tint5};
    color: ${colors.grey50};
  }

  .ace_constant.ace_numeric,
  .ace_constant.ace_language {
    color: ${colors.orange};
  }

  .ace_variable {
    color: ${colors.grey70};
  }

  .ace_string {
    color: #00cf08;
  }

  .ace_indent-guide {
    background: none;
  }

  .ace_marker-layer .ace_selection {
    background: ${colors.tint5};
  }

  .ace_print-margin {
    width: 0px;
    background: #e8e8e8;
  }
`;

export const TitleContainer = styled(Box)`
  display: flex;
  flex: 2;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  height: 58px;
`;

export const PercentageContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 16px;
`;

export const NumberOfImages = styled(Typography)`
  font-size: 14px;
  line-height: 21px;
  line-clamp: 1;
  white-space: nowrap;
  color: ${colors.grey70};
`;

export const JsonEditorHighlighter = styled(Box)<{ $isActive?: boolean }>`
  padding: 0px;
  transition: all 0.2s ease-in-out;

  ${(props) =>
    props.$isActive &&
    css`
      background: white;
      position: relative;
      z-index: 99;
      border-radius: 20px;
      padding: ${PAGE_PADDING * 8}px;
    `}
`;
