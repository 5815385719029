import React from "react";
import { GlobalStyles } from "@mui/material";
import * as Sentry from "@sentry/react";
import { enableMapSet } from "immer";
import { createRoot } from "react-dom/client";
import { ErrorBoundaryFallbackMessage } from "components/ErrorBoundaryFallbackMessage";
import { App } from "./components/App";
import { CustomRouter } from "./components/App/custom-router";
import { globalStyles } from "./components/App/theme";
import { APP_ENV, SENTRY_DSN } from "./constants/constants";
import "./style/index.scss";
import { browserHistory } from "./services/broswer-history";

const isSentryEnabled = () => {
  return APP_ENV === "prod" || APP_ENV === "stage" || APP_ENV === "dev";
};

enableMapSet();
function ErrorBoundedApp() {
  Sentry.init({
    dsn: SENTRY_DSN,
    enabled: isSentryEnabled(),
    environment: APP_ENV,
  });

  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallbackMessage />}>
      <App />
    </Sentry.ErrorBoundary>
  );
}
const container = document.getElementById("root");
const toRender = (
  <CustomRouter history={browserHistory}>
    <GlobalStyles styles={globalStyles} />
    <ErrorBoundedApp />
  </CustomRouter>
);
createRoot(container!).render(toRender);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
