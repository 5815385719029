import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton } from "@mui/material";
import { colors } from "components/App/theme";
import { InlineEdit } from "components/Common/InlineEdit";
function EditableDatatableBox({
  id,
  oldLabel,
  isEditable,
  onEdit,
}: {
  id: string;
  oldLabel: string;
  isEditable: boolean;
  onEdit: (id: string, name: string) => void;
}) {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <>
      {isEditable ? (
        <Box
          bgcolor={colors.white}
          component="div"
          minWidth={isEditing ? "400px" : "100%"}
          pr={isEditing ? 2 : 0}
          width="100%"
          zIndex={999}
        >
          <InlineEdit
            editIcon={
              <Box
                className="show-on-hover"
                component="div"
                ml={1}
                visibility="hidden"
              >
                <IconButton data-testid="pencil-icon" size="small">
                  <EditIcon fontSize="small" />
                </IconButton>
              </Box>
            }
            flexGrow={1}
            isEditing={isEditing}
            saveLabel="Save"
            value={oldLabel}
            onSave={(newLabel) => onEdit(id, newLabel)}
            onToggleEditingMode={(e) => setIsEditing(e)}
          />
        </Box>
      ) : (
        <Box
          component="div"
          flexGrow="1"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {oldLabel}
        </Box>
      )}
    </>
  );
}

export { EditableDatatableBox };
