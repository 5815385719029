import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import {
  InputContainer,
  InputLabel,
  SelectorInput,
} from "./SelectorConfigInput.styled";

export function SelectorConfigInput({
  leftValue,
  rightValue,
  leftLabel,
  rightLabel,
  leftError = false,
  rightError = false,
  leftErrorMessage,
  rightErrorMessage,
  extraInfo,
  disabled = false,
  labelDirection = "row",
  onChangeLeftValue,
  onChangeRightValue,
  InputProps,
}: {
  leftValue: string;
  rightValue: string;
  leftLabel: string;
  rightLabel: string;
  leftError?: boolean;
  rightError?: boolean;
  leftErrorMessage?: string;
  rightErrorMessage?: string;
  extraInfo?: string;
  disabled?: boolean;
  labelDirection?: "row" | "column";
  onChangeLeftValue?: (newValue: string) => void;
  onChangeRightValue?: (newValue: string) => void;
  InputProps?: Partial<StandardInputProps>;
}) {
  const handleLeftConfigChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChangeLeftValue && onChangeLeftValue(event.target.value);

  const handleRightConfigChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => onChangeRightValue && onChangeRightValue(event.target.value);

  return (
    <>
      <InputContainer $labelDirection={labelDirection}>
        <SelectorInput
          InputProps={InputProps}
          disabled={disabled}
          error={leftError}
          helperText={leftError ? leftErrorMessage : ""}
          value={leftValue}
          onChange={handleLeftConfigChange}
        />
        <InputLabel>{leftLabel}</InputLabel>
      </InputContainer>
      <InputContainer $labelDirection={labelDirection}>
        <SelectorInput
          disabled={disabled}
          error={rightError}
          helperText={rightError ? rightErrorMessage : ""}
          value={rightValue}
          onChange={handleRightConfigChange}
        />
        <InputLabel>{rightLabel}</InputLabel>
      </InputContainer>
      {extraInfo && <InputLabel>{extraInfo}</InputLabel>}
    </>
  );
}
