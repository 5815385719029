import { useCallback, useMemo } from "react";
import { SelectChangeEvent } from "@mui/material";
import { produce } from "immer";
import { SimpleDropdown } from "components/SimpleDropdown";
import { TitleWithIcon } from "components/TitleWithIcon/TitleWithIcon";
import { SelectionType, LoRType } from "domain/Human";
import { IconsType } from "types/main";
import { dasherize } from "utils/stringUtil";
import { selectorsEnum } from "./data";
import { ListSelector } from "./ListSelector";
import { NormalDistributionSelector } from "./NormalDistributionSelector";
import { RangeSelector } from "./RangeSelector";
import {
  Container,
  DropdownContainer,
  ExampleImage,
  InfoContainer,
  SelectorsContainer,
} from "./SelectorsPicker.styled";

type SelectorsPickerProps = {
  title: string;
  icon: IconsType;
  imgSrc: string;
  lorValue: LoRType;
  limits: { max: string; min: string };
  step?: number;
  unit: string;
  onUpdate: (lor: LoRType) => void;
  showNormalDistribution?: boolean;
  noSpacing?: boolean;
  alternativeCustomIcon?: boolean;
};

export function SelectorsPicker(props: SelectorsPickerProps) {
  const {
    title,
    icon,
    imgSrc,
    lorValue,
    limits,
    step,
    unit,
    onUpdate,
    showNormalDistribution = true,
    noSpacing,
    alternativeCustomIcon,
  } = props;

  const humanizedTypeValue = {
    list: "List",
    range: "Range",
    normal_distribution: "Normal Distribution",
  };

  const selectorOptions = useMemo(
    () =>
      (Object.keys(selectorsEnum) as (keyof typeof selectorsEnum)[])
        .map((key) => {
          if (key === "Normal_Distribution" && !showNormalDistribution) {
            return null;
          }

          return selectorsEnum[key];
        })
        .filter(Boolean) as selectorsEnum[],
    [showNormalDistribution]
  );

  const onUpdateLocal = useCallback(
    (fn: (draft: LoRType) => void) => {
      onUpdate(produce(lorValue, fn));
    },
    [lorValue, onUpdate]
  );

  const onUpdateType = (v: SelectionType) =>
    onUpdateLocal((d) => {
      d.type = v;
    });

  const selectorType = {
    [selectorsEnum.List]: "list",
    [selectorsEnum.Range]: "range",
    [selectorsEnum.Normal_Distribution]: "normal_distribution",
  };

  const onUpdateSelector = (event: SelectChangeEvent<unknown>) => {
    const selectedSelector = event.target.value as unknown as selectorsEnum;
    onUpdateType(selectorType[selectedSelector] as SelectionType);
  };

  const currentSelector = {
    [selectorsEnum.List]: (
      <ListSelector
        lorValue={lorValue}
        maxValue={Number(limits.max)}
        minValue={Number(limits.min)}
        unit={unit}
        onUpdate={onUpdate}
      />
    ),
    [selectorsEnum.Range]: (
      <RangeSelector
        lorValue={lorValue}
        maxValue={Number(limits.max)}
        minValue={Number(limits.min)}
        step={step}
        unit={unit}
        onUpdate={onUpdate}
      />
    ),
    [selectorsEnum.Normal_Distribution]: (
      <NormalDistributionSelector
        lorValue={lorValue}
        maxValue={Number(limits.max)}
        minValue={Number(limits.min)}
        step={step}
        onUpdate={onUpdate}
      />
    ),
  };

  return (
    <Container $noSpacing={noSpacing} data-testid={dasherize(title)}>
      <InfoContainer>
        <TitleWithIcon icon={icon} label={title} />
        {imgSrc && <ExampleImage alt={title} src={imgSrc} />}
      </InfoContainer>
      <SelectorsContainer>
        <DropdownContainer>
          <SimpleDropdown
            alternativeCustomIcon={alternativeCustomIcon}
            options={selectorOptions}
            value={humanizedTypeValue[lorValue.type]}
            onChange={onUpdateSelector}
          />
        </DropdownContainer>
        {currentSelector[humanizedTypeValue[lorValue.type] as selectorsEnum]}
      </SelectorsContainer>
    </Container>
  );
}
