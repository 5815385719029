export function HeadTurnIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8141 20.3913L16.8141 20.3913L16.8096 20.3906L15.7455 20.2329V21.3026C15.7455 21.8021 15.3403 22.2002 14.8438 22.2002H6.26351C5.76545 22.2002 5.36174 21.7965 5.36174 21.2985V15.6896C3.85932 14.1683 3.00757 12.1031 3.00757 9.96011C3.00757 5.45963 6.66707 1.8 11.1636 1.8C12.38 1.8 13.5555 2.06339 14.643 2.57776C17.5121 3.9081 19.3645 6.81021 19.3645 9.96827V10.9513L20.7411 13.5801L20.7413 13.5804C21.0598 14.191 21.085 14.8505 20.7636 15.38C20.478 15.8529 19.9663 16.1351 19.3645 16.1861V17.9773C19.3645 19.3161 18.2751 20.401 16.9409 20.401C16.8945 20.401 16.8467 20.3953 16.8141 20.3913ZM18.4628 14.395H19.1363L17.6655 11.5912L17.6652 11.5906C17.5982 11.4619 17.561 11.3202 17.561 11.1719V9.96827C17.561 7.50863 16.1172 5.24952 13.8782 4.20914L13.8782 4.20915L13.8771 4.20862C13.0225 3.80505 12.1092 3.59945 11.1595 3.59945C7.65517 3.59945 4.80294 6.45151 4.80294 9.96011C4.80294 11.7359 5.55454 13.445 6.86375 14.6475C7.04663 14.8148 7.15711 15.0554 7.15711 15.309V20.4049H13.9379V19.1932C13.9379 18.9295 14.0542 18.6833 14.2516 18.5118L14.2539 18.5098C14.4509 18.3439 14.7117 18.2647 14.97 18.3016L14.971 18.3018L16.9854 18.6005C17.3088 18.5727 17.561 18.3037 17.561 17.9773V15.2927C17.561 14.7931 17.9662 14.395 18.4628 14.395Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.4"
      />
    </svg>
  );
}
