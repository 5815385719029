import React, { forwardRef } from "react";
import { Link as BaseLink, LinkProps as BaseLinkProps } from "@mui/material";
import { NavigateFunction } from "react-router-dom";

type LinkProps = {
  level?: "primary" | "secondary";
  navigate?: NavigateFunction;
  children: React.ReactNode;
} & BaseLinkProps;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  { children, sx, ...props },
  ref
) {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (props.navigate && props.href) {
      event.preventDefault();
      props.navigate(props.href);
    }
  };
  const { level = "primary" } = props;

  if (level === "secondary") {
    return (
      <BaseLink
        ref={ref}
        onClick={handleClick}
        {...props}
        sx={{
          ...(sx || {}),
          textDecoration: "none",
          color: "#37568e",
        }}
      >
        {children}
      </BaseLink>
    );
  }
  return (
    <BaseLink
      ref={ref}
      onClick={handleClick}
      {...props}
      sx={{
        ...(sx || {}),
        textDecoration: "none",
      }}
    >
      {children}
    </BaseLink>
  );
});
