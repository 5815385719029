import { produce } from "immer";
import { WritableDraft } from "immer/dist/internal";
import {
  allExpressions,
  allHairs,
  defaultAnimationsSection,
  defaultBodySection,
  defaultCamera,
  defaultClothingSection,
  defaultExpression,
  defaultEyebrow,
  defaultEye,
  defaultFacialHair,
  defaultGaze,
  defaultGlassesSelection,
  defaultHair,
  defaultHdri,
  defaultHeadphoneSelection,
  defaultHeadTurn,
  defaultHeadwearSelection,
  defaultIdentities,
  defaultLight,
  defaultLocation,
  defaultMaskSelection,
  defaultPresetTransform,
  defaultRig,
  defaultSection,
  facialHairColors,
  facialHairs,
  LoRType,
  Section,
  ThreeDLocationType,
} from "domain/Human";
import {
  allHdrisSelector,
  allGesturesSelector,
  allGesturesNamesSelector,
  clothingOutfitSelector,
  fatContentSelector,
  glassColorsSelector,
  glassStylesSelector,
  headphonesStylesSelector,
  headwearStylesSelector,
  maskPositionsSelector,
  maskStylesSelector,
  maskVariantsSelector,
} from "features/JobBuilder/store";
import { useBoundStore } from "store/_boundStore";
import {
  getAnimationsListPeacefully,
  getBodiesPeacefully,
  getClothingListPeacefully,
  getHdriPeacefully,
} from "./json-extracter";
import {
  DeepPartial,
  Json3DLocation,
  JsonAnimations,
  JsonBody,
  JsonCamera,
  JsonClothing,
  JsonColor,
  JsonExpression,
  JsonEyebrow,
  JsonEye,
  JsonFacialHair,
  JsonGaze,
  JsonGlasses,
  JsonHair,
  JsonHdri,
  JsonHeadphones,
  JsonHeadTurn,
  JsonHeadwear,
  JsonIdentities,
  JsonInput,
  JsonLight,
  JsonLocation,
  JsonLoR,
  JsonMask,
  JsonPresetLocation,
  JsonRig,
  Uncertain,
} from "./json-types";

const stringEnum = (
  value: any,
  allOptions: string[],
  originalValue: string[]
) => {
  if (!value || !Array.isArray(value)) {
    return originalValue;
  }
  const joinedString = value.join();
  if (joinedString === "all") {
    return allOptions;
  }
  if (joinedString === "none") {
    return [];
  }
  return value;
};

const updateLor = (obj: Uncertain<JsonLoR>, formOldValue: LoRType): LoRType => {
  return produce(formOldValue, (d) => {
    if (obj && obj.values && obj.type && typeof obj.type === "string") {
      if (obj.type === "list" && Array.isArray(obj.values)) {
        d.type = "list";
        d.listValues = obj.values.join(",");
      } else if (obj.type === "range") {
        d.type = "range";

        const { min, max } = obj.values as { min?: number; max?: number };

        if (max !== undefined) {
          d.max = max.toString();
        }
        if (min !== undefined) {
          d.min = min.toString();
        }
      } else {
        d.type = "normal_distribution";

        const { mean, std_dev } = obj.values as {
          mean?: number;
          std_dev?: number;
        };

        if (mean !== undefined) {
          d.mean = mean.toString();
        }
        if (std_dev !== undefined) {
          d.std_dev = std_dev.toString();
        }
      }
    }
  });
};

export const convertHdri = (
  json: Uncertain<JsonHdri>,
  formOldValue: typeof defaultHdri
) => {
  if (!json) {
    return formOldValue;
  }
  const hdriOptions = allHdrisSelector(useBoundStore.getState());
  return produce(formOldValue, (d) => {
    d.name = stringEnum(json.name, hdriOptions, d.name);
    d.intensity = updateLor(json.intensity, d.intensity);
    d.rotation = updateLor(json.rotation, d.rotation);
  });
};

export const convertExpressions = (
  json: Uncertain<JsonExpression[]>,
  formOldValue: (typeof defaultExpression)[],
  allOptions: string[]
): (typeof defaultExpression)[] => {
  if (!json) {
    return formOldValue;
  }
  return json.map((item) =>
    convertExpression(item, { ...defaultExpression }, allOptions)
  );
};

export const convertExpression = (
  json: Uncertain<JsonExpression>,
  formOldValue: typeof defaultExpression,
  allOptions: string[]
) => {
  if (!json) {
    return formOldValue;
  }
  return produce(formOldValue, (d) => {
    d.name = stringEnum(json.name, allOptions, d.name);
    d.intensity = updateLor(json.intensity, d.intensity);
    d.percent = getPercent(json.percent);
  });
};

export const convertGazes = (
  json: Uncertain<JsonGaze[]>,
  formOldValue: (typeof defaultGaze)[]
): (typeof defaultGaze)[] => {
  if (!json) {
    return formOldValue;
  }
  return json.map((item) => convertGaze(item, { ...defaultGaze }));
};

export const convertGaze = (
  json: Uncertain<JsonGaze>,
  formOldValue: typeof defaultGaze
) => {
  if (!json) {
    return formOldValue;
  }
  return produce(formOldValue, (d) => {
    d.vertical_angle = updateLor(json.vertical_angle, d.vertical_angle);
    d.horizontal_angle = updateLor(json.horizontal_angle, d.horizontal_angle);
    d.percent = getPercent(json.percent);
  });
};

export const convertEye = (
  json: Uncertain<JsonEye>,
  defaultValue: typeof defaultEye,
  allIrisColors: string[]
) => {
  if (!json) {
    return defaultValue;
  }

  return produce(defaultValue, (d) => {
    d.iris_color = stringEnum(json.iris_color, allIrisColors, d.iris_color);
    d.pupil_dilation = updateLor(json.pupil_dilation, d.pupil_dilation);
    d.redness = updateLor(json.redness, d.redness);
    d.percent = getPercent(json.percent);
  });
};

export const convertEyes = (
  json: Uncertain<JsonEye[]>,
  formOldValue: (typeof defaultEye)[],
  allIrisColors: string[]
) => {
  if (!json) {
    return formOldValue;
  }

  return json.map((item) => convertEye(item, { ...defaultEye }, allIrisColors));
};

export const convertHeadTurns = (
  json: Uncertain<JsonHeadTurn[]>,
  formOldValue: (typeof defaultHeadTurn)[]
): (typeof defaultHeadTurn)[] => {
  if (!json) {
    return formOldValue;
  }
  return json.map((item) => convertHeadTurn(item, { ...defaultHeadTurn }));
};

export const convertHeadTurn = (
  json: Uncertain<JsonHeadTurn>,
  formOldValue: typeof defaultHeadTurn
) => {
  if (!json) {
    return formOldValue;
  }
  return produce(formOldValue, (d) => {
    d.pitch = updateLor(json.pitch, d.pitch);
    d.yaw = updateLor(json.yaw, d.yaw);
    d.roll = updateLor(json.roll, d.roll);
    d.percent = getPercent(json.percent);
  });
};

export const convertHairs = (
  json: Uncertain<JsonHair[]>,
  formOldValue: (typeof defaultHair)[],
  allStyleOptions: string[],
  allColorOptions: string[]
): (typeof defaultHair)[] => {
  if (!json) {
    return formOldValue;
  }
  return json.map((item) =>
    convertHair(item, { ...defaultHair }, allStyleOptions, allColorOptions)
  );
};

export const convertHair = (
  json: Uncertain<JsonHair>,
  formOldValue: typeof defaultHair,
  allStyleOptions: string[],
  allColorOptions: string[]
) => {
  if (!json) {
    return formOldValue;
  }
  return produce(formOldValue, (d) => {
    d.style = stringEnum(json.style, allStyleOptions, d.style);
    d.color = stringEnum(json.color, allColorOptions, d.color);
    d.color_seed = updateLor(json.color_seed, d.color_seed);
    d.relative_length = updateLor(json.relative_length, d.relative_length);
    d.relative_density = updateLor(json.relative_density, d.relative_density);
    d.sex_matched_only = json.sex_matched_only || false;
    d.ethnicity_matched_only = json.ethnicity_matched_only || false;
    d.percent = getPercent(json.percent);
  });
};

export const convertEyebrow = (
  json: Uncertain<JsonEyebrow>,
  defaultValue: typeof defaultEyebrow,
  allStyles: string[],
  allColors: string[]
) => {
  if (!json) {
    return defaultValue;
  }

  return produce(defaultValue, (d) => {
    d.style = stringEnum(json.style, allStyles, d.style);
    d.color = stringEnum(json.color, allColors, d.color);
    d.color_seed = updateLor(json.color_seed, d.color_seed);
    d.relative_density = updateLor(json.relative_density, d.relative_density);
    d.relative_length = updateLor(json.relative_length, d.relative_length);
    d.match_hair_color = !!json.match_hair_color;
    d.sex_matched_only = !!json.sex_matched_only;
    d.percent = getPercent(json.percent);
  });
};

export const convertEyebrows = (
  json: Uncertain<JsonEyebrow[]>,
  formOldValue: (typeof defaultEyebrow)[],
  allOptions: string[],
  allStyles: string[]
) => {
  if (!json) {
    return formOldValue;
  }

  return json.map((item) =>
    convertEyebrow(item, { ...defaultEyebrow }, allOptions, allStyles)
  );
};

export const convertHeadwearList = (
  json: Uncertain<JsonHeadwear[]>,
  formOldValue: (typeof defaultHeadwearSelection)[],
  allStyleOptions: string[]
): (typeof defaultHeadwearSelection)[] => {
  if (!json) {
    return formOldValue;
  }
  return json.map((item) =>
    convertHeadwear(item, { ...defaultHeadwearSelection }, allStyleOptions)
  );
};

export const convertHeadwear = (
  json: Uncertain<JsonHeadwear>,
  formOldValue: typeof defaultHeadwearSelection,
  allStyleOptions: string[]
) => {
  if (!json) {
    return formOldValue;
  }
  return produce(formOldValue, (d) => {
    d.style = stringEnum(json.style, allStyleOptions, d.style);
    d.sex_matched_only = json.sex_matched_only || false;
    d.percent = getPercent(json.percent);
  });
};

export const convertFacialHairs = (
  json: Uncertain<JsonFacialHair[]>,
  formOldValue: (typeof defaultFacialHair)[],
  allStyleOptions: string[],
  allColorOptions: string[]
): (typeof defaultFacialHair)[] => {
  if (!json) {
    return formOldValue;
  }
  return json.map((item) =>
    convertFacialHair(
      item,
      { ...defaultFacialHair },
      allStyleOptions,
      allColorOptions
    )
  );
};

export const convertFacialHair = (
  json: Uncertain<JsonFacialHair>,
  formOldValue: typeof defaultFacialHair,
  allStyleOptions: string[],
  allColorOptions: string[]
) => {
  if (!json) {
    return formOldValue;
  }
  return produce(formOldValue, (d) => {
    d.style = stringEnum(json.style, allStyleOptions, d.style);
    d.color = stringEnum(json.color, allColorOptions, d.color);
    d.color_seed = updateLor(json.color_seed, d.color_seed);
    d.relative_length = updateLor(json.relative_length, d.relative_length);
    d.relative_density = updateLor(json.relative_density, d.relative_density);
    d.match_hair_color = json.match_hair_color || false;
    d.percent = getPercent(json.percent);
  });
};

const getPercent = (n: number | undefined) =>
  !n || isNaN(Number(n)) ? "100" : n.toString();

export const convertBodies = (
  bodiesInJson: Uncertain<JsonBody[]>,
  formOldValue: (typeof defaultBodySection)[]
): (typeof defaultBodySection)[] => {
  if (!bodiesInJson) {
    return formOldValue;
  }
  return bodiesInJson.map((item) =>
    convertBody(item, { ...defaultBodySection })
  );
};

const getEnabled = (e: boolean | undefined) => (e === undefined ? true : e);

export const convertBody = (
  json: Uncertain<JsonBody>,
  formOldValue: typeof defaultBodySection
): typeof defaultBodySection => {
  if (!json) {
    return formOldValue;
  }
  const state = useBoundStore.getState();
  return produce(formOldValue, (ff) => {
    ff.height = stringEnum(json.height, glassStylesSelector(state), ff.height);
    ff.fat_content = stringEnum(
      json.fat_content,
      fatContentSelector(state),
      ff.fat_content
    );
    ff.percent = getPercent(json.percent);
    ff.enabled = getEnabled(json.enabled);
  });
};

export const convertClothingList = (
  clothingListJson: Uncertain<JsonClothing[]>,
  formOldValue: (typeof defaultClothingSection)[]
): (typeof defaultClothingSection)[] => {
  if (!clothingListJson) {
    return formOldValue;
  }
  return clothingListJson.map((item) =>
    convertClothing(item, { ...defaultClothingSection })
  );
};

export const convertClothing = (
  json: Uncertain<JsonClothing>,
  formOldValue: typeof defaultClothingSection
): typeof defaultClothingSection => {
  if (!json) {
    return formOldValue;
  }
  const state = useBoundStore.getState();
  return produce(formOldValue, (clothing) => {
    clothing.percent = getPercent(json.percent);
    clothing.outfit = stringEnum(
      json.outfit,
      clothingOutfitSelector(state),
      clothing.outfit
    );
    clothing.sex_matched_only = json.sex_matched_only || false;
    clothing.single_style_per_id = json.single_style_per_id || false;
  });
};

export const convertAnimationsList = (
  animationsListJson: Uncertain<JsonAnimations[]>,
  formOldValue: (typeof defaultAnimationsSection)[]
): (typeof defaultAnimationsSection)[] => {
  if (!animationsListJson) {
    return formOldValue;
  }
  return animationsListJson.map((item) =>
    convertAnimations(item, { ...defaultAnimationsSection })
  );
};

export const convertAnimations = (
  json: Uncertain<JsonAnimations>,
  formOldValue: typeof defaultAnimationsSection
): typeof defaultAnimationsSection => {
  if (!json) {
    return formOldValue;
  }
  const state = useBoundStore.getState();
  return produce(formOldValue, (animations) => {
    animations.percent = getPercent(json.percent);
    animations.name = stringEnum(
      json.name,
      allGesturesNamesSelector(state),
      animations.name
    );
    animations.keyframe_only = json.keyframe_only || false;
    animations.mirrored = json.mirrored || false;
    animations.position_seed = updateLor(
      json.position_seed,
      animations.position_seed
    );
  });
};

export const convertMask = (
  json: Uncertain<JsonMask>,
  formOldValue: typeof defaultMaskSelection
): typeof defaultMaskSelection => {
  if (!json) {
    return formOldValue;
  }
  const state = useBoundStore.getState();
  return produce(formOldValue, (ff) => {
    ff.style = stringEnum(json.style, maskStylesSelector(state), ff.style);
    ff.position = stringEnum(
      json.position,
      maskPositionsSelector(state),
      ff.position
    );
    ff.variant = stringEnum(
      json.variant,
      maskVariantsSelector(state),
      ff.variant
    );
    ff.percent = getPercent(json.percent);
  });
};

export const convertMasks = (
  masksInJson: Uncertain<JsonMask[]>,
  formOldValue: (typeof defaultMaskSelection)[]
): (typeof defaultMaskSelection)[] => {
  if (!masksInJson) {
    return formOldValue;
  }
  return masksInJson.map((item) =>
    convertMask(item, { ...defaultMaskSelection })
  );
};

export const convertGlasses = (
  glassesInJson: Uncertain<JsonGlasses[]>,
  formOldValue: (typeof defaultGlassesSelection)[]
): (typeof defaultGlassesSelection)[] => {
  if (!glassesInJson) {
    return formOldValue;
  }
  return glassesInJson.map((item) =>
    convertGlass(item, { ...defaultGlassesSelection })
  );
};

export const convertGlass = (
  json: Uncertain<JsonGlasses>,
  formOldValue: typeof defaultGlassesSelection
): typeof defaultGlassesSelection => {
  if (!json) {
    return formOldValue;
  }
  const state = useBoundStore.getState();
  return produce(formOldValue, (ff) => {
    ff.name = stringEnum(json.style, glassStylesSelector(state), ff.name);
    ff.lensColor = stringEnum(
      json.lens_color,
      glassColorsSelector(state),
      ff.lensColor
    );
    ff.metalness = updateLor(json.metalness, ff.metalness);
    ff.transparency = updateLor(json.transparency, ff.transparency);
    ff.sexMatching = json.sex_matched_only !== false;
    ff.percent = getPercent(json.percent);
  });
};

export const convertHeadphones = (
  headphonesInJson: Uncertain<JsonHeadphones[]>,
  formOldValue: (typeof defaultHeadphoneSelection)[]
): (typeof defaultHeadphoneSelection)[] => {
  if (!headphonesInJson) {
    return formOldValue;
  }
  return headphonesInJson.map((item) =>
    convertHeadphone(item, { ...defaultHeadphoneSelection })
  );
};

export const convertHeadphone = (
  json: Uncertain<JsonHeadphones>,
  formOldValue: typeof defaultHeadphoneSelection
): typeof defaultHeadphoneSelection => {
  if (!json) {
    return formOldValue;
  }
  const state = useBoundStore.getState();
  return produce(formOldValue, (ff) => {
    ff.style = stringEnum(
      json.style,
      headphonesStylesSelector(state),
      ff.style
    );
    ff.percent = getPercent(json.percent);
  });
};

export const convertIds = (json: number[], formOldValue: number[]) =>
  json || formOldValue;

export const convertIdentities = (
  json: Uncertain<JsonIdentities>,
  formOldValue: typeof defaultIdentities
) => {
  if (!json) {
    return formOldValue;
  }
  return produce(formOldValue, (d) => {
    d.rendersPerIdentity =
      json.renders_per_identity?.toString() || d.rendersPerIdentity;
    d.ids = convertIds(json.ids as number[], d.ids);
  });
};

export const convertRig = (
  json: Uncertain<JsonRig>,
  formOldValue: typeof defaultRig
) => {
  if (!json) {
    return formOldValue;
  }
  return produce(formOldValue, (d) => {
    d.type = json.type || d.type;
    d.presetName = json.preset_name || d.presetName;
    fillLocation(json.location, d);
    fillPresetLocation(json.preset_transform, d);
    const jsonLights = json.lights;
    if (jsonLights && Array.isArray(jsonLights)) {
      const lightsInForm = jsonLights.map((l) =>
        convertLight(l, { ...defaultLight })
      );
      d.lights = lightsInForm;
    }
    const jsonCameras = json.cameras;
    if (jsonCameras && Array.isArray(jsonCameras)) {
      const camerasInForm = jsonCameras.map((l) =>
        convertCamera(l, { ...defaultCamera })
      );
      d.cameras = camerasInForm;
    }
  });
};

export const convertLight = (
  json: Uncertain<JsonLight>,
  formOldValue: typeof defaultLight
) => {
  if (!json) {
    return formOldValue;
  }
  return produce(formOldValue, (d) => {
    d.type = json.type || d.type;
    d.wavelength = json.wavelength || d.wavelength;
    d.intensity = updateLor(json.intensity, d.intensity);
    d.sizeMeters = updateLor(json.size_meters, d.sizeMeters);

    fillLocation(json.relative_location, d);

    const jsonColor = json.color;
    if (jsonColor) {
      const props: (keyof JsonColor)[] = ["green", "red", "blue"];
      props.forEach((prop) => {
        d[prop as "x"] = updateLor(jsonColor[prop], d[prop as "x"]);
      });
      d.red = updateLor(jsonColor.red, d.red);
      d.green = updateLor(jsonColor.green, d.green);
      d.blue = updateLor(jsonColor.blue, d.blue);
    }
    return d;
  });
};

export const convertCamera = (
  json: Uncertain<JsonCamera>,
  formOldValue: typeof defaultCamera
) => {
  if (!json) {
    return formOldValue;
  }
  return produce(formOldValue, (d) => {
    d.name = json.name || d.name;

    const specifications = json.specifications;
    const advanced = json.advanced;

    if (specifications) {
      d.resolutionH = specifications.resolution_h
        ? specifications.resolution_h.toString()
        : d.resolutionH;
      d.resolutionW = specifications.resolution_w
        ? specifications.resolution_w.toString()
        : d.resolutionW;
      d.focalLength = updateLor(specifications.focal_length, d.focalLength);
      d.sensorWidth = updateLor(specifications.sensor_width, d.sensorWidth);
      d.wavelength = specifications.wavelength || d.wavelength;
    }

    if (advanced) {
      d.lens_shift_horizontal = updateLor(
        advanced.lens_shift_horizontal,
        d.lens_shift_horizontal
      );
      d.lens_shift_vertical = updateLor(
        advanced.lens_shift_vertical,
        d.lens_shift_vertical
      );
      d.window_offset_horizontal = updateLor(
        advanced.window_offset_horizontal,
        d.lens_shift_horizontal
      );
      d.window_offset_vertical = updateLor(
        advanced.window_offset_vertical,
        d.window_offset_vertical
      );
      d.noise_threshold =
        advanced.noise_threshold?.toString() || d.noise_threshold;
      d.denoise = advanced.denoise || d.denoise;
    }

    fillLocation(json.relative_location, d);
    return d;
  });
};

export const convertThreeDimensionalLocations = (
  json: Uncertain<Json3DLocation[]>,
  formOldValue: ThreeDLocationType
): ThreeDLocationType => {
  if (!json || !json[0]) {
    return formOldValue;
  }
  return convertThreeDimensionalLocation(json[0], ThreeDLocationType.open);
};

export const convertThreeDimensionalLocation = (
  json: Uncertain<Json3DLocation>,
  formOldValue: ThreeDLocationType
) => {
  if (!json || !json.specifications || !json.specifications.type) {
    return formOldValue;
  }
  const type = json.specifications.type as ThreeDLocationType;
  if (!Object.values(ThreeDLocationType).includes(type)) {
    return formOldValue;
  }
  return type;
};

const fillLocation = (
  loc: Uncertain<JsonLocation>,
  d: WritableDraft<typeof defaultLocation>
) => {
  if (loc) {
    const props: (keyof JsonLocation)[] = [
      "x",
      "y",
      "z",
      "pitch",
      "yaw",
      "roll",
    ];
    props.forEach((prop) => {
      d[prop as "x"] = updateLor(loc[prop], d[prop as "x"]);
    });
  }
};

const fillPresetLocation = (
  loc: Uncertain<JsonPresetLocation>,
  d: WritableDraft<typeof defaultPresetTransform>
) => {
  if (loc) {
    const props: (keyof JsonPresetLocation)[] = ["v", "u"];
    props.forEach((prop) => {
      d[prop as "v"] = updateLor(loc[prop], d[prop as "v"]);
    });
  }
};

export function convertPercentToNumber<T extends { percent: string }>(
  list: T[]
) {
  return list.map((item) => ({
    ...item,
    percent: parseInt(item.percent, 10),
  }));
}

export function templateToSubjob(
  humans: DeepPartial<JsonInput["humans"]>
): Section[] {
  const state = useBoundStore.getState();
  return humans.map((h) => {
    const section = { ...defaultSection };
    if (!h) {
      return section;
    }

    section.threeDLocation = convertThreeDimensionalLocations(
      h["3d_location"],
      section.threeDLocation
    );

    section.identities = convertIdentities(h.identities, section.identities);
    section.hdri = convertHdri(getHdriPeacefully(h), section.hdri);
    const jsonRigs = h.camera_and_light_rigs;
    if (jsonRigs) {
      section.rigs = jsonRigs.map((r) => convertRig(r, defaultRig));
    }
    const jsonFacial = h.facial_attributes;
    if (jsonFacial) {
      section.expression = convertExpressions(
        jsonFacial.expression,
        section.expression,
        allExpressions
      );
      section.facialHair = convertFacialHairs(
        jsonFacial.facial_hair,
        section.facialHair,
        facialHairs,
        facialHairColors
      );
      section.gaze = convertGazes(jsonFacial.gaze, section.gaze);
      section.hair = convertHairs(
        jsonFacial.hair,
        section.hair,
        allHairs,
        facialHairColors
      );
      section.headTurn = convertHeadTurns(
        jsonFacial.head_turn,
        section.headTurn
      );
    }
    const jsonAccessories = h.accessories;
    if (jsonAccessories) {
      section.glasses = convertGlasses(
        jsonAccessories.glasses,
        section.glasses
      );
      section.headphones = convertHeadphones(
        jsonAccessories.headphones,
        section.headphones
      );
      section.headwear = convertHeadwearList(
        jsonAccessories.headwear,
        section.headwear,
        headwearStylesSelector(state)
      );
      section.masks = convertMasks(jsonAccessories.masks, section.masks);
    }
    section.body = convertBodies(getBodiesPeacefully(h), section.body);
    section.clothing = convertClothingList(
      getClothingListPeacefully(h),
      section.clothing
    );
    section.gesture = convertAnimationsList(
      getAnimationsListPeacefully(h),
      section.gesture
    );
    return section;
  });
}
