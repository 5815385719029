import { ApisVersion } from "domain/ApisVersion";
import { SliceType } from "store/_boundStore";
import { createActions } from "./actions";

type State = {
  data?: ApisVersion;
};

type Actions = {
  fetchApisVersion: () => void;
};

const initialState: State = {
  data: undefined,
};

export type ApisVersionSlice = State & Actions;

export const createApisVersionSlice: SliceType<ApisVersionSlice> = (
  set,
  get
) => ({
  ...initialState,
  ...createActions(set),
});
