import React from "react";
import { DialogContent, DialogTitle } from "@mui/material";
import * as Icons from "assets/icons";
import { colors } from "components/App/theme";
import { IconsType } from "types/main";
import {
  ActionsContainer,
  CancelButton,
  ConfirmButton,
  DialogBody,
  DialogContainer,
} from "./new-ui-confirmation-dialog.styled";

export function NewUIConfirmationDialog({
  onCancel,
  onConfirm,
  title,
  children,
  icon,
}: {
  onCancel: () => void;
  onConfirm: () => void;
  title?: string;
  children: JSX.Element;
  icon: IconsType;
}) {
  const Icon = Icons[icon];

  return (
    <DialogContainer open onClose={onCancel}>
      {!!title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <Icon style={{ color: colors.red, height: "48px", width: "48px" }} />
        <DialogBody>{children}</DialogBody>
      </DialogContent>
      <ActionsContainer>
        <ConfirmButton onClick={onConfirm}>Delete</ConfirmButton>

        <CancelButton onClick={onCancel}>Cancel</CancelButton>
      </ActionsContainer>
    </DialogContainer>
  );
}
