import React from "react";
import { Button } from "@mui/material";
import { ErrorBoundaryFallbackMessageContainer } from "./error-boundary-fallback-message.styled";

export function ErrorBoundaryFallbackMessage() {
  return (
    <ErrorBoundaryFallbackMessageContainer>
      <h1>Something went wrong</h1>
      <p>
        We caught an error before it could ruin your experience. We already
        notified our team. While we fix it, please try refreshing the page.
      </p>

      <Button
        color="warning"
        variant="outlined"
        onClick={() => window.location.reload()}
      >
        Refresh
      </Button>
    </ErrorBoundaryFallbackMessageContainer>
  );
}
