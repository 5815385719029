import { useMemo } from "react";
import expressionDiagram from "assets/diagrams/expression-intensity.svg";
import { ExpressionIcon } from "assets/icons";
import { ProTip } from "components/ProTip";
import { RightAsidePreviewFull } from "components/RightAsidePreviewFull";
import { PreviewContent } from "components/RightAsidePreviewFull/PreviewContent";
import { SelectorsPicker } from "components/SelectorsPicker";
import { PUBLIC_S3_BUCKET } from "constants/constants";
import { Messages } from "constants/messages";
import { defaultExpression } from "domain/Human";
import { EmptyState } from "features/JobBuilder/empty-state";
import { convertExpressionStateToJson } from "features/JobBuilder/form-to-input-json";
import {
  getExpression,
  getHumanObject,
} from "features/JobBuilder/json-extracter";
import { JsonManagerNewUI } from "features/JobBuilder/json-manager-new-ui";
import { convertExpressions } from "features/JobBuilder/json-to-form";
import { DeepPartial, JsonHuman } from "features/JobBuilder/json-types";
import { ContentCard, Page } from "features/JobBuilder/layout";
import { MultiOptionImageGrid } from "features/JobBuilder/MultiOptionImageGrid";
import { expressionStyleSelector } from "features/JobBuilder/store";
import { useDistPage } from "features/JobBuilder/useDistPage";
import {
  useImageSelection,
  useSelectionOutcomeCount,
} from "features/JobBuilder/useImageSelection";
import { useBoundStore } from "store/_boundStore";

const id = "expression";
export function JobBuilderExpression() {
  const {
    value,
    selectedGroupIndex,
    onUpdate,
    onAdd,
    numberOfImages,
    toggleDistListItem,
    isSummingTo100,
    onUpdatePart,
    onUpdatePercent,
  } = useDistPage(id);

  const selectedExpressionGroup = value[selectedGroupIndex];
  const onUpdateIntensity = onUpdatePart("intensity");
  const expressionOptions = useBoundStore((s) => expressionStyleSelector(s));

  const nameUIState = useImageSelection(
    selectedExpressionGroup ? selectedExpressionGroup.name : [],
    expressionOptions,
    (s) => `${PUBLIC_S3_BUCKET}/pl1/expression.name/${s}.png`,
    onUpdatePart("name"),
    "Expression",
    "expression",
    "ExpressionIcon",
    toggleDistListItem("name", expressionOptions)
  );

  const outcomes = useSelectionOutcomeCount([nameUIState]);

  const { setErrorMessage, setSuccessMessage } =
    useBoundStore.getState().message;

  const onIndecesReceived = (
    indices: number[],
    humansArr: DeepPartial<JsonHuman>[]
  ) => {
    try {
      onUpdate(
        convertExpressions(
          getExpression(getHumanObject(humansArr, indices[0])),
          value,
          expressionOptions
        )
      );
      setSuccessMessage("updated expression");
    } catch (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
      }
    }
  };

  // TODO: Type JSON
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDoneJson = (json: any) => {
    const updated = convertExpressions(json, value, expressionOptions);
    onUpdate(updated);
  };

  const jsonValue = useMemo(() => convertExpressionStateToJson(value), [value]);
  const isEmptyState = !selectedExpressionGroup;
  const rightAside = (
    <RightAsidePreviewFull previewTitle="Preview">
      <PreviewContent data={nameUIState.selectedImages} title="STYLE" />
    </RightAsidePreviewFull>
  );
  const center = (
    <JsonManagerNewUI
      emptyState={
        isEmptyState ? (
          <EmptyState
            description="Expressions controls the expressions variations of the character models. Default expressions variations are applied, some sample results are shown below:"
            folder="expressions"
            icon={<ExpressionIcon style={{ width: 48, height: 48 }} />}
            title="Default expressions settings applied"
          />
        ) : null
      }
      groupIndex={selectedGroupIndex + 1}
      groupPercentage={selectedExpressionGroup?.percent}
      id={`expression-${selectedGroupIndex + 1}`}
      isSummingTo100={isSummingTo100}
      jsonValue={jsonValue}
      numberOfImages={numberOfImages}
      parentTitle="Facial Attributes"
      parentTitleIcon="FacialAttributesIcon"
      title={`Expression`}
      titleCount={outcomes}
      titleIcon="ExpressionIcon"
      unknownIndicesKeys={["Human"]}
      onClickAddButton={onAdd}
      onDoneEditJson={onDoneJson}
      onIndecesReceived={onIndecesReceived}
      onUpdatePercent={onUpdatePercent}
    >
      <ContentCard>
        <ProTip label={Messages.expressionProTip} />
        <MultiOptionImageGrid {...nameUIState} />
      </ContentCard>
      <ContentCard style={{ padding: "20px" }}>
        <SelectorsPicker
          icon={"IntensityIcon"}
          imgSrc={expressionDiagram}
          limits={defaultExpression.intensity}
          lorValue={selectedExpressionGroup?.intensity}
          noSpacing={true}
          title="Intensity"
          unit={"mm"}
          onUpdate={onUpdateIntensity}
        />
      </ContentCard>
    </JsonManagerNewUI>
  );
  return (
    <Page
      fixedRightSide
      center={center}
      hideRightAside={isEmptyState}
      rightAside={rightAside}
    />
  );
}
