export function InboxIcon({
  style = { height: "16px" },
  fill = "currentColor",
}: {
  style?: React.CSSProperties;
  fill?: string;
} = {}) {
  return (
    <svg
      fill="none"
      height="16"
      style={style}
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 2.5H3.33333C2.6 2.5 2 3.1 2 3.83333V13.1667C2 13.9 2.59333 14.5 3.33333 14.5H12.6667C13.4 14.5 14 13.9 14 13.1667V3.83333C14 3.1 13.4 2.5 12.6667 2.5ZM12.6667 13.1667H3.33333V11.1667H5.70667C6.16667 11.96 7.02 12.5 8.00667 12.5C8.99333 12.5 9.84 11.96 10.3067 11.1667H12.6667V13.1667ZM12.6667 9.83333H9.34C9.34 10.5667 8.74 11.1667 8.00667 11.1667C7.27333 11.1667 6.67333 10.5667 6.67333 9.83333H3.33333V3.83333H12.6667V9.83333Z"
        fill={fill}
      />
    </svg>
  );
}
