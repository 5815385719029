import { useState, useMemo, useCallback } from "react";
import { geom2 } from "@flasd/modeling/src/geometries";
import { Vec2 } from "@flasd/modeling/src/maths/vec2";
import { useThree } from "@react-three/fiber";
import { useGesture, UserHandlers } from "@use-gesture/react";
import * as THREE from "three";
import { useAutoRef } from "features/JobBuilder2/steps/threeDimensional/useAutoRef";
import { selectPickedSubjob } from "features/JobBuilder2/store";
import { segmentTrack } from "features/JobBuilder2/track-service";
import { useBoundStore } from "store/_boundStore";

const _pointer = new THREE.Vector2(0, 0);

const floorPlane = new THREE.Plane().setFromNormalAndCoplanarPoint(
  new THREE.Vector3(0, 0, 1),
  new THREE.Vector3()
);

export const useSubdivideRegion = () => {
  const [controlMode, onGeomDrawn, pickedSubjob, userID] = useBoundStore(
    (s) => [
      s.userControls.controlMode,
      s.jobBuilder2.onGeomDrawn,
      selectPickedSubjob(s),
      s.profile.data?.id,
    ]
  );

  const isMoveControlMode = controlMode === "move";

  const [initialDragPoint, setInitialDragPoint] =
    useState<THREE.Vector3 | null>(null);
  const [releaseDragPoint, setReleaseDragPoint] =
    useState<THREE.Vector3 | null>(null);

  const intersectionPoint = useMemo(() => {
    return new THREE.Vector3();
  }, []);

  const drawingGeom = useMemo(() => {
    if (!releaseDragPoint || !initialDragPoint) {
      return null;
    }
    const rectangle = [
      [releaseDragPoint.x, releaseDragPoint.y],
      [releaseDragPoint.x, initialDragPoint.y],
      [initialDragPoint.x, initialDragPoint.y],
      [initialDragPoint.x, releaseDragPoint.y],
    ] as Vec2[];
    return geom2.create([rectangle]);
  }, [releaseDragPoint, initialDragPoint]);

  const drawingGeomRef = useAutoRef(drawingGeom);

  // once dragging stops, call the store to update activity zones
  const onDragStopped = useCallback(() => {
    const currentDrawingGeom = drawingGeomRef.current;
    if (currentDrawingGeom) {
      onGeomDrawn(currentDrawingGeom);
      segmentTrack("added activity zone", userID, {
        locationName: pickedSubjob.location,
      });
    }
    setInitialDragPoint(null);
    setReleaseDragPoint(null);
  }, [drawingGeomRef, onGeomDrawn, pickedSubjob.location, userID]);

  const { raycaster, camera, canvas } = useThree(
    ({ raycaster, camera, gl: { domElement: canvas } }) => ({
      raycaster,
      camera,
      canvas,
    })
  );

  const handleCursorDrawing = useCallback(
    (
      event: PointerEvent | MouseEvent | TouchEvent | KeyboardEvent,
      first: boolean,
      active: boolean
    ) => {
      if (!(event instanceof PointerEvent) || isMoveControlMode) {
        return;
      }

      const canvasRect = canvas.getBoundingClientRect();

      const offsetX = event.clientX - canvasRect.x;
      const offsetY = event.clientY - canvasRect.y;

      _pointer.x = (offsetX / canvas.clientWidth) * 2 - 1;
      _pointer.y = -(offsetY / canvas.clientHeight) * 2 + 1;

      raycaster.setFromCamera(_pointer, camera);

      // if it's the first time
      if (first) {
        raycaster.ray.intersectPlane(floorPlane, intersectionPoint);
        setInitialDragPoint(intersectionPoint.clone());
      }
      if (!initialDragPoint) {
        return;
      }
      raycaster.ray.intersectPlane(floorPlane, intersectionPoint);
      setReleaseDragPoint(intersectionPoint.clone());
      if (!active) {
        onDragStopped();
      }
    },
    [
      isMoveControlMode,
      onDragStopped,
      intersectionPoint,
      initialDragPoint,
      raycaster,
      camera,
      canvas,
    ]
  );

  const onDrag: UserHandlers["onDrag"] = useCallback(
    ({ dragging, first, event }) => {
      handleCursorDrawing(event, first, Boolean(dragging));
    },
    [handleCursorDrawing]
  );

  useGesture(
    {
      onDrag,
    },
    {
      target: canvas,
    }
  );

  return drawingGeom;
};
