import React from "react";
import { CameraModel } from "features/JobBuilder/CamerasAndLightsPreview/assets/CameraModel";
import { useRelativeLocation } from "features/JobBuilder/CamerasAndLightsPreview/hooks/useRelativeLocation";
import { JsonCamera } from "features/JobBuilder/json-types";

interface CameraProps {
  data: JsonCamera;
}

export function Camera({ data }: CameraProps) {
  const { position, rotation } = useRelativeLocation(data.relative_location);

  return (
    <group position={position} rotation={rotation}>
      <CameraModel />
    </group>
  );
}
