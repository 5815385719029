import React from "react";
import { GenericMessage } from "./GenericMessage";

type ErrorMessageProps = {
  message: string;
  onDismiss: () => void;
};

export function ErrorMessage(props: ErrorMessageProps) {
  const { message, onDismiss } = props;
  return (
    <GenericMessage
      duration={5000}
      message={message}
      severity="error"
      onDismiss={onDismiss}
    />
  );
}
