export function GeneralLightIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7834 5.83999V3.26659C13.7834 3.08323 13.6334 2.93323 13.45 2.93323C11.1134 2.93323 9.30344 4.21987 8.51004 6.24663H2.08664C1.48664 6.24663 1 6.68327 1 7.21999V10.11C1 10.6433 1.48664 11.08 2.08664 11.08H3.72328V14.5766C3.72328 14.7599 3.87328 14.9099 4.05664 14.9099H4.28V17.5065C4.28 17.6899 4.43 17.8399 4.61336 17.8399H5.13672V20.7365C5.13672 20.9199 5.28672 21.0699 5.47008 21.0699C5.65344 21.0699 5.80344 20.9199 5.80344 20.7365V17.8399H6.3268C6.51016 17.8399 6.66016 17.6899 6.66016 17.5065V14.9099H6.84016C7.02352 14.9099 7.17352 14.7599 7.17352 14.5766L7.17336 11.08H8.50672C9.29672 13.1066 11.1101 14.3934 13.4467 14.3934C13.6301 14.3934 13.7801 14.2434 13.7801 14.06V11.4866C15.1301 11.3133 16.1767 10.1133 16.1767 8.66323C16.1766 7.21331 15.1333 6.01003 13.7833 5.84003L13.7834 5.83999ZM6.51 14.2434H4.3932L4.39328 8.96999C4.39328 8.73663 4.58328 8.54999 4.81328 8.54999H6.08992C6.32328 8.54999 6.50992 8.73999 6.50992 8.96999L6.51 14.2434ZM5.99664 17.1734H4.95V14.91H5.99664V17.1734ZM7.17664 10.4134V8.96675C7.17664 8.36675 6.69 7.88011 6.09 7.88011H4.81336C4.21336 7.88011 3.72672 8.36675 3.72672 8.96675V10.4134H2.08664C1.86336 10.4134 1.66664 10.2734 1.66664 10.11V7.22003C1.66664 7.05667 1.86328 6.91339 2.08664 6.91339H8.29324C8.14988 7.45675 8.0766 8.04339 8.0766 8.66339C8.0766 9.28339 8.15324 9.87003 8.29324 10.4134H7.17664ZM13.1166 13.72C10.0966 13.5533 8.74004 11.0966 8.74004 8.66339C8.74004 6.22999 10.0967 3.77679 13.1166 3.60679V13.72ZM13.7834 10.8134V6.51339C14.7634 6.68339 15.5134 7.58003 15.5134 8.66339C15.5134 9.74667 14.7634 10.6434 13.7834 10.8134Z"
        fill={fill}
      />
      <path
        d="M5.45 9.33655C5.26664 9.33655 5.11664 9.48655 5.11664 9.66991V10.3365C5.11664 10.5199 5.26664 10.6699 5.45 10.6699C5.63336 10.6699 5.78336 10.5199 5.78336 10.3365V9.66991C5.78336 9.48655 5.63664 9.33655 5.45 9.33655V9.33655Z"
        fill={fill}
      />
      <path
        d="M17.7166 8.32996H16.8832C16.6999 8.32996 16.5499 8.47996 16.5499 8.66332C16.5499 8.84668 16.6999 8.99668 16.8832 8.99668H17.7166C17.9 8.99668 18.05 8.84668 18.05 8.66332C18.05 8.47996 17.9 8.32996 17.7166 8.32996Z"
        fill={fill}
      />
      <path
        d="M19.09 8.99655H20.4634C20.6467 8.99655 20.7967 8.84655 20.7967 8.66319C20.7967 8.47983 20.6467 8.32983 20.4634 8.32983H19.09C18.9066 8.32983 18.7566 8.47983 18.7566 8.66319C18.7566 8.84655 18.9034 8.99655 19.09 8.99655Z"
        fill={fill}
      />
      <path
        d="M21.834 8.99655H22.6673C22.8506 8.99655 23.0006 8.84655 23.0006 8.66319C23.0006 8.47983 22.8506 8.32983 22.6673 8.32983H21.8339C21.6506 8.32983 21.5006 8.47983 21.5006 8.66319C21.5006 8.84655 21.6506 8.99655 21.834 8.99655V8.99655Z"
        fill={fill}
      />
      <path
        d="M17.78 10.74L17.0334 10.3734C16.8667 10.29 16.6667 10.36 16.5867 10.5267C16.5067 10.6933 16.5734 10.8934 16.7401 10.9734L17.4867 11.34C17.5334 11.3633 17.5834 11.3734 17.6334 11.3734C17.7567 11.3734 17.8734 11.3033 17.9334 11.1867C18.0134 11.0233 17.9434 10.8233 17.78 10.74Z"
        fill={fill}
      />
      <path
        d="M20.636 12.1465L19.206 11.4432C19.0394 11.3598 18.8394 11.4298 18.7594 11.5965C18.6794 11.7632 18.746 11.9599 18.9127 12.0432L20.3427 12.7465C20.3894 12.7699 20.4394 12.7799 20.4894 12.7799C20.6127 12.7799 20.7294 12.7099 20.7894 12.5933C20.8694 12.4265 20.8027 12.2265 20.636 12.1465V12.1465Z"
        fill={fill}
      />
      <path
        d="M22.814 13.2166L22.0674 12.8499C21.9007 12.7666 21.7007 12.8366 21.6207 13.0033C21.5407 13.1699 21.6074 13.3699 21.7741 13.4499L22.5207 13.8166C22.5674 13.8399 22.6174 13.8499 22.6674 13.8499C22.7907 13.8499 22.9074 13.7799 22.9674 13.6633C23.0473 13.4966 22.9806 13.2966 22.814 13.2166Z"
        fill={fill}
      />
      <path
        d="M16.8834 6.98653C16.9334 6.98653 16.9834 6.97653 17.03 6.95317L17.7767 6.58653C17.9433 6.50653 18.01 6.30653 17.93 6.13989C17.85 5.97653 17.65 5.90653 17.4834 5.98653L16.7368 6.35317C16.5701 6.43317 16.5034 6.63317 16.5834 6.79981C16.6434 6.91989 16.76 6.98653 16.8834 6.98653V6.98653Z"
        fill={fill}
      />
      <path
        d="M20.344 4.58344L18.914 5.2868C18.7474 5.3668 18.6806 5.5668 18.7606 5.73344C18.8173 5.85008 18.9373 5.92008 19.0606 5.92008C19.1106 5.92008 19.1606 5.91008 19.2073 5.88672L20.6373 5.18336C20.8039 5.10336 20.8706 4.90336 20.7906 4.73672C20.7073 4.57008 20.5106 4.50008 20.344 4.58343V4.58344Z"
        fill={fill}
      />
      <path
        d="M21.92 4.51339C21.97 4.51339 22.02 4.50339 22.0666 4.48003L22.8133 4.11339C22.9799 4.03339 23.0466 3.83339 22.9666 3.66675C22.8866 3.50339 22.6866 3.43339 22.52 3.51339L21.7734 3.88003C21.6067 3.96003 21.54 4.16003 21.62 4.32667C21.6766 4.44339 21.7966 4.51339 21.92 4.51339V4.51339Z"
        fill={fill}
      />
    </svg>
  );
}
