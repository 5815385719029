import { useState, useEffect, useRef } from "react";
import useResizeObserver from "use-resize-observer";

export const usePartialSticky = (maxHeightDown: number) => {
  const scrollContentRef = useRef<HTMLDivElement>();
  const { ref: stickyContentRef, width: stickyContentWidth = 0 } =
    useResizeObserver<HTMLCanvasElement>();

  const [scrolledDown, setScrolledDown] = useState(false);

  useEffect(() => {
    const el = scrollContentRef.current;
    if (!el) {
      return;
    }
    const onScroll = () => setScrolledDown(el.scrollTop > maxHeightDown);
    el.addEventListener("scroll", onScroll);
    return () => el.removeEventListener("scroll", onScroll);
  }, [maxHeightDown]);

  const widthOfNonStickyContent = `calc(100% - ${stickyContentWidth}px)`;
  return [
    scrollContentRef,
    stickyContentRef,
    scrolledDown,
    widthOfNonStickyContent,
  ] as const;
};
