import React from "react";
import Check from "@mui/icons-material/Check";
import {
  StepConnector as StepConnectorBase,
  stepConnectorClasses,
  StepIconProps,
  styled,
} from "@mui/material";
import { colors } from "components/App/theme";

function getBackgroundColor(active: boolean, completed: boolean) {
  if (active) return colors.bright[2];
  if (completed) return colors.white;

  return colors.greyBG;
}

function getBorderColor(active: boolean, completed: boolean) {
  if (active || completed) return colors.bright[2];

  return colors.greyBG;
}

function getColor(active: boolean, completed: boolean) {
  if (active) return colors.white;
  if (completed) return colors.bright[2];

  return colors.grey70;
}

const StepIconRoot = styled("div")<{ $active?: boolean; $completed?: boolean }>(
  ({ theme, $active, $completed }) => ({
    display: "flex",
    height: 32,
    width: 32,
    backgroundColor: getBackgroundColor(!!$active, !!$completed),
    borderRadius: 16,
    border: `2px solid ${getBorderColor(!!$active, !!$completed)}`,
    alignItems: "center",
    justifyContent: "center",
    "& .QontoStepIcon-completedIcon": {
      color: getColor(!!$active, !!$completed),
      zIndex: 1,
      fontSize: 16,
    },
    "& .QontoStepIcon-circle": {},
  })
);

export const StepConnector = styled(StepConnectorBase)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.bright[2],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: colors.bright[2],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderWidth: 2,
    position: "relative",
    left: 3,
    top: -8,
    marginBottom: -16,
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

function StepIcon(props: StepIconProps & { index: number }) {
  const { active, completed, className, index } = props;

  return (
    <StepIconRoot $active={active} $completed={completed} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-completedIcon">{index + 1}</div>
      )}
    </StepIconRoot>
  );
}

export function getStepIcon(index: number) {
  return function StepIconHOC(props: StepIconProps) {
    return <StepIcon {...props} index={index} />;
  };
}
