import { useMemo } from "react";
import { colors } from "components/App/theme";

export const useStripeFormOptions = () => {
  return useMemo(
    () => ({
      style: {
        base: {
          fontSize: "20px",
          color: colors.fiord,
          letterSpacing: "0.025em",
          fontFamily: "monospace",
          "::placeholder": {
            color: colors.cadetBlue,
          },
        },
        invalid: {
          color: colors.pomegranate,
        },
      },
    }),
    []
  );
};
