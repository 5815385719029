import { useMemo } from "react";
import sexMatchedOnlyDiagram from "assets/diagrams/headwear-sex-matched-only.svg";
import { CapIcon } from "assets/icons";
import { BooleanSelector } from "components/BooleanSelector";
import { ProTip } from "components/ProTip";
import { RightAsidePreviewFull } from "components/RightAsidePreviewFull";
import { PreviewContent } from "components/RightAsidePreviewFull/PreviewContent";
import { PUBLIC_S3_BUCKET } from "constants/constants";
import { Messages } from "constants/messages";
import { EmptyState } from "features/JobBuilder/empty-state";
import { convertHeadwearStateToJson } from "features/JobBuilder/form-to-input-json";
import {
  getHumanObject,
  getHeadwear,
} from "features/JobBuilder/json-extracter";
import { JsonManagerNewUI } from "features/JobBuilder/json-manager-new-ui";
import { convertHeadwearList } from "features/JobBuilder/json-to-form";
import { JsonHuman } from "features/JobBuilder/json-types";
import { ContentCard, Page } from "features/JobBuilder/layout";
import { MultiOptionImageGrid } from "features/JobBuilder/MultiOptionImageGrid";
import { headwearStylesSelector } from "features/JobBuilder/store";
import { useDistPage } from "features/JobBuilder/useDistPage";
import {
  useImageSelection,
  useSelectionOutcomeCount,
} from "features/JobBuilder/useImageSelection";
import { changeCheckEventValue } from "features/JobBuilder/utils";
import { useBoundStore } from "store/_boundStore";

const id = "headwear";
export function JobBuilderHeadwear() {
  const {
    isSummingTo100,
    onUpdate,
    onUpdatePart,
    value,
    selectedGroupIndex,
    onUpdatePercent,
    onAdd,
    numberOfImages,
    toggleDistListItem,
  } = useDistPage(id);
  const onUpdateSexMatchedOnly = onUpdatePart("sex_matched_only");
  const selectedHeadwear = value[selectedGroupIndex];

  const [setErrorMessage, setSuccessMessage, styleOptions] = useBoundStore(
    (s) => [
      s.message.setErrorMessage,
      s.message.setSuccessMessage,
      headwearStylesSelector(s),
    ]
  );
  const onIndecesReceived = (indices: number[], humansArr: JsonHuman[]) => {
    try {
      const humanObject = getHumanObject(humansArr, indices[0]);
      const jsonHeadwearList = getHeadwear(humanObject);
      onUpdate(convertHeadwearList(jsonHeadwearList, value, styleOptions));
      setSuccessMessage("updated mask");
    } catch (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
      }
    }
  };

  // TODO: Type json
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDoneJson = (json: any) => {
    const obj = convertHeadwearList(json, value, styleOptions);
    onUpdate(obj);
  };

  const jsonValue = useMemo(() => convertHeadwearStateToJson(value), [value]);

  const styleUIState = useImageSelection(
    selectedHeadwear ? selectedHeadwear.style : [],
    styleOptions,
    (s) => `${PUBLIC_S3_BUCKET}/pl1/headwear.style/${s}.png`,
    onUpdatePart("style"),
    "Style",
    "styles",
    "StyleIcon",
    toggleDistListItem("style", styleOptions)
  );

  const outcomes = useSelectionOutcomeCount([styleUIState]);

  const isEmptyState = !selectedHeadwear;
  const center = (
    <JsonManagerNewUI
      key={`maskGroup-${selectedGroupIndex}`}
      emptyState={
        isEmptyState ? (
          <EmptyState
            description="Headwear controls the headwear variations of the character models. Default headwear variations are applied, some sample results are shown below:"
            folder="headwear"
            icon={<CapIcon style={{ width: 48, height: 48 }} />}
            title="Default headwear settings applied"
          />
        ) : null
      }
      groupIndex={selectedGroupIndex + 1}
      groupPercentage={selectedHeadwear?.percent}
      id={`maskGroup-${selectedGroupIndex}`}
      isSummingTo100={isSummingTo100}
      jsonValue={jsonValue}
      numberOfImages={numberOfImages}
      parentTitle="Accessories"
      parentTitleIcon="AccessoriesIcon"
      title={`Headwear`}
      titleCount={outcomes}
      titleIcon="CapIcon"
      unknownIndicesKeys={["Human"]}
      onClickAddButton={onAdd}
      onDoneEditJson={onDoneJson}
      onIndecesReceived={onIndecesReceived}
      onUpdatePercent={onUpdatePercent}
    >
      <ContentCard>
        <ProTip label={Messages.hedwearProTip} />
        <MultiOptionImageGrid {...styleUIState} />
      </ContentCard>

      <ContentCard style={{ padding: "8px" }}>
        <BooleanSelector
          noSpacing
          description={Messages.sexMatchedDescriptionForHeadwear}
          icon={"SexMatchedOnlyIcon"}
          imgSrc={sexMatchedOnlyDiagram}
          label={"Enabled"}
          title={"Sex Matched Only"}
          value={selectedHeadwear?.sex_matched_only}
          onUpdate={changeCheckEventValue(onUpdateSexMatchedOnly)}
        />
      </ContentCard>
    </JsonManagerNewUI>
  );

  const rightAside = (
    <RightAsidePreviewFull previewTitle="Preview">
      <PreviewContent data={styleUIState.selectedImages} title="STYLE" />
    </RightAsidePreviewFull>
  );
  return (
    <Page
      fixedRightSide
      center={center}
      hideRightAside={isEmptyState}
      rightAside={rightAside}
    />
  );
}
