import { useMemo } from "react";
import { GazeIcon } from "assets/icons";
import { SelectorsPicker } from "components/SelectorsPicker";
import { Messages } from "constants/messages";
import { defaultGaze } from "domain/Human";
import { EmptyState } from "features/JobBuilder/empty-state";
import { convertGazeStateToJson } from "features/JobBuilder/form-to-input-json";
import { getGaze, getHumanObject } from "features/JobBuilder/json-extracter";
import { JsonManagerNewUI } from "features/JobBuilder/json-manager-new-ui";
import { convertGazes } from "features/JobBuilder/json-to-form";
import { DeepPartial, JsonHuman } from "features/JobBuilder/json-types";
import { ContentCard, Page } from "features/JobBuilder/layout";
import { useDistPage } from "features/JobBuilder/useDistPage";
import { useProTip } from "hooks/UseProTip";
import { useBoundStore } from "store/_boundStore";
import horizontalAngleImage from "./HorizontalAngleImage.png";
import verticalAngleImage from "./VerticalAngleImage.png";

const id = "gaze";
export function JobBuilderGaze() {
  const {
    value,
    selectedGroupIndex,
    onUpdate,
    onAdd,
    numberOfImages,
    isSummingTo100,
    onUpdatePart,
    onUpdatePercent,
  } = useDistPage(id);

  const { ProTip, showingProTip } = useProTip();
  const GazeProTip = () => ProTip({ label: Messages.gazeProTip });
  const selectedGazeGroup = value[selectedGroupIndex];
  const onUpdateHorizontalAngle = onUpdatePart("horizontal_angle");
  const onUpdateVerticalAngle = onUpdatePart("vertical_angle");

  const { setErrorMessage, setSuccessMessage } =
    useBoundStore.getState().message;

  const onIndecesReceived = (
    indices: number[],
    humansArr: DeepPartial<JsonHuman>[]
  ) => {
    try {
      onUpdate(
        convertGazes(getGaze(getHumanObject(humansArr, indices[0])), value)
      );
      setSuccessMessage("updated gaze");
    } catch (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
      }
    }
  };

  const onDoneJson = (json: any) => {
    const updated = convertGazes(json, value);
    onUpdate(updated);
  };
  const jsonValue = useMemo(() => convertGazeStateToJson(value), [value]);
  const center = (
    <JsonManagerNewUI
      emptyState={
        !selectedGazeGroup ? (
          <EmptyState
            description="Gaze controls the gaze variations of the character models. Default gaze variations are applied, some sample results are shown below:"
            folder="eyegaze"
            icon={<GazeIcon style={{ width: 48, height: 48 }} />}
            title="Default gaze settings applied"
          />
        ) : null
      }
      groupIndex={selectedGroupIndex + 1}
      groupPercentage={selectedGazeGroup?.percent}
      id={`gaze-${selectedGroupIndex + 1}`}
      isSummingTo100={isSummingTo100}
      jsonValue={jsonValue}
      numberOfImages={numberOfImages}
      parentTitle="Facial Attributes"
      parentTitleIcon="FacialAttributesIcon"
      title="Gaze"
      titleIcon="GazeIcon"
      unknownIndicesKeys={["Human"]}
      onClickAddButton={onAdd}
      onDoneEditJson={onDoneJson}
      onIndecesReceived={onIndecesReceived}
      onUpdatePercent={onUpdatePercent}
    >
      {showingProTip && (
        <ContentCard>
          <GazeProTip />
        </ContentCard>
      )}
      <ContentCard style={{ padding: "20px" }}>
        <SelectorsPicker
          icon={"HorizontalAngleIcon"}
          imgSrc={horizontalAngleImage}
          limits={defaultGaze.horizontal_angle}
          lorValue={selectedGazeGroup?.horizontal_angle}
          noSpacing={true}
          title="Horizontal Angle"
          unit={"degree(s)"}
          onUpdate={onUpdateHorizontalAngle}
        />
      </ContentCard>
      <ContentCard style={{ padding: "8px" }}>
        <SelectorsPicker
          icon={"VerticalAngleIcon"}
          imgSrc={verticalAngleImage}
          limits={defaultGaze.vertical_angle}
          lorValue={selectedGazeGroup?.vertical_angle}
          noSpacing={true}
          title="Vertical Angle"
          unit={"degree(s)"}
          onUpdate={onUpdateVerticalAngle}
        />
      </ContentCard>
    </JsonManagerNewUI>
  );
  return <Page center={center} />;
}
