import React from "react";
import { ImageGridViewMode } from "components/ImageGrid";
import { ReactComponent as GridIcon } from "components/ListViewToggle/assets/gridIcon.svg";
import { ReactComponent as HorizontalListIcon } from "components/ListViewToggle/assets/horizontalListIcon.svg";
import { Container, IconContainer } from "./ListViewToggle.styled";

const views: {
  value: ImageGridViewMode;
  title: string;
  icon: JSX.Element;
}[] = [
  {
    value: "grid",
    title: "Gallery view (with vertical scroll)",
    icon: <GridIcon />,
  },
  {
    value: "horizontalList",
    title: "Carousel view (with horizontal scroll)",
    icon: <HorizontalListIcon />,
  },
];

interface ListViewToggleProps {
  view: ImageGridViewMode;
  onChange: (view: ImageGridViewMode) => void;
}

export function ListViewToggle({ view, onChange }: ListViewToggleProps) {
  return (
    <Container>
      {views.map(({ icon, value, title }) => (
        <IconContainer
          key={value}
          $isSelected={view === value}
          placement="top"
          title={title}
          onClick={() => onChange(value)}
        >
          {icon}
        </IconContainer>
      ))}
    </Container>
  );
}
