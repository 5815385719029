import styled from "styled-components";

export const ErrorBoundaryFallbackMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: center;

  h1 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 16px;
    max-width: 370px;
  }
`;
