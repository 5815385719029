import {
  BufferGeometry,
  CatmullRomCurve3,
  Line,
  LineBasicMaterial,
  Vector3,
} from "three";
import { JsonRig } from "features/JobBuilder/json-types";
import { useRigLocation } from "./useRigLocation";

export const useGetRangeOfMotionCurve = (rigData: JsonRig | undefined) => {
  const {
    origin: maxOrigin,
    position: maxPosition,
    rotation: maxRotation,
  } = useRigLocation(rigData, "max");

  const rigMaxPosition = new Vector3().addVectors(
    maxOrigin,
    maxPosition.applyEuler(maxRotation)
  );

  const { origin, position, rotation } = useRigLocation(rigData, "mean");

  const rigMeanPosition = new Vector3().addVectors(
    origin,
    position.applyEuler(rotation)
  );

  const {
    origin: minOrigin,
    position: minPosition,
    rotation: minRotation,
  } = useRigLocation(rigData, "min");

  const rigMinPosition = new Vector3().addVectors(
    minOrigin,
    minPosition.applyEuler(minRotation)
  );

  const xCurve = new CatmullRomCurve3([
    new Vector3(
      rigMaxPosition.x,
      rigMeanPosition.y,
      rigData?.type === "manual" ? rigMeanPosition.z : rigMinPosition.z
    ),
    rigMeanPosition,
    new Vector3(
      rigMinPosition.x,
      rigMeanPosition.y,
      rigData?.type === "manual" ? rigMeanPosition.z : rigMinPosition.z
    ),
  ]);

  const yCurve = new CatmullRomCurve3([
    new Vector3(
      rigMeanPosition.x,
      rigMaxPosition.y,
      rigData?.type === "manual" ? rigMeanPosition.z : rigMinPosition.z
    ),
    rigMeanPosition,
    new Vector3(
      rigMeanPosition.x,
      rigMinPosition.y,
      rigData?.type === "manual" ? rigMeanPosition.z : rigMinPosition.z
    ),
  ]);

  const zCurve = new CatmullRomCurve3([
    new Vector3(rigMeanPosition.x, rigMeanPosition.y, rigMaxPosition.z),
    rigMeanPosition,
    new Vector3(rigMeanPosition.x, rigMeanPosition.y, rigMinPosition.z),
  ]);

  const xMaterial = new LineBasicMaterial({ color: 0xff0000 });
  const yMaterial = new LineBasicMaterial({ color: 0x00ff00 });
  const zMaterial = new LineBasicMaterial({ color: 0x0000ff });

  return [
    new Line(
      new BufferGeometry().setFromPoints(xCurve.getPoints(50)),
      xMaterial
    ),
    new Line(
      new BufferGeometry().setFromPoints(yCurve.getPoints(50)),
      yMaterial
    ),
    new Line(
      new BufferGeometry().setFromPoints(zCurve.getPoints(50)),
      zMaterial
    ),
  ];
};
