import { identityIDToIndex } from "domain/Human";
export const PL2_JOB_STATE_VERSION = 18;
export const PL2_SEGMENT_CATEGORY_NAME = "synthesis scenarios";
export const APP_ENV = process.env.REACT_APP_ENV
  ? process.env.REACT_APP_ENV.trim().toLowerCase()
  : "localhost";

export const PREVENT_REDUX_LOG = process.env.REACT_APP_PREVENT_REDUX_LOG
  ? process.env.REACT_APP_PREVENT_REDUX_LOG
  : "false";

export const IS_NEW_HUMANS_GA = true;
export const IS_SCENARIOS_GA = false;

const getBaseURL = () => {
  switch (APP_ENV) {
    case "stage":
      return "https://stage.synthesis.ai/api";
    case "prod":
      return "https://app.synthesis.ai/api";
    case "dev":
      return "https://dev.synthesis.ai/api";
    case "localhost":
    default:
      return "https://stage.synthesis.ai/api";
    // return "http://localhost:8080/api";
  }
};

const getTemplatesBaseURL = () => {
  switch (APP_ENV) {
    case "stage":
      return "https://strapi.stage.synthesis.ai/api";
    case "prod":
      return "https://strapi.synthesis.ai/api";
    case "dev":
      return "https://strapi.dev.synthesis.ai/api";
    case "localhost":
    default:
      return "https://strapi.stage.synthesis.ai/api";
  }
};

export const ASSETCAT_BASE_URL = "https://asset-catalog.synthesis.ai";

const getCatalogCharactersBaseURL = () => {
  switch (APP_ENV) {
    case "stage":
    case "prod":
    case "dev":
      return `${ASSETCAT_BASE_URL}/${APP_ENV}/v1`;
    default:
      return `${ASSETCAT_BASE_URL}/stage/v1`;
  }
};
const getPublicS3Bucket = () => {
  const prefix = "https://sai-public-assets.s3.us-east-2.amazonaws.com";
  switch (APP_ENV) {
    case "stage":
    case "prod":
      return `${prefix}/${APP_ENV}`;
    default:
      return `${prefix}/dev`;
  }
};
const getCatalogAssetsBaseURL = (assetName: "actions" | "locations") => {
  switch (APP_ENV) {
    case "prod":
      return `${ASSETCAT_BASE_URL}/api/v1/${assetName}`;
    case "dev":
      return `${ASSETCAT_BASE_URL}/cg/v1/${assetName}`;
    case "localhost":
    case "stage":
    default:
      return `${ASSETCAT_BASE_URL}/cg/v1/${assetName}/release`;
  }
};

// getCatalogIdentitiesBaseURL must include the env for which you need identities
export const getCatalogIdentitiesBaseURL = () => {
  switch (APP_ENV) {
    case "prod":
    case "dev":
    case "stage":
      return `https://asset-catalog.synthesis.ai/api/v2/identities/${APP_ENV}`;
    case "localhost":
    default:
      return `https://asset-catalog.synthesis.ai/api/v2/identities/dev`;
  }
};

export const BASE_URL = getBaseURL();
export const TEMPLATE_BASE_URL = getTemplatesBaseURL();
export const CATALOGUE_CHARACTERS_BASE_URL = getCatalogCharactersBaseURL();
export const CATALOGUE_ACTIONS_BASE_URL = getCatalogAssetsBaseURL("actions");
export const CATALOGUE_LOCATIONS_BASE_URL =
  getCatalogAssetsBaseURL("locations");

export const PUBLIC_S3_BUCKET = getPublicS3Bucket();
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PK
  ? process.env.REACT_APP_STRIPE_PK
  : "pk_test_51I8UcbGrU9Jj2l9kCuK9KqTxQpC1iVbPf59ndtFw8VmflJ0GBw5CMlnRKpgo9egfIihYWQ5YKZw42Osrta84EcD000uP6TWItr";
export const CLI_OSX_X86_URL = `${PUBLIC_S3_BUCKET}/cli/latest/synthesis-client-go_darwin_amd64.tar.gz`;
export const CLI_OSX_ARM_URL = `${PUBLIC_S3_BUCKET}/cli/latest/synthesis-client-go_darwin_arm64.tar.gz`;
export const CLI_LINUX_URL = `${PUBLIC_S3_BUCKET}/cli/latest/synthesis-client-go_linux_amd64.tar.gz`;
export const CLI_WINDOWS_URL = `${PUBLIC_S3_BUCKET}/cli/latest/synthesis-client-go_windows_amd64.zip`;
export const SAMPLE_JOB_URL = `${PUBLIC_S3_BUCKET}/sample_json/first_job.json`;
export const CLI_VIEW_ASSETS_COMMAND = "synthesis job assets view";
export const SENTRY_DSN =
  "https://81f2a8d0b67a4489a17158e77e6424a3@o418384.ingest.sentry.io/5854157";
export const MAX_NUMBER_OF_REQUESTS_AT_TIME = 1100;
export const identityImageURL = (identityID: number) => {
  const ALL_IDENTITIES_JOB_ID = "7d3af62b-c36c-4cf2-8f0b-fcde021d9dcc";
  const ALL_IDENTITIES_JOB_CAMERA_NAME = "cam_default";
  return `${PUBLIC_S3_BUCKET}/identities/all-identities/${ALL_IDENTITIES_JOB_ID}/${identityIDToIndex(
    identityID
  )}.${ALL_IDENTITIES_JOB_CAMERA_NAME}.f_1.rgb.png`;
};

export const templateSampleImages = (jobID: string) => {
  return Array.from(
    { length: 15 },
    (_, i) => `${getPublicS3Bucket()}/templates/${jobID}/${i}.png`
  );
};
export const HDRI_FOLDER = `${getPublicS3Bucket()}/pl1/hdri`;

export const bodyImageURL = (type: "height" | "fat") => (variant: string) => {
  return `${PUBLIC_S3_BUCKET}/pl1/body.${type}/${variant}.png`;
};
export const maskImageURL =
  (type: "style" | "position" | "variant") => (variant: string) =>
    `${PUBLIC_S3_BUCKET}/pl1/mask.${type}/${variant}.png`;

export const glassesImageURL = (type: "style" | "color") => (label: string) => {
  return `${PUBLIC_S3_BUCKET}/pl1/glasses.${type}/${label}.png`;
};
