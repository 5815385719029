import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { URLParams } from "constants/params";
import { useURLParam } from "hooks/useURLParam";
import { Pages } from "pages";
import { useBoundStore } from "store/_boundStore";
import { theme } from "./theme";
// import * as tld from '@testing-library/dom'
// (window as any).tld = tld

function App() {
  const { setSuccessMessage, setErrorMessage } =
    useBoundStore.getState().message;
  const [msg, type] = useURLParam(URLParams.msg, URLParams.msgType);
  if (msg && type === "success") {
    setSuccessMessage(msg);
  } else if (msg && (type === "error" || type === "failure")) {
    setErrorMessage(msg);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Pages />
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  );
}

export { App };
