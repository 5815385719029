import styled from "styled-components";
import { colors } from "components/App/theme";

export const DialogHeaderIconContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: ${colors.bright[2]};
  color: ${colors.white};
  margin-right: 8px;

  & > svg {
    font-size: 20px;
  }
`;
