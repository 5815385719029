import styled from "styled-components";
import { colors } from "components/App/theme";
import { ReverseTreeItem } from "features/JobBuilder/LeftSidebar/left-sidebar.styled";

export const Container = styled(ReverseTreeItem)`
  border-top: 1px solid ${colors.grey20};
  background-color: #ffffff;
  position: sticky;
  bottom: 0;
  margin-top: auto;
  width: 100%;

  & .MuiCollapse-root {
    margin: 0;
  }

  & .Mui-expanded p,
  & .Mui-expanded svg {
    color: ${colors.orange} !important;
  }
`;
