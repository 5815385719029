import { useState } from "react";
import { useBoundStore } from "store/_boundStore";
import { JsonInput } from "./input-json-types";
import { generateInputJson } from "./state-to-input-json";

export const useInputJson = (templateName: string) => {
  const [inputJson, setInputJson] = useState<JsonInput | null>(null);
  const [userEmail, activities, prefabGroups, locations, subjobs] =
    useBoundStore((s) => [
      s.profile.data?.email,
      s.catalogData.activities,
      s.catalogData.prefabGroups,
      s.catalogData.locations,
      s.jobBuilder2.subjobs,
    ]);

  const onShowInputJsonClick = async () => {
    if (!templateName || !userEmail) {
      return;
    }
    const inputJson = generateInputJson(
      subjobs,
      prefabGroups,
      locations,
      activities
    );
    setInputJson(inputJson);
  };

  return {
    onShowInputJsonClick,
    inputJson,
    setInputJson,
  };
};
