import React from "react";
import { Box, Grid, Typography } from "@mui/material";

interface SuccessMessageProps {
  title: string;
  details: string;
  children?: any;
}

function SuccessMessage({ title, details }: SuccessMessageProps) {
  return (
    <Grid container justifyContent="center">
      <Grid item md={6} xs={12}>
        <Box component="div" mb={2}>
          <Typography variant="h1">{title}</Typography>
        </Box>
        <Typography variant="body2">{details}</Typography>
      </Grid>
    </Grid>
  );
}

export { SuccessMessage };
