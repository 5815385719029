import { useCallback } from "react";
import { ActivityRegion } from "features/JobBuilder2/steps/threeDimensional/activity-regions/ActivityRegion";
import { DrawingActivityRegion } from "features/JobBuilder2/steps/threeDimensional/activity-regions/DrawingActivityRegion";
import { ReadOnlyPickedCameraPresetLocation } from "features/JobBuilder2/steps/threeDimensional/ReadOnlyPickedCameraPresetLocation";
import { useSubdivideRegion } from "features/JobBuilder2/steps/threeDimensional/useSubdivideRegion";
import { selectPickedSubjob } from "features/JobBuilder2/store";
import { useKeyDown } from "hooks/useKeyDown";
import { useBoundStore } from "store/_boundStore";

export function ActivityZones3dStep() {
  const [setControlMode, controlMode, pickedSubjob] = useBoundStore((s) => [
    s.userControls.setControlMode,
    s.userControls.controlMode,
    selectPickedSubjob(s),
  ]);
  const isMoveControlMode = controlMode === "move";

  // This is a keyboard shortcut for switching between different modes.
  // When you click on "c", you can flip flop between 2 modes ("draw" and "move" modes)
  useKeyDown(
    "c",
    useCallback(
      (e) => {
        e.preventDefault();
        setControlMode(isMoveControlMode ? "draw" : "move");
      },
      [isMoveControlMode, setControlMode]
    )
  );

  const drawingGeom = useSubdivideRegion();
  const activityZones = pickedSubjob.activityZones;

  return (
    <>
      <ReadOnlyPickedCameraPresetLocation />
      {/* polygon being drawn in "draw" mode (obviously) */}
      {drawingGeom && <DrawingActivityRegion geom={drawingGeom} />}

      {/* old geoms outlined */}
      {activityZones.map((activityZone, shapeIndex) => (
        <ActivityRegion
          key={activityZone.geomRegion.id}
          activityZone={activityZone}
          shapeIndex={shapeIndex}
        />
      ))}
    </>
  );
}
