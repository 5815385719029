import { Checkbox } from "@mui/material";
import { colors } from "components/App/theme";
import { TitleWithIcon } from "components/TitleWithIcon/TitleWithIcon";
import { IconsType } from "types/main";
import {
  Container,
  Description,
  Label,
  SelectorContainer,
  InfoContainer,
  ExampleImage,
  EndContainer,
} from "./BooleanSelector.styled";

export function BooleanSelector({
  title,
  description,
  label,
  icon,
  value,
  onUpdate,
  imgSrc,
  noSpacing,
}: {
  title: string;
  description: string;
  label: string;
  icon: IconsType;
  value: boolean;
  onUpdate: (e: React.ChangeEvent<HTMLInputElement>) => void;
  imgSrc?: string;
  noSpacing?: boolean;
}) {
  const hasImage = !!imgSrc;
  return (
    <Container $hasImage={hasImage} $noSpacing={noSpacing}>
      <InfoContainer>
        <TitleWithIcon icon={icon} label={title} />
        {hasImage && (
          <ExampleImage $noSpacing={noSpacing} alt={title} src={imgSrc} />
        )}
      </InfoContainer>
      <EndContainer>
        <Description>{description}</Description>
        <SelectorContainer>
          <Checkbox
            defaultChecked
            checked={value}
            sx={{
              color: colors.grey20,

              "&.Mui-checked": {
                color: colors.orange,
              },
            }}
            onChange={onUpdate}
          />
          <Label>{label}</Label>
        </SelectorContainer>
      </EndContainer>
    </Container>
  );
}
