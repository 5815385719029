import React from "react";
import { Box } from "@mui/material";
import { colors } from "components/App/theme";

export function StepItem({
  children,
  disabledPadding,
}: {
  children: React.ReactNode;
  disabledPadding?: boolean;
}) {
  return (
    <Box
      component="div"
      m={2}
      mt={0}
      p={disabledPadding ? 0 : 2}
      sx={{
        position: "relative",
        backgroundColor: colors.white,
        border: `1px solid ${colors.grey20}`,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        flex: "1",
      }}
    >
      {children}
    </Box>
  );
}
