import { Box, Typography } from "@mui/material";
import { colors } from "components/App/theme";
import { OrgBilling } from "types/main";
import { formatDateWithoutTime } from "utils/dateUtils";

export function Contract({
  orgBilling,
  orgName,
}: {
  orgBilling: OrgBilling;
  orgName: string;
}) {
  return (
    <Box
      bgcolor={colors.neutral[0]}
      component="div"
      data-testid="payment-method-component"
      padding={2}
    >
      <Box component="div" mb={2}>
        <Typography sx={{ marginBottom: 1 }} variant="h2">
          Active Pricing Agreement
        </Typography>
        {[
          [
            ["Place Tier", orgBilling.subscription_tier],
            [
              "Starts",
              orgBilling.date_activated
                ? formatDateWithoutTime(orgBilling.date_activated)
                : "",
            ],
            [
              "Ends",
              orgBilling.deactivation_date
                ? formatDateWithoutTime(orgBilling.deactivation_date)
                : "",
            ],
            ["Monthly Fee", orgBilling.monthly_fee],
            ["Ad-Hoc Image Pricing", orgBilling.fee_above_base_allotment],
            [
              "Ad-Hoc Dense Landmark Pricing",
              orgBilling.fee_per_additional_hd_landmark,
            ],
          ],
          [
            ["Default Image Allotment", orgBilling.base_image_allotment],
            ["User Allotment", orgBilling.end_users_count],
            // ["Default SAI Dense Landmark Download Allotment", ""],
            // ["Default Media Pipe Dense Landmark Allotment", ""],
          ],
          // [
          //     ["Used Images", "NNN (Base) MMM (Ad-Hoc)"],
          //     ["Downloads SAI Dense Landmarks", "NNN (Base) MMM (Ad-Hoc)"],
          //     ["Downloads Media Pipe Dense Landmarks", "NNN (Base) MMM (Ad-Hoc)"],
          //     ["Users", ""],
          // ]
        ].map((group, g) => (
          <Box key={g} component="div" mb={3}>
            {group.map((data, i) => (
              <Box key={i} component="div" display="flex">
                <Typography
                  sx={{ display: "block", flex: "1 1 0" }}
                  variant="body2"
                >
                  {data[0]}:
                </Typography>
                <Typography
                  sx={{ display: "block", flex: "1 1 0" }}
                  variant="body2"
                >
                  {data[1]}
                </Typography>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
