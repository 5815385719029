import React from "react";

export function BrightnessIcon({
  fill,
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4999 8.68945V8.89656L19.6464 9.04301L22.6028 11.9995L19.6464 14.9559L19.4999 15.1023V15.3095V19.4995H15.3099H15.1028L14.9564 19.6459L11.9999 22.6023L9.04349 19.6459L8.89705 19.4995H8.68994H4.49994V15.3095V15.1023L4.35349 14.9559L1.39705 11.9995L4.35349 9.04301L4.49994 8.89656V8.68945V4.49945H8.68994H8.89705L9.04349 4.35301L11.9999 1.39656L14.9564 4.35301L15.1028 4.49945H15.3099H19.4999V8.68945ZM17.9999 18.4995H18.4999V17.9995V14.6866L20.8335 12.353L21.187 11.9995L20.8335 11.6459L18.4999 9.31235V5.99945V5.49945H17.9999H14.687L12.3535 3.1659L11.9999 2.81235L11.6464 3.1659L9.31283 5.49945H5.99994H5.49994V5.99945V9.31235L3.16639 11.6459L2.81283 11.9995L3.16639 12.353L5.49994 14.6866V17.9995V18.4995H5.99994H9.31283L11.6464 20.833L11.9999 21.1866L12.3535 20.833L14.687 18.4995H17.9999ZM12.4999 7.02434C15.0206 7.27656 16.9999 9.4143 16.9999 11.9995C16.9999 14.5846 15.0206 16.7223 12.4999 16.9746V7.02434Z"
        fill={fill || "#9A9A9B"}
        stroke={fill || "#9A9A9B"}
      />
    </svg>
  );
}
