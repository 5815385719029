import React from "react";
import {
  DialogContent,
  Box,
  CircularProgress,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { defaultEmailValidation } from "utils/validators";

export function EmailForm({
  onCancel,
  onSubmit,
  loading,
}: {
  onCancel: () => void;
  onSubmit: (email: string) => void;
  loading: boolean;
}) {
  const { register, handleSubmit, errors, getValues } = useForm();
  return (
    <form onSubmit={handleSubmit(() => onSubmit(getValues("email")))}>
      <DialogContent>
        <p>Enter your new email address</p>
        <Box component="div" mb={3} mt={4}>
          {loading ? (
            <CircularProgress size="6rem" />
          ) : (
            <TextField
              autoFocus
              fullWidth
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ""}
              id="email"
              inputProps={{
                "data-testid": "email-input",
              }}
              inputRef={register(defaultEmailValidation)}
              label="Email address *"
              name="email"
              type="text"
              variant="outlined"
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          component="div"
          mb={1}
          ml={2}
          mr={2}
          textAlign="right"
          width="100%"
        >
          <Box component="div" display="inline" mr={2}>
            <Button color="secondary" variant="contained" onClick={onCancel}>
              Cancel
            </Button>
          </Box>
          <Button
            autoFocus
            color="warning"
            data-testid="submit-email-form"
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </DialogActions>
    </form>
  );
}
