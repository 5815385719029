export function SubJobIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 6.5V4.5H10V6.5H14ZM4 8.5V19.5H20V8.5H4ZM20 6.5C21.11 6.5 22 7.39 22 8.5V19.5C22 20.61 21.11 21.5 20 21.5H4C2.89 21.5 2 20.61 2 19.5L2.01 8.5C2.01 7.39 2.89 6.5 4 6.5H8V4.5C8 3.39 8.89 2.5 10 2.5H14C15.11 2.5 16 3.39 16 4.5V6.5H20Z"
        fill={fill}
      />
    </svg>
  );
}
