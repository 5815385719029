import { IconContainer } from "./icons.styled";

export function ChevronUpIcon({
  style = { height: "24px" },
  fill = "currentColor",
  onClick,
}: {
  style?: React.CSSProperties;
  fill?: string;
  onClick?: () => void;
} = {}) {
  return (
    <IconContainer style={style} onClick={onClick}>
      <svg
        fill="none"
        height="24"
        style={style}
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.2966 8.99953L6.70656 13.5895C6.31656 13.9795 6.31656 14.6095 6.70656 14.9995C7.09656 15.3895 7.72656 15.3895 8.11656 14.9995L12.0066 11.1195L15.8866 14.9995C16.2766 15.3895 16.9066 15.3895 17.2966 14.9995C17.6866 14.6095 17.6866 13.9795 17.2966 13.5895L12.7066 8.99953C12.3266 8.60953 11.6866 8.60953 11.2966 8.99953Z"
          fill={fill}
        />
      </svg>
    </IconContainer>
  );
}
