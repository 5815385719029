import { Box } from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeGrid } from "react-window";

type VirtualizedListProps<T> = {
  items: T[];
  cellWidth: number;
  rowHeight: number;
  children: (index: number) => JSX.Element;
};

export function VirtualizedList<T = unknown>(props: VirtualizedListProps<T>) {
  const { items, cellWidth, rowHeight, children } = props;

  function Cell(columnCount: number) {
    return function InnerCell({
      columnIndex,
      rowIndex,
      style,
    }: {
      columnIndex: number;
      rowIndex: number;
      style: React.CSSProperties;
    }) {
      const imageIndex = rowIndex * columnCount + columnIndex;
      if (!items[imageIndex]) {
        return <></>;
      }
      return (
        <Box component="div" style={style}>
          {children(imageIndex)}
        </Box>
      );
    };
  }

  function renderCell(size: { width?: number; height?: number }) {
    const { width, height } = size;
    const columnCount = Math.floor((width ?? 1) / cellWidth);

    return (
      <FixedSizeGrid
        columnCount={columnCount}
        columnWidth={cellWidth}
        height={height ?? 0}
        rowCount={Math.ceil(items.length / columnCount)}
        rowHeight={rowHeight}
        width={width ?? 0}
      >
        {Cell(columnCount)}
      </FixedSizeGrid>
    );
  }

  return <AutoSizer>{renderCell}</AutoSizer>;
}
