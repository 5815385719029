import { useState } from "react";
import {
  Dialog as BaseDialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
} from "@mui/material";
import { PageIndicator } from "components/DialogPageIndicator";

export type TutorialDialogPage = {
  title: string;
  content: JSX.Element;
};

export function TutorialDialog({
  onClose,
  pages,
  width,
}: {
  onClose: () => void;
  pages?: Array<TutorialDialogPage>;
  width?: string;
}) {
  const [currentPage, setCurrentPage] = useState(0);

  return (
    <BaseDialog
      PaperProps={{
        style: {
          display: "flex",
          flexDirection: "column",
          width: width || "880px",
          minHeight: "680px",
        },
      }}
      maxWidth={false}
      open={true}
      scroll="paper"
      onClose={onClose}
    >
      {pages && pages[currentPage].title && (
        <DialogTitle fontWeight="600">{pages[currentPage].title}</DialogTitle>
      )}

      <DialogContent sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        {pages && pages[currentPage].content}
      </DialogContent>

      <DialogActions disableSpacing>
        <Box component="div" p={2} width="100%">
          <PageIndicator
            currentPage={currentPage}
            numberOfPages={pages?.length || 0}
            onCloseDialog={onClose}
            onPageChangeCallback={(i: number) => setCurrentPage(i)}
          />
        </Box>
      </DialogActions>
    </BaseDialog>
  );
}
