import React from "react";
import { v4 } from "uuid";
import { useGetRangeOfMotionCurve } from "features/JobBuilder/CamerasAndLightsPreview/hooks/useGetRangeOfMotionCurve";
import { useRigLocation } from "features/JobBuilder/CamerasAndLightsPreview/hooks/useRigLocation";
import { JsonRig } from "features/JobBuilder/json-types";
import { Camera } from "./Camera";
import { Light } from "./Light";

interface RigProps {
  data: JsonRig;
}

export function Rig({ data }: RigProps) {
  const { cameras, lights } = data;
  const { origin, position, rotation } = useRigLocation(data, "mean");

  const rangeOfMotionCurves = useGetRangeOfMotionCurve(data);

  return (
    <>
      {rangeOfMotionCurves.map((curve) => (
        <primitive key={v4()} object={curve} />
      ))}

      <group position={origin} rotation={rotation}>
        <group position={position}>
          <mesh>
            <boxGeometry args={[1, 0.05, 0.15]} />
            <meshStandardMaterial />
          </mesh>

          {/* Cameras */}
          {cameras?.map((camera) => (
            <Camera key={v4()} data={camera} />
          ))}

          {/* Lights */}

          {lights?.map((light) => (
            <Light key={v4()} data={light} />
          ))}
        </group>
      </group>
    </>
  );
}
