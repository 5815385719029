import { SetState } from "store/_boundStore";
import type { State } from ".";

export function createActions(set: SetState) {
  return {
    setStoreValues: (values: State) =>
      set((state) => {
        state.catalogData = { ...state.catalogData, ...values };
      }),
  };
}
