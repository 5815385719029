import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(Typography)`
  padding-top: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
`;

export const Description = styled(Typography)`
  padding-top: 4px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;
