import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import * as Icons from "assets/icons";
import { colors } from "components/App/theme";
import { disableBackdropClickClose } from "services/ui-service";
import { IconsType } from "types/main";
import {
  ActionsWrapper,
  StyledDialogContent,
  DeleteButton,
  CancelButton,
} from "./warningDialog.styled";

export interface WarningDialogBaseProps {
  onCancel?: () => void;
  onConfirm: (e: React.MouseEvent<any>) => void;
  textContent: string;
}

export function WarningDialog({
  icon,
  onCancel,
  onCancelButtonText,
  onConfirm,
  textContent,
  onConfirmButtonText,
  hideCancelButton,
}: WarningDialogBaseProps & {
  icon: IconsType;
  onCancelButtonText?: string;
  onConfirmButtonText: string;
  hideCancelButton?: boolean;
}) {
  const iconStyle = {
    transform: "scale(1.8)",
    height: "36px",
    color: colors.red,
  };

  const DialogIcon = Icons[icon];

  return (
    <Dialog
      disableEscapeKeyDown
      open
      onClose={(e, reason) => disableBackdropClickClose(reason, null)}
    >
      <DialogTitle>
        <Box
          alignItems="center"
          component="div"
          display="flex"
          justifyContent="center"
        >
          <Box component="div" mt="8px">
            <DialogIcon style={iconStyle} />
          </Box>
        </Box>
      </DialogTitle>
      <StyledDialogContent>
        <Box component="div">
          <Typography m="0" variant="body2">
            {textContent}
          </Typography>
        </Box>
      </StyledDialogContent>
      <DialogActions>
        <ActionsWrapper mb={1} ml={2} mr={2}>
          <DeleteButton
            autoFocus
            color="warning"
            variant="contained"
            onClick={onConfirm}
          >
            {onConfirmButtonText}
          </DeleteButton>
          {!hideCancelButton && (
            <CancelButton
              color="secondary"
              variant="contained"
              onClick={onCancel}
            >
              {onCancelButtonText}
            </CancelButton>
          )}
        </ActionsWrapper>
      </DialogActions>
    </Dialog>
  );
}
