import { CameraPresetLocation } from "features/JobBuilder2/steps/threeDimensional/CameraPresetLocation";
import { useBoundStore } from "store/_boundStore";
import { selectPL2Location } from "store/selectors/selectPL2Location";

export function RigLocation3dStep() {
  const location = useBoundStore(selectPL2Location);
  return (
    <>
      {location.camera_preset_locations.map((presetLocation, index) => (
        <CameraPresetLocation
          key={index}
          interactive
          index={index}
          preset={presetLocation}
        />
      ))}
    </>
  );
}
