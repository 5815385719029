import React, { useCallback } from "react";
import { Add, DeleteOutline, Settings, Videocam } from "@mui/icons-material";
import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { colors } from "components/App/theme";
import { defaultSubjob } from "features/JobBuilder2/types";

export function LeftNavigation({
  activeView,
  openRigSettings,
  openCameraSettings,
  cameras,
  onAddCamera,
  onDeleteCamera,
}: {
  activeView: number;
  openRigSettings: () => void;
  openCameraSettings: (i: number) => () => void;
  cameras: typeof defaultSubjob.rig.cameras;
  onAddCamera: () => void;
  onDeleteCamera: (i: number) => () => void;
}) {
  const handleDelete = useCallback(
    (i: number) => (e: React.MouseEvent) => {
      e.stopPropagation();
      onDeleteCamera(i)();
    },
    [onDeleteCamera]
  );

  const rigSettingsViewActive = activeView === 0;

  return (
    <Drawer
      anchor="left"
      sx={{
        width: 300,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          position: "relative",
          width: 300,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
    >
      <List>
        <ListItem
          disablePadding
          sx={{
            backgroundColor: rigSettingsViewActive
              ? colors.tint5
              : "transparent",
          }}
        >
          <ListItemButton onClick={openRigSettings}>
            <ListItemIcon
              sx={{
                width: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: rigSettingsViewActive ? colors.bright[2] : colors.grey50,
              }}
            >
              <Settings />
            </ListItemIcon>
            <ListItemText
              primary="Rig Settings"
              sx={{
                ml: 1,
                fontWeight: activeView === 0 ? 600 : "normal",
                color: rigSettingsViewActive ? colors.bright[2] : colors.grey50,
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemIcon
            sx={{
              width: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Videocam />
          </ListItemIcon>
          <ListItemText primary="Cameras" sx={{ ml: 1 }} />
          <Button
            color="warning"
            sx={{ ml: "auto" }}
            variant="text"
            onClick={onAddCamera}
          >
            <Add />
          </Button>
        </ListItem>
        {cameras.map((camera, index) => {
          const isActive = activeView === index + 1;

          return (
            <ListItem
              key={`${camera.name}-${index}`}
              disablePadding
              sx={{
                backgroundColor: isActive ? colors.tint5 : "transparent",
              }}
            >
              <ListItemButton
                sx={{ pl: 5 }}
                onClick={openCameraSettings(index + 1)}
              >
                <ListItemIcon
                  sx={{
                    width: 24,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: isActive ? colors.bright[2] : colors.grey50,
                  }}
                >
                  <Videocam />
                </ListItemIcon>
                <ListItemText
                  primary={camera.name}
                  primaryTypographyProps={{
                    color: isActive ? colors.bright[2] : "inherit",
                  }}
                />
                {cameras.length > 1 && (
                  <ListItemIcon
                    sx={{
                      width: 24,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      color="error"
                      sx={{ ml: "auto" }}
                      variant="text"
                      onClickCapture={handleDelete(index)}
                    >
                      <DeleteOutline />
                    </Button>
                  </ListItemIcon>
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
}
