import { useState } from "react";
import { ManageAccountsOutlined, SupervisorAccount } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { colors } from "components/App/theme";
import { DialogHeaderIcon } from "components/DialogHeaderIcon";
import { PUBLIC_S3_BUCKET } from "constants/constants";
import { PrefabGroupMap } from "domain/Prefabs";
import { disableBackdropClickClose } from "services/ui-service";
import { formatDateWithoutTime } from "utils/dateUtils";

const getImagePath = (jobID: string) => (index: number) =>
  `${PUBLIC_S3_BUCKET}/pl2/characters/${jobID}/${index}.png`;

export function CharacterGroupPickerDialog({
  selectedPrefabGroupId,
  onCancel,
  onConfirm,
  prefabGroups,
}: {
  selectedPrefabGroupId: string;
  onCancel: () => void;
  onConfirm: (v: string) => void;
  prefabGroups: PrefabGroupMap;
}) {
  const [selected, setSelected] = useState(selectedPrefabGroupId);

  const handleSelect = (i: string) => () => {
    setSelected(i);
  };

  const handleKeyDown = (i: string) => (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      setSelected(i);
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open
      data-testid="confirmation-dialog"
      maxWidth="lg"
      onClose={(e, reason) => disableBackdropClickClose(reason, null)}
    >
      <DialogTitle borderBottom={`1px solid ${colors.grey20}`}>
        <Box alignItems="flex-start" component="div" display="flex">
          <Box component="div" flexGrow={1}>
            <Box component="div" display="flex">
              <DialogHeaderIcon>
                <SupervisorAccount />
              </DialogHeaderIcon>
              Character Groups
            </Box>
          </Box>
          <Box component="div" display="flex" flexGrow={0}>
            <CloseIcon onClick={onCancel} />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Box component="div" display="flex" sx={{ minWidth: 812 }}>
          {/* Sidebar */}
          <Box
            borderRight={`1px solid ${colors.grey20}`}
            component="div"
            width="310px"
          >
            <Box
              alignItems="center"
              component="div"
              display="flex"
              p={2}
              sx={{
                backgroundColor: colors.tint5,
                color: colors.bright[2],
                fontSize: 16,
              }}
            >
              <Box
                alignItems="center"
                component="div"
                display="flex"
                height={1}
                mr={1}
              >
                <ManageAccountsOutlined />
              </Box>
              Synthesis AI Pre-fab Characters
            </Box>
          </Box>
          {/* Content */}
          <Box
            component="div"
            display="flex"
            flex={1}
            flexWrap="wrap"
            minHeight="500px"
            minWidth="872px"
            p={2}
          >
            {Object.keys(prefabGroups).map((id, i) => (
              <Box
                key={i}
                component="div"
                display="flex"
                mb={2}
                mr={2}
                p={1}
                role="button"
                sx={{
                  width: "420px",
                  height: "140px",
                  background: selected === id ? colors.tint5 : colors.white,
                  borderRadius: "8px",
                  border:
                    selected === id
                      ? `1px solid ${colors.bright[2]}`
                      : `1px solid ${colors.grey20}`,

                  boxShadow:
                    selected === id
                      ? `0px 0px 0px 1px ${colors.bright[2]}`
                      : "none",
                  fontSize: 16,
                  cursor: "pointer",
                  position: "relative",
                }}
                tabIndex={0}
                onClick={handleSelect(id)}
                onKeyDown={handleKeyDown(id)}
              >
                <Box
                  alignItems="center"
                  borderRadius="10px"
                  component="div"
                  display="flex"
                  height="20px"
                  justifyContent="center"
                  position="absolute"
                  sx={{
                    top: "12px",
                    left: "12px",
                    backgroundColor: colors.bright[2],
                    color: colors.white,
                  }}
                  width="40px"
                >
                  {prefabGroups[id].characters.length}
                </Box>
                <Box component="div" flexShrink={0}>
                  <div
                    aria-label="Character 1"
                    style={{
                      flex: 1,
                      width: "122px",
                      height: "100%",
                      backgroundColor: colors.grey20,
                      backgroundImage: `url(${getImagePath(
                        prefabGroups[id].job_id
                      )(0)})`,
                      borderRadius: "8px",
                      backgroundSize: "cover",
                    }}
                  />
                </Box>
                <Box
                  component="div"
                  display="flex"
                  flexDirection="column"
                  flexShrink={0}
                  pl={0.5}
                  pr={2}
                  width="56px"
                >
                  {[1, 2, 3].map((i) => (
                    <div
                      key={i}
                      aria-label={`Character ${i + 1}`}
                      style={{
                        flex: 1,
                        width: "36px",
                        height: "36px",
                        marginBottom: i !== 3 ? "4px" : "0px",
                        flexShrink: 0,
                        backgroundColor: colors.grey20,
                        borderRadius: "8px",
                        backgroundImage: `url(${getImagePath(
                          prefabGroups[id].job_id
                        )(i)})`,
                        backgroundSize: "cover",
                      }}
                    />
                  ))}
                </Box>
                <Box
                  component="div"
                  display="flex"
                  flex={3}
                  flexDirection="column"
                >
                  <Box component="div" flex="1">
                    <Typography variant="h4">
                      <strong>{prefabGroups[id].name}</strong>
                    </Typography>
                    <Typography
                      sx={{ fontWeight: "bold", fontSize: 16 }}
                      variant="body1"
                    >
                      {prefabGroups[id].characters.length} character
                      {prefabGroups[id].characters.length === 1 ? "" : "s"}
                    </Typography>
                  </Box>
                  <Box component="div">
                    <Box component="div" display="flex">
                      <Typography
                        color="text.secondary"
                        sx={{ fontSize: 16 }}
                        variant="body1"
                        width="96px"
                      >
                        Created by
                      </Typography>
                      <Typography sx={{ fontSize: 16 }} variant="body1">
                        User Id:{prefabGroups[id].user_id}
                      </Typography>
                    </Box>

                    <Box component="div" display="flex">
                      <Typography
                        color="text.secondary"
                        sx={{ fontSize: 16 }}
                        variant="body1"
                        width="96px"
                      >
                        Created on
                      </Typography>
                      <Typography sx={{ fontSize: 16 }} variant="body1">
                        {formatDateWithoutTime(prefabGroups[id].createdAt)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${colors.grey20}` }}>
        <Box
          component="div"
          mb={1}
          ml={2}
          mr={2}
          textAlign="right"
          width="100%"
        >
          <Button
            autoFocus
            color="warning"
            sx={{ mr: 2 }}
            variant="outlined"
            onClick={() => onCancel()}
          >
            Cancel changes
          </Button>
          <Button
            autoFocus
            color="warning"
            variant="contained"
            onClick={() => onConfirm(selected)}
          >
            Confirm
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
