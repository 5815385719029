import { IconContainer } from "./icons.styled";

export function DeleteIcon({
  fill = "currentColor",
  style = { height: "24px" },
  onClick,
}: {
  style?: React.CSSProperties;
  fill?: string;
  onClick?: (e: React.MouseEvent<any>) => void;
}) {
  return (
    <IconContainer onClick={onClick}>
      <svg
        fill="none"
        height="24"
        style={style}
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.2929 4.5L15.1464 4.35355L14.4364 3.64355M15.2929 4.5L14.4364 3.64355M15.2929 4.5H15.5M15.2929 4.5H15.5M14.4364 3.64355C14.3491 3.55625 14.2159 3.5 14.09 3.5H9.91C9.78411 3.5 9.65086 3.55625 9.56355 3.64355L9.21 3.29M14.4364 3.64355L9.21 3.29M9.21 3.29L9.56355 3.64355L8.85355 4.35355L8.70711 4.5H8.5H6C5.72614 4.5 5.5 4.72614 5.5 5C5.5 5.27386 5.72614 5.5 6 5.5H18C18.2739 5.5 18.5 5.27386 18.5 5C18.5 4.72614 18.2739 4.5 18 4.5H15.5M9.21 3.29L15.5 4.5M8 20.5C7.17614 20.5 6.5 19.8239 6.5 19V9C6.5 8.17614 7.17614 7.5 8 7.5H16C16.8239 7.5 17.5 8.17614 17.5 9V19C17.5 19.8239 16.8239 20.5 16 20.5H8ZM15 8.5H9C8.17386 8.5 7.5 9.17386 7.5 10V18C7.5 18.8261 8.17386 19.5 9 19.5H15C15.8261 19.5 16.5 18.8261 16.5 18V10C16.5 9.17386 15.8261 8.5 15 8.5Z"
          fill={fill}
          stroke={fill}
        />
      </svg>
    </IconContainer>
  );
}
