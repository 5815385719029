import { Typography } from "@mui/material";
import rigImage from "components/dialogs/StageElementDialog/assets/rig.svg";
import {
  AnimatedImage,
  CameraTipWrapper,
  ContentWrapper,
  RigTipWrapper,
  DialogParagraph,
} from "components/dialogs/StageElementDialog/StageElementDialog.styled";
import { Messages } from "constants/messages";

export function Rig() {
  return (
    <ContentWrapper>
      <RigTipWrapper current>
        <Typography color="white">Rig</Typography>
        <DialogParagraph>{Messages.stageElementStep1Rig}</DialogParagraph>
      </RigTipWrapper>

      <CameraTipWrapper>
        <Typography color="white">Camera</Typography>
        <DialogParagraph>{Messages.stageElementStep1Camera}</DialogParagraph>
      </CameraTipWrapper>
      <AnimatedImage src={rigImage} />
    </ContentWrapper>
  );
}
