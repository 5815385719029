import { Button, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const Caption = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  color: ${colors.grey70};
  line-height: 24px;
`;

export const TabButton = styled(Button)<{ selected: boolean }>`
  ${({ selected }) => `
    color: ${selected ? colors.orange : colors.grey70};
    border-color: ${selected ? colors.orange : colors.grey70};
    background-color: ${selected ? colors.tint5 : "transparent"};
  `}
  font-weight: 600;
`;

export const TotalLabel = styled(Typography)`
  font-size: 14px;
  text-align: end;
`;

export const Bold = styled.span`
  font-weight: 600;
`;
