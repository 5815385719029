export function MasksIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 9.5C12.3188 9.5 12.578 9.56076 12.8268 9.6634C12.5826 9.56574 12.3142 9.5 12 9.5ZM12 9.5C11.6007 9.5 11.2907 9.59536 10.9764 9.74868C11.2765 9.60443 11.6034 9.5 12 9.5ZM16.8915 8.7832L17.4675 8.93641L17.5182 8.34248C17.6068 7.30309 18.4578 6.5 19.5 6.5C20.6039 6.5 21.5 7.39614 21.5 8.5V9C21.5 11.8952 20.635 13.7067 19.4874 14.8169C18.3308 15.9357 16.8267 16.4007 15.4509 16.4808L15.3217 16.4884L15.2124 16.5577C14.2771 17.1503 13.1792 17.5 12 17.5C10.8208 17.5 9.72285 17.1503 8.78763 16.5577L8.67829 16.4884L8.54906 16.4808C7.17328 16.4007 5.66922 15.9357 4.51265 14.8169C3.36504 13.7067 2.5 11.8952 2.5 9V8.5C2.5 7.39614 3.39614 6.5 4.5 6.5C5.54073 6.5 6.39322 7.31161 6.48184 8.34281L6.53284 8.93631L7.10851 8.7832C7.92023 8.56732 8.58571 8.20598 9.18038 7.88309C9.32549 7.80429 9.46639 7.72779 9.60417 7.65624C10.3171 7.28606 11.0191 7 12 7C12.9757 7 13.6777 7.28589 14.3924 7.6564C14.5301 7.7278 14.671 7.80412 14.816 7.8827C15.4123 8.20578 16.0795 8.56724 16.8915 8.7832ZM6.29839 15.433L7.82735 16.1472L6.93424 14.7154C6.34985 13.7785 6 12.6793 6 11.5V8.5C6 7.67386 5.32614 7 4.5 7C3.67386 7 3 7.67386 3 8.5V9C3 10.8926 3.38656 12.3032 4.00476 13.3472C4.62493 14.3946 5.45932 15.0411 6.29839 15.433ZM6.89942 9.36022L6.5 9.44225V9.85V11.5C6.5 14.5348 8.96256 17.01 12 17.01C15.0361 17.01 17.5 14.5461 17.5 11.51V9.86V9.45579L17.1048 9.3711C16.0623 9.14772 15.2879 8.73019 14.5807 8.34176L14.5805 8.34167C14.561 8.33095 14.5416 8.32026 14.5222 8.30961C13.7347 7.87711 13.0481 7.5 12 7.5C10.9665 7.5 10.2837 7.86704 9.51142 8.28223C9.48228 8.2979 9.45301 8.31363 9.42359 8.32942L9.42358 8.3294L9.41928 8.33176L9.41142 8.33608C8.70238 8.72555 7.93361 9.14784 6.89942 9.36022ZM17.0677 14.7124L16.1525 16.1566L17.7016 15.433C18.5407 15.0411 19.3751 14.3946 19.9952 13.3472C20.6134 12.3032 21 10.8926 21 9V8.5C21 7.67386 20.3261 7 19.5 7C18.6739 7 18 7.67386 18 8.5V11.5C18 12.6818 17.6588 13.7795 17.0677 14.7124Z"
        fill={fill}
        stroke={fill}
      />
    </svg>
  );
}
