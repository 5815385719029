import { useMemo } from "react";
import { negate } from "lodash";
import mirroredDiagram from "assets/diagrams/animations-mirrored.svg";
import singleKeyframeDiagram from "assets/diagrams/animations-single-keyframe.svg";
import { AnimationsIcon } from "assets/icons";
import { BooleanSelector } from "components/BooleanSelector";
import { ProTip } from "components/ProTip";
import { RightAsidePreviewFull } from "components/RightAsidePreviewFull";
import { PreviewContent } from "components/RightAsidePreviewFull/PreviewContent";
import { SelectorsPicker } from "components/SelectorsPicker";
import { PUBLIC_S3_BUCKET } from "constants/constants";
import { Messages } from "constants/messages";
import { defaultAnimationsSection, ThreeDLocationType } from "domain/Human";
import { EmptyState } from "features/JobBuilder/empty-state";
import { convertAnimationsStateToJson } from "features/JobBuilder/form-to-input-json";
import {
  getHumanObject,
  getAnimationsList,
} from "features/JobBuilder/json-extracter";
import { JsonManagerNewUI } from "features/JobBuilder/json-manager-new-ui";
import { convertAnimationsList } from "features/JobBuilder/json-to-form";
import { JsonHuman } from "features/JobBuilder/json-types";
import { ContentCard, Page } from "features/JobBuilder/layout";
import { MultiOptionImageGrid } from "features/JobBuilder/MultiOptionImageGrid";
import {
  allGesturesSelector,
  selectActiveSubjobAnimations,
  selectNumberOfImagesForAllSubjobsAsString,
  selectPickedSubjob,
  selectSelectedAnimationsGroupIndex,
} from "features/JobBuilder/store";
import { useDistPage } from "features/JobBuilder/useDistPage";
import { useImageSelection } from "features/JobBuilder/useImageSelection";
import { changeCheckEventValue, summingTo100 } from "features/JobBuilder/utils";
import { useBoundStore } from "store/_boundStore";
import { addSIfGreaterThan1 } from "utils/stringUtil";
import positionJitterDice from "./position_jitter_dice.svg";

const id = "gesture";

export function JobBuilderAnimations() {
  const [setErrorMessage, setSuccessMessage, gesturesData] = useBoundStore(
    (s) => [
      s.message.setErrorMessage,
      s.message.setSuccessMessage,
      s.jobBuilder.gestures,
    ]
  );

  const { onUpdate, onUpdatePart, onUpdatePercent, onAdd, toggleDistListItem } =
    useDistPage(id);

  const activeSubjob = useBoundStore(selectPickedSubjob);
  const gesturesSpecArray = useBoundStore(selectActiveSubjobAnimations);
  const selectedGroupIndex = useBoundStore(selectSelectedAnimationsGroupIndex);
  const numberOfImages = useBoundStore(
    selectNumberOfImagesForAllSubjobsAsString
  );
  const isSummingTo100 = summingTo100(gesturesSpecArray);

  const selectedAnimations = gesturesSpecArray[selectedGroupIndex];
  const onUpdateMirrored = onUpdatePart("mirrored");
  const onUpdateSingleStylePerId = onUpdatePart("keyframe_only");
  const onUpdatePositionSeed = onUpdatePart("position_seed");

  const filteredNamesByLocationType = useMemo(() => {
    const isVehicleEnv =
      activeSubjob.threeDLocation === ThreeDLocationType.vehicle;
    return Object.keys(gesturesData).filter(
      (ges) => gesturesData[ges].car_ready == isVehicleEnv
    );
  }, [gesturesData, activeSubjob.threeDLocation]);

  const animationsUiState = useImageSelection(
    selectedAnimations ? selectedAnimations.name : [],
    filteredNamesByLocationType,
    (gestureName) => `${PUBLIC_S3_BUCKET}/pl1/gestures/${gestureName}.png`,
    onUpdatePart("name"),
    "Animation Name",
    "animation-name",
    "AnimationNameIcon",
    toggleDistListItem("name", filteredNamesByLocationType),
    (gestureName) =>
      gesturesData[gestureName]
        ? addSIfGreaterThan1(
            gesturesData[gestureName].output_frame_length,
            "frame"
          )
        : undefined
  );

  const onIndecesReceived = (indices: number[], humansArr: JsonHuman[]) => {
    try {
      const humanObject = getHumanObject(humansArr, indices[0]);
      const jsonAnimationsList = getAnimationsList(humanObject);
      onUpdate(convertAnimationsList(jsonAnimationsList, gesturesSpecArray));
      setSuccessMessage("updated animations section");
    } catch (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
      }
    }
  };

  // TODO: Type this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDoneJson = (json: any) => {
    const obj = convertAnimationsList(json, gesturesSpecArray);
    onUpdate(obj);
  };

  const jsonValue = useMemo(
    () => convertAnimationsStateToJson(gesturesSpecArray),
    [gesturesSpecArray]
  );
  const isEmptyState = !selectedAnimations;
  const center = (
    <JsonManagerNewUI
      key={`animationsGroup-${selectedGroupIndex}`}
      emptyState={
        isEmptyState ? (
          <EmptyState
            description="Animations controls the animations variations of the character models. Default animations variations are applied, some sample results are shown below:"
            folder="gestures"
            icon={<AnimationsIcon style={{ width: 48, height: 48 }} />}
            title="Default animations settings applied"
          />
        ) : null
      }
      groupIndex={selectedGroupIndex + 1}
      groupPercentage={selectedAnimations?.percent}
      id={`animationsGroup-${selectedGroupIndex}`}
      isSummingTo100={isSummingTo100}
      jsonValue={jsonValue}
      numberOfImages={numberOfImages}
      title={`Animations`}
      titleCount={selectedAnimations?.name.length}
      titleIcon="AnimationsIcon"
      unknownIndicesKeys={["Human"]}
      onClickAddButton={onAdd}
      onDoneEditJson={onDoneJson}
      onIndecesReceived={onIndecesReceived}
      onUpdatePercent={onUpdatePercent}
    >
      <ContentCard>
        <ProTip label={Messages.animationsProTip} />
        <MultiOptionImageGrid {...animationsUiState} />
      </ContentCard>

      <ContentCard style={{ padding: "8px" }}>
        <BooleanSelector
          noSpacing
          description={Messages.singleStylePerIdForAnimations}
          icon={"PersonIcon"}
          imgSrc={singleKeyframeDiagram}
          label={"Enabled"}
          title={"Single Still Image"}
          value={selectedAnimations?.keyframe_only}
          onUpdate={changeCheckEventValue(onUpdateSingleStylePerId)}
        />
      </ContentCard>

      <ContentCard style={{ padding: "8px" }}>
        <BooleanSelector
          noSpacing
          description={Messages.mirroredForAnimations}
          icon={"MirroredIcon"}
          imgSrc={mirroredDiagram}
          label={"Enabled"}
          title={"Mirrored"}
          value={selectedAnimations?.mirrored}
          onUpdate={changeCheckEventValue(onUpdateMirrored)}
        />
      </ContentCard>

      <ContentCard style={{ padding: "8px" }}>
        <SelectorsPicker
          noSpacing
          icon={"RadarIcon"}
          imgSrc={positionJitterDice}
          limits={defaultAnimationsSection.position_seed}
          lorValue={selectedAnimations?.position_seed}
          title="Position Jitter"
          unit={""}
          onUpdate={onUpdatePositionSeed}
        />
      </ContentCard>
    </JsonManagerNewUI>
  );
  const rightAside = (
    <RightAsidePreviewFull previewTitle="Preview">
      <PreviewContent data={animationsUiState.selectedImages} title="PREVIEW" />
    </RightAsidePreviewFull>
  );
  return (
    <Page
      fixedRightSide
      center={center}
      hideRightAside={isEmptyState}
      rightAside={rightAside}
    />
  );
}
