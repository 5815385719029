export function FatContentIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 5V4.5H19H5H4.5V5V19V19.5H5H19H19.5V19V5ZM9.5 13.5V14V17.5H7.5V14V13.5H7H6.5V11.5C6.5 10.6761 7.17614 10 8 10H9C9.82386 10 10.5 10.6761 10.5 11.5V13.5H10H9.5ZM5 3.5H19C19.8239 3.5 20.5 4.17614 20.5 5V19C20.5 19.8239 19.8239 20.5 19 20.5H5C4.17614 20.5 3.5 19.8239 3.5 19V5C3.5 4.17614 4.17614 3.5 5 3.5ZM9.25 7.25C9.25 7.66386 8.91386 8 8.5 8C8.08614 8 7.75 7.66386 7.75 7.25C7.75 6.83614 8.08614 6.5 8.5 6.5C8.91386 6.5 9.25 6.83614 9.25 7.25ZM13.9021 10.5L15.5 7.9434L17.0979 10.5H13.9021ZM17.0979 13.5L15.5 16.0566L13.9021 13.5H17.0979Z"
        stroke="#9A9A9B"
      />
    </svg>
  );
}
