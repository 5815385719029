import React, { useEffect, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes as ReactRouterRoutes,
  useLocation,
} from "react-router-dom";
import { Showcase } from "components/design-system/Showcase";
import { Routes } from "constants/routes";
import { useIsAuthenticated } from "hooks/useIsAuthenticated";
import { useIsSupportUser } from "hooks/useIsSupportUser";
import { AcceptInvite } from "pages/AcceptInvite";
import { AccountInfo } from "pages/AccountInfo";
import { AccountVerified } from "pages/AccountVerified";
import { AddPaymentInfo } from "pages/AddPaymentInfo";
import { AddUser } from "pages/AddUser";
import { ChangePassword } from "pages/ChangePassword";
import { CharactersJobBuilder } from "pages/CharactersJobBuilder";
import { CreateJob } from "pages/CreateJob";
import { EditPaymentInfo } from "pages/EditPaymentInfo";
import { FirstOrg } from "pages/FirstOrg";
import { Home } from "pages/Home";
import { HumansJobBuilder } from "pages/HumansJobBuilder";
import { Jobs } from "pages/Jobs";
import { JobsTutorial } from "pages/JobsTutorial";
import { JobTemplateCategory } from "pages/JobTemplateCategory";
import { Login } from "pages/Login";
import { ManageApiKeys } from "pages/ManageApiKeys";
import { NewAccount } from "pages/NewAccount";
import { NewApiKey } from "pages/NewApiKey";
import { NewOrg } from "pages/NewOrg";
import { NotFound } from "pages/NotFound";
import { ResendActivationEmail } from "pages/ResendActivationEmail";
import { ResetPassword } from "pages/ResetPassword";
import { ScenariosJobBuilder } from "pages/ScenariosJobBuilder";
import { SendPasswordResetEmail } from "pages/SendPasswordResetEmail";
import { Settings } from "pages/Settings";
import { Team } from "pages/Team";
import { UpdateEmailReply } from "pages/UpdateEmailReply";
import { useBoundStore } from "store/_boundStore";

type Routes = Array<[string, React.FunctionComponent]>;

const PUBLIC_ROUTES: Routes = [
  [Routes.LOGIN, Login],
  [Routes.NEW_ACCOUNT, NewAccount],
  [Routes.UPDATE_EMAIL_REPLY, UpdateEmailReply],
  [Routes.FORGOT_PASSWORD, SendPasswordResetEmail],
  [Routes.RESET_PASSWORD, ResetPassword],
  [Routes.ACCEPT_INVITE, AcceptInvite],
  [Routes.ACCOUNT_VERIFIED, AccountVerified],
  [Routes.RESEND_ACTIVATION_EMAIL, ResendActivationEmail],
];

const PROTECTED_ROUTES: Routes = [
  [Routes.ROOT, Home],
  [Routes.FIRST_ORG, FirstOrg],
  [Routes.JOBS_TUTORIAL, JobsTutorial],
  [Routes.CHANGE_PASSWORD, ChangePassword],
  [Routes.MANAGE_API_KEYS, ManageApiKeys],
  [Routes.NEW_API_KEY, NewApiKey],
  [Routes.JOBS, Jobs],
  [Routes.ACCOUNT_INFORMATION, AccountInfo],
  [Routes.ADD_PAYMENT_INFO, AddPaymentInfo],
  [Routes.EDIT_PAYMENT_INFO, EditPaymentInfo],
  [Routes.TEAM, Team],
  [Routes.SETTINGS, Settings],
  [Routes.ADD_USER, AddUser],
  [Routes.NEW_ORG, NewOrg],
  [Routes.CREATE_JOB, CreateJob],
  [Routes.JOB_BUILDER, HumansJobBuilder],
  [Routes.JOB_BUILDER2, ScenariosJobBuilder],
  [Routes.HUMAN_JOB, JobTemplateCategory],
  [Routes.HUMAN_JOB_TEMPLATES_CATEGORY, JobTemplateCategory],
  [Routes.HUMAN_JOB_TEMPLATES_SUBCATEGORY, JobTemplateCategory],
];

const SUPPORT_USER_ROUTES: Routes = [
  [Routes.DESIGN_SYSTEM_SHOWCASE, Showcase],
  [Routes.CHARACTERS_BUILDER, CharactersJobBuilder],
];

const ELIGIBLE_FOR_REDIRECT_AFTER_SIGN_IN_ROUTES = new Set([
  Routes.MANAGE_API_KEYS,
  Routes.HUMAN_JOB,
]);

export function Pages() {
  const {
    profile: { setLoginRedirect, data: profile, getUserProfile },
  } = useBoundStore((state) => state);

  const locationPathname = useLocation().pathname;
  const isAuthenticated = useIsAuthenticated();
  const isSupportUser = useIsSupportUser();

  useEffect(() => {
    if (isAuthenticated) {
      getUserProfile();
    }
  }, [isAuthenticated, getUserProfile]);

  const routes = useMemo(() => {
    if (!isAuthenticated) {
      return PUBLIC_ROUTES;
    }

    const routes = [...PUBLIC_ROUTES, ...PROTECTED_ROUTES];

    if (isSupportUser) {
      routes.push(...SUPPORT_USER_ROUTES);
    }

    return routes;
  }, [isAuthenticated, isSupportUser]);

  if (isAuthenticated && !profile) {
    return null;
  }

  if (
    !isAuthenticated &&
    PROTECTED_ROUTES.some(([pathName]) => pathName === locationPathname)
  ) {
    if (ELIGIBLE_FOR_REDIRECT_AFTER_SIGN_IN_ROUTES.has(location.pathname)) {
      setLoginRedirect(location);
    }

    return <Navigate to={Routes.LOGIN} />;
  }

  return (
    <ReactRouterRoutes>
      {routes.map(([path, Component], i) => (
        <Route key={i} element={<Component />} path={path} />
      ))}
      <Route element={<NotFound />} path="*" />
    </ReactRouterRoutes>
  );
}
