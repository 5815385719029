import { useState } from "react";
import { CloseIcon, TipsAndUpdatesIcon } from "assets/icons";
import { colors } from "components/App/theme";
import {
  Container,
  Label,
  LearnMore,
  ProTipTitle,
  TipIconContainer,
} from "./ProTip.styled";
import { ProTipDisplayType } from "./types";

export function ProTip({
  label,
  dismissible = true,
  marginY = 2,
  onClickLearnMore,
  closeCallback,
}: {
  label: string;
  dismissible?: boolean;
  marginY?: number;
  onClickLearnMore?: () => void;
  closeCallback?: () => void;
}) {
  const [display, setDisplay] = useState<ProTipDisplayType>("flex");

  const closeProTip = () => {
    setDisplay("none");
    closeCallback && closeCallback();
  };

  return (
    <span>
      <Container $display={display} sx={{ marginY }}>
        <TipIconContainer>
          <TipsAndUpdatesIcon style={{ color: colors.orange }} />
        </TipIconContainer>
        <Label $dismissible={dismissible}>
          <ProTipTitle>ProTip:</ProTipTitle>
          {label}
          {onClickLearnMore && (
            <LearnMore onClick={onClickLearnMore}>Learn More</LearnMore>
          )}
        </Label>
        {dismissible && (
          <CloseIcon
            style={{
              color: colors.grey50,
              cursor: "pointer",
              position: "relative",
              top: 4,
            }}
            onClick={closeProTip}
          />
        )}
      </Container>
    </span>
  );
}
