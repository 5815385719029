import { ActivityZonesControlsMode } from "features/JobBuilder2/steps/threeDimensional/activity-zones-controls";
import { SliceType } from "store/_boundStore";
import { createActions } from "./actions";

export * from "./selectors";

export type CursorWithId = { cursor: string; id: string };

type State = {
  controlMode: ActivityZonesControlsMode;
  dragging: boolean;
  cursorStack: { id: string; cursor: string }[];
};

type Actions = {
  setControlMode: (mode: ActivityZonesControlsMode) => void;
  setDragging: (value: boolean) => void;
  appendToCursorStack: (value: CursorWithId) => void;
  removeFromCursorStack: (cursorId: string) => void;
};

export type UserControlsSlice = State & Actions;

const initialState: State = {
  controlMode: "draw",
  dragging: false,
  cursorStack: [],
};

export const createUserControlsSlice: SliceType<UserControlsSlice> = (set) => ({
  ...initialState,
  ...createActions(set),
});
