import React, { useMemo, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Button,
  Typography,
} from "@mui/material";
import produce from "immer";

export function IndicesPickerDialog({
  arr,
  onConfirm,
}: {
  arr: string[];
  onConfirm: (s: string[]) => void;
}) {
  const [values, setValues] = useState(arr.map((str) => "0"));
  const isFormValid = useMemo(
    () => values.filter((v) => v).length === values.length,
    [values]
  );

  const valueChange =
    (i: number) =>
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setValues(
        produce((d) => {
          d[i] = e.target.value;
        })
      );
    };

  return (
    <Dialog disableEscapeKeyDown open maxWidth="sm">
      <DialogContent>
        <Typography variant="body2">
          Please write indices for the following
        </Typography>
        {arr.map((str, i) => {
          const hasInvalidInputError = isNaN(parseInt(values[i]));

          return (
            <TextField
              key={i}
              InputLabelProps={{ shrink: true }}
              error={hasInvalidInputError}
              helperText={
                hasInvalidInputError ? "Please type a valid number" : ""
              }
              inputProps={{ min: 0 }}
              label={str}
              margin="normal"
              type="number"
              value={values[i]}
              variant="standard"
              onChange={valueChange(i)}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <Box
          component="div"
          mb={1}
          ml={2}
          mr={2}
          textAlign="right"
          width="100%"
        >
          <Button
            autoFocus
            color="warning"
            disabled={!isFormValid}
            variant="contained"
            onClick={() => onConfirm(values)}
          >
            Confirm
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
