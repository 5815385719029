import { useMemo } from "react";
import { Box, Slider } from "@mui/material";

export function AppSlider({
  value,
  min,
  max,
  onChange,
  onBlur,
}: {
  value: number;
  min: number;
  max: number;
  onChange: (e: Event, v: number | number[]) => void;
  onBlur: () => void;
}) {
  const marks = useMemo(() => {
    return [min, max].map((v) => ({
      value: v,
      label: v.toString(),
    }));
  }, [max, min]);

  return (
    <Box component="div" sx={{ mb: 2, mt: 5 }} width="300px">
      <Slider
        color={"warning" as any}
        getAriaLabel={() => "Range Selector"}
        marks={marks}
        max={max}
        min={min}
        step={1}
        value={value}
        valueLabelDisplay="on"
        onBlur={onBlur}
        onChange={onChange}
      />
    </Box>
  );
}
