import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { Canvas, events } from "@react-three/fiber";
import { CanvasContents } from "features/JobBuilder2/steps/threeDimensional/canvas-contents";
import { selectPickedSubjob } from "features/JobBuilder2/store";
import { Pipe2StepEnum } from "features/JobBuilder2/types";
import { useBoundStore } from "store/_boundStore";
import { ActivityZonesControls } from "./activity-zones-controls";

export function ThreeDimensional() {
  const [selectedStep, controlMode, setControlMode, pickedSubjob] =
    useBoundStore((s) => [
      s.jobBuilder2.selectedStep,
      s.userControls.controlMode,
      s.userControls.setControlMode,
      selectPickedSubjob(s),
    ]);

  useEffect(() => {
    // When the step changes, reset the control mode.
    if (selectedStep === Pipe2StepEnum.ActivityZones) {
      setControlMode("draw");
    } else {
      setControlMode("move");
    }
  }, [selectedStep, setControlMode]);

  return (
    <React.Suspense
      fallback={
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="warning" size="2rem" />
        </div>
      }
    >
      <div
        data-testid="three-dimensional"
        style={{
          flex: 1,
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            flexDirection: "column",
            zIndex: 555,
          }}
        >
          {selectedStep === Pipe2StepEnum.ActivityZones && <></>}
        </div>
        {selectedStep === Pipe2StepEnum.ActivityZones && (
          <ActivityZonesControls mode={controlMode} setMode={setControlMode} />
        )}
        {/* 3D canvas ThreeJS uses to draw the UI */}
        {pickedSubjob.location && (
          <Canvas events={events} style={{ borderRadius: "8px" }}>
            {/*
             * Everything from inside CanvasContents down has to be a canvas component e.g. mesh,
             * boxGeometry, etc or a component that returns a canvas component.
             */}
            <CanvasContents locationName={pickedSubjob.location} />
          </Canvas>
        )}
      </div>
    </React.Suspense>
  );
}
