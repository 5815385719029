import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const Container = styled(Box)<{ $backgroundColor: string }>`
  width: 100%;
  padding: 16px 16px 8px;

  border-radius: 8px;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

export const InfosConatiner = styled(Box)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: center;
`;

export const InformationRow = styled(Box)`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  gap: 20px;
`;

export const Label = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.grey70};
  white-space: nowrap;
  flex: 1;
`;

export const Value = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  color: ${colors.grey70};
`;
