import { createSelector } from "reselect";
import { DetailedEnums, ThreeDLocationType } from "domain/Human";
import {
  cameraAndLightNamesAndTypesFromAllRigs,
  getcameraAndLightNamesAndTypesFromOneRig,
} from "features/JobBuilder/utils";
import { BoundStore } from "store/_boundStore";
import { getNumberOfImagesString } from "utils/stringUtil";

const enums = (state: BoundStore) => state.jobBuilder.enums;

export const enumList = (key: keyof DetailedEnums) =>
  createSelector([enums], (enums) => (enums && enums[key] ? enums[key] : []));

const hairColorKey = "hair.color";

export const glassStylesSelector = enumList("glasses.style");
export const glassColorsSelector = enumList("glasses.color");
export const maskStylesSelector = enumList("mask.style");
export const maskVariantsSelector = enumList("mask.variant");
export const maskPositionsSelector = enumList("mask.position");
export const fatContentSelector = enumList("body.fat");
export const heightSelector = enumList("body.height");
export const expressionStyleSelector = enumList("expression.name");
export const eyebrowStyleSelector = enumList("eyebrows.style");
export const eyebrowColorSelector = enumList(hairColorKey);
export const hairStyleSelector = enumList("hair.style");
export const hairColorSelector = enumList(hairColorKey);
export const eyesIrisColorSelector = enumList("eyes.iris_color");
export const facialHairStyleSelector = enumList("facial_hair.style");
export const facialHairColorSelector = enumList(hairColorKey);
export const clothingOutfitSelector = enumList("clothing.outfit");
export const indoorHdrisSelector = enumList("indoor_hdris");
export const outdoorHdrisSelector = enumList("outdoor_hdris");
export const allHdrisSelector = createSelector(
  [indoorHdrisSelector, outdoorHdrisSelector],
  (indoor, outdoor) => [...indoor, ...outdoor]
);

export const headphonesStylesSelector = enumList("headphones.style");
export const headwearStylesSelector = enumList("headwear.style");

export const allGesturesSelector = (state: BoundStore) =>
  state.jobBuilder.gestures;

export const allGesturesNamesSelector = (state: BoundStore) =>
  Object.keys(state.jobBuilder.gestures);

export const selectSubjobs = (state: BoundStore) => state.jobBuilder.subjobs;

export const selectTemplate = (state: BoundStore) => state.jobBuilder.template;

export const selectPickedSubjobIndex = (state: BoundStore) =>
  state.jobBuilder.pickedSubjobIndex;

export const selectPickedSubjob = (state: BoundStore) =>
  state.jobBuilder.subjobs[state.jobBuilder.pickedSubjobIndex];

export const selectIsVehicleEnviroment = (state: BoundStore) => {
  const pickedSubjob = selectPickedSubjob(state);

  return pickedSubjob.threeDLocation === ThreeDLocationType.vehicle;
};

export const selectSelectedHumanObjectPart = (state: BoundStore) =>
  state.jobBuilder.selectedPart;

export const selectActiveSubjobDemographicBuilder = createSelector(
  [selectPickedSubjob],
  (subjob) => subjob.demographicBuilder
);

export const selectActiveSubjobIdentities = createSelector(
  [selectPickedSubjob],
  (subjob) => subjob.identities
);

export const selectActiveSubjobIdentitiesIds = createSelector(
  [selectActiveSubjobIdentities],
  (identities) => identities.ids
);

export const selectActiveSubjobRendersPerIdentity = createSelector(
  [selectActiveSubjobIdentities],
  (identities) => identities.rendersPerIdentity
);

export const selectSelectedIdentitiesPrefab = (state: BoundStore) =>
  state.jobBuilder.selectedIdentitiesPrefab;

export const selectSelectedIndecis = (state: BoundStore) =>
  state.jobBuilder.selectedIndeces;

export const selectSelectedBodyGroupIndex = (state: BoundStore) =>
  state.jobBuilder.selectedIndeces.body;

export const selectActiveSubjobBodies = createSelector(
  [selectPickedSubjob],
  (subjob) => subjob.body
);

export const selectActiveSubjobSkin = createSelector(
  [selectPickedSubjob],
  (subjob) => subjob.skin
);

export const selectSelectedClothingGroupIndex = (state: BoundStore) =>
  state.jobBuilder.selectedIndeces.clothing;

export const selectActiveSubjobClothing = createSelector(
  [selectPickedSubjob],
  (subjob) => subjob.clothing
);

export const selectActiveSubjobAnimations = createSelector(
  [selectPickedSubjob],
  (subjob) => subjob.gesture
);

export const selectSelectedAnimationsGroupIndex = (state: BoundStore) =>
  state.jobBuilder.selectedIndeces.gesture;

export const selectActiveSubjobHDRI = createSelector(
  [selectPickedSubjob],
  (subjob) => subjob.hdri
);

export const selectActiveSubjobthreeDimensionLocations = createSelector(
  [selectPickedSubjob],
  (subjob) => subjob.threeDLocation
);

export const selectSelectedRigIndex = (state: BoundStore) =>
  state.jobBuilder.selectedRigIndex;

export const selectActiveSubjobRigs = createSelector(
  [selectPickedSubjob],
  (subjob) => subjob.rigs
);

export const selectActiveSubjobSelectedRig = createSelector(
  [selectActiveSubjobRigs, selectSelectedRigIndex],
  (rigs, selectedRigIndex) => rigs[selectedRigIndex]
);

export const selectActiveSubjobCameraAndLightNamesForRig = createSelector(
  [selectPickedSubjob, selectSelectedRigIndex],
  (subjob, selectedRigIndex) => {
    return getcameraAndLightNamesAndTypesFromOneRig(subjob, selectedRigIndex);
  }
);

export const selectActiveSubjobCameraAndLightForAllRigs = createSelector(
  [selectPickedSubjob],
  (subjob) => {
    return cameraAndLightNamesAndTypesFromAllRigs(subjob);
  }
);

export const selectNumberOfImagesForEachSubjob = (state: BoundStore) =>
  state.jobBuilder.subjobs.map((subjob) => {
    const baseValue =
      subjob.identities.ids.length *
      Number(subjob.identities.rendersPerIdentity) *
      subjob.rigs.reduce((acc, current) => acc + current.cameras.length, 0);

    const sectionAnimations =
      subjob.gesture[selectSelectedAnimationsGroupIndex(state)];

    const animationDurations = sectionAnimations
      ? sectionAnimations.name.map(
          (nm) => state.jobBuilder.gestures[nm].output_frame_length
        )
      : [];

    const minNumberOfAnimations =
      sectionAnimations && animationDurations
        ? Math.min(...animationDurations)
        : 0;
    const maxNumberOfAnimations =
      sectionAnimations && animationDurations
        ? Math.max(...animationDurations)
        : 1;

    return sectionAnimations &&
      sectionAnimations.name.length > 0 &&
      !sectionAnimations.keyframe_only
      ? [minNumberOfAnimations * baseValue, maxNumberOfAnimations * baseValue]
      : [baseValue];
  });

export const selectNumberOfImagesForAllSubjobs = createSelector(
  [selectNumberOfImagesForEachSubjob],
  (images) =>
    images.reduce(
      (prev, current) => {
        if (current.length > 1) {
          return [prev[0] + current[0], prev[1] + current[1]];
        }
        return [prev[0] + current[0], prev[1] + current[0]];
      },
      [0, 0]
    )
);

export const selectNumberOfImagesForAllSubjobsAsString = createSelector(
  [selectNumberOfImagesForAllSubjobs],
  (range) => getNumberOfImagesString(range)
);

export const selectNumberOfImagesForActiveSubjobAsString = createSelector(
  [selectNumberOfImagesForEachSubjob, selectPickedSubjobIndex],
  (range, index) => getNumberOfImagesString(range[index])
);
