import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/ext-language_tools";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { NavigationHeader } from "components/Navigation/Header";
import { URLParams } from "constants/params";
import { Routes } from "constants/routes";
import { TemplateStr } from "features/JobBuilder/types";
import { useOrgParam } from "hooks/useOrgParam";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { selectedOrgSelector } from "store/orgStore";

export function isTemplateStr(obj: unknown): obj is TemplateStr {
  return (
    !!obj &&
    (obj as TemplateStr).name !== undefined &&
    typeof (obj as TemplateStr).name === "string"
  );
}

type InstructionsSectionProps = {
  header: string;
  paragraph: React.ReactChild;
};

function InstructionsSection(props: InstructionsSectionProps) {
  const { header, paragraph } = props;
  return (
    <>
      <br></br>
      <br></br>
      <Typography variant="h6">{header}</Typography>
      <Typography variant="body1">{paragraph}</Typography>
    </>
  );
}
export function CreateJob() {
  const { setSuccessMessage, setErrorMessage } =
    useBoundStore.getState().message;
  const navigate = useNavigate();
  const { orgParam } = useOrgParam();
  const { org, orgLoading } = useBoundStore((state) => {
    return {
      org: selectedOrgSelector(orgParam)(state),
      orgLoading: state.org.loading,
    };
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [template, setTemplate] = useState<TemplateStr | null>(null);
  const { state, search } = useLocation();

  useEffect(() => {
    if (!isTemplateStr(state)) {
      navigate(-1);
    } else {
      setTemplate({ name: state.name, jsonStr: state.jsonStr });
    }
  }, [navigate, search, state]);

  const userEmail = useBoundStore((state) => state.profile.data?.email);

  // TODO: I should be calling an api to get "first_job.json" but I am stuck
  // with a CORS error.
  // useEffect(()=> {
  //     async function getDefaultJob(){
  //         const result = await restClient.getSampleJob()
  //         setJobSpec(result)
  //         console.log("aaa ", result)
  //     }
  //     getDefaultJob()
  // }, [])

  const onDrop: DropzoneOptions["onDrop"] = useCallback(
    (acceptedFiles: (Blob & { name: string })[]) => {
      acceptedFiles.forEach((file: Blob & { name: string }) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          const str = reader.result as string;
          setTemplate({
            name: file.name.replace(/\.[^/.]+$/, ""),
            jsonStr: str,
          });
        };
        reader.readAsText(file);
      });
    },
    [setTemplate]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: "application/json",
  });

  const onSubmitJob = async () => {
    if (!userEmail || !org || !template) {
      return;
    }
    try {
      setIsSubmitting(true);
      await restClient.submitJob(
        org.name,
        template.jsonStr,
        userEmail,
        template.name
      );
      setSuccessMessage(`${template.name} has been submitted`);
      navigate(`${Routes.JOBS}?org=${orgParam}`);
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setIsSubmitting(false);
  };

  const onGoToJobs = () => {
    if (!org) {
      return;
    }
    navigate(Routes.JOBS + `?${URLParams.org}=${org.name}`);
  };

  if (!template) {
    return <></>;
  }

  const setJobName = (name: string) => {
    setTemplate({ ...template, name });
  };

  const setJobSpec = (jsonStr: string) => {
    setTemplate({ ...template, jsonStr });
  };

  return (
    <>
      <Box component="div" height="100%">
        {org && (
          <NavigationHeader selectedOrgName={org.name} title="Create job" />
        )}
        {orgLoading || isSubmitting ? (
          <FullScreenProgress />
        ) : (
          <>
            <div style={{ marginBottom: "56px" }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={onGoToJobs}
              >
                {"< Back to jobs table"}
              </Button>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Button color="secondary" variant="contained">
                    Upload local JSON file (optional)
                  </Button>
                </div>
                <Box component="div" maxWidth="400px" mb={3} mt={4}>
                  <TextField
                    autoFocus
                    fullWidth
                    label="Job Name"
                    name="email"
                    placeholder="Enter job name *"
                    value={template.name}
                    variant="outlined"
                    onChange={(e) => setJobName(e.target.value)}
                  />
                </Box>
                <AceEditor
                  editorProps={{}}
                  mode="json"
                  value={template.jsonStr}
                  width="100%"
                  onChange={(s) => setJobSpec(s)}
                />
                <Box component="div" mt={2}>
                  <Button
                    autoFocus
                    color="warning"
                    disabled={!template || !template.name}
                    variant="contained"
                    onClick={onSubmitJob}
                  >
                    Submit job
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  To create images with annotations, submit your job here.
                </Typography>
                <InstructionsSection
                  header="(1) Specify Job Parameters"
                  paragraph={
                    <>
                      Job parameters determine the humans, environemnt, and
                      camera setup for the images created. See our{" "}
                      <a href="https://docs.synthesis.ai/inputs.html">
                        inputs documentation
                      </a>{" "}
                      for what you can change, or simply{" "}
                      <a href="https://docs.synthesis.ai/templates.html">
                        download a template
                      </a>
                    </>
                  }
                />
                <InstructionsSection
                  header="(2) Submit Job"
                  paragraph={
                    <>
                      Simply click the submit job button, and our cloud will
                      provision the rendering process. The status of the job
                      will be updated in the jobs table.
                    </>
                  }
                />
                <InstructionsSection
                  header="(3) Download Assets"
                  paragraph={
                    <>
                      When the job completes, {"we'll"} send you an email.
                      Download the assets with our{" "}
                      <a href="https://docs.synthesis.ai/cli.html">CLI</a> and
                      read our{" "}
                      <a href="https://docs.synthesis.ai/outputs.html">
                        outputs documentation{" "}
                      </a>
                    </>
                  }
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
}
