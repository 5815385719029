export function MatchHairColorIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0129 5.05645L17.6594 4.70289L17.3058 5.05645L14.6158 7.74645L14.2623 8.1L14.6158 8.45355L15.5358 9.37355L15.8894 9.72711L16.2429 9.37355L18.9329 6.68355L19.2865 6.33L18.9329 5.97645L18.0129 5.05645ZM17.2258 10.5236L18.7923 12.09L18.0894 12.7929L17.0229 11.7264L16.6694 11.3729L16.3158 11.7264L7.54225 20.5H3.49936V16.4571L12.2729 7.68355L12.6265 7.33L12.2729 6.97645L11.2065 5.91L11.9112 5.20527L13.4877 6.76539L13.8412 7.11527L14.1929 6.76355L17.3077 3.64872C17.4171 3.54664 17.5434 3.5 17.6694 3.5C17.783 3.5 17.9098 3.54485 18.021 3.64872L20.3558 5.98355C20.5473 6.17505 20.5538 6.48845 20.3458 6.69645L17.2258 9.81645L16.8723 10.17L17.2258 10.5236ZM6.56581 19.3536L6.91936 19.7071L7.27291 19.3536L15.3329 11.2936L15.6865 10.94L15.3329 10.5864L13.4129 8.66645L13.0594 8.31289L12.7058 8.66645L4.64581 16.7264L4.29225 17.08L4.64581 17.4336L6.56581 19.3536Z"
        fill={fill}
        stroke={fill}
      />
    </svg>
  );
}
