export const headPosition = { x: 0.000994, y: 1.77512, z: 0.09 };
export const headPositionInCar = { x: 0.370994, y: 1.4, z: 0.09 };

export const rigTypeInformation = {
  preset_location: {
    origin: {
      rearview_mirror: { x: 0, y: 1.45, z: 0.3 },
      overhead_console: { x: 0, y: 1.52, z: 0.3 },
      dashboard: { x: 0.370094, y: 1.1, z: 0.6 },
      multimedia_console_upper: { x: 0, y: 1.3, z: 0.4 },
      multimedia_console_lower: { x: 0, y: 1.15, z: 0.4 },
      pillar_a: { x: 0.74, y: 1.4, z: 0.5 },
    },
    initial_pitch: {
      rearview_mirror: -16,
      overhead_console: -25,
      dashboard: 0,
      multimedia_console_upper: -10,
      multimedia_console_lower: -10,
      pillar_a: -10,
    },
  },
  head_orbit: {
    origin: { open: headPosition, car: headPositionInCar },
  },
  manual: {
    origin: { open: { x: 0, y: 0, z: 0 }, car: { x: 0, y: 0, z: 0 } },
  },
  head_orbit_with_tracking: {
    origin: { open: headPosition, car: headPositionInCar },
  },
  head_orbit_in_car: {
    origin: {
      open: { ...headPositionInCar, y: 1.3 },
      car: { ...headPositionInCar, y: 1.3 },
    },
  },
};
