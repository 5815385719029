import {
  Container,
  Header,
  InnerContainer,
  Paragraph,
} from "./identities-selected-empty.styled";
import identitiesSelectedEmpty from "./identities-selected-empty.svg";

export function IdentitiesSelectedEmpty({
  title,
  description,
}: {
  title?: string;
  description?: string;
}) {
  return (
    <>
      <Container component="div">
        <InnerContainer component="div">
          <img alt="" src={identitiesSelectedEmpty} />
          <Header>{title || "How it works"}</Header>
          <Paragraph>
            {description ||
              `To get started quickly, you can select a prefab identity group from above that will load several commonly used identities to your Job builder.`}
          </Paragraph>
        </InnerContainer>
      </Container>
    </>
  );
}
