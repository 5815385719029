import React, { CSSProperties, forwardRef } from "react";
import { colors } from "components/App/theme";

const labelStyles: CSSProperties = {
  display: "inline-block",
  paddingBottom: "24px",
  position: "relative",
  overflow: "hidden",
};

const titleStyles: CSSProperties = {
  fontSize: "12px",
  fontWeight: 600,
  lineHeight: "16px",
  textTransform: "uppercase",
  marginBottom: "4px",
  color: colors.grey70,
};

export const Label = forwardRef<
  HTMLLabelElement,
  React.ComponentPropsWithoutRef<"label"> & { title?: React.ReactNode }
>(function Label({ children, title, ...props }, ref) {
  return (
    <label ref={ref} {...props} style={labelStyles}>
      <div style={titleStyles}>{title}</div>
      {children}
    </label>
  );
});
