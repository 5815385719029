import { Tooltip } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const IconContainer = styled(Tooltip)<{ $isSelected: boolean }>`
  cursor: pointer;
  color: ${({ $isSelected }) => ($isSelected ? colors.orange : colors.grey50)};
`;
