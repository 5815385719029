import { useEffect, useMemo } from "react";
import { OrthographicCamera, useTexture } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { getImage } from "features/JobBuilder2/locations-data";
import { Activities3dStep } from "features/JobBuilder2/steps/threeDimensional/activities-3d-step";
import { ActivityZones3dStep } from "features/JobBuilder2/steps/threeDimensional/activity-zones-3d-step";
import { CustomControls } from "features/JobBuilder2/steps/threeDimensional/custom-controls";
import { MetricGrid } from "features/JobBuilder2/steps/threeDimensional/MetricGrid";
import {
  MetricRuler,
  rulerSize,
} from "features/JobBuilder2/steps/threeDimensional/MetricRuler";
import { PrefabGroups3dStep } from "features/JobBuilder2/steps/threeDimensional/prefab-groups-3d-step";
import { RigLocation3dStep } from "features/JobBuilder2/steps/threeDimensional/rig-location-3d-step";
import { Pipe2StepEnum } from "features/JobBuilder2/types";
import { useBoundStore } from "store/_boundStore";
import { selectPL2LocationDimension } from "store/selectors/selectPL2LocationDimension";
import { selectCurrentCursor } from "store/userControlsStore";

export function CanvasContents({ locationName }: { locationName: string }) {
  const selectedStep = useBoundStore((s) => s.jobBuilder2.selectedStep);
  const locationDimensions = useBoundStore(selectPL2LocationDimension);
  const canvasDimensions = useThree((s) => s.size);

  // bind domElement cursor to the cursor on state
  const currentCursor = useBoundStore(selectCurrentCursor);
  const domElement = useThree((state) => state.gl.domElement);
  useEffect(() => {
    domElement.style.cursor = currentCursor;
  }, [currentCursor, domElement.style]);

  const currentRoute = useMemo(() => {
    const routes: Record<number, JSX.Element> = {
      [Pipe2StepEnum.RigLocation]: <RigLocation3dStep />,
      [Pipe2StepEnum.ActivityZones]: <ActivityZones3dStep />,
      [Pipe2StepEnum.Activities]: <Activities3dStep />,
      [Pipe2StepEnum.PrefabGroups]: <PrefabGroups3dStep />,
    };
    return routes[selectedStep];
  }, [selectedStep]);

  const texture = useTexture(`${getImage(locationName)}?version=2`);
  const scale = canvasDimensions.width / locationDimensions.width;
  const rulerSizeScaled = rulerSize / scale;

  const defaultZoom = Math.min(
    canvasDimensions.height / (locationDimensions.height + rulerSizeScaled * 2),
    canvasDimensions.width / (locationDimensions.width + rulerSizeScaled * 2)
  );
  return (
    <>
      {/* Background Image */}
      <mesh>
        <boxBufferGeometry
          args={[locationDimensions.width, locationDimensions.height]}
        />
        <meshBasicMaterial map={texture} />
      </mesh>
      {/* Step and Rulers */}
      <MetricGrid />
      <MetricRuler scale={scale} />
      {currentRoute}

      {/* Drag/Zoom Controls */}
      <CustomControls defaultZoom={defaultZoom} />

      {/* Camera */}
      <OrthographicCamera
        makeDefault
        far={9000}
        near={0.01}
        position={[0, 0, 5000]}
        up={[0, 0, 1]}
        zoom={defaultZoom}
      />
    </>
  );
}
