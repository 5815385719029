import { WritableDraft } from "immer/dist/internal";
import { create, StateCreator } from "zustand";
import { immer } from "zustand/middleware/immer";
import {
  createJobBuilderSlice,
  JobBuilderSlice,
} from "features/JobBuilder/store";
import {
  createJobBuilder2Slice,
  JobBuilder2Slice,
} from "features/JobBuilder2/store";
import { AuthSlice, createAuthSlice } from "store/authStore";
import {
  CatalogDataSlice,
  createCatalogDataSlice,
} from "store/catalogueDataStore";
import { createMessageSlice, MessageSlice } from "store/messageStore";
import { createOrgSlice, OrgSlice } from "store/orgStore";
import { createProfileSlice, ProfileSlice } from "store/profileStore";
import {
  createUserControlsSlice,
  UserControlsSlice,
} from "store/userControlsStore";
import { createApisVersionSlice, ApisVersionSlice } from "./apisVersionStore";

export type BoundStore = {
  auth: AuthSlice;
  org: OrgSlice;
  profile: ProfileSlice;
  userControls: UserControlsSlice;
  message: MessageSlice;
  jobBuilder: JobBuilderSlice;
  jobBuilder2: JobBuilder2Slice;
  catalogData: CatalogDataSlice;

  apisVersion: ApisVersionSlice;
};

export const useBoundStore = create<BoundStore>()(
  immer((...a) => ({
    auth: createAuthSlice(...a),
    org: createOrgSlice(...a),
    profile: createProfileSlice(...a),
    userControls: createUserControlsSlice(...a),
    message: createMessageSlice(...a),
    jobBuilder: createJobBuilderSlice(...a),
    jobBuilder2: createJobBuilder2Slice(...a),
    catalogData: createCatalogDataSlice(...a),
    apisVersion: createApisVersionSlice(...a),
  }))
);

useBoundStore.subscribe(({ auth: { authenticated, token } }) => {
  localStorage.setItem(
    "auth-store",
    JSON.stringify({
      authenticated,
      token,
    })
  );
});

export type SliceType<T> = StateCreator<
  BoundStore,
  [["zustand/immer", never]],
  [],
  T
>;

export type SetState = (
  nextStateOrUpdater:
    | BoundStore
    | Partial<BoundStore>
    | ((state: WritableDraft<BoundStore>) => void),
  shouldReplace?: boolean | undefined
) => void;

export type GetState = () => BoundStore;
