import { SliceType } from "store/_boundStore";
import { createActions } from "./actions";

type State = {
  success: string;
  warn: string;
  error: string;
};

type Actions = {
  setErrorMessage: (v: string) => void;
  setWarnMessage: (v: string) => void;
  setSuccessMessage: (v: string, overwrite?: boolean) => void;
  clearMessages: () => void;
};

export type MessageSlice = State & Actions;

const initialState: State = { success: "", warn: "", error: "" };

export const createMessageSlice: SliceType<MessageSlice> = (set, get) => ({
  ...initialState,
  ...createActions(set, get, initialState),
});
