// noinspection DuplicatedCode,RequiredAttributes

import { useCallback, useMemo } from "react";
import { area } from "@flasd/modeling/src/maths/utils";
import { partition } from "lodash";
import { Path, Shape } from "three";
import { colors as themeColors } from "components/App/theme";
import { ActivityZoneOutlines } from "features/JobBuilder2/steps/threeDimensional/activity-zone-outlines";
import { ActivityZoneLabel } from "features/JobBuilder2/steps/threeDimensional/ActivityZoneLabel";
import { fromVec2ToVector2 } from "features/JobBuilder2/steps/threeDimensional/utils/polygon-converters";
import { useCursor2 } from "features/JobBuilder2/steps/threeDimensional/utils/useCursor2";
import { ActivityZone, Pipe2StepEnum } from "features/JobBuilder2/types";
import { useBoundStore } from "store/_boundStore";
import { hexToThreeJsColor } from "utils/colors";

const zIndex = 1;
export const activityZoneHeight = 0.1;
export const activityZoneGap = 0.05;

type ActivityRegionProps = {
  shapeIndex: number;
  onClick?: () => void;
  activityZone: ActivityZone;
  hidden?: boolean;
};

/**
 * @description Draws and allows for interaction with an activity zone.
 */
export function ActivityRegion({
  shapeIndex,
  onClick,
  activityZone,
  hidden,
}: ActivityRegionProps) {
  const [
    hoveredActivityZone,
    setHoveredActivityZone,
    isDragging,
    selectedStep,
  ] = useBoundStore((s) => [
    s.jobBuilder2.hoveredActivityZone,
    s.jobBuilder2.setHoveredActivityZone,
    s.userControls.dragging,
    s.jobBuilder2.selectedStep,
  ]);

  // Get the geometry of the activity zone.
  const geom = activityZone.geomRegion.geom;

  // get shapes with holes
  const shapes = useMemo(() => {
    const [positiveShapes, holes] = partition(
      geom.outlines,
      (vec2s) => area(vec2s) > 0
    );
    const shapes = positiveShapes.map(
      (vec2s) => new Shape(vec2s.map(fromVec2ToVector2))
    );
    // add holes to each shape
    const holePaths = holes.map((hole) =>
      new Path().setFromPoints(hole.map(fromVec2ToVector2))
    );
    shapes.forEach((s) => {
      s.holes = holePaths;
    });
    return shapes;
  }, [geom]);

  const isClickable = useMemo(() => {
    return hoveredActivityZone === shapeIndex && Boolean(onClick);
  }, [onClick, hoveredActivityZone, shapeIndex]);

  const color = useMemo(() => {
    const hexColor =
      isClickable && !isDragging ? themeColors.bright[2] : themeColors.purple;
    return hexToThreeJsColor(hexColor);
  }, [isDragging, isClickable]);

  useCursor2(isClickable, "pointer");

  const handlePointerEnter = useCallback(() => {
    setHoveredActivityZone(shapeIndex);
  }, [setHoveredActivityZone, shapeIndex]);

  const handlePointerLeave = useCallback(() => {
    setHoveredActivityZone(-1);
  }, [setHoveredActivityZone]);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  const shouldShowOutline = useMemo(() => {
    const { activities, geomRegion } = activityZone;
    const isProhibited =
      selectedStep === Pipe2StepEnum.PrefabGroups && activities.length === 0;
    return geomRegion.type !== "background" && !isProhibited;
  }, [activityZone, selectedStep]);

  if (hidden) return null;

  return (
    <group
      position={[
        0,
        0,
        activityZoneGap +
          shapeIndex * (activityZoneHeight + activityZoneGap) +
          zIndex,
      ]}
    >
      {shouldShowOutline && (
        <ActivityZoneOutlines geom={activityZone.geomRegion.geom} />
      )}
      <ActivityZoneLabel activityZone={activityZone} shapeIndex={shapeIndex} />
      {/* purple background that resemble the total free areas (where an activity can potentially be located) */}
      <mesh
        frustumCulled={false}
        onClick={handleClick}
        onPointerEnter={handlePointerEnter}
        onPointerLeave={handlePointerLeave}
      >
        <shapeGeometry args={[shapes]} />
        <meshBasicMaterial transparent color={color} opacity={0.44} />
      </mesh>
    </group>
  );
}
