import { ErrorMessage } from "components/Common/SnackbarMessages/ErrorMessage";
import { SuccessMessage } from "components/Common/SnackbarMessages/SuccessMessage";
import { WarnMessage } from "components/Common/SnackbarMessages/WarnMessage";
import { useBoundStore } from "store/_boundStore";

export function Alerts() {
  const { error, success, warn } = useBoundStore((s) => ({
    error: s.message.error,
    success: s.message.success,
    warn: s.message.warn,
  }));

  const { clearMessages } = useBoundStore.getState().message;

  return (
    <>
      <ErrorMessage message={error} onDismiss={clearMessages} />
      <SuccessMessage message={success} onDismiss={clearMessages} />
      <WarnMessage message={warn} onDismiss={clearMessages} />
    </>
  );
}
