import { Section } from "domain/Human";
import { ReverseTreeItem } from "features/JobBuilder/LeftSidebar/left-sidebar.styled";
import { SectionLabel } from "features/JobBuilder/LeftSidebar/SectionLabel";
import { AdditionalInfo } from "features/JobBuilder/LeftSidebar/SectionLabel/section-label.styled";
import { useBoundStore } from "store/_boundStore";
import { IconsType } from "types/main";

export function SimpleSidebarItem({
  label,
  icon,
  additionalInfo,
  id,
}: {
  label: string;
  icon: IconsType;
  additionalInfo: string;
  id: keyof Section;
}) {
  const setSelectedHumanObjectPart =
    useBoundStore.getState().jobBuilder.setSelectedHumanObjectPart;
  const selectedHumanObjectPart =
    useBoundStore.getState().jobBuilder.selectedPart;
  const onSelect = () => setSelectedHumanObjectPart(id);
  const selected = selectedHumanObjectPart === id;

  return (
    <ReverseTreeItem
      label={
        <SectionLabel
          additionalInfo={
            <AdditionalInfo selected={selected}>
              {additionalInfo}
            </AdditionalInfo>
          }
          icon={icon}
          label={label}
          selected={selected}
        />
      }
      nodeId={id}
      onClick={onSelect}
    />
  );
}
