import React from "react";
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

export function Footer() {
  const info = process.env.REACT_APP_VERSION_INFO;
  const currentYear = new Date().getFullYear();

  return (
    <Box
      component="div"
      sx={{
        alignSelf: "flex-end",
        backgroundColor: "secondary.main",
        width: "100%",
        textAlign: "center",
        p: 2,
        fontSize: "14px",
        marginTop: "3rem",
      }}
    >
      <Tooltip placement="top" title={<React.Fragment>{info}</React.Fragment>}>
        <span>&copy; {currentYear} Synthesis AI</span>
      </Tooltip>
    </Box>
  );
}
