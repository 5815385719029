import { SliceType } from "store/_boundStore";
import { createActions } from "./actions";

export type State = {
  authenticated: boolean;
  error: string;
  token: string;
  loading: boolean;
};

type Actions = {
  login: (username: string, password: string) => void;
  logout: (error: string) => void;
  getCurrentUserId: () => string | null;
};

export type AuthSlice = State & Actions;

const initialState: State = {
  error: "",
  loading: false,
  authenticated: false,
  token: "",
};
const authStoreFromLocalStorageAsString = localStorage.getItem("auth-store");
const authStoreFromLocalStorageAsObject = authStoreFromLocalStorageAsString
  ? JSON.parse(authStoreFromLocalStorageAsString)
  : {};

export const createAuthSlice: SliceType<AuthSlice> = (set, get) => ({
  ...initialState,
  ...authStoreFromLocalStorageAsObject,
  ...createActions(set, get, initialState),
});
