import React, { useState } from "react";
import { Box, Divider, Grid, Link, TextField, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useLocation } from "react-router";
import { ActionButton } from "components/Common/ActionButton";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { TermsAndConditionsFormElement } from "components/Common/TermsAndConditionsFormElement";
import { SuccessMessage } from "components/SuccessMessage";
import { Messages } from "constants/messages";
import { Routes } from "constants/routes";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { validateEmail, validatePassword } from "utils/validators";

function NewAccount() {
  const { setErrorMessage } = useBoundStore.getState().message;
  const [emailValidationError, setEmailValidationError] = useState("");
  const [passwordValidationError, setPasswordValidationError] = useState("");
  const [agreeValidationError, setAgreeValidationError] = useState("");

  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [agree, setAgree] = useState(false);
  const location = useLocation();

  const validate = () => {
    let valid = true;

    let validationResult = validatePassword(password);
    if (!validationResult.valid) {
      valid = false;
      setPasswordValidationError(validationResult.message || "Invalid");
    } else {
      if (password !== password2) {
        valid = false;
        setPasswordValidationError(Messages.passwordsNotMatching);
      } else {
        setPasswordValidationError("");
      }
    }

    validationResult = validateEmail(email);
    if (!validationResult.valid) {
      valid = false;
      setEmailValidationError(validationResult.message || "Invalid");
    } else {
      setEmailValidationError("");
    }

    if (!agree) {
      valid = false;
      setAgreeValidationError(Messages.shouldAgree);
    } else {
      setAgreeValidationError("");
    }

    return valid;
  };

  const createAccount = async () => {
    if (!validate()) {
      return;
    }

    setLoading(true);
    try {
      const message = await restClient.createAccount(
        email.toLowerCase(),
        firstName,
        lastName,
        password
      );

      window.analytics.track("Account Created", {
        email,
        firstName,
        lastName,
      });

      if (message) {
        setSuccessMessage(message);
      } else {
        setSuccessMessage(
          `To activate your account please follow the instructions in the email we sent to ${email}`
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  if (successMessage) {
    return (
      <SuccessMessage
        details={successMessage}
        title="Your account has been created!"
      />
    );
  }

  return (
    <>
      {loading && <FullScreenProgress />}
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          <Typography variant="h1">Account Registration</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          <Box component="div" mt={4}>
            <TextField
              autoFocus
              fullWidth
              required
              error={!!emailValidationError}
              helperText={emailValidationError}
              id="email"
              label="Email address"
              size="small"
              type="email"
              value={email}
              variant="outlined"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailValidationError("");
              }}
            />
          </Box>
          <Box component="div" mt={4}>
            <TextField
              fullWidth
              id="firstName"
              label="First name"
              size="small"
              type="text"
              value={firstName}
              variant="outlined"
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
          </Box>
          <Box component="div" mt={4}>
            <TextField
              fullWidth
              id="lastName"
              label="Last name"
              size="small"
              type="text"
              value={lastName}
              variant="outlined"
              onChange={(e) => setLastName(e.target.value)}
            />
          </Box>
          <Box component="div" mt={4}>
            <TextField
              fullWidth
              required
              error={!!passwordValidationError}
              helperText={passwordValidationError}
              id="password"
              label="Password"
              size="small"
              type="password"
              value={password}
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Box>
          <Box component="div" mt={4}>
            <TextField
              fullWidth
              required
              error={!!passwordValidationError}
              helperText={passwordValidationError}
              id="password2"
              label="Re-enter password"
              size="small"
              type="password"
              value={password2}
              variant="outlined"
              onChange={(e) => setPassword2(e.target.value)}
            />
          </Box>

          <TermsAndConditionsFormElement
            agree={agree}
            agreeValidationError={agreeValidationError}
            onChange={(newValue: boolean) => setAgree(newValue)}
          />

          <Box component="div" mt={2}>
            <ActionButton fullWidth color="warning" onClick={createAccount}>
              Create account
            </ActionButton>
          </Box>

          <Box component="div" display="flex" mt={3} textAlign="center">
            <Box component="div" flex="50%" flexShrink={0}>
              <Link
                href={Routes.LOGIN + location.search}
                underline="always"
                variant="body2"
              >
                Already have an account?
              </Link>
            </Box>
            <Box component="div" flexGrow={0}>
              <Divider orientation="vertical" />
            </Box>
            <Box component="div" flex="50%" flexShrink={0}>
              <Link
                href={Routes.RESEND_ACTIVATION_EMAIL + location.search}
                underline="always"
                variant="body2"
              >
                {"Didn't"} get a verification email?
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export { NewAccount };
