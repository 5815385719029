import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { URLParams } from "constants/params";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { useURLParam } from "./useURLParam";

function useOrgParam() {
  const { setErrorMessage } = useBoundStore.getState().message;
  const { setLoading, saveOrgs, replacePermissions } =
    useBoundStore.getState().org;
  const navigate = useNavigate();
  const userId = useBoundStore.getState().auth.getCurrentUserId();

  const { orgs } = useBoundStore((state) => {
    return {
      orgs: state.org.list,
    };
  });

  const [orgParam] = useURLParam(URLParams.org);

  // fetch for orgs
  useEffect(() => {
    if (orgs || !userId) {
      return;
    }
    if (!orgParam) {
      console.warn("no org param in URL");
      navigate("/not-found");
    }

    const fetch = async () => {
      try {
        setLoading(true);
        const orgs = await restClient.getOrgs(userId);
        saveOrgs(orgs);
        setLoading(false);
        const found = orgs.find((o) => o.name === orgParam);
        if (found) {
          await replacePermissions(found.name);
        } else {
          console.warn("problem with org param in URL");
          navigate("/not-found");
        }
      } catch (error) {
        Sentry.captureException(error);
        setErrorMessage(restClient.APIErrorMessage(error));
        setLoading(false);
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { orgParam };
}

export { useOrgParam };
