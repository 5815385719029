import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const Container = styled(Box)`
  background-color: ${colors.tint4};
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
`;

export const NumberOfImages = styled(Typography)`
  color: ${colors.grey50};
  font-size: 12px;
  line-height: 20px;
`;
