import { InfiniteScroll } from "components/RightAsidePreviewFull/InfiniteScroll";
import { PreviewContentProps } from "components/RightAsidePreviewFull/types";
import { Container } from "./PreviewContent.styled";

export function PreviewContent({ data, title }: PreviewContentProps) {
  return (
    <Container>
      <InfiniteScroll data={data} title={title} />
    </Container>
  );
}
