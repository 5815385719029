export function MetalnessIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2001 3H6.8001L1.6001 12L6.8001 21H17.2001L22.4001 12L17.2001 3ZM16.0501 19H7.9501L3.9101 12L7.9501 5H16.0401L20.0801 12L16.0501 19Z"
        fill={fill}
      />
    </svg>
  );
}
