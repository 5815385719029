import { TextField, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";
import { SelectorWidth } from "components/SelectorsPicker/utils";

export const InputContainer = styled.div<{ $labelDirection: string }>`
  display: flex;
  flex-direction: ${({ $labelDirection }) => $labelDirection};
  align-items: ${({ $labelDirection }) =>
    $labelDirection === "row" ? "center" : "flex-start"};
  justify-content: flex-start;
`;

export const SelectorInput = styled(TextField).attrs((props) => ({
  FormHelperTextProps: {
    sx: {
      lineHeight: "1.0",
      overflow: "visible",
      whiteSpace: "nowrap",
      alignSelf: "flex-start",
      margin: "8px 0 0px",
    },
  },
}))<{ disabled: boolean }>`
  display: flex;
  width: ${SelectorWidth};
  min-width: 214px;
  align-items: center;
  margin: 8px 10px 4px 0;
  position: relative;

  input {
    padding: 10px 16px;
    font-size: 14px;
    line-height: 21px;
    min-width: 214px;
  }

  .css-qnpdxo-MuiFormHelperText-root {
    font-size: 12px;
    line-height: 14px;
    margin-left: -8px;
    width: 124px;
  }
`;

export const InputLabel = styled(Typography)`
  color: ${colors.grey70};
  font-size: 14px;
  line-height: 21px;
`;
