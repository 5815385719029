import axios from "axios";
import {
  CATALOGUE_LOCATIONS_BASE_URL,
  CATALOGUE_ACTIONS_BASE_URL,
} from "constants/constants";
import { Activity, CGLocation } from "features/JobBuilder2/types";

export class CatalogueAssetsRestClient {
  actionsClient;
  locationsClient;

  constructor() {
    this.actionsClient = axios.create({
      baseURL: CATALOGUE_ACTIONS_BASE_URL,
    });
    this.locationsClient = axios.create({
      baseURL: CATALOGUE_LOCATIONS_BASE_URL,
    });
  }

  async getActivities(): Promise<Activity[]> {
    const result = await this.actionsClient.get("");
    const data = result.data.results;
    return data || [];
  }

  async getLocations(): Promise<CGLocation[]> {
    const result = await this.locationsClient.get("");
    const data = result.data.results;
    return data || [];
  }
}
