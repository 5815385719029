import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { URLParams } from "constants/params";
import { Routes } from "constants/routes";
import { Org } from "domain/Org";

export function NeedHelp({ org }: { org: Org | undefined }) {
  const navigate = useNavigate();
  return (
    <Box component="div" padding={4} textAlign="center">
      <Box component="div" mb={1}>
        <Typography variant="body2">Need help using our CLI tool? </Typography>
      </Box>
      {org && (
        <Button
          color="warning"
          variant="outlined"
          onClick={() =>
            navigate(Routes.JOBS_TUTORIAL + `?${URLParams.org}=${org.name}`)
          }
        >
          Visit our CLI tutorial
        </Button>
      )}
    </Box>
  );
}
