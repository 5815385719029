import {
  defaultGlobalEnv,
  getPipe2Step,
  UpdatePickedSubjobFn,
} from "features/JobBuilder2/types";
import { TimeOfTheDay } from "./core";

export const getGlobalEnvStep = (
  updatePickedSubjob: UpdatePickedSubjobFn,
  globalEnv: typeof defaultGlobalEnv
) => {
  const onUpdateGlobalEnv = (v: typeof defaultGlobalEnv) =>
    updatePickedSubjob((d) => {
      d.globalEnv = v;
    });
  return getPipe2Step(
    "Set Lighting (Optional)",
    <TimeOfTheDay value={globalEnv} onUpdate={onUpdateGlobalEnv} />,
    true,
    {
      proTip: "Customize the lighting effects of the scene’s environment.",
    }
  );
};
