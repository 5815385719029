import React, { useCallback, MouseEvent, useState } from "react";
import { debounce } from "lodash";
import { SearchIcon } from "assets/icons";
import { colors } from "components/App/theme";
import { SearchInputEndAdornment } from "components/Common/SearchInputEndAdorment";
import { Input } from "components/design-system/forms/Input";

const handleAdormentMouseDown = (event: MouseEvent<HTMLButtonElement>) => {
  event.preventDefault();
};

export function SearchInput({
  placeholder,
  onSearch,
}: {
  placeholder: string;
  onSearch: (term: string) => void;
}) {
  const [value, setValue] = useState("");
  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce((term: string) => onSearch(term), 400),
    []
  );

  const handleClearClick = () => {
    setValue("");
    onSearch("");
  };

  return (
    <Input
      data-testid="search-input"
      endAdornment={
        value ? (
          <SearchInputEndAdornment
            handleClearClick={handleClearClick}
            handleMouseDown={handleAdormentMouseDown}
          />
        ) : (
          ""
        )
      }
      placeholder={placeholder}
      startAdornment={
        <SearchIcon fill={colors.grey50} style={{ marginRight: 8 }} />
      }
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        debouncedSearch(e.target.value);
      }}
    />
  );
}
