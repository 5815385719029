import React from "react";
import { InputContainer, InputWrapper } from "./ResponsiveInput.styled";

export function ResponsiveInput({
  value,
  onChange,
  placeholder,
}: {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}) {
  return (
    <InputWrapper>
      <InputContainer data-value={value}>
        <textarea
          placeholder={placeholder}
          rows={1}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </InputContainer>
    </InputWrapper>
  );
}
