import { ContentContainer } from "components/BaseWizardTab/BaseWizardTab.styled";
import { WizardOptionCard } from "components/WizardOptionCard";

type CardOptionListProps = {
  options: {
    attributes: { title: string; description?: string; imageSrc: string };
    id: number;
  }[];
  onOptionClick: (optionId: number) => void;
  hideThumbnails?: boolean;
};

export function CardOptionList(props: CardOptionListProps) {
  const { options, onOptionClick, hideThumbnails } = props;

  return (
    <ContentContainer>
      {options.map((option, i) => (
        <WizardOptionCard
          key={i}
          description={option.attributes.description || ""}
          hideThumbnails={hideThumbnails}
          imageSrc={option.attributes.imageSrc}
          title={option.attributes.title}
          onClick={() => onOptionClick(option.id)}
        />
      ))}
    </ContentContainer>
  );
}
