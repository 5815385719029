import { ListItem } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import { ScrollContainer } from "react-indiana-drag-scroll";
import styled, { css } from "styled-components";
import { colors } from "components/App/theme";

const ITEM_WIDTH = 150;
const ITEM_PADDING = 16;

export const Spacer = styled.div`
  width: 100%;
  padding-top: 100%;
`;

export const StyledList = styled(ScrollContainer)<{
  $hasDynamicSize?: boolean;
  $horizontalView?: boolean;
}>`
  display: grid;
  max-width: unset;
  column-gap: 36px;
  row-gap: 18px;
  width: 100%;
  max-width: 100%;
  background-color: "background.paper";
  padding-bottom: 24px;
  border-bottom: 1px solid ${colors.grey20};
  overflow: auto;
  max-height: 520px;

  ${({ $horizontalView }) =>
    $horizontalView &&
    `
    display:flex;
  `}

  ${({ $hasDynamicSize }) => {
    const widthAndPadding = ITEM_WIDTH + ITEM_PADDING;
    const dynamicColumns = `repeat(auto-fill, minmax(${widthAndPadding}px, 1fr));`;
    const staticColumns = `repeat(auto-fill, ${widthAndPadding}px);`;

    return $hasDynamicSize
      ? css`
          grid-template-columns: ${dynamicColumns};
        `
      : css`
          grid-template-columns: ${staticColumns};
        `;
  }};
`;

export const StyledListItem = styled(ListItem)<{ $horizontalView?: boolean }>`
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  ${({ $horizontalView }) => $horizontalView && "min-width: 150px;"}
`;

export const StyledListItemButton = styled(ListItemButton)<{
  $isSelected: boolean;
  $isDisabled: boolean;
  $hasDynamicSize?: boolean;
}>`
  padding: 0;
  padding: 8px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 10px;
  cursor: ${({ $isDisabled }) => ($isDisabled ? "initial" : "pointer")};

  border: ${(props) =>
    props.$isSelected
      ? `2px solid ${colors.orange}`
      : `1px solid ${colors.grey20}`};
  border-radius: 8px;

  ${({ $hasDynamicSize }) =>
    $hasDynamicSize
      ? css`
          width: 100%;
          height: 100%;
        `
      : css`
          width: ${ITEM_WIDTH + ITEM_PADDING}px;
          height: ${ITEM_WIDTH + 30}px;
        `}
`;

export const ImageAndIconContainer = styled.div`
  position: relative;
  border: 1px solid ${colors.grey20};
  flex: 1;
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
  background-color: ${colors.greyBG};
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
  z-index: 2;
`;

export const StyledImage = styled.img<{
  $hasDynamicSize?: boolean;
}>`
  object-fit: cover;
  border-radius: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;

  ${({ $hasDynamicSize }) =>
    $hasDynamicSize
      ? css`
          width: 100%;
          height: 100%;
        `
      : css`
          width: ${ITEM_WIDTH}px;
          height: ${ITEM_WIDTH}px;
        `}
`;

export const StyledVideo = styled.video`
  width: ${ITEM_WIDTH}px;
  height: ${ITEM_WIDTH}px;
  object-fit: cover;
  border-radius: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;
