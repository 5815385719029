import { Box } from "@mui/material";

export function TutorialCard({ children }: { children: any }) {
  return (
    <Box
      component="div"
      data-testid="tutorial-card"
      sx={{
        border: "1px solid #979797",
        borderRadius: "6px",
        padding: 3,
        height: "400px",
        textAlign: "center",
        width: {
          xs: "300px",
          md: "unset",
        },
        margin: {
          xs: "auto",
          md: "unset",
        },
      }}
    >
      {children}
    </Box>
  );
}
