import {
  HelpOverlay,
  HelpMessage,
} from "features/JobBuilder2/help-overlay/help-overlay";
import { CameraRigDialog } from "features/JobBuilder2/steps/set-rig-location/camera-rig-dialog/camera-rig-dialog";
import { selectPickedSubjob } from "features/JobBuilder2/store";
import { segmentTrack } from "features/JobBuilder2/track-service";
import { defaultSubjob } from "features/JobBuilder2/types";
import { useBoundStore } from "store/_boundStore";

export function SetRigLocationCore({
  rigLocationIndex,
  updatePickedSubjob,
  children,
}: {
  rigLocationIndex: number;
  updatePickedSubjob: (fn: (draft: typeof defaultSubjob) => void) => void;
  children: React.ReactNode;
}) {
  const [
    setIsCameraRigDialogOpened,
    userID,
    pickedSubjob,
    isCameraRigDialogOpened,
  ] = useBoundStore((s) => [
    s.jobBuilder2.setIsCameraRigDialogOpened,
    s.profile.data?.id,
    selectPickedSubjob(s),
    s.jobBuilder2.isCameraRigDialogOpened,
  ]);

  const onCameraAdded = () => {
    segmentTrack("camera added", userID, {
      locationName: pickedSubjob.location,
    });
  };

  const onCameraRemoved = () => {
    segmentTrack("camera removed", userID, {
      locationName: pickedSubjob.location,
    });
  };

  return (
    <>
      <CameraRigDialog
        cameraRig={pickedSubjob.rig}
        open={isCameraRigDialogOpened}
        onCameraAdded={onCameraAdded}
        onCameraRemoved={onCameraRemoved}
        onCameraRigSettingsChange={updatePickedSubjob}
        onClose={() => setIsCameraRigDialogOpened(false)}
        onOpen={() => setIsCameraRigDialogOpened(true)}
      />
      <HelpOverlay
        helpMessage={
          <HelpMessage>
            Select one of the preset locations
            <br />
            for your camera rig{" "}
          </HelpMessage>
        }
        startOpen={rigLocationIndex === -1}
      >
        {children}
      </HelpOverlay>
    </>
  );
}
