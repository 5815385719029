import { useEffect, useMemo, useState } from "react";
import { debounce } from "throttle-debounce";
import { PatchDraftPayload } from "domain/Draft";
import { restClient } from "services/restClient/main";
import { defaultSubjob } from "./types";

export const useAutoSyncDraftWithBackend = (
  orgParam: string,
  templateName: string,
  subjobs: (typeof defaultSubjob)[],
  draftId: string | undefined
) => {
  const [draftSyncState, setDraftDirty] = useState<
    "empty" | "saved" | "saving" | "dirty" | "error"
  >("empty");

  const shouldSaveDraft = useMemo(() => {
    return (
      subjobs.length > 1 ||
      subjobs.some((subjob) => {
        return (
          subjob.activityZones.some(
            (zone) => zone.geomRegion.type === "drawn"
          ) || subjob.activityZones.some((zone) => zone.activities.length >= 1)
        );
      })
    );
  }, [subjobs]);

  const patchDraft = useMemo(
    () =>
      debounce(
        2000,
        async (draft: PatchDraftPayload, draftID: string) => {
          if (!draft.job_name) {
            setDraftDirty("error");
            return;
          }

          setDraftDirty("saving");
          try {
            await restClient.patchPL2Draft(draft, draftID, orgParam);
          } catch (error) {
            setDraftDirty("error");
            console.error(error);
          } finally {
            setDraftDirty("saved");
          }
        },
        { atBegin: false }
      ),
    [orgParam]
  );

  useEffect(() => {
    if (!draftId || !shouldSaveDraft) {
      return;
    }

    setDraftDirty("dirty");

    const payload = {
      job_name: templateName,
      job_state: subjobs,
      location: subjobs[0].location,
    };

    patchDraft(payload, draftId);
  }, [subjobs, templateName, draftId, patchDraft, shouldSaveDraft]);

  if (!draftId || !shouldSaveDraft) {
    return ["empty", null] as const;
  }

  if (!templateName) {
    return ["error", "Template name is empty"] as const;
  }

  return [draftSyncState, null] as const;
};
