import { Geom2 } from "@flasd/modeling/src/geometries/types";
import { subtract } from "@flasd/modeling/src/operations/booleans";
import { GeomRegion } from "features/JobBuilder2/steps/threeDimensional/GeomRegion";
import { fromVec2sToGeom2 } from "features/JobBuilder2/steps/threeDimensional/utils/polygon-converters";
import { pretranformCGLocation } from "features/JobBuilder2/steps/threeDimensional/utils/pre-transform-cg-location";
import { CGLocation } from "features/JobBuilder2/types";

type PretransformedCGLocation = ReturnType<typeof pretranformCGLocation>;
type PretransformedCGActionRegion =
  PretransformedCGLocation["action_regions"][0];
type PretransformedCGAvoidRegion =
  PretransformedCGActionRegion["avoid_regions"][0];
export enum ActivityStance {
  Standing = "standing",
  Sitting = "sitting",
}

export const predefinedGeomFromActionRegion = (
  a: PretransformedCGActionRegion
) =>
  new GeomRegion(
    subractAvoidRegions(fromVec2sToGeom2(a.shell), a.avoid_regions),
    a.hints === ActivityStance.Standing ? "background" : "seated",
    a.name
  );

export const subractAvoidRegions = (
  geomWithoutAvoid: Geom2,
  avoid_regions: PretransformedCGAvoidRegion[]
) => {
  const toBeSubtracted = avoid_regions.map((a) => fromVec2sToGeom2(a.shell));
  return subtract(geomWithoutAvoid, toBeSubtracted);
};

export const transformCGLocation = (cgLocation: CGLocation) => {
  const pretransformed = pretranformCGLocation(cgLocation);
  return {
    name: pretransformed.name,
    origin: pretransformed.origin,
    dimensions: pretransformed.dimensions,
    backgroundActionRegion: pretransformed.action_regions[0],
    sittingRegions: pretransformed.action_regions
      .slice(1)
      .map(predefinedGeomFromActionRegion),
    camera_preset_locations: pretransformed.camera_preset_locations,
  };
};

export type TransformedLocation = ReturnType<typeof transformCGLocation>;
export type TransformedCameraPresetLocation =
  TransformedLocation["camera_preset_locations"][0];
export const getLocationOrThrow = (
  pickedSubjobLocation: string,
  locations: TransformedLocation[]
) => {
  const pickedLocation = locations.find((l) => l.name === pickedSubjobLocation);
  if (!pickedLocation) {
    throw new Error(
      `Invalid location ${pickedSubjobLocation} provided. Check the locations object.`
    );
  }
  return pickedLocation;
};
