import { restClient } from "services/restClient/main";
import { SetState } from "store/_boundStore";
export function createActions(set: SetState) {
  return {
    fetchApisVersion: async () => {
      const apisVersion = await restClient.getApisVersion();
      set((state) => {
        state.apisVersion.data = apisVersion;
      });
    },
  };
}
