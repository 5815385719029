import { ReactNode } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import {
  HeaderActionsContainer,
  HeaderContainer,
} from "features/JobBuilder/json-manager.styled";

export function Layout({
  title,
  children,
  onDelete,
}: {
  title: ReactNode;
  children: ReactNode;
  onDelete?: () => void;
}) {
  return (
    <Box component="div">
      <HeaderContainer>{title}</HeaderContainer>
      <HeaderActionsContainer>
        {onDelete && (
          <CloseIcon style={{ cursor: "pointer" }} onClick={onDelete} />
        )}
      </HeaderActionsContainer>
      {children}
    </Box>
  );
}
