import { NoSearchResultFound } from "components/Common/NoSearchResultFound";
import { ImageGridImage, ImageGrid } from "components/ImageGrid";
import { ListViewToggle } from "components/ListViewToggle";
import { TitleWithIcon } from "components/TitleWithIcon/TitleWithIcon";
import { Messages } from "constants/messages";
import { MultiOptionFilter } from "features/JobBuilder/MultiOptionsFilter";
import { useImageGridViewState } from "hooks/useImageGridViewState";
import { IconsType } from "types/main";

type Props = {
  label: string;
  id: string;
  icon: IconsType;
  setSearchTerm: (searchTerm: string) => void;
  areAllSelected: boolean;
  handleSelectAll: () => void;
  filteredImages: ImageGridImage[];
  togglePickOne: (i: number) => void;
};

export function MultiOptionImageGrid({
  icon,
  id,
  label,
  setSearchTerm,
  areAllSelected,
  handleSelectAll,
  filteredImages,
  togglePickOne,
}: Props) {
  const { imageGridViewMode, setImageGridViewMode } = useImageGridViewState(
    filteredImages.length
  );
  return (
    <>
      <TitleWithIcon
        addBorderBottom
        withSpacing
        icon={icon}
        id={id}
        label={label}
        leftSideElement={
          <ListViewToggle
            view={imageGridViewMode}
            onChange={setImageGridViewMode}
          />
        }
      />
      <MultiOptionFilter
        allSelected={areAllSelected}
        placeholder={Messages.search}
        onSearch={setSearchTerm}
        onSelectAll={handleSelectAll}
      />

      {!filteredImages.length ? (
        <NoSearchResultFound />
      ) : (
        <ImageGrid
          dynamicSize
          images={filteredImages}
          viewMode={imageGridViewMode}
          onSelect={togglePickOne}
        />
      )}
    </>
  );
}
