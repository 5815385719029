import { useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "assets/icons/ChevronRight";
import { BreadcrumbNavigator, Row } from "./Breadcrumb.styled";
import { IBreadcrumbItem } from "./types";

export function Breadcrumb({
  breadcrumbs,
}: {
  breadcrumbs: IBreadcrumbItem[];
}) {
  const navigate = useNavigate();
  const onClick = (e: React.MouseEvent, href?: string | number) => {
    e.preventDefault();
    if (href) {
      navigate(href as string);
    }
  };

  return (
    <Row>
      {breadcrumbs.map((breadcrumb, index) => {
        const isLastBreadcrumb = breadcrumbs.length === index + 1;

        if (!breadcrumb) {
          return null;
        }

        return (
          <Row key={index}>
            <BreadcrumbNavigator
              $active={isLastBreadcrumb}
              href={(breadcrumb?.href || "").toString()}
              onClick={(e) => onClick(e, breadcrumb.href)}
            >
              {breadcrumb.label}
            </BreadcrumbNavigator>
            {!isLastBreadcrumb && (
              <ChevronRightIcon style={{ marginRight: "12px" }} />
            )}
          </Row>
        );
      })}
    </Row>
  );
}
