import { parse, format } from "date-fns";

const apisDateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";
export function formatDate(str: string) {
  const date = parse(new Date(str).toISOString(), apisDateFormat, new Date());
  return format(date, "MM/dd/yy HH:mm");
}
export function formatDateWithoutTime(str: string) {
  const date = parse(new Date(str).toISOString(), apisDateFormat, new Date());
  return format(date, "MM/dd/yy");
}
export function zerofyHours(d: Date) {
  return d.setHours(0, 0, 0, 0);
}

export function getSeconds(milliseconds: number) {
  return milliseconds / 1000;
}

export function addDay(timestamp: number) {
  const date = new Date(timestamp);
  return date.setDate(date.getDate() + 1);
}
