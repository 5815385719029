import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";
import { CollapsibleDisplayType } from "./types";

export const Container = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  margin-bottom: 16px;
  margin-top: 4px;
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Separator = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${colors.grey20};
  height: 1px;
  width: 100%;
  margin-right: 8px;
`;

export const HideContainer = styled(Box)<{
  $display: CollapsibleDisplayType;
  $alignItems: React.CSSProperties["alignItems"];
  $justifyContent: React.CSSProperties["justifyContent"];
}>`
  width: 100%;
  display: ${({ $display }) => $display};
  align-items: ${({ $alignItems }) => $alignItems};
  justify-content: ${({ $justifyContent }) => $justifyContent};
`;

export const Label = styled(Typography)`
  display: inline;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.grey70};
  font-weight: 600;
  padding-right: 8px;
  line-clamp: 1;
  white-space: nowrap;
  cursor: default;
`;

export const Tag = styled.p`
  all: unset;
  align-self: flex-end;
  background-color: ${colors.tint3};
  font-size: 13px;
  color: ${colors.orange};
  margin-top: 5px;
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 5px;
`;
