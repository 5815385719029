import { useCallback } from "react";
import { Box } from "@mui/material";
import produce from "immer";
import { exceedLimit } from "components/SelectorsPicker/utils";
import { HelpText } from "components/Typography";
import { Messages } from "constants/messages";
import { LoRType } from "domain/Human";
import {
  Container,
  ListInput,
  ListSelectorContainer,
  SeparationInfo,
} from "./ListSelector.styled";

export function ListSelector({
  lorValue,
  minValue,
  maxValue,
  unit,
  onUpdate,
}: {
  lorValue: LoRType;
  minValue: number;
  maxValue: number;
  unit: string;
  onUpdate: (lor: LoRType) => void;
}) {
  const listValuesHasError = lorValue.listValues
    .split(",")
    .some(
      (lv) => exceedLimit(lv, minValue, maxValue) || Number.isNaN(Number(lv))
    );

  const errorMessage = `Use comma seperated numbers between ${minValue} and ${maxValue}`;

  const onUpdateLocal = useCallback(
    (fn: (draft: LoRType) => void) => {
      onUpdate(produce(lorValue, fn));
    },
    [lorValue, onUpdate]
  );

  const onUpdateListValues = (v: string) =>
    onUpdateLocal((d) => {
      d.listValues = v;
    });

  const handleListValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const listValues = event.target.value;
    return onUpdateListValues(listValues.trim());
  };

  return (
    <Container>
      <ListSelectorContainer>
        <ListInput
          error={listValuesHasError}
          helperText={listValuesHasError ? errorMessage : ""}
          value={lorValue.listValues}
          onChange={handleListValueChange}
        />
        <SeparationInfo>{Messages.commaSeparated}</SeparationInfo>
      </ListSelectorContainer>
      <Box
        alignItems="center"
        component="div"
        display="flex"
        sx={{ width: "100%", position: "relative", bottom: 6 }}
      >
        <HelpText sx={{ mr: 2 }}>
          From {minValue} {unit} to {maxValue} {unit}
        </HelpText>
      </Box>
    </Container>
  );
}
