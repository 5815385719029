export function EthnicityMatchedOnlyIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 6.5V17.5H7.5V6.5H8.5ZM12.5 2.5V21.5H11.5V2.5H12.5ZM4.5 10.5V13.5H3.5V10.5H4.5ZM16.5 6.5V17.5H15.5V6.5H16.5ZM20.5 13.5H19.5V10.5H20.5V13.5Z"
        fill={fill}
        stroke={fill}
      />
    </svg>
  );
}
