export function CapIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="18"
      style={style}
      viewBox="0 0 22 18"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.52938 16.4936L5.52899 16.4934L1.88721 14.9564C1.88716 14.9564 1.88712 14.9563 1.88707 14.9563C1.03588 14.5998 0.630418 13.5991 0.969954 12.7239C1.10594 12.3734 1.35014 12.0782 1.66572 11.8853L1.66621 11.885L5.4334 9.60147C5.43528 8.84663 5.4616 7.13096 5.54118 5.79182L5.54118 5.79179C5.61427 4.56629 6.43051 3.32791 7.87249 2.40145C9.31655 1.47366 11.3986 0.85 14.0395 0.85C16.6355 0.85 18.4181 1.6336 19.5512 2.80411C20.6826 3.97282 21.15 5.51017 21.15 6.98785V11.5196V11.52H21C21.002 12.1837 20.6124 12.7809 20.0183 13.0248L5.52938 16.4936ZM5.52938 16.4936C6.56363 16.9267 7.66844 17.1496 8.78387 17.15H8.78407C9.89394 17.149 10.9935 16.9299 12.0247 16.5043L12.025 16.5042L17.3238 14.3024L17.324 14.3023L17.3387 14.2962L5.52938 16.4936ZM2.15935 13.4L2.15929 13.4C2.16716 13.5417 2.2538 13.6616 2.37504 13.71L2.37764 13.7111L2.37763 13.7111L6.01949 15.2471L2.15935 13.4ZM2.15935 13.4L2.15901 13.3959M2.15935 13.4L2.15901 13.3959M2.15901 13.3959C2.14693 13.2505 2.21624 13.1137 2.33224 13.042M2.15901 13.3959L2.33224 13.042M2.33266 13.0417L6.22756 10.6818C8.94022 11.0417 11.571 11.8951 13.9993 13.2032C14.3547 13.4178 14.7226 13.6093 15.1007 13.7764L11.5367 15.2564C9.76525 15.992 7.7891 15.9887 6.0198 15.2473L2.33266 13.0417ZM2.33266 13.0417C2.33252 13.0418 2.33238 13.0419 2.33224 13.042M2.33266 13.0417L2.33224 13.042M6.74444 9.3963C6.75274 8.5771 6.77658 7.06746 6.85152 5.87494L6.85156 5.87423C6.9006 5.03017 7.58571 4.11193 8.83873 3.39744C10.0852 2.68669 11.8677 2.19501 14.0445 2.19501C15.8865 2.19501 17.3372 2.65132 18.3247 3.47111C19.3086 4.28789 19.8492 5.479 19.8492 6.98785L19.8492 11.52L19.8492 11.5204C19.8497 11.6976 19.7459 11.8527 19.594 11.9153L19.5937 11.9154L17.0204 12.9817C16.1876 12.815 15.3893 12.4969 14.6622 12.0418L14.6624 12.0416L14.6548 12.0374C12.1866 10.6838 9.50932 9.78992 6.74444 9.3963Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
      />
      <path
        d="M16.6323 11.3932L16.6323 11.3932C16.6203 11.6694 16.8258 11.9018 17.0894 11.9145H17.112C17.3677 11.9142 17.5793 11.7047 17.5907 11.4372C17.6199 10.7453 17.6652 9.83497 17.7285 8.65515L17.7285 8.65513C17.8911 5.68346 16.8784 3.78916 15.8208 2.63579C14.7626 1.48172 13.6584 1.06834 13.6382 1.06136L13.638 1.06128C13.3881 0.971823 13.1148 1.1094 13.0282 1.37067C12.9414 1.63226 13.0754 1.91641 13.3256 2.00606C13.3257 2.00608 13.3257 2.00609 13.3257 2.0061L13.3179 2.02796C13.3551 2.04051 17.0305 3.42094 16.7479 8.59788L16.6323 11.3932ZM16.6323 11.3932C16.6614 10.6952 16.7067 9.78411 16.771 8.59914L16.6323 11.3932Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.0464444"
      />
    </svg>
  );
}
