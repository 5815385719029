import { useCallback, useEffect, useMemo, useState } from "react";
import {
  SaveOutlined,
  SaveRounded,
  WarningAmberOutlined,
} from "@mui/icons-material";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import { produce } from "immer";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { JsonDialog } from "components/dialogs/JsonDialog";
import { LeftSideBarContainer } from "features/JobBuilder/job-builder.styled";
import { JobBuilderFooter } from "features/JobBuilder/JobBuilderFooter";
import { useOrgParam } from "hooks/useOrgParam";
import { useBoundStore } from "store/_boundStore";
import { selectCatalogLocationWithLabels } from "store/catalogueDataStore";
import { isSubjobValid } from "./domain";
import { JobBuilder2LeftSidebar } from "./LeftSidebar/left-sidebar";
import { Steps } from "./steps";
import { getAssignActivitiesStep } from "./steps/assign-activities/step";
import { getAssignCharacterGroupStep } from "./steps/assign-character-groups/step";
import { getDefineActivityZonesStep } from "./steps/define-activity-zone/step";
import { getDistributionStep } from "./steps/distribution/step";
import { getGlobalEnvStep } from "./steps/global-env/step";
import { getSetLocationStep } from "./steps/set-location/step";
import { getSetRigLocationStep } from "./steps/set-rig-location/step";
import { ThreeDimensional } from "./steps/threeDimensional";
import {
  selectNumberOfImagesForAllSubjobs,
  selectPickedSubjob,
  selectPickedSubjobHasAtleastOneActivity,
} from "./store";
import { segmentPage } from "./track-service";
import { defaultSubjob, Pipe2Step } from "./types";
import { useAutoSyncDraftWithBackend } from "./useAutoSyncDraftWithBackend";
import { useFetchInitialData } from "./useFetchInitialData";
import { useInputJson } from "./useInputJson";
import { useJobSubmission } from "./useJobSubmission";
import { VerifyBigJobDialog } from "./VerifyBigJobDialog";

const MAX_IMAGES_WITHOUT_VERIFICATION = 5000;

export function JobBuilder2() {
  const { draftId } = useParams<{ draftId: string }>();
  const { orgParam } = useOrgParam();

  const [
    setSubjobs,
    setSelectedStep,
    templateName,
    setTemplateName,
    userEmail,
    userID,
    getUserProfile,
    subjobs,
    pickedSubjobIndex,
    selectedStep,
    pickedSubjob,
    numberOfImagesForAllSubjobs,
    pickedSubjobHasAtleastOneActivity,
    allLocations,
  ] = useBoundStore((state) => [
    state.jobBuilder2.setSubjobs,
    state.jobBuilder2.setSelectedStep,
    state.jobBuilder2.jobName,
    state.jobBuilder2.updateJobName,
    state.profile.data?.email,
    state.profile.data?.id,
    state.profile.getUserProfile,
    state.jobBuilder2.subjobs,
    state.jobBuilder2.pickedSubjobIndex,
    state.jobBuilder2.selectedStep,
    selectPickedSubjob(state),
    selectNumberOfImagesForAllSubjobs(state),
    selectPickedSubjobHasAtleastOneActivity(state),
    selectCatalogLocationWithLabels(state),
  ]);

  useFetchInitialData(orgParam, draftId, setTemplateName);

  const { onShowInputJsonClick, inputJson, setInputJson } =
    useInputJson(templateName);

  const { submitJob, isSubmitting } = useJobSubmission(
    orgParam,
    draftId,
    templateName
  );

  const [showVerifyBigJobDialog, setShowVerifyBigJobDialog] = useState(false);

  const onSubmitJobClick = useCallback(() => {
    if (numberOfImagesForAllSubjobs > MAX_IMAGES_WITHOUT_VERIFICATION) {
      setShowVerifyBigJobDialog(true);
    } else {
      submitJob();
    }
  }, [numberOfImagesForAllSubjobs, submitJob]);

  // Send the user to 1st step (index 0) every time they change subjobs.
  useEffect(() => {
    setSelectedStep(0);
  }, [pickedSubjobIndex, setSelectedStep]);

  // Subjob Actions
  const onUpdateAnySubjob =
    (i: number) => (fn: (draft: typeof defaultSubjob) => void) => {
      setSubjobs(
        produce(subjobs, (d) => {
          fn(d[i]);
        })
      );
    };

  const updatePickedSubjob = onUpdateAnySubjob(pickedSubjobIndex);

  // Generate the 3D element
  const threeDElement = useMemo(() => {
    return <ThreeDimensional />;
  }, []);

  const locationOptions = useMemo(
    () =>
      pickedSubjob.locationOptions
        .map((locationValue) => {
          const index = allLocations.findIndex(
            (location) => location.value === locationValue
          );

          return allLocations[index];
        })
        .filter(Boolean),
    [pickedSubjob.locationOptions, allLocations]
  );

  const steps: Pipe2Step[] = useMemo(
    () => [
      getSetLocationStep(
        updatePickedSubjob,
        pickedSubjob,
        userID,
        locationOptions
      ),
      getSetRigLocationStep(
        threeDElement,
        pickedSubjob.rigLocationIndex,
        updatePickedSubjob
      ),
      getDefineActivityZonesStep(threeDElement, pickedSubjob.activityZones),
      getAssignActivitiesStep(threeDElement, pickedSubjobHasAtleastOneActivity),
      getAssignCharacterGroupStep(threeDElement, pickedSubjob.activityZones),
      getGlobalEnvStep(updatePickedSubjob, pickedSubjob.globalEnv),
      getDistributionStep(pickedSubjob, updatePickedSubjob, userID),
    ],
    [
      updatePickedSubjob,
      pickedSubjob,
      userID,
      locationOptions,
      threeDElement,
      pickedSubjobHasAtleastOneActivity,
    ]
  );

  const selectedPipe2StepLabel = useMemo(
    () => steps[selectedStep].label,
    [steps, selectedStep]
  );

  useEffect(() => {
    if (!userID) {
      return;
    }
    segmentPage("navigated to " + selectedPipe2StepLabel, userID);
  }, [userID, selectedPipe2StepLabel]);

  const [draftState, errorMessage] = useAutoSyncDraftWithBackend(
    orgParam,
    templateName,
    subjobs,
    draftId
  );

  useEffect(() => {
    if (!userEmail) {
      getUserProfile();
    }
  }, [userEmail, getUserProfile]);

  // Form derived state and actions
  const isFormValid = useMemo(() => {
    return !subjobs.find((sj) => !isSubjobValid(sj));
  }, [subjobs]);

  const draftElement = (
    <DraftElement>
      {draftState === "saved" && (
        <>
          <SaveRounded sx={{ fontSize: "20px" }} />
          Draft saved
        </>
      )}
      {draftState === "dirty" && (
        <>
          <SaveOutlined sx={{ fontSize: "20px" }} />
          Unsaved changes
        </>
      )}
      {draftState === "saving" && (
        <>
          <CircularProgress color="warning" size="1rem" />
          Saving...
        </>
      )}
      {draftState === "error" && (
        <>
          <Tooltip
            content={errorMessage ?? "Error saving draft."}
            title="Error saving draft."
          >
            <>
              <WarningAmberOutlined color="warning" sx={{ fontSize: "20px" }} />
              Error saving changes.
            </>
          </Tooltip>
        </>
      )}
    </DraftElement>
  );

  if (!templateName || isSubmitting) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <CircularProgress color="warning" size="2rem" />
      </div>
    );
  }

  return (
    <>
      {showVerifyBigJobDialog && (
        <VerifyBigJobDialog
          submitJob={submitJob}
          totalNumberOfImages={numberOfImagesForAllSubjobs}
          onCancel={() => setShowVerifyBigJobDialog(false)}
        />
      )}

      {inputJson && (
        <JsonDialog
          payload={inputJson}
          title="Generated Job"
          onClose={() => {
            setInputJson(null);
          }}
        />
      )}

      {/* Content including asides */}
      <Box
        component="div"
        display="flex"
        flex="1"
        maxWidth="100vw"
        overflow="hidden"
      >
        <LeftSideBarContainer>
          <JobBuilder2LeftSidebar steps={steps} />
        </LeftSideBarContainer>
        <Steps steps={steps} />
      </Box>
      {!!templateName && (
        <JobBuilderFooter
          draftElement={draftElement}
          isFormValid={isFormValid}
          onShowInputJsonClick={onShowInputJsonClick}
          onSubmitClick={onSubmitJobClick}
        />
      )}
    </>
  );
}

const DraftElement = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-right: 2rem;
  font-size: 1rem;
  opacity: 0.6;
`;
