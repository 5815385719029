export function NoiseThresholdIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 16C6 16.5539 5.55386 17 5 17C4.44614 17 4 16.5539 4 16C4 15.4461 4.44614 15 5 15C5.55386 15 6 15.4461 6 16ZM9.5 12C9.5 12.2739 9.27386 12.5 9 12.5C8.72614 12.5 8.5 12.2739 8.5 12C8.5 11.7261 8.72614 11.5 9 11.5C9.27386 11.5 9.5 11.7261 9.5 12ZM9.5 8C9.5 8.27386 9.27386 8.5 9 8.5C8.72614 8.5 8.5 8.27386 8.5 8C8.5 7.72614 8.72614 7.5 9 7.5C9.27386 7.5 9.5 7.72614 9.5 8ZM20.5 19.5V20.5H3.5V19.5H20.5ZM6 8C6 8.55386 5.55386 9 5 9C4.44614 9 4 8.55386 4 8C4 7.44614 4.44614 7 5 7C5.55386 7 6 7.44614 6 8ZM6 12C6 12.5539 5.55386 13 5 13C4.44614 13 4 12.5539 4 12C4 11.4461 4.44614 11 5 11C5.55386 11 6 11.4461 6 12ZM9.5 16C9.5 16.2739 9.27386 16.5 9 16.5C8.72614 16.5 8.5 16.2739 8.5 16C8.5 15.7261 8.72614 15.5 9 15.5C9.27386 15.5 9.5 15.7261 9.5 16ZM3.5 3.5H20.5V4.5H3.5V3.5ZM13.5 8C13.5 8.27386 13.2739 8.5 13 8.5C12.7261 8.5 12.5 8.27386 12.5 8C12.5 7.72614 12.7261 7.5 13 7.5C13.2739 7.5 13.5 7.72614 13.5 8ZM13.5 12C13.5 12.2739 13.2739 12.5 13 12.5C12.7261 12.5 12.5 12.2739 12.5 12C12.5 11.7261 12.7261 11.5 13 11.5C13.2739 11.5 13.5 11.7261 13.5 12ZM13.5 16C13.5 16.2739 13.2739 16.5 13 16.5C12.7261 16.5 12.5 16.2739 12.5 16C12.5 15.7261 12.7261 15.5 13 15.5C13.2739 15.5 13.5 15.7261 13.5 16Z"
        fill={fill}
        stroke={fill}
      />
    </svg>
  );
}
