import { useMemo } from "react";
import { Vector3, Euler, MathUtils } from "three";
import { rigTypeInformation } from "features/JobBuilder/CamerasAndLightsPreview/components/ThreeDimensionalComponent/constants";
import { getMeanMinMaxValue } from "features/JobBuilder/CamerasAndLightsPreview/utils/getMeanMinMaxValue";
import { JsonRig } from "features/JobBuilder/json-types";
import { selectIsVehicleEnviroment } from "features/JobBuilder/store";
import { useBoundStore } from "store/_boundStore";

export const useRigLocation = (
  rigData: JsonRig | undefined,
  locationValue: "mean" | "min" | "max" = "mean"
) => {
  const isVehicleEnviroment = useBoundStore(selectIsVehicleEnviroment);

  const rigDetails = useMemo(() => {
    if (!rigData)
      return {
        position: new Vector3(),
        rotation: new Euler(),
        origin: new Vector3(),
      };

    const { type, location, preset_name } = rigData;

    const originPosition =
      type === "preset_location"
        ? rigTypeInformation[type].origin[
            !preset_name
              ? "rearview_mirror"
              : (preset_name as keyof typeof rigTypeInformation.preset_location.origin)
          ]
        : rigTypeInformation[
            type as keyof Omit<typeof rigTypeInformation, "preset_location">
          ].origin[isVehicleEnviroment ? "car" : "open"];

    const origin = new Vector3(
      originPosition.x,
      originPosition.y,
      originPosition.z
    );

    const position = new Vector3();
    position.x += getMeanMinMaxValue(location.x)[locationValue];
    position.y += getMeanMinMaxValue(location.y)[locationValue];
    position.z += getMeanMinMaxValue(location.z)[locationValue];

    const rotation = new Euler();
    rotation.x = MathUtils.degToRad(
      getMeanMinMaxValue(location.pitch)[locationValue]
    );
    // presets have initial pitch values
    if (type === "preset_location" && preset_name) {
      const initial_pitch =
        rigTypeInformation.preset_location.initial_pitch[
          preset_name as keyof typeof rigTypeInformation.preset_location.initial_pitch
        ];

      rotation.x += MathUtils.degToRad(initial_pitch);
    }

    rotation.y = MathUtils.degToRad(
      getMeanMinMaxValue(location.yaw)[locationValue]
    );
    rotation.z = MathUtils.degToRad(
      getMeanMinMaxValue(location.roll)[locationValue]
    );
    rotation.order = "ZYX";

    if (type === "manual") {
      origin.copy(position);

      position.set(0, 0, 0);
    }

    return {
      position,
      rotation,
      origin,
    };
  }, [rigData, locationValue, isVehicleEnviroment]);

  return { ...rigDetails };
};
