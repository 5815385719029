import { useLocation } from "react-router";
import { URLParams } from "constants/params";
import { params } from "services/url-params";

export function useURLParam(...keys: URLParams[]) {
  const location = useLocation();
  return params(location.search, false, ...keys);
}

export function useURLParamWithoutDecoding(...keys: URLParams[]) {
  const location = useLocation();
  return params(location.search, true, ...keys);
}
