import React, { useMemo } from "react";
import { AspectRatio, OpenWith } from "@mui/icons-material";
import { Button, SxProps } from "@mui/material";
import { colors } from "components/App/theme";

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    position: "absolute",
    bottom: 16,
    left: 16,
    zIndex: 11,
    padding: 8,
    background: "rgba(255, 255, 255, 0.7)",
    borderRadius: 4,
    boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
  },
  button: {
    margin: "0 8px 0 0",
  },

  lastButton: {
    margin: 0,
  },
};

export type ActivityZonesControlsMode = "draw" | "move";

export function ActivityZonesControls({
  mode,
  setMode,
}: {
  mode: ActivityZonesControlsMode;
  setMode: (mode: ActivityZonesControlsMode) => void;
}) {
  const handleSetMode = (mode: ActivityZonesControlsMode) => () => {
    setMode(mode);
  };

  const activeSX = useMemo<SxProps>(() => {
    return {
      outline: `2px solid ${colors.grey70}`,
      outlineOffset: 0,
      backgroundColor: colors.white,
      color: colors.black,
      pointerEvents: "none",
    };
  }, []);

  return (
    <div style={styles.container}>
      <Button
        color="warning"
        size="small"
        startIcon={<OpenWith />}
        style={styles.button}
        sx={mode === "move" ? activeSX : undefined}
        variant="contained"
        onClick={handleSetMode("move")}
      >
        Control Camera
      </Button>
      <Button
        color="warning"
        size="small"
        startIcon={<AspectRatio />}
        style={styles.button}
        sx={mode === "draw" ? activeSX : undefined}
        variant="contained"
        onClick={handleSetMode("draw")}
      >
        Subdivide Region
      </Button>
    </div>
  );
}
