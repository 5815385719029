import { ChangeEvent, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { InboxIcon } from "assets/icons/Inbox";
import { MediaIcon } from "assets/icons/Media";
import { UploadIcon } from "assets/icons/Upload";
import { Messages } from "constants/messages";
import {
  JobWizardContainer,
  TabContentContainer,
  WizardHeaderMessage,
  WizardTabHeader,
  WizardTabs,
  WizardTabsContainer,
} from "features/HumanJob/styled";
import { TabPanel, a11yProps } from "features/HumanJob/tab-panel";
import { DeepPartial, JsonInput } from "features/JobBuilder/json-types";
import { segmentTrack } from "features/JobBuilder2/track-service";
import { useOrgParam } from "hooks/useOrgParam";
import { useBoundStore } from "store/_boundStore";
import { DraftsTab } from "./drafts-tab";
import { TemplatesTab } from "./templates-tab";
import { UplaodTab } from "./upload-tab";
import { WizardLayout } from "./wizard-layout";

export type HumanJobTabProps = {
  title: string;
  orgName: string;
};

const getTabData = (
  title: string,
  icon: JSX.Element,
  el: (props: HumanJobTabProps) => JSX.Element
) => ({ title, icon, el });

export const tabs = [
  getTabData("Drafts", InboxIcon(), DraftsTab),
  getTabData("Upload json", UploadIcon(), UplaodTab),
  getTabData("Templates", MediaIcon(), TemplatesTab),
];

export type onPickTemplateFn = (
  name: string,
  json: DeepPartial<JsonInput>
) => void;

export function HumanJob() {
  const [currentTab, setCurrentTab] = useState(2);
  const { orgParam } = useOrgParam();
  const [userId] = useBoundStore((state) => [state.profile.data?.id]);

  useEffect(() => {
    if (!userId) {
      return;
    }
    segmentTrack("landed on human job wizard page", userId);
  }, [userId]);

  const handleTabChange = (_event: ChangeEvent<object>, selectedTab: number) =>
    setCurrentTab(selectedTab);

  if (!orgParam) {
    return <CircularProgress />;
  }
  return (
    <WizardLayout>
      <JobWizardContainer>
        <WizardHeaderMessage>
          {Messages.wizardHeaderMessage}
        </WizardHeaderMessage>
        <WizardTabsContainer>
          <WizardTabs
            aria-label="add job wizard tabs"
            value={currentTab}
            onChange={handleTabChange}
          >
            {tabs.map((tab, index) => {
              return (
                <WizardTabHeader
                  key={index}
                  {...a11yProps(index)}
                  icon={tab.icon}
                  label={tab.title}
                />
              );
            })}
          </WizardTabs>
        </WizardTabsContainer>
        <TabContentContainer>
          {tabs.map((t, i) => (
            <TabPanel key={i} index={i} value={currentTab}>
              <t.el orgName={orgParam} title={t.title} />
            </TabPanel>
          ))}
        </TabContentContainer>
      </JobWizardContainer>
    </WizardLayout>
  );
}
