import { TutorialDialog } from "components/dialogs/TutorialDialog";
import { Messages } from "constants/messages";
import { hasRigLocation } from "features/JobBuilder2/domain";
import { StepItem } from "features/JobBuilder2/step-item/step-item";
import { SetRigLocationCore } from "features/JobBuilder2/steps/set-rig-location/core";
import { defaultSubjob, getPipe2Step } from "features/JobBuilder2/types";
import { useBoundStore } from "store/_boundStore";
import { Camera } from "./help-pages/Camera";
import { Wrapper } from "./help-pages/help-pages.styled";
import { Rig } from "./help-pages/Rig";

export const getSetRigLocationStep = (
  threeDElement: JSX.Element,
  rigLocationIndex: number,
  updatePickedSubjob: (fn: (draft: typeof defaultSubjob) => void) => void
) => {
  return getPipe2Step(
    "Set rig location",
    <StepItem disabledPadding>
      <SetRigLocationCore
        rigLocationIndex={rigLocationIndex}
        updatePickedSubjob={updatePickedSubjob}
      >
        {threeDElement}
      </SetRigLocationCore>
    </StepItem>,
    hasRigLocation(rigLocationIndex),
    {
      proTip: Messages.pipe2RigLocationProTip,
      Dialog: (
        <TutorialDialog
          pages={[
            {
              title: "Rig and Camera Settings Overview",
              content: (
                <Wrapper>
                  <Rig />
                </Wrapper>
              ),
            },
            {
              title: "Rig and Camera Settings Overview",
              content: (
                <Wrapper>
                  <Camera />
                </Wrapper>
              ),
            },
          ]}
          onClose={() =>
            useBoundStore.getState().jobBuilder2.setShowHelpDialog(false)
          }
        />
      ),
    }
  );
};
