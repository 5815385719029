import { TutorialDialog } from "components/dialogs/TutorialDialog";
import { hasAtleastOneActivityZone } from "features/JobBuilder2/domain";
import {
  HelpMessage,
  HelpOverlay,
} from "features/JobBuilder2/help-overlay/help-overlay";
import { StepItem } from "features/JobBuilder2/step-item/step-item";
import { defaultSubjob, getPipe2Step } from "features/JobBuilder2/types";
import { useBoundStore } from "store/_boundStore";
import { ActivityZones } from "./help-pages/ActivityZones";
import { Overview } from "./help-pages/Overview";

export const getDefineActivityZonesStep = (
  threeDElement: JSX.Element,
  activityZones: typeof defaultSubjob.activityZones
) => {
  const noDrawnGeomsDefined = activityZones.every(
    (az) => az.geomRegion.type !== "drawn"
  );

  return getPipe2Step(
    "Define activity zones",
    <StepItem disabledPadding>
      <HelpOverlay
        key="activity-zones"
        helpMessage={
          <HelpMessage>
            Subdivide the available
            <br />
            activity region (Marked Pink)
          </HelpMessage>
        }
        startOpen={noDrawnGeomsDefined}
      >
        {threeDElement}
      </HelpOverlay>
    </StepItem>,
    hasAtleastOneActivityZone(activityZones),
    {
      proTip:
        "Here is where you select the locations where the animations will take place",
      Dialog: (
        <TutorialDialog
          pages={[
            {
              title: "Overview",
              content: <Overview />,
            },
            {
              title: "The activity zone with activities and characters",
              content: <ActivityZones />,
            },
          ]}
          onClose={() =>
            useBoundStore.getState().jobBuilder2.setShowHelpDialog(false)
          }
        />
      ),
    }
  );
};
