import React from "react";
import { Alert, Snackbar } from "@mui/material";

function GenericMessage({
  message,
  onDismiss,
  severity,
  duration,
}: {
  message: string;
  onDismiss: () => void;
  severity: "success" | "info" | "warning" | "error";
  duration: number | null;
}) {
  const open = !!message;
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={duration}
      open={open}
      sx={{ zIndex: 5000 }}
      onClose={onDismiss}
    >
      <Alert
        elevation={6}
        severity={severity}
        variant="filled"
        onClose={onDismiss}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export { GenericMessage };
