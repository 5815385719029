import { useBoundStore } from "store/_boundStore";
import { CatalogueAssetsRestClient } from "./CatalogueAssetsRestClient";
import { CatalogueCharactersRestClient } from "./CatalogueCharactersRestClient";
import { RestClient } from "./RestClient";
import { StrapiRestClient } from "./StrapiRestClient";

export const restClient = new RestClient();
export const strapiRestClient = new StrapiRestClient();
export const catalogueCharactersRestClient =
  new CatalogueCharactersRestClient();
export const catalogueAssetsRestClient = new CatalogueAssetsRestClient();

restClient.axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      console.warn("Detected a 401. logging out");
      const data = error.response.data;
      const message = data?.message ? data.message : "Logged out";
      useBoundStore.getState().auth.logout(message);
    }
    return Promise.reject(error);
  }
);
