import { inflate } from "pako";
import { PL2_JOB_STATE_VERSION } from "constants/constants";
import { AssetID, CameraRange, Manifest } from "types/main";
import { OrgPermissions } from "./Permissions";
export type JobStatus =
  | "complete"
  | "submitted"
  | "cancelled"
  | "paused"
  | "accepted";

export interface Job {
  id: string;
  user_name: string;
  job_name: string;
  job_type: number;
  created_at: string; //RFC-3339
  status: JobStatus;
  task_count: number;
  task_completed: number;
  has_presubmission: boolean;
  presubmission_state_version: number;
}

export enum JobType {
  Humans = 0,
  Scenarios = 6,
}

export const canDuplicateJob = (
  row: Job,
  isSupportUser: boolean,
  permissions: OrgPermissions
) => {
  if (row.job_type === JobType.Scenarios) {
    return (
      row.has_presubmission &&
      row.presubmission_state_version === PL2_JOB_STATE_VERSION &&
      (permissions.is_owner || permissions.is_writer)
    );
  }
  return (
    row.job_type === JobType.Humans &&
    (isSupportUser || permissions.is_owner || permissions.is_writer)
  );
};

export const getAssetIDsForDownload = (manifest: Manifest): AssetID[] => {
  const assetIDs: AssetID[] = [];
  const summariesLength = manifest.tracking_number_summaries.length;
  for (let renderID = 0; renderID < summariesLength; renderID++) {
    const taskSummary = manifest.tracking_number_summaries[renderID];
    for (
      let frameNo = taskSummary.min_frame_number;
      frameNo <= taskSummary.max_frame_number;
      frameNo += taskSummary.frame_increment
    ) {
      manifest.all_camera_ranges.forEach((cam) => {
        if (
          trackingNumberHasCamera(
            manifest.all_camera_ranges,
            renderID,
            cam.Name
          )
        ) {
          assetIDs.push({ renderID, frameNumber: frameNo, camName: cam.Name });
        }
      });
    }
  }
  return assetIDs;
};

export function trackingNumberHasCamera(
  cameraRanges: CameraRange[],
  t: number,
  cameraName: string
): boolean {
  for (const cam of cameraRanges) {
    if (cam.Name === cameraName) {
      for (const r of cam.Ranges) {
        if (t >= r[0] && t <= r[1]) {
          return true;
        }
      }
    }
  }
  return false;
}

export const handleJobSpecJson = (arr: ArrayBuffer): object => {
  const binDeflated = new Uint8Array(arr);
  const binInflated = inflate(binDeflated);
  const str = new TextDecoder().decode(binInflated);
  return JSON.parse(str);
};
