export function WarningIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill={fill}
      height="57"
      style={style}
      viewBox="0 0 65 57"
      width="65"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.5024 6.69777L57.4455 49.7934H7.55923L32.5024 6.69777ZM1.8286 46.4809C-0.72202 50.8865 2.45798 56.4184 7.55923 56.4184H57.4455C62.5467 56.4184 65.7267 50.8865 63.1761 46.4809L38.233 3.38527C35.6824 -1.02035 29.3224 -1.02035 26.7717 3.38527L1.8286 46.4809ZM29.1899 23.2934V29.9184C29.1899 31.7403 30.6805 33.2309 32.5024 33.2309C34.3242 33.2309 35.8149 31.7403 35.8149 29.9184V23.2934C35.8149 21.4715 34.3242 19.9809 32.5024 19.9809C30.6805 19.9809 29.1899 21.4715 29.1899 23.2934ZM29.1899 39.8559H35.8149V46.4809H29.1899V39.8559Z"
        fill="#F5AC79"
      />
    </svg>
  );
}
