import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { disableBackdropClickClose } from "services/ui-service";

export function PODialog({
  orgName,
  email,
  onCancel,
  onConfirm,
}: {
  orgName: string;
  email: string;
  onCancel: () => void;
  onConfirm: (email: string) => void;
}) {
  const [inputText, setInputText] = useState("");
  const onSubmitClick = () => onConfirm(inputText);

  return (
    <Dialog
      disableEscapeKeyDown
      open
      onClose={(e, reason) => disableBackdropClickClose(reason, null)}
    >
      <DialogTitle>
        <Box alignItems="flex-start" component="div" display="flex">
          <Box component="div" flexGrow={1}>
            Request for Purchase Order Billing
          </Box>
          <Box component="div" display="flex" flexGrow={0}>
            <CloseIcon onClick={onCancel} />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="div" mt={2}>
          <Box component="div" mb={4}>
            {[
              ["Account Email", email],
              ["Org", orgName],
            ].map((data, i) => (
              <Box key={i} component="div" display="flex">
                <Typography
                  sx={{ display: "block", flex: "1 1 0" }}
                  variant="body2"
                >
                  {data[0]}:
                </Typography>
                <Typography
                  sx={{ display: "block", flex: "1 1 0" }}
                  variant="body2"
                >
                  {" "}
                  {data[1]}
                </Typography>
              </Box>
            ))}
          </Box>
          <Typography variant="body2">
            Please tell us more about your industry and use case (required)
          </Typography>
          <TextField
            autoFocus
            fullWidth
            multiline
            minRows={3}
            type="text"
            value={inputText}
            variant="outlined"
            onChange={(e) => setInputText(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          component="div"
          mb={1}
          ml={2}
          mr={2}
          textAlign="right"
          width="100%"
        >
          <Box component="div" display="inline" mr={2}>
            <Button color="secondary" variant="contained" onClick={onCancel}>
              Cancel
            </Button>
          </Box>
          <Button
            autoFocus
            color="warning"
            variant="contained"
            onClick={onSubmitClick}
          >
            Submit
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
