import React, { useEffect, useMemo, useState } from "react";
import { uniq } from "lodash";
import {
  AbstractCategory,
  DistKey,
  hasAnyAccessories,
  hasAnyFacialAttributes,
  Section,
} from "domain/Human";
import { selectPickedSubjob } from "features/JobBuilder/store";
import { usePreviousValue } from "hooks/usePreviousValue";
import { useBoundStore } from "store/_boundStore";
export const expandedKeyword = "Expanded";

const distGroupsKeys: Array<DistKey> = [
  "expression",
  "gaze",
  "headTurn",
  "hair",
  "facialHair",
  "glasses",
  "headphones",
  "headwear",
  "masks",
  "body",
  "clothing",
  "gesture",
];
const nonDistGroupAlwaysExpandedKeys: Array<keyof Section> = ["rigs"];
const alwaysExpandedNodes = (distGroupsKeys as string[])
  .concat(nonDistGroupAlwaysExpandedKeys)
  .map((s) => `${s}${expandedKeyword}`);

export function useExpandedNodes(loading: boolean) {
  const pickedSubjob = useBoundStore(selectPickedSubjob);
  const humanAndAccessories = useMemo(
    () => ({
      hasAnyFacialAttributes: hasAnyFacialAttributes(pickedSubjob),
      hasAnyAccessories: hasAnyAccessories(pickedSubjob),
      hasAnyBodyDistributionGroups: pickedSubjob.body.length > 0,
      hasAnyClothingDistributionGroups: pickedSubjob.clothing.length > 0,
      hasAnyAnimationsDistributionGroups: pickedSubjob.gesture.length > 0,
    }),
    [pickedSubjob]
  );

  const [expanded, setExpanded] = useState<string[]>(alwaysExpandedNodes);
  const wasLoading = usePreviousValue(loading);

  useEffect(() => {
    if (wasLoading && !loading) {
      const expandedNodes: Array<keyof Section | AbstractCategory> = [];

      if (humanAndAccessories.hasAnyFacialAttributes)
        expandedNodes.push("facialAttributes");

      if (humanAndAccessories.hasAnyAccessories)
        expandedNodes.push("accessories");

      if (humanAndAccessories.hasAnyBodyDistributionGroups)
        expandedNodes.push("body");

      if (humanAndAccessories.hasAnyClothingDistributionGroups)
        expandedNodes.push("clothing");

      if (humanAndAccessories.hasAnyAnimationsDistributionGroups)
        expandedNodes.push("gesture");

      setExpanded([...alwaysExpandedNodes, ...expandedNodes]);
    }
  }, [loading, humanAndAccessories, wasLoading]);

  const handleNodeToggle = (
    event: React.ChangeEvent<object>,
    nodeIds: string[]
  ) => {
    setExpanded(uniq([...alwaysExpandedNodes, ...nodeIds]));
  };

  return [expanded, handleNodeToggle] as const;
}
