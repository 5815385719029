import axios from "axios";
import { CATALOGUE_CHARACTERS_BASE_URL } from "constants/constants";
import { PrefabGroup } from "domain/Prefabs";

export class CatalogueCharactersRestClient {
  axiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: CATALOGUE_CHARACTERS_BASE_URL,
    });
  }

  async getPrefabGroups(): Promise<PrefabGroup[]> {
    const result = await this.axiosInstance.get(`/characters/groups`);
    const data = result.data.results;
    return data || [];
  }
}
