import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Box, Button, TextField } from "@mui/material";
import { colors } from "components/App/theme";

export function UserNameInlineEdit({
  firstName,
  lastName,
  isOwner,
  onSave,
}: {
  firstName: string;
  lastName: string;
  isOwner: boolean;
  onSave: (firstName: string, lastName: string) => void;
}) {
  const [edit, setEdit] = useState(false);
  const [internalFirstName, setInternalFirstName] = useState("");
  const [internalLastName, setInternalLastName] = useState("");

  useEffect(() => {
    setInternalFirstName(firstName);
  }, [firstName]);

  useEffect(() => {
    setInternalLastName(lastName);
  }, [lastName]);

  return (
    <Box
      bgcolor={colors.white}
      component="div"
      minWidth={edit ? "400px" : "100%"}
      pr={edit ? 2 : 0}
      width="100%"
      zIndex={999}
    >
      <Box
        alignItems={edit ? "flex-end" : "center"}
        component="div"
        display="flex"
      >
        {edit ? (
          <Box component="div" display="flex">
            <TextField
              autoFocus
              data-testid="inline-edit-input"
              placeholder="First name"
              value={internalFirstName}
              variant="standard"
              onChange={(e) => setInternalFirstName(e.target.value)}
            ></TextField>
            <TextField
              autoFocus
              data-testid="inline-edit-input"
              placeholder="Last name"
              value={internalLastName}
              variant="standard"
              onChange={(e) => setInternalLastName(e.target.value)}
            ></TextField>
          </Box>
        ) : (
          <Box
            component="div"
            flexGrow={1}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {`${firstName} ${lastName}`}
          </Box>
        )}
        {isOwner && (
          <Box component="div" display="flex" ml={1}>
            {edit ? (
              <>
                <Box component="div" mr={1}>
                  <Button
                    color="warning"
                    data-testid="save-inline-button"
                    size="small"
                    variant="contained"
                    onClick={() => {
                      if (
                        internalFirstName !== firstName ||
                        internalLastName !== lastName
                      ) {
                        onSave(internalFirstName, internalLastName);
                      }
                      setEdit(false);
                    }}
                  >
                    Save
                  </Button>
                </Box>
                <Box component="div">
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={() => {
                      setInternalFirstName(firstName);
                      setInternalLastName(lastName);
                      setEdit(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </>
            ) : (
              <Box component="div" onClick={() => setEdit(true)}>
                <Box component="div" ml={1}>
                  <IconButton data-testid="pencil-icon" size="small">
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
