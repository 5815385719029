import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import * as Sentry from "@sentry/react";
import fileDownload from "js-file-download";
import { useLocation, useNavigate } from "react-router-dom";
import { Alerts } from "components/Alerts";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { TopAppBar2 } from "components/Common/TopAppBar2";
import { ErrorBoundaryFallbackMessage } from "components/ErrorBoundaryFallbackMessage";
import { Routes } from "constants/routes";
import { childKeys, Section } from "domain/Human";
import { onPickTemplateFn } from "features/HumanJob";
import { JobBuilderAccessories } from "features/JobBuilder/Accessories";
import { JobBuilderAnimations } from "features/JobBuilder/Animations";
import { JobBuilderBody } from "features/JobBuilder/Body";
import { BreadcrumbsProvider } from "features/JobBuilder/breadcrumbs";
import { JobBuilderClothing } from "features/JobBuilder/Clothing";
import { DownloadJobSpec } from "features/JobBuilder/download-job-spec";
import { JobBuilderEnvironment } from "features/JobBuilder/Environment";
import { JobBuilderFacialAttributes } from "features/JobBuilder/FacialAttributes";
import { generateInputJson } from "features/JobBuilder/form-to-input-json";
import { JobBuilderIdentities } from "features/JobBuilder/Identities/Identities";
import { LeftSideBarContainer } from "features/JobBuilder/job-builder.styled";
import { JobBuilderFooter } from "features/JobBuilder/JobBuilderFooter";
import { templateToSubjob } from "features/JobBuilder/json-to-form";
import { JobBuilderLayout } from "features/JobBuilder/layout";
import { JobBuilderRigs } from "features/JobBuilder/rigs";
import { ScenesPerIdentity } from "features/JobBuilder/ScenesPerIdentity";
import {
  selectNumberOfImagesForActiveSubjobAsString,
  selectPickedSubjobIndex,
  selectSelectedHumanObjectPart,
  selectSubjobs,
  selectTemplate,
} from "features/JobBuilder/store";
import { isTemplate } from "features/JobBuilder/types";
import { useOrgParam } from "hooks/useOrgParam";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { toCharactersInputJSON } from "./helpers";
import { JobBuilderLeftSidebar } from "./LeftSidebar";

export type updateRegularPropFn = (prop: keyof Section) => (value: any) => void;

const facial = Object.fromEntries(
  childKeys("facialAttributes")
    .filter((key) => !["gaze", "headTurn"].includes(key))
    .map((key) => [key, <JobBuilderFacialAttributes key="1" />])
);
const accessories = Object.fromEntries(
  childKeys("accessories").map((key) => [
    key,
    <JobBuilderAccessories key="2" />,
  ])
);
const partComponentMap: {
  [key in keyof Partial<Section>]: JSX.Element;
} = {
  demographicBuilder: <JobBuilderIdentities />,
  identities: <ScenesPerIdentity />,
  hdri: <JobBuilderEnvironment />,
  ...facial,
  rigs: <JobBuilderRigs />,
  ...accessories,
  body: <JobBuilderBody />,
  clothing: <JobBuilderClothing />,
  gesture: <JobBuilderAnimations />,
};
export function CharactersBuilder() {
  const navigate = useNavigate();
  const { orgParam } = useOrgParam();
  const { state, search } = useLocation();

  const { setSuccessMessage, setErrorMessage } =
    useBoundStore.getState().message;

  const userEmail = useBoundStore((state) => state.profile.data?.email);
  const { getUserProfile } = useBoundStore.getState().profile;

  // JobBuilder state
  const template = useBoundStore(selectTemplate);
  const subjobs = useBoundStore(selectSubjobs);
  const pickedSubjobIndex = useBoundStore(selectPickedSubjobIndex);
  const selectedHumanObjectPart = useBoundStore(selectSelectedHumanObjectPart);

  const {
    saveEnums,
    saveGestures,
    setSubjobs,
    setPickedSubjobIndex,
    setTemplate,
    goToNextPart,
    goToPrevPart,
  } = useBoundStore.getState().jobBuilder;

  // UI State
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const displayNumberOfImagesForSelectedSection = useBoundStore(
    selectNumberOfImagesForActiveSubjobAsString
  );

  const allSectionsHaveIds = useMemo(() => {
    return !subjobs.filter((s) => !s.identities.ids.length).length;
  }, [subjobs]);

  const breadcrumbsContextValue = useMemo(
    () => ({
      selectedSubjobIndex: pickedSubjobIndex,
      numberOfImageForSelectedSection: displayNumberOfImagesForSelectedSection,
    }),
    [displayNumberOfImagesForSelectedSection, pickedSubjobIndex]
  );

  const onSubmitClick = async () => {
    if (!template || !userEmail) {
      return;
    }
    const jobSpec = toCharactersInputJSON(
      generateInputJson(template.json, subjobs)
    );

    if (!jobSpec) return;

    try {
      setIsSubmitting(true);
      await restClient.submitCharactersJob(
        orgParam,
        jobSpec,
        userEmail,
        template.name
      );
      console.log(jobSpec);
      setSuccessMessage(`${template.name} has been submitted`);
      setIsSubmitting(false);
      navigate(`${Routes.JOBS}?org=${orgParam}`);
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
      setIsSubmitting(false);
    }
  };

  const onDownloadJobSpecClick = () => {
    if (!template || !userEmail) return;

    const jobSpec = generateInputJson(template.json, subjobs);
    fileDownload(JSON.stringify(jobSpec, null, 2), `${template.name}.json`);
  };

  const onPickTemplate: onPickTemplateFn = useCallback(
    (name, json) => {
      if (!json.humans) {
        return;
      }

      setPickedSubjobIndex(0);
      setTemplate({ name, json });
      setSubjobs(templateToSubjob(json.humans));
    },
    [setPickedSubjobIndex, setSubjobs, setTemplate]
  );

  useEffect(() => {
    if (!userEmail) {
      getUserProfile();
    }
  }, [userEmail, getUserProfile]);

  // enums
  useEffect(() => {
    async function getEnums() {
      try {
        setLoading(true);
        const incomingEnums = await restClient.getEnums();
        saveEnums(incomingEnums);
        setLoading(false);
      } catch (error) {
        setErrorMessage(restClient.APIErrorMessage(error));
      }
    }
    getEnums();
  }, [setErrorMessage, saveEnums]);
  // gestures
  useEffect(() => {
    async function getGestures() {
      try {
        setLoading(true);
        const incomingGestures = await restClient.getGestures();
        saveGestures(incomingGestures);
        setLoading(false);
      } catch (error) {
        setErrorMessage(restClient.APIErrorMessage(error));
      }
    }
    getGestures();
  }, [setErrorMessage, saveGestures]);

  useEffect(() => {
    if (!isTemplate(state)) {
      navigate(Routes.HUMAN_JOB + search);
    } else {
      onPickTemplate(state.name, state.json);
    }
  }, [navigate, onPickTemplate, search, state]);

  if (isSubmitting) {
    return <FullScreenProgress />;
  }

  return (
    <JobBuilderLayout>
      <Alerts />
      <TopAppBar2
        fullWidth
        title={
          <Box alignItems="center" component="div" display="flex">
            Characters
            <Box component="div" ml={2}>
              <DownloadJobSpec onClick={onDownloadJobSpecClick} />
            </Box>
          </Box>
        }
      />
      {/* content including asides */}
      {loading && <FullScreenProgress />}

      <Box component="div" display="flex" flex="1">
        {/* Sidebar and its error boundary */}
        <Sentry.ErrorBoundary
          fallback={() => (
            <LeftSideBarContainer>
              <ErrorBoundaryFallbackMessage />
            </LeftSideBarContainer>
          )}
        >
          <LeftSideBarContainer>
            <JobBuilderLeftSidebar loading={loading} />
          </LeftSideBarContainer>
        </Sentry.ErrorBoundary>

        {/* page including right aside */}
        <Box
          component="div"
          flex="5"
          height="calc(100vh - 120px)"
          overflow="auto"
          style={{
            background: "rgba(203, 203, 210, 0.15)",
            paddingBottom: 0,
            position: "relative",
          }}
        >
          <Sentry.ErrorBoundary
            key={selectedHumanObjectPart.toString()}
            fallback={() => <ErrorBoundaryFallbackMessage />}
          >
            <BreadcrumbsProvider value={breadcrumbsContextValue}>
              {partComponentMap[selectedHumanObjectPart]}
            </BreadcrumbsProvider>
          </Sentry.ErrorBoundary>
        </Box>
      </Box>
      {!!template && (
        <JobBuilderFooter
          canGoBack={() => selectedHumanObjectPart !== "demographicBuilder"}
          canGoNext={() => selectedHumanObjectPart !== "clothing"}
          isFormValid={allSectionsHaveIds}
          onBackClick={goToPrevPart}
          onNextClick={goToNextPart}
          onSubmitClick={onSubmitClick}
        />
      )}
    </JobBuilderLayout>
  );
}
