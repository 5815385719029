export function LensShiftHIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5 12C20.5 7.31386 16.6861 3.5 12 3.5C7.31386 3.5 3.5 7.31386 3.5 12C3.5 16.6861 7.31386 20.5 12 20.5C16.6861 20.5 20.5 16.6861 20.5 12ZM2.5 12C2.5 6.75614 6.75614 2.5 12 2.5C17.2439 2.5 21.5 6.75614 21.5 12C21.5 17.2439 17.2439 21.5 12 21.5C6.75614 21.5 2.5 17.2439 2.5 12Z"
        fill={fill}
        stroke={fill}
      />
      <path
        d="M17.3 12C17.3 9.07786 14.9221 6.7 12 6.7C9.07786 6.7 6.7 9.07786 6.7 12C6.7 14.9221 9.07786 17.3 12 17.3C14.9221 17.3 17.3 14.9221 17.3 12ZM6.5 12C6.5 8.96414 8.96414 6.5 12 6.5C15.0359 6.5 17.5 8.96414 17.5 12C17.5 15.0359 15.0359 17.5 12 17.5C8.96414 17.5 6.5 15.0359 6.5 12Z"
        fill="black"
        stroke={fill}
      />
    </svg>
  );
}
