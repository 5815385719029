import React from "react";
import { Grid } from "@mui/material";
import { TutorialCard } from "components/tutorials/TutorialCard";
import { TutorialCardHeader } from "components/tutorials/TutorialCardHeader";
import { TutorialCommand } from "components/tutorials/TutorialCommand";
import { TutorialCraeteApiKey } from "components/tutorials/TutorialCreateApiKey";
import { TutorialDownloadOs } from "components/tutorials/TutorialDownloadOs";
import { TutorialDownloadSampleJob } from "components/tutorials/TutorialDownloadSampleJob";

type WelcomeToOrgProps = { orgName: string | undefined };

function WelcomeToOrg(props: WelcomeToOrgProps) {
  const { orgName } = props;

  const sampleCommand = (() => {
    return "synthesis humans create first_job.json";
  })();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <TutorialCard>
            <TutorialCardHeader stepNumber={1} title="Download CLI" />
            <TutorialDownloadOs />
          </TutorialCard>
        </Grid>
        <Grid item md={3} xs={12}>
          <TutorialCard>
            <TutorialCardHeader stepNumber={2} title="Create API key" />
            <TutorialCraeteApiKey orgName={orgName} />
          </TutorialCard>
        </Grid>
        <Grid item md={3} xs={12}>
          <TutorialCard>
            <TutorialCardHeader stepNumber={3} title="Download Sample Job" />
            <TutorialDownloadSampleJob />
          </TutorialCard>
        </Grid>
        <Grid item md={3} xs={12}>
          <TutorialCard>
            <TutorialCardHeader
              stepNumber={4}
              title="Run the following command"
            />
            <TutorialCommand sampleCommand={sampleCommand} />
          </TutorialCard>
        </Grid>
      </Grid>
    </>
  );
}

export { WelcomeToOrg };
