import { Box, Button, DialogContent } from "@mui/material";
import styled, { css } from "styled-components";
import { colors } from "components/App/theme";

const sharedButtonStyles = css`
  width: 166px;
  font-size: 12px;
  box-shadow: none;
`;

export const StyledDialogContent = styled(DialogContent)`
  width: 327px;
  padding-bottom: 0px;
`;

export const ActionsWrapper = styled(Box)`
  width: 100%;
  text-align: right;
  align-items: center;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 8px;
  }
`;

export const DeleteButton = styled(Button)`
  ${sharedButtonStyles}
  background: ${colors.red};
`;

export const CancelButton = styled(Button)`
  ${sharedButtonStyles}
  color: ${colors.orange};
  background: ${colors.tint5};
`;
