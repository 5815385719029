import { useMemo } from "react";
import PitchImage from "assets/diagrams/head-turn-pitch.svg";
import RollImage from "assets/diagrams/head-turn-roll.svg";
import YawImage from "assets/diagrams/head-turn-yaw.svg";
import { HeadTurnIcon } from "assets/icons";
import { SelectorsPicker } from "components/SelectorsPicker";
import { Messages } from "constants/messages";
import { defaultHeadTurn } from "domain/Human";
import { EmptyState } from "features/JobBuilder/empty-state";
import { convertHeadTurnStateToJson } from "features/JobBuilder/form-to-input-json";
import {
  getHeadTurn,
  getHumanObject,
} from "features/JobBuilder/json-extracter";
import { JsonManagerNewUI } from "features/JobBuilder/json-manager-new-ui";
import { convertHeadTurns } from "features/JobBuilder/json-to-form";
import { DeepPartial, JsonHuman } from "features/JobBuilder/json-types";
import { ContentCard, Page } from "features/JobBuilder/layout";
import { useDistPage } from "features/JobBuilder/useDistPage";
import { useProTip } from "hooks/UseProTip";
import { useBoundStore } from "store/_boundStore";

const id = "headTurn";
export function JobBuilderHeadTurn() {
  const { ProTip, showingProTip } = useProTip();
  const HeadTurnProTip = () => ProTip({ label: Messages.headTurnProTip });
  const {
    value,
    selectedGroupIndex,
    onUpdate,
    onAdd,
    numberOfImages,
    isSummingTo100,
    onUpdatePart,
    onUpdatePercent,
  } = useDistPage(id);
  const selectedHeadTurnGroup = value[selectedGroupIndex];
  const onUpdatePitch = onUpdatePart("pitch");
  const onUpdateYaw = onUpdatePart("yaw");
  const onUpdateRoll = onUpdatePart("roll");

  const { setErrorMessage, setSuccessMessage } =
    useBoundStore.getState().message;

  const onIndecesReceived = (
    indices: number[],
    humansArr: DeepPartial<JsonHuman>[]
  ) => {
    try {
      onUpdate(
        convertHeadTurns(
          getHeadTurn(getHumanObject(humansArr, indices[0])),
          value
        )
      );
      setSuccessMessage("updated headTurn");
    } catch (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
      }
    }
  };

  // TODO: Type this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDoneJson = (json: any) => {
    const updated = convertHeadTurns(json, value);
    onUpdate(updated);
  };
  const jsonValue = useMemo(() => convertHeadTurnStateToJson(value), [value]);

  const center = (
    <JsonManagerNewUI
      emptyState={
        !selectedHeadTurnGroup ? (
          <EmptyState
            description="Head turn controls the head turn variations of the character models. Default head turn variations are applied, some sample results are shown below:"
            folder="headturn"
            icon={<HeadTurnIcon style={{ width: 48, height: 48 }} />}
            title="Default head turn settings applied"
          />
        ) : null
      }
      groupIndex={selectedGroupIndex + 1}
      groupPercentage={selectedHeadTurnGroup?.percent}
      id={`headTurn-${selectedGroupIndex + 1}`}
      isSummingTo100={isSummingTo100}
      jsonValue={jsonValue}
      numberOfImages={numberOfImages}
      parentTitle="Facial Attributes"
      parentTitleIcon="FacialAttributesIcon"
      title="Head Turn"
      titleIcon="HeadTurnIcon"
      unknownIndicesKeys={["Human"]}
      onClickAddButton={onAdd}
      onDoneEditJson={onDoneJson}
      onIndecesReceived={onIndecesReceived}
      onUpdatePercent={onUpdatePercent}
    >
      {showingProTip && (
        <ContentCard>
          <HeadTurnProTip />
        </ContentCard>
      )}
      <ContentCard style={{ padding: "8px" }}>
        <SelectorsPicker
          noSpacing
          icon={"PitchIcon"}
          imgSrc={PitchImage}
          limits={defaultHeadTurn.pitch}
          lorValue={selectedHeadTurnGroup?.pitch}
          title="Pitch"
          unit={"degree(s)"}
          onUpdate={onUpdatePitch}
        />
      </ContentCard>
      <ContentCard style={{ padding: "8px" }}>
        <SelectorsPicker
          noSpacing
          icon={"YawIcon"}
          imgSrc={YawImage}
          limits={defaultHeadTurn.yaw}
          lorValue={selectedHeadTurnGroup?.yaw}
          title="Yaw"
          unit={"degree(s)"}
          onUpdate={onUpdateYaw}
        />
      </ContentCard>
      <ContentCard style={{ padding: "8px" }}>
        <SelectorsPicker
          noSpacing
          icon={"RollIcon"}
          imgSrc={RollImage}
          limits={defaultHeadTurn.roll}
          lorValue={selectedHeadTurnGroup?.roll}
          title="Roll"
          unit={"degree(s)"}
          onUpdate={onUpdateRoll}
        />
      </ContentCard>
    </JsonManagerNewUI>
  );
  return <Page center={center} />;
}
