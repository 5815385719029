import { useMemo } from "react";
import glassesMetalness from "assets/diagrams/glasses-metalness.svg";
import glassesTransparency from "assets/diagrams/glasses-transparency.svg";
import SexMatchedOnlyDiagram from "assets/diagrams/hair-relative-density-sex-matched-only.svg";
import { GlassesIcon } from "assets/icons";
import { BooleanSelector } from "components/BooleanSelector";
import { ProTip } from "components/ProTip";
import { RightAsidePreviewFull } from "components/RightAsidePreviewFull";
import { PreviewContent } from "components/RightAsidePreviewFull/PreviewContent";
import { SelectorsPicker } from "components/SelectorsPicker";
import { glassesImageURL } from "constants/constants";
import { Messages } from "constants/messages";
import { defaultGlassesSelection } from "domain/Human";
import { EmptyState } from "features/JobBuilder/empty-state";
import { convertGlassesStateToJson } from "features/JobBuilder/form-to-input-json";
import { getGlasses, getHumanObject } from "features/JobBuilder/json-extracter";
import { JsonManagerNewUI } from "features/JobBuilder/json-manager-new-ui";
import { convertGlasses } from "features/JobBuilder/json-to-form";
import { DeepPartial, JsonHuman } from "features/JobBuilder/json-types";
import { ContentCard, Page } from "features/JobBuilder/layout";
import { MultiOptionImageGrid } from "features/JobBuilder/MultiOptionImageGrid";
import {
  glassColorsSelector,
  glassStylesSelector,
} from "features/JobBuilder/store";
import { useDistPage } from "features/JobBuilder/useDistPage";
import {
  useImageSelection,
  useSelectionOutcomeCount,
} from "features/JobBuilder/useImageSelection";
import { changeCheckEventValue } from "features/JobBuilder/utils";
import { useBoundStore } from "store/_boundStore";

const id = "glasses";
export function JobBuilderGlasses() {
  const {
    isSummingTo100,
    onUpdate,
    onUpdatePart,
    value,
    selectedGroupIndex,
    onUpdatePercent,
    onAdd,
    numberOfImages,
    toggleDistListItem,
  } = useDistPage(id);
  const selectedGlassesGroup = value[selectedGroupIndex];

  const [setErrorMessage, setSuccessMessage, colorOptions, styleOptions] =
    useBoundStore((s) => [
      s.message.setErrorMessage,
      s.message.setSuccessMessage,
      glassColorsSelector(s),
      glassStylesSelector(s),
    ]);
  const styleUIState = useImageSelection(
    selectedGlassesGroup ? selectedGlassesGroup.name : [],
    styleOptions,
    glassesImageURL("style"),
    onUpdatePart("name"),
    "Styles",
    "glasses-styles",
    "StyleIcon",
    toggleDistListItem("name", styleOptions)
  );
  const colorUIState = useImageSelection(
    selectedGlassesGroup ? selectedGlassesGroup.lensColor : [],
    colorOptions,
    glassesImageURL("color"),
    onUpdatePart("lensColor"),
    "Lens Color",
    "lens-color",
    "LensShiftHIcon",
    toggleDistListItem("lensColor", colorOptions)
  );
  const onUpdateSexMatching = onUpdatePart("sexMatching");
  const onUpdateTransparency = onUpdatePart("transparency");
  const onUpdateMetalness = onUpdatePart("metalness");

  const outcomes = useSelectionOutcomeCount([styleUIState, colorUIState]);

  const onIndecesReceived = (
    indices: number[],
    humansArr: DeepPartial<JsonHuman>[]
  ) => {
    try {
      onUpdate(
        convertGlasses(getGlasses(getHumanObject(humansArr, indices[0])), value)
      );
      setSuccessMessage("updated glasses");
    } catch (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
      }
    }
  };

  // TODO: Type json
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDoneJson = (json: any) => {
    const glasses = convertGlasses(json, value);
    onUpdate(glasses);
  };

  const jsonValue = useMemo(() => convertGlassesStateToJson(value), [value]);

  const isEmptyState = !selectedGlassesGroup;
  const center = (
    <JsonManagerNewUI
      key={`glassesGroup-${selectedGroupIndex}`}
      emptyState={
        isEmptyState ? (
          <EmptyState
            description="Glasses controls the glasses variations of the character models. Default glasses variations are applied, some sample results are shown below:"
            folder="glasses"
            icon={<GlassesIcon style={{ width: 48, height: 48 }} />}
            title="Default glasses settings applied"
          />
        ) : null
      }
      groupIndex={selectedGroupIndex + 1}
      groupPercentage={selectedGlassesGroup?.percent}
      id={`glassesGroup-${selectedGroupIndex}`}
      isSummingTo100={isSummingTo100}
      jsonValue={jsonValue}
      numberOfImages={numberOfImages}
      parentTitle="Accessories"
      parentTitleIcon="AccessoriesIcon"
      title={`Glasses`}
      titleCount={outcomes}
      titleIcon="GlassesIcon"
      unknownIndicesKeys={["Human"]}
      onClickAddButton={onAdd}
      onDoneEditJson={onDoneJson}
      onIndecesReceived={onIndecesReceived}
      onUpdatePercent={onUpdatePercent}
    >
      <ContentCard style={{ marginBottom: "16px" }}>
        <ProTip label={Messages.glassesProTip} />
        <MultiOptionImageGrid {...styleUIState} />
      </ContentCard>

      <ContentCard style={{ marginBottom: "16px" }}>
        <MultiOptionImageGrid {...colorUIState} />
      </ContentCard>

      <ContentCard style={{ marginBottom: "16px" }}>
        <SelectorsPicker
          icon={"TransparencyIcon"}
          imgSrc={glassesTransparency}
          limits={defaultGlassesSelection.transparency}
          lorValue={selectedGlassesGroup?.transparency}
          title="Transparency"
          unit=""
          onUpdate={onUpdateTransparency}
        />
      </ContentCard>

      <ContentCard style={{ marginBottom: "16px" }}>
        <SelectorsPicker
          icon={"MetalnessIcon"}
          imgSrc={glassesMetalness}
          limits={defaultGlassesSelection.metalness}
          lorValue={selectedGlassesGroup?.metalness}
          title="Metalness"
          unit=""
          onUpdate={onUpdateMetalness}
        />
      </ContentCard>

      <ContentCard style={{ marginBottom: "16px" }}>
        <BooleanSelector
          description={Messages.sexMatchedDescriptionForGlasses}
          icon={"SexMatchedOnlyIcon"}
          imgSrc={SexMatchedOnlyDiagram}
          label="Enabled"
          title="Sex Matched Only"
          value={selectedGlassesGroup?.sexMatching}
          onUpdate={changeCheckEventValue(onUpdateSexMatching)}
        />
      </ContentCard>
    </JsonManagerNewUI>
  );

  const rightAside = (
    <RightAsidePreviewFull previewTitle="Preview">
      <PreviewContent data={styleUIState.selectedImages} title={"STYLE"} />
      <PreviewContent data={colorUIState.selectedImages} title={"COLOR"} />
    </RightAsidePreviewFull>
  );

  return (
    <Page
      fixedRightSide
      center={center}
      hideRightAside={isEmptyState}
      rightAside={rightAside}
    />
  );
}
