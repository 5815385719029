import { useMemo } from "react";
import { Vec2 } from "@flasd/modeling/src/maths/vec2";
import * as THREE from "three";
import { Vector3 } from "three";
import { fromVec2ToVector2 } from "features/JobBuilder2/steps/threeDimensional/utils/polygon-converters";
import { useBoundStore } from "store/_boundStore";
import { selectPL2LocationDimension } from "store/selectors/selectPL2LocationDimension";
const zIndex = 1;

const material = new THREE.LineBasicMaterial({
  color: 0x444e48,
  opacity: 0.7,
  transparent: true,
});

const verticalLine = (pointOnXAxis: number, height: number) =>
  [
    [pointOnXAxis, 0],
    [pointOnXAxis, height],
  ] as Vec2[];

const horizontalLine = (pointOnYAxis: number, width: number) =>
  [
    [0, pointOnYAxis],
    [width, pointOnYAxis],
  ] as Vec2[];

export function MetricGrid() {
  const { width, height } = useBoundStore(selectPL2LocationDimension);

  const geometry = useMemo(() => {
    // if the location's height or width is bigger than 15meter make the spacing of the grid every 1m otherwise 0.25m
    const spacing = Math.max(width, height) > 18 ? 1 : 0.25;
    const verticalLinesCount = Math.ceil(width / spacing);
    const horizontalLinesCount = Math.ceil(height / spacing);

    const verticalLines = new Array(verticalLinesCount)
      .fill(0)
      .map((_, i) => verticalLine(i * spacing, height));

    const horizontalLines = new Array(horizontalLinesCount)
      .fill(0)
      .map((_, i) => horizontalLine(height - i * spacing, width));

    return new THREE.BufferGeometry().setFromPoints(
      verticalLines.concat(horizontalLines).flat(1).map(fromVec2ToVector2)
    );
  }, [width, height]);

  return (
    <lineSegments
      geometry={geometry}
      material={material}
      position={new Vector3(-width / 2, -height / 2, zIndex)}
    />
  );
}
