import React, { forwardRef } from "react";
import { Input as BaseInput, InputProps } from "@mui/material";
import { colors } from "components/App/theme";

export const Input = forwardRef(function Input(
  props: InputProps,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <BaseInput
      {...props}
      ref={ref}
      sx={{
        borderRadius: "4px",
        border: "1px solid #e0e0e0",
        padding: "4px 12px",
        minWidth: "320px",
        "&:hover": {
          borderColor: colors.bright[2],
        },
        "&:focus-within": {
          borderColor: colors.bright[2],
        },
        "&::before": {
          display: "none",
        },
        "&::after": {
          display: "none",
        },
        ...(props.sx || {}),
      }}
    />
  );
});
