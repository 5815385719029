import React, { useCallback, useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { Mode, PaymentInfo } from "components/PaymentInfo";
import { useOrgParam } from "hooks/useOrgParam";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { selectedOrgSelector } from "store/orgStore";
import { OrgBilling } from "types/main";

export function EditPaymentInfo() {
  const { orgParam } = useOrgParam();
  const { setErrorMessage } = useBoundStore.getState().message;
  const [loading, setLoading] = useState(true);
  const [orgBilling, setOrgBilling] = useState<OrgBilling | null>(null);
  const org = useBoundStore(selectedOrgSelector(orgParam));

  const fetchBillingInfo = useCallback(async () => {
    if (!org) {
      return;
    }
    setLoading(true);
    try {
      const billing = await restClient.getCurrentBillingInfo(org.name);
      setOrgBilling(billing);
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  }, [org, setErrorMessage]);

  useEffect(() => {
    fetchBillingInfo();
  }, [fetchBillingInfo]);

  return (
    <Grid container justifyContent="center">
      <Grid item md={8} xs={12}>
        <Box component="div" display="flex" justifyContent="center">
          {loading && <FullScreenProgress />}
          {!!orgBilling && (
            <PaymentInfo
              mode={
                orgBilling.has_pending_po_request
                  ? Mode.EditOnly
                  : Mode.EditAndOfferPO
              }
              orgName={orgParam}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
