import { Typography } from "@mui/material";
import cameraImage from "components/dialogs/StageElementDialog/assets/camera.svg";
import {
  AnimatedImage,
  CameraTipWrapper,
  ContentWrapper,
  LightTipWrapper,
  RigTipWrapper,
  DialogParagraph,
} from "components/dialogs/StageElementDialog/StageElementDialog.styled";
import { Messages } from "constants/messages";

export function Camera() {
  return (
    <ContentWrapper>
      <LightTipWrapper>
        <Typography color="white">Light</Typography>
        <DialogParagraph>{Messages.stageElementStep2Light}</DialogParagraph>
      </LightTipWrapper>

      <RigTipWrapper>
        <Typography color="white">Rig</Typography>
        <DialogParagraph>{Messages.stageElementStep2Rig}</DialogParagraph>
      </RigTipWrapper>

      <CameraTipWrapper current>
        <Typography color="white">Camera</Typography>
        <DialogParagraph>{Messages.stageElementStep2Camera}</DialogParagraph>
      </CameraTipWrapper>
      <AnimatedImage src={cameraImage} />
    </ContentWrapper>
  );
}
