import { useEffect, useMemo, useState } from "react";
import { Satellite } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { colors } from "components/App/theme";
import { DialogHeaderIcon } from "components/DialogHeaderIcon";
import { ImageGrid } from "components/ImageGrid";
import { Switch } from "components/Switch";
import { PUBLIC_S3_BUCKET } from "constants/constants";
import { LinkLike } from "features/HumanJob/upload-tab.styled";
import { getEndFrameConsideringFPS } from "features/JobBuilder2/domain";
import { ActivityStance } from "features/JobBuilder2/steps/threeDimensional/utils/Location";
import { Activity, DisallowActivityReason } from "features/JobBuilder2/types";
import { disableBackdropClickClose } from "services/ui-service";
import { addSIfGreaterThan1 } from "utils/stringUtil";

const ACTIONS_FOLDER = `${PUBLIC_S3_BUCKET}/pl2/genpop/actions`;

const videoPath = (path: string) => `${ACTIONS_FOLDER}/${path.substring(5)}`;
const imagePath = (path: string) => `${ACTIONS_FOLDER}/${path.substring(5)}`;

const getMissmatchStanceMessage = (activityStance: ActivityStance) => {
  switch (activityStance) {
    case ActivityStance.Standing:
      return "Standing region required";
    case ActivityStance.Sitting:
      return "Seating region required";
    default:
      return "Not Available";
  }
};

const getDisallowMessage = (
  reason: DisallowActivityReason,
  activityStance: ActivityStance
) => {
  switch (reason) {
    case DisallowActivityReason.MismatchingStance:
      return getMissmatchStanceMessage(activityStance);
    case DisallowActivityReason.NeedsMoreSpace:
      return "Needs more space";
    default:
      return "Not Available";
  }
};

type ActivitiesPickerDialogProps = {
  value: string[];
  onCancel: () => void;
  onConfirm: (v: string[]) => void;
  allowedActivitiesNames: string[];
  disallowedActivityReasons: Record<string, DisallowActivityReason>;
  activities: Activity[];
};

export function ActivitiesPickerDialog(props: ActivitiesPickerDialogProps) {
  const {
    value,
    onCancel,
    onConfirm,
    allowedActivitiesNames,
    disallowedActivityReasons,
    activities,
  } = props;

  const [selected, setSelected] = useState<string[]>([]);
  const [showAvailableOnly, setShowAvailableOnly] = useState(true);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const options = useMemo(
    () =>
      activities
        .filter(
          (activity) =>
            !showAvailableOnly || allowedActivitiesNames.includes(activity.name)
        )
        .map((activity) => {
          const isSelected = selected.includes(activity.name);
          const isDisabled = !allowedActivitiesNames.includes(activity.name);

          return {
            key: activity.name,
            label: (
              <>
                <Typography variant="body1">{activity.name}</Typography>
                <Typography variant="body1">
                  {addSIfGreaterThan1(
                    getEndFrameConsideringFPS(activity.end_frame, activity.fps),
                    "frame"
                  )}
                </Typography>
                <Typography
                  sx={{ opacity: isDisabled ? 0.4 : "initial" }}
                  variant="body1"
                >
                  {isDisabled
                    ? getDisallowMessage(
                        disallowedActivityReasons[activity.name],
                        activity.stance
                      )
                    : "Available"}
                </Typography>
              </>
            ),
            src: imagePath(activity.filepaths.preview_image),
            videoSrc: videoPath(activity.filepaths.preview_video),
            disabled: isDisabled,
            isSelected,
          };
        }),
    [
      activities,
      allowedActivitiesNames,
      disallowedActivityReasons,
      selected,
      showAvailableOnly,
    ]
  );

  const handleSelectAll = () => {
    setSelected(options.filter((o) => !o.disabled).map((o) => o.key));
  };

  const handleDeselectAll = () => {
    setSelected([]);
  };

  const handleSelect = (i: number) => {
    const activity = options[i];

    if (selected.includes(activity.key)) {
      setSelected(selected.filter((s) => s !== activity.key));
    } else {
      setSelected([...selected, activity.key]);
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open
      data-testid="confirmation-dialog"
      maxWidth="lg"
      onClose={(e, reason) => disableBackdropClickClose(reason, null)}
    >
      <DialogTitle borderBottom={`1px solid ${colors.grey20}`} mb={2}>
        <Box alignItems="flex-start" component="div" display="flex">
          <Box component="div" flexGrow={1}>
            <Box component="div" display="flex">
              <DialogHeaderIcon>
                <Satellite />
              </DialogHeaderIcon>
              Assign Activities
            </Box>
          </Box>
          <Box component="div" display="flex" flexGrow={0}>
            <CloseIcon onClick={onCancel} />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="div" sx={{ minWidth: 812 }}>
          <>
            <Box component="div" mb={2}>
              <Box
                alignItems="center"
                component="div"
                display="flex"
                justifyContent="space-between"
              >
                <Box alignItems="center" component="div" display="flex">
                  <Typography mr={2} variant="h4">
                    All activities
                  </Typography>
                  <FormControlLabel
                    control={<Switch defaultChecked sx={{ m: 1 }} />}
                    label="Show Available Only"
                    value={showAvailableOnly}
                    onChange={() => setShowAvailableOnly(!showAvailableOnly)}
                  />
                </Box>
                <Box component="div" mt={1}>
                  <Typography variant="body2">
                    <LinkLike onClick={handleSelectAll}>Select all</LinkLike>
                    {selected.length !== 0 && (
                      <>
                        {" | "}
                        <LinkLike onClick={handleDeselectAll}>
                          Clear selection
                        </LinkLike>
                      </>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {options.length === 0 && <>No activities fit inside this region.</>}
            <ImageGrid dynamicSize images={options} onSelect={handleSelect} />
          </>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          component="div"
          mb={1}
          ml={2}
          mr={2}
          textAlign="right"
          width="100%"
        >
          {options.length > 0 && (
            <Button
              autoFocus
              color="warning"
              variant="contained"
              onClick={() => onConfirm(selected)}
            >
              Select activities
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}
