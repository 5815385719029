import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { disableBackdropClickClose } from "services/ui-service";

export function ConfirmationDialog({
  onCancel,
  onConfirm,
  textContent,
  title,
}: {
  onCancel: () => void;
  onConfirm: () => void;
  textContent: JSX.Element;
  title: string;
}) {
  return (
    <Dialog
      disableEscapeKeyDown
      open
      data-testid="confirmation-dialog"
      onClick={(e) => {
        e.stopPropagation();
      }}
      onClose={(e, reason) => disableBackdropClickClose(reason, null)}
    >
      <DialogTitle>
        <Box alignItems="flex-start" component="div" display="flex">
          <Box component="div" flexGrow={1}>
            {title}
          </Box>
          <Box component="div" display="flex" flexGrow={0}>
            <CloseIcon onClick={onCancel} />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box component="div" mb={2}>
          <Typography variant="body1">{textContent}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          component="div"
          mb={1}
          ml={2}
          mr={2}
          textAlign="right"
          width="100%"
        >
          <Box component="div" display="inline" mr={2}>
            <Button color="warning" variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </Box>
          <Button
            autoFocus
            color="warning"
            variant="contained"
            onClick={onConfirm}
          >
            {"I'm"} sure
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
