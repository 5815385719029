export function PositionIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 11.5098H17.5V11.0098V9.00977V8.50977H17H13H12.5V9.00977V11.0098V11.5098H13H17ZM21 19.5198H21.5V19.0198V4.98977V4.48977H21H3H2.5V4.98977V19.0198V19.5198H3H21ZM11.5 7.50977H18.5V12.5098H11.5V7.50977ZM3 3.50977H21C21.8239 3.50977 22.5 4.18591 22.5 5.00977V19.0098C22.5 19.8271 21.8304 20.4898 21 20.4898H3C2.16957 20.4898 1.5 19.8271 1.5 19.0098V5.00977C1.5 4.18591 2.17614 3.50977 3 3.50977Z"
        stroke="#9A9A9B"
      />
    </svg>
  );
}
