export function GazeIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask fill="white" id="path-1-inside-1_4423_24616">
        <path d="M15.0304 6.37221C14.0755 6.37221 13.2988 7.14883 13.2988 8.10381C13.2988 9.05879 14.0755 9.83541 15.0304 9.83541C15.9854 9.83541 16.762 9.05879 16.762 8.10381C16.762 7.14883 15.9854 6.37221 15.0304 6.37221ZM15.0304 8.96961C14.5525 8.96961 14.1646 8.58087 14.1646 8.10381C14.1646 7.62675 14.5525 7.23801 15.0304 7.23801C15.5084 7.23801 15.8962 7.62675 15.8962 8.10381C15.8962 8.58087 15.5084 8.96961 15.0304 8.96961Z" />
      </mask>
      <path
        d="M15.0304 6.37221C14.0755 6.37221 13.2988 7.14883 13.2988 8.10381C13.2988 9.05879 14.0755 9.83541 15.0304 9.83541C15.9854 9.83541 16.762 9.05879 16.762 8.10381C16.762 7.14883 15.9854 6.37221 15.0304 6.37221ZM15.0304 8.96961C14.5525 8.96961 14.1646 8.58087 14.1646 8.10381C14.1646 7.62675 14.5525 7.23801 15.0304 7.23801C15.5084 7.23801 15.8962 7.62675 15.8962 8.10381C15.8962 8.58087 15.5084 8.96961 15.0304 8.96961Z"
        fill={fill}
      />
      <path
        d="M15.0304 4.37221C12.9709 4.37221 11.2988 6.04426 11.2988 8.10381H15.2988C15.2988 8.2534 15.18 8.37221 15.0304 8.37221V4.37221ZM11.2988 8.10381C11.2988 10.1634 12.9709 11.8354 15.0304 11.8354V7.83541C15.18 7.83541 15.2988 7.95422 15.2988 8.10381H11.2988ZM15.0304 11.8354C17.09 11.8354 18.762 10.1634 18.762 8.10381H14.762C14.762 7.95422 14.8808 7.83541 15.0304 7.83541V11.8354ZM18.762 8.10381C18.762 6.04426 17.09 4.37221 15.0304 4.37221V8.37221C14.8808 8.37221 14.762 8.2534 14.762 8.10381H18.762ZM15.0304 6.96961C15.6584 6.96961 16.1646 7.4776 16.1646 8.10381H12.1646C12.1646 9.68413 13.4466 10.9696 15.0304 10.9696V6.96961ZM16.1646 8.10381C16.1646 8.73002 15.6584 9.23801 15.0304 9.23801V5.23801C13.4466 5.23801 12.1646 6.52349 12.1646 8.10381H16.1646ZM15.0304 9.23801C14.4025 9.23801 13.8962 8.73002 13.8962 8.10381H17.8962C17.8962 6.52349 16.6142 5.23801 15.0304 5.23801V9.23801ZM13.8962 8.10381C13.8962 7.4776 14.4025 6.96961 15.0304 6.96961V10.9696C16.6142 10.9696 17.8962 9.68413 17.8962 8.10381H13.8962Z"
        fill={fill}
        mask="url(#path-1-inside-1_4423_24616)"
      />
      <path
        d="M12 22.25C17.6524 22.25 22.25 17.6524 22.25 12C22.25 6.34764 17.6524 1.75 12 1.75C6.34764 1.75 1.75 6.34764 1.75 12C1.75 17.6524 6.34764 22.25 12 22.25ZM18.8929 8.66667C18.8929 11.1543 16.8686 13.1786 14.381 13.1786C11.8933 13.1786 9.86905 11.1543 9.86905 8.66667C9.86905 6.17902 11.8933 4.15476 14.381 4.15476C16.8686 4.15476 18.8929 6.17902 18.8929 8.66667ZM11.9982 3.20238C9.89223 4.12334 8.41667 6.22409 8.41667 8.66667C8.41667 11.9562 11.0915 14.631 14.381 14.631C17.5019 14.631 20.0696 12.2232 20.3245 9.16711C20.6301 10.0563 20.7976 11.0086 20.7976 12C20.7976 16.8505 16.8505 20.7976 12 20.7976C7.1495 20.7976 3.20238 16.8505 3.20238 12C3.20238 7.15009 7.14854 3.20334 11.9982 3.20238Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.5"
      />
    </svg>
  );
}
