import { Box, Button, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";

export const CardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: ${colors.white};
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.07);
  margin-bottom: 16px;
  min-width: 720px;
`;

export const WarningContainer = styled(CardContainer)`
  padding-top: 96px;
  padding-bottom: 72px;
  justify-content: center;
  align-items: center;
`;

export const InformationalIconsContainer = styled(Box)`
  display: flex;
  flex: 7;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-left: 8px;
`;

export const RigConfigurationContainer = styled(CardContainer)`
  align-items: flex-start;
  justify-content: center;
  border: 1px solid ${colors.grey20};
`;

export const Label = styled(Typography)`
  width: 100%;
  padding-right: 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${colors.grey70};
  padding-top: 24px;
`;

export const PresetHeaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;

export const Caption = styled(Typography)`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey70};
`;

export const ActionButtonsContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 48px;
`;

export const ActionButton = styled(Button)`
  width: 152px;
  font-size: 12px;
  line-height: 16px;
  line-clamp: 1;
  white-space: nowrap;
  font-weight: 700;
  box-shadow: none;
  color: ${colors.white};
  background-color: ${colors.orange};
  margin-left: 8px;

  &:hover {
    background-color: ${colors.pumpkin};
  }
`;
