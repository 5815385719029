import { Button, Dialog } from "@mui/material";
import { CircledCloseIcon } from "assets/icons/CircledClose";
import { colors } from "components/App/theme";
import { templateSampleImages } from "constants/constants";
import { Messages } from "constants/messages";
import { Template1, Template2 } from "features/HumanJob/types";
import {
  ActionsContainer,
  CloseIconContainer,
  Description,
  DialogHeader,
  HeaderTextContent,
  MainHeaderContent,
  MainImage,
  PreviewContainer,
  PreviewImage,
  Title,
} from "./TemplatePreviewDialog.styled";

export function TemplatePreviewDialog({
  template,
  onCancel,
  onConfirmGoToNewPL1UI,
}: {
  template: Template1 | Template2;
  onCancel: () => void;
  onConfirmGoToNewPL1UI: () => void;
}) {
  const previewImages = templateSampleImages(
    template.attributes.sampleImagesJobID
  );

  return (
    <Dialog open sx={{ borderRadius: "8px" }} onClose={onCancel}>
      <DialogHeader>
        <CloseIconContainer>
          <CircledCloseIcon fill={colors.grey50} onClick={onCancel} />
        </CloseIconContainer>
        <MainHeaderContent>
          <MainImage src={template.attributes.imageSrc} />
          <HeaderTextContent>
            <Title>{template.attributes.title}</Title>
            <Description>{template.attributes.description}</Description>
          </HeaderTextContent>
        </MainHeaderContent>
      </DialogHeader>
      <PreviewContainer>
        {previewImages.map((url, index) => (
          <PreviewImage key={index} src={url} />
        ))}
      </PreviewContainer>

      <ActionsContainer>
        <Button
          autoFocus
          color="warning"
          variant="contained"
          onClick={onConfirmGoToNewPL1UI}
        >
          {Messages.selectTemplate}
        </Button>
      </ActionsContainer>
    </Dialog>
  );
}
