import { useMemo } from "react";
import sexMatchedOnlyDiagram from "assets/diagrams/clothing-sex-matched-only.svg";
import singleOutfit from "assets/diagrams/clothing-single-outfit.svg";
import { ClothingIcon } from "assets/icons";
import { BooleanSelector } from "components/BooleanSelector";
import { ProTip } from "components/ProTip";
import { RightAsidePreviewFull } from "components/RightAsidePreviewFull";
import { PreviewContent } from "components/RightAsidePreviewFull/PreviewContent";
import { PUBLIC_S3_BUCKET } from "constants/constants";
import { Messages } from "constants/messages";
import { EmptyState } from "features/JobBuilder/empty-state";
import { convertClothingStateToJson } from "features/JobBuilder/form-to-input-json";
import {
  getHumanObject,
  getClothingList,
} from "features/JobBuilder/json-extracter";
import { JsonManagerNewUI } from "features/JobBuilder/json-manager-new-ui";
import { convertClothingList } from "features/JobBuilder/json-to-form";
import { JsonHuman } from "features/JobBuilder/json-types";
import { ContentCard, Page } from "features/JobBuilder/layout";
import { MultiOptionImageGrid } from "features/JobBuilder/MultiOptionImageGrid";
import { clothingOutfitSelector } from "features/JobBuilder/store";
import { useDistPage } from "features/JobBuilder/useDistPage";
import {
  useImageSelection,
  useSelectionOutcomeCount,
} from "features/JobBuilder/useImageSelection";
import { changeCheckEventValue } from "features/JobBuilder/utils";
import { useBoundStore } from "store/_boundStore";

const id = "clothing";
export function JobBuilderClothing() {
  const {
    value,
    onUpdate,
    selectedGroupIndex,
    numberOfImages,
    toggleDistListItem,
    isSummingTo100,
    onAdd,
    onUpdatePercent,
    onUpdatePart,
  } = useDistPage(id);

  const selectedClothing = value[selectedGroupIndex];
  const onUpdateSexMatchedOnly = onUpdatePart("sex_matched_only");
  const onUpdateSingleStylePerId = onUpdatePart("single_style_per_id");

  const [setErrorMessage, setSuccessMessage, styleOptions] = useBoundStore(
    (s) => [
      s.message.setErrorMessage,
      s.message.setSuccessMessage,
      clothingOutfitSelector(s),
    ]
  );

  const outfitUIState = useImageSelection(
    selectedClothing ? selectedClothing.outfit : [],
    styleOptions,
    (s) => `${PUBLIC_S3_BUCKET}/pl1/clothing.outfit/${s}.png`,
    onUpdatePart("outfit"),
    "Outfit",
    "outfit",
    "StyleIcon",
    toggleDistListItem("outfit", styleOptions)
  );

  const outcomes = useSelectionOutcomeCount([outfitUIState]);

  const onIndecesReceived = (indices: number[], humansArr: JsonHuman[]) => {
    try {
      const humanObject = getHumanObject(humansArr, indices[0]);
      const jsonClothingList = getClothingList(humanObject);
      onUpdate(convertClothingList(jsonClothingList, value));
      setSuccessMessage("updated clothing");
    } catch (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
      }
    }
  };

  // TODO: Type this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDoneJson = (json: any) => {
    const obj = convertClothingList(json, value);
    onUpdate(obj);
  };

  const jsonValue = useMemo(() => convertClothingStateToJson(value), [value]);

  const isEmptyState = !selectedClothing;
  const center = (
    <JsonManagerNewUI
      key={`clothing-${selectedGroupIndex}`}
      emptyState={
        isEmptyState ? (
          <EmptyState
            description="Clothing controls the clothing variations of the character models. Default clothing variations are applied, some sample results are shown below:"
            folder="clothing"
            icon={<ClothingIcon style={{ width: 48, height: 48 }} />}
            title="Default clothing settings applied"
          />
        ) : null
      }
      groupIndex={selectedGroupIndex + 1}
      groupPercentage={selectedClothing?.percent}
      id={`clothing-${selectedGroupIndex}`}
      isSummingTo100={isSummingTo100}
      jsonValue={jsonValue}
      numberOfImages={numberOfImages}
      title={`Clothing`}
      titleCount={outcomes}
      titleIcon="ClothingIcon"
      unknownIndicesKeys={["Human"]}
      onClickAddButton={onAdd}
      onDoneEditJson={onDoneJson}
      onIndecesReceived={onIndecesReceived}
      onUpdatePercent={onUpdatePercent}
    >
      <ContentCard>
        <ProTip label={Messages.clothingProTip} />
        <MultiOptionImageGrid {...outfitUIState} />
      </ContentCard>

      <ContentCard>
        <BooleanSelector
          description={Messages.singleStylePerIdForClothing}
          icon={"PersonIcon"}
          imgSrc={singleOutfit}
          label={"Enabled"}
          title={"Single Style Per Id"}
          value={selectedClothing?.single_style_per_id}
          onUpdate={changeCheckEventValue(onUpdateSingleStylePerId)}
        />
      </ContentCard>

      <ContentCard>
        <BooleanSelector
          description={Messages.sexMatchedDescriptionForClothing}
          icon={"SexMatchedOnlyIcon"}
          imgSrc={sexMatchedOnlyDiagram}
          label={"Enabled"}
          title={"Sex Matched Only"}
          value={selectedClothing?.sex_matched_only}
          onUpdate={changeCheckEventValue(onUpdateSexMatchedOnly)}
        />
      </ContentCard>
    </JsonManagerNewUI>
  );
  const rightAside = (
    <RightAsidePreviewFull previewTitle="Body Preview">
      <PreviewContent data={outfitUIState.selectedImages} title="STYLE" />
    </RightAsidePreviewFull>
  );
  return (
    <Page
      fixedRightSide
      center={center}
      hideRightAside={isEmptyState}
      rightAside={rightAside}
    />
  );
}
