export enum URLParams {
  msg = "msg",
  msgType = "msgType",
  email = "email",
  code = "code",
  org = "org",
  token = "token",
  billingName = "billingName",
  last4 = "last4",
  orderDate = "orderDate",
  orderNumber = "orderNumber",
  price = "price",
  cardBrand = "cardBrand",
  orgName = "orgName",
}
