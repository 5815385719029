import { useState } from "react";
import { DeleteIcon } from "assets/icons";
import { colors } from "components/App/theme";
import { DeleteConfirmationDialog } from "components/dialogs/DeleteConfirmationDialog";
import { Section } from "domain/Human";
import { ReverseTreeItem } from "features/JobBuilder/LeftSidebar/left-sidebar.styled";
import { SectionLabel } from "features/JobBuilder/LeftSidebar/SectionLabel";
import { RigPart } from "features/JobBuilder/types";
import { getCameraOrLightIcon } from "features/JobBuilder/utils";

export function CameraAndLightForRig({
  isSelectedRig,
  nodeId,
  onSelectRigItemCallback,
  rigPart,
  selectedHumanObjectPart,
  onDeleteCameraCallback,
  onDeleteLightCallback,
}: {
  isSelectedRig: boolean;
  nodeId: string;
  onSelectRigItemCallback: () => void;
  rigPart: RigPart;
  selectedHumanObjectPart: keyof Section;
  onDeleteCameraCallback: (index: number) => void;
  onDeleteLightCallback: (index: number) => void;
}) {
  const [isDeleting, setIsDeleting] = useState(false);

  const onCancelCallback = () => {
    setIsDeleting(false);
  };

  const onConfirmDeletionCallback = () => {
    setIsDeleting(false);
    rigPart.type === "camera"
      ? onDeleteCameraCallback(rigPart.indexOnDataArray)
      : onDeleteLightCallback(rigPart.indexOnDataArray);
  };

  const rigPartHumanText = `${rigPart.type === "camera" ? "camera" : "light"} ${
    rigPart.name
  }`;
  const textContent = `Are you sure you want to delete ${rigPartHumanText}? If you delete this you will lose the data.`;

  const expandIcon = (
    <DeleteIcon
      style={{
        color: colors.red,
        cursor: "pointer",
      }}
      onClick={() => {
        setIsDeleting(true);
      }}
    />
  );

  const collapseIcon = (
    <DeleteIcon
      style={{
        color: colors.red,
        cursor: "pointer",
      }}
      onClick={() => {
        setIsDeleting(true);
      }}
    />
  );
  return (
    <>
      {isDeleting && (
        <DeleteConfirmationDialog
          textContent={textContent}
          onCancel={onCancelCallback}
          onConfirm={onConfirmDeletionCallback}
        />
      )}
      <ReverseTreeItem
        collapseIcon={collapseIcon}
        expandIcon={expandIcon}
        label={
          <SectionLabel
            icon={getCameraOrLightIcon(rigPart.type)}
            label={rigPart.name}
            selected={selectedHumanObjectPart === "rigs" && isSelectedRig}
            variant="subsection"
          />
        }
        nodeId={nodeId}
        onClick={onSelectRigItemCallback}
      >
        <></>
      </ReverseTreeItem>
    </>
  );
}
