import { useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { ActionButton } from "components/Common/ActionButton";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { SuccessMessage } from "components/SuccessMessage";
import { restClient } from "services/restClient/main";
import { validateEmail } from "utils/validators";

function SendPasswordResetEmail() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailValidationError, setEmailValidationError] = useState("");
  const [success, setSuccess] = useState(false);
  const [apiMessage, setApiMessage] = useState("");

  const validate = () => {
    const validationResult = validateEmail(email);
    if (!validationResult.valid) {
      setEmailValidationError(validationResult.message || "Invalid");
    } else {
      setEmailValidationError("");
    }
    return validationResult.valid;
  };

  const handleSubmit = async () => {
    if (!validate()) {
      return;
    }
    setLoading(true);
    try {
      const message = await restClient.forgotPassword(email.toLowerCase(), {});
      setSuccess(true);
      setApiMessage(message);
    } catch (error) {
      //setErrorMessage(e.message));
      setEmailValidationError(restClient.APIErrorMessage(error));
    }
    setLoading(false);
  };

  if (success) {
    return <SuccessMessage details={apiMessage} title="Forgot Password" />;
  }

  return (
    <>
      {loading && <FullScreenProgress />}
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          <Box component="div" mb={5}>
            <Typography variant="h1">Forgot Password</Typography>
          </Box>
          <Typography variant="body2">
            Enter your email address below and we will send instructions for
            resetting your password.
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item md={6} xs={12}>
          <Box component="div" mt={4}>
            <TextField
              autoFocus
              fullWidth
              required
              error={!!emailValidationError}
              helperText={emailValidationError}
              id="email"
              label="Email address"
              size="small"
              type="text"
              value={email}
              variant="outlined"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailValidationError("");
              }}
            />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box component="div" mt={2}>
                <ActionButton fullWidth color="warning" onClick={handleSubmit}>
                  Send password reset email
                </ActionButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export { SendPasswordResetEmail };
