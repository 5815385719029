import { useEffect } from "react";
import { plainToClass } from "class-transformer";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_ENV, PL2_JOB_STATE_VERSION } from "constants/constants";
import { Routes } from "constants/routes";
import { PrefabGroupMap } from "domain/Prefabs";
import { individualCharactersGroupName } from "features/JobBuilder2/characters";
import { GeomRegion } from "features/JobBuilder2/steps/threeDimensional/GeomRegion";
import { activityZonesInit } from "features/JobBuilder2/steps/threeDimensional/utils/activity-zones-init";
import {
  catalogueCharactersRestClient,
  catalogueAssetsRestClient,
  restClient,
} from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import {
  ActivityStance,
  transformCGLocation,
} from "./steps/threeDimensional/utils/Location";
import { Activity } from "./types";

export const useFetchInitialData = (
  orgParam: string,
  draftId: string | undefined,
  setTemplateName: (v: string) => void
) => {
  const [setErrorMessage, setStoreValues, setSubjobs] = useBoundStore((s) => [
    s.message.setErrorMessage,
    s.catalogData.setStoreValues,
    s.jobBuilder2.setSubjobs,
  ]);
  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    const getAll = async () => {
      try {
        const [locations, activities, prefabs, draft] = await Promise.all([
          catalogueAssetsRestClient.getLocations(),
          catalogueAssetsRestClient.getActivities(),
          catalogueCharactersRestClient.getPrefabGroups(),
          restClient.getPL2Draft(orgParam, draftId || ""),
        ]);
        activities.forEach((activity: Activity) => {
          if ((activity.stance as string) === "seated") {
            // Unify activity stances and activity zone hints
            activity.stance = ActivityStance.Sitting;
          }
        });

        if (draft.version !== PL2_JOB_STATE_VERSION) {
          setErrorMessage("Wrong version");
          return;
        }
        const draftState = draft.job_state;
        if (!Array.isArray(draftState)) {
          setErrorMessage("not an array");
          return;
        }
        prefabs.sort((a, b) => (a.name.startsWith("Demo") ? -1 : 1));
        const prefabGroupMap: PrefabGroupMap = {};
        prefabs.forEach((prefab) => {
          if (prefab.name === individualCharactersGroupName) {
            return;
          }
          if (prefab.access_level === "internal" && APP_ENV === "prod") {
            return;
          }
          prefabGroupMap[prefab._id] = prefab;
        });
        draftState.forEach((sj) => {
          sj.activityZones.forEach((activityZone) => {
            if (
              activityZone.prefabGroupId &&
              !prefabGroupMap[activityZone.prefabGroupId]
            ) {
              throw Error(
                `One of the prefab groups in this draft (${JSON.stringify(
                  activityZone.prefabGroupId
                )}) no longer exists`
              );
            }
          });
        });
        setStoreValues({
          prefabGroups: prefabGroupMap,
          activities,
          locations: locations.map(transformCGLocation),
        });
        setSubjobs(
          draftState.map((sj) => ({
            ...sj,
            activityZones: sj.activityZones.length
              ? sj.activityZones.map((a) => ({
                  ...a,
                  geomRegion: plainToClass(GeomRegion, a.geomRegion),
                }))
              : activityZonesInit(sj.location || sj.locationOptions[0]),
          }))
        );
        setTemplateName(draft.job_name);
      } catch (error) {
        console.warn("error is caught here");
        setErrorMessage(restClient.PotentiallyAPIErrorMessage(error));
        navigate(Routes.HUMAN_JOB + search);
      }
    };
    getAll();
  }, [
    navigate,
    search,
    setErrorMessage,
    draftId,
    orgParam,
    setTemplateName,
    setSubjobs,
    setStoreValues,
  ]);
};
