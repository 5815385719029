export function PriorityHighIcon({
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21Z"
        fill="#F44C58"
      />
      <path
        d="M12 3C10.9 3 10 3.9 10 5V13C10 14.1 10.9 15 12 15C13.1 15 14 14.1 14 13V5C14 3.9 13.1 3 12 3Z"
        fill="#F44C58"
      />
    </svg>
  );
}
