import React from "react";
import { defaultRig, Section } from "domain/Human";
import { IconsType } from "types/main";
import { allGesturesSelector } from "./store";
import { RigPart } from "./types";
export interface PercentString {
  percent: string;
}
export interface PercentNumber {
  percent: number;
}
export const summingTo100 = (selections: PercentString[]) => {
  return (
    selections
      .map((s) => parseFloat(s.percent))
      .reduce((previous, current) => previous + current, 0) === 100
  );
};
const allOptionsAreThere = (value: string[], options: string[]) =>
  value.length === options.length;
const allStringArray = ["all"];
const noneStringArray = ["none"];
export const includeAll = (value: string[], options: string[]) => {
  return allOptionsAreThere(value, options) ? allStringArray : value;
};
export const includeAllAndNone = (value: string[], options: string[]) => {
  if (allOptionsAreThere(value, options)) {
    return allStringArray;
  }
  if (!value.length || (value.length === 1 && value[0] === "")) {
    return noneStringArray;
  }
  return value;
};

export type WithPercent = { percent: string };
export const addToArrayPercentages = <T>(array: T[], newItem: T): T[] => {
  const percentageTaken = array.reduce(
    (acc: number, group: any) => Number(group.percent ?? 0) + acc,
    0
  );

  const cappedNewPercentage =
    100 - percentageTaken <= 0 ? 0 : 100 - percentageTaken;

  return [...array, { ...newItem, percent: cappedNewPercentage.toString() }];
};

export const removeFromArrayPercentages = (
  array: any[],
  indexToRemove: number
) => array.filter((_object: any, index: number) => index !== indexToRemove);

export const cameraAndLightNamesAndTypesFromAllRigs = (
  s: Section
): RigPart[][] => {
  return s.rigs.map((r) => {
    const cameras = r.cameras.map((c, i) => ({
      name: c.name,
      type: "camera",
      indexOnDataArray: i,
    }));
    const lights = r.lights.map((l, i) => ({
      name: l.name,
      type: l.type,
      indexOnDataArray: i,
    }));
    return cameras.concat(lights);
  });
};

export const getcameraAndLightNamesAndTypesFromOneRig = (
  s: Section,
  rigIndex: number
): RigPart[] => cameraAndLightNamesAndTypesFromAllRigs(s)[rigIndex];

export const getRigTotalCameras = (rig: RigPart[]) =>
  rig.filter((item) => item.type === "camera").length;
export const getRigTotalLights = (rig: RigPart[]) =>
  rig.filter((item) => item.type !== "camera").length;

export const changeEventValue =
  (fn: any) =>
  (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    fn(e.target.value);
  };

export const changeCheckEventValue =
  (fn: any) => (e: React.ChangeEvent<HTMLInputElement>) =>
    fn(e.target.checked);

export const preventDefault = (fn: any) => (e: React.MouseEvent<any>) => {
  e.preventDefault();
  fn();
};

export const splice =
  (i: number) =>
  <T>(d: T[]) => {
    d.splice(i, 1);
  };

export const push =
  <T>(item: T) =>
  (d: T[]) => {
    d.push({ ...item });
  };

export const getCameraOrLightIcon = (type: string): IconsType => {
  switch (type) {
    case "camera":
      return "CameraIcon";
    case "rect":
      return "RectLightIcon";
    case "omni":
      return "OmniLightIcon";
    default:
      return "GeneralLightIcon";
  }
};

export const getNewCameraNLightName = (array: any[], baseName: string): any => {
  let biggestCameraNumber = 0;

  const newName = (i: number) => `${baseName}_${i}`;

  array.forEach((item) => {
    const numberSuffixFromName = item.name.split("_").pop() ?? "1";
    const number = parseInt(numberSuffixFromName, 10);

    if (number > biggestCameraNumber) {
      biggestCameraNumber = number;
    }
  });

  return newName(biggestCameraNumber + 1);
};

export const updateRigsAndCameraNames = (rigs: (typeof defaultRig)[]) => {
  return rigs.map((rig, rigIndex) => ({
    ...rig,
    cameras: rig.cameras.map((camera, cameraIndex) => {
      const newCameraName = `Rig_${rigIndex + 1}_Camera_${cameraIndex + 1}`;
      return { ...camera, name: newCameraName };
    }),
  }));
};
