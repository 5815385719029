import React from "react";
import { InfoOutlined } from "@mui/icons-material";
import { Box, Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { Label } from "features/JobBuilder/rigs.styled";

type MultiselectProps = {
  label: string;
  selectAll?:
    | {
        label: string;
        help: React.ReactNode | string;
      }
    | true;
  options: Array<{ label: string; value: string }>;
  value: string[];
  onChange: (value: string[]) => void;
};

export function MultiCheckbox(props: MultiselectProps) {
  const { label, selectAll, options, value, onChange } = props;

  const toggleSelectAll = () => {
    if (value.length === options.length) {
      onChange([]);
    } else {
      onChange(options.map((option) => option.value));
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: optionValue } = event.target;

    if (value.includes(optionValue)) {
      onChange(value.filter((v) => v !== optionValue));
    } else {
      onChange([...value, optionValue]);
    }
  };

  return (
    <Box component="div" mb={3}>
      <Box
        alignItems="center"
        component="div"
        display="flex"
        justifyContent="flex-start"
        mt={2}
      >
        <Label style={{ width: "initial", padding: "0 8px 0 0" }}>
          {label}
        </Label>
        {selectAll && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value.length === options.length}
                  color="warning"
                  sx={{ pr: 0.5 }}
                  onChange={toggleSelectAll}
                />
              }
              label={typeof selectAll !== "boolean" && selectAll.label}
              sx={{ mr: 1 }}
            />
            {typeof selectAll !== "boolean" && selectAll.help && (
              <Tooltip title={selectAll.help}>
                <InfoOutlined color="disabled" fontSize="small" />
              </Tooltip>
            )}
          </>
        )}
      </Box>

      <Box component="div">
        {options.map((o) => (
          <FormControlLabel
            key={o.value}
            control={
              <Checkbox
                checked={value.includes(o.value)}
                color="warning"
                sx={{ pr: 0.3, py: 0 }}
                value={o.value}
                onChange={handleChange}
              />
            }
            label={o.label}
            sx={{ mr: 2 }}
          />
        ))}
      </Box>
    </Box>
  );
}
