import React, { useMemo } from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router";
import { NavigationHeader } from "components/Navigation/Header";
import { WelcomeToOrg } from "components/tutorials/WelcomeToOrg";
import { URLParams } from "constants/params";
import { Routes } from "constants/routes";
import { useOrgParam } from "hooks/useOrgParam";
import { useBoundStore } from "store/_boundStore";
import { selectedOrgSelector } from "store/orgStore";

function JobsTutorial() {
  const navigate = useNavigate();
  const { orgParam } = useOrgParam();
  const [org] = useBoundStore((s) => [selectedOrgSelector(orgParam)(s)]);

  const onContinueClick = () => {
    navigate(Routes.JOBS + `?${URLParams.org}=${org?.name}`);
  };

  const pageTitle = useMemo(() => {
    if (org) {
      return `Welcome to ${org.name}`;
    }

    return "Welcome";
  }, [org]);

  return (
    <Box component="div" data-testid="jobs-component" height="100%">
      <NavigationHeader selectedOrgName={org?.name} title={pageTitle} />
      <WelcomeToOrg orgName={org?.name} />
      {org && (
        <div style={{ float: "right", marginTop: "16px" }}>
          <Button color="warning" variant="contained" onClick={onContinueClick}>
            Continue
          </Button>
        </div>
      )}
    </Box>
  );
}

export { JobsTutorial };
