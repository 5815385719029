import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { colors } from "components/App/theme";
import { SelectorWidth } from "components/SelectorsPicker/utils";

export const Container = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 0;
`;

export const Title = styled(Typography)`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  padding-right: 24px;
  padding-left: 8px;
  white-space: nowrap;
  line-clamp: 1;
  color: ${colors.grey70};
`;

export const DropdownContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: ${SelectorWidth};
  min-width: 200px;
  margin-right: 8px;
  text-transform: capitalize;
`;

export const InfoTooltipContainer = styled(Box)`
  display: grid;
  place-items: center;
`;
