import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography, styled } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { colors } from "components/App/theme";

type InlineEditProps = {
  value: string;
  isEditing: boolean;
  editIcon: React.ReactNode;
  saveLabel: string;
  flexGrow: number;
  onSave: (val: string) => void;
  onToggleEditingMode: (edit: boolean) => void;
  internalValueFontSize?: Variant;
  internalValueFontWeight?: number;
  textVariant?: "leftSidebar" | "default";
};

export function InlineEdit(props: InlineEditProps) {
  const {
    value,
    isEditing,
    editIcon,
    saveLabel,
    flexGrow,
    onSave,
    onToggleEditingMode,
    internalValueFontSize,
    internalValueFontWeight = 400,
    textVariant = "default",
  } = props;
  const [internalValue, setInternalValue] = useState("");

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const textColors = { leftSidebar: colors.grey70, default: undefined };
  const textSizes = { leftSidebar: "12px", default: undefined };

  const handleSave = () => {
    if (internalValue !== value) {
      onSave(internalValue);
    }
    onToggleEditingMode(false);
  };

  return (
    <Box
      alignItems={isEditing ? "flex-end" : "center"}
      component="div"
      display="flex"
    >
      {isEditing ? (
        <Box component="div" flexGrow={flexGrow}>
          <TextField
            autoFocus
            fullWidth
            inputProps={{ "data-testid": "inline-edit-input" }}
            sx={{
              input: {
                color: textColors[textVariant],
                fontSize: textSizes[textVariant],
              },
            }}
            value={internalValue}
            variant="standard"
            onChange={(e) => setInternalValue(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleSave()}
          />
        </Box>
      ) : (
        <Box
          component="div"
          flexGrow={flexGrow}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          <Typography
            color={textColors[textVariant]}
            fontSize={textSizes[textVariant]}
            fontWeight={internalValueFontWeight}
            variant={internalValueFontSize || "inherit"}
          >
            {internalValue}
          </Typography>
        </Box>
      )}
      <Box component="div" display="flex" ml={1}>
        {isEditing ? (
          <>
            <Box component="div" mr={1}>
              <Button
                color="warning"
                data-testid="inline-edit-save-button"
                size="small"
                variant="contained"
                onClick={handleSave}
              >
                {saveLabel}
              </Button>
            </Box>
            <Box component="div">
              <Button
                color="secondary"
                size="small"
                variant="contained"
                onClick={() => {
                  setInternalValue(value);
                  onToggleEditingMode(false);
                }}
              >
                Cancel
              </Button>
            </Box>
          </>
        ) : (
          <EditButton
            component="button"
            data-testid="inline-edit-edit-button"
            onClick={() => onToggleEditingMode(true)}
          >
            {editIcon}
          </EditButton>
        )}
      </Box>
    </Box>
  );
}

const EditButton = styled(Box)({
  cursor: "pointer",
  border: "none",
  background: "none",
});
