import {
  allExpressions,
  defaultAnimationsSection,
  defaultBodySection,
  defaultCamera,
  defaultClothingSection,
  defaultExpression,
  defaultEyebrow,
  defaultEye,
  defaultFacialHair,
  defaultGaze,
  defaultGlassesSelection,
  defaultHair,
  defaultHeadphoneSelection,
  defaultHeadTurn,
  defaultHeadwearSelection,
  defaultIdentities,
  defaultLight,
  defaultLocation,
  defaultMaskSelection,
  defaultSection,
  LoRType,
  Section,
  ThreeDLocationType,
} from "domain/Human";
import {
  fatContentSelector,
  glassColorsSelector,
  glassStylesSelector,
  heightSelector,
  headphonesStylesSelector,
  headwearStylesSelector,
  maskStylesSelector,
  maskPositionsSelector,
  maskVariantsSelector,
  clothingOutfitSelector,
  allGesturesSelector,
  hairStyleSelector,
  hairColorSelector,
  facialHairStyleSelector,
  facialHairColorSelector,
  allHdrisSelector,
  eyebrowStyleSelector,
  eyebrowColorSelector,
  eyesIrisColorSelector,
} from "features/JobBuilder/store";
import { useBoundStore } from "store/_boundStore";
import {
  DeepPartial,
  JsonColor,
  JsonIdentities,
  JsonInput,
  JsonLocation,
  JsonLocationAngles,
  JsonLoR,
} from "./json-types";
import { includeAll, includeAllAndNone } from "./utils";

const fillLoR = (lor: LoRType): JsonLoR => {
  switch (lor.type) {
    case "list":
      return { type: "list", values: lor.listValues.split(",").map(Number) };

    case "range":
      return {
        type: "range",
        values: { max: parseFloat(lor.max), min: parseFloat(lor.min) },
      };

    case "normal_distribution":
      return {
        type: "normal_distribution",
        values: {
          mean: parseFloat(lor.mean as string),
          std_dev: parseFloat(lor.std_dev as string),
        },
      };
  }
};

export const generateInputJson = (
  json: DeepPartial<JsonInput>,
  sections: Section[]
): Partial<JsonInput> => {
  if (!json.humans) {
    return {};
  }
  const firstHumanObject = json.humans[0];
  if (!firstHumanObject) {
    return {};
  }
  return {
    humans: sections.map((s) => ({
      ...firstHumanObject,
      identities: convertIdentitiesStateToJson(s.identities),
      environment: {
        hdri: convertHdriStateToJson(s.hdri),
      },
      "3d_location": undefinedIfEmpty(
        convertThreeDimensionalLocationsToJson(s.threeDLocation)
      ),
      camera_and_light_rigs: s.rigs.map((r) => convertRigStateToJson(r)),
      accessories: {
        glasses: undefinedIfEmpty(convertGlassesStateToJson(s.glasses)),
        headphones: undefinedIfEmpty(
          convertHeadphonesStateToJson(s.headphones)
        ),
        headwear: undefinedIfEmpty(convertHeadwearStateToJson(s.headwear)),
      },
      body: undefinedIfEmpty(convertBodiesStateToJson(s.body)),
      skin: s.skin,
      clothing: undefinedIfEmpty(convertClothingStateToJson(s.clothing)),
      gesture: undefinedIfEmpty(convertAnimationsStateToJson(s.gesture)),
      facial_attributes: {
        ...(!!s.expression.length && {
          expression: convertExpressionStateToJson(s.expression),
        }),
        ...(!!s.gaze.length && {
          gaze: convertGazeStateToJson(s.gaze),
        }),
        ...(!!s.headTurn.length && {
          head_turn: convertHeadTurnStateToJson(s.headTurn),
        }),
        ...(!!s.hair.length && {
          hair: convertHairStateToJson(s.hair),
        }),
        ...(!!s.facialHair.length && {
          facial_hair: convertFacialHairStateToJson(s.facialHair),
        }),
      },
    })),
  };
};

export const convertEyebrowStateToJson = (
  eyebrows: (typeof defaultEyebrow)[]
) =>
  eyebrows.map((e) => {
    const state = useBoundStore.getState();

    return {
      style: includeAllAndNone(e.style, eyebrowStyleSelector(state)),
      color: includeAll(e.color, eyebrowColorSelector(state)),
      color_seed: fillLoR(e.color_seed),
      relative_length: fillLoR(e.relative_length),
      relative_density: fillLoR(e.relative_density),
      match_hair_color: !!e.match_hair_color,
      sex_matched_only: !!e.sex_matched_only,
      percent: Number(e.percent),
    };
  });

export const convertHdriStateToJson = (hdri: typeof defaultSection.hdri) => {
  const state = useBoundStore.getState();
  return {
    name: includeAll(hdri.name, allHdrisSelector(state)),
    intensity: fillLoR(hdri.intensity),
    rotation: fillLoR(hdri.rotation),
  };
};

export const convertExpressionStateToJson = (
  expression: (typeof defaultExpression)[]
) => {
  return expression.map((e) => {
    return {
      name: includeAllAndNone(e.name, allExpressions),
      intensity: fillLoR(e.intensity),
      percent: Number(e.percent),
    };
  });
};

export const convertEyeStateToJson = (eyebrows: (typeof defaultEye)[]) =>
  eyebrows.map((e) => {
    const state = useBoundStore.getState();

    return {
      style: includeAllAndNone(e.iris_color, eyesIrisColorSelector(state)),
      pupil_ditalation: fillLoR(e.pupil_dilation),
      redness: fillLoR(e.redness),
      percent: Number(e.percent),
    };
  });

export const convertGazeStateToJson = (gaze: (typeof defaultGaze)[]) => {
  return gaze.map((g) => {
    return {
      horizontal_angle: fillLoR(g.horizontal_angle),
      vertical_angle: fillLoR(g.vertical_angle),
      percent: Number(g.percent),
    };
  });
};

export const convertHeadTurnStateToJson = (
  headTurn: (typeof defaultHeadTurn)[]
) => {
  return headTurn.map((ht) => {
    return {
      pitch: fillLoR(ht.pitch),
      yaw: fillLoR(ht.yaw),
      roll: fillLoR(ht.roll),
      percent: Number(ht.percent),
    };
  });
};

export const convertHairStateToJson = (hair: (typeof defaultHair)[]) => {
  const state = useBoundStore.getState();
  return hair.map((h) => {
    return {
      style: includeAllAndNone(h.style, hairStyleSelector(state)),
      color: includeAll(h.color, hairColorSelector(state)),
      color_seed: fillLoR(h.color_seed),
      relative_length: fillLoR(h.relative_length),
      relative_density: fillLoR(h.relative_density),
      sex_matched_only: h.sex_matched_only,
      ethnicity_matched_only: h.ethnicity_matched_only,
      percent: Number(h.percent),
    };
  });
};

export const convertFacialHairStateToJson = (
  facialHair: (typeof defaultFacialHair)[]
) => {
  const state = useBoundStore.getState();
  return facialHair.map((h) => {
    return {
      style: includeAllAndNone(h.style, facialHairStyleSelector(state)),
      color: includeAll(h.color, facialHairColorSelector(state)),
      color_seed: fillLoR(h.color_seed),
      relative_length: fillLoR(h.relative_length),
      relative_density: fillLoR(h.relative_density),
      match_hair_color: h.match_hair_color,
      percent: Number(h.percent),
    };
  });
};

export const convertIdentitiesStateToJson = (
  state: typeof defaultIdentities
): JsonIdentities => {
  return {
    renders_per_identity: Number(state.rendersPerIdentity),
    ids: state.ids,
  };
};

export const convertRigStateToJson = (r: (typeof defaultSection.rigs)[0]) => {
  return {
    type: r.type,
    preset_name: r.type === "preset_location" ? r.presetName : undefined,
    preset_transform:
      r.type === "preset_location"
        ? {
            u: fillLoR(r.u),
            v: fillLoR(r.v),
          }
        : undefined,
    location:
      r.type === "preset_location" ? fillAngleLocations(r) : fillLocation(r),
    cameras: undefinedIfEmpty(r.cameras.map(convertCameraStateToJson)),
    lights: undefinedIfEmpty(r.lights.map(convertLightStateToJson)),
  };
};

export const convertCameraStateToJson = (c: typeof defaultCamera) => {
  return {
    name: c.name,
    specifications: {
      resolution_w: parseFloat(c.resolutionW),
      resolution_h: parseFloat(c.resolutionH),
      wavelength: c.wavelength,
      focal_length: fillLoR(c.focalLength),
      sensor_width: fillLoR(c.sensorWidth),
    },
    advanced: {
      lens_shift_horizontal: fillLoR(c.lens_shift_horizontal),
      lens_shift_vertical: fillLoR(c.lens_shift_vertical),
      window_offset_horizontal: fillLoR(c.window_offset_horizontal),
      window_offset_vertical: fillLoR(c.window_offset_vertical),
      noise_threshold: parseFloat(c.noise_threshold),
      denoise: c.denoise,
    },
    relative_location: fillLocation(c),
  };
};

export const convertLightStateToJson = (l: typeof defaultLight) => {
  return {
    type: l.type,
    wavelength: l.wavelength,
    intensity: fillLoR(l.intensity),
    size_meters: fillLoR(l.sizeMeters),
    relative_location: fillLocation(l),
    color: fillcolor(l),
  };
};

export const convertBodiesStateToJson = (
  bodies: (typeof defaultBodySection)[]
) => {
  const state = useBoundStore.getState();
  return bodies.map((b) => ({
    enabled: b.enabled === undefined ? true : b.enabled,
    fat_content: includeAll(b.fat_content, fatContentSelector(state)),
    height: includeAll(b.height, heightSelector(state)),
    percent: Number(b.percent),
  }));
};

export const convertClothingStateToJson = (
  clothingList: (typeof defaultClothingSection)[]
) => {
  const state = useBoundStore.getState();

  return clothingList.map((clothing) => ({
    percent: Number(clothing.percent),
    outfit: includeAllAndNone(clothing.outfit, clothingOutfitSelector(state)),
    sex_matched_only: clothing.sex_matched_only,
    single_style_per_id: clothing.sex_matched_only,
  }));
};

export const convertAnimationsStateToJson = (
  animationsList: (typeof defaultAnimationsSection)[]
) => {
  const state = useBoundStore.getState();

  return animationsList.map((animations) => ({
    percent: Number(animations.percent),
    name: includeAllAndNone(
      animations.name,
      Object.keys(allGesturesSelector(state))
    ),
    keyframe_only: animations.keyframe_only,
    mirrored: animations.mirrored,
    position_seed: fillLoR(animations.position_seed),
  }));
};

export const convertHeadphonesStateToJson = (
  list: (typeof defaultHeadphoneSelection)[]
) => {
  const state = useBoundStore.getState();
  return list.map((g) => ({
    style: includeAllAndNone(g.style, headphonesStylesSelector(state)),
    percent: Number(g.percent),
  }));
};

export const convertHeadwearStateToJson = (
  list: (typeof defaultHeadwearSelection)[]
) => {
  const state = useBoundStore.getState();
  return list.map((g) => ({
    style: includeAllAndNone(g.style, headwearStylesSelector(state)),
    sex_matched_only: g.sex_matched_only,
    percent: Number(g.percent),
  }));
};

export const convertGlassesStateToJson = (
  glasses: (typeof defaultGlassesSelection)[]
) => {
  const state = useBoundStore.getState();
  return glasses.map((g) => ({
    sex_matched_only: g.sexMatching,
    style: includeAllAndNone(g.name, glassStylesSelector(state)),
    lens_color: includeAllAndNone(g.lensColor, glassColorsSelector(state)),
    metalness: fillLoR(g.metalness),
    transparency: fillLoR(g.transparency),
    percent: Number(g.percent),
  }));
};

export const convertMasksStateToJson = (
  masks: (typeof defaultMaskSelection)[]
) => {
  const state = useBoundStore.getState();
  return masks.map((b) => ({
    style: includeAll(b.style, maskStylesSelector(state)),
    position: includeAll(b.position, maskPositionsSelector(state)),
    variant: includeAll(b.variant, maskVariantsSelector(state)),
    percent: Number(b.percent),
  }));
};

export const convertThreeDimensionalLocationsToJson = (
  location: ThreeDLocationType
) => [
  {
    specifications: {
      type: location,
      properties: {},
    },
    percent: 100,
  },
];
export const undefinedIfEmpty = <T,>(arr: T[]) =>
  arr.length ? arr : undefined;

const fillLocation = (loc: typeof defaultLocation): JsonLocation => {
  return {
    x: fillLoR(loc.x),
    y: fillLoR(loc.y),
    z: fillLoR(loc.z),
    ...fillAngleLocations(loc),
  };
};

const fillAngleLocations = (
  loc: typeof defaultLocation
): JsonLocationAngles => {
  return {
    pitch: fillLoR(loc.pitch),
    yaw: fillLoR(loc.yaw),
    roll: fillLoR(loc.roll),
  };
};

const fillcolor = (l: typeof defaultLight): JsonColor => {
  return {
    red: fillLoR(l.red),
    green: fillLoR(l.green),
    blue: fillLoR(l.blue),
  };
};
