import React from "react";
import { Box, Card, CardContent } from "@mui/material";
import styled, { css } from "styled-components";
import { colors } from "components/App/theme";
import { APP_BAR_HEIGHT } from "components/Common/TopAppBar2";
import { PAGE_PADDING } from "features/constants";
import { JOB_FOOTER_HEIGHT } from "./JobBuilderFooter/JobBuilderFooter.styled";

const RIGHTASIDE_WIDTH = 350;

const ContentContainer = styled(Box)<{ $fixedRightSide?: boolean }>`
  width: calc(100% - ${RIGHTASIDE_WIDTH}px);
  box-sizing: border-box;

  ${({ $fixedRightSide }) =>
    $fixedRightSide &&
    css`
      overflow: auto;
      overflow-x: hidden;
      height: calc(100vh - ${APP_BAR_HEIGHT + JOB_FOOTER_HEIGHT}px);
    `}
`;

export function Page({
  center,
  rightAside,
  centerProps = {},
  fixedRightSide,
  hideRightAside,
}: {
  center: React.ReactNode;
  rightAside?: React.ReactNode;
  centerProps?: Record<string, any>;
  fixedRightSide?: boolean;
  hideRightAside?: boolean;
}) {
  return (
    <Box
      component="div"
      display="flex"
      height="100%"
      style={{
        maxWidth: "calc(100vw - 260px)",
      }}
    >
      <ContentContainer
        $fixedRightSide={fixedRightSide}
        component="div"
        p={PAGE_PADDING}
        pt={0}
        {...centerProps}
      >
        {center}
      </ContentContainer>
      <Box component="div" width={RIGHTASIDE_WIDTH}>
        {!hideRightAside && rightAside}
      </Box>
    </Box>
  );
}

export function SAICard({
  children,
  style,
  className,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}) {
  return (
    <Card className={className} style={style}>
      <CardContent>{children}</CardContent>
    </Card>
  );
}

export const ContentCard = styled(SAICard)`
  border-radius: 8px;
  border: 1px solid ${colors.grey20};
  margin-bottom: 16px;
  box-shadow: none;
`;

export function JobBuilderLayout({ children }: { children: React.ReactNode }) {
  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column"
      height="100vh"
      overflow="hidden"
      width="100vw"
    >
      {children}
    </Box>
  );
}
