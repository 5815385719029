import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "constants/constants";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

function StripeFormWrapper({ StripeForm }: { StripeForm: JSX.Element }) {
  return <Elements stripe={stripePromise}>{StripeForm}</Elements>;
}

export { StripeFormWrapper };
