import { Box, TextField, Typography } from "@mui/material";
import styled, { css } from "styled-components";
import { colors } from "components/App/theme";

const imageSpacing = "40px";

export const Container = styled(Box)<{ $hasImage?: boolean }>`
  display: flex;
  width: 100%;
  margin: 8px 0;
  align-items: flex-start;
  padding: 24px 24px 36px;

  ${({ $hasImage }) =>
    $hasImage
      ? css`
          justify-content: flex-start;
        `
      : css`
          justify-content: center;
          flex-direction: column;
        `}
`;

export const ContentContainer = styled(Box)<{ $noVerticalSpacing?: boolean }>`
  display: flex;
  align-items: flex-start;
  padding-left: 28px;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;

  ${({ $noVerticalSpacing }) =>
    $noVerticalSpacing
      ? css`
          padding-top: calc(${imageSpacing} + 1em);
        `
      : css`
          padding-top: 32px;
        `}
`;

export const Label = styled(Typography)`
  display: flex;
  line-height: 21px;
  font-size: 14px;
  color: ${colors.grey70};
`;

export const Input = styled(TextField)`
  margin-right: 8px;

  input {
    padding: 10px 8px;
    font-size: 14px;
    line-height: 21px;
  }

  .css-qnpdxo-MuiFormHelperText-root {
    font-size: 12px;
    line-height: 14px;
  }

  .css-259w0f-MuiInputBase-root-MuiOutlinedInput-root {
    height: 40px;
    width: 76px;
  }
`;

export const ExampleImage = styled.img`
  width: 220px;
  height: 110px;
  object-fit: contain;
  border-radius: 4px;
  margin-top: ${imageSpacing};
`;

export const MainInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
