import { useState, useCallback, useEffect } from "react";
import { MAX_NUMBER_OF_REQUESTS_AT_TIME } from "constants/constants";
import { getImageSuffix } from "domain/Asset";
import { getAssetIDsForDownload } from "domain/Job";
import { allSettledFullfilled } from "services/promise";
import { restClient } from "services/restClient/main";
import { AssetIDWithURL, AssetID, Manifest } from "types/main";

export const useImagesFromManifest = (orgName: string, jobID: string) => {
  const [images, setImages] = useState<AssetIDWithURL[]>([]);
  const [loading, setLoading] = useState(false);
  const [manifest, setManifest] = useState<Manifest | null>(null);

  const getManifest = useCallback(async () => {
    setImages([]);
    try {
      setLoading(true);
      const manifest = await restClient.getManifest(orgName, jobID);
      setManifest(manifest);
      const assetIDs: AssetID[] = getAssetIDsForDownload(manifest);
      const imageSuffix = getImageSuffix(manifest);
      const tempAssetIDsWithUrl: AssetIDWithURL[] = [];
      for (
        let i = 0;
        i < assetIDs.length;
        i += MAX_NUMBER_OF_REQUESTS_AT_TIME
      ) {
        const selectedImagesMetadata = assetIDs.slice(
          i,
          i + MAX_NUMBER_OF_REQUESTS_AT_TIME
        );
        try {
          const promises: Promise<AssetIDWithURL>[] =
            selectedImagesMetadata.map((m) =>
              restClient
                .getAssetURL(
                  orgName,
                  jobID,
                  m.renderID,
                  m.frameNumber,
                  m.camName,
                  imageSuffix
                )
                .then((url) => ({ ...m, url }))
            );
          const fullfilled = await allSettledFullfilled(promises);
          tempAssetIDsWithUrl.push(...fullfilled);
        } catch (error) {
          console.error(error);
        }
      }
      setImages((oldState) => [...oldState, ...tempAssetIDsWithUrl]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }, [jobID, orgName]);

  useEffect(() => {
    getManifest();
  }, [getManifest]);

  return [images, loading, manifest] as const;
};
