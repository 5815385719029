import { noop } from "lodash";
import { IntegerSelector } from "components/IntegerSelector";
import { ProTip } from "components/ProTip";
import { exceedLimit } from "components/SelectorsPicker/utils";
import { Messages } from "constants/messages";
import { JsonManagerNewUI } from "features/JobBuilder/json-manager-new-ui";
import { ContentCard, Page } from "features/JobBuilder/layout";
import { selectActiveSubjobRendersPerIdentity } from "features/JobBuilder/store";
import { changeEventValue } from "features/JobBuilder/utils";
import { useBoundStore } from "store/_boundStore";
import { MIN_SCENES_PER_IDENTITY, MAX_SCENES_PER_IDENTITY } from "./constants";

export function ScenesPerIdentity() {
  const { onUpdateScenesPerIdentities: onUpdate } =
    useBoundStore.getState().jobBuilder;

  const value = useBoundStore(selectActiveSubjobRendersPerIdentity);

  const resolutionError = (resolution: string) =>
    exceedLimit(
      resolution,
      Number(MIN_SCENES_PER_IDENTITY),
      Number(MAX_SCENES_PER_IDENTITY)
    ) || Number.isNaN(Number(resolution));

  const resolutionErrorMessage = `Enter a number between ${MIN_SCENES_PER_IDENTITY} and ${MAX_SCENES_PER_IDENTITY}`;

  return (
    <Page
      center={
        <JsonManagerNewUI
          hideActions
          id="scenesPerIdentity"
          jsonValue={value}
          title={`Scenes Per Identity`}
          titleCount={value ? Number(value) : 0}
          titleIcon="RenderIcon"
          unknownIndicesKeys={["Human"]}
          onDoneEditJson={noop}
          onIndecesReceived={noop}
        >
          <ContentCard>
            <ProTip label={Messages.scenesPerIdentityProTip} />
            <IntegerSelector
              error={resolutionError(value)}
              errorMessage={resolutionErrorMessage}
              icon={"RenderIcon"}
              label={"Number of Scenes Per Identity"}
              limits={{
                max: MAX_SCENES_PER_IDENTITY,
                min: MIN_SCENES_PER_IDENTITY,
              }}
              title={"Scenes Per Identity"}
              value={value}
              onUpdate={changeEventValue(onUpdate)}
            />
          </ContentCard>
        </JsonManagerNewUI>
      }
    />
  );
}
