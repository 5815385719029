export function YAxisIcon({
  fill = "currentColor",
  style = { height: "24px" },
}: {
  style?: React.CSSProperties;
  fill?: string;
}) {
  return (
    <svg
      fill="none"
      height="24"
      style={style}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 7V6.5H19H15H14.5V7V17V17.5H15H19H19.5V17V7ZM3.5 13.5H10.5V14.5H3.5V13.5ZM3.5 17.5H10.5V18.5H3.5V17.5ZM3.5 9.5H10.5V10.5H3.5V9.5ZM3.5 5.5H10.5V6.5H3.5V5.5ZM13.5 18.5V5.5H20.5V18.5H13.5Z"
        fill={fill}
        stroke={fill}
      />
    </svg>
  );
}
