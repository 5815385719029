import React, { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, SxProps, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "components/App/theme";
import { BaseWizardTab } from "components/BaseWizardTab";
import { FullScreenProgress } from "components/Common/FullScreenProgress";
import { PL2_JOB_STATE_VERSION } from "constants/constants";
import { Routes } from "constants/routes";
import { DraftWithoutState } from "domain/Draft";
import { HumanJobTabProps } from "features/HumanJob";
import { getImage } from "features/JobBuilder2/locations-data";
import { segmentTrack } from "features/JobBuilder2/track-service";
import { SmallTitle } from "features/JobBuilderShared/Styles/styles";
import { restClient } from "services/restClient/main";
import { useBoundStore } from "store/_boundStore";
import { formatDateWithoutTime } from "utils/dateUtils";
import { trimMultiple } from "utils/stringUtil";

const styles: { [key: string]: SxProps } = {
  cardsContainer: {},
  card: {
    mr: 4,
    mb: 4,
    width: "250px",
    height: "270px",
    cursor: "pointer",
    border: `1px solid ${colors.grey50}`,
    borderRadius: "4px",
    p: 1,
    "&:hover": {
      border: `1px solid ${colors.grey70}`,
      background: colors.greyBGLighter,
    },
  },
  locationImageContainer: {
    width: "100%",
    height: "150px",
    mb: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    position: "relative",
    background: colors.black,
    borderRadius: "4px",
  },
  locationImage: {
    // width: "100%",
    height: "100%",
    zIndex: 2,
  },
  bluredImage: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transform: "scale(1.2)",
    filter: "blur(7px)",
    width: "100%",
    opacity: 0.7,
    zIndex: 1,
  },
  deleteButton: {
    p: 0,
    justifyContent: "flex-end",
    pr: 0.5,
    "&:hover": {
      color: colors.bright[2],
    },
  },
};

export function DraftsTab({ orgName, title }: HumanJobTabProps) {
  const [drafts, setDrafts] = useState<DraftWithoutState[]>([]);
  const [isLoadingDrafts, setIsLoadingDrafts] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userID = useBoundStore((s) => s.profile.data?.id);
  const { setErrorMessage } = useBoundStore.getState().message;

  const fetchData = useCallback(async () => {
    try {
      setIsLoadingDrafts(true);
      const list = await restClient.getPL2DraftsWithoutStateForAnOrgAndVersion(
        orgName,
        PL2_JOB_STATE_VERSION
      );
      setDrafts(list);
    } catch (error) {
      setErrorMessage(restClient.APIErrorMessage(error));
    }
    setIsLoadingDrafts(false);
  }, [setErrorMessage, orgName]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSelectDraft = (draftID: string) => () => {
    segmentTrack("selected a draft and launched job builder 2", userID);
    navigate(Routes.JOB_BUILDER2_FN(draftID) + location.search);
  };

  const onDeleteDraft =
    (draftID: string) => async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      try {
        setIsLoadingDrafts(true);
        await restClient.deletePL2Draft(orgName, draftID);
        fetchData();
      } catch (error) {
        setErrorMessage(restClient.APIErrorMessage(error));
      }
    };

  if (isLoadingDrafts) {
    return <FullScreenProgress />;
  }

  if (!drafts.length) {
    return <>You have no drafts</>;
  }
  return (
    <BaseWizardTab
      HeaderComponent={<SmallTitle>{title}</SmallTitle>}
      data-testid="draft-base-tab"
      description="You can pick one of your previous drafts"
    >
      <Box component="div" display="flex" flexWrap="wrap">
        {drafts.map((d, i) => (
          <Box
            key={i}
            component="div"
            sx={styles.card}
            onClick={onSelectDraft(d.id)}
          >
            <Box component="div" sx={styles.locationImageContainer}>
              <img
                alt={`${d.job_name} location`}
                src={getImage(d.location)}
                style={styles.locationImage as React.CSSProperties}
              />
              <img
                alt={`${d.job_name} location`}
                src={getImage(d.location)}
                style={styles.bluredImage as React.CSSProperties}
              />
            </Box>
            <Box component="div">
              <Typography mb={0.5} variant="h4">
                {d.job_name}
              </Typography>
              <Typography
                mb={2}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
                variant="body2"
              >
                Created by:{" "}
                {trimMultiple(
                  d.created_by_user_first_name,
                  d.created_by_user_last_name
                )}
              </Typography>
              <Box
                alignItems="center"
                component="div"
                display="flex"
                justifyContent="space-between"
              >
                <Typography variant="body2">
                  Created on: {formatDateWithoutTime(d.created_at)}
                </Typography>
                <Button
                  color="secondary"
                  endIcon={<DeleteIcon />}
                  sx={styles.deleteButton}
                  onClick={onDeleteDraft(d.id)}
                />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </BaseWizardTab>
  );
}
