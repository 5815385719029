import React, { useMemo } from "react";
import { People, PersonAdd, PersonSearch } from "@mui/icons-material";
import { Dialog, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { IdentitiesIcon } from "assets/icons";
import { colors } from "components/App/theme";
import { defaultDemographicBuilder } from "domain/Human";
import { ToolbarIconContainer } from "features/JobBuilder2/steps/set-rig-location/camera-rig-dialog/camera-rig-dialog.styled";
import { usePreviousValue } from "hooks/usePreviousValue";
import { IdentitiesDemographic } from "./identities-demographic";
import { IdentitesManual } from "./identities-manual";
import { IdentitesPrefab } from "./identities-prefab";

export const ADD_IDENTITIES_OPTIONS = [
  {
    key: "prefab",
    title: "Prefab Identity Groups",
    description: "Select from a list of pre-defined identity groups",
    actionLabel: "Add prefab group",
    icon: (
      <People
        sx={{
          fontSize: "24px",
        }}
      />
    ),
  },
  {
    key: "demographic",
    title: "Demographic Builder",
    description: "Customize your identities with Demographic Builder",
    actionLabel: "Add using builder",
    icon: (
      <PersonSearch
        sx={{
          fontSize: "24px",
        }}
      />
    ),
  },
  {
    key: "manual",
    title: "Manually Enter Identitites",
    description: "Manually input comma separated IDs to add them to your Job.",
    actionLabel: "Add ID's manually",
    icon: (
      <PersonAdd
        sx={{
          fontSize: "24px",
        }}
      />
    ),
  },
];

export type IdentityOptionKey = (typeof ADD_IDENTITIES_OPTIONS)[number]["key"];

type AddIdentityDialogProps = {
  addIdentitiesModalVisible: string | null;
  setAddIdentitiesModalVisible: (value: string | null) => void;
  setPrefab: (ids: number[], oldIds: number[]) => void;
  demographicBuilder: typeof defaultDemographicBuilder;
  onUpdateDemographicBuilder: (v: typeof defaultDemographicBuilder) => void;
  addIdentities: (ids: number[], allowDuplicates?: boolean) => void;
};

export function AddIdentitiesDialog(props: AddIdentityDialogProps) {
  const {
    addIdentitiesModalVisible,
    setAddIdentitiesModalVisible,
    setPrefab,
    demographicBuilder,
    onUpdateDemographicBuilder,
    addIdentities,
  } = props;

  const handleSetPrefab = (ids: number[], oldIds: number[]) => {
    setPrefab(ids, oldIds);
    setAddIdentitiesModalVisible(null);
  };

  const handleAddIdentities = (ids: number[], allowDuplicates?: boolean) => {
    addIdentities(ids, allowDuplicates);
    setAddIdentitiesModalVisible(null);
  };

  const lastActive = usePreviousValue(addIdentitiesModalVisible);

  const activeOption = useMemo(
    () =>
      ADD_IDENTITIES_OPTIONS.find((option) => {
        if (addIdentitiesModalVisible) {
          return option.key === addIdentitiesModalVisible;
        }

        // If the modal is not visible, we want to show the last active option
        // to prevent the dialog from jumping around when it is closed
        return option.key === lastActive;
      }),
    [addIdentitiesModalVisible, lastActive]
  );

  return (
    <Dialog
      maxWidth="md"
      open={!!addIdentitiesModalVisible}
      onClose={() => setAddIdentitiesModalVisible(null)}
    >
      <Box
        alignItems="center"
        borderBottom={`1px solid ${colors.grey20}`}
        component="div"
        display="flex"
        p={2}
      >
        <ToolbarIconContainer>
          <IdentitiesIcon />
        </ToolbarIconContainer>{" "}
        <Typography ml={1} variant="h4">
          {activeOption?.title}
        </Typography>
      </Box>

      <Box component="div">
        {activeOption?.key === "prefab" && (
          <IdentitesPrefab
            closeDialog={() => setAddIdentitiesModalVisible(null)}
            prefabClicked={handleSetPrefab}
          />
        )}

        {/* MAKE SURE THE CALL TO ADD IDENTITIES WORKS PROPERLY _ BEFORE THERE WERE 2 ADD IDENTITY FUNCTIONS, ONE WITH DUPLICATES ALLOWED AND ONE WITHOUT, FIGURE OUT AND MAKE SURE ITS WORKING */}

        {activeOption?.key === "demographic" && (
          <IdentitiesDemographic
            closeDialog={() => setAddIdentitiesModalVisible(null)}
            demographicBuilder={demographicBuilder}
            onAppendIds={addIdentities}
            onUpdateDemographicBuilder={onUpdateDemographicBuilder}
          />
        )}

        {activeOption?.key === "manual" && (
          <IdentitesManual
            closeDialog={() => setAddIdentitiesModalVisible(null)}
            onAppendIds={handleAddIdentities}
          />
        )}
      </Box>
    </Dialog>
  );
}
